import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-hotel',
  templateUrl: './item-hotel.component.html',
  styleUrls: ['./item-hotel.component.scss']
})
export class ItemHotelComponent implements OnInit {

  @Input() item: any;
  @Input() destinations: any;
  @Input() roomsloops:any;

  constructor() { }

  ngOnInit() {
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }
}
