import { Component, OnInit, Inject } from "@angular/core";
// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ItineraryService } from "../itinerary.service";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MaterialModalConfirmComponent } from "../material-modal-confirm/material-modal-confirm.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormControl, AbstractControl, ValidationErrors, FormGroup, Validators, FormArray } from "@angular/forms";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap";

@Component({
  selector: "usb-modal-edit-booking",
  templateUrl: "./modal-edit-booking.component.html",
  styleUrls: ["./modal-edit-booking.component.scss"],
})
export class ModalEditBookingComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  step = 1;
  tabSelected = "HOTEL";
  payload = "";
  _form: FormGroup;
  results: any;
  itinerary: any;
  item: any;
  paxes: any;
  itineraryItems: any;
  paxesInItems = [];
  roomSelect: any;
  hotelSelect: any;
  bsModalRef?: BsModalRef;
  constructor(
    public dialog: MatDialog,
    private itineraryService: ItineraryService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalEditBookingComponent>,
    public sanitizer: DomSanitizer,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.itinerary = this.data.itinerary;
    this.item = this.data.item;
    this.getItineraryInfo();
  }

  getItineraryInfo() {
    const products = [];
    this.itinerary.items.forEach((element) => {
      if (element.status_id == 1) {
        products.push(element);
      }
    });
    this.itineraryItems = products;
  }

  emitPaxes(paxes: any) {
    this.paxesInItems = paxes;
    console.log('emitPaxes this.paxesInItems ', this.paxesInItems);
  }

  changeStep(step) {
    console.log("recibo step", step);
    this.step = step;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  closeTop() {
    this.dialog.closeAll();
  }

  // control tabs
  goBack() {
    // if ( this.step == 2 ) {
    this.step = this.step - 1;
    //  }
  }

  verifyNext() {}

  emitResults(event: any) {
    if (event) {
      this.results = event;
      console.log("results llegando", this.results);
    }
  }

  emitNext(event: any) {
    if (event) {
      this.next();
    }
  }

  roomSelect_(roomSelect: any) {
    this.roomSelect = roomSelect;
  }

  hotelSelect_(hotelSelect: any) {
    this.hotelSelect = hotelSelect;
  }

  emitStep(step: any) {
    this.step = step;
  }

  next() {
    /*
    switch (this.step) {
      case 3:
      break;
      default:

        this.step = (this.step + 1);
        break;
    } */

    this.step = this.step + 1;
  }

  close() {
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Estás seguro de cerrar?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if (result) {
        this.dialogRef.close(true);
      } else {
        //this.openModalExtraLarge(templateNewClient);
      }
      confirmSubscription.unsubscribe();
    });
  }

  goReservation() {
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Esta seguro de realizar la reserva?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if (result) {
        console.log("---ModalRoomsComponet--- continue()--- roomAdd", this.roomSelect);
        console.log("---ModalRoomsComponet--- continue()--- hotel", this.hotelSelect);
        console.log("---ModalRoomsComponet--- continue()--- item", this.item);
        this.addItinerary();
        this.cancelReservation();
        this.dialog.closeAll();
      }
      confirmSubscription.unsubscribe();
    });
  }

  addItinerary() {
    this.itineraryService.add(this.roomSelect).subscribe(
      (response: any) => {
        console.log('Add-Success: ', response);
        this.createReservation();
      },
      (error) => {
        console.log('Add-Error: ', error);
        this.itineraryService.sendMessageSubscriptorLoading(false);
      }
    );
  }

  cancelReservation() {
    this.itineraryService.cancelReservation(this.item);
  }


  createReservation() {
    console.log('createReservation paxesInItems ', this.paxesInItems);
    this.itineraryService.createReservation(this.roomSelect, this.itinerary, this.paxesInItems);
  }
}
