import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-rentacar-detail-summary',
  templateUrl: './item-rentacar-detail-summary.component.html',
  styleUrls: ['./item-rentacar-detail-summary.component.scss']
})
export class ItemRentacarDetailSummaryComponent implements OnInit {
  @Input() item: any;
  constructor() { }

  ngOnInit() {
  }

}
