import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MultiDestinationRoutingModule } from './multi-destination-routing.module';
import { KeyPointComponent } from './key-point/key-point.component';
import { MapComponent } from './map/map.component';
import { MultiDestinationComponent } from './multi-destination.component';
////import { SharedModule } from '../shared/shared.module';
//import { NgxLoadingModule } from 'ngx-loading';
import { configLoading } from '../shared/config.loading';
import { SharedModule } from '../shared/shared.module';




@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MultiDestinationRoutingModule,
    SharedModule
    //NgxLoadingModule.forRoot(configLoading),
    //NgxSortableModule ,
    //SortableModule.forRoot()
  ],
  declarations: [
    KeyPointComponent,
    MapComponent,
    MultiDestinationComponent
  ],
  providers: [

  ]
})
export class MultiDestinationModule { }
