import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ApiService } from '../../itinerary/shared/api.service';
import { ItineraryService } from '../../shared/itinerary.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { setting } from '../../../../setting';

@Component({
  selector: 'usb-data-itinerary',
  templateUrl: './data-itinerary.component.html',
  styleUrls: ['./data-itinerary.component.scss'],
  providers: [DatePipe]
})
export class DataItineraryComponent implements OnInit {

  @Input() itinerary: any;
  @Input() screen: any;
  @Input() idReservation: any;
  @Input() idConfirmation: any;
  clientData: any;
  arrival: string;
  departure: string;
  nigths: number = 0;
  adults: number = 0;
  children: number = 0;
  destinySecuency: string;
  flyQty:string = '0';
  public totalAdults: number;
  public totalChildren: number;

  public getClientsSettingAppBooking: any;

  constructor(
    private datePipe: DatePipe,
    private apiService: ApiService,
    public itineraryService: ItineraryService,
    public userService:UserService)
    {
      this.clientData = {
        name: null,
        mail: null,
        phone: null
      };
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    }

  ngOnInit() {
    this.getClientData();

    // console.log('userservice data fechas ->', this.userService);

    // console.log('userservice data ->', this.itinerary);

  ///  this.getReservations();
    if (this.itinerary && this.itinerary.items && Array.isArray(this.itinerary.items)) {

      let arrivalDates = [];
      let departureDates = [];
      let origin: string;

      this.totalAdults = this.itinerary.items.reduce((sum, item) => sum + (item.paxAdults || 0), 0);
      this.totalChildren = this.itinerary.items.reduce((sum, item) => sum + (item.paxChildren || 0), 0);

      this.itinerary.items.forEach(date => {
        arrivalDates.push(date.arrivalDate);
        departureDates.push(date.departureDate);
        if (date.categoryName === 'INSURANCE') {
          origin = date.origin;
        }
      });

      arrivalDates.sort();
      departureDates.sort().reverse();

      this.arrival = this.itinerary.arrivalDate ;
      this.departure = this.itinerary.departureDate ;
      this.destinySecuency = this.getDestinySecuency(this.itinerary.destination, origin);
      // this.totalPrice = this.getTotalPrice(this.actualItinerary.items);
     // this.totalPrice = this.itinerary.totalPrice;
      this.getFlyItinerary();

      let suscription = this.itineraryService.getItinerary$();
      suscription.subscribe((data:any) => {
        if (data) {
          this.itinerary = data[0];
          if  (this.itinerary) {
              this.destinySecuency = this.getDestinySecuency(this.itinerary.destination, origin);
          }
        }
      });

    }else{
      console.log("error itinerary");
    }
  }


  getFlyItinerary(){

    this.apiService.doGet('itinerary/' + this.itinerary.id +'/fly').subscribe(
    //this.apiService.doGet('fly_itineraries').subscribe(
      (response:any) => {
        //console.log('Reservas', response);

        if(response == undefined){
          this.flyQty = '0';
        }else{
          this.flyQty = '1';
        }
      },
      error => {
        console.log("vuelo error", error);
        this.flyQty = '0';
      }
    );

  }


  destinationsPerItinerary(): string {
    let destinations = '';
    if (this.itinerary.destination.length > 0) {
      this.itinerary.destination.forEach((destination, index) => {
        destinations = destinations.concat(index === 0 ?
        (destination.destination_info.cityName ? destination.destination_info.cityName : destination.iata_code)
        : ' > ' + (destination.destination_info.cityName ? destination.destination_info.cityName : destination.iata_code));
      });
    } else {
      destinations = 'Any destination';
    }
    return destinations;
  }


 /**
   * get string secuency destinys
   * @param destinys
   */

  getDestinySecuency(destinys: Array<any>, origin?: string): string {
    let secuency = '';
    if (origin) {
      if (destinys.length === 1) {
        secuency = origin + ' > ' + destinys[0].iata_code;
      } else {
        secuency = origin;
      }
    }
    if (destinys) {
      for (let i = 0; i < destinys.length; i++) {
        let depar1 = new Date(destinys[i].destination_info.departure);
        let depar2 = new Date(this.departure);
        this.nigths += parseInt(destinys[i].destination_info.nights);
        this.adults = destinys[i].destination_info.adults > this.adults ?
        destinys[i].destination_info.adults : this.adults;
        this.children = destinys[i].destination_info.childs > this.children ?
        destinys[i].destination_info.childs : this.children;
        //this.departure = depar1 > depar2 ? depar1.toString() : depar2.toString();
        if (!origin && i === 0) {
          secuency = secuency.concat((destinys[i] && destinys[i].destination_info.cityName) ?
            destinys[i].destination_info.cityName : '');
        } else if (destinys[i] && destinys[i].destination_info.cityName) {
          if(destinys[i].destination_info.cityName !== ''){
            secuency = secuency.concat(' > ' + destinys[i].destination_info.cityName);
          }
        }
      }
    }
    return secuency.toUpperCase();
  }



  getReservations() {
    this.apiService.doGet('reservations/').subscribe(
      (response:any) => {
        //console.log('Reservas', response);
      },
      error => {
        console.log(error);
      });
  }
  getDatesRange() {
    const destination = this.itinerary.destination;
    if (destination.length > 0) {
      const arrival = this.itinerary.arrivalDate ?
      this.datePipe.transform(this.itinerary.arrivalDate.toString(), 'MMM dd, yyyy') :
      this.datePipe.transform(this.itinerary.arrivalDate.toString(),
         'MMM dd, yyyy');
      const departure = destination.length === 0 ? '' : ' to ' +
      this.datePipe.transform(this.itinerary.departureDate.toString(),
         'MMM dd, yyyy');
      return arrival + departure;
    } else {
      return 'Dates not defined';
    }
  }

  getClientData() {
    const client = this.itinerary.clients;
    if(client==null){
      const name = '';
      const email = '';
      const phone = '';
      return 'Personal information not available';
    }
    this.clientData.name = client.first_name !== 'false' ? client.first_name + ' '  + (client.last_name==null ? '' : client.last_name) : '';
    this.clientData.email = client.email ? client.email : null;
    this.clientData.phone = client.phone ? client.phone : null;
  }

  existHotel(){
    if(this.userService.itineraryActive.length > 0){
      if(this.userService.itineraryActive[0].items.length>0){
        let exist = this.userService.itineraryActive[0].items.find( element => element.categoryName == "HOTELS" );

        if(exist){
          return true;
        }else{
          return false;
        }
      }
    }
  }

}
