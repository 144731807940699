import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ItineraryService } from '../../../../itinerary.service';

@Component({
  selector: 'usb-itinerary-ticket',
  templateUrl: './itinerary-ticket.component.html',
  styleUrls: ['./itinerary-ticket.component.scss']
})
export class ItineraryTicketComponent implements OnInit {

  @Input() data: any;
  @Input() dt: any;

  constructor(
    public itineraryService: ItineraryService,
    private _router: Router,
  ) { 
		console.log('ticket-data:', this.data);
	}

  ngOnInit(): void {
    if (typeof this.dt.reserveData === 'string') {
      this.dt.reserveData = JSON.parse(this.dt.reserveData);
    }
  }

  remove(dt: any) {
    console.log('data:', this.data);
    console.log('dt:', dt);
    const index: number = this.data.indexOf(dt);
    if (index !== -1) {
      this.data.splice(index, 1);
      this.itineraryService.remove(this.dt);
    }
  }
	
  // get detail reservation
  getDetail(data: any) {
    let reservation = data;
    if (typeof data === 'string') {
      reservation = JSON.parse(data);
    }
    this._router.navigateByUrl('/reservation/' + reservation.id);
  }
}