<div id="themes-result-component">
  <div class="d-flex col-12 m-0 p-0 pos-div">
    <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12 themes-image">
      <div class="col-12 m-0 p-0">
        <!-- <img class="img-themes" *ngIf="result.additionalInfo.images[0]" src="{{result.additionalInfo.images[0].image}}" alt="PThemes"> -->
       <!-- <img class="img-themes" src="{{imageItem}}" alt="PThemes">-->
       <img class="img-themes" *ngIf="result?.additionalInfo?.images && result?.additionalInfo?.images[0]" src="{{result.additionalInfo.images[0].image}}" alt="PThemes">
       <img class="img-themes" *ngIf="!result?.additionalInfo?.images" src="assets/img/app/parque-tematico.jpg" alt="PThemes">      
      </div>
    </div>
    <div class="col-xg-7 col-lg-7 col-md- 5 col-sm-12 themes-details p-0 d-flex flex-wrap">
      <!-- Titulo -->
      <div class="col-12 m-0 p-0">
        <div class="row">
          <div class="col-5">
            <h4 class="title font-lato">{{stingUpperCase(result.title)}}</h4>
          </div>
          <div class="col-7 px-0">
            <ul class="nav justify-content-end pt-2">
              <li style="border-left: 1px solid #000000; padding-left: 5px; padding-right: 10px;">
                <div class="d-flex">
                  <i class="icon-view"></i>
                  <span class="text-views font-opensans">2500<br>views</span>
                </div>
              </li>
              <li style="border-left: 1px solid #000000; padding: 0px 10px;">
                <i class="icon-vid-1"></i>
              </li>
              <li style="border-left: 1px solid #000000; padding: 0px 8px;">
                <i class="icon-vid-2"></i>
              </li>
              <li style="border-left: 1px solid #000000; padding: 0px 15px;">
                <p class="days text-nowrap text-center font-lato">
                  <span class="days-nro">07</span>&nbsp;&nbsp;DAYS&nbsp;
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Descriptions -->
      <div class="col-12 m-0 p-0">
        <div class="row pb-2">
          <div class="col-12">
            <p class="text-description font-lato">
              {{ !(isNull(result.description))? result.description : 'El proveedor no añadió ninguna descripción del servicio.' }}
            </p>
          </div>
        </div>
      </div>
      <!-- Included Services -->
      <div class="col-12 m-0 p-0 align-self-end">
        <div class="row">
          <div class="col-8">
            <ul class="nav">
              <li class="included-content">
                <p class="text-included font-poppins pt-1 px-2">included</p>
              </li>
              <li class="ml-2">
                <i class="icon-wifi"></i>
              </li>
              <li>
                <i class="icon-radio"></i>
              </li>
              <li>
                <i class="icon-coffee"></i>
              </li>
              <li>
                <i class="icon-tv"></i>
              </li>
              <li class="ml-1">
                <i class="icon-tickets"></i>
              </li>
              <li class="ml-2">
                <i class="icon-transports"></i>
              </li>
            </ul>
          </div>
          <div class="col-4 text-right">
            <span class="font-lato text-off">15 <i class="icon-off"></i></span>
          </div>
        </div>
      </div>
      <!-- Hotels Logo & points -->
      <div class="col-12 m-0 p-0 align-self-end">
        <div class="row">
          <div class="col-6">
            <img src="/assets/img/app/logo-disney-hotel.png" class="logo-disney-hotel" alt="">
          </div>
          <div class="col-6 text-right">
            <span class="font-points font-poppins align-middle">
              <i class="icon-points"></i>
              500&nbsp;Bks.
            </span>
          </div>
        </div>
      </div>

      <!-- <h4 class="pt-2">{{result.title}}</h4>
      <div class="flex-row d-flex justify-content-between location">
        <span *ngIf="result.additionalInfo?.location" class="d-flex">
            <i class="icon-placeholder"></i>{{result.additionalInfo?.location}}
        </span>
      </div>

      <div class="description pt-1 d-flex flex-column"> -->
        <!-- <p>{{result.additionalInfo.rooms[0].mealPlan.name}}</p> -->
        <!-- <p>{{result.description | truncate : 130 : " "}}<a (click)="ModalRooms.show()" class="more">...More</a></p>
        <div class="d-flex services">
          <div class="d-flex mt-3">
            <span *ngIf="result.additionalInfo?.childAgeLimit" class="d-flex">
                  <i class="fa fa-child"></i>&nbsp;Age limit: {{result.additionalInfo?.childAgeLimit}} Years
                </span>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12">
      <div class="col-12 m-1 p-0">
        <img class="img-logo" *ngIf="result?.logo" src="{{result.logo }}" alt="logo">
      </div>
    </div> -->
    
    <!-- Price & Add product -->
    <div class="col-xg-2 col-lg-2 col-md-3 col-sm-12 justify-content-end continue p-0 flex-column d-flex">
      <div class="d-flex align-content-end flex-wrap">
        <p class="w-100 m-0 from-text font-lato">From</p>
        <p class="m-0 font-lato from-price"><span class="text-usd">USD</span>3380</p>
        <p class="m-0 font-lato font-p-night">P/NIGHT</p>
      </div>
      <div class="d-flex">
        <button type="button" (click)="ModalRooms.show()" class="btn btn-add justify-content-end w-100 p-0">Add</button>
        <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" [modalRoomRef]="ModalRooms"
          [product]="result" [productQty]="payload.qtyProduct" [form]="form" [origin]="origin" [arrayResults]="arrayResults">
        </usb-modals>
      </div>

      <!-- <div class="d-flex flex-row justify-content-center rating-star">
       <i class="icon-estrella px-1"></i><i class="icon-estrella px-1"></i><i class="icon-estrella px-1"></i><i class="icon-estrella px-1"></i> 
      </div>-->
      <!-- <div class="d-flex justify-content-center flex-column text-right py-2">
        <span class="text-price-from">{{result.additionalInfo?.options[0].priceAdultsFlag}}From</span>
        <div *ngIf="!(result.additionalInfo?.options[0].priceAdultsProf ? true : false)">
          <a class="loader text-center justify-content-center ">
              <i class="fa fa-spinner fa-spin"></i>
          </a>
        </div>
        <span *ngIf="(result.additionalInfo?.options[0].priceAdultsProf ? true : false)" 
              class="themes-price">${{result.additionalInfo?.options[0].priceAdultsProf | number:'1.0-2'}}</span>
        <span class="text-price-pp">P/Adult</span>
      </div>
      <div class="d-flex pb-2">
        <button type="button" (click)="ModalRooms.show()" class="btn btn-usblick-pink justify-content-end w-100 py-1">Add</button>
        <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" [modalRoomRef]="ModalRooms"
          [product]="result" [productQty]="payload.qtyProduct" [form]="form" [origin]="origin" [arrayResults]="arrayResults"></usb-modals>
      </div> -->
    </div>

  </div>

</div>