import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FormsModule , ReactiveFormsModule } from '@angular/forms';
// import { TranslateModule } from '@ngx-translate/core';
//import { //NgbModule, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ManagementItineraryRoutingModule } from './management-itinerary-routing.module';
// import { CardItineraryComponent } from './card-itinerary/card-itinerary.component';
// import { FilterItineraryComponent } from './filter-itinerary/filter-itinerary.component';

// import { configLoading } from '../shared/config.loading';
////import { SharedModule } from '../shared/shared.module';
//import { NgxLoadingModule } from 'ngx-loading';
// import { NgxSpinnerModule } from "ngx-spinner";
// import { ModalPostBookingComponent } from '../shared/modal-post-booking/modal-post-booking.component';
// // import { ManagementItineraryComponent } from './management-itinerary.component';
// import { SharedModule } from '../shared/shared.module';
// import { BsDatepickerModule } from 'ngx-bootstrap';
// import { defineLocale } from 'ngx-bootstrap/chronos';
// import { esLocale } from 'ngx-bootstrap/locale';


// defineLocale('es', esLocale);

@NgModule({
    entryComponents:[
        // ModalPostBookingComponent
    ],
    imports: [

        CommonModule,
        // ReactiveFormsModule,
        // FormsModule,
        //TranslateModule,
        //NgbModule,
        ManagementItineraryRoutingModule,
        //LoadingModule.forRoot(configLoading);
        //NgxLoadingModule.forRoot(configLoading),
        // NgxSpinnerModule,
        // SharedModule,
        // BsDatepickerModule,


    ],
    declarations: [
        // ManagementItineraryComponent,
        // CardItineraryComponent,
        // FilterItineraryComponent
    ]
})
export class ManagementItineraryModule { }
