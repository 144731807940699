<ngx-spinner bdColor="rgba(0, 0, 0, 0)" size="medium" color="#fa1a1a" type="line-scale">
  <p style="color: white"> Loading... </p>
</ngx-spinner>


<div id="modalcars" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close" (click)="close()">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div class="modal-body pt-0 px-0">
    <div class="row mx-2">
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-12">
            <!--- title --->
            <p class="title font-lato font-dark">{{stingUpperCase(result.title)}}&nbsp;
              <span class="d-flex sub-title font-lato mt-2">
                {{ result?.additionalInfo?.carRental?.category }} ,
                {{ (((result?.additionalInfo?.carRental?.type_definition).length) > 80)? (result?.additionalInfo?.carRental?.type_definition.substring(0,80)) : result?.additionalInfo?.carRental?.type_definition }}
              </span>
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-between mt-1">
          <div class="col-2 p-0 pl-3">
            <p class="font-lato date-rent">
              <span class="font-verde text-date-rent font-poppins">INICIO: </span> {{ formatDate(result?.dateIni) }}
              <br>
              <span class="font-verde text-date-rent font-poppins">FIN: </span> {{ formatDate(result?.dateEnd) }}
            </p>
            <div class="row ml-3">
              <div class="col-6 px-0" *ngIf="result?.additionalInfo?.carRental?.space_distribution.Adultos">
                <div class="row justify-content-start">
                  <div class="m-0 p-0">
                    <i class="icon-adults"></i>
                  </div>
                  <div class="m-0 p-0">
                    <span class="count-settings font-poppins pl-1">
                      {{result?.additionalInfo?.carRental?.space_distribution.Adultos? result?.additionalInfo?.carRental?.space_distribution.Adultos.slice(1) : "No disponible" }}</span>
                  </div>
                </div>
              </div>
              <div class="col-6 px-0" *ngIf="totalChildren">
                <div class="row justify-content-start">
                  <div class="m-0 p-0">
                    <i class="icon-child"></i>
                  </div>
                  <div class="m-0 p-0">
                    <span class="count-settings font-poppins pl-1">
                      {{totalChildren ? totalChildren.slice(1) : "No disponible"}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-2" style="text-align: center">
                <i class="icon-bags"></i>
              </div>
              <div class="col-9 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle">Tamaño del equipaje
                  <br>
                  {{result?.additionalInfo?.carRental?.space_distribution.bag ? result?.additionalInfo?.carRental?.space_distribution.handbag : 0 }}
                  {{ result?.additionalInfo?.carRental?.acriss_extra_detail.bag_size }}
                </p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-2" style="text-align: center">
                <i class="icon-handbag"></i>
              </div>
              <div class="col-9 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle">Tamaño del mano <br>
                  {{result?.additionalInfo?.carRental?.space_distribution.bag ? result?.additionalInfo?.carRental?.space_distribution.handbag : 0 }}
                  {{result?.additionalInfo?.carRental?.acriss_extra_detail.handbag_size }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-2">
            <!-- <div class="row">
              <div class="col-2">
                <i class="icon-kilometro"></i>
              </div>
              <div class="col-8 text-nowrap">
                <p style="white-space: pre-line !important;" class="text-detail-car font-lato text-nowrap align-middle">
                  Kilometraje Ilimitado
                </p>
              </div>
            </div> -->
            <div class="row" *ngIf="result?.additionalInfo?.carRental?.doors">
              <div class="col-2" style="text-align: center">
                <i class="icon-car"></i>
              </div>
              <div class="col-9 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle">Asientos
                  <br>
                  {{result?.additionalInfo?.carRental?.space_distribution ? result?.additionalInfo?.carRental?.space_distribution : 0 }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-2" style="text-align: center">
                <i class="icon-adults"></i>
              </div>
              <div class="col-9 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle">Pasajeros permitidos
                  <br>
                  {{result?.additionalInfo?.carRental?.paxQuantity ?  result?.additionalInfo?.carRental?.paxQuantity : "No disponible"}}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-2" style="text-align: center">
                <i class="fa fa-suitcase"></i>
              </div>
              <div class="col-9 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle">Cantidad de Maletas
                  <br>
                  {{result?.additionalInfo?.carRental?.space_distribution.Maleta ?  result?.additionalInfo?.carRental?.space_distribution.Maleta.slice(1) : "No disponible"}}
                </p>
              </div>
            </div>

            <!-- <div class="row  mt-2">
              <div class="col-2">
                <i class="icon-aircon"></i>
              </div>
              <div class="col-8 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle">Air conditioning
                </p>
              </div>
            </div> -->
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-2">
                <i class="icon-automatic"></i>
              </div>
              <div class="col-8 text-nowrap">
                <p style="white-space: pre-line !important;" class="text-detail-car font-lato text-nowrap align-middle">
                  {{result?.additionalInfo?.carRental?.gearbox ? result?.additionalInfo?.carRental?.gearbox : 0}}
                </p>
              </div>
            </div>
            <div class="row" style="margin-top: -4px;">
              <div class="col-2">
                <i class="icon-fuel"></i>
              </div>
              <div class="col-8 text-nowrap">
                <p class="text-detail-car font-lato text-nowrap align-middle" style="white-space: pre-line !important;">
                  {{result?.additionalInfo?.carRental?.fuel_aircondition}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col text-center px-0">
                <img *ngIf="result?.additionalInfo?.images && result?.additionalInfo?.images[0]"
                  src="{{result.additionalInfo.images[0].image}}" class="img-fluid w-100" alt="rentacar">
                <img *ngIf="!result.additionalInfo?.images || !result.additionalInfo?.images[0]" src="assets/img/app/rent-a-car-default.png"
                  class="img-fluid w-100" alt="rentacar">
              </div>
            </div>
            <div class="ubicacion-marca w-100">
              <div class="row text-align-end">
                <div class="col-6">
                  <img width="100%" height="100%" src="{{result.additionalInfo?.carRental?.optionRates?.suplier?.img}}" onerror="if (this.src != 'assets/img/app/rent-a-car-default.png') this.src = 'assets/img/app/rent-a-car-default.png';">
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="row mx-2">
      <div class="col-12">
        <ul class="nav justify-content-between">
          <li class="nav-item font-poppins" [ngClass]="{'selected': selected=='INCLUDES'}">
            <a class="nav-link" (click)="selected='INCLUDES'">PLAN</a>
          </li>
          <!-- ,'disabled-event': optionals == '' || totalPrice==0  -->
          <li class="nav-item font-poppins"
            [ngClass]="{'selected': selected=='UBICACION'}">

            <a class="nav-link" (click)="selected='UBICACION' ">UBICACIÓN</a>
          </li>
          <!--  , 'disabled-event': pickUpData == '' || totalPrice==0 } -->
          <li class="nav-item font-poppins"
            [ngClass]="{'selected': selected=='OPCIONALES'}">

            <a class="nav-link" (click)="selected='OPCIONALES'">OPCIONALES</a>
          </li>
          <li class="nav-item font-poppins" [ngClass]="{'selected': selected=='INFO', 'disabled-event': booleanWindowInfo}">
            <a class="nav-link" (click)="selected='INFO'">INFO</a>
          </li>
        </ul>
        <div class="content-modal mx-3 mt-2">
          <!-- INCLUDES -->
          <div [ngClass]="{'d-block': selected == 'INCLUDES', 'd-none': selected != 'INCLUDES'}">
            <div class="row d-flex pb-3" style="background-color:#f0f0f0">
              <div class="row d-flex pt-2 pb-2 ml-3">
                <span class="font-verde text-date-rent font-poppins pt-3" style="font-size:16px !important;">OFICINAS DE RECOGIDA Y
                  DEVOLUCIÓN</span> 
              </div>
              <div class="row d-flex pt-2 pb-2 w-100 ml-3 mr-3">
                <div class="col-6 d-flex flex-column pl-0">
                  <label class="card-info-title"> Recogida </label>
                  <select class="form-control description-modal" (change)="onChangePickup($event.target.value)"
                    style="height: 41px !important; width: 100%; text-align: left!important;">
                    <option value="null" disabled>Seleccione una Oficina</option>
                    <option *ngFor="let location of officesNotRepeated let index = index"
                      [value]="location?.PickUp.Name">
                     <span> {{location?.PickUp.Name}}</span>
                    </option>
                  </select>
                </div>
                <div class="col-6 d-flex flex-column pr-1">
                  <label class="card-info-title">Devolución</label>
                  <select class="form-control description-modal" (change)="onChangeDropOff($event.target.value)"
                    style="height: 41px !important; width: 100%; text-align: left!important;">
                    <option value="null" disabled>Seleccione una Oficina</option>
                    <option *ngFor="let location of officesNotRepeated let index = index"
                      [value]="location?.DropOff?.Name">
                      {{location?.DropOff.Name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngFor="let includes of arrayResults; let index = index" class="col-12 includes-detail mt-3">
                <div class="row" id="{{index}}">
                  <div class="include-list py-3 px-3">
                    <div class="row row pt-2 pb-2" style="background-color:#f0f0f0;">
                      <div class="col-2 pr-0 pl-0">
                          <span class="pl-3 pb-3" style="font-weight:bold"> {{namePlan(includes?.additionalInfo?.carRental?.ContractInfo?.Name)}} </span>
                      </div>
                      <div class="col-10 pr-1 pb-3 pl-4">
                        <span class="pb-3" style="font-size:12px ;line-height:0px ;"> {{includes?.additionalInfo?.carRental?.ContractInfo?.Description}} </span>
                    </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col pr-0">
                        <p class="font-poppins title-included">Incluido</p>
                        <!-- *ngFor="let incluided of includes.additionalInfo.carRental.optionRates.suplements"-->
                        <ul *ngFor="let incluided of includes.additionalInfo.carRental.optionRates.carsfees" class="nav flex-column">
                          <div *ngIf="incluided.PaymentOffice == 'false'">
                            <li>
                              <p class="font-poppins text-included text-nowrap">
                                <i class="icon-check-green"></i>
                                <!-- _split(includes?.additionalInfo?.carRental?.ContractInfo?.Description)-->
                                <span class="pl-1"> {{ incluided.Name ? incluided.Name : "No disponible"}} </span>
                               <!-- <span style="font-weight: 700;"> $ {{ incluided.Amount | number:'1.2-2' }} </span> --> 
                              </p>
                            </li>
                          </div>
                        </ul>
                        <br>
                      </div>
                      <div class="col pr-0">
                        <p class="font-poppins title-included">No Incluido</p>
                        <ul *ngFor="let noincluided of includes.additionalInfo?.carRental?.optionRates?.carsfees" class="nav flex-column">
                          <div *ngIf="noincluided.PaymentOffice =='true'">
                            <li>
                              <p class="font-poppins text-included text-nowrap">
                                <i class="fas fa-times noincluded"></i>
                                <span class="pl-1"> {{noincluided.Name}} </span>
                                <span style="font-weight: 700;"> $ {{ noincluided.Amount | number:'1.2-2' }} </span>
                              </p>
                            </li>
                          </div>
                        </ul>
                        <span *ngIf = "includes.additionalInfo.carRental.optionRates.carsfees.length == 0"> NO INCLUYE OPCIONALES </span>

                      </div>
                    </div>
                    <!-- politicas 
                    
                         <div class="row pl-2">
                      <div class="col-2 mt-1">
                        <div class="row pl-2">
                          <div class="m-0 p-0">
                            <ul class="nav flex-column">
                              <li>
                                <p class="font-poppins text-included text-nowrap">
                                  <i class="icon-check-green"></i>
                                  <span class="pl-1" *ngIf ="optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy"> {{ cancelationPolicy(optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.Description) }} </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
         
                        <div class="row pl-2">
                          <div class="col-2 mt-1">
                            <div class="row pl-2">
                              <div class="m-0 p-0">
                                <ul class="nav flex-column">
                                  <li>
                                    <p class="font-poppins text-included text-nowrap">
                                      <i class="icon-check-green"></i>
                                      <span class="pl-1"  *ngIf ="optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy"> {{ cancelationPolicy_(optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.Description) }} </span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                    
                    -->
                    <div class="row pl-2 pt-2" style="border-top: solid 1px #e3e3e3;">
                      <p class="font-poppins title-included ">Políticas de Cancelación</p>
                    </div>
                    <!-- *ngFor="let cancellation_policy of optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.PolicyRules" -->
                    <div class="row pl-2">
                      <div class="col-2 mt-1">
                        <div class="row pl-2">
                          <div class="m-0 p-0">
                            <ul class="nav flex-column">
                              <li>
                                <p class="font-poppins text-included text-nowrap">
                                  <i class="icon-check-green"></i>
                                  <span class="pl-1"> Hasta: {{dateEndSearch}}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="row pl-2">
                          <div class="m-0 p-0">
                            <ul class="nav flex-column">
                              <li>
                                <p class="font-poppins text-included text-nowrap">
                                  <i class="icon-check-green"></i>
                                  <span class="pl-1">Desde: {{dropOffDate}}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-2">
                        <div class="row">
                          <div class="m-0 p-0 pl-2">
                            <span class="text-policy font-lato" style="line-height: 1;">
                              {{  optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.PolicyRules[0].DateTo}} </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="m-0 p-0 pl-2 mt-2">
                            <span class="text-policy font-lato" style="line-height: 1;">
                              {{ optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.PolicyRules[1].DateFrom }} </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="row pl-2">
                          <div class="m-0 p-0">
                            <span class="font-verde pl-1" style="font-weight: bold;  font-size: 13px !important"> Cancelación Gratis </span>
                          </div>
                        </div>
                        <div class="row pl-2 mt-2">
                          <div class="m-0 p-0">
                            <!-- {{optionals?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.PolicyRules[0].PercentPrice}} -->
                            <span class="font-verde pl-1" style="color: #d2394e;font-weight: bold; font-size: 13px !important;"> No Show
                              aplica 100% de gastos</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="select-include-list py-3 px-0" style="border-left: solid 1px #e3e3e3;">
                    <div class="row content-pago-destino pt-2">
                      <div class="col-9 mt-2">
                        <p class="text-pay pb-0 mb-0 font-poppins" style="font-size:13 ;">SELECCIONAR</p>
                      </div>
                      <div class="col-3 mx-0 px-0 pl-2" style="padding-top: 3px !important;">
                        <span>
                          <label class="custom-radio-checkbox mb-0">
                            <input class="custom-radio-checkbox__input" id="{{includes.id}}" value="{{includes.id}}" name="car" type="radio"
                              (change)="selectCar(includes, index)">
                            <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div class="row mt-3 pl-4 pr-4">
                      <div class="col-8">
                        <p class="font-poppins pb-0 mb-0" style="font-size:13px; font-weight: 400;">Pago Booking</p>
                      </div>
                      <div class="col-4">
                        <p class="font-poppins text-right text-nowrap" style="font-size:13px; font-weight: 400;">
                          $
                          {{ includes?.additionalInfo?.carRental?.totalPriceProf ? includes?.additionalInfo?.carRental?.totalPriceProf  : 0  | number:'1.2-2' }}
                        </p>
                      </div>
                    </div>
                    <div class="row pl-4 pr-4">
                      <div class="col-8">
                        <p class="font-poppins pb-0 mb-0" style="font-size:13px; font-weight: 400;">Pago Agencia</p>
                      </div>
                      <div class="col-4">
                        <p class="font-poppins text-right text-nowrap" style="font-size:13px; font-weight: 400;">
                          <!--  $ {{ totalPaymentAgency? totalPaymentAgency : 0  | number:'1.2-2' }} -->
                          $ {{ sumTotalAgency(includes?.additionalInfo?.carRental.optionRates?.carsfees)  | number:'1.2-2' }}
                        </p>
                      </div>
                    </div>
                    <div class="row pl-4 pr-4">
                      <div class="col-8">
                        <p class="font-poppins pb-0 mb-0" style="font-size:13px; font-weight: 400; font-weight: bold !important;">TOTAL
                          ALQUILER</p>
                      </div>
                      <div class="col-4">
                        <p class="font-poppins text-right text-nowrap" style="font-size:13px; font-weight: 400;">
                          $
                          {{ sumTotalRent(includes?.additionalInfo?.carRental?.totalPriceProf, sumTotalAgency(includes?.additionalInfo?.carRental.optionRates?.carsfees) )  | number:'1.2-2' }}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <!-- UBICACION -->
          <div [ngClass]="{'d-block': selected == 'UBICACION', 'd-none': selected != 'UBICACION'}">
            <div class="row">
              <div class="col-12 includes-detail">

                <!-- maps location -->
                <div class="row">
                  <div #map style="min-height: 450px; margin: 15px 0px 0px 0px !important; width: 100%;"></div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 includes-detail detail-rent-ubicacion">
                <div class="row">
                  <div class="col-12 px-0">
                    <p class="font-poppins font-verde" style="font-weight: 500; font-size: 16px !important;">OFICINAS DE RECOGIDA Y
                      DEVOLUCIÓN</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 px-0 pr-1">
                    <p class="font-poppins font-weight-bold" style="font-size: 13px !important;">Zona de recogida</p>
                    <span style="font-size: 12px !important; font-weight: 700; line-height: 1.5;" class="font-weight-bold">Oficina:
                    </span>
                    <span style="font-size: 12px !important; font-weight: 400; line-height: 1.5;"> {{ otherOpcions?.PickUp?.Name }}
                    </span> <br>
                    <span style="font-size: 12px !important; font-weight: 700; line-height: 1.5;" class="font-weight-bold">Dirección:
                    </span>
                    <span style="font-size: 12px !important; font-weight: 400; line-height: 1.5;"> {{ otherOpcions?.PickUp?.Address }}
                    </span> <br>
                    <span style="font-size: 12px !important; font-weight: 700; line-height: 3.5;" class="font-weight-bold">Telefono:</span>
                    <br>
                    <!-- <div *ngFor="let phoneNumbers of  otherOpcions?.PickUpData?.PhoneNumbers">
                      <p style="font-size: 12px !important; font-weight: 400; line-height:0;">{{phoneNumbers}} </p>
                    </div>-->
                    <div *ngIf="otherOpcions?.PickUp?.PhoneNumbers">
                      <p style="font-size: 12px !important; font-weight: 400; line-height:0;">{{otherOpcions?.PickUp?.PhoneNumbers}} </p>
                    </div>

                    <span style="font-size: 12px !important; font-weight: 400; line-height: 3.5;"
                      class="font-weight-bold">HORARIO:</span><br>
                    <div *ngFor="let operationTimes of otherOpcions?.OperationTime">
                      <p style="font-size: 12px !important; font-weight: 400; line-height: 0;">
                        {{operationTimes.day }}: {{operationTimes.hoursStart }} - {{operationTimes.hoursEnd }} hrs </p>
                    </div>
                  </div>
                  <div class="col-6 px-0 pr-1">
                    <p class="font-poppins font-weight-bold" style="font-size: 13px !important;">Zona de devolución</p>
                    <span style="font-size: 12px !important; font-weight: 700; line-height: 1.5;" class="font-weight-bold">Oficina:
                    </span>
                    <span style="font-size: 12px !important; font-weight: 400; line-height: 1.5;"> {{ otherOpcions?.DropOff?.Name }}
                    </span> <br>
                    <span style="font-size: 12px !important; font-weight: 700; line-height: 1.5;" class="font-weight-bold">Dirección:
                    </span>
                    <span style="font-size: 12px !important; font-weight: 400; line-height: 1.5;">
                      {{ otherOpcions?.DropOff?.Address }} </span> <br>
                    <span style="font-size: 12px !important; font-weight: 700; line-height: 3.5;" class="font-weight-bold">Telefono:</span>
                    <br>
                    <!-- *ngFor="let phoneNumbers of  otherOpcions?.PickUp?.PhoneNumbers" -->
                    <div>
                      <p style="font-size: 12px !important; font-weight: 400; line-height:0;">{{otherOpcions?.DropOff?.PhoneNumbers}} </p>
                    </div>
                    <span style="font-size: 12px !important; font-weight: 400; line-height: 3.5;"
                      class="font-weight-bold">HORARIO:</span><br>
                    <div *ngFor="let operationTimes of otherOpcions?.OperationTime">
                      <p style="font-size: 12px !important; font-weight: 400; line-height: 0;">
                        {{operationTimes.day }}: {{operationTimes.hoursStart }} - {{operationTimes.hoursEnd }} hrs </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- OPCIONALES       -->
          <div [ngClass]="{'d-block': selected == 'OPCIONALES', 'd-none': selected != 'OPCIONALES'}">
            <div class="row">
              <div class="col-12 includes-detail">
                <div class="row">
                  <div class="opcionales-detail px-3 py-3">
                    <div class="row">
                      <div class="col">
                        <p class="font-poppins font-weight-bold" style="font-size: 13px;">Suplementos Opcionales</p>
                      </div>
                    </div>
                    <div class="row mt-2" *ngFor="let optional of itemSelect?.additionalInfo?.carRental?.optionRates?.suplements let index = index">
                    
                      <div class="col-1" style="margin-left:-10px;">
                        <span *ngIf="optional.Units == 1">
                          <label class="custom-radio-checkbox">
                            <input class="custom-radio-checkbox__input" type="checkbox" id="{{this.itemSelect.additionalInfo.carRental.RatePlanCode}}" value="{{optional}}"
                              type="checkbox" (click)="_selectOptional(optional, index)">
                            <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio_opcionales"
                              style="width: 20px !important; height: 20px !important;"></span>
                          </label>
                        </span>
                      </div>

                      <div class="col-5 px-0">
                        <p class="font-poppins font-verde" style="font-size: 13px;  font-weight: 500;">{{optional.Name}}
                        </p>
                      </div>
                      <div class="col-2 px-0">
                        <p class="font-poppins font-verde" style="font-size: 13px;font-weight: 500;">${{optional.Description}}</p>
                      </div>
                      
                      <div class="col-2 px-0"> 
                        <div *ngIf = "optional.UntMax > 1"> 
                            <select class="form-control description-modal" (change)="onChangeOptional($event.target.value,  optional.Amount, index, optional)" style="height: 35px !important; text-align: left!important;" [value]="optional.UntMin">
                              <option  [value]="0">0</option>
                              <option *ngFor="let qty of getQty(optional.UntMax)" [value]="qty">{{ qty }}</option>
                            </select>
                       </div>
                      </div>
                      <div class="col-2 px-0">
                        <div *ngIf = "optional.UntMax > 1"> 
                            <p class="font-poppins font-verde" style="font-size: 13px;  font-weight: 500;">${{ totalOptional[index]? totalOptional[index] : valueDefault }}</p>
                                <!-- <p class="font-poppins font-verde" style="font-size: 13px;  font-weight: 500;">${{ totalOptional[index]? totalOptional[index] : optional.Amount}}</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- INFO -->
          <div [ngClass]="{'d-block': selected == 'INFO', 'd-none': selected != 'INFO'}">
            <div class="row">
              <div class="col-12 includes-detail">
                <div class="row">
                  <div class="col-8 px-4 pt-2">
                    <div class="row">
                      <div class="col">
                        <p class="font-poppins title-included">INFO ÚTIL</p>
                        <p class="font-poppins text-included" style="margin-top: -15px;">
                          <!-- Cada día de alquiler se deacuerdo a un término de 24 horas a partir del horario en que se retira el auto, si
                          quisiera conservar el auto por más tiempo, deberá abonar una tarifa por hora hasta cubrir la tarifa mensual o
                          semanal completa. Para los productos opcionales se aplica la tárifa diaria en caso de que el auto se entregue
                          con demora. -->
                          {{itemSelect?.additionalInfo?.carRental?.contractInfo?.Description}}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="font-poppins text-included">
                          <span class="font-weight-bold">
                            Tarjetas de Crédito de garantía:
                          </span>
                          <br>
                          <!-- Cada día de alquiler se deacuerdo a un término de 24 horas a partir del horario en que se retira el auto, si
                          quisiera conservar el auto por más tiempo, deberá abonar una tarifa por hora hasta cubrir la tarifa mensual o
                          semanal completa. Para los productos opcionales se aplica la tárifa diaria en caso de que el auto se entregue
                          con demora. -->
                          {{itemSelect?.additionalInfo?.carRental?.contractInfo?.NotIncludes}}

                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-5 pt-2" style="border-left: solid 1px #e3e3e3;">
                    <div class="row">
                      <div class="col mx-0 px-0">
                        <p class="font-poppins title-included text-center" style="color: #ab355d !important;">IMPORTANTE</p>
                        <p class="font-poppins text-included" style="margin-top: -15px;">
                          <!-- Si el títular de la reserva es menor de 25 años aplicarán cargos que deberán abonarse directamente en las
                          oficinas al momento del pick up. -->
                          {{itemSelect?.additionalInfo?.carRental?.contractInfo?.Includes}}
                        </p>
                        <!-- <p class="font-poppins text-included">
                          Por favor tener en cuenta que es obligatorio para los pick up de Hertz que el titular de la reserva cuente con
                          una tarjtea de crédito válida, de otra forma no se le rentara el auto.
                        </p> -->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col mx-0 px-0">
                        <i class="icon-warning-red"></i>
                        <p class="font-poppins text-included font-weight-bold" style="color: #ab355d !important;">
                          Se requiere licencia de conducir, válida de su país, con el nombre titular de la reserva
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-4 mt-3">
      <div class="col-12 includes-detail">
        <div class="row py-1">
          <div class="include-list pt-1 pl-3">
            <p class="font-poppins py-0 my-0 " style="font-size: 18px; font-weight: 700;">PAGO BOOKING
          </div>
          <div class="select-include-list">
            <p class="font-poppins py-0 my-0 mr-3" style="font-size: 22px; font-weight: 700; text-align: end">
              $ {{ totalPaymentUsblick? totalPaymentUsblick : 0  | number:'1.2-2' }} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-4 mt-3">
      <div class="col-12 includes-detail">
        <div class="row py-1">
          <div class="include-list pt-1 pl-3">
            <p class="font-poppins py-0 my-0 " style="font-size: 18px; font-weight: 700;">PAGO EN AGENCIA
          </div>
          <div class="select-include-list">
            <p class="font-poppins py-0 my-0 mr-3" style="font-size: 22px; font-weight: 700; text-align: end">
              $ {{ totalPaymentAgency? totalPaymentAgency : 0  | number:'1.2-2' }} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-4 mt-3">
      <div class="col-12 includes-detail" style="background-color: #414141 !important;">
        <div class="row py-1">
          <div class="include-list pt-1 pl-3">
            <p class="font-poppins py-0 my-0 text-white" style="font-size: 18px; font-weight: bold;">TOTAL ALQUILER
          </div>
          <div class="select-include-list">
            <p class="font-poppins py-0 my-0 text-white mr-2" style="font-size: 30px; font-weight: 500;text-align: end">Total: $
              {{ totalPrice? totalPrice : 0 | number:'1.2-2'  }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 mr-4">
      <button style="width: 28%;height: 51px !important;" type="button" class="ml-auto btn btn-sm btn-usblick" (click)="add()"
        [disabled]="totalPrice==0"
        [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
        >CONTINUAR</button>
    </div>

  </div>
</div>
