import { Component, OnInit, Output, Input, ViewContainerRef, EventEmitter } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ItineraryService } from '../../../../shared/itinerary.service';
import { CookieService } from 'ngx-cookie';
import { isValidOwener, fillOwnerMessage } from '../../../../utils/Functions';

@Component({
  selector: 'usb-transport-result',
  templateUrl: './transport-result.component.html',
  styleUrls: ['./transport-result.component.scss'],
  providers: [DatePipe]
})
export class TransportResultComponent implements OnInit {


  
  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() __pax: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;
  @Input() __payloadForm: any;

  public options = {
    position: ['top', 'right'],
    timeOut: 460,
    lastOnBottom: true,
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    maxLength: 60
  };


  constructor(
//    
    private datePipe: DatePipe,
    private itineraryService: ItineraryService,
    private _cookieService: CookieService,
    //public _toastr: ToastsManager
  ) { }

  ngOnInit() {
    console.log("this.pax", this.__pax , this.__payloadForm);
  }

  add() {
    const optionToReserved = this.result.additionalInfo.transfers;
    console.log("this.pax, this.payload['qtyPax']", this.__pax, this.payload['qtyPax'])
    //if (this.pax + this.__payloadForm['qtyPax'] <= this.payload['qtyPax']) {  

    //try {
      if (this.__pax <= this.payload['qtyPax']) {  
        // validar que no se agregue mas servicios que pax
        this.addToItinerary(optionToReserved, this.result);
      } else {
        this.hideModal('error - Error - Item was not added to your itinerary');
      }
      
    //} catch (error) {
      //this.hideModal('error - Error - Item was not added to your itinerary');
    //}
 }


 hideModal(status: string) {
  const stat = status.split(' - ');
  const type = stat[0];
  const title = stat[1];
  const message = stat[2];

  switch (type) {
    case 'success': this.options.timeOut = 360; //this._toastr.success(title, message);
      break;
    case 'error': this.options.timeOut = 1060;  //this._toastr.error(title, message);
      break;
    case 'warning': //this._toastr.warning(title, message);
      break;
  }
}

  addToItinerary(item: any, result: any) {
    // validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      //this.toastr.warning(fillOwnerMessage());
      return;
    }
    item.categoryName = this.origin,
    // item.form = this.form !== undefined ? this.form.value : undefined,
    //item.payload =  this.__payloadForm, // this.payload,
    item.payload =  this.payload,
    item.id = result.id + '-' + item.id,
    item.idProduct = result.productId,
    item.destination = result.destination,
    item.origin = result.origin,
    item.searchId = this.arrayResults[0].searchId,
    item.providerId = result.providerId,
    item.providerName = result.providerName,
    item.title = result.title,
    item.location = result.additionalInfo.location,
    item.arrivalDate = result.dateIni,
    item.departureDate = result.dateEnd,
    item.updateDate = new Date(),
    item.payloadUpdate = this._payloadUpdate(this.payload),

    console.log('Res 0: ', item);
    // se adiciona destination_info 
    item.destination = this.itineraryService.getDestinationInfo(this.form, item);
    console.log('Res 1: ', item);
    this.itineraryService.sendMessageSubscriptorLoading(true);
    this.itineraryService.add(item).subscribe(
      (response:any) => {
        console.log('Product added successfully', response);
        if (typeof response === 'string') {
          //this.toastr.error(response);
        } else {
          //this.toastr.success('Product added successfully to your itinerary');
        }
        this.itineraryService.sendMessageSubscriptorLoading(false);
      },
      error => {
        console.log('Product not added', error);
        this.itineraryService.sendMessageSubscriptorLoading(false);
        //this.toastr.error('An error occurred, product was not added', 'Error');
      }
    );
  }

  _payloadUpdate(payload: any): Object {

    console.log(this.origin);
    return {
      products: [{
        providerId: this.result.providerId,
        nameAdapter: this.result.nameAdapter,
        categoryId: this.result.categoryId,
        productId: this.result.id,
        productName: this.result.title,
        origin: this.result.origin,
        destination: this.result.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.result.dateIni,
        departureDate: this.result.dateEnd,
        qtyProduct: 1,
        qtyPax: 0,
        others: {
          transport: ''
        }
      }]
    };
  }
}