import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
//import { ToastsManager } from 'ng2-toastr';
import { ItineraryService } from '../../itinerary.service';
import { isValidOwener, fillOwnerMessage } from '../../../utils/Functions';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'usb-modal-pthemes',
  templateUrl: './modal-pthemes.component.html',
  styleUrls: ['./modal-pthemes.component.scss']
})
export class ModalPthemesComponent implements OnInit {

  @Input() ptheme: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() itineraryItem?: any;

  @Output() refModal = new EventEmitter<any>();

  max: number;
  rate = 4;
  ratereview = 4.5;
  isReadonly: boolean;
  x = 5;
  y = 2;
  select: any;
  totalQty = 0;
  single: any[];
  view: any[] = [200, 150];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = '.';
  showYAxisLabel = true;
  yAxisLabel = '%';

  _findData: boolean;

  colorScheme = {
    domain: ['#00a680', '#00a680', '#00a680', '#00a680', '#00a680']
  };

  tab: string = '#ticket'
  collapseList: any = [
    {
      'collapseOne': false,
      'collapseTwo': false,
      'collapseThree': false
    },
    {
      'collapseOne': false,
      'collapseTwo': false,
      'collapseThree': false
    }
  ]
  imgSelect: number = 0
  listImg: any = [
    '/assets/img/app/pthemes/01.png',
    '/assets/img/app/pthemes/02.jpg',
    '/assets/img/app/pthemes/03.jpg',
    '/assets/img/app/pthemes/04.jpg',
  ]

  constructor(
    public vcr: ViewContainerRef,
    private _itineraryService: ItineraryService,
    private _cookieService: CookieService, ) {
    //this.toastr.setRootViewContainerRef(this.vcr);
    this.single = [
      {
        'name': 'Excelente',
        'value': 74
      },
      {
        'name': 'Muy Bueno',
        'value': 19
      },
      {
        'name': 'Regular',
        'value': 6
      },
      {
        'name': 'Malo',
        'value': 1
      },
      {
        'name': 'Pésimo',
        'value': 0
      }
    ];
  }


  ngOnInit() {
    console.log("Ticket seleccionado " + this.ptheme);
    console.log("Arreglo de resultados " + this.arrayResults);
  }


  calculateTotalPrice(index: any) {
    console.log("Entre a calculateTotalPrice con index " + index);
    this.inicializedObject();
    if (this.ptheme.additionalInfo.options[index].optionTotalProf) {
      this.totalQty = this.ptheme.additionalInfo.options[index].optionTotalProf;
    }
    this.ptheme.additionalInfo.options[index].reservations = 1;
    this.ptheme.additionalInfo.options[index].reserved = true;
    this.select = index;
  }

  inicializedObject() {
    this.ptheme.additionalInfo.options.forEach(obj => {
      obj.reservations = 0;
      obj.reserved = false;
    });
  }

  continue() {
    this._findData = true;
    console.log('---ModalPThemeComponet--- continue()---');
    const optionToReserved = this.ptheme.additionalInfo.options.filter(obj => obj.reserved === true);
    console.log("Item a agregar " + optionToReserved);
    optionToReserved.forEach(element => {
      this.addItinerary(element, this.ptheme);
    });
    this.closeModal();
  }

  enabledButton(): boolean {
    this.ptheme.additionalInfo.options.forEach(
      obj => {
        if (obj.reserved) {
          return true;
        }
      }
    );
    return false;
  }

  closeModal() {
    this._findData = false;
    this.select = null;
  }


  addItinerary(item: any, ptheme: any, reservation?: any) {
    //validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      // //this.toastr.warning(fillOwnerMessage());
      this._emitRefModal('warning - Warning - ' + fillOwnerMessage());
      
      return;
    }
    console.log('ModalRoomsComponent--- addItinerary()');
    console.log(this.origin);
    console.log(this.arrayResults);
    item.id = ptheme.id + '-' + item.optionId;
    item.searchId = this.arrayResults[0].searchId;
    item.providerId = ptheme.providerId;
    // item.form = this.form.value;
    item.providerName = ptheme.providerName;
    item.ticketId = ptheme.id;
    item.idProduct = ptheme.productId;
    item.categoryName = 'PTHEMES';
    item.title = ptheme.title;
    item.location = ptheme.additionalInfo.location;
    item.arrivalDate = ptheme.dateIni;
    item.departureDate = ptheme.dateEnd;
    item.updateDate = new Date();
    // se agrega el payload de busqueda de producto al objeto room
    console.log('ModalPThemesComponent--- addItinerary()--- form ', this.form);
    if (this.form === null || this.form === undefined) {
      item.payloadUpdate = this.payloadUpdate;
    } else {
      item.payloadUpdate = this._payloadUpdateTicket(this.form);
    }
    //se adiciona destination_info 
    item.destination = this._itineraryService.getDestinationInfo(this.form, item);
    if (this.itineraryItem !== undefined) {
      item.destination = this.itineraryItem.destination;
      this._itineraryService.remove(this.itineraryItem, item);
      this._emitRefModal('success - Congratulations - Product updated succesfully');
    } else {
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(item).subscribe((response:any) => {
        console.log('Add-Success: ', response);
        if (typeof response === 'string') {
          this._emitRefModal('error - Error - ' + response);
        } else {
          this._emitRefModal('success - Congratulations - Product added succesfully to your itinerary');
        }
        this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary['items']);
        this._itineraryService.sendMessageSubscriptorLoading(false);
        if (reservation) {
          this._itineraryService._restService.doPost('app', 'reservations', reservation).subscribe((response:any) => {
            console.log('Reservation-Success: ', response);
          }, error => {
            console.log('Reservation-Error: ', error);
          });
        }
      }, error => {
        console.log('Add-Error: ', error);
        this._emitRefModal('error - Error - Item was not added to your itinerary, please check the console');
        this._itineraryService.sendMessageSubscriptorLoading(false);
      });
    }
  }

  _payloadUpdateTicket(form: FormGroup): Object {
    let qtyPax;
    let others;
    console.log(this.origin);

    others = {
      child: form.value.qtyChilds,
      ages: this.getAgeChild(form)
    };
    qtyPax = form.value.qtyAdults;

    return {
      products: [{
        providerId: this.ptheme.providerId,
        nameAdapter: this.ptheme.nameAdapter,
        categoryId: this.ptheme.categoryId,
        productId: this.ptheme.id,
        productName: this.ptheme.title,
        origin: this.ptheme.origin,
        destination: this.ptheme.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.ptheme.dateIni,
        departureDate: this.ptheme.dateEnd,
        qtyProduct: 0,
        qtyPax: qtyPax,
        others: others
      }]
    };
  }

  getAgeChild(form: any) {
    const arrayChildren: Array<any> = [];
    if (form.value.childsage.length > 0) {
      form.value.childsage.forEach(object => arrayChildren.push(object));
    }
    return {
      int: arrayChildren.map(obj => obj.age)
    };
  }

  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  tabs(idtab){
    this.tab= idtab
  }

  prev(){
    if(this.imgSelect>0){
      this.imgSelect=this.imgSelect-1
    }
    if(this.imgSelect<=5){
      this.scroll('l')
    }
  }

  next(){
    if(this.imgSelect<(this.listImg.length-1)){
      this.imgSelect++
    }
    if(this.imgSelect>=5){
      this.scroll('r')
    }
  }

  scroll(dir){
    let content = document.getElementById('slide-miniatura')
    const stepr = 150
    const stepl = -150
    if(dir=='l'){
      content.scrollLeft += stepl
    }else if(dir=='r'){
      content.scrollLeft += stepr
    }
  }
}
