import { Component, OnInit } from "@angular/core";
import { ItineraryService } from "../shared/itinerary.service";
import { Observable } from "../../../node_modules/rxjs";
import { RestService } from "../shared/rest.service";
import { UserService } from "../pages/com.usblick.common/auth/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { setting } from "../../../setting";

@Component({
  selector: "usb-itinerary-summary",
  templateUrl: "./itinerary-summary.component.html",
  styleUrls: ["./itinerary-summary.component.scss"],
})
export class ItinerarySummaryComponent implements OnInit {
  _actualItinerary: any;
  _flightInfo: any;
  suggestions: any;
  partialReserve: boolean;
  qtyItemsToPartial: number = 0;
  cargoSugg = false;
  itinerary$: Observable<any[]>;
  public getClientsSettingAppBooking: any;
  public sectionSummary: any;
  public routeUploadFile: any;

  constructor(
    private _itineraryService: ItineraryService,
    private restService: RestService,
    public userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }

  ngOnInit() {
    this.routeUploadFile = setting.routeUploadFile;
    this.sectionSummary = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_summary);
    this._initData();
    /* setTimeout(() => {
      if (this._actualItinerary) {
          this.getSuggestionsBig();
       }
     }, 150); */
  }

  getSuggestionsBig() {
    if (this.cargoSugg == false) {
      const url = "suggestionsByID/" + this._actualItinerary.id;
      this.spinner.show();
      setTimeout(() => {
        this.restService.doGet("app", url).subscribe(
          (data: any) => {
            this.suggestions = data.suggestions;
            this.cargoSugg = true;
            this.spinner.hide();
          },
          (error) => {
            console.log("error response", error);
            this.spinner.hide();
          }
        );
      }, 850);
    }
  }

  _initData() {
    if (this.userService.itineraryActive.length > 0) {
      var data = this.userService.itineraryActive;
      if (data) {
        if (data[0]) {
          this._actualItinerary = data[0];
          this._initFlights();
        } else {
          this._actualItinerary = null;
        }
      }
    } else {

      let suscription = (this._itineraryService.activeItinerary as Observable<any>).subscribe(
        (data: any) => {
          if (data) {
            if (data[0]) {
              this._actualItinerary = data[0];
              //console.log( "this._actualItinerary", this._actualItinerary.id);
              this._initFlights();
            } else {
              this._actualItinerary = null;
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
        }
      );
    }
  }

  _initFlights() {
    if(this._actualItinerary["id"] && this._actualItinerary["id"].length > 0) {

      const url = "itinerary/" + this._actualItinerary["id"] + "/fly";
      this._itineraryService._restService.doGet("app", url).subscribe(
        (response: any) => {
          if (response["data"] && response["data"][0]) {
            const flight = response["data"][0];
            this._flightInfo = {
              departureIn: new Date(flight["departureIn"]),
              arrivalIn: new Date(flight["arrivalIn"]),
              airportIn: flight["airportIn"],
              airlineIn: flight["airlineIn"],
              flyNumberIn: flight["flyNumberIn"],
              departureOut: new Date(flight["departureOut"]),
              arrivalOut: new Date(flight["arrivalOut"]),
              airportOut: flight["airportOut"],
              airlineOut: flight["airlineOut"],
              flyNumberOut: flight["flyNumberOut"],
            };
          }
        },
        (error) => {
          console.log("Has no flights associated", error);
        }
      );
    }

  }

  onChange(partialReserve: boolean) {
    this.partialReserve = partialReserve;
  }

  _itemToPartial(qty: number) {
    this.qtyItemsToPartial = qty;
  }

  _resetItemToPartial(qty: number) {
    this.qtyItemsToPartial = qty;
  }
}
