<div class="card card-info d-flex flex-column my-3 " id="{{index}}" style="border: none">
  <div class="card card-info d-flex flex-column" style="border: none">
    <div class="d-flex px-2 pt-2 pb-2">
      <span class="card-info-title flex-column mr-2 pt-1">
        <span>RÉGIMEN</span>
      </span>
      <select class="form-control description-modal" (change)="onChangeBoardName($event.target.value,$event,index)"
        style="width: 315px; text-align: left!important;">
        <option [value]="-1"> Seleccione</option>
        <option *ngFor="let r of roomBoardName; index as i " [value]="i">
          {{r.boardName}}
        </option>
      </select>
      <span class="card-info-title ml-4 mr-2 pt-1">
        <span>CONDICION</span>
      </span>
      <select class="form-control description-modal" (change)="onChangeRateClass($event.target.value,$event,index)"
        style="width: 315px; text-align: left!important;">
        <option [value]="-1"> Seleccione</option>
        <option *ngFor="let r of roomRateClass; index as i " [value]="i">
          <span *ngIf="r.rateClass == 'NR'"> NO REEMBOLSABLE</span>
          <span *ngIf="r.rateClass == 'R'">REEMBOLSABLE</span>
        </option>
      </select>
    </div>
  </div>

  <div class="d-flex px-4 pt-4">
    <div class="flex-column d-flex type-room">
      <span class="card-info-title flex-column mb-2">
        <span>Tipo Habitación</span>
      </span>
      <span class=" flex-column description-modal"
        style="color:#00d196 !important;font-weight: 500 !important;">{{ stingUpperCase(roomSelect.roomType) }}</span>
      <span class="card-info-title flex-column mb-2 mt-2">
        <span>Regimen</span>
      </span>
      <span class=" flex-column description-modal mb-2">
        {{ stingUpperCase(roomSelect.boardName) }}
      </span>
    </div>
    <div class="flex-column px-2 d-flex max-people">
      <span class="card-info-title mb-2">
        <span>Capacity</span>
      </span>
      <span class="description-modal">
        <i class="icon icon-multiple-users-silhouette pr-1" aria-hidden="true"></i> {{roomSelect.maxPax}} Person(s)</span>
      <span class="card-info-title pt-2">
        <span>Amenities</span>
        <div class="row pt-2">
          <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 font-view text-center">
            <i class="i-wifi "></i>
          </div>
          <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 font-view text-center">
            <i class="i-radio"></i>
          </div>
          <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 text-center">
            <i class="i-cafe"></i>
          </div>
          <div class="col-xg-7 col-lg-1 col-md-3 col-sm-12 text-center">
            <i class="i-tv"></i>
          </div>
        </div>
      </span>
    </div>
    <div class="flex-column px-2 d-flex rooms-price">
      <span class="card-info-title mb-2">
        Tarifa Neta
      </span>
      <span class="description-modal" style="color:#00d196 !important;font-weight: 500 !important;" *ngIf="roomSelect.netPriceProf">
        USD ${{roomSelect.netPriceProf | number:'1.2-2'}}
      </span>
      <span class="card-info-title pt-2 mb-2 ">
        <span>Tarifa Total</span>
      </span>
      <span class="description-modal" style="color:#00d196 !important; font-weight: 500 !important;"
        *ngIf="roomSelect.optionNightsNetTotalProf">
        USD ${{roomSelect.optionNightsNetTotalProf | number:'1.2-2'}}
      </span>
    </div>
    <div class="flex-column px-2 d-flex options">
      <span class="card-info-title mb-2">
        <span>Condiciones</span>
      </span>
      <span class="description-modal" aria-hidden="true">
        <i class="fa fa-check"></i>
        <span *ngIf="roomSelect.rateClass == 'NR'"> NO REEMBOLSABLE</span>
        <span *ngIf="roomSelect.rateClass == 'R'"> REEMBOLSABLE</span>
      </span>
    </div>
    <div class=" d-flex  justify-content-center">
      <div class="btn-group" dropdown>
        <div class="card-info-title form-group px-2">
          <label for="elect-romms">
            <span>Select</span>
          </label>
          <br>
          <span class="mb-0">
            <label class="custom-radio-checkbox mb-0">
              <input class="custom-radio-checkbox__input" id="{{roomSelect.roomId}}" value="roomSelect.roomId" type="checkbox"
                [(ngModel)]="qtySelect" (ngModelChange)="selectRoom(roomSelect, index)">
              <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            </label>
          </span>
          <!--- <select class="form-control" id="select-romms" [(ngModel)]="qtySelect" 
          (ngModelChange)="selectRoom(select[index], qtySelect)"  (ngModelChange)="selectRoom(select[index], qtySelect)" name="select-romms"
               [value]="0" >
              <option *ngFor="let qty of select[index].selectArray" [value]="qty">{{qty}}</option>
          </select>  -->

          <div class="d-flex pt-3">
            <img class="img-logo" *ngIf="roomSelect?.providerLogo" src="{{roomSelect.providerLogo}}" alt="logo">
          </div>

          <!--   <select class="form-control" id="select-romms" [(ngModel)]="qtySelect" (ngModelChange)="selectRoom(select[index], qtySelect)" name="select-romms"
            [value]="0" [disabled]="select[index].selectArray.length === 1">
            <option *ngFor="let qty of select[index].selectArray" [value]="qty">{{qty}}</option>
          </select>  -->
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex px-4 mt-1 pt-1 pb-1" style="background-color:black ;">
    <div class="container">
      <div class="row" style="color: white; font-weight: 600;">
        <div class="col-2 pl-1">
          TOTAL
        </div>
        <div class="col-8">
        </div>
        <div class="col-2 pl-4">
          USD ${{roomSelect.optionNightsNetTotalProf | number:'1.2-2'}}
        </div>
      </div>

    </div>
  </div>

  <!-- <div class="md-accordion-custom p-4">
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            DESCRIPCIÓN
          </mat-panel-title>
          <mat-panel-description class="m-0">
            &nbsp;
            <mat-icon x-small class="white-icon">format_list_bulleted</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
    
        <p class="text-expand mt-3">
          {{roomSelect.description}}
        </p>

        <div class="d-flex mt-3" style="border-bottom: 0.48px solid #e0e0e0; height:175px;">
          <div class="d-flex justify-content-around align-items-start flex-row w-100" style="width: 100%;">
            <div class="d-flex flex-row flex-wrap w-100">
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Pisos</p>
                <img class="i-description" src="/assets/img/app/modal/edificio.svg" alt="">
                <p class="m-0 subtitle-item">20</p>
              </div>
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Habitaciones</p>
                <img class="i-description" src="/assets/img/app/modal/cama.svg" alt="">
                <p class="m-0 subtitle-item">150</p>
              </div>
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Años</p>
                <img class="i-description" src="/assets/img/app/modal/calendar.svg" alt="">
                <p class="m-0 subtitle-item">10</p>
              </div>
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Restaurant</p>
                <img class="i-description" src="/assets/img/app/modal/resto.svg" alt="">
                <p class="m-0 subtitle-item">3</p>
              </div>
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Niños</p>
                <img class="i-description" src="/assets/img/app/modal/child.svg" alt="">
                <p class="m-0 subtitle-item">SI</p>
              </div>
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Mascotas</p>
                <img class="i-description" src="/assets/img/app/modal/perro.svg" alt="">
                <p class="m-0 subtitle-item">SI</p>
              </div>
              <div class="d-flex flex-column service">
                <p class="m-0 title-item">Fumador</p>
                <img class="i-description" src="/assets/img/app/modal/no-fumar.svg" alt="">
                <p class="m-0 subtitle-item">NO</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-3 mb-5" >
          <div class="d-flex flex-column w-100">
            <div class="d-flex flex-column w-100">
              <p class="m-0 title-item">Disponible en el hotel</p>
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/wifi.svg" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1">WIFI</p>
                </div>
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/tv.svg" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1">TV</p>
                </div>
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/piscina.svg" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1">Piscina</p>
                </div>
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/ac.png" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1">Aire Acond.</p>
                </div>
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/cafe.svg" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1">Café</p>
                </div>
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/tel.svg" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1">Teléfono</p>
                </div>
                <div class="d-flex align-items-end">
                  <img class="i-description m-0 mr-2" src="/assets/img/app/modal/parking.svg" alt="">
                  <p class="m-0 subtitle-item p-0 pb-1 p-0 pb-1">Parking</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            POLÍTICAS DE CANCELACIÓN Y PENALIDADES
          </mat-panel-title>
          <mat-panel-description class="m-0">
            &nbsp;            
            <mat-icon x-small class="white-icon">not_interested</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <p class="title-itemmb-1 mt-3 font-weight-bold">{{ roomSelect.cancellation_policy.descript_polecy}}</p>
        <div class="d-flex mb-2">
          <div class="d-flex align-items-center" style="width: 100%;">
            <img style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
            <p class="text-desc m-0 font-weight-bold color-green">Hasta las {{ roomSelect.cancellation_policy.from  | date:'dd/mm/yyyy h:m' }}</p>            
          </div>
          <div class="d-flex align-items-center" style="width: 30%;">
            <p class="text-desc m-0 font-weight-bold color-green">USD $ {{roomSelect.cancellation_policy.amount | number:'1.2-2'}}</p>
          </div>          
        </div>
        <div class="d-flex mb-2">
          <div class="d-flex align-items-center" style="width: 100%;">
            <img style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
            <p class="text-desc m-0">Despues las 23:59 del 09/10/2021</p>            
          </div>
          <div class="d-flex align-items-center" style="width: 30%;">
            <p class="text-desc m-0 font-weight-bold color-red">120,33 - USD</p>
          </div>          
        </div>
        <div class="d-flex mb-5">
          <div class="d-flex align-items-center" style="width: 100%;">
            <img style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
            <p class="text-desc m-0">Cancelando no show</p>            
          </div>
          <div class="d-flex align-items-center" style="width: 30%;">
            <p class="text-desc m-0 font-weight-bold">120,3 - USD</p>
          </div>          
        </div> 
    
      </mat-expansion-panel>
      <mat-expansion-panel id="last-ac" [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            IMAGENES
          </mat-panel-title>
          <mat-panel-description class="m-0">
            &nbsp;
            <mat-icon x-small class="white-icon">photo_camera</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="mt-4 mb-5">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center content-imgSelect">
              <img [src]="images[imgSelect]?.image" class="img-gallery d-block" alt="">
            </div>
          </div>
          <div class="d-flex flex-column">            
            <div class="d-flex pt-2 content-miniatura" *ngIf="images">
            
              <i class="scroll-left" *ngIf="images.length > 5" (click)="scroll('l')"></i>
      
              <div class="d-flex justify-content-between slide-miniatura" id="slide-miniatura" >
                <img [src]="img.image" class="img-miniatura mr-3" *ngFor="let img of images; index as j"
                  [ngClass]="{selectedImg: imgSelect==j}" (click)="imgSelect=j">
              </div>
      
     
              <i class="scroll-right" *ngIf="images.length > 5" (click)="scroll('r')"></i>
            </div>
            <div class="d-flex justify-content-center py-4">
              <span class="font-poppins pagination-img">
                {{imgSelect+1 +' of '+ images.length}}
              </span>
              <a class="ml-5 pagination-btn" (click)="prev()" title="previous">
                <i class="i-arrow-left" *ngIf="imgSelect>0"></i>
                <i class="i-arrow-left-gray" *ngIf="imgSelect==0"></i>
              </a>
              <a class="ml-5 pagination-btn" (click)="next()" title="next">
                <i class="i-arrow-right" *ngIf="imgSelect<(images.length-1)"></i>
                <i class="i-arrow-right-gray" *ngIf="imgSelect==(images.length-1)"></i>
              </a>
            </div>
          </div>
        </div> 
      </mat-expansion-panel>
    </mat-accordion>
  </div> -->
</div>
