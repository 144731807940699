import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'orderBy'})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], orderBy: any, asc: boolean = true) {
    if (!orderBy || orderBy.trim() === '') {
      return array;
    }
    if (asc) {
      return array.sort((a: any, b: any) => {
        return this.orderByComparator(a[orderBy], b[orderBy]);
      });
    } else {
      return array.sort((a: any, b: any) => {
        return this.orderByComparator(b[orderBy], a[orderBy]);
      });
    }
  }
  orderByComparator(a: any, b: any): number {
    console.log('que es a: ',a);
    if(a===undefined || b===undefined){
      return 0;
    }
    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }
    return 0;
  }
}

@Pipe({name: 'groupBy'})
export class GroupByPipe implements PipeTransform {
    transform(collection: Array<any>, property: string): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if(!collection) {
            return null;
        }

        const groupedCollection = collection.reduce((previous, current)=> {
            if(!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }

            return previous;
        }, {});

        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }
}