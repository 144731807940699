import { Component, OnInit, Input, ViewContainerRef, ElementRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { RestService } from '../../../../shared/rest.service';
import { ResultsSearchService } from '../../../results-search.service';
import { ActivitiesService } from './activitiesMockup.service';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap';
import { CustomValidator } from '../../../../shared/customValidators';

import { searchPayload } from '../../../../utils/search-functions';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';
import { UbicacionesService } from '../../service/ubicaciones.service';


// nuevo
import { MaterialModalConfirmComponent } from '../../../../shared/material-modal-confirm/material-modal-confirm.component';
import { MaterialModalAlertComponent } from '../../../../shared/material-modal-alert/material-modal-alert.component';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { setting } from '../../../../../../setting';

@Component({
    selector: 'usb-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss'],
    providers: [UbicacionesService, ActivitiesService]
})
export class ActivitiesComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() _close: boolean;
    @Output() payload = new EventEmitter<any>();
    @Output() results = new EventEmitter<any>();
    @Output() isLoading = new EventEmitter<boolean>();
    @Output() origin = new EventEmitter<any>();
    @Output() modal = new EventEmitter<string>();
    @Input() tabName:string = '';


    @ViewChild('dpDeparture') datepickerDeparture: BsDatepickerDirective;
    @ViewChild('dpArrival') datepickerArrival: BsDatepickerDirective;
    @ViewChild("destination") myInputField: ElementRef;

    _findData: boolean;
    _form: FormGroup; // Form Group Activities
    _maxPersons: any; // : [0,1,2,3,4,5];
    minDateDeparture: Date;
    maxDateArrival;

    dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    isCollapsed = false;
    selectedDestiny: any;
    _longDestination: string;
    destiny: any;
    passengers: string;
    _resultsQuery: any;
    bsConfig: object;
    isLoading_ = false;
    valueQtyChildTour:number = 0;
    


    ///  nuevo
    _maxAdults: any;
    _maxChildren: any;
    dateRange: any = { start: null, end: null };
    minDate: dayjs.Dayjs = dayjs().subtract(0, "days");
    maxDate: dayjs.Dayjs = dayjs().add(12, "month");
    destinationOptions: Observable<any[]>;
    destinationOptions_: Observable<any[]>;
    getClientsSettingAppBooking: any;
    bgClientColor: string;
    isHoverConfirm: boolean;
    agencyId:any;





    constructor(
        private fb: FormBuilder,
        private _routeActive: ActivatedRoute,
        private _router: Router,
        private _restService: RestService,
        private ubicacionesService: UbicacionesService,
        private _searchService: ResultsSearchService,
        private _mockup: ActivitiesService,
        private dialog: MatDialog,
        public userService: UserService,
    ) {
        this._maxPersons = Array.from(Array(26).keys());
        this.dpConfig.containerClass = 'theme-red';
        // nuevo
        this._maxAdults = Array.from({ length: 10 }, (_, i) => i + 1);
        this._maxChildren = Array.from(Array(11).keys());
        this.minDateDeparture = new Date();
        this.maxDateArrival = new Date();
        this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
        this.bgClientColor = this.getClientsSettingAppBooking?.clients_setting_global?.color_main_1;
        let agency = JSON.parse(localStorage.getItem("selectedAgency"));
        if(agency) {
        console.log('agency', agency);
        this.agencyId = agency.agency_id;
    }
    }

    ngOnInit(): void {
        this.passengers = "Passengers";
        this._form = this.fb.group({
            destination: [null, Validators.compose([Validators.required, CustomValidator.countryValidator])],
            arrivalDateDepartureDate: ["", Validators.compose([Validators.required, CustomValidator.countryValidator])],
            qtyAdults: [1, [Validators.required, Validators.min(1)]],
            qtyChilds: [0, Validators.required],
            childsage: this.fb.array([]),
        });

       // nuevo
        this.dateRange = "";
        this._form.get("destination").valueChanges.subscribe((selectedValue) => {
          if (selectedValue) {
            this.isLoading_ = true;
            this.destinationOptions = null;
            this.destinationOptions_ = null;
            this.destinationOptions = this.ubicacionesService.getCitys(selectedValue);
            this.destinationOptions_ = this.ubicacionesService.getAirports(selectedValue);
            this.isLoading_ = false;
          } else {
            this.destinationOptions = this.ubicacionesService.getDestinations('something else');
          }
        });
}

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {
        setTimeout(() => {
            this.myInputField.nativeElement.focus();
        }, 500);
    }

    formArray(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }


    _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
        const _payload = [];
        console.log("TOURS", this.dateRange, form, this.selectedDestiny);
        if (this.selectedDestiny !== undefined && this.selectedDestiny !== null) {
          if (event) {
            event.preventDefault();
          }
          const payload = searchPayload("TOURS", this.dateRange, form, this.selectedDestiny, '', '', null,'', this.agencyId);
          console.log("payload", payload);
          console.log("form", JSON.stringify(form.value));
          localStorage.setItem("form", JSON.stringify(form.value));
          if (localStorage.getItem("payload")) {
            localStorage.removeItem("payload");
          }

          _payload[0] = payload;
          localStorage.setItem("payload", JSON.stringify(_payload));
          this._emitLoading(true);
          this._restService.doPost("app", "search", dummy || payload).subscribe(
            (response: any) => {
              if (response !== null && response.results !== null) {
                console.log("responseInit", response);
                const payloadArray = [searchPayload("TOURS", this.dateRange, form, this.selectedDestiny, this.agencyId)];
                console.log("payloadArray", payloadArray);
                this.payload.emit(payloadArray);
             //   this._colocarSearchIdEnlosHoteles(response);
                this._setArrivalAndDepartureDate(response, form);
                this._handleResponse(response, "TOURS", form);
                this._emitOrigin("TOURS");
                this._emitLoading(false);
                this._router.navigate(["/results"]);
              } else {
                const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
                  height: "auto",
                  width: "350px",
                  data: "No se encontraron Resultados",
                  disableClose: true,
                });
              }
            },
            (error) => {
              const message = "Ocurrió un error al intentar encontrar productos. Inténtalo de nuevo";
              this._emitLoading(false);
              const dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
                width: "465px",
                data: {message:message},
                disableClose: true,
              });
              dialogRef.afterClosed().subscribe((result) => {
                console.log("The dialog was closed", result);
                if (result) {
                  this._onSubmit(form, event);
                } else {
                  this._router.navigate(["/home"]);
                }
              });
            }
          );
        } else {
          this._form.get("destination").setErrors({ invalidDestination: true });
        }


    }

    _handleResponse(response: any, categoryName: string, form: FormGroup) {
        if (response && response.results != null) {
            try {
                this._resultsQuery = response.results;
                this._resultsQuery.categoryName = categoryName;
                this._resultsQuery.arrivalDate = form.value['arrivalDate'];
                this._resultsQuery.destination = this._longDestination;

                if (!response.results.length) {
                    this._resultsQuery.noResultMessage = 'No results found for the search';
                }
                // se anexa el formulario actual
                this._resultsQuery.form = form;
                const arrayResults = [this._resultsQuery];
                this.results.emit(arrayResults);
            } catch (e) {
                this._resultsQuery = [];
                this._restService.page = 1;
            }
        } else {
            this._resultsQuery = [];
            this._resultsQuery.noResultMessage = 'No results found for the search';
            this.results.emit(this._resultsQuery);
        }
    }

    _setSearchIdInResults(response: any) {
        response.results.forEach(obj => {
            obj.searchId = response.searchId;
        });
    }


    _emitOrigin(origin: any) {
        this.origin.emit(origin);
    }

    _addChild() {
      this.valueQtyChildTour = this._form.value['qtyChilds'];
        setTimeout(() => {
            const ages: any = this._form.get('childsage') as FormArray;
            const qty: number = +this._form.value['qtyChilds'];
            if (qty > ages.length) {
                for (let i = ages.length; i < qty; i++) {
                    ages.push(this.fb.group({
                        id: [i],
                        age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
                    }));
                }
            } else {
                for (let i = ages.length - 1; i >= qty; i--) {
                    ages.removeAt(i);
                }
            }
        }, 10);
    }

    _emitLoading(isLoading: boolean) {
        this._findData = isLoading;
        this.isLoading.emit(isLoading);
    }


    /// nuevo

    _setArrivalAndDepartureDate(response: any, form) {

        response.results.forEach((obj) => {
          obj.dateIni = moment(this.dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD");
          obj.dateEnd = moment(this.dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD");
        });
      }

    startDateClicked($event) {
        //  this.maxDate = moment($event.startDate._d).add(1, 'month');
    }
    endDateClicked($event) {
        //  this.minDate = moment($event.endDate._d).subtract(1, 'month');
    }

    /***********************************************Auto Complete********************************************/

    capitalize(val: any) {
      // console.log('value tours', val);
      return val
        .toLowerCase()
        .trim()
        .split(" ")
        .map((v) => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    }

    observableSource = (keyword: any): Observable<any[]> => {
      return this.ubicacionesService.getCitys(keyword);
    }

    displayFn(name: any) {
      if (name) {
        if (name.city_name != undefined) {
          return name.city_name;
        }
        if (name.name_airport != undefined) {
          return name.name_airport;
        }
      }
    }
    updateMySelection(event: any) {
      if (event) {
        this.destiny = event.city_name ? event.city_name : "";
        this.selectedDestiny = event.city_iata_code ?  event.city_iata_code: "";
        this._longDestination = event.city_name ? event.city_name : "";
        /// this.destiny['cityName'] =  event.cityName;
        this.selectedDestiny = event.city_iata_code ?  event.city_iata_code : "";
      }
    }
    /***********************************************Auto Complete********************************************/
    _over() {
        this.modal.emit('false');
    }

    clickOutside(event: any) {
        this.setPassanger();
    }

    _openModal() {
        this.isCollapsed = true;
        this._close = false;
        setTimeout(() => {
            this.modal.emit('true');
        }, 100);
    }

    _confirm() {
        this.isCollapsed = false;
        this.setPassanger();
    }

    setPassanger() {
        let adults = this._form.value['qtyAdults'];
        let childs = this._form.value['qtyChilds'];
        let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
        adults = adults != undefined ? adults + " Adultos " : null;
        childs = childs != undefined ? " - " + childs + " Niños " : null;

        this.passengers = noSelected ? "Passengers" : adults + childs;
    }

    setOptions(tipo:String): void {
        if(tipo!='departure'){
            this.bsConfig = Object.assign({}, {maxDate: this.maxDateArrival});
            this.datepickerArrival.setConfig();
            return;
        }

        this.bsConfig = Object.assign({}, {minDate: this.minDateDeparture});
        //this.datepickerDeparture.setConfig();
    }

    updateButtonColorConfirm() {
      const button = document.querySelector('.btn-choice-confirm') as HTMLElement;
      if (this.isHoverConfirm) {
        button.style.backgroundColor = this.bgClientColor;
      } else {
        button.style.backgroundColor = 'white';
      }
    }

}


