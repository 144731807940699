import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../material-modal-confirm/material-modal-confirm.component';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'usb-material-modal-alert',
  templateUrl: './material-modal-alert.component.html',
  styleUrls: ['./material-modal-alert.component.scss']
})
export class MaterialModalAlertComponent implements OnInit {

  public confirmResult: Subject<boolean> = new Subject<boolean>();

  newModal: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MaterialModalConfirmComponent>,
    public userService: UserService,
    public bsModalRef: BsModalRef
  )
  { }

  ngOnInit() {
    console.log("recibi de modal", this.data);
    if(this.data === 'No se encontraron datos, para los parámetros suministrados') {
      this.newModal = true;
    }
  }

  confirm(): void {
    this.confirmResult.next(true);
    this.bsModalRef.hide();
  }


}
