import { Component, OnInit, EventEmitter, ViewContainerRef, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { TimepickerModule } from 'ngx-bootstrap';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../../shared/rest.service';
import { UbicacionesService } from '../../service/ubicaciones.service';
import { Observable } from 'rxjs/Observable';
import { searchPayload } from '../../../../utils/search-functions';
import * as moment from 'moment';
//import { ToastsManager } from 'ng2-toastr';
import { BsDatepickerConfig, BsDatepickerModule, BsDatepickerDirective} from 'ngx-bootstrap';
import { PACKAGES } from '../../../../home/search-box/categories/packages/response/searchResponsepackages';


@Component({
  selector: 'usb-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  _form: FormGroup; // Form Group Activities
  _maxPersons: any; // : [0,1,2,3,4,5];
  isCollapsed = false;
  ismeridian = false;
  validate = false;
  passengers: string;
  selectedDestiny: any;
  _resultsQuery: any;
  _longDestination: string;
  destiny: any;
  roomsAndPassengers: string;
  minDateDeparture: Date;
  maxDateArrival: Date;
  bsConfig: object;
  _findData: boolean;
  minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Output() origin = new EventEmitter<any>();
  @Input() _close: boolean;
  @Output() modal = new EventEmitter<string>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  
  @ViewChild('dpDeparture') datepickerDeparture: BsDatepickerDirective;
  @ViewChild('dpArrival') datepickerArrival: BsDatepickerDirective;

  @ViewChild("destination") myInputField: ElementRef;

  public months = [
    { id: 1, name: 'Jan' }, { id: 2, name: 'Feb' }, { id: 3, name: 'Mar' }, { id: 4, name: 'Apr' }, { id: 5, name: 'May' },
    { id: 6, name: 'Jun' }, { id: 7, name: 'Jul' }, { id: 8, name: 'Aug' }, { id: 9, name: 'Sep' }, { id: 10, name: 'Oct' },
    { id: 11, name: 'Nov' }, { id: 12, name: 'Dec' }];

  selectedMonthsId: any;

  public odestinations = [
    { id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }];

  selectedOdestinationsId: any;

  constructor(
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private _router: Router,
    private _restService: RestService,
    private ubicacionesService: UbicacionesService,
    public vcr: ViewContainerRef,
    //public toastr: ToastsManager
    ) {
    this._maxPersons = Array.from(Array(26).keys());
    this.minDate = new Date();
    this.minDateDeparture = new Date();
    this.maxDateArrival = new Date();
    this.maxDateArrival.setDate(this.minDate.getDate() + 1095);

    this.dpConfig.containerClass = 'theme-red';
    //this.toastr.setRootViewContainerRef(this.vcr);
  }

  ngOnInit() {
    this._form = this.fb.group({
      // Search basic
      origin: ['', Validators.required],
      destination: ['', Validators.required],
      arrivalDate: [null, Validators.compose([Validators.required,
        (c: FormControl) => {
            if (
                this._form !== undefined &&
                c.value !== null &&
                this._form.get('departureDate').value !== null &&
                this._form.get('departureDate').value !== undefined
            ) {
                const valid = (c.value < this._form.get('departureDate').value) ? null : { invalidDate: true };
                this._form.controls['departureDate'].setErrors(valid);
                return (c.value < this._form.get('departureDate').value) ? null : { invalidDate: true };
            }
        }
        ])],
        departureDate: [null, Validators.compose([Validators.required,
        (c: FormControl) => {
            if (
                this._form !== undefined &&
                c.value !== null &&
                this._form.get('departureDate').value !== null &&
                this._form.get('departureDate').value !== undefined
            ) {
                const valid = (c.value > this._form.get('arrivalDate').value) ? null : { invalidDate: true };
                this._form.controls['arrivalDate'].setErrors(valid);
                return (c.value > this._form.get('arrivalDate').value) ? null : { invalidDate: true };
            }
        }
        ])],
      room: this.fb.array([]),
      qtyDestination: [''],
      othersDestinations: this.fb.array([]),
    });
    this.passengers = "Pasajeros";
    this._addRoom();
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.myInputField.nativeElement.focus();
    }, 500);
  }
  
  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    if (event) {
      event.preventDefault();
    }

    const payload = searchPayload('PACKAGES', '', form, form.value['destination']['cityIataCode'], form.value['origin']['cityIataCode']);

    this._emitLoading(true);
    // se comenta temporal 12/10/2020 
   /* this._restService.doPost('app', 'search', dummy || payload).subscribe(
        (response:any) => {
            const payloadArray = [searchPayload('PACKAGES', form, form.value['destination']['cityName'], form.value['origin']['cityName'])];
            this.payload.emit(payloadArray);
            if (response !== null && response.results !== null) {
                this._colocarSearchIdEnlosHoteles(response);
                this._setArrivalAndDepartureDate(response, form);
            }
            this._handleResponse(response, 'PACKAGES', form);
            this._emitOrigin('PACKAGES');
            this._emitLoading(false);
            this.results.emit(PACKAGES);   /// temporal hasta q exista resultados reales
            this._router.navigate(['/results']);

        }, error => {
            this._emitLoading(false);
            //this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
        }
    );*/
// fin comentado
    const payloadArray = [searchPayload('PACKAGES','', form, form.value['destination']['cityName'], form.value['origin']['cityName'])];
    this.payload.emit(payloadArray);
    this._emitOrigin('PACKAGES');
    this._emitLoading(false);
    this.results.emit(PACKAGES);   /// temporal hasta q exista resultados reales
    this._router.navigate(['/results']);
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  formArray(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }

  _addRoom() {
    const room = this._form.get('room') as FormArray;
    room.push(this.fb.group({
      qtyAdults: [0],
      qtyChilds: [0],
      childsage: this.fb.array([]),
    }));
  }

  _removeRoom(index: number) {
    const room = this._form.get('room') as FormArray;
    room.removeAt(index);
  }

  _getAdultsChildrenQty(key: string, room: Array<any>) {
    let qty = 0;
    key === 'qtyAdults' ? room.map(obj => obj.qtyAdults).forEach(element => qty += +element)
      : room.map(obj => obj.qtyChilds).forEach(element => qty += +element);
    return qty;
  }

  _addOtherDestinations() {
    setTimeout(() => {
      const destinations: any = this._form.get('othersDestinations') as FormArray;
      const qty: number = +this._form.value['qtyDestination'];
      if (qty > destinations.length) {
        for (let i = destinations.length; i < qty; i++) {
          destinations.push(this.fb.group({
            id: [i],
            destination: ['', Validators.required],
          }));
        }
      } else {
        for (let i = destinations.length - 1; i >= qty; i--) {
          destinations.removeAt(i);
        }
      }
    }, 10);
  }


  _addChild(item: FormGroup) {
    setTimeout(() => {
      const ages: any = item.get('childsage') as FormArray;
      const qty: number = +item.value['qtyChilds'];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  /***********************************************Auto Complete********************************************/
  _myListFormatter(data: any): string {
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _myCallbackOrigin(data: any): string {
    this._longDestination = this._form.value['destination']['cityName'];
    this.selectedDestiny = this._form.value['origin']['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _myCallbackDestiny(data: any): string {
    this._longDestination = this._form.value['origin']['cityName'];
    this.selectedDestiny = this._form.value['origin']['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }



  
  observableSource = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getCitys(keyword);
  }
  /***********************************************Auto Complete********************************************/

  _openModal() {
    this.isCollapsed = true;
    this.validate = false;
    this._close = false;
    setTimeout(() => {
      this.modal.emit('true');
    }, 100);
  }
  _over() {
    this.modal.emit('false');
  }

  _confirm() {
    this.isCollapsed = false;
    this.validate = true;
    this.setPassanger();
  }

  clickOutside(event: any) {
    this.setPassanger();
  }

  setPassanger() {
    let adults = this._form.value['room'][0]['qtyAdults'];
    let childs = this._form.value['room'][0]['qtyChilds'];
    let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
    adults = adults != undefined ? adults + " Adultos " : null;
    childs = childs != undefined ? " - " + childs + " Niños " : null;

    this.passengers = noSelected ? "Pasajeros" : adults + childs;
  }
  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
        try {
            this._resultsQuery = response.results;
            this._resultsQuery.categoryName = categoryName;
            this._resultsQuery.arrivalDate = form.value['arrivalDate'];
            this._resultsQuery.destination = this._longDestination;

            if (!response.results.length) {
                this._resultsQuery.noResultMessage = 'No results found for the search';
            }
            // se anexa el formulario actual
            this._resultsQuery.form = form;
            const arrayResults = [this._resultsQuery];
            this.results.emit(arrayResults);
        } catch (e) {
            this._resultsQuery = [];
            this._restService.page = 1;
        }
    } else {
        this._resultsQuery = [];
        this._resultsQuery.noResultMessage = 'No results found for the search';
        this.results.emit(this._resultsQuery);
    }
}

_colocarSearchIdEnlosHoteles(response: any) {
  response.results.forEach(obj => {
      obj.searchId = response.searchId;
  });
}

_setArrivalAndDepartureDate(response: any, form) {
  response.results.forEach(obj => {
      obj.dateIni = moment(form.value['arrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
      obj.dateEnd = moment(form.value['departureDate'].toString().split(',')[0]).format('YYYY-MM-DD');
  });
}

_emitLoading(isLoading: boolean) {
  this._findData = isLoading;
  this.isLoading.emit(isLoading);
}

async setDepartureDate(event: any){
  if(event!==null){
    this.minDateDeparture = await new Date(event);
    await this.setOptions();
    //this.datepickerDeparture.toggle();

      setTimeout(() => {
        this.datepickerDeparture.toggle();
    }, 200);
      return;
  }
  //this.minDateDeparture = new Date();
}

setArrivalDate(event: any){
  if(event!==null){
      this.maxDateArrival = new Date(event);
      this.setOptions('arrival');
      return;
  }
  //this.maxDateArrival = new Date();
}

setOptions(tipo:String = 'departure'): void {
  if(tipo!='departure'){
      this.bsConfig = Object.assign({}, {maxDate: this.maxDateArrival});
      //this.datepickerArrival.setConfig();    
      return;
  }
  this.bsConfig = Object.assign({}, {minDate: this.minDateDeparture});
  //this.datepickerDeparture.setConfig();
}

}
