import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.scss']
})
export class FlightComponent implements OnInit {

  @Input() _flightInfo: any;
  @Input() _start: number;
  constructor() { }

  ngOnInit() {
  console.log('flightInfo', this._flightInfo); //this._flightInfo['departureDate']
 }
}