import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { setting } from '../../../../setting';
import { UsblickCommonEnum } from 'app/pages/com.usblick.common/usblick-common-enum';
import { environment } from 'environments/environment';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private headersSubject = new BehaviorSubject<HttpHeaders | null>(null);
  public apiBookingUrl: string;

  constructor(private _http: HttpClient, private userService: UserService) {
                                            this.apiBookingUrl = setting.apiBookingUrl;
                                         }

  listToModulesUser(): Observable<any>{
    return this.getHeaders().pipe(
        switchMap(headers =>
                            this
                            ._http.get<any>(
                                                `${this.apiBookingUrl}application/listToModulesUser`,
                                                { headers }
                                                )
                            .map(data => data.data.mod)
      )
    );
  }

  public getHeaders(): Observable<HttpHeaders> {
    return new Observable((observer) => {
      const authToken = this.getCookie('auth_token');
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('client-id', setting.client_id);
      headers = headers.set('client-secret', setting.client_secret);
      headers = headers.set('Authorization', 'Bearer ' + authToken);
      observer.next(headers);
      observer.complete();
    });
  }

  public getHeadersObservable(): Observable<HttpHeaders> {
    return this.headersSubject.asObservable();
  }

  _getAgent(): Observable<any> { ;
    let url = setting.apiBookingUrl;
    let headers = new HttpHeaders();

    headers.append("Authorization", "Bearer " + this.getCookie("auth_token"));
    headers.append("Content-Type", "application/json");
    return this._http
      .get(url + "user/current")
      // .catch(this.handleError)
      // .subscribe(
      //   (Response) => {
      //     console.log(Response)
      //     this.userService._profile = Response["det"]["profile"];
      //     this.userService._name = Response["det"]["first_name"] + " " + Response["det"]["last_name"];
      //     this.userService._email = Response["det"]["email"];
      //     this.userService._agentID = Response["det"]["id"];
      //     // console.log(Response)
      //     this.getAgencyName(this.userService._agentID);
      //   },
      //   (Error) => {
      //     console.log("Error: ", Error);
      //     window.location.href = this.appUrl;
      //   }
      // );
      // console.log(this.userService._agentID)
  }

  	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	public getCookie(name: string){
		const nameEQ = encodeURIComponent(name) + UsblickCommonEnum.equalChar;
		const ca = document.cookie.split(UsblickCommonEnum.dotCommaChar);
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === UsblickCommonEnum.spaceChar)
						{c = c.substring(1, c.length);}
				if (c.indexOf(nameEQ) === 0)
						{return decodeURIComponent(c.substring(nameEQ.length, c.length));}
		}
		return null;
    }

    getProfiles(): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('Authorization', 'Bearer ' + this.getCookie('auth_token'));
      console.log(headers);
      console.log(`${this.apiBookingUrl}profile/list`, { headers: headers});
      return this._http.get<any>(`${this.apiBookingUrl}profile/list`, { headers: headers}).
      pipe(map(res => res.data));
  }

  getAgencyName(agentId: any): Observable<any> {
    let url = setting.apiBookingUrl;

    const headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("client-id", environment.client_id);
    headers.append("client-secret", environment.client_secret);
    headers.append("Authorization", "Bearer " + this.getCookie("auth_token"));
    return this._http
      .get<any>(url + "agents/" + agentId, { headers: headers })
      .map((response: any) => response)
      //.catch(this.handleError)
      // .subscribe(
      //   (response: any) => {
      //     console.log('response del agencyName', response.data[0]);
      //     this.userService.userActive = response.data[0];
      //     this.userService.activeUserSubject.next(response);
      //     this.userService._imagen_logotype = response.data[0].imagen_logotype;
      //     this.userService._channel_id = response.data[0].channel_id;
      //     this.userService._licenseId = response.data[0].license;
      //     this.userService._marketId = response.data[0].location_agency_id;
      //   },
      //   (error) => {
      //     console.log(error);
      //   }
      // );
  }

  public getCurrencyConfig(id: number): Observable<string[]> {
    return this._http.get<string[]>(`${this.apiBookingUrl}typeCurrency/list/${id}`)
    .pipe(map(res => res['data']));
  }

}
