<div class="container-fluid">
    <div class="row" style="background-color: black; position: relative;">
        <div class="col col-xg-12 col-lg-12 col-md-12 col-sm-12 p-0">
                  <!-- <img loading="lazy"   class="img-responsive video-imag" src="assets/img/app/bg-video.jpg" onclick="playVid()" />
                    <a> </a>-->
            <video id="video" class="embed-responsive-item"  controls="controls" poster="assets/img/app/shortcuts/imgpsh_fullsize_anim.png">
              <img loading="lazy" src="../../../assets/video/play.png" class="btn-play" alt="">
               <source [src]="videoRoute" type="video/mp4">
              <!--    <source src="http://www.gossettmktg.com/video/dangot.ogv" type="video/ogg">
                <source src="images/webm_video.webm" type="video/webm"> -->
                <object type="application/x-shockwave-flash" data="https://releases.flowplayer.org/swf/flowplayer-3.2.1.swf" title="image-video-commercial" width="100%" height="100%">
                    <param name="movie" value="https://releases.flowplayer.org/swf/flowplayer-3.2.1.swf">
                    <param name="allowFullScreen" value="true">		<param name="wmode" value="transparent">
                    <param name="flashVars" value="config={'playlist':['http%3A%2F%2Fwww.gossettmktg.com%2Fvideo%2Fdangot.png',{'url':'http%3A%2F%2Fwww.gossettmktg.com%2Fvideo%2Fdangot.mp4','autoPlay':false}]}">
                    <!-- <img loading="lazy" alt="Big Buck Bunny" src="http://www.gossettmktg.com/video/dangot.png" width="100%" height="100%" title="No video playback capabilities, please download the video below">-->
                </object>
            </video>
        </div>

        <!-- <div id=note style=" margin-top: 0px !important;"
          class="align-self-start container align-self-sm-center mt-0 col-xg-4 col-lg-4 col-md-9 col-sm-9 align-content-end align-right align-items-end"
          [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
           <usb-note></usb-note>
        </div> -->
    </div>
</div>
