import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';
//import { Event } from '@angular/router/src/events';


@Component({
  selector: 'usb-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {


  @Input() vehicle: any;
  @Input() select: any[]; // : [0,1,2,3,4,5];
  @Input() index: number;
  @Input() selected: any[];
  @Input() productQty: number;
  @Input() reserveMultiHab: boolean;
  //@Input() toastr: ToastsManager;
  @Output() selectRoom = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log('vehiculo ' + this.vehicle);
  }

  /**
   * La funcion se encarga de evaluar la cantidad que se pide.
   * Se le manda 1 para que los demas select aparezcan con 1 elemento que sera el 0.
   * @param select Recibe el elemento colocado por el usuario al seleccionar la cantidad.
   */
 /* public selectRoom(select: any, qty: any) {
    if (!this.reserveMultiHab && this.select.some(obj => obj.selectValue > 0) && !(select.selectValue > 0)) {
      //this.toastr.warning('This hotel does not support the selection of several vehicle types.');
    }
    select.selectValue = qty;
    this.addRoomToSelect();
  }*/
  sendRoom(vehicle: any){
    console.log('emitted', this.vehicle);
    this.selectRoom.emit(this.vehicle);
  }

  /*private addRoomToSelect() {
    const productQtyNowSelect = this.productQtyNowSelect(this.select);
    const productQtyLeft = this.productQty - productQtyNowSelect;
    this.select.forEach((obj, index) => {
      obj.selectArray = Array.from(Array(+obj.selectValue + productQtyLeft + 1).keys());
    });
    this.calculateTotalPrice.emit();
  }*/


  private productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach(obj => total += +obj.selectValue);
    return total;
  }

}
