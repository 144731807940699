import { ItineraryReservationModule } from './../itinerary-reservation/itinerary-reservation.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

////import { SharedModule } from '../shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule, TabsModule, BsDropdownModule } from 'ngx-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
// import { TruncateModule } from 'ng2-truncate';
//import { NgxLoadingModule } from 'ngx-loading';
import { configLoading } from '../shared/config.loading';
import { CardItineraryComponent } from './card-itinerary/card-itinerary.component';
import { DataItineraryComponent } from './data-itinerary/data-itinerary.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PriceItineraryComponent } from './price-itinerary/price-itinerary.component';
import { ItemDetailsComponent } from './card-itinerary/item-details/item-details.component';
import { ItemStatusComponent } from './card-itinerary/item-status/item-status.component';
import { ItineraryDetailComponent } from './itinerary-detail.component';
import { InvoiceStatusComponent } from './invoice-status/invoice-status.component';
import { VoucherStatusComponent } from './voucher-status/voucher-status.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { CarouselModule } from 'ngx-owl-carousel-o';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgxPaginationModule,
        ModalModule.forRoot(),
        CarouselModule,
        TabsModule.forRoot(),
        //BsDropdownModule.forRoot(),

        BrowserAnimationsModule,
        //NgxChartsModule,
        FormsModule,
        //TruncateModule,
        //NgxLoadingModule.forRoot(configLoading),
        NgxSpinnerModule,
        SharedModule,
        HttpClientModule,
        AngularSvgIconModule,
        // ItineraryReservationModule,
    ],
    declarations: [
        ItineraryDetailComponent,
        CardItineraryComponent,
        DataItineraryComponent,
        PaymentStatusComponent,
        PriceItineraryComponent,
        ItemDetailsComponent,
        ItemStatusComponent,
        InvoiceStatusComponent,
        VoucherStatusComponent,
    ],
    exports: [
      ItineraryDetailComponent
    ]
})
export class ItineraryDetailModule { }
