<div class="row mt-3" *ngIf="isCheckIn && dato">
  <!-- <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;">
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'dd'}}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'MMM'}}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'yy'}}</p>
        </div>
        <div class="row mt-2" style="margin-left: 52px !important; margin-top: -53px !important;">
          <div class="icon-line icon-bg-ck-in">
            <i class="icon-adventure"></i>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center py-2" style="max-height: 150px; overflow: hidden;">
        <!-- {{dato.product ? dato.product.additionalInfo.image[0].image : 'assets/img/app/tour.jpg'}} -->
        <img class="img-fluid" src="{{dato.images[0].image}}" alt="suggestions" 
        style="max-height: 150px;object-fit: cover;" width="100%">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex justify-content-between py-1">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{stingUpperCase(dato?.title)}}</p> 
            <div style="margin-top: -5px !important;">
              <ng-container *ngIf="dato.product">
                <span *ngIf="dato.product.logo; else nameProduct">
                  <img class="logo-proveedor" [src]="dato.product.logo" [alt]="dato.product.logo">
                </span>
                <ng-template #nameProduct>
                  <span class="sub-title">
                    {{dato.product.providerName}}
                  </span>
                </ng-template>
              </ng-container>
            </div>
            <div style="margin-top: 10px !important;">
              <span class="p-0 spaced" style="padding-top: 20px !important; margin-top: 10px;">
                <span style="font-size: 15px !important;  font-weight: 600 !important;">
                  <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                  {{stingUpperCase(dato.location)}}
                </span>
              </span>
              <br>
              <span class="p-0 m-0  spaced">
                <p class="p-0 m-0 inter" style="font-size: 12px; font-weight: 500;">
              <!--  TEMPORAL     {{ stingUpperCase(dato.name) }} {{ stingUpperCase(dato.duration) }} | {{ dato.payloadUpdate.products[0].others.tours.adults }} Adults - {{ dato.payloadUpdate.products[0].others.tours.children.length }} Children -->
                </p>
              </span>
              <div class="d-flex font-inline flex-row">
                <span class="check">Check-in</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="py-0" style="margin-top: 1px; width: 98px !important;">
              <span style="width: 10%" *ngIf="dato.status_id === 1" class="status product-status-saved">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 2" class="status product-status-reserved">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 3" class="status product-status-locked">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 4" class="status product-status-denied">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 5" class="status product-status-cancelled">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 6" class="status product-status-paid-agency">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 7" class="status product-status-paid-provider">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 8" class="status product-status-completed">{{dato.status}}</span>
            </div>
            <div class="py-0" style="width: 20px !important;" *ngIf="iconsTourDisabled">
              <a type="button"  style="cursor: pointer; margin-top: 3px !important;"> <!-- (click)="openModal()" -->
                <i class="icon-info"></i>
              </a>
              <div>
                <!-- <usb-modals bsModal #modalRoom="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
                  [modalRoomRef]="modalRoom" [product]="dato.product" [productQty]="1" [form]="null"
                  [payloadUpdate]="dato.payloadUpdate" [view_info]="true" [itineraryItem]="dato"
                  [origin]="'MULTIDESTINATION'">
                </usb-modals> -->
              </div>
            </div>
            <div class="py-0" style="width: 20px !important; margin-left: 4px; margin-top: 1px !important;">
              <a style="cursor: pointer;" *ngIf="this.dato.nr">
                <span class="icon-nr font-poppins">NR</span>
              </a>
            </div>
          </div>

        </div>
      </div>

      <div class="col-2 px-0 text-center bg-blue py-2 icon-accion">

        <div *ngIf="iconsTourDisabled" class="icons-item text-right" style="margin-top: 5px;">
          <!-- <span *ngIf="partialReserve===false||partialReserve===undefinid">
            <i class="far fa-check-circle" style="color: #00d397"></i>
          </span> -->
          <span *ngIf="partialReserve===true">
            <label class="custom-radio-checkbox">
              <input class="custom-radio-checkbox__input" type="checkbox" (change)="toPartialReserve($event, dato)" ng-checked="dato.status_id==3">
              <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            </label>
          </span>
          <span>
            <a style="cursor: pointer; margin-left: 5px;" (click)="edit()">
              <i class="icon-edit" aria-hidden="true"></i>
            </a>
          </span>
          <span *ngIf="iconsTourDisabled">
            <a style="cursor: pointer;" (click)="removeItem(dato)">
              <i class="icon-delete" aria-hidden="true"></i>
            </a>
          </span>
        </div>
        <div class="date-expiration pt-2 text-right" class="padding-right: 10px;" *ngIf="dato.expirationDate">
          <span class="font-poppins" style="font-size:11px; font-weight: 400;">
            Expiration Day
          </span>
          <br>
          <span class="font-poppins text-uppercase" style="font-size: 11px; font-weight: bold; color: #00d397;">
            {{ subtractDays(dato.expirationDate) | date:'mediumDate' }}
          </span>
        </div>
        <div class="amount pt-1 text-right" style="margin-top: 1px; padding-right: 10px;">
          <span class="title-destiny-start text-nowrap" [ngStyle]="{'font-size': fontPrice(dato.netPriceAgent)}">$ {{ dato.netPriceAgent | number:'1.2-2'  }}</span>
        </div>
      </div>
    </div> 
  </div>
</div>


<div class="row mt-3" *ngIf="isCheckIn && dato && (dato.duration != '1 DÍA')">
  <!-- <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;">
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'dd'}}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'MMM'}}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'yy'}}</p>
        </div>
        <div class="row mt-2" style="margin-left: 52px !important; margin-top: -53px !important;">
          <div class="icon-line icon-bg-ck-in">
            <i class="icon-adventure-red"></i>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center py-2" style="max-height: 150px; overflow: hidden;">
        <!-- {{dato.product ? dato.product.additionalInfo.image[0].image : 'assets/img/app/tour.jpg'}} -->
        <img class="img-fluid" src="{{dato.images[0].image}}" alt="suggestions" style="max-height: 150px;object-fit: cover;" width="100%">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0" >
        <div class="d-flex justify-content-between py-1">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{stingUpperCase(dato.title)}}</p> 
            <div style="margin-top: -5px !important;">
              <span class="sub-title" >Turibus</span>
            </div>
            <div style="margin-top: 10px !important;">
              <span class="p-0 spaced" style="font-size: 14px; padding-top: 20px !important; font-weight: bold; margin-top: 10px;">
                <span style="font-size: 15px !important;  font-weight: 600 !important;">
                  <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                  {{stingUpperCase(dato.location)}}
                </span>
              </span>
              <br>
              <span class="p-0 m-0  spaced">
                <p class="p-0 m-0 inter" style="font-size: 12px; font-weight: 500;">  
                <!--  TEMPORAL  {{ stingUpperCase(dato.duration) }} | {{ dato.payloadUpdate.products[0].others.tours.adults }} Adults - {{ dato.payloadUpdate.products[0].others.tours.children.length }} Children --> 
                </p>
              </span>
              <div class="d-flex font-inline flex-row">
                <span class="check" style="color: #ff003f !important">Check-Out</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div> 
  </div>
</div>