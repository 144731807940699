<div class="t-content">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <!--***********Basic Data*************-->
    <div class="form-row pb-5 pt-3">
      <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12 ml-1" id="form-destination">
        <input 
        matInput 
        placeholder="Destino" 
        style="background-color: white;"
        maxlength="20"
        name="destino"
        class="form-control 
        input-search-box"
        autofocus 
        #destination 
        formControlName='destination' 
        [matAutocomplete]="auto">
        <mat-autocomplete class="mt-2" #auto="matAutocomplete" [displayWith]="displayFn"
          (optionSelected)='updateMySelection($event.option.value)'>
          <mat-option *ngFor="let name of destinationOptions | async" [value]="name">
            <div class="row" style="padding-top: 13px;">
              <div class="col-2">
                <img class="imag-redo" [src]="name.pic_mini">
              </div>
              <div class="col-10" style="line-height: 19px;padding-left: 25px;">
                <span>
                  <span style="color:#00cc99">{{capitalize(name.city_name)}} </span>
                  <!-- , Fl, Estados Unidos -->
                </span>
                <span class="ml-1"> ({{name.city_iata_code}})</span> <br>
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span style="font-size: 13px !important; padding-left: 5px !important;"> Ciudad </span>
              </div>
            </div>
          </mat-option>
          <mat-option *ngFor="let name of destinationOptions_ | async" [value]="name">
            <div class="row" style="padding-top: 13px;">
              <div class="col-2">
                <img class="imag-redo" src="assets/img/app/aeropuerto-de-miami.jpg">
              </div>
              <div class="col-10" style="line-height: 19px;padding-left: 25px;">
                <span>
                  <span style="color:#00cc99">{{capitalize(name.nameAirport)}} </span>
                   <!-- , Fl, Estados Unidos  -->
                  </span>
                <span class="ml-1"> ({{name.codeIataAirport}})</span> <br>
                <img height="12" src="assets/img/app/little-icons/airplane-dark.svg">
                <span style="font-size: 13px !important; padding-left: 5px !important;"> Aeropuerto </span>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
        <span class="help-block text-danger" *ngIf="_form.get('destination').touched && _form.get('destination').hasError('required')">Este
          campo se requiere.
          <!-- This field is required. -->
        </span>
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidcountry')">Formato
        de destino invalido.</span>
        <!-- Invalid destination format. -->
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidDestination')">Destino invalido,
        seleccione uno de la lista</span>
        <!-- Invalid destination, please select a valid destination from the list. -->
      </div>

      <div class="form-row">
        <div class="form-group pr-3 pl-3">
          <input type="text" 
            formControlName="arrivalDateDepartureDate"
            autocomplete="off"
            class="form-control input-search-box" 
            ngxDaterangepickerMd 
            placeholder="  Check-in - check-out"
            [locale]="{applyLabel: 'ok', format: 'DD/MM/YYYY'}" 
            startKey="start" 
            endKey="end" 
            [(ngModel)]="dateRange" 
            [autoApply]="true"
            [closeOnAutoApply]="true" 
            [minDate]="minDate"
            [maxDate]="maxDate" 
            (startDateChanged)="startDateClicked($event)"
            (endDateChanged)="endDateClicked($event)"
            readonly
            name="daterange" />
        </div>
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">
        <button type="button" class="form-control btn-usb-rp" (click)="_openModal()" [attr.aria-expanded]="!isCollapsed" aria-controls="false">

          
          {{passengers}}
          <span class="arrow-sort fa fa-sort"></span>
        </button>
        <div (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close" (click)="_over()">
          <div class="row">
            <div>
              <div class="card d-flex flex-row px-3">
                <div class="d-flex flex-row align-items-start justify-content-start py-2">
                  <div class="d-flex flex-column mr-2">
                    <label for="adults">Adultos</label>
                    <select formControlName="qtyAdults" class="custom-select form-control-sm" id="adults" name="adults" [value]="1">
                      <option *ngFor="let qty of _maxAdults" [value]="qty">{{ qty }}</option>
                    </select>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="container-label d-flex">
                      <label for="childs">Niños</label>
                      <div class="info-tool d-flex flex-column" style="position: right;">i
                        <span class="tooltip-info" >Los bebes (0 - 2 años sin cumplir) deben indicarse como niños</span>
                      </div>
                    </div>
                    <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs" (ngModelChange)="_addChild()"
                      name="childs" [value]="0">
                      <option *ngFor="let qty of _maxChildren" [value]="qty">{{ qty }}</option>
                    </select>
                  </div>
                  <div class="px-2 d-flex w-100">
                    <!-- Children age inputs -->
                    <div id="childrenAges" class="d-flex flex-column" *ngIf="formArray(_form, 'childsage').length > 0" style="min-width:100px;">
                      <label for="age">Edad niños</label>
                      <div class="d-flex flex-column p-0 m-0">
                        <div class="d-flex flex-row">
                          <div formArrayName="childsage" *ngFor="let age of formArray(_form, 'childsage'); let j = index;"
                            class="select-ages">
                            <div [formGroupName]="j" class="p-0">
                              <input formControlName="age" min=0 max=17 limit-to="14" class="form-control mr-1 p-3 input-age"
                                value-formatter="0" placeholder="0" name="age" type="number">
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div *ngFor="let age of formArray(_form, 'childsage'); let j = index;">
                            <span class="help-block text-danger d-flex w-100 text-nowrap"
                              *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>
                              Age of children {{j+1}} is incorrect</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-around flex-row py-2">
                  <div class="p-2 justify-content-end">
                    <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                      class="badge rounded-pill btn-choice-confirm" type="button"
                      (mouseenter)="isHoverConfirm = true; updateButtonColorConfirm();"
                      (mouseleave)="isHoverConfirm = false; updateButtonColorConfirm();" 
                      [ngStyle]="{'background-color': (isHoverConfirm ? bgClientColor : 'white')}">
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-xs-12 col-lg-2 col-md-4 justify-content-start align-middle">
        <!-- btn-usblick -->
        <button class="btn btn-search-act  rounded-pill d-flex justify-content-center align-items-center" type="submit" [disabled]="(!_form.valid || _findData)"
        [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
        
        >
          <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
          BUSCAR
        </button>

      </div>
    </div>
  </form>
</div>
