<!-- Caja de Búsqueda Insert Destination -->
<div class="card card-inverse my-2" id="destin-search">
    <div class="d-flex justify-content-center px-3 py-1 align-items-center">
      <span class="text-center">
        <h6>Insert Destinations</h6>
      </span>
    </div>
    <div class="card-block flex-column">
      <div class="flex-column d-flex justify-content-between my-0 mx-3">
        <form (ngSubmit)="_submitDestination(_destinationForm, $event)" [formGroup]="_destinationForm" novalidate>
          <div id="search-itinerary" class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2  font-italic ">

            <input ngui-auto-complete formControlName='destination' maxlength="20" type="text" name="destino" class="form-control font-italic" value-formatter="cityName"
              [(ngModel)]="destiny" [source]="_observableSource.bind(this)" display-property-name="cityName" (valueChanged)="_myCallback($event)"
              [list-formatter]="_myListFormatter" accept-user-input="false" autocomplete="off" placeholder="Ex.: Miami - Florida">

            <button type="button" class="btn btn-sm" title="Search">
              <i class="fa fa-search ml-auto"></i>
            </button>
          </div>
          <div class="mt-5 pb-4 px-0 text-left col-12 d-flex flex-row justify-content-between">
            <input type="number" style=" height: 36px;" placeholder="Nights" (ngModelChange)="evaluateDisabledButton()" class="form-control form-control-sm" formControlName='nights' id="nights" min="1" step="1" />

            <button type="submit" class="btn btn-sm btn-usblick ml-3" [disabled]="disabledAddToMyTrip" style="height: 35px;width: 150%;">ADD TO MY TRIP</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Fin Caja de Búsqueda Insert Destination -->
  <!-- <ngx-loading [show]="loadingReording" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading> -->

  <div *ngIf="_destinations.length !== 0" class="disable">


    <!-- <div [sortablejs]="_destinations" class="col-12 p-0 m-0" > -->
    <!-- <div class="col-12 p-0 m-0" > -->

      <!-- <ng-template let-item let-i="index"> -->
      <div  *ngFor="let item of _destinations;">
        <div class="p-0 mt-2 d-flex justify-content-end" id="clear-multid">
          <button (click)="_removeDestination(_destinations.indexOf(item))" class="btn btn-usblick btn-sm">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="list-group-item border rounded d-inline-flex col-12 p-0">
          <div class="d-flex flex-column col-12 p-0" id="info-hotels">
            <div class="d-inline-flex col-12 px-0  w-100">
              <div class="d-flex col-2 flex-row m-0 px-0 align-items-center">
                <div class="d-flex flex-column mx-1 px-0 background-green justify-content-center" id="date-start">
                  <p class="p-0 m-0 date font-poppins text-center" style="font-size: 18px;">
                    {{item.dd}}
                  </p>
                  <p class="p-0 m-0 date font-poppins text-center" style="font-size: 10px;">
                    {{item.mm.substring(0,3)}}
                  </p>
                  <p class="p-0 m-0 date font-poppins text-center" style="font-size: 13px;">
                    {{item.yy}}
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row col-8 px-0">
                <div id="info-destiny" class="col-12 py-2 d-flex flex-column justify-content-center">
                  <span class="p-0 m-0 title-multidestination font-poppins d-flex">
                    {{item.cityName}}
                  </span>
                  <span class="p-0 m-0 date-multidestination font-poppins d-flex">
                    Inn {{item.dd}} {{item.mm}} {{item.yy}} &nbsp;&nbsp; Out {{item.ddd}} {{item.mmm}} {{item.yyy}}
                  </span>
                  <span class="p-0 m-0 font-poppins stay d-flex">
                    Stay: {{item.nights}} Night(s)
                  </span>
                  <span class="p-0 m-0 font-dark-c d-flex">
                    {{item.room}} Room | {{item.adults}} Adults | {{item.childs}} Children
                  </span >
                </div>

              </div>
              <div class="d-flex flex-column col-2 px-0 justify-content-end">
                <div class="d-flex p-0 ml-2 justify-content-end">
                  <button type="button" class="btn btn-sm bottom-number mb-1" data-placement="bottom" title="1">
                    {{_destinations.indexOf(item) + 1}}
                  </button>
                </div>
                <div class="d-flex p-0 justify-content-end">
                  <!-- <button type="button" (click)="openModalWithClass(templateRoomsPassengers, item)"
                  style="width: 50%; padding-left: 4px;" class="btn btn-sm btn-dark" data-placement="bottom" [disabled]="_activeItinerary === null || !_isDestinationEditable(item)"
                    title="Edit">
                    <i class="icon-edit icon icon-pencil" aria-hidden="true"></i>
                  </button> -->
                  <button class="btn btn-dark btn-edit-destin"
                    (click)="openModalWithClass(templateRoomsPassengers, item)"
                    data-placement="bottom" [disabled]="_activeItinerary === null || !_isDestinationEditable(item)"
                    title="Edit"
                  >
                    <i class="icon-edit icon icon-pencil" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal Edit Rooms Passengers -->
          <ng-template #templateRoomsPassengers>
            <div class="modal-body " id="modal-edit-room">
              <div class="" role="document">
                <h5 class="modal-title text-center pt-3">Edit Rooms and Passengers In {{item.cityName}}</h5>
                <div>
                  <div>
                    <form (ngSubmit)="_roomsApply()" [formGroup]="_form">
                      <div formArrayName="room" *ngFor="let room of _form.get('room').value; let i = index">
                        <div [formGroupName]="i" class="d-flex flex-row px-2 mx-5 align-items-center justify-content-center col-xg-11 col-lg-11 col-md-11 col-sm-12">
                          <div class="p-2 col-2">Room {{i+1}}</div>
                          <div class="p-2 col-2 d-flex flex-column">
                            <label for="adults">Adults</label>
                            <select formControlName="qtyAdults" class="custom-select form-control-sm " id="adult" name="adult">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                          <div class="p-2 col-2 d-flex flex-column">
                            <label for="childs">Children</label>
                            <select formControlName="qtyChilds" class="custom-select form-control-sm" id="child" name="child" (ngModelChange)="_addChild(room)">
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                          <div class="p-2 col-5 mx-3">
                            <!-- Children age inputs -->
                            <div id="childrenAges" class="p-8 mt-2 col-12" style="padding:0 !important;" *ngIf="room.value['childsAges'].length > 0">
                              <label for="age">Edad niños</label>
                              <div class="col-12 d-flex flex-row" style="padding:0px;">
                                <div formArrayName="childsAges" *ngFor="let age of room.get('childsAges').controls; let j = index" class="col-3" style="display:inline-block;padding:0px">
                                  <div [formGroupName]="j" class="col-12" style="padding:0px;">
                                    <input formControlName="age" min=0 max=17 limit-to="14" class="form-control col-10" style="font-size:1em;padding:3px" placeholder="0"
                                      name="age">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngFor="let age of room.get('childsAges').controls; let j = index">
                              <span class="help-block text-danger col-12" *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min')|| age.controls['age'].hasError('max')|| age.controls['age'].hasError('required')"><br>The age of children {{j+1}} is incorrect</span>
                            </div>
                          </div>
                          <div class="p-2 justify-content-start" *ngIf="_form.value['room'].length > 1">
                            <button (click)="_removeRoom(i)" class="btn btn-outline-danger btn-sm">
                              <i class="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <hr>
                      </div>
                      <div class="d-flex flex-row px-2 mx-5 align-items-center justify-content-center col-xg-11 col-lg-11 col-md-11 col-sm-12">
                        <div class="p-2 col-2 d-flex flex-column spaced">
                          <label for="nights">Nights
                            <input type="number" formControlName="nights" class="form-control px-3 dates" id="nights" min="1" step="1" />
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- <hr> -->
                  <div class="d-flex justify-content-between flex-row">
                    <div class="p-2 justify-content-start" *ngIf="_form.value['room'].length < 7">
                      <button (click)="_addRoom()" class="btn btn-outline-danger btn-sm">
                        Add Room
                      </button>
                    </div>
                    <div class="p-2 justify-content-end">
                      <button aria-controls="false" class="btn btn-outline-danger btn-sm" (click)="cancel()">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center p-2" id="apply-btn-is">
                  <!-- <button type="button" (click)="_roomsApply($event, i)" class="btn btn-sm btn-usblick" [disabled]="!_form.valid">Apply</button> -->
                </div>
              </div>
            </div>
          </ng-template>
          <!-- /Modal Edit Room Passengers -->
        </div>
        <!-- <i *ngIf="(i+1) !== _destinations.length && _destinations.length !== 0" class="icon-separadores d-flex align-items-end"
          aria-hidden="true"></i> -->
      <!-- </ng-template> -->
      </div>
    <!-- </div> -->

  </div>

  <usb-summary-footer [buttonDisabled]="disableButton" [payload]="_payloadArray" [activeItinerary]="_activeItinerary"
                      [modeView]="'MULTIDESTINATION'" [arrivalDate]="_arrivalDate" ></usb-summary-footer>
