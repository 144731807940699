<div usbLoadingSplash
     [appLoading]="appLoading"
     [fullscreen]="true"
     class="container-fluid bg-top-reserva"
     style="background-color: #eeeef4! important"
>
  <div class="row px-5 pt-5 pb-3 mb-5 ">
      <!-- <div class="steps">
        <usb-steps-reservation>
        </usb-steps-reservation>
      </div> -->
        <div class="col-12" style="margin-top: -75px">
            <!-- usb-data-itinerary -->
            <usb-data-itinerary *ngIf="itinerary && screen"
                [screen]="screen"
                [idConfirmation]="idConfirmation"
                [itinerary]="itinerary"
                [idReservation]="idReservation"
                >
            </usb-data-itinerary>
          </div>
        </div>
      </div>

      <div class="container-fluid pt-4 pr-5 pl-5" style="background-color: #f0edf4; padding-bottom: 50px;">
        <div class="row bg-white container-fluid mx-auto content-detail-cards justify-content-between" style="margin-top: -17vh !important;">
          <!-- usb-card-itinerary -->

          <div class="pl-3" *ngIf="itinerary && destinationsArray" style="width: calc(100% - 385px);">
              <div *ngFor="let destination of destinationsArray; let i=index" class="mt-3" >
                  <!-- destination data -->
                  <div class="row">
                      <div class="d-flex justify-content-between pr-2" style="width: 100%; height: 54px;">
                          <div class="destination-iata content-detail-green d-flex align-items-center justify-content-center"
                           [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
                              <p class=" font-poppins text-center f-15 font-weight-bold m-0" style="color: #000000">{{destination.iata}}</p>
                          </div>
                          <div class="destination content-detail-dark d-flex align-items-center justify-content-start px-3" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
                              <p class="text-white font-weight-medium font-poppins f-15 m-0">{{destination.city}} &nbsp;
                                  <span class="f-15 font-weight-medium border-less" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
                                      IN: {{ destination.arrival | date:'dd MMM yyyy'}} &nbsp; | &nbsp; OUT: {{ destination.departure | date:'dd MMM yyyy'}}
                                  </span>
                              </p>
                          </div>
                      </div>
                  </div>
                  <!-- list cards itinerary -->
                  <div class="row pr-2" *ngIf="destination.items">
                      <div *ngIf="destination.items" style="width: 100%;">

                         <!-- <ng-container *ngFor="let item of destination.items; let i=index">-->
                          <ng-container *ngFor="let item of destination.items; let i=index">
                              <usb-card-itinerary  *ngIf="item.status_id != 4" #card
                                  (reserve)="reserveItem($event)"
                                  [itinerary]="itinerary"
                                  (delete)="deleteItem($event)"
                                  (cancel)="cancelReservations($event)"
                                  [changePriceFromReserve]="changePriceFromReserve"
                                  [item]="item"
                                  [_status]="status"
                                  [showTextChangeDetailsProducts]="showTextChangeDetailsProducts"
                                  [index]="i"
                                  [reservation]="findReservedProduct(item)"
                                  [partialReserve]="isActivePartialReserve"
                                  (itemToPartial)="_itemToPartial($event)"
                                  (modifications) ="_modifications()"
                                  (reloadItinerary)="_reloadItinerary()">
                              </usb-card-itinerary>

                              <!--   [_status]="item?.syncProduct?.codeStatus" -->
                         </ng-container>
                      </div>
                  </div>
              </div>
          </div>
          <!-- usb-price-itinerary   *ngIf="isUpdate"  -->
          <div  class="pr-3 mt-3 ml-auto" style="width: 355px;">
              <usb-price-itinerary *ngIf="itinerary"
                  (updateEmitter)="updateCards(false)"
                  [netPriceBookingFreeTax]="netPriceBookingFreeTax"
                  [netPriceBooking]="netPriceBooking"
                  [taxSum]="taxSum"
                  [modifications]="modifications"
                  [itinerary]="itinerary"
                  [screen]="screen"
                  [partialReserve]="isActivePartialReserve"
                  [showPartialContainer]="showPartialContainer">

              </usb-price-itinerary>
              <ng-container *ngIf="itinerary && screen === 'addFee'">
                  <div class="row pl-2 mt-2">
                      <button *ngIf="itinerary.status_id!==3 && goTo=='saveDraft' " class="btn btn-danger btn-block btn-big text-uppercase" (click)="save()">
                          Continue
                      </button>
                  </div>

                  <div class="row pl-2 mt-2">
                      <button *ngIf="itinerary.status_id != 3 && itinerary.status_id != 4 && itinerary.status_id != 5 && itinerary.status_id != 6"
                      class="btn btn-danger btn-block btn-big text-uppercase" (click)="reserve()"
                      [disabled]="((isActivePartialReserve && qtyItemsToPartial==0 || modifications))">
                          Reserve
                      </button>
                  </div>

                  <div class="row pl-2 mt-2">
                      <button *ngIf="itinerary.items.length > 1 && itinerary.status_id != 3 && itinerary.status_id != 4 && itinerary.status_id != 5 && itinerary.status_id != 6 && activeButtonPartialReserve" class="btn btn-danger btn-block btn-big text-uppercase" (click)="activePartial()">
                          Reserve
                      </button>
                  </div>
              </ng-container>

              <ng-container *ngIf="itinerary && screen === 'detail'">
                  <div class="row pl-2 mt-2" *ngIf="permissionsMap['booking.itinerarios.reservar']">
                      <!-- falta validar si el itinerario tiene mas de 1 producto -->
                      <ng-container *ngIf="!itemChangeTwoProduct">
                        <button
                            *ngIf="this.itinerary.items.length > 1 &&
                                    this.itinerary.status_id != 3 &&
                                    this.itinerary.status_id != 4 &&
                                    this.itinerary.status_id != 5 &&
                                    this.itinerary.status_id != 6 &&
                                    this.activeButtonPartialReserve"
                            class="btn btn-block rounded-pill btn-big text-uppercase" (click)="activePartial(); controlShow();" style="font-size: 24px; font-weight:600; background-color: #afafaf" >
                            Reserva Parcial
                        </button>
                      </ng-container>
                  </div>
                  <div class="row pl-2 mt-2" *ngIf="permissionsMap['booking.itinerarios.reservar']">
                      <button *ngIf="itinerary.status_id != 3 && itinerary.status_id != 4 && itinerary.status_id != 5 && itinerary.status_id != 6"
                      class="btn btn-block rounded-pill btn-big text-uppercase" (click)="reserve()"
                      [disabled]="((isActivePartialReserve && qtyItemsToPartial==0 || modifications))"
                      [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                      Style="font-family: 'Poppins'; font-size: 26px; font-weight: 600;">
                          Reservar
                      </button>
                  </div>
                  <div class="row pl-2 mt-2" *ngIf="permissionsMap['booking.itinerarios.pagar']">
                      <button *ngIf="(itinerary.status_id == 2 || itinerary.status_id == 3) && searchProductReserved()"
                      class="btn btn-danger rounded-pill btn-block btn-big text-uppercase" (click)="pay()"
                      [disabled]="isActivePartialReserve && qtyItemsToPartial==0">
                          Pagar
                      </button>
                  </div>
              </ng-container>

          </div>
      </div>
    </div>


<!---
<div class="usb-itinerary-pdf">
    <usb-itinerary-pdf *ngIf="itinerary && id"
    [itinerary]="itinerary"
    [itineraryID]="id" >
    </usb-itinerary-pdf>
</div>
-->

<!-- <ngx-spinner r bdColor="rgba(0, 0, 0, 0.8)" size = "medium" color="white" type="line-scale">
</ngx-spinner> -->
<!---
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#d40057" type="line-scale">
    <p style="color: white"> Procesando</p>
</ngx-spinner>-->
