import { Component, Input, OnInit} from '@angular/core';
import { setting } from '../../../../setting';
import { infoArticle } from '../article/article.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'usb-destination-small',
  templateUrl: './destination-small.component.html',
  styleUrls: ['./destination-small.component.scss']
})
export class DestinationSmallComponent implements OnInit {
  @Input() getClientsSettingAppBooking: any;
  public sectionDestinationSmall: any;
  public routeUploadFile:any;
  hover:boolean = false;
  public articleSmallArr: infoArticle[] = [];
  public clientSettingGlobal: any;

  constructor(

              private router: Router
            ) {
                this.routeUploadFile =  setting.routeUploadFile;
              }

  ngOnInit() {
                this.sectionDestinationSmall  = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_destination_small);
                this.clientSettingGlobal = this.getClientsSettingAppBooking?.clients_setting_global;
                this.articleSmallArr = this.sectionDestinationSmall.info;
             }

  articleRedirect(index: any) {
    const typeArticle = 'small'; // Aquí puedes configurar la variable small como necesites
    const id = index; // Usar el índice o el id que se pase como parámetro
    const active = 0
    this.router.navigate([`/article/${typeArticle}/${id}/${this.getClientsSettingAppBooking.id}/${active}`]);
  }

}
