<!-- <ng-container *ngIf="
  (this.agency.pay_mode == 1) || 
  (this.agency.pay_mode == 2 && points >= totalPriceNR) ||
  (this.agency.pay_mode == 3 && points >= totalPriceNR) ||
  (this.agency.pay_mode == 4 && points >= totalPriceNR) ||
  (this.agency.pay_mode == 5 && points >= totalPriceNR) ||
  (this.agency.pay_mode == 6 && points >= totalPriceNR)
"> -->

<ng-container *ngIf="
  (this.agency.pay_mode == 1) 
">


  <div class="header">
    <p>
      TU RESERVA CONTIENE PRODUCTOS CON TARIFAS ESPECIALES Y LA CONDICIÓN DE <span class="icon-nr font-poppins">NR</span>
    </p>
    <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
  </div>


  <div class="card font-weight-bold" style="font-size: 11pt;">
    <div class="row">
      <div class="col-1">
        <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

        </div>
      </div>
      <div class="col-11">
        <p style="font-weight: bold;">
          ESTO SIGNIFICA QUE ESOS PRODUCTOS NO TIENEN DEVOLUCIÓN DESDE EL MOMENTO QUE CONFIRMAS LA RESERVA
        </p>
      </div>
    </div>

  </div>

  <div class="card-2 font-poppins">



    <div class="row">

      <!-- <div class="col-12">
        <p> <span style="font-weight: bold;">El monto de estos productos será debitado del crédito disponible de manera automática.</span>  De modo que antes de continuar verifica que es el producto que desea reservar <span style="font-weight: bold;">dado que una vez realizada la misma PERMITE CANCELACIÓN</span></p>
      </div>

      <div class="col-8 d-flex align-items-center">
        <p>El monto total a debitar de la cuenta de productos <span class="icon-nr font-poppins">NR</span> es de </p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
    
        <div class="border-amount" >
          {{totalPriceNR | number:'1.2-2'}} $
        </div>
      </div> -->

    <div class="col-8 d-flex align-items-center">
      <input type="checkbox" name="pointsActive" [(ngModel)]="pointsActive" *ngIf="this.fidelityService.creditPointActives != 0" (change)="pointsReview()">
      <p>Límite de Credito de Puntos</p>
    </div>
  
    <div class="col-4 d-flex align-items-center">
  
      <div class="border-amount">
        {{points | number:'1.2-2'}} $
      </div>
    </div>

    <div class="col-8 d-flex align-items-center">
      <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
    </div>
  
    <div class="col-4 d-flex align-items-center">
  
      <div class="border-amount" >
        {{totalPriceNR | number:'1.2-2'}} $
      </div>
    </div>

    <div class="col-8 d-flex align-items-center">
      <p *ngIf="agency.pay_mode == 1">{{(totalPriceNR - points) < 0 ? "Monto Restante" : "Monto Faltante"}}</p>
      <p *ngIf="agency.pay_mode == 3">{{(totalPriceNR - fidelityService.depositActives) < 0 ? "Monto Restante" : "Monto Faltante"}}</p>
    </div>
  
    <div class="col-4 d-flex align-items-center">
  
      <div class="border-amount" *ngIf="agency.pay_mode == 1">
        {{ verifyCero(totalPriceNR - points) | number:'1.2-2'}} $
      </div>

      <div class="border-amount" *ngIf="agency.pay_mode == 3">
        {{verifyCero(totalPriceNR - fidelityService.depositActives) | number:'1.2-2'}} $
      </div>
    </div>

    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;" *ngIf="points!=0">
      
      <div class="col-6 d-flex align-items-center ">

        <div>
          <input type="checkbox" [(ngModel)]="terms"> <span style="font-weight: bold;" >SI, DESEO CONTINUAR CON LA RESERVA</span> 
        </div>
      
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 20px;" *ngIf="points!=0">
      
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger" type="button" (click)="close()">CANCELAR</button>
        <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button>
      </div>
    </div>

    
    <div class="row d-flex justify-content-end" style="margin-top: 20px;" *ngIf="points==0">
      
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger ml-5 pl-5 pr-5" type="button" (click)="close()">CERRAR</button>
        <!-- <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button> -->
      </div>
    </div>

  </div>

</ng-container>

<!-- ------------------------------------------------------------ -->
<!-- CASE B -->

<ng-container *ngIf="this.agency.pay_mode == 2">

  <div class="header">
    <p>
      CONFIRMACIÓN DE PAGO PARA PRODUCTOS <span class="icon-nr font-poppins">NR</span>
    </p>
    <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
  </div>


  <div class="card font-weight-bold" style="font-size: 11pt;">
    <div class="row">
      <div class="col-1">
        <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

        </div>
      </div>
      <div class="col-11">
        <p style="font-weight: bold;">
          PUEDES CONTINUAR CON LA RESERVA REALIZANDO EL PAGO CON TARJETA DE CREDITO
        </p>
      </div>
    </div>

  </div>

  <div class="card-2 font-poppins">

    <div class="row">
      <div class="col-8 d-flex align-items-center">
        <input type="checkbox" name="pointsActive" [(ngModel)]="pointsActive" *ngIf="this.fidelityService.creditPointActives != 0" (change)="pointsReview()">
        <p>Límite de Credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{points | number:'1.2-2'}} $
        </div>
      </div>
  
      <div class="col-8 d-flex align-items-center">
        <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{totalPriceNR | number:'1.2-2'}} $
        </div>
      </div>
  
      <div class="col-8 d-flex align-items-center">
        <p>Pago con Tarjeta de Crédito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center" *ngIf="fidelityService.creditPointActives > totalPriceNR">
        <div class="border-amount" >
          {{(pointsActive ? totalPriceNR : 0) | number:'1.2-2'}} $
        </div>
      </div>

      <div class="col-4 d-flex align-items-center" *ngIf="fidelityService.creditPointActives < totalPriceNR">
        <div class="border-amount" >
          {{(pointsActive ? totalPriceNR : totalPriceNR - points) | number:'1.2-2'}} $
        </div>
      </div>
  
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;">
      
      <div class="col-6 d-flex align-items-center ">

        <div>
          <input type="checkbox" [(ngModel)]="terms"> <span style="font-weight: bold;" >SI, DESEO CONTINUAR CON LA RESERVA</span> 
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 20px;">
      
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger" type="button" (click)="close()">CANCELAR</button>
        <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button>
      </div>
    </div>

  </div>

</ng-container>

<!-- CASE C
------------------------------------------------------------ -->

<ng-container *ngIf="
    (this.agency.pay_mode == 3)  || 
    ((this.agency.pay_mode == 4) && ((points + deposit) > totalPriceNR) )" 
>

  <div class="header">
    <p>
      CONFIRMACIÓN DE PAGO PARA PRODUCTOS <span class="icon-nr font-poppins">NR</span>
    </p>
    <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
  </div>


  <div class="card font-weight-bold" style="font-size: 11pt;">
    <div class="row">
      <div class="col-1">
        <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

        </div>
      </div>
      <div class="col-11">
        <p style="font-weight: bold;" *ngIf="this.agency.pay_mode == 3">
          REALIZA UN DEPÓSITO ADICIONAL PARA INCREMENTAR TU CRÉDITO Y ASÍ COMPLETAR LA RESERVA
        </p>

        <p style="font-weight: bold;" *ngIf="this.agency.pay_mode == 4">
          PUEDES CONTINUAR CON LA RESERVA REALIZANDO EL PAGO CON TARJETA DE CREDITO
        </p>
      </div>
    </div>

  </div>

  <div class="card-2 font-poppins">

    <div class="row">

      <div class="col-8 d-flex align-items-center" *ngIf="this.fidelityService.creditPointActives != 0">
        <input type="checkbox" name="pointsActive" [(ngModel)]="pointsActive" *ngIf="this.fidelityService.creditPointActives != 0" (change)="pointsReview()">
        <p>Límite de Credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center" *ngIf="this.fidelityService.creditPointActives != 0">
        <div class="border-amount">
          {{points | number:'1.2-2'}} $
        </div>
      </div>

      <div class="col-8 d-flex align-items-center">
        <p>Límite de depósito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{deposit | number:'1.2-2'}} $
        </div>
      </div>      
      
      <div class="col-8 d-flex align-items-center">
        <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{totalPriceNR | number:'1.2-2'}} $
        </div>
      </div>
  
      <div class="col-8 d-flex align-items-center" *ngIf="validateDiferrenceC(points, deposit, totalPriceNR)">
        <p>Monto faltante</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" style="font-weight: bold;" *ngIf="points < totalPriceNR && deposit > totalPriceNR">
          {{verifyCero(deposit - (totalPriceNR - points)) | number:'1.2-2'}} $
        </div>

        <div class="border-amount" style="font-weight: bold;" *ngIf="points > totalPriceNR && deposit < totalPriceNR">
          {{verifyCero(points - (totalPriceNR - deposit )) | number:'1.2-2'}} $
        </div>

        <div class="border-amount" style="font-weight: bold;" *ngIf="points < totalPriceNR && deposit < totalPriceNR">
          {{ verifyCero((deposit + points) - totalPriceNR) | number:'1.2-2'}} $
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;" *ngIf="(deposit + points) > totalPriceNR">
      <div class="col-6 d-flex align-items-center">
        <div>
          <input type="checkbox" [(ngModel)]="terms"> <span style="font-weight: bold;" >SI, DESEO CONTINUAR CON LA RESERVA</span> 
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 20px;" *ngIf="(deposit + points) > totalPriceNR">
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger" type="button" (click)="close()">CANCELAR</button>
        <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button>
      </div>
    </div>

  </div>

</ng-container>

<!-- CASE D
------------------------------------------------------------ -->

<ng-container *ngIf="(agency.pay_mode == 4) && ((points + deposit) < totalPriceNR)">

  <div class="header">
    <p>
      CONFIRMACIÓN DE PAGO PARA PRODUCTOS <span class="icon-nr font-poppins">NR</span>
    </p>
    <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
  </div>


  <div class="card font-weight-bold" style="font-size: 11pt;">
    <div class="row">
      <div class="col-1">
        <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

        </div>
      </div>
      <div class="col-11">
        <p style="font-weight: bold;">
          PUEDES CONTINUAR CON LA RESERVA REALIZANDO EL PAGO CON TARJETA DE CREDITO
        </p>
      </div>
    </div>

  </div>

  <div class="card-2 font-poppins">

    <div class="row">

      <div class="col-8 d-flex align-items-center" *ngIf="this.fidelityService.creditPointActives != 0">
        <input type="checkbox" name="pointsActive" [(ngModel)]="pointsActive" *ngIf="this.fidelityService.creditPointActives != 0" (change)="pointsReview()">
        <p>Límite de Credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center" *ngIf="this.fidelityService.creditPointActives != 0">
        <div class="border-amount">
          {{points | number:'1.2-2'}} $
        </div>
      </div>

      <div class="col-8 d-flex align-items-center">
        <p>Límite de depósito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{deposit | number:'1.2-2'}} $
        </div>
      </div>      
      
      <div class="col-8 d-flex align-items-center">
        <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{totalPriceNR | number:'1.2-2'}} $
        </div>
      </div>
  
      <div class="col-8 d-flex align-items-center"  *ngIf="(points + deposit) < totalPriceNR">
        <p>Pago con tarjeta de credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" style="font-weight: bold;" *ngIf="(points + deposit) < totalPriceNR">
          {{  (totalPriceNR - (points + deposit))  | number:'1.2-2'}} $
        </div>
      </div>
  
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;">
      
      <div class="col-6 d-flex align-items-center ">

        <div>
          <input type="checkbox" [(ngModel)]="terms"> <span style="font-weight: bold;" >TU RESERVA CONTIENE PRODUCTOS NR SIN DEVOLUCION EN EL CASO DE CANCELAR LA RESERVA</span> 
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 20px;">
      
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger" type="button" (click)="close()">CANCELAR</button>
        <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button>
      </div>
    </div>

  </div>

</ng-container>

<!-- CASE E
------------------------------------------------------------ -->

<ng-container *ngIf="this.agency.pay_mode == 5">
  <div class="header">
    <p>
      CONFIRMACIÓN DE PAGO PARA PRODUCTOS <span class="icon-nr font-poppins">NR</span>
    </p>
    <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
  </div>

  <div class="card font-weight-bold" style="font-size: 11pt;">
    <div class="row">
      <div class="col-1">
        <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

        </div>
      </div>
      <div class="col-11">
        <p style="font-weight: bold;">
          PUEDES CONTINUAR CON LA RESERVA REALIZANDO EL PAGO CON TARJETA DE CREDITO
        </p>
      </div>
    </div>
  </div>

  <div class="card-2 font-poppins">

    <div class="row">

      <div class="col-8 d-flex align-items-center" *ngIf="this.fidelityService.creditPointActives != 0">
        <input type="checkbox" name="pointsActive" [(ngModel)]="pointsActive" *ngIf="this.fidelityService.creditPointActives != 0" (change)="pointsReview()">
        <p>Límite de Credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center" *ngIf="this.fidelityService.creditPointActives != 0">
        <div class="border-amount">
          {{points | number:'1.2-2'}} $
        </div>
      </div>

      <div class="col-8 d-flex align-items-center">
        <p>Límite de depósito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{deposit | number:'1.2-2'}} $
        </div>
      </div>      
      
      <div class="col-8 d-flex align-items-center">
        <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
    
        <div class="border-amount" >
          {{totalPriceNR | number:'1.2-2'}} $
        </div>
      </div>
  
      <div class="col-8 d-flex align-items-center"  *ngIf="(points + deposit) < totalPriceNR">
        <p>Pago con tarjeta de credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" style="font-weight: bold;" *ngIf="(points + deposit) < totalPriceNR">
          {{ (totalPriceNR - (points + deposit))  | number:'1.2-2'}} $
        </div>
      </div>
  
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;">
      
      <div class="col-6 d-flex align-items-center ">

        <div>
          <input type="checkbox" [(ngModel)]="terms"> <span style="font-weight: bold;" >TU RESERVA CONTIENE PRODUCTOS NR SIN DEVOLUCION EN EL CASO DE CANCELAR LA RESERVA</span> 
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 20px;">
      
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger" type="button" (click)="close()">CANCELAR</button>
        <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button>
      </div>
    </div>

  </div>

</ng-container>

<!-- CASE F
------------------------------------------------------------ -->

<ng-container *ngIf="this.agency.pay_mode == 6 && (points + deposit) < totalPriceNR">

  <div class="header">
    <p>
      CONFIRMACIÓN DE PAGO PARA PRODUCTOS <span class="icon-nr font-poppins">NR</span>
    </p>
    <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
  </div>


  <div class="card font-weight-bold" style="font-size: 11pt;">
    <div class="row">
      <div class="col-1">
        <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

        </div>
      </div>
      <div class="col-11">
        <p style="font-weight: bold;">
          PUEDES CONTINUAR CON LA RESERVA REALIZANDO EL PAGO CON TARJETA DE CREDITO O CUENTA CORRIENDTE
        </p>
      </div>
    </div>

  </div>

  <div class="card-2 font-poppins">

    <div class="row">

      <div class="col-8 d-flex align-items-center" *ngIf="points !=0">
        <p>Límite de Credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center" *ngIf="points !=0">
        <div class="border-amount">
          {{points | number:'1.2-2'}} $
        </div>
      </div>

      <div class="col-8 d-flex align-items-center">
        <p>Límite de depósito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" >
          {{deposit | number:'1.2-2'}} $
        </div>
      </div>      
      
      <div class="col-8 d-flex align-items-center">
        <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
    
        <div class="border-amount" >
          {{totalPriceNR | number:'1.2-2'}} $
        </div>
      </div>
  
      <div class="col-8 d-flex align-items-center"  *ngIf="(points + deposit) < totalPriceNR">
        <p>Pago con tarjeta de credito</p>
      </div>
    
      <div class="col-4 d-flex align-items-center">
        <div class="border-amount" style="font-weight: bold;" *ngIf="(points + deposit) < totalPriceNR">
          {{(totalPriceNR - (points + deposit)) | number:'1.2-2'}} $
        </div>
      </div>
  
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;">
      
      <div class="col-10 d-flex align-items-center ">
        <div>
          <input type="checkbox" [(ngModel)]="terms"> 
          <span style="font-weight: bold;" >TU RESERVA CONTIENE PRODUCTOS NR SIN DEVOLUCION EN EL CASO DE CANCELAR LA RESERVA</span> 
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-end" style="margin-top: 10px;">
      <div class="col-10 d-flex align-items-center ">
        <div>
          <!-- <input type="checkbox" [(ngModel)]="terms">  -->
          <span style="font-weight: bold;" >ACEPTO QUE LOS PRODUCTOS NR SE DEBITAN CON MICUENTA CORRIENTE</span> 
        </div>
      </div>
    </div>



    <div class="row d-flex justify-content-end" style="margin-top: 20px;">
      
      <div class="col-7 d-flex align-items-center justify-content-between">
        <button class="btn btn-danger" type="button" (click)="close()">CANCELAR</button>
        <button class="btn btn-success" type="button" (click)="close(true)" [disabled]="!terms">CONTINUAR</button>
      </div>
    </div>

  </div>

</ng-container>