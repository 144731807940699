import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState } from './storeProfile';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectRoles = createSelector(
  selectAuthState,
  (state: AuthState) => state.roles
);

export const selectPermissions = createSelector(
  selectAuthState,
  (state: AuthState) => state.permissions
);

export const selectAgent = createSelector(
  selectAuthState,
  (state: AuthState) => state.agent
)

export const selectAgency = createSelector(
  selectAuthState,
  (state: AuthState) => state.agency
);

export const selectCategories = createSelector(
  selectAuthState,
  (state: AuthState) => state.categories
);

export const selectSetCurrency = createSelector(
  selectAuthState,
  (state: AuthState) => state.currency
);

