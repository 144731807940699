import { Component, OnInit, Input, ViewContainerRef, Output, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ToastsManager } from 'ng2-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { TruncateModule } from 'ng2-truncate';


@Component({
  selector: 'usb-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {

  public options = {
    position: ['top', 'right'],
    timeOut: 460,
    lastOnBottom: true,
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    maxLength: 60
  };

  @Input() productQty: number;
  @Input() modalRoomRef: BsModalRef;
  @Input() product: any;
  @Input() results: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() payload?: any;
  @Input() itineraryItem?: any;
  @Input() origin: any;
  @Input() arrayResults?: any;
  @Input() _item?: any;
  @Input() view_info: any;
  @Input() tabActive?: any;
  @Input() departureDate?: any;

  appLoading: boolean = false;
  closeModal: boolean = false;
  // productQty: number;
  // modalRoomRef: BsModalRef;
  // product: any;
  // results: any;
  // form: FormGroup;
  // payloadUpdate?: any;
  // payload?: any;
  // itineraryItem?: any;
  // origin: any;
  // arrayResults?: any;
  // _item?: any;
  // view_info: any;

  constructor(
//
    private modalService: BsModalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalsComponent>,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    console.log("modal product", this.data);

    this.productQty = this.data.productQty ? this.data.productQty : null;
    //this.modalRoomRef = this.data.modalRoomRef;
    this.product = this.data.product ? this.data.product : null;
    this.results = this.data.results ? this.data.results : null;
    this.form = this.data.form ? this.data.form : null;
    this.payloadUpdate = this.data.payloadUpdate ? this.data.payloadUpdate : null;
    this.payload = this.data.payload ? this.data.payload : null;
    this.itineraryItem = this.data.itineraryItem ? this.data.itineraryItem : null;
    this.origin = this.data.origin ? this.data.origin : null;
    this.arrayResults = this.data.arrayResults ? this.data.arrayResults : null;
    this._item = this.data._item ? this.data._item : null;
    this.view_info = this.data.view_info ?  this.data.view_info : null;

    this.tabActive = this.data.tabActive ?  this.data.tabActive : null;
    this.departureDate = this.data.departureDate ?  this.data.departureDate : null;

    // console.log("modal product", this.product);
    // console.log("modal productQty", this.productQty);
    // console.log("modal view_info", this.view_info);
    // console.log("modal itineraryItem", this.itineraryItem);
    // console.log("modal form", this.form);
    // console.log("modal results", this.results);
    // console.log("modal payloadUpdate", this.payloadUpdate);
    // console.log("modal origin", this.origin);
    // console.log("modal modalRoomRef", this.modalRoomRef);
    // console.log("modal tab", this.tabActive);
    // console.log("modal arrayResults", this.arrayResults);

  }

  hideModal(status: string) {
    this.appLoading = true;
    // console.log("status", status);

    const stat = status.split(' - ');
    const type = stat[0];
    const title = stat[1];
    const message = stat[2];

    switch (type) {
      case 'success': this.options.timeOut = 360; //this.toastr.success(title, message);
        break;
      case 'error': this.options.timeOut = 1060;  //this.toastr.error(title, message);
        break;
      case 'warning': //this.toastr.warning(title, message);
        break;
    }


    if(status == 'close'){
      this.closeModal = true;
      this.dialogRef.close();
      this.appLoading = false;
    }else{
      this.closeModal = true;
      this.dialogRef.close(true);
      this.appLoading = false;
    }

  }

}
