import { Component, OnInit, EventEmitter, Output, Input, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { TimepickerModule } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../../shared/rest.service';
import { UbicacionesService } from '../../service/ubicaciones.service';
import { Observable } from 'rxjs/Observable';
import { TransportsService } from '../../service/transportsMockUp.service';
import * as moment from 'moment';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { AddressesService } from '../../service/addresses.service';
import { CustomValidator } from '../../../../shared/customValidators';
//import { ToastsManager } from 'ng2-toastr';
import { ItineraryService } from '../../../../shared/itinerary.service';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';

@Component({
  selector: 'usb-transports',
  templateUrl: './transports.component.html',
  styleUrls: ['./transports.component.scss'],
  providers: [TransportsService, DatePipe, AddressesService, UpperCasePipe]
})
export class TransportsComponent implements OnInit {
  destineValue: boolean;
  destineVale: boolean;
  originValue: boolean;
  selectediataCodeDestiny: any;
  selectediataCodeOrigin: any;
  iataCodeOrigin: any;
  nameOrigin: any;
  iataCodeDestiny: any;
  nameDestination: any;
  isLoadingResult: boolean;



  @Output() origin = new EventEmitter<any>();
  @Input() _close: boolean;
  @Output() modal = new EventEmitter<string>();
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  

  @ViewChild("destination") myInputField: ElementRef;

  _resultsQuery: any;
  _findData: boolean;
  _form: FormGroup;
  minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  _maxPersons: any;
  isCollapsed = false;
  ismeridian = false;
  dest: any;
  ori: any;
  pickup: any;
  dropoff: any;
  passengers: string;
  isOpen= false;
  _actualItinerary: any;
  departureDateIn: any;
  arrivalDateIn: any;
  departureDateOut: any;
  airportIn: any;
  idFlight: any;
  itinerary: any;
  message = [];
  constructor(
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private datePipe: DatePipe,
    private upperPipe: UpperCasePipe,
    private _router: Router,
    private _restService: RestService,
    private ubicacionesService: UbicacionesService,
    private _addressService: AddressesService,
    private transportsService: TransportsService,
    public vcr: ViewContainerRef,
//    
    private _itineraryService: ItineraryService,
    public userService:UserService
  ) {
    this._maxPersons = Array.from(Array(5).keys());
    this.dpConfig.containerClass = 'theme-red';
    this.minDate = new Date();
    //this.toastr.setRootViewContainerRef(this.vcr);
  }

  
  ngOnInit() {
    this.passengers = 'Pasajeros';
    this.getFlight();
    this.initForm();

  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.myInputField.nativeElement.focus();
    }, 500);
  }

   async initForm() {
    this._form = this.fb.group({
      type: ['oneTrip', Validators.required],
      firstOrigin: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      firstDestination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      pickUp: ['', Validators.required],
      dropOff: ['', Validators.required],
      firstArrivalDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('secondArrivalDate').value !== null &&
          this._form.get('secondArrivalDate').value !== undefined
        ) {
          const valid = (c.value <= this._form.get('secondArrivalDate').value) ? null : { invalidDate: true };
          this._form.controls['secondArrivalDate'].setErrors(valid);
          return (c.value <= this._form.get('secondArrivalDate').value) ? null : { invalidDate: true };
        }
      }
      ])],
      firstTime: [null, Validators.required],
      firstqtyAdults: [0, [Validators.required, Validators.min(1)]],
      firstqtyChilds: [0, Validators.required],
      firstchildsage: this.fb.array([]),
      secondArrivalDate: [null, Validators.compose([Validators.required,
        (c: FormControl) => {
          if (
            this._form !== undefined &&
            c.value !== null &&
            this._form.get('firstArrivalDate').value !== null &&
            this._form.get('firstArrivalDate').value !== undefined
          ) {
            const valid = (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
            this._form.controls['firstArrivalDate'].setErrors(valid);
            return (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
          }
        } ])
    ],
    secondTime: [null, Validators.required],
    });
    
    await this.handleChange('oneTrip');

  }

  getFlight() {
    this._itineraryService.getActiveItinerary().subscribe(
      (data:any) => {
        if (data) {
          this._actualItinerary = data[0];
          this.itinerary = this._actualItinerary['id'];
          let flight = null;
          if  (this.itinerary) {
              // tslint:disable-next-line:max-line-length
              this._itineraryService._restService.doGet('app', 'itinerary/' + this.itinerary + '/fly').subscribe(
                Response => {
                    if (Response.success === false && Response.message === 'Fly not found') { // no existe vuelo
                      console.log('flights nuLL', Response);
                      // this.initForm();
                    } else {
                      flight = Response['data'][0];
                      console.log('Has flights associated', Response);
                      this.idFlight = flight['id'];
                      this.departureDateIn = new Date(flight['departureIn']);
                      this.arrivalDateIn = new Date(flight['arrivalIn']);
                      this.departureDateOut = new Date(flight['departureOut']);
                      this.airportIn =  flight['airportIn'];
                      setTimeout(() => {
                      //  this.loadinitForm();
                      }, 100);
                    }
                  },
                  Error => {
                    console.log('Has no flights associated', Error);
                  }
              );
          }
        }
      },
      error => {
        console.log(error);
      }
    );
    this._itineraryService.consultActiveItinerary(this.userService._agentID);
}

  handleChange(evt: any) {
    if (evt === 'roundTrip') {
       this.isOpen = true;
       this._form.get('secondArrivalDate').setValidators( Validators.compose([Validators.required,
        (c: FormControl) => {
          if (
            this._form !== undefined &&
            c.value !== null &&
            this._form.get('firstArrivalDate').value !== null &&
            this._form.get('firstArrivalDate').value !== undefined
          ) {
            const valid = (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
            this._form.controls['firstArrivalDate'].setErrors(valid);
            return (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
          }
        } ]));
        this._form.get('secondTime').setValidators(Validators.required);
        this._form.get('secondArrivalDate').updateValueAndValidity();
        this._form.get('secondTime').updateValueAndValidity();
     } else {
        this.isOpen = false;
        this._form.get('secondArrivalDate').clearValidators();
        this._form.get('secondArrivalDate').updateValueAndValidity();
        this._form.get('secondTime').clearValidators();
        this._form.get('secondTime').updateValueAndValidity();
    }
  }

  checkFlight() {
    let  result = true;
    const a = this._form;
    let firstArrivalDate;
    let secondArrivalDate;
    let firstTime;
    let secondTime;
    let arrivalHourIn;
    let arrivalDateIn;
    let departureDateOut;
    let departureHourOut;
    let st; 
    let ft; 
   // Transport
    firstArrivalDate  = moment(a.value['firstArrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
    firstTime = moment(a.value['firstTime'].toString().split(',')[0]).format('HH:mm');
    ft = parseInt(firstTime.split(':')[0], 10) * 60 + parseInt(firstTime.split(':')[1], 10);

    if (a.value['type'] !== 'oneTrip') {
      secondArrivalDate = moment(a.value['secondArrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
      secondTime = moment(a.value['secondTime'].toString().split(',')[0]).format('HH:mm');
      st = parseInt(secondTime.split(':')[0], 10) * 60 + parseInt(secondTime.split(':')[1], 10);
    }
    if (firstArrivalDate >  secondArrivalDate) {
       result = false;
       // tslint:disable-next-line:max-line-length
    //   this.showError('Error, Enter a lower Pickup In Date to the Pickup OUT: ' + firstArrivalDate);
       this.message.push('Error, Enter a lower Pickup In Date to the Pickup OUT: ' + firstArrivalDate);
     }

     if ((ft > st) && (firstArrivalDate === secondArrivalDate)) {
       result = false;
       // tslint:disable-next-line:max-line-length
       //this.showError('Error, Enter a lower Pickup IN time  to the Pickup OUT time ' + secondTime);
       this.message.push('Error, Enter a lower Pickup IN time  to the Pickup OUT time ' + secondTime);
     }

     if ((this.idFlight !== undefined)) {
         arrivalDateIn = moment(this.arrivalDateIn.toString().split(',')[0]).format('YYYY-MM-DD');
         arrivalHourIn = moment(this.arrivalDateIn.toString().split(',')[0]).format('HH:mm');
         const at = parseInt(arrivalHourIn.split(':')[0], 10) * 60 + parseInt(arrivalHourIn.split(':')[1], 10);
         departureDateOut = moment(this.departureDateOut.toString().split(',')[0]).format('YYYY-MM-DD');
         departureHourOut = moment(this.departureDateOut.toString().split(',')[0]).format('HH:mm');
         const _at = parseInt(departureHourOut.split(':')[0], 10) * 60 + parseInt(departureHourOut.split(':')[1], 10);


         if (firstArrivalDate > arrivalDateIn) {
           result = false;
           // tslint:disable-next-line:max-line-length
          // this.showError('Error, Enter a lower Pickup In Date to the Flight Arrival IN: ' + arrivalDateIn);
          this.message.push('Error, Enter a lower Pickup In Date to the Flight Arrival IN: ' + arrivalDateIn);
         }

           ft =  ft + 75; // 1.15 hrs
           if (( ft > at)  && (firstArrivalDate === arrivalDateIn))  {
               result = false;
               // tslint:disable-next-line:max-line-length
             //  this.showError('Error, Enter a lower Pickup IN time (- 1:45hrs) to the Flight Arrival IN: ' + arrivalHourIn);
             this.message.push('Error, Enter a lower Pickup IN time (- 1:45hrs) to the Flight Arrival IN: ' + arrivalHourIn);
           }
 
           if (secondArrivalDate > departureDateOut) {
             result = false;
             // tslint:disable-next-line:max-line-length
            // this.showError('Error, Enter a lower Pickup Out Date to the Flight Deaperture IN: ' + departureDateOut);
             this.message.push('Error, Enter a lower Pickup Out Date to the Flight Deaperture IN: ' + departureDateOut);
           }
 
           st =  st + 210; // 1.15 hrs

           if (( st > _at) && (secondArrivalDate === departureDateOut)) {
             result = false;
             // tslint:disable-next-line:max-line-length
             //this.showError('Error, Enter a lower Pickup OUT time (- 3:30hrs) to the Flight Deaperture IN: ' + arrivalHourIn);
             this.message.push('Error, Enter a lower Pickup OUT time (- 3:30hrs) to the Flight Deaperture IN: ' + departureHourOut);
           }
       }
   return result;
  }


  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {

    this.message.splice(0, this.message.length);
    if (this.checkFlight()) {
      this.message.length = 0;
      this.goSearch(form, event, dummy); // llamar al metodo ara efectuar la busqueda
    }
  }

  goSearch(form: FormGroup, event?: Event, dummy?: Object) {
    this.originValue = ((this.selectediataCodeOrigin !== undefined) && (this.selectediataCodeOrigin !== null));
    this.destineVale = ((this.selectediataCodeDestiny !== undefined) && (this.selectediataCodeDestiny !== null));
    if ((this.originValue) && (this.destineVale)) {
      if (event) {
        event.preventDefault();
      }
      this._emitLoading(true);
      const payload = this._payload(form, this.selectediataCodeDestiny, this.selectediataCodeOrigin);
      const payloadArray = [payload];
      this._restService.doPost('app', 'search', payload).subscribe(
        (response:any) => {
          // _response = Response['status'] ? Response : this.transportsService.getTransports(payload);
          this.payload.emit(payloadArray);
          if (response !== null && response.results !== null) {
            this._colocarSearchIdEnlosHoteles(response);
          }
          this._emitOrigin('TRANSPORTS');
          this._handleResponse(response, 'TRANSPORTS', form);
          this._emitLoading(false);
          this._router.navigate(['/results']);
        },
        error => {
          this._emitLoading(false);
          //this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
          //^*********** DESCOMENTAR PARA BUSQUEDA EN SERVICIO MOCKUP***********////

          // _response = this.transportsService.getTransports(payload);
          // this.payload.emit(payloadArray);
          // if (_response !== null && _response.results !== null) {
          //   this._colocarSearchIdEnlosHoteles(_response);
          // }
          // this._emitOrigin('TRANSPORTS');
          // this._handleResponse(_response, 'TRANSPORTS', form);
          // setTimeout(() => {
          //   this._router.navigate(['/results']);
          // }, 300);
        });
    } else {
      // tslint:disable-next-line:curly
      if (!this.originValue) {
        this._form.get('firstOrigin').setErrors({ invalidDestination: true });
      }
      if (!this.destineVale) {
        this._form.get('firstDestination').setErrors({ invalidDestination: true });
      }

    }
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }

  _colocarSearchIdEnlosHoteles(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }
  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = form.value['arrivalDate'];

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = 'No results found for the search';
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
        this.results.emit(arrayResults);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = 'No results found for the search';
      this.results.emit(this._resultsQuery);
    }
  }
  _payload(form: FormGroup, iataCodeDestiny: string, iataCodeOrigin: string): Object {

    const payloadTransport = [];
    const payload = {
      providersId: null,
      categoriesId: [8],
      origin: iataCodeOrigin,
      destination: iataCodeDestiny,
      priceFrom: null,
      priceTo: null,
      arrivalDate: this.getFormattedDate(form.value['firstArrivalDate'], 'date'),
      departureDate: this.getFormattedDate(form.value['secondArrivalDate'], 'date'),
      qtyProduct: 1,
      qtyPax: +form.value['firstqtyAdults'],
      others: {
        maxRequestTimeOnSeconds: 20,
        minNumResults: 3,
        maxNumResultsPerAdapter: "",
        transfers: {
          transferType: 'Regular / Premium',
          pickupTime: this.getFormattedDate(form.value['firstTime'], 'time'),
         /// dropoffTime:  this.getFormattedDate(form.value['secondTime'], 'time'),
          dropoffTime: form.value['type'] !== 'oneTrip' ? this.getFormattedDate(form.value['secondTime'], 'time') : null,
          pickupLocation: {
            id: this.pickup['id'],
            type: this.pickup['type'],
            providerId: this.pickup['providerId'],
            nameAdapter: this.pickup['nameAdapter']
          },
          dropoffLocation: {
            id: this.dropoff['id'],
            type: this.dropoff['type'],
            providerId: this.dropoff['providerId'],
            nameAdapter: this.dropoff['nameAdapter']
          },
          paxList: {
            adults: +form.value['firstqtyAdults'],
            children: this.getAges(form.value['firstchildsage'])
          },
          vehiclesDist: [
            {
              adults: +form.value['firstqtyAdults'],
              children: this.getAges(form.value['firstchildsage'])
            }
          ],
          oneWay: form.value['type'] !== 'oneTrip' ? false : true
        }
      }
    };
    payloadTransport.push(payload);
    console.log('pay trans HOME ----->', payloadTransport);
    return payload;
  }

  getAges(ages: Array<any>): any {
    const _ages = [];
    ages.forEach(age => {
      _ages.push(age['age']);
    });
    return _ages;
  }

  getFormattedDate(date: any, format: string): any {
    if (format === 'time') {
      const _date = date ? this.datePipe.transform(date, 'HH:mm') : '13:00';
      return _date;
    } else {
      const _date = date ? this.datePipe.transform(date, 'yyyy-MM-dd') : '2018-05-30';
      return _date;
    }
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  _addChild(key: string, key2: string) {
    setTimeout(() => {
      const ages: any = this._form.get(key) as FormArray;
      const qty: number = +this._form.value[key2];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  formArray(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }

  /***********************************************Auto Complete********************************************/
  _myListFormatter(data: any): string {
    return `${data['cityName']} (${data['cityIataCode']})`;
  }
  _myListFormatterAdd(data: any): string {
  let type: any = '';
  let icon: any = '';
   icon = 'fa fa-hotel';
  switch (data['type']) {

    case 'airport': {
      icon = 'fa fa-plane';
      break;
    }
    case 'hotel': {
      icon = 'fa fa-hotel';
      break;
    }
    case 'port': {
      icon = 'fa 	fa fa-ship';
      break;
    }
    default: {
      icon = '';
      break;
    }
  }

  type = ' <span class=" '+ icon +' "<span/> ';

    return `${type} ${data['address']}`;
   // return `${data['address']} (${data['type']})`;
  }

  observableSourceOri = (keyword: any): Observable<any[]> => {

    let type: any;
    const results = this._addressService._getAddress(this.ori['cityIataCode'], keyword, 8, type);
    //const results = this._addressService.getAddress(this.ori['cityIataCode'], keyword, 8);
    if (results !== undefined) {
      return results;
    }
  }

  observableSourceDest = (keyword: any): Observable<any[]> => {
    let type: any;
    if ((this.pickup.hasOwnProperty('type')) && (this._form.get('pickUp').value !== '')) {
      type = this.pickup['type'];
    }

   console.log(" type------->",  type);
    const results = this._addressService._getAddress(this.dest['cityIataCode'], keyword, 8, type);
    //const results = this._addressService.getAddress(this.dest['cityIataCode'], keyword, 8);
    if (results !== undefined) {
        console.log('results' + results);
      return results;
    }
  }

  _myCallbackPickup($event) {
    this.pickup = null;
    if (($event !== null) && ($event instanceof Object)) {
      this.pickup = $event;
      console.log('pick', this.pickup);
    } else {
      this._form.get('pickUp').setErrors({ invalidLocation: true })
    }
  }

  _myCallbackDropoff($event) {
    if (($event !== null) && ($event instanceof Object)) {
      this.dropoff = $event;
      console.log('drop', this.dropoff);
    } else {
      this._form.get('dropOff').setErrors({ invalidLocation: true })
    }
  }
  observableSource = (keyword: any): Observable<any[]> => {
    const results = this.ubicacionesService.getCitys(keyword);
    if (results !== undefined) {
      return results;
    }
  }

  _myCallbackDestiny(data: any): string {
    this.selectediataCodeDestiny = this.dest['cityName'];
    this.selectediataCodeDestiny = this.dest['cityIataCode'];
    // this.nameDestination = this.dest['cityName'];
    // this.iataCodeDestiny = this.dest['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _myCallbackOrigin(data: any): string {
    if(this.userService.itineraryActive.length == 0){
      //this.toastr.warning("Verify itinerary information", 'Important!!');
    }else{
    }
    
    this.selectediataCodeOrigin = this.ori['cityName'];
    this.selectediataCodeOrigin = this.ori['cityIataCode'];
    // this.nameOrigin = this.ori['cityName'];
    // this.iataCodeOrigin = this.ori['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }
  /***********************************************Auto Complete********************************************/

  _over() {
    this.modal.emit('false');
  }

  clickOutside(event: any) {
    this.setPassanger();
  }

  _openModal() {
    this.isCollapsed = true;
    this._close = false;
    setTimeout(() => {
      this.modal.emit('true');
    }, 100);
  }

  _confirm() {
    this.isCollapsed = false;
    this.setPassanger();
  }

  setPassanger() {
    let adults = this._form.value['firstqtyAdults'];
    let childs = this._form.value['firstqtyChilds'];
    let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
    adults = adults != undefined ? adults + " Adults " : null;
    childs = childs != undefined ? " - " + childs + " Childs " : null;

    this.passengers = noSelected ? "Pasajeros" : adults + childs;
  }

}
