import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup } from '@angular/forms';
import { ItineraryService } from '../../itinerary.service';
import { RestService } from '../../rest.service';
import { ResponseSearhDetailsReservation } from './ResponseSearchDetailsReservation';
import { isValidOwener, fillOwnerMessage } from '../../../utils/Functions';
import { CookieService } from 'ngx-cookie';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../../material-modal-confirm/material-modal-confirm.component';
import { UserService } from '../../../pages/com.usblick.common/auth/user.service';
import { MaterialModalAlertComponent } from '../../material-modal-alert/material-modal-alert.component';
import {  } from 'google-maps';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from "environments/environment";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { log } from 'console';
import { element } from 'protractor';
import { title } from 'process';


@Component({
  selector: 'usb-modal-tours',
  templateUrl: './modal-tours.component.html',
  styleUrls: ['./modal-tours.component.scss']
})

export class ModalToursComponent implements OnInit {


  @Input() tour: any;
  @Input() payloadUpdate: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() itineraryItem?: any;
  @Input() form: any;
  @ViewChild("gmap") gmapElement: any;
  @Output() refModal = new EventEmitter<any>();

  _select: any;
  _disabledButton: boolean;
  _itemOption: any;
  _findData: boolean;
  _total: number;
  _itemSelected: ResponseSearhDetailsReservation = {
    availableDate: undefined,
    questions: []
  };
  _loadDates = false;
  _selectDate = 'Select date...';
  notFoundDates: boolean;
  toursAvailableDates: any = [];
  dateAvailablesReady: boolean = false;
  dateSelected: boolean = false;
  dayInitPayload:string;
  dayFinalPayload:string;
  companyNamesOpt:string[] = [];
  itemsTour:any[] = [];

  optionsTour:any[] = [];
  selectTour:any;
  arrayDatesAvailables:any[] = [];
  dateLastFrom:string = '';
  dateLastTo:string = '';
  totalProfValues:number = 0;
  toggle = false;
  status = 'Enable';
  dateSelect:string = '';
  infoArr: string[] = [];
  adultsTotalPrice:number = 0;
  childsPriceFrom:number = 0;
  childsPriceTo:number = 0;
  hotelsTitleArr:any[] = [];
  titleSelectUniqueHotel:string = '';
  textComplete: boolean = true;
  textInfoExtra:string = '';
  toggleTextBtn:string = '';
  arrayPricesPassengers: any[] = [];
  // currencySelected:string = '';

  private readonly getValueTours$ = new Subject();

  showTap_1 = false;
  showTap_2 = false;
  showTap_3 = false;
  showTap_4 = false;

  tab:string = 'tour'

  imgList: any =[
    '/assets/img/app/tour/01.jpg',
    '/assets/img/app/tour/02.jpg',
    '/assets/img/app/tour/03.jpg',
    '/assets/img/app/tour/04.jpg',
    '/assets/img/app/tour/05.png',
    '/assets/img/app/tour/01.jpg',
    '/assets/img/app/tour/02.jpg',
    '/assets/img/app/tour/03.jpg',
    '/assets/img/app/tour/04.jpg',
    '/assets/img/app/tour/05.png'
  ]
  imgSelect: number = 0
  imagesArrList: any[] = [];
  daysMissing:any[] = [];

  constructor(
    private toastr: ToastrService,
    private _itineraryService: ItineraryService,
    private _restService: RestService,
    private _cookieService: CookieService,
    private datePipe:DatePipe,
    public dialog: MatDialog,
    public userService:UserService,
    public spinner:NgxSpinnerService
  ) {}

  ngOnInit() {
    this._disabledButton = true;
    this.dateSelect = '';
    console.log(' this.tour.======>',this.tour);

    this.textInfoExtra = this.tour.additionalInfo.extra_info;

    this.optionsTour =  this.tour.additionalInfo.tours.options;
    this.selectTour = this.tour.additionalInfo.tours.options[0]; // seleccionar el prmer tour
    // this.currencySelected = this.tour.additionalInfo.currency;
    // console.log('currency selected tours', this.currencySelected);
    // console.log("this.selectTour", this.selectTour);

    // this.imagesArrList = this.tour.additionalInfo.images;

    for (let i = 0; i < this.tour.additionalInfo.images.length; i++) {
      const element = this.tour.additionalInfo.images[i].image;
      this.imagesArrList.push(element);

    }

    this.tour.additionalInfo.tours.options.forEach(opt => {
      console.log('options', opt);
      this._itemSelected.questions = opt.questions;
      console.log('itemSelected', this._itemSelected);

    });

    //this._itemSelected.availableDate = this.getDatesSelected(this.dateSelect);

    this.infoArr = this.tour.additionalInfo.general_info;

   /* this.tour.additionalInfo.tours.options.forEach(element => {
     this.setItemDate(null, element);
    });*/

    this.dayInitPayload = moment(this.payloadUpdate.arrivalDate).format('YYYY-MM-DD').toString();
    this.dayFinalPayload = moment(this.payloadUpdate.departureDate).format('YYYY-MM-DD').toString();



    this.dateLastFrom = this.selectTour.availableDates[0].from;
    this.dateLastTo = this.selectTour.availableDates[this.selectTour.availableDates.length - 1].to;
    this.totalProfValues = this.optionsTour[0].totalPriceProf;
    this.getValueTours$.subscribe((res:any) =>{
      this.selectTour = res;
      this.dateLastFrom = this.selectTour.availableDates[0].from;
      this.dateLastTo = this.selectTour.availableDates[this.selectTour.availableDates.length - 1].to;
      this.totalProfValues = this.selectTour.totalPriceProf;
    })

    this.getHotelsSelect();

    this.toggleTextBtn = 'Ver Más';
      this.toggleTextComplete();

    this.selectTour.prices.forEach((prices:any) => {
      this.arrayPricesPassengers.push(prices);
    });

    this.daysHeaderCalendar();
  }

  close(){
    this._emitRefModal('close');
  }

  getdataSubscription(){
    this._itineraryService.dateCatchSelect$.subscribe((res:any) =>{
      this.dateSelect = res;
    })

    this._itineraryService.valueCatchBoolean$.subscribe((res:boolean) =>{
      this._disabledButton = res;
    })

    this._itineraryService.titleHotelUnique$.subscribe((res:any) =>{
      this.titleSelectUniqueHotel = res;
    })


  }

  continue() {
    this._findData = true;
    this._itemOption= this.selectTour;
    console.log('---ModalTourComponet--- continue()---');
    console.log('Item a agregar ', this._itemOption);
    console.log('userService itineraryActive -->', this.userService.itineraryActive[0].items);
    let tourNew = this.userService.itineraryActive[0].items.find((element:any) => element.id == this.tour.id);
    console.log("found tour ", tourNew);
    console.log("this._itemSelected.availableDate.arrivalDate", this._itemSelected.availableDate.arrivalDate);
    this._emitRefModal('');
    if(tourNew){
      if(tourNew.arrivalDate == this._itemSelected.availableDate.arrivalDate){
        var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
          height: '180px',
          width: '350px',
          data: 'already have this product',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if(result){
            this.addItinerary(this._itemOption, this.selectTour);
          }
        });
      }else{
        this.addItinerary(this._itemOption, this.selectTour);
        this.closeModal();

      }

    }else{
      this.addItinerary(this._itemOption, this.selectTour);
      this.closeModal();
    }

    this._itineraryService.itinerary.arrivalDate = this._itineraryService.itinerary?.items[0].arrivalDate;
    this._itineraryService.itinerary.departureDate = this._itineraryService.itinerary?.items[0].departureDate;
    // this._itineraryService.itinerary.currency = this.currencySelected;
    this._itineraryService.updateItinerary(this._itineraryService.itinerary);
  }

  addItinerary(item: any, tour: any, reservation?: any) {
    let titleSelectHotel:any;

    //validar si owner esta lleno
    if (!isValidOwener(this._cookieService.get('clientActive'))) {
      //this.toastr.warning(fillOwnerMessage());
      return;
    }
    console.log('ModalRoomsComponent--- addItinerary()');
    console.log("this.origin", this.origin);
    //console.log(this.arrayResults);
    console.log("add tour", tour);

    console.log('unique hotel selec ->', this.titleSelectUniqueHotel);




    item.taxes = tour.taxes ? tour.taxes : [];
    item.taxPrice = tour.taxPrice ? tour.taxPrice : 0; //monto impuesto por noche
    item.tax = tour.tax ?  tour.tax : 0; // valor del iva aplicado
    item.profility = tour.profility ? tour.profility : 'Falta profility';

    item.id = tour.optionId;//tour.id; // tour.id + '-' + item.optionId;
    item.searchId = this.arrayResults[0].searchId;
    item.providerId = tour.providerId;
    item.providerName = this.tour.providerName;
    // item.form = this.form.value;
    item.tourId = this.tour.productId;
    item.idProduct = tour.optionId; //tour.productId;
    item.categoryName = 'TOURS';
    item.title = tour.option_name;
    item.productName =tour.option_name;
    item.location = this.tour.additionalInfo.location;
    item.arrivalDate = this.dayInitPayload;//this._itemSelected.availableDate.arrivalDate;
    item.departureDate = this.dayFinalPayload;//this._itemSelected.availableDate.departureDate;
    item.questions = this._itemSelected.questions;
    item.hasReservationQuestions = this.tour.additionalInfo.tours.hasReservationQuestions;
    item.company = this.tour.additionalInfo.tours.company;
    item.images = this.tour.additionalInfo.images;
   // item.availableDates =tour
    item.cancellation_policy = tour.cancellation_policy;
    item.selectDay = this.dateSelect;
    item.totalPrice = tour.totalPrice;
    item.totalPriceProf = tour.totalPriceProf;
    item.updateDate = new Date();
    item.productKey = this.getRandomUniqueId();
    item.titleHotel = this.titleSelectUniqueHotel;


    this.form = JSON.parse(localStorage.getItem("form"));
    console.log("form agregando", this.form);

    // estoy llegando del home
    item.form = this.form;
    console.log("form agregando desde el home", this.form);
    item.payloadUpdate = this._payloadUpdateTours(this.form, item); // ( this.form.value
    console.log(item.payloadUpdate);
    item.destination = this._itineraryService.getDestinationInfo(this.form, item);

    if (this.itineraryItem) {
      item.destination = this.itineraryItem.destination;

      console.log("item agregando", item);
      this._itineraryService.remove(this.itineraryItem, item);
      this._emitRefModal("success - Congratulations - Product updated succesfully");
      this.toastr.success("Congratulations !!", "Product Successfully updated");
    } else {
      console.log("item antes de guardar", item);
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(item).subscribe(
        (response: any) => {
          console.log("Add-Success: ", response);
          if (typeof response === "string") {
            this._emitRefModal("error - Error - " + response);
          } else {
            // this._itineraryService.currencySuccessEmit.next(item.currency);
            this._emitRefModal("success - Congratulations - Product added succesfully to your itinerary");
            this.toastr.success("¡Felicitaciones! Producto agregado con éxito. ");
          }
          this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary["items"]);
        },
        (error) => {
          console.log("Add-Error: ", error);
          this._emitRefModal("error - Error - Item was not added to your itinerary");
          this._itineraryService.sendMessageSubscriptorLoading(false);
        }
      );
    }
  }

  closeModal() {
    this._findData = false;
    this._select = null;
    this._disabledButton = false;
    this.dateSelect = null;
    this._emitRefModal('fail close modal');
  }

  setTotal(index: any) {
    this._select = index;
    this._total = this._itemOption.netPriceProf;
    this._disabledButton = this.verifyButton();

    //console.log("this._itemOption", this._itemOption);
    console.log("this._select", this._select);
    console.log("this._disabledButton", this._disabledButton);
  }

  activeDate(itemSelected:any){
    console.log("ïtem select date", itemSelected);
    this.dateSelected = true;
    this._itemSelected.availableDate = this.getDatesSelected(itemSelected);
   // this._disabledButton = this.verifyButton();
    console.log("this._itemSelected.availableDate 2", this._itemSelected.availableDate);
  }

  setItemDate(itemSelected: any, itemTours: any) {
    console.log("AQUI EL ITEM itemSelected", itemSelected);
    console.log("AQUI EL ITEM SELECCIONADO itemTours", itemTours);
    this.itemsTour.push(itemTours);
    console.log("this._itemSelected.availableDate 1", this._itemSelected.availableDate);

    if (itemSelected != null) {
      this._itemSelected.availableDate = this.getDatesSelected(itemSelected);
      console.log("this._itemSelected.availableDate 2", this._itemSelected.availableDate);
    }

    if (itemTours.availableDates && itemTours.availableDates.length == 0 && this.tour.additionalInfo.tours.hasReservationQuestions) {
      this._loadDates = true;

      var payload = this.getPayloadSearchDetailsToReservation(itemTours);

      this._restService.doPost('api', '/searchdetailstoreservation', payload).subscribe(
        (response:any) => {
          console.log(response);
          this.notFoundDates = response.results.length == 0;
          if (itemTours.availableDates) {
            itemTours.availableDates = this.getArrivalDateFromResponse(response);
            this.dateAvailablesReady = true;
          }
          this._itemSelected.questions = response.results.questions;
          console.log('itemselected -->',this._itemSelected.questions);

          this._loadDates = false;
        }, error => {
          console.log(error);
          this._loadDates = false;
          this.notFoundDates = true;
        }
      );
    } else {

    if (this.dateAvailablesReady) {
        //this._disabledButton = !(this._select !== undefined && this._itemSelected.availableDate !== undefined);
        this._disabledButton = this.verifyButton();
        console.log("this._disabledButton", this._disabledButton);
        return;
    }

      this.tour.additionalInfo.tours.options.forEach(element => {
        if (element.name == itemTours.name) {
          element.availableDates.forEach(toursDates => {
            this.toursAvailableDates.push(toursDates);
          });
        }
      });

      itemTours.availableDates = this.toursAvailableDates;
      this.dateAvailablesReady = true;
    }

    this._disabledButton = this.verifyButton();
    console.log("this._disabledButton", this._disabledButton);
  }

  verifyButton():boolean{
    //if(this._select && this._itemSelected.availableDate){
    console.log("verify this select es: " + this._select + " this.dateSelected " + this.dateSelected);
    if(this._select && this.dateSelected){
      console.log("Se activa el botón");
      return false;
    }else
    console.log("Se desactiva el botón");
    return true;
  }



  _payloadUpdateTours(form: any, item: any): Object {
    console.log("item _payloadUpdateTours", item);
    console.log("form _getRoomDist", form);
    console.log("payloadUpdate", this.payloadUpdate);
    let qtyPax;
    let others;
    let payload;
    console.log(this.origin);
    if (this.origin === undefined || this.origin == "HOTELS" || this.origin == "TOURS") {
      others = {
        agedriver: environment.agedriver,
        serialized: environment.serialized,
        maxRequestTimeOnSeconds: environment.maxRequestTimeOnSeconds,
        minNumResults: environment.minNumResults,
        maxNumResultsPerAdapter: environment.maxNumResultsPerAdapter,
        tours: this.payloadUpdate.others !== undefined ? this.payloadUpdate.others.tours : this.payloadUpdate.products[0].others
      };
    }

    payload = {
      products: [
        {
          providerId: item.providerId,
          nameAdapter: item.nameAdapter,
          categoriesId: [6],  // tours
          productId: item.tourId,
          productName: item.title,
          destination: this.tour.destination,
          priceFrom: null,
          priceTo:null,
          arrivalDate: item.arrivalDate,
          departureDate: item.departureDate,
          others: others
        }
      ]
    };
    console.log("item _payloadUpdateHotel ", payload);
    return payload;
  }

  _emitRefModal(status: string) {
    this.refModal.emit(status);
    this._disabledButton = true;
  }


  /**
   * Arma el payload de busqueda para el endPoint
   * @link /api/v1/app/searchdetailstoreservation/
   * @param item
   *
   */
  getPayloadSearchDetailsToReservation(item: any) {

    var payload = {
      providerId: this.tour.providerId,
      nameAdapter: this.tour.nameAdapter,
      dateIni: this.tour.dateIni,
      dateEnd: this.tour.dateEnd,
      adults: this.payloadUpdate.qtyPax === undefined ? this.payloadUpdate.products[0].qtyPax : this.payloadUpdate.qtyPax,
      children: this.payloadUpdate.others === undefined ?  this.payloadUpdate.products[0].others.tours.children :  this.payloadUpdate.others.tours.children,
      productId: this.tour.productId,
      optionId: item.optionId,
      hasReservationQuestions: this.tour.hasReservationQuestions,
      company: this.tour.company
    }
    return payload;
  }

  /**
   * Obtiene el arreglo de fechas disponibles para los tours de Hotel Beds
   * @param response
   */
  getArrivalDateFromResponse(response: any) {
    var dateAvailable = [];
    if (response && response.results) {
      if (response.results.availableDates) {
        response.results.availableDates.forEach(element => {
          dateAvailable.push(element.from + " / " + element.to);
        });
      }
    }
    return dateAvailable;
  }


  getDatesSelected(itemSelected: string) {
    var dates;
   // if (itemSelected && itemSelected !== this._selectDate) {
      dates = {
        arrivalDate: itemSelected,
        departureDate: itemSelected
   //   }
     /* if (itemSelected.includes('/')) {
        var datesArray = itemSelected.split('/');
        dates = {
          arrivalDate: datesArray[0],
          departureDate: datesArray[1]
        }
      } else {
        dates = {
          arrivalDate: itemSelected,
          departureDate: itemSelected
        }
      }*/
    }
    this.dateSelected = true;
    return dates;
  }


  mapMarker() {
    //se inicializa el mapa con zoom 4

    // let latitudeValue = this.tour.additionalInfo.location_gps.latitude;
    // let longitudeValue = this.tour.additionalInfo.location_gps.longitude;
    // const myLatLang = new google.maps.LatLng(latitudeValue, longitudeValue)
    // var geocoder;
    // var mapa;
    // var mapOptions = {
    //   center: myLatLang,
    //   zoom: 4,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // };

    // //seteamos el maa
    // mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

    // //buscamos las coordenadas con la direccion dada del hotel
    // geocoder = new google.maps.Geocoder();
    // var title =  this.tour.title;
    // geocoder.geocode({ address: this.tour.additionalInfo.location.trim() }, (results, status) => {

    //   if (status.toString() == "OK") {
    //     var marker = new google.maps.Marker({
    //       map: mapa,
    //       position: myLatLang,
    //       title: title,
    //     });

    //     google.maps.event.trigger(mapa, "resize");
    //     //colocamos el market
    //     mapa.panTo(marker.getPosition());
    //     mapa.setZoom(16);
    //     marker.setMap(mapa);
    //   } else {
    //     console.log("No se obtuvo la ubicacion del mapa: " + status);
    //   }
    // });
    // mapa.set("parent", this);
  }

 /* mapMarker(){
    //se inicializa el mapa con zoom 4
    this.tab = 'maps'
    const lat = this.tour.additionalInfo.location_gps.latitude;
    const lng = this.tour.additionalInfo.location_gps.longitude;
    console.log('latitude ->', lat);

    let mapa:any;
    const mapOptions = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    //seteamos el maa
    mapa = new google.maps.Map(this.gmap.nativeElement, mapOptions);

    new google.maps.Marker({position: {lat: lat, lng: lng}, map: mapa});
    mapa.set('parent', this);
  } */

  prev(){
    if(this.imgSelect>0){
      this.imgSelect=this.imgSelect-1
    }
    if(this.imgSelect<=5){
      this.scroll('l')
    }
  }
  next(){
    if(this.imgSelect<(this.imgList.length-1)){
      this.imgSelect++
    }
    if(this.imgSelect>=5){
      this.scroll('r')
    }
  }
  scroll(dir){
    let idc = 'slide-miniatura'
    let content = document.getElementById(idc)
    const stepr = 150
    const stepl = -150
    if(dir=='l'){
      content.scrollLeft += stepl
    }else if(dir=='r'){
      content.scrollLeft += stepr
    }
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

generateCalendar() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const startDate = new Date(year, month, 1);

  const endDate = new Date(year, month + 1, 0);
  const nextMonthEndDate = new Date(year, month + 10, 0);
  let startDay = startDate.getDay();
  startDay = startDay === 0 ? 6 : startDay - 1;
  const daysInMonth = endDate.getDate();
  const daysInPrevMonth = startDate.getDay();
  const nextMonth = nextMonthEndDate.getDate();
  const calendar = [];




  for (let i = startDay; i > 0; i--) {
    const date = moment(new Date(year, month, 1 - i)).format('YYYY-MM-DD').toString();
    const totalPriceProf = this.selectTour.totalPriceProf;
    this.totalProfValues = totalPriceProf;

    // this.datePipe.transform(new Date(year, month, 1 - i), 'dd-MM-YY');
    calendar.push({
      date,
      number: date,
      isCurrentMonth: false,
      totalPriceProf
    });
  }


  for (let i = 1; i <= daysInMonth; i++) {
    const date =  moment(new Date(year, month, i)).format('YYYY-MM-DD').toString();
    const totalPriceProf = this.selectTour.totalPriceProf;
    calendar.push({

      date,
      number: i,
      isCurrentMonth: true,
      totalPriceProf
    });
  }

  for (let i = 1; calendar.length <= 150 ; i++) {
    const date = moment(new Date(year, month + 1, i)).format('YYYY-MM-DD').toString();
    const totalPriceProf = this.selectTour.totalPriceProf;

    calendar.push({
      date,
      number: i,
      isCurrentMonth: false,
      totalPriceProf
    });
  }

  return calendar;
}

toggleTap(tap: any, selectTour) {
  // Cerramos todos los taps que estén abiertos


  this.showTap_1 = false;
  this.showTap_2 = false;
  this.showTap_3 = false;
  this.showTap_4 = false;
  // Abrimos el tap correspondiente
  this[tap] = true;
  this.selectTour = selectTour;
  console.log(  this.selectTour);
  this.dateLastFrom = this.selectTour.availableDates[0].from;
  this.dateLastTo = this.selectTour.availableDates[this.selectTour.availableDates.length - 1].to;
  this.totalProfValues = this.selectTour.totalPriceProf;

  this.getPricesTours(selectTour)
}

// validateValuesAccordion(data:any){
//   // let eventCatchTour:any;

//   /* switch (data.option_name) {
//    case this.itemsTour[0].option_name:
//       eventCatchTour = data;
//       break;
//     case this.itemsTour[1].option_name:
//       eventCatchTour = data;
//       break;
//     case this.itemsTour[2].option_name:

//       eventCatchTour = data;
//       break;
//     case this.itemsTour[3].option_name:

//       eventCatchTour = data;
//       break;
//     default:
//       console.log('error');

//       break;
//   }

//   this.getValueTours$.next(eventCatchTour)
//   // if(event.option_name == this.itemsTour[0].option_name){
//   //   eventCatchTour = event;
//   // }
//   // if(event.option_name == this.itemsTour[1].option_name){
//   //   eventCatchTour = event;
//   // }
//   // if(event.option_name == this.itemsTour[2].option_name){
//   //   eventCatchTour = event;
//   // }
//   // this._itineraryService.sendDataOptions(eventCatchTour); */
// }

getDateClick(date:any){

  this.dateSelect = date;
  this._itemSelected.availableDate = this.getDatesSelected(this.dateSelect);

  this._itemSelected.availableDate.arrivalDate = this.dateSelect;
  this._itemSelected.availableDate.departureDate = this.dateSelect;

  let valueButtonChange = false;

  if(date < this.dateLastFrom || date > this.dateLastTo){
    valueButtonChange = true;
  }


  // this._itineraryService.valueCatchBoolean$.next(valueButtonChange);
  this._itineraryService.dateCatchSelect$.next(date);
  this._disabledButton = valueButtonChange;
  this.dateSelect = date;
}

getHotelsSelect(){

  for (let i = 0; i < this.userService.itinerary.length; i++) {
    const element = this.userService.itinerary[i].item;
    if(element.categoryName === 'HOTELS'){
      this.hotelsTitleArr.push(element.title)
    }
  }
}

getUniqueHotelSelect(title:any){
  // console.log('title-hotel -->', hotelsObj);
  console.log('title-hotel -->', title);
  this._itineraryService.titleHotelUnique$.next(title);
  this.titleSelectUniqueHotel = title;

}

toggleTextComplete(){
  this.textComplete = !this.textComplete;
  if(this.textComplete){
    this.toggleTextBtn = 'Ver Menos';
  }else{
    this.toggleTextBtn = 'Ver Más';
  }
}

getRandomUniqueId(){
  return Math.floor(Math.random() * 20000000000000) + Date.now();
}


getPricesTours(selectTours:any){

  this.arrayPricesPassengers = [];

  selectTours.prices.forEach((prices:any) => {
    this.arrayPricesPassengers.push(prices);
  });
}

daysHeaderCalendar():void{

  moment.locale('es');

  let payloadDateInitial = moment(this.payloadUpdate.arrivalDate);
  let payloadDateFinal =  moment(this.payloadUpdate.departureDate);

  this.daysMissing = [];

  let currentDate = payloadDateInitial.clone();

  while(currentDate.isBefore(payloadDateFinal) && this.daysMissing.length < 7){
    this.daysMissing.push(currentDate.format('ddd'));
    currentDate.add(1, 'days');
  }

  if(this.daysMissing.length != 7){
    this.daysMissing.push(payloadDateFinal.format('ddd'));
  }
}


}
