import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ResultsSearchService } from '../../../../home/results-search.service';
import { ItineraryService } from '../../../../shared/itinerary.service';
//import { ToastsManager } from 'ng2-toastr';
import { Procesed } from '../../../../shared/enum-procesed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalsComponent } from '../../../../shared/modals/modals.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'usb-rent-a-car-result',
  templateUrl: './rent-a-car-result.component.html',
  styleUrls: ['./rent-a-car-result.component.scss']
})
export class RentACarResultComponent implements OnInit {

  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;
  modalRef: BsModalRef;
  pickUpLocation: any;
  dropOffLocation: any;
  _findData = false;
  itineraryItem;
  ModalRooms: any;
  days: any;
  dateEndSearch:any = '';
  totalChildrenResult:string = '';

  // currencyRentacar:string = '';


  desc1 = false;
  desc2 = false;
  desc3 = false;
  desc4 = false;
  desc5 = false;
  desc6 = false;
   
  constructor(private searchService: ResultsSearchService,
    private _itineraryService: ItineraryService,
    private modalService: BsModalService,
    private dialog:MatDialog
    ) {
  }

  ngOnInit() {
    const dateEndForm = JSON.parse(localStorage.getItem('form'));
    const dateSubtract = moment(dateEndForm.dateEnd, "DD-MM-YYYY").subtract(1, 'days');
    this.dateEndSearch = dateSubtract.format("DD-MM-YYYY");

    if (this.origin === null || this.origin === undefined) {
      this.origin = this.searchService._getOrigin();
    }
   // console.log("PAYLOAD INPUT", this.payload);
  //  console.log("PAYLOAD SERVICE", this.searchService._getPayload());
  //  console.log("FORM", this.form);
    if (this.payload !== null) {
      this.initPickupAndDropOff(this.payload);
    }
    let day1: any;
    let day2: any;

    this.totalChildrenResult = this.result?.additionalInfo?.carRental?.space_distribution.Niños;
    // this.currencyRentacar = this.result?.additionalInfo?.carRental?.currency;

    day1 = new Date(this.result.dateIni);
    day2 = new Date(this.result.dateEnd);

    const  difference = Math.abs(day2 - day1);
    this.days = difference / (1000 * 3600 * 24);

  }



  add() {
    this._findData = true;
    const optionToReserved = this.result.additionalInfo.carRental;
    console.log("Item a agregar " + optionToReserved);
    this.addItinerary(optionToReserved, this.result);

  }



  addItinerary(rentacar: any, itemResult: any, reservation?: any) {
    console.log('ModalRoomsComponent--- addItinerary()');
    console.log(this.origin);
    console.log(this.arrayResults);
    rentacar.id = itemResult.id + '-' + rentacar.optionId;
    rentacar.searchId = itemResult.searchId; /*** falta ***/
    rentacar.providerId = itemResult.providerId;
    // rentacar.form = this.form;
    rentacar.providerName = itemResult.providerName;
    rentacar.rentacarId = itemResult.id;
    rentacar.idProduct = itemResult.productId;
    rentacar.categoryName = Procesed.RENT_A_CAR;
    rentacar.title = itemResult.title;
    rentacar.location = itemResult.additionalInfo.location;
    rentacar.arrivalDate = itemResult.dateIni;
    rentacar.departureDate = itemResult.dateEnd;
    rentacar.updateDate = new Date();
    // se agrega el payload de busqueda de producto al objeto rentacar

    rentacar.payloadUpdate = this._payloadUpdateRentaCar(this.payload);
    //se adiciona destination_info 
    rentacar.destination = this._itineraryService.getDestinationInfo(this.form, rentacar);
    if (this.itineraryItem !== undefined) {
      rentacar.destination = this.itineraryItem.destination;
      this._itineraryService.remove(this.itineraryItem, rentacar);
    } else {
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(rentacar).subscribe((response:any) => {
        console.log('Add-Success: ', response);
        if (typeof response === 'string') {
          //this.toastr.error(response);
        } else {
          //this.toastr.success('Product added successfully to your itinerary');
        }
        this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary['items']);
        if (reservation) {
          this._itineraryService._restService.doPost('app', 'reservations', reservation).subscribe((response:any) => {
            console.log('Reservation-Success: ', response);
          }, error => {
            console.log('Reservation-Error: ', error);
          });
        }
        this._itineraryService.sendMessageSubscriptorLoading(false);
      }, error => {
        console.log('Add-Error: ', error);
        this._itineraryService.sendMessageSubscriptorLoading(false);
        //this.toastr.error('Item was not added to your itinerary, please check the console', 'Error:');
      });
    }
  }

  _payloadUpdateRentaCar(payload: any): Object {

    console.log(this.origin);

    return {
      products: [{
        providerId: this.result.providerId,
        nameAdapter: this.result.nameAdapter,
        categoryId: this.result.categoryId,
        productId: this.result.id,
        productName: this.result.title,
        origin: this.result.origin,
        destination: this.result.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.result.dateIni,
        departureDate: this.result.dateEnd,
        qtyProduct: 1,
        qtyPax: 0,
        others: {
          carRental: payload.others.carRental
        }
      }]
    };
  }

  showMore() {


  }

  initPickupAndDropOff(payload: any) {
    if (payload.others !== null && payload.others.carRental !== null) {
      this.pickUpLocation = payload.others.carRental.pickupLocation.address;
      this.dropOffLocation = payload.others.carRental.dropoffLocation.address;
    }
  }

  openModal(template: TemplateRef<any>) {
    // setTimeout(() => {
    //   this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    // }, 500);

    var dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '970px',
      data: {
        modalRoomRef: this.modalRef,
        product: this.result, 
        productQty: this.payload.qtyProduct, 
        form: this.form, 
        origin:'RENT-A-CAR', 
        arrayResults: this.arrayResults, 
        payloadUpdate: this.payload
      },

      //disableClose: true
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // if(result){
        
      
      // }else{
        
      // }
    });
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

}



