<div class="d-flex flex-column pl-3 py-4" style="position: relative;">

  <div style="height: 75px;">
    <img *ngIf="agencyImagen" class="imag-circle" [src]="agencyImagen" alt="Logo Agency" >
  </div>
<!--
  <button type="button" class="close btn-closed" aria-label="Close" (click)="dialogRef.close(false)">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button> -->

  <span *ngIf="data.message">
    <h1 class="mt-3" mat-dialog-title style="font-family: 'Poppins', sans-serif;font-size: 18px; color: rgb(0, 211, 151); text-align: start; line-height: 1.2; font-weight: 300">{{message || this.data.message}}</h1>
  </span>

  <ng-container *ngIf="paymentData.length > 0">
    <h2 class="mt-3 text-confirm-payment">Presione SI para registrar su pago en el sistema</h2>

    <h3 class="mt-2 text-title-payment">Detalle de pago</h3>
    <div *ngFor="let payment of paymentData" style="position: relative">
      <div *ngIf="payment.id === 1" class="d-flex justify-content-between mb-1 text-payment">
        <div>
          Blickoins
        </div>
        <div>
          {{currencyItinerary}} {{payment.amount}}
        </div>
      </div>
      <div *ngIf="payment.id === 2" class="d-flex justify-content-between mb-1 text-payment">
        <div>
          Tarjeta de Crédito *
        </div>
        <div>
          {{currencyItinerary}} {{payment.amount}}
        </div>
      </div>
      <div *ngIf="payment.id === 3" class="d-flex justify-content-between mb-1 text-payment">
        <div>
          Deposito en Garantía
        </div>
        <div>
          {{currencyItinerary}} {{payment.amount}}
        </div>
      </div>
      <div *ngIf="payment.id === 4" class="d-flex justify-content-between mb-1 text-payment">
        <div>
          Transferencia
        </div>
        <div>
          {{currencyItinerary}} {{payment.amount}}
        </div>
      </div>
    </div>

  </ng-container>

  <ng-container *ngFor="let payment of paymentData">
    <div *ngIf="payment.id === 2" >
      <p class="text-disclaimer-payment-card">* Su pago por tarjeta ya fue aprobado por la entidad bancaria, este paso registrará el pago en el sistema</p>
    </div>
  </ng-container>

  <div class="row d-flex justify-content-start mt-3" style="margin-left: 0em;">
      <button type="button" class="btn btn-sm btn-cancel text-uppercase" style="color: rgb(0, 211, 151); width: 125px;" (click)="decline()">No</button>
      <button type="button"  data-placement="bottom" class="btn ml-3 btn-sm  shadow text-uppercase btn-ok" style="color: black; width: 125px;" title="Yes" (click)="confirm()">Si</button>
  </div>
</div>
