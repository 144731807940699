<div class="row p-0 mt-2 mb-3">
  <div class="col-2 pr-2">
    <div class="conteiner-icon bg-gray p-2">
      <img src="/assets/img/app/little-icons/bed-white.svg" class="img-fluid" alt="">
    </div>
  </div>

  <div class="col-10">
    <div class="row">
      <div class="col-12">
        <p class="title font-poppins pl-2">
          {{stingUpperCase(item.title)}}
        </p>
      </div>
      <div class="col-12">
        <i class="icon-placeholder font-verde pl-2"></i>
        <span class="font-poppins location-text">{{item.location}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <p class="subtitle font-poppins pl-2">
          {{ item.qty }} Hab.
          <!-- <i class="icon-moon-phase-outline" aria-hidden="true"></i>
          {{item.nights}}&nbsp;&nbsp; -->
          <i class=" icon icon-adults" aria-hidden="true">&nbsp;&nbsp;</i> 
          {{item.paxAdults}}&nbsp;&nbsp;
          <i class="icon icon-child" aria-hidden="true">&nbsp;&nbsp;</i> 
          {{item.paxChildren===undefined ? 0 : item.paxChildren}}
        </p>
        <p class="description font-poppins pl-2">
          {{item.type}} {{item.description}}
        </p>
      </div>
      <!-- <span class="p-0">
        <span class="subtitle">{{item.nights}}
          <i class="icon-moon-phase-outline" aria-hidden="true"></i>
          <i class="icon-estrella" aria-hidden="true"></i> | 
          {{(item.rooms) ? item.rooms.length: item.reservations}} Room
        </span>
      </span>
      <span class="p-0">
        <p class="p-0 description">
          {{item.type}} {{item.description}}
        </p>
      </span> -->
    </div>
  </div>
</div>