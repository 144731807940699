<div class="summary p-0">
  <div *ngFor="let item of itineraryItems; let i = index" class="row ml-0 mr-0">
    <ng-container>
      <div class="col-12 head-destiny p-3 mb-3 d-flex flex-column" *ngIf="!verifyCityExist(i)"
      [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
        <span class="title-city mb-2">{{item.destination.iata_code}} - {{item.cityame}}</span>
        <span class="date" *ngIf="item.destination.destination_info">
          {{ setDateHead(item.destination.destination_info.arrival)}} - {{ setDateHead(item.destination.destination_info.departure)}}
        </span>
      </div>
    </ng-container>

    <div class="col-2 p-0 d-flex flex-column align-items-center">

      <span class="circle p-0" [ngClass]="{
        'background-icon-status-saved' : item.status_id === 1,
        'background-icon-status-reserved': item.status_id === 2,
        'background-icon-status-locked': item.status_id === 3,
        'background-icon-status-denied': item.status_id === 4,
        'background-icon-status-cancelled': item.status_id === 5,
        'background-icon-status-paid-agency': item.status_id === 6,
        'background-icon-status-paid-provider': item.status_id === 7,
        'background-icon-status-billed': item.status_id === 8,
        'background-icon-status-pending-approve-pay': item.status_id === 9,
        'background-icon-status-completed': item.status_id === 10
      }">
        <svg *ngIf="item.categoryName=='HOTELS'" [ngClass]="{
          'icon-status-saved' : item.status_id === 1,
          'icon-status-reserved': item.status_id === 2,
          'icon-status-locked': item.status_id === 3,
          'icon-status-denied': item.status_id === 4,
          'icon-status-cancelled': item.status_id === 5,
          'icon-status-paid-agency': item.status_id === 6,
          'icon-status-paid-provider': item.status_id === 7,
          'icon-status-billed': item.status_id === 8,
          'icon-status-pending-approve-pay': item.status_id === 9,
          'icon-status-completed': item.status_id === 10
        }" class="icon-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g>
            <g>
              <g>
                <path d="M53.333,202.667h53.333c-3.285,0-6.379-1.515-8.405-4.096c-2.027-2.581-2.731-5.952-1.941-9.152l6.635-26.517
                  c3.563-14.272,16.341-24.235,31.04-24.235h68.672c17.643,0,32,14.357,32,32V192c0,5.888-4.779,10.667-10.667,10.667h64
                  c-5.888,0-10.667-4.779-10.667-10.667v-21.333c0-17.643,14.357-32,32-32h68.672c14.699,0,27.477,9.963,31.04,24.235l6.635,26.517
                  c0.811,3.179,0.085,6.571-1.941,9.152c-2.027,2.581-5.12,4.096-8.405,4.096h53.333c5.888,0,10.667-4.779,10.667-10.667v-64
                  c0-29.397-23.936-53.333-53.333-53.333H96c-29.397,0-53.333,23.936-53.333,53.333v64
                  C42.667,197.888,47.445,202.667,53.333,202.667z" />
                <path d="M458.667,224H53.333C23.936,224,0,247.936,0,277.333v149.333c0,5.888,4.779,10.667,10.667,10.667
                  s10.667-4.779,10.667-10.667v-32h469.333v32c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V277.333
                  C512,247.936,488.064,224,458.667,224z M490.667,373.333H21.333V352h469.333V373.333z" />
              </g>
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>
        <svg *ngIf="item.categoryName == 'RENT-A-CAR'" [ngClass]="{
          'icon-status-saved' : item.status_id === 1,
          'icon-status-reserved': item.status_id === 2,
          'icon-status-locked': item.status_id === 3,
          'icon-status-denied': item.status_id === 4,
          'icon-status-cancelled': item.status_id === 5,
          'icon-status-paid-agency': item.status_id === 6,
          'icon-status-paid-provider': item.status_id === 7,
          'icon-status-billed': item.status_id === 8,
          'icon-status-pending-approve-pay': item.status_id === 9,
          'icon-status-completed': item.status_id === 10
        }" class="icon-svg" x="0px" y="0px" viewBox="0 0 447.64499 370.905">
          <g id="g32" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g34" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g36" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g38" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g40" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g42" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g44" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g46" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g48" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g50" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g52" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g54" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g56" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g58" transform="translate(-14.821571,-74.886488)">
          </g>
          <g id="g60" transform="translate(-14.821571,-74.886488)">
          </g>
          <path
            d="m 447.639,206.032 c 0,-8.805 -1.988,-17.215 -5.578,-24.909 -0.37,-1.956 -0.793,-3.909 -1.322,-5.89 l -38.884,-96.364998 -0.263,-0.867 C 387.987,37.492002 368.629,0 319.543,0 H 131.868 C 81.572,0 63.799,38.421002 49.896,77.776002 L 9.223,174.376 C 3.343,183.797 0,194.574 0,206.032 v 29.986 c 0,4.636 0.548,9.171 1.59,13.539 C 0.577,252.196 0,255.04 0,258.038 v 89.185 c 0,13.078 10.602,23.68199 23.68,23.68199 h 49.14 c 13.070999,0 23.672999,-10.60399 23.672999,-23.68199 V 302.624 H 353.953 v 44.599 c 0,13.078 10.604,23.68199 23.683,23.68199 h 46.326 c 13.083,0 23.683,-10.60399 23.683,-23.68199 v -89.195 c 0,-2.987 -0.583,-5.844 -1.588,-8.474 1.038,-4.375 1.588,-8.905 1.588,-13.54 v -29.981 h -0.01 z M 78.754,87.451002 c 15.482999,-43.683 27.934,-57.018 53.114,-57.018 h 187.664 c 24.995,0 38.913,14.873 53.056,56.83 L 400.963,144.765 c -9.265,-3.431 -19.461,-5.335 -30.173,-5.335 H 76.849 c -9.645,0 -18.862,1.551 -27.366,4.358 z M 103.129,247.406 H 51.281 c -9.335,0 -16.906,-7.578 -16.906,-16.912 0,-9.337 7.571,-16.91 16.906,-16.91 h 51.848 c 9.339,0 16.91,7.573 16.91,16.91 0,9.334 -7.576,16.912 -16.91,16.912 z m 183.155,-3.387 h -120.6 c -5.913,0 -10.704,-4.794 -10.704,-10.704 0,-5.921 4.791,-10.713 10.704,-10.713 h 120.6 c 5.92,0 10.71,4.792 10.71,10.713 0,5.91 -4.79,10.704 -10.71,10.704 z m 108.767,3.387 h -51.846 c -9.343,0 -16.91,-7.578 -16.91,-16.912 0,-9.337 7.573,-16.91 16.91,-16.91 h 51.846 c 9.343,0 16.916,7.573 16.916,16.91 0,9.334 -7.573,16.912 -16.916,16.912 z"
            id="svg_2" inkscape:connector-curvature="0" />
        </svg>
        <svg *ngIf="item.categoryName == 'TRANSPORTS'" [ngClass]="{
          'icon-status-saved' : item.status_id === 1,
          'icon-status-reserved': item.status_id === 2,
          'icon-status-locked': item.status_id === 3,
          'icon-status-denied': item.status_id === 4,
          'icon-status-cancelled': item.status_id === 5,
          'icon-status-paid-agency': item.status_id === 6,
          'icon-status-paid-provider': item.status_id === 7,
          'icon-status-billed': item.status_id === 8,
          'icon-status-pending-approve-pay': item.status_id === 9,
          'icon-status-completed': item.status_id === 10
        }" class="icon-svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
          y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g>
            <g>
              <path d="M53.333,234.667v-64C59.221,170.667,64,165.888,64,160s-4.779-10.667-10.667-10.667C23.915,149.333,0,173.269,0,202.667
                V224c0,17.643,14.357,32,32,32h21.333C59.221,256,64,251.221,64,245.333S59.221,234.667,53.333,234.667z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M458.667,149.333c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667v64
                c-5.888,0-10.667,4.779-10.667,10.667S452.779,256,458.667,256H480c17.643,0,32-14.357,32-32v-21.333
                C512,173.269,488.085,149.333,458.667,149.333z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M181.333,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667
                V480c0,17.643,14.357,32,32,32H160c17.643,0,32-14.357,32-32v-21.333C192,452.779,187.221,448,181.333,448z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M416,448c-5.888,0-10.667,4.779-10.667,10.667h-64c0-5.888-4.779-10.667-10.667-10.667S320,452.779,320,458.667V480
                c0,17.643,14.357,32,32,32h42.667c17.643,0,32-14.357,32-32v-21.333C426.667,452.779,421.888,448,416,448z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M416,0H96C66.581,0,42.667,23.936,42.667,53.333V416c0,29.397,23.915,53.333,53.333,53.333h320
                c29.419,0,53.333-23.936,53.333-53.333V53.333C469.333,23.936,445.419,0,416,0z M138.667,42.667h234.667c17.643,0,32,14.357,32,32
                c0,17.643-14.357,32-32,32H138.667c-17.643,0-32-14.357-32-32C106.667,57.024,121.024,42.667,138.667,42.667z M138.667,405.333
                c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32C170.667,390.976,156.309,405.333,138.667,405.333z
                M373.333,405.333c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32
                C405.333,390.976,390.976,405.333,373.333,405.333z M426.667,266.667c0,17.643-14.357,32-32,32H117.333c-17.643,0-32-14.357-32-32
                V160c0-17.643,14.357-32,32-32h277.333c17.643,0,32,14.357,32,32V266.667z" />
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>
        <svg *ngIf="item.categoryName == 'TOURS'" [ngClass]="{
          'icon-status-saved' : item.status_id === 1,
          'icon-status-reserved': item.status_id === 2,
          'icon-status-locked': item.status_id === 3,
          'icon-status-denied': item.status_id === 4,
          'icon-status-cancelled': item.status_id === 5,
          'icon-status-paid-agency': item.status_id === 6,
          'icon-status-paid-provider': item.status_id === 7,
          'icon-status-billed': item.status_id === 8,
          'icon-status-pending-approve-pay': item.status_id === 9,
          'icon-status-completed': item.status_id === 10
        }" class="icon-svg" height="461pt" viewBox="-78 0 461 461.344" width="461pt" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m128.179688 180.726562 22.769531 136.617188h2.445312l22.738281-136.398438c-.226562-13.050781-10.863281-23.601562-23.960937-23.601562-13.023437 0-23.664063 10.441406-23.992187 23.382812zm0 0" />
          <path
            d="m216.171875 157.34375c-13.230469 0-24 10.769531-24 24l-.113281 1.3125-22.445313 134.6875h17.078125l53.433594-137.398438c-.722656-12.585937-11.183594-22.601562-23.953125-22.601562zm0 0" />
          <path
            d="m88.171875 157.34375c-12.769531 0-23.230469 10.015625-23.960937 22.601562l53.433593 137.398438h17.085938l-22.558594-136c0-13.230469-10.769531-24-24-24zm0 0" />
          <path
            d="m184.15625 157.566406c7.296875-9.796875 18.886719-16.222656 32.015625-16.222656 8.710937 0 16.71875 2.878906 23.296875 7.625-6.226562-78.585938-55.929688-126.074219-79.296875-144.226562v137.410156c9.800781 2 18.207031 7.671875 23.984375 15.414062zm0 0" />
          <path d="m71.148438 365.34375h162.046874l-14.222656-32h-133.601562zm0 0" />
          <path
            d="m100.476562 317.34375-52.304687-134.496094v-1.503906c0-13.230469-10.769531-24-24-24-12.519531 0-22.839844 9.648438-23.910156 21.894531l84.398437 138.105469zm0 0" />
          <path
            d="m179.914062 0c27.800782 23.984375 70.570313 72.976562 75.691407 150.015625 6.804687-5.34375 15.261719-8.671875 24.566406-8.671875 9.039063 0 17.296875 3.128906 24 8.207031v-.207031c0-74.328125-53.664063-136.253906-124.257813-149.34375zm0 0" />
          <path
            d="m280.171875 157.34375c-13.230469 0-24 10.769531-24 24v1.503906l-52.304687 134.496094h15.816406l84.402344-138.105469c-1.074219-12.246093-11.394532-21.894531-23.914063-21.894531zm0 0" />
          <path
            d="m240.308594 381.34375h-176.265625l-3.4375 7.734375c-2.9375 6.609375-4.433594 13.625-4.433594 20.867187 0 28.34375 23.054687 51.398438 51.390625 51.398438h89.210938c28.34375 0 51.398437-23.054688 51.398437-51.398438 0-7.242187-1.496094-14.257812-4.433594-20.867187zm0 0" />
          <path
            d="m64.875 148.96875c6.578125-4.746094 14.585938-7.625 23.296875-7.625 13.128906 0 24.703125 6.449219 32 16.25 5.777344-7.761719 14.191406-13.441406 24-15.441406v-137.410156c-23.367187 18.152343-73.070313 65.640624-79.296875 144.226562zm0 0" />
          <path
            d="m48.738281 150.015625c5.128907-77.039063 47.890625-126.03125 75.691407-150.015625-70.59375 13.089844-124.257813 75.015625-124.257813 149.34375v.207031c6.703125-5.078125 14.960937-8.207031 24-8.207031 9.304687 0 17.761719 3.328125 24.566406 8.671875zm0 0" />
        </svg>
        <i *ngIf="item.categoryName=='PTHEMES'" class="icon-small icon-pthemes iconstart text-center pl-1"></i>
        <i *ngIf="item.categoryName=='DISNEY'" class="icon-small icon-disney iconstart text-center"></i>
        <i *ngIf="item.categoryName=='INSURANCE'" class="icon-small icon-seguro iconstart text-center"></i>
        <i *ngIf="item.categoryName=='CRUISES'" class="icon-small icon-crucero iconstart text-center"></i>
      </span>


    </div>

    <div class="col-10">
      <div class="row">
        <div class="col-12">
          <p class="item dark-1 mt-0" *ngIf="item.categoryName!='DISNEY'" style="font-size: 14px;">
            {{item.title}} </p>
          <span class="item col-8 dark-1 mt-0" *ngIf="item.categoryName=='DISNEY'">{{item.hotel.title}}</span>
          <p class="m-0 location-name dark-1 ">
            <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px" height="15px"></object>
            {{item.cityame}}
          </p>
        </div>
        <!-- <div class="col-6 d-flex justify-content-end align-items-center"> -->
          <!-- <div *ngIf="!(item.optionNightsNetTotalProf ? true : false)">
            <a class="loader text-center justify-content-center ">
              <i class="fa fa-spinner fa-spin"></i>
            </a>
          </div> -->
       <!---   <span *ngIf="(item.optionNightsNetTotalProf)" class="priceItem dark-1">
            ${{item.optionNightsNetTotalProf | number:'1.2-2'}}
          </span>

          <span *ngIf="(!item.optionNightsNetTotalProf)" class="priceItem dark-1">
            ${{item.totalPriceProf | number:'1.2-2'}}
          </span> -->


          <!-- <span *ngIf="(item.netPriceAgent)" class="priceItem dark-1">
            {{currencyItinerary}} {{item.netPriceAgent | number:'1.2-2'}}
          <p class="mb-0" style="font-family: 'Poppins', sans-serif; font-size: 12px; text-align: end;">Imp. incl.</p>
          </span> -->
        <!-- </div> -->
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-1" style="border-top: 1px dotted  #000000 !important;">
        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div class="d-flex flex-row align-items-center mt-1">
            <span class=" nights d-flex align-items-center" *ngIf="item.categoryName=='HOTELS'">
              <object type="image/svg+xml" data='/assets/img/app/little-icons/bed-gray.svg' width="15px" height="15px"
                class=" mr-1"></object>
              <!-- <span class=" icon icon-moon-phase-outline"></span>  -->
              {{item.stay?.nights}} NOCHES
            </span>

            <span class="ml-2 nights d-flex align-items-center" *ngIf="item.categoryName=='TOURS'">
              <object type="image/svg+xml" data='/assets/img/app/little-icons/adventure-sports-gray.svg' width="15px" height="15px"
                class="ml-2 mr-1"></object>
              <!-- <span class=" icon icon-moon-phase-outline"></span>  -->
              {{item.duration}}
              <span class="ml-2 pl-1"> <span class="icon icon-user"></span> {{item.paxAdults}}</span>
              <span class="ml-2"> <span class="icon icon-child"></span> {{item.paxChildren}} </span>
            </span>
          </div>
          <div class="adjust-product-status-saved">
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-saved"
              *ngIf="item.status_id == 1">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-reserved"
              *ngIf="item.status_id == 2">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-locked"
              *ngIf="item.status_id == 3">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-denied"
              *ngIf="item.status_id == 4">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-cancelled"
              *ngIf="item.status_id == 5">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-paid-agency"
              *ngIf="item.status_id == 6">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-paid-provider"
              *ngIf="item.status_id == 7">{{item.status}}</span>
            <span class="status-item badge d-flex justify-content-center align-items-center product-status-paid-completed"
              *ngIf="item.status_id == 8">{{item.status}}</span>
          </div>
        </div>

        <div class="mt-3" *ngIf="item.categoryName=='HOTELS'" >
          <p class="m-0 typeRoom dark-1"><strong>Tipo: </strong><span>{{item.room?.roomType}}</span></p>
        </div>

        <div class="d-flex flex-row mt-2" *ngIf="item.categoryName=='HOTELS'">
          <p class="hotel-date m-0 dark-1"><strong>IN:</strong>
            {{ item.arrivalDate | date:'mediumDate'}}</p>
          <P class="hotel-date m-0 ml-1 dark-1 adjust-date-out"><strong>OUT:</strong>
            {{ item.departureDate | date:'mediumDate'}}</P>
        </div>
        <div class="mt-2" *ngIf="item.categoryName=='HOTELS'" >
          <p *ngIf="item.rateClassId === 'R'" class="m-0 typeRoom" style="color: #00d196"><strong>REEMBOLSABLE </strong></p>
          <p *ngIf="item.rateClassId === 'NR'" class="m-0 typeRoom" style="color: #d60159"><strong>NO REEMBOLSABLE</strong></p>
        </div>
        <p class="hotel-room m-0 mt-1 dark-1" *ngIf="item.categoryName=='HOTELS'">
          {{item.payloadUpdate?.products[0]?.others?.hotels?.roomConfiguration.length}} Hab.
          <span class="ml-2 pl-1"> <span class="icon icon-user"></span> {{item.paxAdults}}</span>
          <span class="ml-2"> <span class="icon icon-child"></span> {{item.paxChildren}} </span>
        </p>
      </div>
      <div class="col-12 p-0">
         <span *ngIf="(item.netPriceAgent)" class="priceItem dark-1">
            <p class="mb-0" style="text-align: end;">{{currencyItinerary}} {{item.netPriceAgent | number:'1.2-2'}}</p>
          <p class="mb-0" style="font-family: 'Poppins', sans-serif; font-size: 12px; text-align: end;">Imp. incl.</p>
          </span>
      </div>
    </div>
  </div>
  <div class="col-12 p-3 mt-4" style="border-radius: 5px;background-color:#ffffff">
    <div class="row pl-2 pr-2 d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <span class="netPrice">
          TARIFA NETA
        </span>
      </div>
      <div class="d-flex">
        <span *ngIf="totalNetPriceBookingFreeTax > 0" class="netPriceMount" >{{currencyItinerary}} {{ totalNetPriceBookingFreeTax | number:'1.2-2'}}</span>
        <span *ngIf="totalNetPriceBookingFreeTax === 0" class="netPriceMount" >{{currencyItinerary}} {{ totalTarifaNeta | number:'1.2-2'}}</span>
      </div>
    </div>
    <div *ngIf="taxSum > 0" class="row pl-2 pr-2 d-flex pt-2 justify-content-between align-items-center">
      <div class="d-flex">
        <span class="netPrice">
          IMPUESTO
        </span>
      </div>
      <div class="d-flex">
        <span  class="netPriceMount">{{currencyItinerary}} {{ taxSum | number:'1.2-2'}}</span>
      </div>
    </div>
    <div class="row pl-2 pr-2  pt-2 d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <span class="netPrice">
          TARIFA TOTAL
        </span>
      </div>
      <div class="d-flex">
        <span *ngIf="price" class="netPriceMount">{{currencyItinerary}} {{price | number:'1.2-2'}}</span>
      </div>
    </div>
  </div>

  <div class="col-12 p-3" style="margin-top: 5px; border-radius: 5px; background-color: #ffce0b;">
    <div class="row pl-2 pr-2 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center justify-content-start">

        <!-- <i class="icons-point mr-2"></i> -->
        <span class="font-blickoins">
          Total Blickoins
        </span>
      </div>
      <div class="d-flex align-items-center">
        <img src="../../../assets/img/app/little-icons/badge-bks.png" alt="" class="mr-1" style="filter: brightness(0);">
        <span *ngIf="blickoins" class="font-blickoins">{{ (blickoins ? blickoins : 0) | number:'1.2-2' }} BKS</span>
      </div>
    </div>
    <div class="row pl-2 pr-2 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center justify-content-start">

        <!-- <i class="icons-point mr-2"></i> -->
        <span class="font-blickoins">
          Creditos Blickoins
        </span>
      </div>
      <div class="d-flex align-items-center">
        <!-- <img src="../../../assets/img/app/little-icons/badge-bks.png" alt="" style="filter: brightness(0); margin-right: 22px;"> -->
        <span *ngIf="totalCreditBlickoins" class="font-blickoins">{{ (totalCreditBlickoins ? totalCreditBlickoins : 0) | number:'1.2-2' }} USD</span>
      </div>
    </div>
  </div>
</div>
