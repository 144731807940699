import { Component, OnInit } from '@angular/core';
import { ResultsSearchService } from '../home/results-search.service';
import { ItineraryService } from '../shared/itinerary.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'usb-multi-destination',
  templateUrl: './multi-destination.component.html',
  styleUrls: ['./multi-destination.component.scss']
})
export class MultiDestinationComponent implements OnInit {

  _payload: any;
  isLoading: boolean;
  itinerary$: Observable<any[]>;

  constructor(private resultsSearch: ResultsSearchService,
    private itineraryService: ItineraryService) { }

  ngOnInit() {
    this.isLoading = true;
    this._payload = this.resultsSearch._getPayload();
    console.log("this._payload this.resultsSearch._getPayload()", this._payload);

    // if (this._payload == undefined && localStorage.getItem('payload') ) {
    //   this._payload = JSON.parse(localStorage.getItem('payload'));
    //   console.log("no habia payload y lo agarro de local", this._payload);
    // } else {

    //   console.log("no hay payload en memoria");
      
    //   this.itinerary$ = this.itineraryService.getItinerary$();
    //   this.itinerary$.subscribe((data:any) => {
    //     console.log("LLEGO DATA A PAYLOAD MAKER", data);

    //     if(data[0].destination.length >0){
    //       this._payload = this.makePayload(data);
    //       console.log("this.makePayload(data)",  this._payload);
    //     }
        
    //   });
    // }

    this.itineraryService.getLoadingObject().subscribe((response:any) => {
      this.isLoading = response;
    });
  }

  _onLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  makePayload(data: any) {
    const payload = {
      providersId: null,
      categoriesId: [0],
      origin: '',
      destination: data[0].destination[0],
      priceFrom: 0,
      priceTo: 20000,
      arrivalDate: moment(data[0].arrivalDate).format('YYYY-MM-DD'),
      departureDate: null,
      qtyProduct: 1,
      qtyPax: data[0].destination[0].destination_info.childs + data[0].destination[0].destination_info.adults,
      others: {
        serialized: false,
        maxRequestTimeOnSeconds: 20,
        minNumResults: 1,
        maxNumResultsPerAdapter: "",
        hotels: {
          roomConfiguration: data[0].destination[0].destination_info.roomDist
        }
      }
    };
    return payload;
  }

}
