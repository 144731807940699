import { Component, OnInit, Inject } from '@angular/core';
// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ItineraryService } from '../itinerary.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'usb-select-agency-modal',
  templateUrl: './select-agency-modal.component.html',
  styleUrls: ['./select-agency-modal.component.scss']
})
export class SelectAgencyModalComponent implements OnInit {

  //data:any;

  agency:any;

  constructor(
    // public bsModalRef: BsModalRef,
    private itineraryService: ItineraryService,
    public userService:UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectAgencyModalComponent>
  ) { }

  ngOnInit() {
    console.log("recibi de modal", this.data);
  } 


  changue(){
    console.log(this.agency);
  }

  asignAgency(){
    this.userService._agencyName = this.agency.name_agency;
    this.userService._agentID = this.agency.agent_id;
    this.userService._agencyID = this.agency.agency_id;

    console.log("item seleccionado, y se guarda en local storage", this.agency);
    localStorage.setItem('selectedAgency', JSON.stringify(this.agency));
    this.itineraryService.consultActiveItinerary(this.userService._agentID);

    setTimeout(() => {
      this.dialogRef.close();
    }, 2300);
    
    //this.closeModal();
  }

  // closeModal(){
  //   this.bsModalRef.hide();
  // }
  
}
