<div class="t-content" id="packages">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <div class="form-row pt-3">
      <div class="form-group col-xg-6 col-lg-2 col-md-12 col-sm-12 inp">
        <label for="origin">&nbsp;</label>
        <input ngui-auto-complete formControlName='origin' maxlength="20" type="text" name="origin" class="form-control input-search-box"
          value-formatter="cityName" placeholder="Origen" [source]="observableSource.bind(this)"
          display-property-name="cityName" (valueChanged)="_myCallbackOrigin($event)"
          [list-formatter]="_myListFormatter" accept-user-input="false" autocomplete="off" #destination>
      </div>
      <div class="form-group col-xg-6 col-lg-2 col-md-12 col-sm-12 inp">
        <label for="destination">&nbsp;</label>
        <input ngui-auto-complete formControlName='destination' maxlength="20" type="text" name="destination"
          class="form-control input-search-box" value-formatter="cityName" placeholder="Destino"
          [source]="observableSource.bind(this)" display-property-name="cityName"
          (valueChanged)="_myCallbackDestiny($event)" [list-formatter]="_myListFormatter" accept-user-input="false"
          autocomplete="off">
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">
        <div class="form-row">
          <div class="form-group col-xg-4 col-lg-6 col-md-12 col-sm-12 pr-0">
            <input formControlName="arrivalDate" type="text" class="form-control input-search-box btn-border-right" id="arrivalDate"
              placeholder="Check-in     -" autocomplete="off" #dpArrival="bsDatepicker" bsDatepicker [minDate]="minDate"
              [maxDate]="maxDateArrival" [bsConfig]="dpConfig" (bsValueChange)="setDepartureDate($event)" readonly>
            <span class="help-block text-danger"
              *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('required')">Este campo es obligatorio.</span>
            <small class="error-label"
              *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('invalidDate')">
              * ¡El check-in debe ser una fecha anterior al check-out!
            </small>
          </div>
          <div class="form-group col-xg-4 col-lg-6 col-md-12 col-sm-12 pl-0">
            <input formControlName="departureDate" type="text" class="form-control input-search-box btn-border-left" id="departureDate"
              placeholder="Check-out" autocomplete="off" #dpDeparture="bsDatepicker" bsDatepicker
              [minDate]="minDateDeparture" [bsConfig]="dpConfig" (bsValueChange)="setArrivalDate($event)" readonly>
            <span class="help-block text-danger"
              *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('required')">Este campo es obligatorio</span>
            <small class="error-label"
              *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('invalidDate')">
              * ¡El check-out debe ser una fecha posterior al check-in!
            </small>

          </div>


        </div>
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">
        <button type="button" class="form-control btn-usb-rp" (click)="_openModal()" [attr.aria-expanded]="!isCollapsed"
          aria-controls="false">
          {{passengers}}
          <span class="arrow-sort fa fa-sort"></span>
        </button>
        <div *ngFor="let room of _form.value['room']; let i = index">
          <span class="help-block text-danger"
            *ngIf="((validate || (_close && isCollapsed)) && (room['qtyAdults'] == 0))">Al menos selecciona 1 adulto en la habitación
            {{i+1}}.</span>
        </div>
        <div (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close"
          (click)="_over()" class="justify-content-end">
          <div class="row">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
              <div class="card">
                <div formArrayName="room" *ngFor="let item of formArray(_form, 'room'); let i = index"
                  class="d-flex flex-row col-12 align-items-center justify-content-between p-2">
                  <div [formGroupName]="i"
                    class="d-flex flex-row align-items-start justify-content-between col-12 p-0">
                    <div class="p-2 col-2" style="padding:25px 0 0 0 !important;">Hab. {{i + 1}}</div>
                    <div class="p-0 m-0 col-2">
                      <label for="adults">Adultos</label>
                      <select formControlName="qtyAdults" class="custom-select form-control-sm " id="adults"
                        name="adults" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <div class="p-0 m-0 col-2">
                      <label for="childs">Niños</label>
                      <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs"
                        (ngModelChange)="_addChild(item)" name="childs" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <div class="p-0 col-5 w-100">
                      <!-- Children age inputs -->
                      <div id="childrenAges" class="px-1 col-12" *ngIf="item.get('childsage').length > 0">
                        <label for="age">Edad de Niños</label>
                        <div class="d-flex flex-column">
                          <div class="d-flex p-0 m-0">
                            <div formArrayName="childsage"
                              *ngFor="let age of formArray(item, 'childsage'); let j = index;" class="col-3 select-ages">
                              <div [formGroupName]="j" class="col-12 p-0">
                                <input formControlName="age" min=0 max=17 limit-to="14"
                                  class="form-control col-11 p-3 input-age" value-formatter="0" placeholder="0"
                                  name="age">
                              </div>
                            </div>
                          </div>
                          <div *ngFor="let age of formArray(item, 'childsage'); let j = index;">
                            <span class="help-block text-danger d-flex w-100 text-nowrap"
                              *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>La edad de los niños{{j+1}} es incorrecta</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="p-0" style="margin-top: 29px;">
                      <button (click)="_removeRoom(i)" class="btn btn-outline-danger col-12 input-age btn-sm">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                    <hr>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-between flex-row">
                  <div class="p-2 justify-content-start">
                    <button type="button" (click)="_addRoom()" class="btn btn-outline-danger btn-sm">
                      Agregar habitación
                    </button>
                  </div>
                  <div class="p-2 justify-content-end">
                    <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                      class="btn btn-outline-danger btn-sm" type="button">
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-groupcol-xg-4 col-lg-2 col-md-12 col-sm-12">
        <button class="btn btn-usblick" type="submit" [disabled]="!_form.valid" data-toggle="modal">
          Buscar
        </button>
      </div>
    </div>
    <div
      class="d-flex flex-xg-row flex-lg-row flex-md-column flex-sm-column justify-content-sm-start justify-content-lg-between mb-3 pb-5">
      <div class="form-group align-items-center m-0 py-0 pl-0 pr-1 col-xg-3 col-lg-3">
        <ng-select formControlName="qtyDestination" [items]="odestinations" bindLabel="name" bindValue="id"
          placeholder="Otros destinos" (ngModelChange)="_addOtherDestinations()" class="custom select-custom">
          <span class="arrow-sort fa fa-sort"></span>
        </ng-select>
      </div>
      <div formArrayName="othersDestinations" *ngFor="let age of formArray(_form, 'othersDestinations'); let j = index;"
        class="form-group m-0 py-0 px-1 col-xg-3 col-lg-3">
        <div [formGroupName]="j" class="col-12 multdest">
          <label for="destination"></label>
          <input ngui-auto-complete formControlName='destination' type="text" name="destination"
            class="form-control btndest input-search-box" value-formatter="cityIataCode" placeholder="&nbsp;Destino {{j + 1}}"
            [source]="observableSource.bind(this)" display-property-name="cityName" select-value-of="cityIataCode"
            [list-formatter]="_myListFormatter">
        </div>
      </div>
    </div>

  </form>
</div>