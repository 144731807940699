import { Component, OnInit, ViewChild, NgModule, Input, ViewContainerRef, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FormGroup } from "@angular/forms";
import { ItineraryService } from "../../itinerary.service";
import { RoomConfigurationItem } from "../../../home/search-box/categories/hotels/roomConfigurationItem";
import { CookieService } from "ngx-cookie";
import { isValidOwener, fillOwnerMessage } from "../../../utils/Functions";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { MaterialModalConfirmComponent } from "../../material-modal-confirm/material-modal-confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap";

@Component({
  selector: "usb-rooms-select",
  templateUrl: "./rooms-select.component.html",
  styleUrls: ["./rooms-select.component.scss"],
})
export class RoomsSelectComponent implements OnInit {
  @Input() item: any;
  @Input() productQty: number;
  @Input() hotel: any;
  @Input() results: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() view_info: any;
  @Input() tabActive?: any;
  @Output() refModal = new EventEmitter<any>();
  @Output() roomSelect = new EventEmitter<any>();
  @Output() hotelSelect = new EventEmitter<any>();


  @ViewChild("gmap") gmapElement: any;

  max: number;
  rate = 4;
  ratereview = 4.5;
  isReadonly: boolean;
  x = 5;
  y = 2;
  select: any[] = [];
  totalQty = 0;
  single: any[];
  view: any[] = [200, 150];
  disableButton: boolean = true;
  controTabs: boolean = false;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = ".";
  showYAxisLabel = true;
  yAxisLabel = "%";
  form_: any;
  nuevoObjeto = [];
  nuevoObjetoAux = [];

  bsModalRef?: BsModalRef;

  colorScheme = {
    domain: ["#00a680", "#00a680", "#00a680", "#00a680", "#00a680"],
  };

  tab: string = "";
  imgList: any = [];
  imgSelect: number = 0;
  _cookieClient: any = undefined;
  step = 0;
  roomsGroup = {};
  roomAdd: any;
  amenitiesHotelInfo = [];

  constructor(
    public vcr: ViewContainerRef,
    private _itineraryService: ItineraryService,
    private _cookieService: CookieService,
    public userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private modalService: BsModalService,
  ) {
    this.single = [
      {
        name: "Excelente",
        value: 74,
      },
      {
        name: "Muy Bueno",
        value: 19,
      },
      {
        name: "Regular",
        value: 6,
      },
      {
        name: "Malo",
        value: 1,
      },
      {
        name: "Pésimo",
        value: 0,
      },
    ];
  }

  ngOnInit() {
    this.tab = this.tabActive ? this.tabActive : "rooms";
    console.log("Hotel seleccionado", this.hotel);
    if (!this.view_info) {
      if (this.hotel !== undefined && this.hotel.additionalInfo !== undefined) {
        if (this.hotel.additionalInfo.rooms !== undefined) {
          this.createSelectsOfRoom(this.hotel.additionalInfo.rooms.length, this.productQty + 1);
        }
        if (this.hotel.additionalInfo.roomsPackage !== undefined) {
          this.createSelectsOfRoom(this.hotel.additionalInfo.roomsPackage.length, this.productQty + 1);
        }
      }
    }
    this.setRoomsGroup();
    this.getAmenitiesHotelInfo();
  }

  getAmenitiesHotelInfo() {
    this.amenitiesHotelInfo = this.hotel.additionalInfo.amenities
      .filter(function (amenite: any) {
        return amenite.ame_type === "hotel";
      })
      .map(function (amenite: any) {
        return amenite;
      });
  }

  setRoomsGroup() {
    let i = 0;
    const roomType = this.hotel.additionalInfo.rooms;

    this.nuevoObjeto = roomType.reduce(function (r, a) {
      r[a.roomType] = r[a.roomType] || [];
      r[a.roomType].push(a);
      return r;
    }, Object.create(null));

    console.log("array nuevoObjeto", this.nuevoObjeto);

    Object.keys(this.nuevoObjeto).map((key) => {
      this.nuevoObjetoAux[i] = this.nuevoObjeto[key];
      i++;
    });
    console.log("array nuevoObjetoAux", this.nuevoObjetoAux);
  }

  setStep(index: number) {
    this.step = index;
  }

  /**
   * Metodo que crea los select que se veran reflejados en las habitaciones.
   * @param qty Indica la cantidad de select que se crearan (se crea 1 por cada room del hotel).
   * @param maxProductSelect Indica los valores del array (se le agrega 1 mas por el valor 0).
   */
  createSelectsOfRoom(qty: number, maxProductSelect: number) {
    for (let i = 0; i < qty; i++) {
      this.select.push({
        selectArray: Array.from(Array(maxProductSelect).keys()),
        selectValue: 0,
      });
    }
  }

  calculateTotalPrice(roomSelect: any) {
    this.totalQty = 0;
    this.disableButton = true;


    roomSelect.id = roomSelect.roomId;
    roomSelect.providerId = roomSelect.providerId;
    roomSelect.providerName = roomSelect.providerName;
    roomSelect.hotelId = this.hotel.id;
    roomSelect.idProduct = roomSelect.roomId;
    roomSelect.categoryName = 'HOTELS';
    roomSelect.title = this.hotel.title;
    roomSelect.location = this.hotel.additionalInfo.location;
    roomSelect.arrivalDate = this.hotel.dateIni;
    roomSelect.departureDate = this.hotel.dateEnd;
    roomSelect.updateDate = new Date();
    roomSelect.categoryHotel = this.hotel.additionalInfo.category;
    roomSelect.bookParam = this.hotel.bookParam;
    (roomSelect.amount = this.hotel.amount),
    (roomSelect.imagen = this.hotel.additionalInfo.images[0]?.image);
    roomSelect.payloadUpdate = this.item.payloadUpdate;
    roomSelect.paxAdults = this.item.paxAdults;
    roomSelect.paxChildren = this.item.paxChildren;
    roomSelect.destination = this.item.destination;

    if (roomSelect == 0) {
    } else {
      console.log("room", roomSelect);
      this.roomAdd = roomSelect;
      this.roomSelect.emit(roomSelect);
      this.hotelSelect.emit(this.hotel);
      this.totalQty = roomSelect.optionNightsNetTotalProf;
      this.disableButton = false;
    }

    console.log("item", this.item);
    console.log("calculateTotalPrice roomSelect ", roomSelect);
  }

  modalRomms() {
    this.max = 10;
    this.isReadonly = true;
  }
  modalRoom() {
    this.isReadonly = true;
  }

  continue() {
    console.log("---ModalRoomsComponet--- continue()--- roomAdd", this.roomAdd);
    console.log("---ModalRoomsComponet--- continue()--- hotel", this.hotel);
    this.addItinerary(this.roomAdd, this.hotel);
  }

  productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach((obj) => (total += +obj.selectValue));
    return total;
  }

  addItinerary(item: any, hotel: any, reservation?: any) {

    if (!isValidOwener(this._cookieService.get("clientActive"))) {
      this._emitRefModal("warning - Warning - " + fillOwnerMessage());
      return;
    }
    item.id = hotel.id + "-" + item.roomId;
    item.searchId =
      this.arrayResults === undefined
        ? this.itineraryItem.searchId !== undefined
          ? this.itineraryItem.searchId
          : ""
        : this.arrayResults[0].searchId;
    item.providerId = item.providerId;
    item.providerName = item.providerName;
    item.hotelId = hotel.id;
    item.idProduct = hotel.id + "-" + item.roomId;
    item.categoryName = "HOTELS";
    item.title = hotel.title;
    item.location = hotel.additionalInfo.location;
    item.arrivalDate = hotel.dateIni;
    item.departureDate = hotel.dateEnd;
    item.updateDate = new Date();
    item.categoryHotel = hotel.additionalInfo.category;
    item.cancellation_policy = item.cancellation_policy;
    item.bookParam = hotel.bookParam;
    (item.amount = hotel.amount), (item.imagen = hotel.additionalInfo.images[0]?.image);
    if (this.form === null || this.form === undefined) {
      this.form_ = JSON.parse(localStorage.getItem("form"));
      console.log("form localStorage", this.form_);
      item.payloadUpdate = this._payloadUpdateHotel(this.form_, item);
      item.destination = this._itineraryService.getDestinationInfo(this.form_, item);
    } else {
      item.payloadUpdate = this._payloadUpdateHotel(this.form.value, item);
      item.destination = this._itineraryService.getDestinationInfo(this.form.value, item);
    }
    if (this.itineraryItem) {
      item.destination = this.itineraryItem.destination;
      this._itineraryService.remove(this.itineraryItem, item);
      this._emitRefModal("success - Congratulations - Product updated succesfully");
      this.toastr.success("Congratulations !!", "Product Successfully updated");
    } else {
      let hotelNew = this.userService.itineraryActive[0].items.find((element) => element.id == this.hotel.id);
      if (hotelNew) {
        const initialState: ModalOptions = {
          initialState: {
            message: 'Ya tienes este producto, ¿Estás seguro de agregarlo nuevamente?',
          }
        };
        this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
        const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
          if (result) {
            this._itineraryService.sendMessageSubscriptorLoading(true);
            this._itineraryService.add(item).subscribe(
              (response: any) => {
                console.log("Add-Success: ", response);
                if (typeof response === "string") {
                  this._emitRefModal("error - Error - " + response);
                } else {
                  this._emitRefModal("success - Congratulations - Product added succesfully to your itinerary");
                  this.toastr.success("Congratulations !!", "Product Successfully aggregate");
                }
                this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary["items"]);
                //this._itineraryService.sendMessageSubscriptorLoading(false);
                if (reservation) {
                  this._itineraryService._restService.doPost("app", "reservations", reservation).subscribe(
                    (response: any) => {
                      console.log("Reservation-Success: ", response);
                    },
                    (error) => {
                      console.log("Reservation-Error: ", error);
                    }
                  );
                }
              },
              (error) => {
                console.log("Add-Error: ", error);
                this._emitRefModal("error - Error - Item was not added to your itinerary");
                this._itineraryService.sendMessageSubscriptorLoading(false);
              }
            );
          } else {
            this._emitRefModal("error - Error - Item was not added to your itinerary");
          }
          confirmSubscription.unsubscribe();
        });
      } else {
        this._itineraryService.sendMessageSubscriptorLoading(true);
        this._itineraryService.add(item).subscribe(
          (response: any) => {
            console.log("Add-Success: ", response);
            if (typeof response === "string") {
              this._emitRefModal("error - Error - " + response);
            } else {
              this._emitRefModal("success - Congratulations - Product added succesfully to your itinerary");
              this.toastr.success("Congratulations !!", "Product Successfully aggregate");
            }
            this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary["items"]);
          },
          (error) => {
            console.log("Add-Error: ", error);
            this._emitRefModal("error - Error - Item was not added to your itinerary");
            this._itineraryService.sendMessageSubscriptorLoading(false);
          }
        );
      }
    }
  }

  getOthers() {
    console.log("payloadUpdate", this.payloadUpdate);
    const others = {
      maxRequestTimeOnSeconds: 20,
      minNumResults: 3,
      roomConfiguration:
        this.payloadUpdate.others !== undefined ? this.payloadUpdate.others.roomConfiguration : this.payloadUpdate.products[0].others,
    };

    return others;
  }

  _payloadUpdateHotel(form: any, item: any): Object {
    console.log("item _payloadUpdateHotel", item);
    let qtyPax;
    let others;
    let payload;
    console.log(this.origin);
    if (this.origin === undefined || this.origin == "HOTELS") {
      others = {
        serialized: false,
        maxRequestTimeOnSeconds: 20,
        minNumResults: 1,
        maxNumResultsPerAdapter: "",
        hotels: {
          roomConfiguration: this._getRoomDist(form, item),
        },
      };
    }

    payload = {
      products: [
        {
          providerId: item.providerId,
          nameAdapter: item.nameAdapter,
          categoryId: item.categoryId,
          productId: item.roomId,
          productName: item.title,
          origin: this.hotel.origin,
          destination: this.hotel.destination,
          priceFrom: 0,
          priceTo: 10000.0,
          arrivalDate: item.arrivalDate, /// this.hotel.dateIni,
          departureDate: item.departureDate, //// this.hotel.dateEnd,
          qtyProduct: this.productQty,
          qtyPax: item.maxPax,
          others: others,
        },
      ],
    };
    console.log("item _payloadUpdateHotel ", payload);
    return payload;
  }

  _getRoomDist(form?: any, item?: any) {
    const roomDist = [];
    const rooms = form.room;
    for (let i = 0; i < rooms.length; i++) {
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: [],
        boardNameCode: item.boardNameCode,
        condition: item.rateClassId,
        roomId:item.roomId,
        roomCode:item.roomCode,
        bookingCode:item.bookingCode
      };
      const adults = rooms[i].qtyAdults;
      const children = rooms[i].childsage;
      for (let j = 0; j < children.length; j++) {
        people.push(children[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);
    }
    return roomDist;
  }

  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }


  prev() {
    if (this.imgSelect > 0) {
      this.imgSelect = this.imgSelect - 1;
    }
    if (this.imgSelect <= 5) {
      this.scroll("l");
    }
  }

  next() {
    if (this.imgSelect < this.imgList.length - 1) {
      this.imgSelect++;
    }
    if (this.imgSelect >= 5) {
      this.scroll("r");
    }
  }

  scroll(dir) {
    let idc = "slide-miniatura";
    let content = document.getElementById(idc);
    const stepr = 150;
    const stepl = -150;
    if (dir == "l") {
      content.scrollLeft += stepl;
    } else if (dir == "r") {
      content.scrollLeft += stepr;
    }
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str) {
    if (str) {
      let e = str.toLowerCase();
      return e.replace(/\b\w/g, (l) => l.toUpperCase());
    }
  }
}
