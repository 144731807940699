  import { NgModule } from '@angular/core';
  import { CommonModule, HashLocationStrategy, LocationStrategy, TitleCasePipe } from '@angular/common';
  import { BrowserModule } from '@angular/platform-browser';
  import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
  import { AppRoutingModule } from './app-routing.module';
  import { TranslateModule } from '@ngx-translate/core';
  import { ErrorModule } from './error/error.module';
  import { CookieModule, CookieService } from 'ngx-cookie';
  import { SharedModule as SharedModuleAuth } from './pages/com.usblick.common/shared.module';
  import { SendEmailService } from './core/send-email.service';
  import { UserService } from './pages/com.usblick.common/auth/user.service';
  import { HomeService } from './home/home.service';
  import { AppService } from './app.service';
  import { AgentsSummaryModule } from './agents-summary/agents-summary.module';
  import { HttpConfigInterceptor } from '../http-config.interceptor';
  import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
  import { FormsModule } from '@angular/forms';
  import { MatDialogModule } from '@angular/material/dialog';
  import { SharedModule } from './shared/shared.module';
  import { RouterModule } from '@angular/router';
  import { TooltipModule } from 'ngx-bootstrap/tooltip';
  import { NgxSpinnerModule } from 'ngx-spinner';
  import { NgxPaginationModule} from 'ngx-pagination';
  import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
  import { NgxSliderModule } from '@angular-slider/ngx-slider';
  import { ToastrModule } from 'ngx-toastr';
  import { AngularSvgIconModule } from 'angular-svg-icon';
  import { CarouselModule } from 'ngx-owl-carousel-o';
  import { DashboardModule } from './csm/dashboard/dashboard.module';
  import { StoreModule } from '@ngrx/store';
  import { EffectsModule } from '@ngrx/effects';
  import { StoreDevtoolsModule } from '@ngrx/store-devtools';
  import { environment } from '../environments/environment';
  import { AuthEffects } from './core/profiles/profiles.effects';
  import { authReducer } from './core/profiles/profiles.reducer';
  import { CoreModule } from './core/core.module';

    // Próximos a sacar
    import { AgentsModule } from './agents/agents.module';
    import { ManagementItineraryModule } from './management-itinerary/management-itinerary.module';

  // flujo de reserva
  import { ContentResultModule } from './content-result/content-result.module';
  import { ItinerarySummaryModule } from './itinerary-summary/itinerary-summary.module';
  import { MultiDestinationModule } from './multi-destination/multi-destination.module';
  import { ItineraryReservationModule } from './itinerary-reservation/itinerary-reservation.module';
  import { ItineraryDetailModule } from './itinerary-detail/itinerary-detail.module';
  import { DetailsSummaryItineraryModule } from './details-summary-itinerary/details-summary-itinerary.module';

  // app.module
  import { AppComponent } from './app.component';
  import { HomeModule } from './home/home.module';
  //shared
  import { ItineraryFilterPipe } from './shared/itineraryFilter.pipe';
  import { SortByDestinationPipe } from './shared/sortByDestination.pipe';
  import { RoomComponent } from './shared/modals/modal-packages/room/room.component';
  import { ApiService } from './itinerary/shared/api.service';
import { SeoService } from 'seoService';
import { PriceFilterPipe } from './shared/slider.pipe';


  @NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      FormsModule,
      RouterModule,
      HomeModule,
      ItineraryReservationModule,
      DetailsSummaryItineraryModule,
      MultiDestinationModule,
      ItineraryDetailModule,
      ContentResultModule,
      DashboardModule,
      ItinerarySummaryModule,
      ManagementItineraryModule,
      AppRoutingModule,
      ErrorModule,
      SharedModuleAuth,
      MatDialogModule,
      AgentsModule,
      AgentsSummaryModule,
      SharedModule,
      NgxSpinnerModule,
      NgxSliderModule,
      CoreModule,
      TooltipModule.forRoot(),
      TranslateModule.forRoot(),
      ToastrModule.forRoot(),
      CookieModule.forRoot(),
      NgxPaginationModule,
      NgxDaterangepickerMd.forRoot(),
      AngularSvgIconModule.forRoot(),
      CarouselModule,
      StoreModule.forRoot({ auth: authReducer }),
      EffectsModule.forRoot([AuthEffects]),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    declarations: [
      AppComponent,
      ItineraryFilterPipe,
      SortByDestinationPipe,
      PriceFilterPipe,
      RoomComponent,
    ],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
      // FeatureCollection,
      ApiService,
      CookieService,
      SendEmailService,
      TitleCasePipe,
      ItineraryFilterPipe,
      PriceFilterPipe,
      UserService,
      HomeService,
      AppService,
      SeoService,
      { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
  })
  export class AppModule { }
