<div id="{{ 'contentPDF-main-' +itinerary.id }}">

  <div style="display:block;float:left;clear:both;width:100%">
    <div class="col-md-6 " style="float:left;max-width: 200px;">
        <div>
            <img src="assets/img/default-logo-agency.png" alt="logo" width="auto" height="58px">
            <!--  <img src="{{_logoAgency}}" alt="logo" width="auto" height="58px"> -->
        </div>
    </div>
    <div class="col-md-6"  style="float:right;">
        <br>
        <div style="margin-left:160px;font-size:14px;color:#032541;line-height:120%">
            <p><b>{{agency.name_agency}}&nbsp;&nbsp;</b></p>
            <p>{{agency.email_user}}&nbsp;&nbsp;</p>
            <p>{{agency.phone}}&nbsp;&nbsp;</p>
        </div>
    </div>
  </div>

  <div style="height:1px;background-color: #032541 !important;display:block;float:left;clear:both;width:100%">
      <span>&nbsp;&nbsp;</span>
  </div>
  
  <div style="display:block;float:left;clear:both;width:100%;" >
    <div class="col-md-6 " style="float:left;font-size:14px;line-height:120%;padding-top: 10px">

      <h5>RESUMEN DE TRANSACCION</h5>

      <h5 class="color-green">TARIFA NETA: $ {{totalPrice}}</h5>
    </div>

    <div class="col-md-3 " style="float:right;font-size:14px;line-height:120%;padding-top: 10px">
      <h5>DETALLE</h5>
      <p class="mt-2" *ngIf="verifyAmount(1)">PAGO POR PUNTOS ${{verifyAmount(1)}}</p>
      <p class="mt-2" *ngIf="verifyAmount(3)">PAGO POR CRÉDITO ${{verifyAmount(3)}}</p>
      <p class="mt-2" *ngIf="verifyAmount(4)">PAGO POR TRANSFERENCIA ${{verifyAmount(4)}}</p>
      <p class="mt-2" *ngIf="verifyAmount(2)">PAGO POR TARJETA ${{verifyAmount(2)}}</p>
    </div>
  </div>  
  
  <div style="
    font-size:14px; 
    padding:5px;
    margin-top:20px;
    margin-bottom:20px;
    color: #fff !important;
    background-color: #032541 !important;
    display:block;
    float:left;
    clear:both;
    width:100%">
    <!-- <span>{{destination.city}} </span>
      <span>&nbsp;&nbsp;{{datesRange}}</span> -->
    </div>
    
    <div style="display:block;float:left;clear:both;width:100%;" >
      <div class="col-md-6 " style="float:left;font-size:14px;line-height:120%;padding-top: 10px">
          <p> N° 000{{itinerary.id}}</p>
          <p> {{destinations}}</p>
          <!-- <p> Date of travel: {{datesRange}}</p> -->
          <p> {{paxList}} </p>
      </div>
  
  
      <div class="col-md-3 " style="float:right;font-size:14px;line-height:120%;padding-top: 10px">
        <p *ngIf="clientData.name"> {{clientData.name}}</p>
        <p *ngIf="clientData.email">{{clientData.email}}</p>
        <p *ngIf="clientData.phone">{{clientData.phone}}</p>
      </div>
    </div>  
    
  <div style="
    font-size:14px; 
    padding:5px;
    margin-top:20px;
    margin-bottom:20px;
    color: #fff !important;
    background-color: #032541 !important;
    display:block;
    float:left;
    clear:both;
    width:100%">
    <!-- <span>{{destination.city}} </span>
    <span>&nbsp;&nbsp;{{datesRange}}</span> -->
  </div>

  <div *ngFor="let item of items; let i=index">

    <div style="display:block;float:left;clear:both;width:100%">
      <div class="col-md-4" style="float:left;width:525px;letter-spacing: -1px;">
        <h5 class="font-weight-bold m-0 text-capitalize" style="font-size: 16px;">{{item.title}}</h5>
        <p class="font-weight-bold m-0" style="font-size: 13px;">{{item.destination.iata_code}} - US</p>
        <p style="font-size:13px;">
          <span style="color: green"><b>check-in: </b></span> {{item.arrivalDate  | date:'mediumDate'}}  &nbsp;&nbsp; 
          <span style="color: red"><b>check-out: </b> </span> {{item.departureDate | date:'mediumDate'}}
        </p>
      </div>

      <div class="col-md-4"  style="float:left;width:195px;">
        <p class="font-weight-bold">TARIFA NETA</p>
        <p class="font-weight-bold">$ {{item.netPriceBooking | number:'1.2-2'}}</p>
      </div>
    </div>

    
    <hr style="border-color:#b4b7b9;display:block;float:left;clear:both;width:100%">
    
  
  </div>


  <div style="
    font-size:16px; 
    padding:15px;
    margin-top:20px;
    margin-bottom:20px;
    color: #fff !important;
    background-color: #353535 !important;
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align: end;
    bottom: 0;"
  >
   
    <p class="m-0">Monto Total: $ {{totalPrice | number:'1.2-2'}}</p>
    <!-- <span>{{destination.city}} </span>p
    <span>&nbsp;&nbsp;{{datesRange}}</span> -->
  </div>

  <div id="{{'items-' +itinerary.id }}"></div>

</div>