<div class="container p-5" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>

  <h6 class="text-center">Seleccione Su agencia</h6>


  <div class="form-group">
  
    <select id="agency" class="form-control" name="ageny" [(ngModel)]="agency" (change)="changue()">
      <option *ngFor="let agent of data" [ngValue]="agent" >{{agent.name_agency}} </option>
    </select>
  </div>
  
  <button class="btn btn-primary text-center" type="button" (click)="asignAgency()">Seleccionar</button>
  
</div>
