<ng-template [ngIf]="actualItinerary">
  <div class="mb-5" *ngIf="actualItinerary.destination.length > 0">
    <ng-template ngFor let-destination let-i="index" let-f="first" let-l="last" [ngForOf]="(actualItinerary.destination || [])">
      <!--*********************** Items ******************** -->
      <div class="row">

        <!--*********************** Destination ******************** -->
        <div style="width: calc(100% - 347px);padding-right: 75px">
        <div class="row" style="margin-top: 0px !important; margin-left: 10px !important;">
          <div class="destiny-sumary-header">
            <span class="in font-poppins"
              [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
              IN
            </span>
          </div>
          <div class="destiny-sumary-header m-2">
            <span class="in-item font-poppins" style="font-family: 'Open Sans' !important; font-size: 24px !important ;color: #ffffff;" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">{{i+1}}</span>
          </div>
          <div class="destiny-sumary-header m-2">
            <p class="title-result-itinerary font-poppins" style="font-size: 24px !important; line-height: 1.6; margin-left: -15px;">
              &nbsp;{{ citiesName[i] }}
            </p>
          </div>
        </div>
        <div class="row destiny-sumary-date-city">
          <div class="destiny-sumary-header-city" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
            <span class="city-item font-poppins" >
              &nbsp;{{ destination.destination_info.cityName }}
            </span>
          </div>
        </div>
        <!--*********************** Hotel CheckIN ******************** -->

        <!-- <div *ngFor="let item of suggestions; let l = index" class="row" style="margin: 0px !important">
          <usb-suggestions-item class="col-12" *ngIf="item.iata_code == destination.iata_code" [item]="item" [showCategory]="showCategoryVehicle">
          </usb-suggestions-item>
        </div> -->

        <div class="timeline" *ngIf="destination">
          <div class="vertical-line" id="{{ 'line-'+destination.iata_code }}" [ngClass]="{'d-none': hiddenLine}">
          </div>
          <ng-template ngFor let-item let-in="index" let-last="last" [ngForOf]="itemsOrderedDestination(destination.iata_code)">
            <div class="row" id="{{destination.iata_code+'-'+in}}">
              <!-- Product - date & icon start-->
              <div class="col-2">
                <div class="row" *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar) || item.categoryName==categoryName.Hotels || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours) || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports)">
                  <div class="col" *ngIf="!item.ckeckout && (isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar) || item.categoryName==categoryName.Hotels || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours) || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports))">
                    <div class="text-center destiny-sumary-date-general" style="max-width: 37px;" *ngIf="in===0">
                      <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.arrivalDate) | date:'dd'}}</p>
                      <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.arrivalDate) | date:'MMM'}}</p>
                      <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.arrivalDate) | date:'yy'}}</p>
                    </div>
                    <div class="text-center destiny-sumary-date-general" style="max-width: 37px;" *ngIf="(in>0) && (items[in].arrivalDate !== items[in-1].arrivalDate)">
                      <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.arrivalDate) | date:'dd'}}</p>
                      <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.arrivalDate) | date:'MMM'}}</p>
                      <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.arrivalDate) | date:'yy'}}</p>
                    </div>
                    <div class="destiny-sumary-date-general" style="max-width: 37px; background-color: rgba(0, 0, 0, 0) !important;" *ngIf="(in>0) && (items[in].arrivalDate == items[in-1].arrivalDate)">
                      <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;">&nbsp;
                      </p>
                      <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;">&nbsp;
                      </p>
                      <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;">&nbsp;
                      </p>
                    </div>
                    <div class="row mt-2 adjust-container-icon-line" style="margin-left: 52px !important; margin-top: -53px !important;">
                      <span class="icon-line icon-bg-ck-in">
                        <i class="icon-hotel-ck-in" *ngIf="item.categoryName==categoryName.Hotels"></i>
                        <i class="icon-car-pickup"  *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar)"></i>
                        <i class="icon-adventure"   *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours)"></i>
                        <i class="icon-transporte"  *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports)"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col" *ngIf="item.ckeckout && ((item.duration != '1 DÍA')) && (isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar) || item.categoryName==categoryName.Hotels || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours) || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports))">
                    <div class="text-center destiny-sumary-date-general" style="max-width: 37px;"
                      *ngIf="(in>0) && ((items[in].departureDate !== items[in-1].departureDate) && (items[in].departureDate !== items[in-1].arrivalDate)) && !last">
                      <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.departureDate) | date:'dd'}}</p>
                      <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.departureDate) | date:'MMM'}}</p>
                      <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.departureDate) | date:'yy'}}</p>
                    </div>
                    <div class="text-center destiny-sumary-date-general"
                      style="max-width: 37px; background-color: #fff !important;"
                      *ngIf="(in>0) && !last && ((items[in].departureDate == items[in-1].departureDate) || (items[in].departureDate == items[in-1].arrivalDate))">
                      <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;">&nbsp;
                      </p>
                      <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;">&nbsp;
                      </p>
                      <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;">&nbsp;
                      </p>
                    </div>
                    <div class="text-center destiny-sumary-date-general" style="max-width: 37px;" *ngIf="last">
                      <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.departureDate) | date:'dd'}}</p>
                      <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.departureDate) | date:'MMM'}}</p>
                      <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;">
                        {{ formatDate(item.departureDate) | date:'yy'}}</p>
                    </div>
                    <div class="row adjust-container-icon-line" style="margin-left: 52px !important;" [ngStyle]="{'margin-top': last? '-58px' : '-53px'}"
                      *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar) || item.categoryName==categoryName.Hotels || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours) || isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports)">
                      <span class="icon-line icon-bg-ck-in">
                        <i class="icon-hotel-ck-out" *ngIf="item.categoryName==categoryName.Hotels"></i>
                        <i class="icon-car-dropoff"
                          *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar)"></i>
                        <i class="icon-adventure-red"
                          *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours)"></i>
                        <i class="icon-transporte-red"
                          *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports)"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Product - date & icon end -->

              <ng-template [ngIf]="item.categoryName==categoryName.Hotels">
                <usb-item-hotel *ngIf="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Hotels)"
                  [dato]="item" [isCheckIn]="!item.ckeckout" (itemDelete)="_removeItem($event)" (itemToPartial)="_itemToPartial($event)" [partialReserve]="partialReserve" class="col-10">
                </usb-item-hotel>

                <!-- <usb-suggestions-item class="col-12" *ngIf="item.iata_code == destination.iata_code" [item]="item" [showCategory]="showCategoryTour">
                </usb-suggestions-item> -->
              </ng-template>

              <ng-template [ngIf]="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Tours)">
                <usb-item-tours (itemDelete)="_removeItem($event)" [dato]="item" [isCheckIn]="!item.ckeckout" (reSearch)="reSearch($event)"
                  [partialReserve]="partialReserve" (itemToPartial)="_itemToPartial($event)" class="col-10">
                </usb-item-tours>
              </ng-template>

              <ng-template [ngIf]="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Rentacar)" class="col-10">
                <usb-item-rentacar [dato]="item" [partialReserve]="partialReserve" (itemToPartial)="_itemToPartial($event)" class="col-10" [isCheckIn]="!item.ckeckout">
                </usb-item-rentacar>
              </ng-template>


              <ng-template [ngIf]="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Transports)">
                <usb-item-transport [data]="item" [isCheckIn]="!item.ckeckout" [partialReserve]="partialReserve"
                  (itemToPartial)="_itemToPartial($event)" (itemDelete)="_removeItem($event)" class="col-10">
                </usb-item-transport>
              </ng-template>



              <ng-template [ngIf]="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Disney)">
                <usb-item-disney [dato]="item" [isCheckIn]="!item.ckeckout" [partialReserve]="partialReserve"
                  (itemToPartial)="_itemToPartial($event)" class="col-12">
                </usb-item-disney>
              </ng-template>

              <ng-template [ngIf]="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Ptheme)">
                <usb-item-pthemes [dato]="item" [partialReserve]="partialReserve"
                  (itemToPartial)="_itemToPartial($event)" class="col-12" [isCheckIn]="!item.ckeckout">
                </usb-item-pthemes>
              </ng-template>

              <ng-template [ngIf]="isCategoryNameAndDestination(destination.iata_code,item,categoryName.Insurance)">
                <usb-item-insurance [dato]="item" [partialReserve]="partialReserve"
                  (itemToPartial)="_itemToPartial($event)" class="col-12" [isCheckIn]="!item.ckeckout">
                </usb-item-insurance>
              </ng-template>
            </div>
            <ng-template #withoutCategory>
              <usb-item-disney class="col-12"></usb-item-disney>
              <usb-item-rentacar class="col-12"></usb-item-rentacar>
              <usb-item-pthemes class="col-12"></usb-item-pthemes>
            </ng-template>

          </ng-template>
        </div>

        </div>


        <div class="d-flex flex-column" *ngIf="!suggestions && f" style="width: 347px; left: 200px;">
          <div class="row justify-content-end" style="padding-left: 15px; width: 347px; position: absolute;"
            [ngStyle]="{top: !suggestions ? '280px' : '0px'}">
            <div class="d-flex align-items-center justify-content-between bg-price">
              <p class="label-price">TARIFA NETA</p>
              <a class="loader text-center justify-content-center" *ngIf="totalNetPriceBookingFreeTax == 0">
                <i class="fa fa-spinner fa-spin"></i>
              </a>
              <div class="d-flex flex-column align-items-end">
              <p *ngIf="totalNetPriceBookingFreeTax > 0 && getOrigin != 'RENT-A-CAR'"
                  class="price-summary"
                  style="height: 26px;"
                  [ngStyle]="{
                    'font-size': totalNetPriceBooking | dynamicFontSize: 23 : fontSizeAdjustments
                  }"
              > {{currencyItinerary}} {{ totalNetPriceBooking > 0 ? totalNetPriceBooking : totalNetPriceBookingFreeTax | number:'1.2-2'}}
            </p>
              <p *ngIf="getOrigin == 'RENT-A-CAR'" class="netPriceMount" class="price-summary" style="height: 26px;">{{currencyItinerary}} {{  totalNetPriceBookingFreeTax | number:'1.2-2'}}</p>
              <p style="font-size: 11px;font-weight: 600;margin-bottom: 0px;margin-right: 10px;">imp. incl.</p>
            </div>
            </div>
            <div class="d-flex flex-column bg-price-bks py-3">
              <div class="d-flex align-items-center justify-content-between">
                <p class="label-bks">BLICKOINS</p>
                <p class="bks-summary" style="line-height: 1;"
                  [ngStyle]="{
                              'font-size': totalNetPriceBooking | dynamicFontSize: 18 : fontSizeAdjustments
                            }"
                            >BKS {{ (totalBlickoins ? totalBlickoins : 0) | number: '1.2-2'}}</p>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p class="label-bks">CREDITOS BLICKOINS</p>
                <p class="bks-summary" style="line-height: 1;"
                [ngStyle]="{
                            'font-size': totalNetPriceBooking | dynamicFontSize: 18 : fontSizeAdjustments
                          }"
                             >USD {{ (totalCreditBlickoins ? totalCreditBlickoins : 0) | number: '1.2-2'}}</p>
              </div>
            </div>
            <div class="row" id="visited-component">
              <div class="triangle"></div>
                <div class="col-12">
                    <div class="bg-visited-component" style="padding-bottom: 68px;" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
                        <div class="row">
                            <div class="col-12">
                                <span class="font-poppins title-recomendados" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RECOMENDADOS</span>
                            </div>
                        </div>

                        <div class="row pt-3">
                            <div class="col-12">
                                <div class="px-3">
                                  <img src="../../../assets/img/app/Visited/imgpsh_fullsize_anim.jpg" width="315px" alt="">
                                  <div class="d-flex flex-column">
                                    <p class="title-visited" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">TAYLOR SWIFT</p>
                                    <p class="date-visited" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">10 Noviembre - Estadio River Plate</p>
                                    <p class="adress-visited">Buenos Aires</p>
                                    <p class="description-visited">En su nuevo show "The Eras Tour", Taylor repasa lo mejor de sus 10 álbumes. Es un tributo por todas sus eras musicales, incluyendo su último trabajo, Midnights, lanzado el 21 de octubre de 2022.</p>
                                    <div class="d-flex justify-content-end" [ngStyle]="{'border-bottom': '1px dotted ' + (getClientsSettingAppBooking?.clients_setting_global?.color_main_3 || 'black')}" style="padding-bottom: 25px;">
                                      <!-- <button type="button" class="btn btn-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">CLICK AQUÍ</button> -->
                                    </div>
                                  </div>
                                    <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/oUDizYOQjmM0" allowfullscreen></iframe>
                                    </div>
                                    <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="card-text text-left font-poppins font-weight-bold">Universal Studios</p>
                                            </div>
                                            <div class="col-4">
                                                <span class="text-uppercase reserve px-2 font-poppins" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">reserve</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 40px">
                            <div class="col-12">
                                <div class="px-3">
                                  <div class="middle-circle-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"></div>
                                  <div class="d-flex flex-column text-card-visited">
                                    <p class="title-card-visited">HOP ON / OFF</p>
                                    <p class="subtitle-card-visited">Conocé Buenos Aires en pocas horas !!!</p>
                                    <div class="d-flex justify-content-end" style="margin-left: 196px">
                                      <button type="button" class="badge rounded-pill btn-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RESERVAR</button>
                                    </div>
                                  </div>
                                  <img src="../../../assets/img/app/recomended/hop.png" width="315px" alt="">
                                    <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/aFVvCBDYS58" allowfullscreen></iframe>
                                    </div>
                                    <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="card-text text-left font-poppins font-weight-bold">Kissimme</p>
                                            </div>
                                            <div class="col-4">
                                                <span class="text-uppercase reserve px-2 font-poppins" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">reserve</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-12">
                                <div class="px-3">
                                  <div class="middle-circle-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"></div>
                                  <div class="d-flex flex-column text-card-visited">
                                    <p class="title-card-visited">LAWTENNIS CLUB</p>
                                    <p class="subtitle-card-visited">Tennis internacional en Palermo</p>
                                    <div class="d-flex justify-content-end" style="margin-left: 196px">
                                      <button type="button" class="badge rounded-pill btn-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RESERVAR</button>
                                    </div>
                                  </div>
                                  <img src="../../../assets/img/app/recomended/tennis.png" width="315px" alt="">
                                    <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7SvUgiaIvH8" allowfullscreen></iframe>
                                    </div>
                                    <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="card-text text-left font-poppins font-weight-bold">Fort Lauderdale</p>
                                            </div>
                                            <div class="col-4">
                                                <span class="text-uppercase reserve px-2 font-poppins">reserve</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-12">
                                <div class="px-3">
                                  <div class="middle-circle-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"></div>
                                  <div class="d-flex flex-column text-card-visited">
                                    <p class="title-card-visited">BRESH</p>
                                    <p class="subtitle-card-visited">La mejor fiesta tecno vivila en Mar del Plata</p>
                                    <div class="d-flex justify-content-end" style="margin-left: 196px">
                                      <button type="button" class="badge rounded-pill btn-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RESERVAR</button>
                                    </div>
                                  </div>
                                  <img src="../../../assets/img/app/recomended/bresh.png" width="315px" alt="">
                                    <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ItIaOmlZjbk" allowfullscreen></iframe>
                                    </div>
                                    <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="card-text text-left font-poppins font-weight-bold">Key West</p>
                                            </div>
                                            <div class="col-4">
                                                <span class="text-uppercase reserve px-2 font-poppins" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">reserve</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="middle-circle" style="margin-top: 40px;"></div>
                </div>
            </div>
          </div>
        </div>
      </div>






      <!--*********************** Sugerencias ******************** -->

      <!-- <div class="sugestion-right sugerencia" *ngIf="suggestions">
            <div class="my-1" style="padding-top: 75px; border-radius: 2px;">
              <div *ngFor="let item of suggestions; let l = index;" >
                <usb-suggestions-big-summary *ngIf="item.iata_code == destination.iata_code"
                      [itineraryActive]="_actualItinerary" [item] ="item" id="{{'sug'+i}}">
                </usb-suggestions-big-summary>
              </div>
            </div>
            <div class="row justify-content-end" *ngIf="suggestions && (i === (suggestions.length -1))" style="margin-right: -23px !important; margin-left: -7px;">
              <div class="mx-2 col-12">
                <div class="px-3 py-2 my-4" style="background: #353535;">
                  <div class="row justify-content-between mx-1 my-2" style="color: #fff; border: 1px solid #fff; padding: 20px 15px 0px 15px; border-radius: 5px;">
                    <div style="margin-top: 5px !important;">
                      <p style="font-size: 18px !important; font-weight: 400;">TARIFA NETA</p>
                    </div>
                    <div>
                      <p style="font-size: 24px !important; font-weight: 400;">$ {{actualItinerary.totalPrice | number:'1.2-2'}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div> -->
    </ng-template>
  </div>
</ng-template>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size = "medium" color="#fa1a1a" type="line-scale">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
