import { createReducer, on } from '@ngrx/store';
import { setRoles, setPermissions, clearAuthState, setAgent, setAgency, setCategories, setCurrency } from './profiles.actions';
import { AuthState, initialAuthState } from './storeProfile';

const _authReducer = createReducer(
  initialAuthState,
  on(setRoles, (state, { roles }) => ({ ...state, roles })),
  on(setPermissions, (state, { permissions }) => ({ ...state, permissions })),
  on(setAgent, (state, { agent }) => ({ ...state, agent })),
  on(setAgency, (state, { agency }) => ({ ...state, agency })),
  on(setCategories, (state, { categories }) => ({ ...state, categories })),
  on(setCurrency, (state, { currency }) => ({ ...state, currency })),
  on(clearAuthState, () => initialAuthState)
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}
