import { Directive, ElementRef, HostListener, Input, NgZone, Renderer2 } from '@angular/core';

@Directive({
  selector: '[usbScrollToTopDirective]'
})
export class ScrollToTopDirectiveDirective {
  @Input() showAfter: number = 100;
  private button: HTMLElement;

  constructor(private el: ElementRef, private ngZone: NgZone, private renderer: Renderer2) {
    this.button = this.renderer.createElement('button');
    this.renderer.setStyle(this.button, 'position', 'fixed');
    this.renderer.setStyle(this.button, 'bottom', '20px');
    this.renderer.setStyle(this.button, 'right', '20px');
    this.renderer.setStyle(this.button, 'border', '0');
    this.renderer.setStyle(this.button, 'font-size', '40px');
    this.renderer.setStyle(this.button, 'background', 'none');
    this.renderer.setStyle(this.button, 'display', 'none'); // Inicialmente oculto
    this.renderer.setProperty(this.button, 'innerHTML', '<i class="bi bi-arrow-up-circle-fill"></i>');
    this.renderer.listen(this.button, 'click', () => this.scrollToTop());
    this.renderer.appendChild(document.body, this.button);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const yOffset = window.pageYOffset;
    if (yOffset > this.showAfter) {
      this.renderer.setStyle(this.button, 'display', 'block');
    } else {
      this.renderer.setStyle(this.button, 'display', 'none');
    }
  }

  @HostListener('window:popstate', [])
  onPopState() {
    console.log('popstate event triggered');
    setTimeout(() => {
      this.ngZone.run(() => {
        this.renderer.setStyle(this.button, 'display', 'none');
      });
    }, 0); // Asegurar que se ejecute después de que se complete la operación actual
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
