import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ArticleComponent } from "./article.component";

const routes: Routes = [

  {
    path: ':typeArticle/:id/:clientId/:active',
    component: ArticleComponent
  }

]

@NgModule({
  declarations: [ ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class ArticleRoutingModule { }
