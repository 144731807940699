<div class="width-100">
  <div class="row mt-2">
    <div class="col-2 p-0 m-0 text-center">
      <span class="date-itinerary-s"> {{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | uppercase }}</span>
    </div>
    <div class="col-auto mr-4">
      <span class="circle">
        <i class="icon-tours icon-seguro iconregular"></i>
      </span>
    </div>
    <div class="col-xg-9 col-lg-9 flex-column px-0 mx-0 d-flex space-items">
      <div class="d-flex ">
        <div class="col-xg-10 col-lg-10 px-0 m-0 flex-column font-descrip">
          <span class="title-destiny-start d-flex align-items-center">{{ dato.title }}
          </span>
          <span class="p-0 spaced d-flex">
            <span><i class="icon-placeholder"></i> From {{ dato.payloadUpdate.products[0].origin }} to {{dato.payloadUpdate.products[0].destination}}</span>
          </span>
          <span class="p-0 spaced d-flex">
            <span>Benefits: {{dato.benefits[0].description}} - {{dato.benefits[0].detail}}... {{(dato.benefits.length)-1}}
              more </span>
          </span>
        </div>
        <div class="col-2 d-inline m-0 p-0 text-right" *ngIf="isCheckIn">
          <div *ngIf="partialReserve===true" class="pretty p-round p-thick p-bigger p-icon p-smooth">
            <input type="checkbox" (change)="toPartialReserve($event, dato)" ng-checked="dato.status_id==3" />
            <div class="state">
              <i class="icon fa fa-check"></i>
              <label></label>
            </div>
          </div>
          <!-- <span>
            <i class="icon-pencil"></i>
          </span> -->
          <span>
            <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(dato)">
              <i class="icon-papelera"></i>
            </button>
          </span>

        </div>
      </div>
      <div class="d-flex font-inline flex-row" *ngIf="isCheckIn">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class=" spaced font-start d-flex pr-5 mr-5"></span>
          <span classs="align-items-center d-flex ml-5 pl-5 pr-0">Tarifa Neta: {{ dato.payloadUpdate.products[0].others.insurance.adults.length
            }} Adults - {{ dato.payloadUpdate.products[0].others.insurance.children.length }} Children</span>
          <span class="title-destiny-start pt-auto pr-4" [ngStyle]="{'font-size': fontPrice(dato.totalPrice)}">$&nbsp;{{ dato.totalPrice | number:'1.2-2' }}</span>
        </div>
      </div>
      <div class="d-flex font-inline flex-row" *ngIf="isCheckIn">
        <div class="w-100 justify-content-between d-flex flex-row">s
          <span class=" spaced font-start d-flex"><b> Check-In </b></span>
        </div>
      </div>


      <div class="d-flex font-inline flex-row" *ngIf="!isCheckIn">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class="spaced font-end d-flex"><b> Check-Out </b></span>
        </div>
      </div>

    </div>

  </div>
</div> -->

<div class="row mt-3">
  <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;">
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'dd'}}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'MMM'}}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(isCheckIn?dato.arrivalDate:dato.departureDate) | date:'yy'}}</p>
        </div>
        <div class="row mt-2" style="margin-left: 52px !important; margin-top: -53px !important;">
          <div class="icon-line icon-bg-ck-in">
            <i class="icon-insurance-ck-in" *ngIf="isCheckIn"></i>
            <i class="icon-insurance-ck-out" *ngIf="!isCheckIn"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-10 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center py-2" style="max-height: 150px; overflow: hidden;">
        <img class="img-fluid" src="assets/img/app/insurance-def.png" alt="suggestions" style="max-height: 150px;" width="100%">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex justify-content-between py-1">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{dato.title}}</p> 
            <!-- <div style="margin-top: -5px !important;">
              <span class="sub-title" >Turibus</span>
            </div> -->
            <div style="margin-top: 10px !important;">
              <span class="p-0 spaced" style="padding-top: 20px !important; margin-top: 10px;">
                <span style="font-size: 15px; font-weight: bold;">
                  <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                  From {{ dato.payloadUpdate.products[0].origin }} to {{dato.payloadUpdate.products[0].destination}}
                </span>
              </span>
              <br>
              <span class="p-0 m-0  spaced">
                <p class="p-0 m-0 inter" style="font-size: 12px; font-weight: 500;">
                  Benefits: {{dato.benefits[0].description}} - {{dato.benefits[0].detail}}... {{(dato.benefits.length)-1}} more
                </p>
                <p class="p-0 m-0 inter" *ngIf="isCheckIn" style="font-size: 12px; font-weight: 500;">
                  Tarifa Neta: {{ dato.payloadUpdate.products[0].others.insurance.adults.length }} Adults - {{ dato.payloadUpdate.products[0].others.insurance.children.length }} Children
                </p>
              </span>
              <div class="d-flex font-inline flex-row" *ngIf="isCheckIn">
                <span class="check">Check-in</span>
              </div>

              <div class="d-flex font-inline flex-row" *ngIf="!isCheckIn">
                <span class="check" style="color: #ff003f;">Check-Out</span>
              </div>
        
            </div>
          </div>

          <div class="d-flex justify-content-between" *ngIf="isCheckIn">
            <div class="py-0" style="margin-top: 1px; width: 98px !important;">
              <span style="width: 10%" *ngIf="dato.status_id === 1" class=" status product-status-saved">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 2" class=" status product-status-reserved">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 3" class=" status product-status-locked">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 4" class=" status product-status-denied">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 5" class=" status product-status-cancelled">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 6" class=" status product-status-paid-agency">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 7" class=" status product-status-paid-provider">{{dato.status}}</span>
              <span style="width: 10%" *ngIf="dato.status_id === 8" class=" status product-status-completed">{{dato.status}}</span>           
            </div>
            <div class="py-0" style="width: 20px !important;">
              <a type="button"  style="cursor: pointer; margin-top: 3px !important;"> <!-- (click)="modalRoom.show()"-->
                <i class="icon-info"></i>
              </a>
              <div>
                <usb-modals bsModal #modalRoom="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
                  [modalRoomRef]="modalRoom" [product]="dato.product" [productQty]="1" [form]="null"
                  [payloadUpdate]="dato.payloadUpdate" [view_info]="true" [itineraryItem]="dato"
                  [origin]="'MULTIDESTINATION'">
                </usb-modals>
              </div>
            </div>
            <div class="py-0" style="width: 20px !important; margin-left: 4px; margin-top: 1px !important;">
              <a style="cursor: pointer;">
                <span class="icon-nr font-poppins">NR</span>
              </a>
            </div>
          </div>

        </div>
      </div>

      <div class="col-2 px-0 text-center bg-blue py-2 icon-accion" *ngIf="isCheckIn">

        <div class="icons-item text-right" style="margin-top: 5px;">
          <!-- <span *ngIf="partialReserve===false||partialReserve===undefinid">
            <i class="far fa-check-circle" style="color: #00d397"></i>
          </span> -->
          <span *ngIf="partialReserve===true">
            <label class="custom-radio-checkbox">
              <input class="custom-radio-checkbox__input" type="checkbox" (change)="toPartialReserve($event, dato)" ng-checked="dato.status_id==3">
              <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            </label>
          </span>
          <span>
            <a style="cursor: pointer; margin-left: 5px;">
              <i class="icon-edit" aria-hidden="true"></i>
            </a>
          </span>
          <span>
            <a style="cursor: pointer;" (click)="removeItem(dato)">
              <i class="icon-delete" aria-hidden="true"></i>
            </a>
          </span>
        </div>
        <div class="date-expiration pt-2 text-right" style="padding-right: 10px;">
          <span class="font-poppins" style="font-size:11px; font-weight: 400;">
            Expiration Day
          </span>
          <br>
          <span class="font-poppins text-uppercase" style="font-size: 11px; font-weight: bold; color: #00d397;">
            30 dec 2020
          </span>
        </div>
        <div class="amount pt-1 text-right" style="margin-top: 20px; padding-right: 10px;">
          <span class="title-destiny-start text-nowrap" [ngStyle]="{'font-size': fontPrice(dato.totalPriceProf)}">$ {{ dato.totalPriceProf | number:'1.2-2'  }}</span>
        </div>
      </div>
    </div> 
  </div>
</div>