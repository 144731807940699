import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Procesed } from "../../../shared/enum-procesed";
import { ResultsSearchService } from "../../../home/results-search.service";
import { ItineraryService } from "../../../shared/itinerary.service";
import { ToastrService } from "ngx-toastr";
import { RestService } from "../../rest.service";
import { formatArrivalDate, generateArray } from "../../../utils/Functions";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { isValidOwener, fillOwnerMessage } from "../../../utils/Functions";


import { MaterialModalConfirmComponent } from "../../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../../shared/material-modal-alert/material-modal-alert.component";
import * as moment from 'moment';
import { CookieService } from "ngx-cookie";
import { setting } from "../../../../../setting";
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: "usb-modal-rentacar",
  templateUrl: "./modal-rentacar.component.html",
  styleUrls: ["./modal-rentacar.component.scss"],
})
export class ModalRentacarComponent implements OnInit {
  @Output() refModal = new EventEmitter<any>();
  @Input() rentaCar: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() productQty?: any;
  @Input() payload?: any;
  @ViewChild("map") gmapElement: any;
  public formatDate = formatArrivalDate;
  result: any;

  disableButton: Boolean = true;
  selected: String = "INCLUDES";
  _findData = false;
  isChecked: any;
  qtySelect = 0;
  allCar: any;
  itemSelect: any;

  totalPaymentUsblick: any = 0;
  public totalPaymentAgency: any = 0;
  totalRent: any = 0;
  totalPrice: any = 0;

  totalSumCarsFeeAgency: any = 0; // sumar los cars fee

  totalSumOptionalAgency: any = 0;

  optionals: any = 0;
  selectOptional = new Array();
  totalPriceOptional: any = 0;
  paymentAgency: any = 0;
  paymentNow: any = 0;
  updateOptionals = false;
  otherOpcions: any;
  pickUpData: any;
  dropOffData: any;
  selectedPickup: any;
  selectedDropOff: any;
  dropOffTime:string = '';
  pickUpTime:string = '';
  dropOffDate:string = '';
  booleanWindowInfo:boolean =  false;
  dateEndSearch:any = '';
  totalChildren:string = '';
  // currencySelectedRentacar:string = '';


  totalOptional: any = [];
  rentacarLocations: any[] = [];
  officesNotRepeated:any[] = [];

  valueDefault =  parseFloat('0').toFixed(2);
  public getClientsSettingAppBooking: any;


  constructor(
    private _itineraryService: ItineraryService,
    private _rest: RestService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private _cookieService:CookieService,
  ) {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));

  }

  ngOnInit() {
    this.result = this.rentaCar;
    this.payload = this.payloadUpdate;
    this.allCar = this.arrayResults;
    // tslint:disable-next-line:max-line-length

    const dateEndForm = JSON.parse(localStorage.getItem('form'));
    const dateSubtract = moment(dateEndForm.dateEnd, "DD-MM-YYYY").subtract(1, 'days');
    this.dateEndSearch = dateSubtract.format("DD-MM-YYYY");

    const payloadTime = JSON.parse(localStorage.getItem('payload'));

    this.dropOffDate = moment(payloadTime[0].others.carRental.dropoffLocation.dropoffDate).subtract(1, "days").format('DD-MM-YYYY');

    this.pickUpTime = moment(payloadTime[0].others.carRental.pickupLocation.pickupTime, "HH:mm:ss").format("HH:mm");
    this.dropOffTime =  moment(payloadTime[0].others.carRental.dropoffLocation.dropoffTime, "HH:mm:ss").format("HH:mm");

    this.arrayResults = JSON.parse(JSON.stringify(this.arrayResults.filter((element:any) => element.title === this.rentaCar.title)));
    console.log("this.arrayResults despues ", this.arrayResults);

    setTimeout(() => {
      if (document.getElementById(this.rentaCar.id) as HTMLInputElement) {
        (document.getElementById(this.rentaCar.id) as HTMLInputElement).checked = true;
        this.selectCar(this.rentaCar, 0);
      }
    }, 10);

    this.rentacarLocationsDetails();
    this.officesRepeated();
    
    // this.timesAgencies();
  }


  getQty (qty: any) {
    return Array.from({ length: qty }, (_, i) => i + 1);
  }

  _split(cadena: any) {
    return  cadena.split(',')[0];
   }


  // selectCar(car: any, index: any) {
  //   this.itemSelect = car;
  //   console.log("car select", this.itemSelect);
  //   const checkCurrect = (document.getElementById(car.id) as HTMLInputElement).checked;

  //   let checkAll = false;

  //   this.totalSumCarsFeeAgency = 0;
  //   this.totalPaymentAgency = 0;
  //   this.totalPrice = 0;
  //   this.totalPaymentUsblick = 0;
  //   let ind: any = 0;

  //   for (let i = 0; i < this.arrayResults.length; i++) {
  //     (document.getElementById(ind) as HTMLInputElement).style.border = "1px solid #00000020";
  //     ind++;
  //   }

  //   if (checkCurrect === true) {
  //     checkAll = true;
  //     (document.getElementById(index) as HTMLInputElement).style.border = "3px solid #00d096";
  //     (document.getElementById(car.id) as HTMLInputElement).disabled = true;

  //     this.totalSumCarsFeeAgency = this.sumFeePaymentAgency(car.additionalInfo.carRental.optionRates.carsfees);

  //     // tslint:disable-next-line:max-line-length
  //     this.totalPaymentAgency = (parseFloat(this.totalPaymentAgency) + parseFloat(this.totalSumCarsFeeAgency)).toFixed(2);
  //     this.totalPaymentUsblick = parseFloat(car.additionalInfo.carRental.totalPriceProf).toFixed(2);
  //     this.totalPrice = (parseFloat(this.totalPaymentUsblick) + parseFloat(this.totalPaymentAgency)).toFixed(2);
  //     this.searchCarsrules(car.additionalInfo.carRental.RatePlanCode);
  //     this.updateOptionals =  true;
  //   } else {
  //     this.totalSumCarsFeeAgency = 0;
  //     this.totalPaymentAgency = 0;
  //     this.totalPrice = 0;
  //     this.totalPaymentUsblick = 0;
  //     checkAll = false;
  //   }
  //   ind = 0;

  //   this.allCar.forEach((element_) => {
  //     if (element_.id != car.id) {
  //       if (document.getElementById(element_.id) as HTMLInputElement) {
  //         console.log(" es diferente", element_.id);
  //         console.log(" es diferente checkAll", checkAll);
  //         console.log(" ind", ind);
  //         (document.getElementById(element_.id) as HTMLInputElement).disabled = false;
  //         (document.getElementById(element_.id) as HTMLInputElement).checked = false;
  //       }
  //       ind++;
  //     }
  //   });
  // }

  selectCar(car: any, index?: any){
    this.itemSelect = car;
    console.log("car select", this.itemSelect);
    this.totalChildren = this.itemSelect.additionalInfo.carRental.space_distribution.Niños;
    // this.currencySelectedRentacar = this.itemSelect.additionalInfo.carRental.currency;
    this.showWindowInfo(this.itemSelect);
    let ind: any = 0;


    this.totalSumCarsFeeAgency = 0;
    this.totalPaymentAgency = 0;
    this.totalPrice = 0;
    this.totalPaymentUsblick = 0;

    for (let i = 0; i < this.arrayResults.length; i++) {
          (document.getElementById(ind) as HTMLInputElement).style.border = "1px solid #00000020";
          ind++;
        }

    const currentElement = document.getElementById(index) as HTMLInputElement;
    if (currentElement) {
      currentElement.checked = true;
      currentElement.disabled = true;
      currentElement.style.border = "3px solid #00d096";
    }

    this.totalSumCarsFeeAgency = this.sumFeePaymentAgency(car.additionalInfo.carRental.optionRates.carsfees);
    this.totalPaymentAgency = (parseFloat(this.totalPaymentAgency) + parseFloat(this.totalSumCarsFeeAgency)).toFixed(2);
    this.totalPaymentUsblick = parseFloat(car.additionalInfo.carRental.totalPriceProf).toFixed(2);
    this.totalPrice = (parseFloat(this.totalPaymentUsblick) + parseFloat(this.totalPaymentAgency)).toFixed(2);
    // this.searchCarsrules(car.additionalInfo.carRental.RatePlanCode);
    this.updateOptionals = true;


      this.allCar.forEach((element_) => {
        if (element_.id != car.id) {
          if (document.getElementById(element_.id) as HTMLInputElement) {
            (document.getElementById(element_.id) as HTMLInputElement).disabled = false;
            (document.getElementById(element_.id) as HTMLInputElement).checked = false;
            (document.getElementById(element_.id) as HTMLInputElement).style.border = "3px solid #00d096";
          }
          ind++;
        }
      });

  }

  add() {
    this._findData = true;
    console.log("add item", this.itemSelect);
    this.addItinerary(this.itemSelect.additionalInfo.carRental, this.itemSelect);

    
    // this._itineraryService.itinerary.currency = this.currencySelectedRentacar;
    this._itineraryService.updateItinerary(this._itineraryService.itinerary);
    // this._itineraryService.itinerary.nights = this._itineraryService.itinerary?.destination[0]?.destination_info.nights;

  }

  sumTotalAgency(carsfees: any) {
    return this.sumFeePaymentAgency(carsfees);
  }

  sumTotalRent(paymentUsblick: any, paymentAgency: any) {
    return (parseFloat(paymentUsblick) + parseFloat(paymentAgency)).toFixed(2);
  }

  loadUbication() {
    console.log("this.selectedPickup start", this.selectedPickup);
    console.log("this.selectedDropOff start", this.selectedDropOff);

    // tslint:disable-next-line:max-line-
    if (this.selectedPickup == undefined || this.selectedDropOff == undefined) {
      this.selectedPickup = this.arrayResults[0]?.additionalInfo?.carRental?.LocationDetails?.PickUp.Name;
      // tslint:disable-next-line:max-line-length
      this.selectedDropOff = this.arrayResults[0]?.additionalInfo?.carRental?.LocationDetails.DropOff.Name;
    }

    if(this.selectedPickup === this.selectedDropOff){
      this.otherOpcions = this.rentacarLocations.find(
        (data) => {
          return data.DropOff.Name === this.selectedDropOff && data.PickUp.Name === this.selectedPickup
        } 
      );
    }else{
     this.otherOpcions = this.selectOfficeDifferents();
    }

    console.log('otherOptioins ->', this.otherOpcions);

    if (this.otherOpcions) {
      this.mapMarker();
      this.itemSelect.additionalInfo.carRental.selectUbication = this.otherOpcions;
    }
  }


  selectOfficeDifferents():any[] {
    let locationsOffice:any[] = [
      {
        PickUp:[],
        OperationTime:[],
        DropOff:[],
      }
    ]

    this.rentacarLocations.find((data) =>{
      if(data.PickUp.Name === this.selectedPickup){
        const {PickUp, OperationTime, ...rest} = data;
        // console.log('pickUp ->', PickUp);
        locationsOffice[0].PickUp = PickUp;
        locationsOffice[0].OperationTime = OperationTime;
      }
    })
    
    this.rentacarLocations.find((data) =>{
      if(data.DropOff.Name === this.selectedDropOff){
        const {DropOff, OperationTime, ...rest} = data;
        // console.log('DropOff ->',DropOff);
        locationsOffice[0].DropOff = DropOff;
        locationsOffice[0].OperationTime = OperationTime;
      }
    })

    return locationsOffice[0];
  }

  cancelationPolicy(description: any) {
    console.log("description", description);
   // return description;
    let result;
   if (description) {
      result = description.split('*');

      return result[1];
    }
  }

  cancelationPolicy_(description: any) {
    console.log("description", description);
   // return description;
    let result;
   if (description) {
      result = description.split('*');
      return result[2];
    }
  }

  namePlan(name: any) {
   // return description;
    let result;
   if (name) {
      result = name.split(':');
      return result[0];
    }
  }



  onChangePickup(value: any) {
    console.log("onChangeLocation value", value);
    this.selectedPickup = value;
    this.loadUbication();
  }

  onChangeOptional(cant: any, amount: any, i: any, optional: any) {

   let update = false;
   // tslint:disable-next-line:prefer-const
   let selectOptionalAux: any [];

   this.totalOptional[i] =  (parseFloat(cant) * parseFloat(amount)).toFixed(2);

   /* if (value == 0) {
      // se ubica y se borra el opcional
      this.selectOptional.forEach(function (car: any, index: any, object: any) {
        if (car.RatePlanCode === optional.RatePlanCode) {
          object.splice(index, 1);
        }
      });

    } else {
      // se actualiza
      this.selectOptional.forEach(function (car: any, index: any, object: any) {
        if (car.RatePlanCode === optional.RatePlanCode) {
            object.splice(index, 1);
            this.selectOptional.push(optional);
          update = true;
        }
      });
      // se crea
      if (!update) {
        this.selectOptional.push(optional);
      }
      this.updateOptionals =  false;
      this.searchCarsrules(optional.RatePlanCode);
    } */
    if (cant == 0) {
      selectOptionalAux =  this.selectOptional.filter((element) => element.RatePlanCode !== optional.RatePlanCode);
      this.selectOptional = selectOptionalAux;
    } else {
      selectOptionalAux =  this.selectOptional.filter((element) => element.RatePlanCode !== optional.RatePlanCode);
      this.selectOptional = selectOptionalAux;

      for (let index = 1; index <= cant ; index++) {
        this.selectOptional.push(optional);
        console.log('entro');
      }
      this.updateOptionals =  false;
      // this.searchCarsrules(optional.RatePlanCode);
    }


    console.log('this.selectOptional', this.selectOptional);

      // asignar al objeto

      this.itemSelect.additionalInfo.carRental.RatePlanCode = optional.RatePlanCode;
      // this.itemSelect.additionalInfo.carRental.totalPrice = optional.totalNetPriceProf;
      this.itemSelect.additionalInfo.carRental.selectOptional = this.selectOptional;
      // sumar total footter
      this.totalPaymentAgency = 0;
      this.totalRent = 0;
      this.totalPrice = 0;

      // tslint:disable-next-line:max-line-length
      this.totalPaymentAgency = (
        parseFloat(this.totalSumCarsFeeAgency) +
        parseFloat(this.sumFeePaymentAgency(this.selectOptional))
      ).toFixed(2);
      this.totalRent = (parseFloat(this.totalPaymentUsblick) + parseFloat(this.totalPaymentAgency)).toFixed(2);
      this.totalPrice = (parseFloat(this.totalPaymentUsblick) + parseFloat(this.totalPaymentAgency)).toFixed(2);


  }


  onChangeDropOff(value: any) {
    console.log("onChangeLocation dropOff", value);
    this.selectedDropOff = value;
    this.loadUbication();
  }

  sumFeePaymentAgency(fees: any) {
    this.paymentAgency = 0;

      fees.forEach((element) => {
        if ((element.PaymentOffice == 'true')) {
          this.paymentAgency = parseFloat(this.paymentAgency) + parseFloat(element.Amount);
        }
      });

    return this.paymentAgency.toFixed(2);
  }

  // public searchCarsrules(ratePlanCode: any) {
  //   let payload: any;
  //   payload = {
  //     providerId: this.itemSelect.providerId,
  //     ratePlanCode: ratePlanCode,
  //     category_id: 2, // rentacar
  //     update: true,
  //   };
  //   this.spinner.show();
  //   console.log("payload searchCarsrules", payload);
  //   this._itineraryService._restService.doPost("app", "syncProduct/consultCarsrules", payload).subscribe(
  //     (response: any) => {
  //       console.log("Carsrules-Success: ", response);
  //       if (response.results == "message: Not Available Answer") {
  //         console.log("response", response);
  //         const dialogRef_ = this.dialog.open(MaterialModalAlertComponent, {
  //           height: "auto",
  //           width: "350px",
  //           data: response.results,
  //           disableClose: true,
  //         });
  //       }
  //         if (this.updateOptionals) {
  //           this.optionals = "";
  //           this.optionals = response.results;
  //           console.log("optionals: ", this.optionals);
  //         }
  //       let syncProduct: any;
  //          syncProduct = {
  //           providerId: this.itemSelect.providerId,
  //           category_id: 2,
  //           message:  response?.results?.additionalInfo?.carRental?.bookParam ? 'Update Successful' : 'Carsrules-Fail:',
  //           bookParam:  response?.results?.additionalInfo?.carRental?.bookParam,
  //           dateTimeVerifed: (moment(new Date())).format('DD-MM-YYYY HH:mm:ss')
  //         };

  //       console.log("add Carsrules syncProduct ", syncProduct);
  //       console.log("add Carsrules syncProduct  response", response);
  //       // tslint:disable-next-line:max-line-length
  //       this.itemSelect.additionalInfo.carRental.bookParam = response?.results?.additionalInfo?.carRental?.bookParam;
  //       this.itemSelect.additionalInfo.carRental.syncProduct = syncProduct;
  //       // tslint:disable-next-line:max-line-length
  //       this.itemSelect.additionalInfo.carRental.optionRates.cancellation_policy = response?.results?.additionalInfo?.carRental?.optionRates?.cancellation_policy;
  //       console.log("add Carsrules syncProduct  itemSelect", this.itemSelect);


  //       this.loadUbication();
  //       this.spinner.hide();
  //     },
  //     (error) => {
  //       console.log("Carsrules-Error: ", error);
  //     }
  //   );
  // }

  _selectOptional(optional: any, index: any) {
    console.log('optional ->', optional);
    //  this.itemSelect.
    let totalPaymentAgencyAux: any = 0;
    const checkCurrect = (document.getElementById(optional.RatePlanCode) as HTMLInputElement).checked;
    console.log('checkCurrent ->', checkCurrect);
    if (checkCurrect === true) {
      this.selectOptional.push(optional);
      this.updateOptionals =  false;
      // this.searchCarsrules(optional.RatePlanCode);
    } else {
      totalPaymentAgencyAux = this.totalPaymentAgency;
      // tslint:disable-next-line:no-shadowed-variable
      this.selectOptional.forEach(function (car: any, index: any, object: any) {
        if (car.RatePlanCode === optional.RatePlanCode) {
          console.log("delete opcional", optional.Amount);
          if (optional.Amount > 0) {
            totalPaymentAgencyAux = (parseFloat(totalPaymentAgencyAux) - parseFloat(optional.Amount)).toFixed(2);
          }
          console.log("totalPaymentAgency despues", totalPaymentAgencyAux);
          object.splice(index, 1);
        }
      });
      this.totalPaymentAgency = totalPaymentAgencyAux;
    }
    // asignar al objeto

    this.itemSelect.additionalInfo.carRental.RatePlanCode = optional.RatePlanCode;
    // this.itemSelect.additionalInfo.carRental.totalPrice = optional.totalNetPriceProf;
    this.itemSelect.additionalInfo.carRental.selectOptional = this.selectOptional;
    // sumar total footter
    this.totalPaymentAgency = 0;
    this.totalRent = 0;
    this.totalPrice = 0;

    // tslint:disable-next-line:max-line-length
    this.totalPaymentAgency = (
      parseFloat(this.totalPaymentAgency) +
      parseFloat(this.totalSumCarsFeeAgency) +
      parseFloat(this.sumFeePaymentAgency(this.selectOptional))
    ).toFixed(2);
    this.totalRent = (parseFloat(this.totalPaymentUsblick) + parseFloat(this.totalPaymentAgency)).toFixed(2);
    this.totalPrice = (parseFloat(this.totalPaymentUsblick) + parseFloat(this.totalPaymentAgency)).toFixed(2);

    console.log("selectOptional final", this.selectOptional);
    console.log("totalPaymentAgency final", this.totalPaymentAgency);
    console.log("totalRent final", this.totalRent);
    console.log("totalPrice final", this.totalPrice);
    console.log("itemSelect final", this.itemSelect);
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str) {
    let e = str.toLowerCase();
    return e.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  // Activar Boton continue
  checkValue(event: any) {
    if (event > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }

  close() {
    console.log("close");
    this._emitRefModal("close");
  }

  addItinerary(item: any, itemResult: any) {
    console.log("ModalRoomsComponent--- addItinerary()");
    console.log(this.origin);
    console.log(this.arrayResults);
    console.log('item result ->', itemResult);

    item.taxes = itemResult.taxes ? itemResult.taxes : [];
    item.taxPrice = itemResult.taxPrice ? itemResult.taxPrice : 0; //monto impuesto por noche
    item.tax = itemResult.tax ?  itemResult.tax : 0; // valor del iva aplicado
    item.profility = itemResult.additionalInfo.carRental.profility ? itemResult.additionalInfo.carRental.profility : 'Falta profility';

    item.images = itemResult.additionalInfo.images;
    item.id = itemResult.id + "-" + item.optionId;
    item.searchId = itemResult.searchId;
    item.providerId = itemResult.providerId;
    item.providerName = itemResult.providerName;
    item.rentacarId = itemResult.id;
    item.idProduct = itemResult.productId;
    item.categoryName = Procesed.RENT_A_CAR;
    item.title = itemResult.title;
    item.location = itemResult.additionalInfo.location;
    item.arrivalDate = itemResult.dateIni;
    item.departureDate = itemResult.dateEnd;
    item.updateDate = (moment(new Date())).format('DD-MMM-YYYY HH:mm:ss');
    item.totalPaymentAgency = this.totalPaymentAgency;
    item.totalRent = this.totalRent;
    item.totalPrice = itemResult.additionalInfo.carRental.totalPrice;
    item.productKey = this.getRandomUniqueId();
    // item.ratecode = itemResult.additionalInfo.carRental.RatePlanCode;
    // se agrega el payload de busqueda de producto al objeto rentacar
    item.payloadUpdate = this._payloadUpdateRentaCar(this.payload);
    this.form = JSON.parse(localStorage.getItem("form"));
    console.log("form agregando", this.form);
    // estoy llegando del home
    item.form = this.form;
    console.log("form agregando desde el home", this.form);
    item.payloadUpdate = this._payloadUpdateRentaCar(this.payload);
    item.destination = this._itineraryService.getDestinationInfo(this.form, item);

    if (this.itineraryItem) {
      item.destination = this.itineraryItem.destination;
      this._itineraryService.remove(this.itineraryItem, item);
      this._emitRefModal("success - Congratulations - Product updated succesfully");
      this.toastr.success("Congratulations !!", "Product Successfully updated");
    } else {
      console.log("item antes de guardar", item);
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(item).subscribe(
        (response: any) => {
          console.log("Add-Success: ", response);
          if (typeof response === "string") {
            this._emitRefModal("error - Error - " + response);
          } else {
            // this._itineraryService.currencySuccessEmit.next(item.currency);
            this._emitRefModal("success - Congratulations - Product added succesfully to your itinerary");
            this.toastr.success("¡Felicitaciones! Producto agregado con éxito.");
          }
          this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary["items"]);
        },
        (error) => {
          console.log("Add-Error: ", error);
          this._emitRefModal("error - Error - Item was not added to your itinerary");
          this._itineraryService.sendMessageSubscriptorLoading(false);
        }
      );
    }
  }

  _payloadUpdateRentaCar(payload: any): Object {
    console.log(this.origin);

    return {
      products: [
        {
          providerId: this.result.providerId,
          nameAdapter: this.result.nameAdapter,
          categoriesId: [2],
          // productId: this.result.id,
          productId: this.result.productId,
          productName: this.result.title,
          origin: "MIA", //this.result.origin,
          destination: "MIA", //this.result.destination,
          priceFrom: 0,
          priceTo: 10000.0,
          arrivalDate: this.result.dateIni,
          departureDate: this.result.dateEnd,
          qtyProduct: 1,
          qtyPax: 0,
          others: {
            carRental: payload.others.carRental,
          },
        },
      ],
    };
  }

  async mapMarker() {
    /* const  icon = `<svg width="auto" height="33" xmlns="http://www.w3.org/2000/svg">
              <image href="/assets/img/app/marca2.png" height="200" width="200"/>
              </svg>`;
*/  
    const brandDefault = "assets/img/app/rent-a-car-default.png"
    const icon = this.itemSelect.additionalInfo.carRental?.optionRates?.suplier?.img || brandDefault;
    let geocoder: any;
    let mapa: any;
    const mapOptions = {
      center: new google.maps.LatLng(28.3256749, -81.51555309999999),
      zoom: 4,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

    for (let i = 0; i <= 1; i++) {
      geocoder = new google.maps.Geocoder();
      const addIcon = "favorite_border";
      /*  const infowindow = new google.maps.InfoWindow({
        content:;
      });*/
      const latlng = {
        // tslint:disable-next-line:max-line-length
        lat: parseFloat(i == 0 ? this.otherOpcions?.PickUp?.latitude : this.otherOpcions?.DropOff?.latitude),
        // tslint:disable-next-line:max-line-length
        lng: parseFloat(i == 0 ? this.otherOpcions?.PickUp?.longitude : this.otherOpcions?.DropOff?.longitude),
      };

      console.log("mapMarker latlng", latlng);

      geocoder.geocode({ location: latlng }).then((response) => {
        if (response.results[0]) {
          const marker = new google.maps.Marker({
            position: latlng,
            map: mapa,
            //  title: '',
            draggable: true,
            /* label: {
                text: '',
                className: "tooltip-one",
              },*/
            icon:{
              url: icon,
              scaledSize: new google.maps.Size(50, 50),
            },
          });
          google.maps.event.trigger(mapa, "resize");
          mapa.panTo(marker.getPosition());
          mapa.setZoom(10);
        } else {
          console.log("No se obtuvo la ubicacion del mapa: ");
        }
      });
    }
    mapa.set("parent", this);
  }

  getRandomUniqueId(){
    return Math.floor(Math.random() * 20000000000000) + Date.now();
  }

  rentacarLocationsDetails():void{
    this.rentacarLocations = [];

    this.arrayResults.forEach((locations:any) => {
      const pickUp = locations.additionalInfo?.carRental?.LocationDetails.PickUp;
      const dropOff = locations.additionalInfo?.carRental?.LocationDetails.DropOff;
      const operationTime = locations.additionalInfo?.carRental?.LocationDetails.OperationTime;

      if (dropOff && pickUp) {
        this.rentacarLocations.push({ DropOff: dropOff, PickUp: pickUp, OperationTime:operationTime});
      }
    });

  }

  officesRepeated():void{
    let officesArray: any[] = [];

    this.arrayResults.forEach((offices:any) => {
        const allOffices = offices.additionalInfo.carRental.LocationDetails;
        officesArray = officesArray.concat(allOffices);
    });

    const officesMap = new Map();

    console.log('officesArray ->', officesArray);

    officesArray.forEach((office:any) => {
      officesMap.set(office.PickUp.Name, office)
    });

    this.officesNotRepeated = Array.from(officesMap.values())

    console.log('this.officesNotRepeated ->', this.officesNotRepeated);
  }

  showWindowInfo(carSelected:any):void{
    console.log('item select show', carSelected);
    const includeDescription = carSelected.providerName;
    if(includeDescription == 'ACTIONTRAVEL'){
      this.booleanWindowInfo = true;
    }
  }


  // timesAgencies(){
  //   console.log('array result times ->', this.arrayResults);
  //   console.log('pickUptime ->', String(this.pickUpTime));
  //   console.log('dropofftime ->', this.dropOffTime);
    
  //   let arrayName = this.arrayResults?.additionalInfo?.carRental?.LocationDetails?.OperationTime.filter((times:any) => times.hoursStart < this.pickUpTime);
  //   console.log('time operation ->', arrayName);
  // }


}
