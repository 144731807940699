import { Component, Input, OnInit } from '@angular/core';
import { setting } from '../../../../setting';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'usb-capacitation',
  templateUrl: './capacitation.component.html',
  styleUrls: ['./capacitation.component.scss']
})
export class CapacitationComponent implements OnInit {
  @Input() getClientsSettingAppBooking: any;
  public routeUploadFile: string;
  public videoRoute: string;

  constructor() {
    this.routeUploadFile =  setting.routeUploadFile;
  }

  ngOnInit() {
    const clientVideo = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_video_home);
    this.videoRoute = `${this.routeUploadFile}${clientVideo?.video}`;
  }

}
