import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, AfterViewInit, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ItineraryService} from '../../shared/itinerary.service';
import { FidelityService } from '../../fidelity/fidelity.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModalAlertComponent } from '../../shared/material-modal-alert/material-modal-alert.component';
import { Observable } from 'rxjs';
import { setting } from '../../../../setting';
import { AuthorizationService } from 'app/core/authorization/authorization.service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/profiles/storeProfile';
import { selectPermissions } from 'app/core/profiles/profiles.selectors';
import { DynamicFontSizePipe } from 'app/shared/dynamic-font-size.pipe';


@Component({
  selector: 'usb-price-itinerary',
  templateUrl: './price-itinerary.component.html',
  styleUrls: ['./price-itinerary.component.scss'],
  providers: [DatePipe, DynamicFontSizePipe]
})
export class PriceItineraryComponent implements OnInit, AfterViewInit {

  @Input() itinerary: any;
  @Input() screen: any;
  @Input() partialReserve: boolean;
  @Input() modifications: boolean;
  @Input() showPartialContainer: boolean;
  @Input() netPriceBookingFreeTax: number;
  @Input() netPriceBooking: number;
  @Input() totalTaxNightsPriceTotal: number;
  @Input() taxSum: number = 0;
  @Output() updateEmitter = new EventEmitter<any>();
  @Output() netPrice      = new EventEmitter<any>();
  modalRef: BsModalRef;
  element: HTMLElement;
  element_: HTMLElement;
  _emailAgency: any;
  @Input() fromReserve: boolean;

  fee: any = 0;
  totalPrice: number = 0;
  feePercentage: number = 0;
  pointCategory: any= 0;
  totalPriceByReserved: any= 0;
  actualItinerary: any;
  ///  totalPriceNetByReserved: any= 0;
  priceToPay: number = 0;
  blickoinsPartial: number = 0;
  reserved: boolean = false;

  public getClientsSettingAppBooking: any;

  totalPriceNotFeeByReserved: any;
  totalPriceYesFeeByReserved: any;


  button =  <HTMLInputElement>document.getElementById('buttonSync');
  sec: any;
  timeLeft: any = '';

  permissions$: Observable<string[]>;
  permissionsMap: { [key: string]: boolean } = {};
  getOrigin:string = '';
  fontSizeAdjustments = { 4: 0, 6: -2, 9: -6 };

  public bookingURL: string;
  public currencyItinerary: string;
  ngAfterViewInit() {
    const tooltipTriggerList = this.elementRef.nativeElement.querySelectorAll('[data-bs-toggle="tooltip"]');
   // const tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  }


  itinerary$: Observable<any[]>;

  constructor(
              public itineraryService: ItineraryService,
              private datePipe: DatePipe,
              private modalService: BsModalService,
              public fidelityService: FidelityService,
              public userService: UserService,
              public dialog: MatDialog,
              private elementRef: ElementRef,
              private authService: AuthorizationService,
              private store: Store<AppState>
            ) {
              this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
              this.bookingURL = setting.apiBookingUrl;
              }

  ngOnInit() {
    this.getOrigin = localStorage.getItem('origin');

    this.permissions$ = this.store.select(selectPermissions);
    // Despacha las acciones para cargar roles y permisos
    this.store.dispatch({ type: '[Auth] Load Permissions' });

    this.permissions$.subscribe(permissions => {
      this.permissionsMap = this.createPermissionsMap(permissions);
    });
    // console.log("itinerary---->", this.itinerary);
    // console.log("this.userService.itineraryActive[0]", this.userService.itineraryActive[0]);
    // console.log("this.itinerary -->", this.itineraryService);

    this.userService.totalPriceNotFeeByReserved =  0;
    this.userService.totalPriceYesFeeByReserved =  0;
    this.userService.totalPriceNotFee = 0;
    this.userService.totalPriceYesFee = 0;
    this.userService.priceRightFee = 0;
    this.userService.priceRightFeePercentage = 0;
    this.userService.totalBlickoins = 0;
    this.itineraryService.parcialBlickoins = 0;

    if (this.userService.itineraryActive[0] !== undefined ) {
      this.actualItinerary = this.userService.itineraryActive[0];
    } else {
      this.actualItinerary = this.itinerary;
    }

    // console.log(this.actualItinerary);

    this.userService.totalPriceNotFeeByReserved = this.userService.totalPriceNotFeeByReserved;
    this.userService.totalPriceYesFeeByReserved = this.userService.totalPriceYesFeeByReserved;
    this.userService.totalPriceNotFee = this.actualItinerary.totalNetPriceBooking > 0 ? this.actualItinerary.totalNetPriceBooking : this.actualItinerary.netPriceBookingFreeTax;
    this.userService.totalPriceNotFee = this.actualItinerary.totalNetPriceBooking > 0 ? this.actualItinerary.totalNetPriceBooking : this.actualItinerary.netPriceBookingFreeTax;    this.userService.totalPriceYesFee = this.actualItinerary.totalNetPriceBooking > 0 ? this.actualItinerary.totalNetPriceBooking : this.actualItinerary.netPriceBookingFreeTax;
    this.userService.priceRightFee = this.actualItinerary.totalFee;
    this.userService.priceRightFeePercentage = this.actualItinerary.percentageFee;
    this.itineraryService.parcialBlickoins = this.itineraryService.parcialBlickoins;
    this.currencyItinerary = this.actualItinerary.currency;
    console.log(this.userService.totalPriceYesFee)
    // console.log("this.actualItinerary", this.actualItinerary);
    this.itinerary$ = this.itineraryService.getItinerary$();
    this.itinerary$.subscribe((data:any) => {
      this.searchPriceToReserve();
    });
    this.setValueTotals();

    if(this.fromReserve) {

    }
  }

  createPermissionsMap(permissions: any[]): { [key: string]: any } {
    const map: { [key: string]: any } = {};
    permissions.forEach((permission) => {
      // Utiliza el nombre del permiso como clave
      map[permission.name] = permission;
    });
    return map;
  }



   blockButton(time: any) {
      let   countdown = 10;
      const interval = setInterval(() => {
        countdown = --countdown <= 0 ? 10 : countdown;
        this.timeLeft =  countdown;
        this.sec = true;
        if (countdown == 1) {
          this.sec = false;
          this.timeLeft = '';
          clearInterval(interval);
        }
      }, 1000);
  }


  sendMail() {
    this.element_ = document.getElementById('items-' + this.itinerary.id) as HTMLElement;
      if (this.element_) {
          this.element = document.getElementById('contentPDF-' + this.itinerary.id) as HTMLElement;
          this.itineraryService.postSendEmail(this.itinerary, this.element.innerHTML).subscribe(
          (response:any) => {
            if (response['send'] === 'true') {
                this.modalRef.hide();
                //this.toastr.success(response['mssg']);
                console.log('enviado');
            } else {
                this.modalRef.hide();
                //this.toastr.error(response['mssg']);
                console.log('no enviado');
            }
          },
        );
      }
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }
  closeModal(): void {
    this.modalRef.hide();
  }

  getUpdateDate() {
    return this.datePipe.transform(new Date(), 'MMM dd, yyyy');
  }
  getModificationDate(){
    return this.datePipe.transform(this.itinerary.updated_at, 'MMM dd, yyyy');
  }

  updateItinerary(){
    this.blockButton(10000);
    this.updateEmitter.emit();
  }


  getComission() {
    let comission = 0;
    if (this.itinerary.items.length > 0) {
        this.itinerary.items.forEach(item => {
        comission += item.optionComissionValue ? item.optionComissionValue : 0;
      });
    }
    return comission;
  }

  globalFeeClick(){
    this.userService.globalFee = this.userService.priceRightFeePercentage;

  }

  verifyGlobalFee(){
    if(this.userService.globalFee >0){
      this.userService.itinerayDetailFee.percentageFee = 0.0;
      this.userService.itinerayDetailFee.totalFee = 0.0;
      this.userService.itinerayDetailFee.totalPriceAgent = 0.0;
      this.userService.sendGlobalFee$(this.userService.globalFee);
    }
  }

  changeGlobalFee(){
    if (this.userService.globalFee == "") {

      var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
        width: '350px',
        data: 'Debe agregar un valor',
        disableClose: true
      });
      this.userService.globalFee = 0;
    }else{
      if(this.userService.globalFee < 0){
        this.userService.globalFee = 0;
      }
      this.userService.sendGlobalFee$(this.userService.globalFee);
    }
  }

  validateProductsToPay(){

    this.reserved = this.itinerary.items.find(element => element.status_id == 1);
    // console.log("existe items con status id 2 ", reserved);
    if(this.reserved){
      let filter = this.itinerary.items.filter(element => element.status_id != 2 && element.status_id != 4 );
      // console.log("existe items con status id 2 ", filter);

      if(filter.length > 0){
        let i =0;
        filter.forEach(element => {
          // tslint:disable-next-line:max-line-length
          if (element.isPendigApprovePay===false && element.isPaidAgency===false && element.isPaidProvider===false && element.isBilled===false){
            i++;
          }
        });

        if(i > 0){
          return true;
        }else{
          return false;
        }
      }
    }

  }

  searchPriceToReserve(){
    let result = this.itinerary.items.filter(element => element.status_id != 2 &&   element.status_id != 5);
    // console.log("existe items con status id != 2 ", result);
    let price = 0;

    if(result.length > 0){
      let i =0;
      result.forEach(element => {
        price = price + parseFloat(element.netPriceBookingFreeTax) ;
        i++;
      });
      this.priceToPay = price;
    }
  }

  setValueTotals(){
    let result = this.itinerary.items.filter(element => element.status_id == 5);

    if(result == 5){
      this.userService.totalPriceNotFee = 0;
      this.userService.totalPriceYesFee = 0;
    }
  }

  downloadLiquidation(id: number): void {
    const url = `${this.bookingURL}downloadLiquidations/${id}/${this.getClientsSettingAppBooking.id}`;
    window.open(url);
  }


}
