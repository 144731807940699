import { Component, ViewChild, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';


import { TitleCasePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CustomValidator } from '../../../customValidators';

//import { SortableModule, SortableComponent } from 'ngx-bootstrap/sortable';

import { ItineraryService } from '../../../../shared/itinerary.service';
//import { ToastsManager } from 'ng2-toastr';
//import { SortablejsOptions } from 'angular-sortablejs';

import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { RoomConfigurationItem } from '../../../../home/search-box/categories/hotels/roomConfigurationItem';
import { RestService } from '../../../rest.service';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';

import { MaterialModalConfirmComponent } from '../../../material-modal-confirm/material-modal-confirm.component';
import { UbicacionesService } from '../../../../home/search-box/service/ubicaciones.service';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'usb-multi-destination',
  templateUrl: './multi-destination.component.html',
  styleUrls: ['../../itinerary-sidebar.component.scss'],
  providers: [UbicacionesService, TitleCasePipe, DatePipe]
})
export class MultiDestinationComponent implements OnInit {

  @Input() payload: any;
  @Input() results: any;
  @Output() destinations: any;

  item: any;
  modalRef: BsModalRef;
  _activeItinerary: any;
  _clientData: any;
  _form: FormGroup;
  _destinationForm: FormGroup;
  _longDestination: String;
  _destinations: any = [];
  _arrivalDate: string;
  selectedDestiny: String;
  destiny: String;
  disableButton: Boolean;
  itinerarySet: Boolean = true;
  disabledAddToMyTrip: boolean = true;
  _isOutOfUSA:boolean = true;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  rooms = [];

  _payloadArray = [];

  //@ViewChild(SortableComponent) sortableComponent: SortableComponent;
  loadingReording: boolean = false;


  // eventOptions: SortablejsOptions = {
  //   onUpdate: () => {
  //     this._reorderDestinations()
  //   }
  // };

  listStyle = {
    width:'100%', //width of the list defaults to 300
    height: 'unset', //height of the list defaults to 250
  }

  itinerary$: Observable<any[]>;

  constructor(
    private modalService: BsModalService,
    private ubicacionesService: UbicacionesService,
    private fb: FormBuilder,
    private itineraryService: ItineraryService,
    private _formBuilder: FormBuilder,
//
    private datePipe: DatePipe,
    private titlecasePipe: TitleCasePipe,
    private restService:RestService,
    public userService: UserService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {

    this.itineraryService.consultActiveItinerary(this.userService._agentID);
    this.disableButton = true;
    this._initComponent();

    this._destinationForm = this.fb.group({
      destination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      nights: [0, Validators.compose([Validators.required])],
    });


  }

  evaluateDisabledButton() {
    this.disabledAddToMyTrip = true;
    const valueSelect = document.getElementById('nights')['value'];
    if (parseInt(valueSelect) !== 0 && !this._isOutOfUSA) {
      this.disabledAddToMyTrip = false;
    }
  }

  openModalWithClass(template: TemplateRef<any>, item: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' })
    );
    this._initForms(item);
  }

  _initForms(item: any) {
    this._form = this._formBuilder.group({
      rooms: this._setRooms(item),
      nights: [item.nights]
    });
  }

  _setRooms(item: any): FormArray {
    const dist = item.value !== undefined ? item.value.roomsDist : item.roomsDist;
    const rooms = this._formBuilder.array([]);

    dist.forEach(room => {
      const _childsAges = this._formBuilder.array([]);
      room.children.forEach(_age => {
        _childsAges.push(this._formBuilder.group({
          // tslint:disable-next-line:max-line-length
          age: [_age, Validators.compose([Validators.required, CustomValidator.soloNumerosValidator, Validators.max(17)])]
        }));
      });
      rooms.push(this._formBuilder.group({
        qtyChilds: [_childsAges.length],
        qtyAdults: [room.adults],
        childsAges: _childsAges
      }));
    });
    return rooms;
  }

  _initComponent() {

    console.log("Se activo el paso 1 en comeback");

    this.itinerary$ = this.itineraryService.getItinerary$();
    this.itinerary$.subscribe((data:any) => {

    //this.itineraryService.activeItinerary.subscribe((data:any) => {

      console.log('data subscribe destinarions', data);

      if (data) {
        if (data.length > 1) {
          this._clientData = data[1];
        }

        if (data[0] !== undefined && data[0] !== null) {
          this._activeItinerary = data;

          let date;

          if (this.payload === undefined || this.payload === null) {
            if (data[0].arrivalDate !== undefined) {
              //const removeHr = data[0].arrivalDate.split(' ');
              //date = removeHr[0].split('-');
              this._arrivalDate = data[0].arrivalDate;
            } else {
              //this.toastr.error('Arrival date must be defined in home.', 'Unknown Arrival Date.');
            }
          } else {
            this._arrivalDate = this.payload.arrivalDate;
          }

          console.log("arrivalDate antes de modDates", this._arrivalDate);

          this._destinations = [];
          if (data[0].destination.length > 0) {

            for (let i = 0; i < data[0].destination.length; i++) {
              const arrival = data[0].destination[i].destination_info.arrival.split('-');
              const departure = data[0].destination[i].destination_info.departure.split('-');
              this._destinations.push({
                cityIataCode: data[0].destination[i].iata_code,
                cityName: data[0].destination[i].destination_info.cityName,
                nights: data[0].destination[i].destination_info.nights,
                adults: data[0].destination[i].destination_info.adults,
                childs: data[0].destination[i].destination_info.childs,
                rooms: data[0].destination[i].destination_info.rooms,
                roomsDist: data[0].destination[i].destination_info.roomDist,
                yy: this.datePipe.transform(data[0].destination[i].destination_info.arrival, 'yy'),
                mm: this._getMonthName(arrival[1]),
                dd: arrival[2],
                yyy: this.datePipe.transform(data[0].destination[i].destination_info.departure, 'yy'),
                mmm: this._getMonthName(departure[1]),
                ddd: departure[2]
              });
            }
            //this._modifyDates();
            //se envia la notificacion al objeto map para que escuche los cambios
            this.itineraryService.sendMessageSubscriptorMap(data);
            this._setPayloadArray(this._destinations);
          }else{
            this._setPayloadArray(this._destinations);
          }
        }

        this.evaluateDisabledButtonContinue(data.length);
        //this._setPayloadArray(this._destinations);
      }



    });

    setTimeout(() => {
      if(!this._arrivalDate && !this.userService.itineraryActive){
        this._arrivalDate = this.userService.itineraryActive[0].arrivalDate;
        console.log('this._arrivalDate nuevo', this._arrivalDate);
      }
    }, 4000);
  }


  // _payload(data: any) {
  //   //const room = form.get('room') as FormArray;
  //   const payload = {
  //       providersId: null,
  //       categoriesId: [0],
  //       origin: '',
  //       destination: form.value['destination'],
  //       priceFrom: 0,
  //       priceTo: 20000,
  //       arrivalDate: moment(form.value['arrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD'),
  //       departureDate: null,
  //       qtyProduct: +form.value['qtyProduct'],
  //       qtyPax: this._getAdultsChildrenQty('qtyAdults', form.value['room']),
  //       others: {
  //           serialized: false,
  //           maxRequestTimeOnSeconds: 20,
  //           minNumResults: 1,
  //           maxNumResultsPerAdapter: "",
  //           hotels: {
  //               roomConfiguration: this._getRoomDist(form)
  //           }
  //       }
  //   };
  //   return payload;
  // }

  _isDestinationEditable(destino: any) {
    let editable = true;
    if (destino) {
      const items = this._activeItinerary[0].items;
      if (items) {
        items.forEach(item => {
          if (item.payloadUpdate && item.payloadUpdate.products && item.payloadUpdate.products[0]) {
            if (item.payloadUpdate.products[0].destination === destino.cityIataCode) {
              editable = false;
              return;
            }
          }
        });
      }
    }
    return editable;
  }

  evaluateDisabledButtonContinue(data: any) {

    console.log("this._destinations null", this._destinations);

    if ((this._destinations !== undefined && this._destinations !== null && this._destinations.length > 0)) {
      this.disableButton = false;
    }

    console.log("this.disableButton", this.disableButton);
  }

  _myListFormatter(data: any): string {
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _myCallback(data: any): string {
    this._longDestination = this.destiny['cityName'];
    this.selectedDestiny = this.destiny['cityIataCode'];
    this._isOutOfUSA = false;
    if(data['countryIataCode']!='US'){
      this._isOutOfUSA = true;
      this.disabledAddToMyTrip = true;
    }
    console.log ('retornar ', `${data['cityName']} (${data['cityIataCode']})`);
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _observableSource = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getCitys(keyword);
  }

  _removeRoom(index: number) {
    const rooms = this._form.get('rooms') as FormArray;
    rooms.removeAt(index);
  }

  _addRoom() {
    const rooms = this._form.get('rooms') as FormArray;
    const _childsAges = this._formBuilder.array([]);

    rooms.push(this._formBuilder.group({
      qtyChilds: [_childsAges.length],
      qtyAdults: [0],
      childsAges: _childsAges
    }));
  }

  _addChild(room: FormGroup) {
    setTimeout(() => {
      const ages: any = room.get('childsAges') as FormArray;
      const qty: number = +room.value['qtyChilds'];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this._formBuilder.group({
            // tslint:disable-next-line:max-line-length
            age: [0, Validators.compose([Validators.required, CustomValidator.soloNumerosValidator, Validators.min(0), Validators.max(17)])]
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  cancel() {
    this.modalRef.hide();
  }

  _roomsApply(event?: Event, index?: number) {
    const roomDist = [];
    var qtyAdults = 0;
    var qtyChildren = 0;
    this._form.value['rooms'].forEach(room => {
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: [],
        boardNameCode:1,
        condition:1,
        roomId:1,
        roomCode:19,
        bookingCode:null
      };
      qtyAdults += parseInt(room.qtyAdults);
      qtyChildren += room.childsAges.length;
      for (let j = 0; j < room.childsAges.length; j++) {
        people.push(room.childsAges[j].age);
      }
      dist.adults = room.qtyAdults;
      dist.children = people;
      roomDist.push(dist);
    });
    this._destinations[index]['roomsDist'] = roomDist;
    this._destinations[index]['rooms'] = roomDist.length;
    this._destinations[index]['childs'] = qtyChildren;
    this._destinations[index]['adults'] = qtyAdults;
    this._destinations[index]['nights'] = parseInt(this._form.value.nights, 10);

    this._reorderDestinations();
    this.modalRef.hide();
  }



  _submitDestination(form: any, event: Event) {
    console.clear();
    console.log("this._arrivalDate submit", this._arrivalDate);
    console.log("this.payload submit", this.payload);

    if (this.payload == undefined && localStorage.getItem('payload') ) {
      this.payload = JSON.parse(localStorage.getItem('payload'));
      console.log("no habia payload y lo agarro de local", this.payload);
      this._arrivalDate = this.payload.arrivalDate;
    }

    if (this._arrivalDate === undefined || this._arrivalDate === null || this.payload === undefined) {
      if (!this.itinerarySet) {
        //this.toastr.error('It is necessary to create an itinerary');
      } else {
        //this.toastr.error('Arrival date must be defined in home.', 'Unknown Arrival Date.');
      }
    } else {
      if (this._destinations === undefined) {
        this._destinations = [];
      }
      let destination;
      if (typeof form.value.destination === 'object') {
        var qtyChilds = 0;
        var qtyAdults = 0;
        if (this.payload.others !== undefined && this.payload.others !== null) {
          this.payload.others.hotels.roomConfiguration.forEach(room => {
            qtyAdults += parseInt(room.adults);
            qtyChilds += room.children.length;
          })
        }

        destination = {
          cityIataCode: form.value.destination.cityIataCode,
          cityName: this.titlecasePipe.transform(form.value.destination.cityName),
          nights: parseInt(form.value.nights, 10),
          adults: qtyAdults,
          childs: qtyChilds,
          rooms: this.payload.others.hotels.roomConfiguration.length,
          roomsDist: this.payload.others.hotels.roomConfiguration
        };
        this._destinations.push(destination);
        this._modifyDates();
        this._emitDestinations(true);
      } else {
        //this.toastr.warning('You must type a city and then, pick one from the list.', 'Please select a city');
      }
    }
    this.resetSaveFormFields(true);
  }

  _removeDestination(index: number) {
    const message = '¿Estas seguro de eliminar este destino?';
    var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
      width: '350px',
      data: {message: message},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if(result){
        console.log("")
        const itemToDelete = this._destinations[index];
        console.log(itemToDelete);
        this._destinations.splice(index, 1);
        this._modifyDates();
        const items = [];
        for (let i = 0; i < this._activeItinerary[0].items.length; i++) {
          if (this._activeItinerary[0].items[i].payloadUpdate !== undefined && this._activeItinerary[0].items[i].payloadUpdate !== null) {
            if (this._activeItinerary[0].items[i].payloadUpdate.products[0].destination === itemToDelete.cityIataCode) {
              items.push(this._activeItinerary[0].items[i]);
            }
          }
        }
        if (items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            this.itineraryService.remove(items[i]);
          }
        }
        // this._deleteItems();
        this._emitDestinations(true);
        //this.toastr.success('', 'Location Deleted!');

      }else{

      }
    });

  }

  _reorderDestinations() {
    this.loadingReording = true;
    console.log(this._destinations);
    this._modifyDates();

    setTimeout(() => {
      //this._emitDestinations(true);
    }, 500);

    setTimeout(() => {
      this.loadingReording = false;
    }, 2700);
  }

  resetSaveFormFields(flag: boolean) {
    if (flag) {
      this._destinationForm = this.fb.group({
        destination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
        nights: [1, Validators.compose([Validators.required])],
      });
    }
  }

  _setPayloadArray(destinations: any) {
    this._payloadArray = [];

    console.log("_setPayloadArray sthis.payload", this.payload)

    for (let i = 0; i < destinations.length; i++) {
      let arrival = null;
      if (this.payload !== undefined && this.payload !== null) {
        if (this.payload.others !== undefined && this.payload.others !== null) {
          var qtyAdults = 0;

          if(this.payload.others.hotels.roomConfiguration){
            this.payload.others.hotels.roomConfiguration.forEach(room => {
              qtyAdults += parseInt(room.adults);
            });
          }
        }
      }
      if (this._arrivalDate !== undefined || this._arrivalDate !== null) {
        arrival = new Date((i === 0) ? this._arrivalDate : this._payloadArray[i - 1].departureDate);
        this._payloadArray[i] = {
          arrivalDate: (i === 0) ? this._arrivalDate : this._payloadArray[i - 1].departureDate,
          categoriesId: [1],
          departureDate: this._getDeparture(arrival, destinations[i].nights),
          origin: (this.payload === undefined) ? 'MULTIDESTINATION' : this.payload.origin,
          destination: destinations[i]['cityIataCode'],
          others: this.payload !== undefined ? this.payload.others : null,
          priceFrom: (this.payload === undefined) ? 'MULTIDESTINATION' : this.payload.priceFrom,
          priceTo: (this.payload === undefined) ? 20000 : this.payload.priceTo,
          providersId: (this.payload === undefined) ? null : this.payload.providersId,
          qtyPax: qtyAdults,
          qtyProduct: this.payload!=undefined ? this.getqtyPax() :null,
        };
      } else {
        //this.toastr.error('Arrival date must defined in home.', 'Unknown Arrival Date.');
      }
    }
  }

  getqtyPax(){
    if(this.payload[0]){
      return this.payload[0].others.hotels.roomConfiguration.length
    }else{
      return this.payload.others.hotels.roomConfiguration ? this.payload.others.hotels.roomConfiguration.length : null;
    }
  }

  _getMonthName(month: number) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Octr', 'Nov', 'Dec'];
    if (month !== null) {
      return monthNames[month - 1];
    }
  }

  _getDeparture(arrivalDate: any, nights: number) {
    arrivalDate.setDate(arrivalDate.getDate() + (nights + 1));
    const month = (arrivalDate.getMonth() < 9) ? '0' + (arrivalDate.getMonth() + 1) : arrivalDate.getMonth() + 1;
    const day = (arrivalDate.getDate() < 10) ? '0' + (arrivalDate.getDate()) : arrivalDate.getDate();
    return arrivalDate.getFullYear() + '-' + month + '-' + day;
  }

  _modifyDates() {
    this._setPayloadArray(this._destinations);
    console.log("_payloadArray", this._payloadArray);
    if (this._activeItinerary !== undefined) {
      this._activeItinerary[0].arrivalDate = this._arrivalDate;
      this._activeItinerary[0].destination = [];
      for (let i = 0; i < this._destinations.length; i++) {
        let date;
        if (this._payloadArray[i].arrivalDate !== undefined) {
          date = this._payloadArray[i].arrivalDate.split('-');
          this._destinations[i].yy = this.datePipe.transform(this._arrivalDate, 'yy');
          this._destinations[i].mm = this._getMonthName(date[1]);
          this._destinations[i].dd = date[2];
            const destination = {
              'iata_code': this._destinations[i]['cityIataCode'],
              'destination_info': {
                'childs': this._destinations[i]['childs'],
                'adults': this._destinations[i]['adults'],
                'rooms': this._destinations[i]['rooms'],
                'roomDist': this._destinations[i]['roomsDist'],
                'position': i + 1,
                'nights': this._destinations[i].nights,
                'arrival': this._payloadArray[i]['arrivalDate'],
                'departure': this._payloadArray[i]['departureDate'],
                'cityName': this._destinations[i]['cityName']
              }
            };
          this._activeItinerary[0].destination.push(destination);
        }
      }
    }

    console.log("this._destinations", this._destinations);
    console.log("this._activeItinerary[0] updated", this._activeItinerary[0]);
    this._emitDestinations(true);
  }

  /* pasar true si se quiere actualizar el itinerario en bd */

  _emitDestinations(update: boolean) {
    if (this._destinations.length > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
    this.itineraryService.emitActiveItinerary(this._activeItinerary, update);
  }

}
