import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSplashService {

  private loadingState: { [key: string]: BehaviorSubject<boolean> } = {};

  setLoading(id: string, isLoading: boolean) {
    if (!this.loadingState[id]) {
      this.loadingState[id] = new BehaviorSubject<boolean>(false);
    }
    this.loadingState[id].next(isLoading);
  }

  getLoading(id: string): Observable<boolean> {
    if (!this.loadingState[id]) {
      this.loadingState[id] = new BehaviorSubject<boolean>(false);
    }
    return this.loadingState[id].asObservable();
  }
}
