<li class="list-group-item" style="margin:5px;">
    <div class="row text-left">
        <small><strong>{{dt.location}}</strong></small>
    </div>
    <div class="row text-left">
        <h6><i class="fa fa-building-o" aria-hidden="true"></i> {{dt.title}}</h6>
    </div>
    <div class="row">
        <div class="col-md-4 mb-4 m-auto  p-1">
            <strong>Qty:</strong>
        </div>
        <div class="col-md-8 mb-4 m-auto  p-1">
            <span>{{dt.qty}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-5 mb-4 m-auto  p-1">
            <strong>Price Night:</strong>
        </div>
        <div class="col-lg-7 mb-4 m-auto p-1">
            <span class="padding-right">$ {{dt.netPrice}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 mb-4 m-auto  p-1">
            <strong>Entry</strong>
        </div>
        <div class="col-lg-8 mb-4 m-auto p-1">
            <span class="padding-right">{{dt.arrivalDate | date : "short"}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 mb-4 m-auto  p-1">
            <strong>Departure</strong>
        </div>
        <div class="col-lg-8 mb-4 m-auto  p-1">
            <span class="padding-right">{{dt.departureDate | date : "short"}}</span>
        </div>
    </div>
    <div class="row">
        <div style="text-align: right; float:right; width: 100%;">
            <button *ngIf="!dt.confirmedReserve" class="btn btn-sm btn-primary justify-content-end cursor-pointer" (click)="remove(dt)">
                <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <button (click)="getDetail(dt.reserveData)" *ngIf="dt.confirmedReserve && dt.reserveData.status != 'Cancelled'" title="View Details" class="btn btn-sm btn-info justify-content-end cursor-pointer">
                <i class="fa fa-check" aria-hidden="true" title="View Details">Reserved</i>
            </button>
            <button (click)="getDetail(dt.reserveData)" *ngIf="dt.confirmedReserve && dt.reserveData.status == 'Cancelled'" title="View Details" class="btn btn-sm btn-warning justify-content-end cursor-pointer">
                <i class="fa fa-close" aria-hidden="true" title="View Details">Cancelled</i>
            </button>
        </div>
    </div>
</li>