import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResultsSearchService } from '../home/results-search.service';
import { UserService } from '../pages/com.usblick.common/auth/user.service';
import { RestService } from './rest.service';

@Injectable()
export class ResearchService {

  constructor(
    public userService: UserService,
    private router: Router,
    private resultsSearch: ResultsSearchService,
    private _restService:RestService,
    private spinner:NgxSpinnerService,
    private datePipe: DatePipe,
  ) { }


  makePayload(item) {

    this.spinner.show();
    console.clear();
    console.log("click makePayload");
    //this.initPayload();

    if (this.userService.itineraryActive[0].destination.length == 1) {

      console.log("Unitario research");
      //Es una busqueda unitaria
      switch (item.categoryName) {
        case "HOTELS":

          var payload = item.payloadUpdate.products[0];
          payload.categoriesId = [1];
          payload.categoryId = 1;
          payload.providerId = null;
          payload.origin = "";
          payload.qtyPax = 1;
          delete payload.productId;
          delete payload.nameAdapter;
          delete payload.productName;
          payload.others.hotels.minNumResults = 4;
          payload.others.minNumResults = 4;

          console.log("this.item payload", payload);

          this._restService.doPost('app', 'search', payload).subscribe(
            (response:any) => {

              console.log("responseInit", response);
              let payloadArray = [item.payloadUpdate.products[0]];
              console.log("payloadArray", payloadArray);
              this.resultsSearch._setPayload(payloadArray);
              this.resultsSearch._setOrigin(item.categoryName);

              if (response !== null && response.results !== null) {
                this._setSearchIdInResults(response);
              }
              this._handleResponse(response, 'HOTELS', item.payloadUpdate.products[0], item);
              //this._emitLoading(false);
              this.spinner.hide();
              this.router.navigate(['/results']);
            },
            error => {
              this.spinner.hide();
              //this._emitLoading(false);
              ////this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
            }
          );

          break;

        case "TOURS":

          var payload = item.payloadUpdate.products[0];
          payload.categoriesId = [item.payloadUpdate.products[0].categoryId];
          payload.categoryId = item.payloadUpdate.products[0].categoryId;
          payload.providerId = null;
          payload.origin = "";
          payload.qtyPax = 1;
          delete payload.productId;
          delete payload.nameAdapter;
          delete payload.productName;
          payload.others.minNumResults = 3;

          console.log("this.item payload", payload);

          this._restService.doPost('app', 'search', payload).subscribe(
            (response:any) => {

              console.log("responseInit", response);
              let payloadArray = [item.payloadUpdate.products[0]];
              console.log("payloadArray", payloadArray);

              this.resultsSearch._setPayload(payloadArray);
              this.resultsSearch._setOrigin(item.categoryName);

              if (response !== null && response.results !== null) {
                this._setSearchIdInResults(response);
              }
              this._handleResponse(response, 'TOURS', item.payloadUpdate.products[0], item);
              //this._emitLoading(false);
              this.spinner.hide();
              this.router.navigate(['/results']);
            },
            error => {
              this.spinner.hide();
              //this._emitLoading(false);
              ////this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
            }
          );
          
        break;

        default:
          break;
      }

    } else {
      //Es multidestination
      console.log("Multidestination research");

      var _destinations = [];
      let itinerary = this.userService.itineraryActive;
      if (itinerary[0].destination.length > 0) {

        for (let i = 0; i < itinerary[0].destination.length; i++) {
          const arrival = itinerary[0].destination[i].destination_info.arrival.split('-');
          _destinations.push({
            cityIataCode: itinerary[0].destination[i].iata_code,
            cityName: itinerary[0].destination[i].destination_info.cityName,
            nights: itinerary[0].destination[i].destination_info.nights,
            adults: itinerary[0].destination[i].destination_info.adults,
            childs: itinerary[0].destination[i].destination_info.childs,
            rooms: itinerary[0].destination[i].destination_info.rooms,
            roomsDist: itinerary[0].destination[i].destination_info.roomDist,
            yy: this.datePipe.transform(itinerary[0].destination[i].destination_info.arrival, 'yy'),
            mm: this._getMonthName(arrival[1]),
            dd: arrival[2]
          });
        }
      }

      console.log("_destinations", _destinations);
      let payloadArray = this._setPayloadArray(_destinations, item);
      console.log("payloadArray", payloadArray);
      this.loadMultidestinationResult(payloadArray);
    }
  }

  loadMultidestinationResult(payload) {

    if (payload !== undefined && payload.length > 0) {

      let _results = [];
      this.resultsSearch._setPayload(payload);

      for (let i = 0; i < payload.length; i++) {
        this._restService.doPost('app', 'search', payload[i]).subscribe((response:any) => {

          if (response !== null && response.results !== null) {
            this._setSearchIdInResults(response);
          }

          _results[i] = response.results;
          _results[i].qtyPax = payload[i].qtyPax;
          _results[i].others = payload[i].others;
          _results[i].destination = payload[i].destination;
          _results[i].categoryName = "HOTELS";
          _results[i].payload = payload[i];

          if (!response.results.length) {
            _results[i].noResultMessage = 'No results found for the search';
          }

          if (i === (payload.length - 1)) {

            console.log(_results);
            console.log('--- RESULT ---');

            this.resultsSearch._setResponse(_results);
            this.resultsSearch._setOrigin('MULTIDESTINATION');

            console.log('Los results antes de ir a result content', _results);
            console.log('payload antes de continue', payload);
            //this.spinner.hide();
            this.router.navigate(['/results']);
          }
        }, error => {
          //this.spinner.hide();
          //this._results[i] = [];
          //this._results[i].noResultMessage = 'No results found for the search';
          //this._findData = false;
          //this._itineraryService.sendMessageSubscriptorLoading(false);
          console.log(error);
        });
      }

    }
  }

  _setSearchIdInResults(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }

  _handleResponse(response: any, categoryName: string, payload: any, item) {
    if (response && response.results != null) {
      try {
        let _resultsQuery: any;
        _resultsQuery = response.results;
        _resultsQuery.categoryName = categoryName;
        _resultsQuery.arrivalDate = payload.arrivalDate;
        _resultsQuery.destination = item.item.destination.iata_code;
        if (!response.results.length) {
          _resultsQuery.noResultMessage = 'No results found for the search';
        }
        const arrayResults = [_resultsQuery];
        console.log("arrayResults", arrayResults);
        this.resultsSearch._setResponse(arrayResults);
      } catch (e) {

        console.log("error", e);
      }
    } 
  }

   //multidestinetion add extras
   _getMonthName(month: number) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Octr', 'Nov', 'Dec'];
    if (month !== null) {
      return monthNames[month - 1];
    }
  }

  
  _setPayloadArray(destinations: any, item) {

    let _payloadArray = [];
    let payload = item.item.payloadUpdate.products[0];
    let arrivalDate = this.userService.itineraryActive[0].arrivalDate;

    console.log("payload", payload);
    console.log("arrivalDate", arrivalDate);

    for (let i = 0; i < destinations.length; i++) {
      let arrival = null;
      if (payload !== undefined && payload !== null) {
        if (payload.others !== undefined && payload.others !== null) {
          var qtyAdults = 0;
          payload.others.hotels.roomConfiguration.forEach(room => {
            qtyAdults += parseInt(room.adults);
          });
        }
      }
      if (arrivalDate !== undefined || arrivalDate !== null) {
        arrival = new Date((i === 0) ? arrivalDate : _payloadArray[i - 1].departureDate);
        _payloadArray[i] = {
          arrivalDate: (i === 0) ? arrivalDate : _payloadArray[i - 1].departureDate,
          categoriesId: [1],
          departureDate: this.userService.itineraryActive[0].destination[i].destination_info.departure,
          origin: 'MULTIDESTINATION',
          destination: destinations[i]['cityIataCode'],
          others: payload.others,
          priceFrom: payload.priceFrom,
          priceTo: payload.priceTo,
          providersId: payload.providersId,
          qtyPax: qtyAdults,
          qtyProduct: 1,
        };
      } else {
        ////this.toastr.error('Arrival date must defined in home.', 'Unknown Arrival Date.');
      }
    }

    return _payloadArray;
  }

  reSearch(event){
    console.log("realizo el research con ,", event);
    this.makePayload(event);
  }
}
