<div id="{{ 'contentPDF-selected' }}">
  

  <div class="row">

    <div class="col-md-6 ">
      <div>
        <img src="assets/img/default-logo-agency.png" alt="logo" width="auto" height="58px">
      </div>
    </div>
    <div class="col-md-6">
      <br>
      <div style="margin-left:60px;font-size:14px;color:#032541;">
        <p><b>{{agency.name_agency}}&nbsp;&nbsp;</b></p>
        <p>{{agency.email_user}}&nbsp;&nbsp;</p>
        <p>{{agency.phone}}&nbsp;&nbsp;</p>
      </div>
    </div>
  </div>

  <div style="height:1px;background-color: #032541 !important;width: 90%;">
    <span>&nbsp;&nbsp;</span>
  </div>

  <div class="row">

    <div class="col-md-6 ">
      <p> N° 000{{itinerary.id}}</p>
      <p> {{destinations}}</p>
      <p> {{paxList}} </p>
    </div>
    <div class="col-md-6">
      <p *ngIf="clientData.name"> {{clientData.name}}</p>
      <p *ngIf="clientData.email">{{clientData.email}}</p>
      <p *ngIf="clientData.phone">{{clientData.phone}}</p>
    </div>
  </div>

  <div style="height:1px;background-color: #032541 !important;padding: 20px;width: 90%;">
    <span>&nbsp;&nbsp;</span>
  </div>

  <div class="row mt-4">
    <div class="col-md-8">
      <h5 class="font-weight-bold m-0 text-capitalize" style="font-size: 16px;">{{item.title}}</h5>
      <p class="font-weight-bold m-0" style="font-size: 13px;">{{item.destination.iata_code}}</p>
      <p style="font-size:13px;">
        <span style="color: green"><b>check-in: </b></span> {{item.arrivalDate }} &nbsp;&nbsp;
        <span style="color: red"><b>check-out: </b> </span> {{item.departureDate }}
      </p>
    </div>

    <div class="col-md-4">
      <p class="font-weight-bold">TARIFA NETA</p>
      <p class="font-weight-bold">$ {{item.totalPrice | number:'1.2-2'}}</p>
    </div>
  </div>

  <div id="{{'items-selected-' +itinerary.id }}"></div>

</div>