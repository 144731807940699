import {Pipe, PipeTransform} from '@angular/core';
import { Title } from '@angular/platform-browser';

@Pipe({
  name: 'titleFilter'
})
export class TitleFilterPipe implements PipeTransform {
  transform(array: any, text: string): any {
    const _array = [];
    if (array && text) {
        const regexp = new RegExp(text, 'i');
        array.forEach(product => {
            if (product.title && regexp.test(product['title'])) {
                _array.push(product);
            }
          });
        return _array;
    }else {
        return array;
    }
  }
}
