import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItineraryService } from '../../../itinerary.service';
import { RestService } from '../../../rest.service';

@Component({
  selector: 'usb-transaction-master-voucher',
  templateUrl: './transaction-master-voucher.component.html',
  styleUrls: ['./transaction-master-voucher.component.scss']
})
export class TransactionMasterVoucherComponent implements OnInit {

  @Input() itinerary: any;
  @Input() item: any;
  @Input() totalPrice: any;
  @Input() i: any;

  clientData: any = {
    name: null,
    mail: null,
    phone: null
  };

  flightInfo: any = {
    thisdepartureDate: null,
    arrivalDate: null,
    airport: null,
    airline: null,
    flightNumber: null
  };

  paxList: any;
  destinations: any;

  agency: any;

  constructor(
    public restService: RestService,
    private itineraryService: ItineraryService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log("item voucher", this.item);
    console.log("i voucher", this.i);
    
    this.agency = JSON.parse(localStorage.getItem('selectedAgency'));
    //console.log("agency ", this.agency);

    this.clientData = this.itineraryService.getClientData(this.itinerary); // Obtener los datos del cliente

    //this.flightInfo = this._initFlights(this.itinerary); // obtener los datos del vuelo
    this.paxList = this.itineraryService.getPaxList(this.itinerary);
    this.destinations = this.itineraryService.destinationsPerItinerary(this.itinerary);
  }

}
