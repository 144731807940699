
<!-- <div *ngIf="_loading" class="pt-5">
  <div align="center" class="load-setting">
    <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
  </div>
</div> -->
<div
></div>
  <div id="bg-resumen" *ngIf="!_loading">
    <div class="top-summary">
      <div class="container-fluid pr-5 pl-5">
        <div class="steps">
          <usb-steps-reservation [step]="step" [permitedConfiguration]="permitedConfiguration">
          </usb-steps-reservation>
        </div>
        <div class="resumen" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
        <div class="row ml-0 mr-0">
          <div class="d-flex pl-3 align-items-center"
            style="width: 253px !important; height: 45px; border-radius: 4px;border: 1px solid #ffffff">
            <span class="ml-4" style="font-size: 18px; font-weight: 600; color: #ffffff"> PASAJEROS</span>
          </div>
          <div class="d-flex flex-column pl-3" style="color: #ffffff; height: 45px;">
            <p class="m-0 title-itinerary">{{itinerary.title}}</p>
            <p class="m-0" style="font-size: 13px; color: #ffffff; font-weight: 400; font-family:'Poppins';line-height: 1;">
              In: {{ userService.itineraryArrivalDateFull  | date:'dd MMM yyyy'}}
              Out: {{ userService.itineraryDepartureDateFull  | date:'dd MMM yyyy'}}
              <span style=" font-weight: bold;" *ngIf="existHotel()"
              [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"> Estadia: {{userService.itineraryQtyDays}} Días </span>
            </p>
          </div>
        </div>
        <div class="row mt-4 ml-0 mr-0"  style="border-radius: 5px; background-color: #ffffff !important;font-size: 10pt;color: #000000;">
          <div class="col-12 p-2" style="border: 1px solid #e0e0e0; border-radius: 5px;">
              <div class="px-4 pt-2">
                  <ul class="nav">
                      <li class="nav-item">
                          <ul class="nav">
                              <li class="nav-item">
                                  <img src="/assets/img/app/little-icons/user.svg" class="icon-adult-people" alt="">
                              </li> <!-- adults-->
                              <li class="nav-item"><span class="font-poppins f-12 mr-3">&nbsp;{{ itinerary.totalAdultsMax }} Adultos</span></li>
                          </ul>
                      </li>
                      <li class="nav-item">
                          <ul class="nav">
                              <li class="nav-item">
                                  <img src="/assets/img/app/little-icons/child.svg" class="icon-childrens" alt="">
                              </li> <!-- children -->
                              <li class="nav-item"><span class="font-poppins f-12 mr-3">&nbsp;{{ itinerary.totalChildrenMax }} Niños</span></li>
                          </ul>
                      </li>
                      <li class="nav-item" *ngIf="existHotel()">
                          <ul class="nav">
                              <li class="nav-item" style="margin-top: -5px;">
                                  <!-- <img src="/assets/img/app/little-icons/mediluna.svg" class="icon-nights" alt=""> -->
                                  <span class="icon i-bed mr-2"></span>
                              </li> <!-- nigths-->
                              <li class="nav-item"><span class="font-poppins f-12 mr-3">{{ userService.itineraryQtyNights }} Noches</span></li>
                          </ul>
                      </li>
                      <li class="nav-item" *ngIf="userService.verifyCategory('TOURS')">
                          <ul class="nav">
                              <li class="nav-item">
                                  <span class="icon i-ticket"></span>
                              </li>
                              <li class="nav-item">
                                  <span class="font-poppins f-12 mr-3">&nbsp;&nbsp;{{userService.verifyQuantityCategory('TOURS')}} Tickets Tours</span>
                              </li>
                          </ul>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 pr-5 pl-5" style="background-color: #f0edf4; padding-bottom: 50px;" *ngIf="_loading==false">
    <div class="resumen-summary-content justify-content-center content-reservation pt-3">
      <div class="row justify-content-between">
        <div class="count-down" style="position: absolute;right: 100px;" *ngIf="this.userService.itineraryActive">
          <countdown [config]="config" (event)="onFinished($event)"> $!m!:$!s! </countdown>
          <!-- (finished)="onFinished()" -->
          <span class="minutes-summary">MINUTOS SEGUNDOS</span>
        </div>
        <div class="col-12" *ngIf="this.userService.stepReservation=='two' && this.userService.stepReservation!='three'">
          <usb-form-paxes-reservation [paxes]="paxes" [step]="this.userService.stepReservation" [formSended]="formSended"
            [clientOwner]="itinerary.clients" [itineraryId]="this.itinerary.id" [itineraryItems]="itineraryItems"
            [itineraryTotal]="totalPrice" [permitedConfiguration]="permitedConfiguration" [itinerary]="itinerary" [from]="from"
            [clientSelecFormPaxes]="clientSelecFormPaxes" (clientSelectedArr)="clientSelectedArr($event)"
           >
          </usb-form-paxes-reservation>
        </div>
        <div class="col-12" *ngIf="this.userService.stepReservation=='three'">  <!--  [paxes]="itinerary.paxList ? itinerary.paxList : userService.itineraryActive[0].paxlist"-->
          <usb-body-reservation [partialReserve]="partialReserve" (paxesForItems)="onChange($event)" [itineraryId]="this.itinerary.id"
            [itineraryItems]="itineraryItems" [paxes]="itinerary.paxList"
            [itineraryTotal]="totalPrice" [itinerary]="itinerary" [from]="from"
            [clientOwner]="itinerary.clients">
          </usb-body-reservation>
        </div>
        <div class="col-12" *ngIf="this.userService.stepReservation=='confirmation'">
          <usb-confirm-reservation>
          </usb-confirm-reservation>
        </div>
      </div>
    </div>
  </div>
  <br><br>
</div>



