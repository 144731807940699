<div class="d-flex justify-content-center align-items-end" style="margin-top: 30px;">
  <svg-icon class="d-flex icon-step icon-step-3 justify-content-center align-items-center ml-3 mr-3"
    [ngClass]="{'step-active': currentStep >= 1}"
    [ngStyle]="{'fill': currentStep >= 1 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white' }"
    src="../../../assets/img/app/icons-reserve-step/products_gris.svg">
  </svg-icon>
  <i class="bi bi-chevron-right icon-separator"
    [ngClass]="{'step-active': currentStep >= 1}"
    [ngStyle]="{'color': currentStep >= 1 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'rgba(130, 130, 130, 0)' ,
    'stroke': currentStep >= 1 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'rgba(130, 130, 130, 0.147)',
    'stroke-width': '6px',
    '-webkit-text-stroke-color': currentStep >= 1 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'rgba(130, 130, 130, 0.147)',
    '-webkit-text-stroke-width': '6px' }">
  </i>
  <svg-icon class="d-flex icon-step icon-step2 justify-content-center align-items-center ml-3 mr-3"
    [ngClass]="{'step-active': currentStep >= 2}"
    [ngStyle]="{'fill': currentStep >= 2 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white' }"
    src="../../../assets/img/app/icons-reserve-step/passanger_gris.svg">
  </svg-icon>
  <i class="bi bi-chevron-right icon-separator"
    [ngClass]="{'step-active': currentStep >= 2}"
    [ngStyle]="{'color': currentStep >= 2 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'rgba(130, 130, 130, 0)' ,
    'stroke': currentStep >= 2 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'rgba(130, 130, 130, 0.147)',
    'stroke-width': '6px',
    '-webkit-text-stroke-color': currentStep >= 2 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'rgba(130, 130, 130, 0.147)',
    '-webkit-text-stroke-width': '6px' }">
  </i>
  <svg-icon class="d-flex icon-step icon-4 justify-content-center align-items-center ml-3 mr-3"
    [ngClass]="{'step-active': currentStep >= 3}"
    [ngStyle]="{'fill': currentStep >= 3 ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white' }"
    src="../../../assets/img/app/icons-reserve-step/confirm_gris.svg">
  </svg-icon>
</div>

  <!-- <svg-icon src="../../../assets/img/app/icons-reserve-step/itineraty_gris.svg"
  class="icon-step icon-step-1 d-flex justify-content-center align-items-center ml-3 mr-3" [ngStyle]="{'fill':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"></svg-icon> -->
