import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
// import { ManagementItineraryComponent } from './management-itinerary.component';
import { ItineraryDetailComponent } from '../itinerary-detail/itinerary-detail.component';

const routes: Routes =[
    // { path: 'management', component: ManagementItineraryComponent, data: { title: extract('Itinerary') } },
    { path: 'itinerary-detail', component: ItineraryDetailComponent, data: { title: extract('Itinerary Detail') } }
];
const routeDetail: Routes =[
    { path: 'itinerary-detail', component: ItineraryDetailComponent, data: { title: extract('Itinerary Detail') } }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes),
        RouterModule.forRoot(routeDetail, { relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule],
    providers: []
})
export class ManagementItineraryRoutingModule { }
