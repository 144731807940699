
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {map, takeUntil, tap} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Location } from '../interface/locations.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { setting } from '../../../../../setting';

import {User, IUserResponse} from '../../search-box/categories/hotels/user.class';
import { of, Subject } from 'rxjs';

@Injectable()
export class UbicacionesService {

  private endpoint: string;
  private actives: string;
  private list: string;
  private countrys: string;
  private citys: string;
  private airports: string;
  private airlines: string;
  private locations_airports: string;
  private searchlocations: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private url = setting.apiOctopusUrl;

  constructor(private http: HttpClient) {
    this.endpoint = this.url + environment.utilities + '/locations';
    this.list = this.url + environment.utilities + '/activelocations';
    this.actives = this.url + environment.utilities + '/locations';
    this.countrys = this.url + environment.utilities +  '/countrylocations/';
    this.citys = this.url + environment.utilities + '/searchlocations/';
    this.searchlocations = this.url + environment.utilities + '/searchlocations/';
   // this.airports = this.url + '/AirportList/';
    this.airports = this.url + environment.utilities + '/searchlocations/';

    this.airlines = this.url + environment.utilities + '/AirlineList/';
    this.locations_airports = this.url + environment.utilities + '/locations_airports/';
  }

  getCountrys(keyword): Observable<any> {
    if (keyword.length >= 3) {
      return this.http.get(this.countrys + keyword);
    } else {
      return Observable.of([]);
    }
  }

  getCitys(keyword): Observable<any[]> {
    if (keyword.length >= 3) {
      return this.http
        .get(this.citys  + keyword )
        .pipe(takeUntil(this._unsubscribeAll))
        .map((res:any) => {
          let json = res.cities;
          // console.log("json", json);
          return json;
        })
    } else {
      return of([]);
    }
  }


  getDestinations(keyword): Observable<any[]> {
    if (keyword.length >= 3) {
      //this.citys
      return this.http.get(this.citys  + keyword )
        .map((res:any) => {
          let json = res;
          return json;
        })
    } else {
      return of([]);
    }
  }



  getAirports(keyword): Observable<any[]> {
    if (keyword.length >= 3) {
      return this.http.get(this.searchlocations + keyword)
      .map((res:any) => {
        let json = res.airports;
        // console.log ("Airports", this.searchlocations + keyword);
        // console.log ("Airports json", json);
        return json;
      })
    } else {
      return of([]);
    }
  }

  getAirlines(keyword): Observable<any[]> {
    if (keyword.length >= 3) {

      return this.http.get(this.searchlocations + keyword, )
        .map((res:any) => {
          let json = res.airlines;
          return json;
        })
    } else {
      return Observable.of([]);
    }
  }

  getAll(): Observable<Location[]> {
    return this.http
      .get(this.endpoint, )
      .map((response:any) => response.data as Location[])
      .catch(this.handleError);
  }

  getAllActives(): Observable<Location[]> {
    return this.http
      .get(this.list, )
      .map((response:any) => response.data as Location[])
      .catch(this.handleError);
  }


  get(id: number): Observable<Location> {
    const url = `${this.endpoint}/${id}`;
    return this.http
      .get(url, )
      .map((response:any) => response.data as Location)
      .catch(this.handleError);
  }

  crear(param: any): Observable<any> {
    const body = JSON.stringify(param);
    return this.http
      .post(this.endpoint, body, )
      .map((response:any) => response)
      .catch(this.handleError);
  }

  actualizar(param: any): Observable<any> {
    const body = JSON.stringify(param);
    const url = `${this.endpoint}/${param.id}`;
    return this.http
      .put(url, body, )
      .map((response:any) => response)
      .catch(this.handleError);
  }


  borrar(id: number): Observable<any> {
    const url = `${this.endpoint}/${id}`;
    return this.http
      .delete(url, )
      .map((response:any) => response)
      .catch(this.handleError);
  }

  private get _headers() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('client-id', environment.client_id);
    headers.append('client-secret', environment.client_secret);
    if (localStorage.getItem('bearer')) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('bearer'));
    }
    return headers;
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body:any = error || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

}
