<div class="container-fluid" style="position: relative; ">
  <div class="d-flex flex-row justify-content-xl-between header-pay">
    <p class="font-poppins">PROCESO DE RESERVA</p>
    <button type="button" class="close btn-close" title="Close" (click)="closeTop()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- step by step numeros -->
  <div class="row py-3 container-step">
    <div class="line-step"></div>
    <div class="col-3 d-flex justify-content-center">
      <div class="circle font-poppins d-flex justify-content-center align-items-center " [ngClass]="{'circle-step': step == 1}">
        01
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center">
      <div class="circle font-poppins d-flex justify-content-center align-items-center" [ngClass]="{'circle-step': step == 2}">
        02
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center">
      <div class="circle font-poppins d-flex justify-content-center align-items-center" [ngClass]="{'circle-step': step == 3}">
        03
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center">
      <div class="circle font-poppins d-flex justify-content-center align-items-center" [ngClass]="{'circle-step': step == 4}">
        04
      </div>
    </div>
  </div>

  <div class="row justify-content-between mt-4 mt-2">
    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 1}">
      BUSQUEDA
    </div>

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 2}">
      ROOMS
    </div>

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 3}">
      PASSANGER
    </div>

    <div class="square font-poppins d-flex justify-content-center align-items-center font-weight-bold font-poppins"
      [ngClass]="{'square-step': step == 4}">
      RESERVAR
    </div>
  </div>

  <div class="row px-0">
    <ng-container *ngIf="step == 1">
      <usb-pax-list-modal-booking (nextTab)="emitNext($event)" [item]="item" (results)="emitResults($event)" class="card-body">
      </usb-pax-list-modal-booking>

      <!--   <usb-confirm-booking style="width: 100%;">
      </usb-confirm-booking> -->

    </ng-container>

    <ng-container *ngIf="step == 2">
      <usb-rooms-select class="card-body" [item]="results[0].item" [hotel]="results[0].product" [productQty]="results[0].item.qty"
        (roomSelect)="roomSelect_($event)"  (hotelSelect)="hotelSelect_($event)">
      </usb-rooms-select>
    </ng-container>
    <!--  [paxes]="paxes" -->
    <ng-container *ngIf="step == 3">
      <div *ngIf="this.userService.stepReservation=='two'"> 
        <usb-form-paxes-booking class="card-body" [clientOwner]="itinerary.clients" [itineraryId]="itinerary.id"
          [itineraryItems]="itineraryItems" [permitedConfiguration]="true" [itinerary]="itinerary" (steps)="emitStep($event)">
        </usb-form-paxes-booking>
      </div>
      <div *ngIf="this.userService.stepReservation=='three'"> <!--  [from]="from"-->
        <usb-body-booking (paxesForItems)="emitPaxes($event)" [itineraryId]="itinerary.id" [roomSelect]="roomSelect" [hotel] ="hotelSelect" 
          [paxes]="itinerary.paxList" [itinerary]="itinerary"  (steps)="emitStep($event)">
        </usb-body-booking>
      </div>
    </ng-container>

    <ng-container *ngIf="step == 4">
      <usb-confirm-booking style="width: 100%;" [item]="item" [roomSelect]="roomSelect" [paxesInItems] ="paxesInItems"> 
      </usb-confirm-booking>
    </ng-container>
  </div>
  <div class="row d-flex justify-content-between mt-4 bt-4" style="margin-top: 100px !important;">
    <div class="d-flex ml-auto justify-content-end px-0">
      <button class="btn btn-action btn-back ml-2" (click)="goBack()" *ngIf="step == 2 || step == 4">
        BACK
      </button>
      <div class="d-flex justify-content-end">
        <button class="btn btn-action btn-continue ml-2" (click)="next()" *ngIf="step === 2">
          CONTINUE
        </button>
        <button class="btn btn-action btn-continue ml-2" (click)="goReservation()" *ngIf="step === 4">
          RESERVE
        </button>
      </div>
    </div>
  </div>
</div>
