
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { MatSelectChange } from "@angular/material";
import { RestService } from "app/shared/rest.service";
import { constants } from "os";
import { OwlOptions } from 'ngx-owl-carousel-o';
//import { ToastsManager } from 'ng2-toastr';

@Component({
  selector: "usb-room",
  templateUrl: "./room.component.html",
  styleUrls: ["./room.component.scss"],
})
export class RoomComponent implements OnInit {
  @ViewChild('selectedRoomsDiv') selectedRoomsDiv!: ElementRef;
  @ViewChild('containingDiv') containingDiv!: ElementRef;
  @ViewChild('contentBorderSelect') contentBorderSelect: ElementRef;
  @Input() room: any;
  @Input() room_: any;
  @Input() allRoom: any;
  @Input() select: any[];
  @Input() index: number;
  @Input() productQty: number;
  @Input() reserveMultiHab: boolean;
  @Input() hotelAdditionalInfo: any;
  @Input() roomsSearch: any[] = [];
  @Input() departureDate:any;
  @Input() hotelDescription: string;
  @Output() totalProfRooms = new EventEmitter<number>();
  @Output() calculateTotalPrice = new EventEmitter<any>();
  @Output() calculateTotalPriceWithout = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() indexRoom = new EventEmitter<number>();
  qtySelect = 0;

  roomsIndex = 0;
  @Input() images: any[];

  step = null;
  imgSelect = 0;
  uniqBoardName = {};
  uniqRateClass = {};
  imgCoverRoom:string;
  dataRoom:any;
  imgRoomsSlider:any = [];

  uniq_ = {};
  roomSelect: any;
  checkSelect: any;
  roomBoardName: any;
  roomRateClass: any;
  amenitiesHotelRooms = [];
  roomAmenits:any = [];

  nightsTotalProf: number = 0;
  roomsTotalProf: number = 0;

  totalAdults: number = 0;
  totalChildren: number = 0;

  public dailyPricesArr: any[] = [];

  last1 = 50;
  last2 = -50;
  num1 = 0;
  num2 = 0;
  regimeArr = [];
  conditionArr = [];
  setFirstValueReg: string = "";
  availableRoom:boolean = false;
  amenitiesRoom:boolean = false;
  public _form: any;
  public selectedRooms: any[] = [];
  availableRooms = [];
  roomToDelete: any = null;
  boardNameSelect: any;
  form: any;
  imgRoomCover:any;

  customOptionsDailyPrices: OwlOptions = {
    autoHeight: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<img src="../../../../../assets/img/app/arrow-izq-slider.png" />', '<img src="../../../../../assets/img/app/arrow-der-slider.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      },
      1140: {
        items: 1
      },
      1340: {
        items: 1
      },
      1540: {
        items: 1
      },
      1740: {
        items: 1
      },
      1940: {
        items: 1
      }
    },
    nav: true
    }

  customOptions: OwlOptions = {
    autoHeight: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<img src="../../../../../assets/img/app/arrow-izq-slider.png" />', '<img src="../../../../../assets/img/app/arrow-der-slider.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      },
      1140: {
        items: 1
      },
      1340: {
        items: 1
      },
      1540: {
        items: 1
      },
      1740: {
        items: 1
      },
      1940: {
        items: 1
      }
    },
    nav: true
    }

  constructor(private restService: RestService) {}

  ngOnInit() {
    // console.log(this.selectedRooms)
    // console.log(this.hotelDescription)
    this.resetSelect();
    // console.log('hotel en general ->', this.hotelAdditionalInfo);
    // console.log('departuredate ->', this.departureDate);

    this.roomSelect = this.roomBoardName[this.roomsIndex];
    console.log('roomSelect ->', this.roomSelect);

    this.imgRoomCover = this.roomSelect?.room.images.filter(e => e.isCover);

    this.multiplyTotalProf();

    this.form = JSON.parse(localStorage.getItem("form"));
    // console.log('roomSelect form ->', this.form);

    this.dataRoom = this.hotelAdditionalInfo;
    // console.log('roomdata ->', this.dataRoom);
    this.getMoreAmenities();
    // this.dailyPrice();

    this.roomSelect.room?.pricing?.dailyPrices.forEach(room => {
          const roomDate = new Date(room.date + "T00:00:00"); // Ajustar la hora a las 00:00 horas
          const dayOfWeek = this.getDayOfWeek(roomDate.getDay()); // Obtener el día de la semana en español

          this.dailyPricesArr.push({
            dayWeek: dayOfWeek,
            date: room.date,
            price: room.price,
            currency: room.currency
          });
        });


  }


  onSelectionChange(index: any, room: any) {
    const selectedRoomsHeight = this.selectedRoomsDiv.nativeElement.getBoundingClientRect().height;
    this.containingDiv.nativeElement.style.height = `${selectedRoomsHeight}px`;

    const selectedIndex = parseInt(index.target.value, 10);


      // Si la habitación no está seleccionada, la agregamos a la lista
      const selectedRoom = this.roomsSearch[selectedIndex];
      this.selectedRooms.push({ room: selectedRoom, service: this.roomSelect.room});
      // Encuentra el índice de selectedRoom en formRoom
      const formRoom = this.form.room;
      // En la primera carga de resultados el formRoom esta guardado como un array por más que sea un solo objeto
      if(formRoom.length && Array.isArray(formRoom)) {
        // console.log("onSelectionChange form",  this.form);
        const formRoomIndex = formRoom.findIndex((item) => {
          return (
            item.qtyAdults === selectedRoom.qtyAdults &&
            item.qtyChilds === selectedRoom.qtyChilds &&
            JSON.stringify(item.childsage) === JSON.stringify(selectedRoom.childsage)
          );
        });
        const checkAll = this.selectedRooms.length > 0;

        if (checkAll) {

            // Eliminamos la habitación seleccionada de la lista de opciones disponibles
          this.roomsSearch.splice(selectedIndex, 1);
          // Accede al elemento DOM y aplica un estilo
          this.contentBorderSelect.nativeElement.style.border = "3px solid #00d096";
          this.calculateTotalPrice.emit(room);
          this.indexRoom.emit(formRoomIndex);
          // console.log('entre al if');
          this.selectionChange.emit(this.roomsSearch);
        } else {
          this.calculateTotalPrice.emit(0);
        }
        // Desde el + del fee esto viene como un objeto, por eso hay que transformarlo en un array
      } else {
        const formRoomArr: any[] = [];
        formRoomArr.push(formRoom);
        const formRoomIndex = formRoomArr.findIndex((item) => {
          return (
            item.qtyAdults === selectedRoom.qtyAdults &&
            item.qtyChilds === selectedRoom.qtyChilds &&
            JSON.stringify(item.childsage) === JSON.stringify(selectedRoom.childsage)
          );
        });
        const checkAll = this.selectedRooms.length > 0;

        if (checkAll) {

            // Eliminamos la habitación seleccionada de la lista de opciones disponibles
          this.roomsSearch.splice(selectedIndex, 1);
          // Accede al elemento DOM y aplica un estilo
          this.contentBorderSelect.nativeElement.style.border = "3px solid #00d096";
          this.calculateTotalPrice.emit(room);
          this.indexRoom.emit(formRoomIndex);
          // console.log('entre al if');
          this.selectionChange.emit(this.roomsSearch);
        } else {
          this.calculateTotalPrice.emit(0);
        }
      }



  }

  offSelectionRooms(roomSelect: any, selectedIndex: any) {
    const selectedRoomsHeight = this.selectedRoomsDiv.nativeElement.getBoundingClientRect().height;
    this.containingDiv.nativeElement.style.height = `${selectedRoomsHeight}px`;
    // Verifica si la habitación ya está seleccionada
    const roomEmit = {
      roomSelect: roomSelect,
      selectedIndex: selectedIndex
    }
    this.calculateTotalPriceWithout.emit(roomEmit);
    const removedRoom = this.selectedRooms.splice(selectedIndex, 1)[0]; // Elimina y obtiene el objeto eliminado

    if (this.selectedRooms.length === 0) {
      this.contentBorderSelect.nativeElement.style.border = "1px solid #00000020";
    }

    // Agrega nuevamente la habitación eliminada a roomsSearch
    this.roomsSearch.push(removedRoom.room);

  }

  plus() {
    if (this.num1 === this.last1) {
      // console.log("disable");
    } else {
      this.num1++;
      // console.log("Value of num1 after increment ", this.num1);
    }
  }

  minus() {
    if (this.num2 === this.last2) {
      // console.log("disable");
    } else {
      this.num2--;
      // console.log("Value of num1 after decr ", this.num2);
    }
  }




  getNameRateClass(rateClass) {
      var rateClass_;
      switch (rateClass) {
        case "NR": {
          rateClass_ ="No Reembolsable";
          break;
        }
        case "R": {
          rateClass_ ="Reembolsable";
          break;
        }
    }
    return rateClass_;
  }

  resetSelect() {
    this.roomBoardName = this.room;
    //this.roomRateClass = this.room;
    //this.uniqBoardName = {};
    //this.uniqRateClass = {};

   /* this.roomBoardName = this.roomBoardName.filter(
      (obj) => !this.uniqBoardName[obj.boardName] && (this.uniqBoardName[obj.boardName] = true)
    ); */

   /* this.roomRateClass = this.roomRateClass.filter(
      (obj) => !this.uniqRateClass[obj.rateClass] && (this.uniqRateClass[obj.rateClass] = true)
    ); */
  }


  //regimen.
  onChangeBoardName(roomsIndex: any, select: any, index: any) {
    // console.log(roomsIndex)
    // console.log(select)
    const boardName = select.target.options[select.target.options.selectedIndex].text;
    this.boardNameSelect = boardName;
    this.setFirstValueReg = boardName;
    let roomsSearch = this.room;
    const roomsSearchAux = [];
    const roomsSearchAux_ = [];
    let i = 0;
    const uniq = {};
    if (roomsIndex == "-1") {
    } else {
      this.roomSelect = this.roomBoardName[roomsIndex];
      this.dailyPriceSelect(this.roomSelect);
      // asignar valores a la condicion
      roomsSearch = roomsSearch.filter(function (entry: any) {
        return entry.boardName === boardName;
      });

   /*   roomsSearch = roomsSearch.reduce(function (r, a) {
        r[a.boardName] = r[a.boardName] || [];
        r[a.boardName].push(a);
        return r;
      }, Object.create(null));

      Object.keys(roomsSearch).map((key) => {
        roomsSearchAux[i] = roomsSearch[key];
        i++;
      });
      let j = 0;
      roomsSearchAux.forEach((element) => {
        roomsSearchAux_[j] = element.filter((obj) => !uniq[obj.boardName] && (uniq[obj.boardName] = true))[0];
        j++;
      });
      this.roomBoardName = roomsSearchAux_;*/
      this.resetSelect();
    }
    let j;
    j = 0;

    this.allRoom.forEach((element) => {
      element.forEach((element_) => {
        if (document.getElementById(element_.roomId) as HTMLInputElement) {
          (document.getElementById(element_.roomId) as HTMLInputElement).checked = false;
          (document.getElementById(element_.roomId) as HTMLInputElement).disabled = false;
        }
      });
      if (document.getElementById(j) as HTMLInputElement) {
        (document.getElementById(j) as HTMLInputElement).style.border = "1px solid #00000020";
      }
      j++;
    });
  }

  onChangeRateClass(roomsIndex: any, select: any, index: any) {

   /* const rateClass = select.target.options[select.target.options.selectedIndex].text;
    this.setFirstValueReg = rateClass;
    let roomsSearch = this.room;
    const roomsSearchAux = [];
    const roomsSearchAux_ = [];
    let i = 0;
    const uniq = {};
    if (roomsIndex == "-1") {
    } else {
      this.roomSelect = this.roomRateClass[roomsIndex];

      // asignar valores a la condicion
      roomsSearch = roomsSearch.filter(function (entry: any) {
        return entry.rateClass === rateClass;
      });

      roomsSearch = roomsSearch.reduce(function (r, a) {
        r[a.rateClass] = r[a.rateClass] || [];
        r[a.rateClass].push(a);
        return r;
      }, Object.create(null));

      Object.keys(roomsSearch).map((key) => {
        roomsSearchAux[i] = roomsSearch[key];
        i++;
      });
      let j = 0;
      roomsSearchAux.forEach((element) => {
        roomsSearchAux_[j] = element.filter((obj) => !uniq[obj.rateClass] && (uniq[obj.rateClass] = true))[0];
        j++;
      });
      this.roomRateClass = roomsSearchAux_;
      this.resetSelect();
    }
    let j;
    j = 0;
    this.allRoom.forEach((element) => {
      element.forEach((element_) => {
        if (document.getElementById(element_.roomId) as HTMLInputElement) {
          (document.getElementById(element_.roomId) as HTMLInputElement).checked = false;
          (document.getElementById(element_.roomId) as HTMLInputElement).disabled = false;
        }
      });
      if (document.getElementById(j) as HTMLInputElement) {
        (document.getElementById(j) as HTMLInputElement).style.border = "1px solid #00000020";
      }
      j++;
    });
    this.calculateTotalPrice.emit(0);

*/
  }

  setStep(index: number) {
  //   this.step = index;
  // }
  // /**
  //  * La funcion se encarga de evaluar la cantidad que se pide.
  //  * Se le manda 1 para que los demas select aparezcan con 1 elemento que sera el 0.
  //  * @param select Recibe el elemento colocado por el usuario al seleccionar la cantidad.
  //  */
  // public selectRoom(room: any, index: any) {
  //   console.log(room)
  //   console.log(index)
  //   //select: any, qty: any)
  //   const checkCurrect = (document.getElementById(room.roomId) as HTMLInputElement).checked;
  //   let checkAll = false;
  //   if (checkCurrect === true) {
  //     checkAll = true;
  //     // tslint:disable-next-line:max-line-length
  //     (document.getElementById(index) as HTMLInputElement).style.border = "3px solid #00d096";
  //     this.calculateTotalPrice.emit(room);
  //   } else {
  //     this.calculateTotalPrice.emit(0);
  //     (document.getElementById(room.roomId) as HTMLInputElement).disabled = false;
  //     checkAll = false;
  //     (document.getElementById(index) as HTMLInputElement).style.border = "1px solid #00000020";
  //   }
  //   this.allRoom.forEach((element) => {
  //     // tslint:disable-next-line:no-unused-expression
  //     element.forEach((element_) => {
  //       if (element_.roomId != room.roomId) {
  //         if (document.getElementById(element_.roomId) as HTMLInputElement) {
  //           // tslint:disable-next-line:max-line-length
  //           (document.getElementById(element_.roomId) as HTMLInputElement).disabled = checkAll;
  //         }
  //       }
  //     });
  //   });
  //   // console.log("(room.roomId", room.roomId);
  //   // console.log("(room seleccionada", room);
  }

  private addRoomToSelect(select: any) {
    this.select = select;
  }

  private productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach((obj) => (total += +obj.selectValue));
    return total;
  }

  stingUpperCase(str) {
    let e = str.toLowerCase();
    return e.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  prev() {
    if (this.imgSelect > 0) {
      this.imgSelect = this.imgSelect - 1;
    }
    if (this.imgSelect <= 5) {
      this.scroll("l");
    }
  }

  next() {
    if (this.imgSelect < this.images.length - 1) {
      this.imgSelect++;
    }
    if (this.imgSelect >= 5) {
      this.scroll("r");
    }
  }
  scroll(dir) {
    let idc = "slide-miniatura";
    let content = document.getElementById(idc);
    const stepr = 150;
    const stepl = -150;
    if (dir == "l") {
      content.scrollLeft += stepl;
    } else if (dir == "r") {
      content.scrollLeft += stepr;
    }
  }

  multiplyTotalProf(): void {
    const totalProfNight = this.roomSelect.optionNightsNetTotalProf;
    const productTotal = this.productQty;
    this.roomsTotalProf = totalProfNight * productTotal;
  }

  getMoreAmenities(){
    let newArray = []
    if(this.dataRoom) {
        newArray = this.dataRoom?.amenities;
      this.roomAmenits = newArray.slice(0,7);
    }
  }


  // dailyPrice() {
  //   this.roomSelect.dailyPrices.forEach(room => {
  //     const roomDate = new Date(room.date + "T00:00:00"); // Ajustar la hora a las 00:00 horas
  //     const dayOfWeek = this.getDayOfWeek(roomDate.getDay()); // Obtener el día de la semana en español

  //     this.dailyPricesArr.push({
  //       dayWeek: dayOfWeek,
  //       date: room.date,
  //       price: room.price
  //     });
  //   });
  // }


  dailyPriceSelect(roomSelect:any){
    console.log('roomselect ->', roomSelect);

    this.dailyPricesArr = [];

    roomSelect?.room?.dailyPrices.forEach((dailyPrice:any) => {
      // console.log('dailyPrice ->', dailyPrice);
      const roomDate = new Date(dailyPrice.date + "T00:00:00"); // Ajustar la hora a las 00:00 horas
      const dayOfWeek = this.getDayOfWeek(roomDate.getDay()); // Obtener el día de la semana en español

      this.dailyPricesArr.push({
        dayWeek: dayOfWeek,
        date: dailyPrice.date,
        price: dailyPrice.price
      })

    });
  }



  // Función para obtener el día de la semana en español
getDayOfWeek(day: number): string {
  const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  return daysOfWeek[day];
}

openPolitics(){

  if(this.step == null){
    this.step = 1;
  }else{
    this.step = null;
  }

}


}
