import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { RestService } from '../../shared/rest.service';
import { UsblickCommonEnum } from '../../pages/com.usblick.common/usblick-common-enum';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {setting} from '../../../../setting';

@Injectable()
export class ApiService {

	private url = setting.apiBookingUrl;

	constructor(private http: HttpClient, private _restService:RestService) {
		//
	}

	// Generic Get Request
	doGet(endpoint: string): Observable<any> {

		console.log("DO GET ", this.url + endpoint);

		return this.http
			.get(this.url + endpoint, { headers: this._headers })
			.map((response:any) => response.data);
			//.catch(this.handleError);
	}

	// Generic Get Request
	doGetReservation(endpoint: string): Observable<any> {

		console.log(this.url + endpoint);

		return this.http
			.get(this.url + endpoint, { headers: this._headers })
			.map((response:any) => response);
			//.catch(this.handleError);
	}

	// Generic Post Request
	doPost(endpoint: string, param: any): Observable<any> {
		const body = JSON.stringify(param);
		return this.http
			.post(this.url + endpoint, body, { headers: this._headers });
			//.map((response:any) => response)
			//.catch(this.handleError);
	}

	// Generic Put Request
	doPut(endpoint: string, param: any): Observable<any> {
		const body = JSON.stringify(param);
		return this.http
			.put(this.url + endpoint, body, { headers: this._headers })
			//.map((response:any) => response)
			//.catch(this.handleError);
	}

	// Generic Delete Request
	doDelete(endpoint: string): Observable<any> {
		return this.http
			.delete(this.url + endpoint, { headers: this._headers })
			.map((response:any) => response);
			//.catch(this.handleError);
	}

	// Set Header options
	get _headers() {
		const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
		headers.append('client-id', environment.client_id);
		headers.append('client-secret', environment.client_secret);
		headers.append('Authorization', 'Bearer ' + this.getCookie('auth_token'));
		//headers.append('Authorization', 'Bearer ' +  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgzNDEyMDg0OTU4NmUwZDEzOTMwZjMxODUxYjVmMDU3ZDdlYjZjNmEyNzJmYTdiZDVmNmU2OGI5YjA4MjQ2YmE2OTBlZjQzNGM3ZmZjNGQ0In0.eyJhdWQiOiIxNyIsImp0aSI6IjgzNDEyMDg0OTU4NmUwZDEzOTMwZjMxODUxYjVmMDU3ZDdlYjZjNmEyNzJmYTdiZDVmNmU2OGI5YjA4MjQ2YmE2OTBlZjQzNGM3ZmZjNGQ0IiwiaWF0IjoxNjA5MTcwNjUwLCJuYmYiOjE2MDkxNzA2NTAsImV4cCI6MTY0MDcwNjY1MCwic3ViIjoiNiIsInNjb3BlcyI6W119.NE-oOiyB11RI1pFgyPutvrdtMyzgwZW9BluwQmr1d5t3eVc5gFtjwHr4JEH5mLXTF5xRot9EXz-4B8wNemCir1-G_DDYfh5gr2TDgg62Vm5gsuepIDvRJ_xMpNEzMltQ9q1wD7Y4EbuJNs1LLBZNo6aNNNYnHkaM8U34m1E_K1foqo09X_7zGSrioUhZnHS1CBomfq3qZPKmsWxNuBF1PljEk0pnC6ikuLrMvNObQX4UXTUn0x4TySQ4k6OlX7RmCveordxDATZ9dyqQ4QsPUBEN9vlANMDu_8NYcW3Gp0yztUXFCnh6Ku1F5oBCmi8tCcuKb3Ji1Z1qL6nCF3j1UYVdVRheVnojjs5joK4CA4sw8j239Zqh-II_grzhBQuF570W8ziT-NAabfEf53u65hK0iPpr5HRYJX0OpRc9s6qe0w76RnSNdmEU4rA5M1cviTWomHKZ2bZVWfR5ExyMIvOBteH2Y8EClyfb4lYsgdcyclpCAb5jyj2UOeHeGRy5VH6DqVWnV9BkQipsIB3UoKfcnOsUvvZjqKsiNo8ERR24n6lz_fOZ2d0j1Fwsivq8FZTY5-wt8KUoSq1uxvs1UKJlK3L6zpiDPgEeAuA2aQ1S-ADO-3vRjdsEZDqaarOm4eXEqotGv1Xsw0fyPdbEb3nFoC4zSHmZJje6aqpRioM');

		return headers;
	}

	getCookie(name : string){
		var nameEQ = encodeURIComponent(name) + UsblickCommonEnum.equalChar;
		var ca = document.cookie.split(UsblickCommonEnum.dotCommaChar);
		for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) === UsblickCommonEnum.spaceChar)
						c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0)
						return decodeURIComponent(c.substring(nameEQ.length, c.length));
		}
		return null;
}

	// Handling errors in response
	handleError(error: Response | any) {
		let errMsg: string;
		if (error instanceof Response) {
			const body:any = error.json() || '';
			const err = body.error || JSON.stringify(body);
			errMsg = `${err}`;
		} else {
			errMsg = error.message ? error.message : error.toString();
		}
		return Observable.throw(errMsg);
	}

	add(item: any, reservation?) {
		console.log("ADD de api service", item);
		console.log("ADD de api service", reservation);
		// item.reserveData = reservation;
		const storage = this.storage();
		const itinerary = JSON.parse(storage.getItem('itinerary'));
		const items: Array<any> = itinerary['items'];
		for (let i = 0; i < items.length; i++) {
			if (items[i].id === item.id) {
				items[i] = item;
			}
		}
		this.setItem(itinerary);
		this._restService.doPost('app', 'itineraries', itinerary).subscribe((response:any) => {
			console.log('Add-Success: ', response);
			if (reservation) {
				this._restService.doPost('app', 'reservations', reservation).subscribe(response2 => {
					console.log('Reservation-Success: ', response2);
				}, error => {
					console.log('Reservation-Error: ', error);
				});
			}
		}, error => {
			console.log('Add-Error: ', error);
		});
	}

	setItem(itinerary: any) {
		const storage = this.storage();
		storage.setItem('itinerary', JSON.stringify(itinerary));
	}
	storage() {
		let credentials = localStorage;
		if (sessionStorage.getItem('credentials') != null) {
			credentials = sessionStorage;
		}
		return credentials;
	}
}
