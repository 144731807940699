import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'usb-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {
  @Input() images: any[] = [];

  customOptionsPhotos: OwlOptions = {
    autoHeight: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<img src="../../../../../assets/img/app/arrow-izq-slider.png" />', '<img src="../../../../../assets/img/app/arrow-der-slider.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      },
      1140: {
        items: 1
      },
      1340: {
        items: 1
      },
      1540: {
        items: 1
      },
      1740: {
        items: 1
      },
      1940: {
        items: 1
      }
    },
    nav: true
    }

  constructor() { }

  ngOnInit(): void {
  }

}
