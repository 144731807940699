import { Component, Input, OnInit } from '@angular/core';
import { ItineraryService } from '../../../../itinerary.service';
import { Router } from '@angular/router';

@Component({
  selector: 'usb-itinerary-hotel',
  templateUrl: './itinerary-hotel.component.html',
  styleUrls: ['./itinerary-hotel.component.scss']
})
export class ItineraryHotelComponent implements OnInit {

  @Input() data: any;
  @Input() dt: any;

  constructor(public itineraryService: ItineraryService,
    private _router: Router,
  ) {
		console.log('hotel-data:', this.data);
  }

  ngOnInit(): void {
    if (typeof this.dt.reserveData === 'string') {
      this.dt.reserveData = JSON.parse(this.dt.reserveData);
    }
  }

  remove(dt: any) {
    console.log('data:', this.data);
    console.log('dt:', dt);
    const index: number = this.data.indexOf(dt);
    if (index !== -1) {
      this.data.splice(index, 1);
      this.itineraryService.remove(this.dt);
    }
  }
	
  // get detail reservation
  getDetail(data: any) {
    let reservation = data;
    if (typeof data === 'string') {
      reservation = JSON.parse(data);
    }
    this._router.navigateByUrl('/reservation/' + reservation.id);
  }
}
