import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { setting } from '../../../../../setting';

@Injectable()
export class AddressesService {

  private endpoint: string;
  private url = setting.apiOctopusUrl;
 
  constructor(private http: HttpClient) {
   // this.endpoint = this.url + '/address';
     this.endpoint = this.url + '/searchaddress';
  }

  getAddress(iataCode: any, keyword: any, categoryId: any): Observable<any[]> {
    if (keyword) {
      const url = this.endpoint + '/' + keyword + '/' + categoryId + '/' + iataCode;
      return this.http.get(url, { headers: this._headers })
        .map((res:any) => {
          const json = res.data;
          return json;
        });
    } else {
      return Observable.of([]);
    }
  }

  _getAddress(iataCode: any, keyword: any, categoryId: any, type: any): Observable<any[]> {
    if (keyword) {
      const url = this.endpoint + '/' + keyword + '/' + categoryId + '/' + iataCode;
      return this.http.get(url, { headers: this._headers })
      .map((res:any) => {
        const json = res.data;
          if (type) {
            const resu = json.filter(d => d.type !== type);
            return resu;
          } else {
            return json;
         }
        });
    } else {
      return Observable.of([]);
    }
  }

  private get _headers() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('client-id', environment.client_id);
    headers.append('client-secret', environment.client_secret);
    if (localStorage.getItem('bearer')) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('bearer'));
    }
    return headers;
  }
}
