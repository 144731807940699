import { Component, OnInit, Input, ViewContainerRef, TemplateRef, Output, EventEmitter, ViewChild, HostListener, ChangeDetectorRef } from "@angular/core";
import { RestService } from "../rest.service";
import { ResultsSearchService } from "../../home/results-search.service";
import { ItineraryService } from "../itinerary.service";
import { BsModalService, BsDatepickerConfig, BsModalRef, BsDatepickerDirective, BsLocaleService } from "ngx-bootstrap";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { CustomValidator } from "../customValidators";
import { roomSearchConfigurationItem } from "../../home/search-box/categories/hotels/roomSearchConfigurationItem";
import { InvokeEnum } from "./enums/invokeEnum";
import { UserService } from "app/pages/com.usblick.common/auth/user.service";
import { setting } from "../../../../setting";

@Component({
  selector: "usb-pax-list-modal",
  templateUrl: "./pax-list-modal.component.html",
  styleUrls: ["./pax-list-modal.component.scss"],
  providers: [DatePipe],
})
export class PaxListModalComponent implements OnInit {
  @ViewChild('startDateDp', { static: false }) startDateDp?: BsDatepickerDirective;
  @ViewChild('endDateDp', { static: false }) endDateDp?: BsDatepickerDirective;
  @Input() invoke: number; // If its 0, comes from sidebar footer, 1 from head-band
  @Input() actualItinerary: any;
  @HostListener('window:scroll')
  onScrollEvent() {
    this.startDateDp?.hide();
    this.endDateDp?.hide();
  }

  paxList: any;
  @Input() _payload: any;
  @Input() tabSelected: any;
  @Input() ape: any;
  @Input() form: any;
  _maxPersons: any;
  editable = false;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  modalRef: BsModalRef;
  _form: FormGroup;
  origin: any;
  minDate: Date;
  originalArrivalDate: Date;
  originalDepartureDate: Date;
  minDateDeparture: Date;
  maxDateArrival: Date;
  bsConfig: object;
  @ViewChild("dpDeparture") datepickerDeparture: BsDatepickerDirective;
  @ViewChild("dpArrival") datepickerArrival: BsDatepickerDirective;

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
  };
  modify = false;
  maxRooms: any;
  _maxAdults: any;
  _maxChildren: any;
  maxAdults: any;
  maxChildren: any;
  overlap = false;
  @Input() index: number;
  @Input() city: string;
  @Input() _paxAdults: string;
  @Input() _paxChildren: string;
  @Output() emitReload = new EventEmitter<any>();
  @Output() emitReSearch = new EventEmitter<any>();

  public getClientsSettingAppBooking: any;
  fullPayload: any;
  arrivalModify: boolean = false;
  departureModify: boolean = false;
  nights: any;
  actionEdit: number = 1;
  activeBed: boolean = false;
  valueAdultsObs: number = 0;
  totalAdults: number = 0;
  qtyProducts:number = 0;
  getForm:any;
  products: any;
  loading: boolean = false;
  qtyAdults: any;
  qtyChilds: any;
  qtyRoom: any;
  editProduct: boolean = false;
  chargeOnInit: boolean = false;

  payload: any;
  show: boolean = false;

  countries: any[] = [];
  filteredCountries: any[] = [];
  selectedIsNationality: any;

  constructor(
    private modalService: BsModalService,
    public resultService: ResultsSearchService,
    private _restService: RestService,
    private _formBuilder: FormBuilder,
    private _itineraryService: ItineraryService,
    public vcr: ViewContainerRef,
    private localeService: BsLocaleService,
    private cdr: ChangeDetectorRef,
  ) {
    this.dpConfig.dateInputFormat = 'DD/MM/YYYY';
    this.dpConfig.showWeekNumbers = true;
    this._maxAdults = Array.from({ length: 10 }, (_, i) => i + 1);
    this._maxChildren = Array.from(Array(11).keys());
    this.paxList = {
      datestart: null,
      dateend: null,
      stay: null,
      adults: null,
      children: null,
      nights: [],
      nationality: null,
      rooms: null,
    };
    this.maxRooms = [];
    this.maxAdults = [];
    this.maxChildren = [];
    this.initArrays();
    this.minDate = new Date();
    this.minDateDeparture = new Date();
    this.maxDateArrival = new Date();
    this.maxDateArrival.setDate(this.minDate.getDate() + 1095);
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }


  ngOnInit() {
    this.payload = JSON.parse(localStorage.getItem("payload"));
    this.getForm = JSON.parse(localStorage.getItem("form"));
    const body = JSON.stringify(this.payload);
    this.localeService.use('es');
    this.getCountries();
		// console.log("endpoint", url + endpoint);
    // console.log("this.payload ngOnInit de paxList", this.payload);
    // console.log("this.payload ngOnInit de paxList body stringify", body);
    // console.log("this.__form ngOnInit", this._form);


    if (this.actualItinerary.arrivalDate) {
      this.originalArrivalDate = new Date(this.actualItinerary.arrivalDate);
      this.originalArrivalDate = new Date(
        this.originalArrivalDate.getUTCFullYear(),
        this.originalArrivalDate.getUTCMonth(),
        this.originalArrivalDate.getUTCDate()
      );
    } else {
      this.originalArrivalDate = new Date(this.actualItinerary.arrivalDate);
      this.originalArrivalDate = new Date(
        this.originalArrivalDate.getUTCFullYear(),
        this.originalArrivalDate.getUTCMonth(),
        this.originalArrivalDate.getUTCDate()
      );
    }

    if (this.actualItinerary.originalDepartureDate) {
      this.originalDepartureDate = new Date(this.actualItinerary.departureDate);
      this.originalDepartureDate = new Date(
        this.originalDepartureDate.getUTCFullYear(),
        this.originalDepartureDate.getUTCMonth(),
        this.originalDepartureDate.getUTCDate()
      );
    } else {
      this.originalDepartureDate = new Date(this.actualItinerary.departureDate);
      this.originalDepartureDate = new Date(
        this.originalDepartureDate.getUTCFullYear(),
        this.originalDepartureDate.getUTCMonth(),
        this.originalDepartureDate.getUTCDate()
      );
    }

    this.origin = this.resultService._getOrigin();
    if ((!this.origin || !this.fullPayload) && localStorage.getItem("origin")) { // cuando se refresca la pagina
      this.origin = localStorage.getItem("origin");
    }

    if(this.payload && this.payload.length > 0){
      this.qtyProducts = this.payload[0].qtyProduct;
    }else{
      this.qtyProducts = this.payload.qtyProduct;
    }

    this.nights = this.getNights();
    if (this.origin != "RENT-A-CAR" && this.origin != "TRANSPORTS" && this.origin != "TOURS") {
      this.activeBed = true;
    } else {
      this.activeBed = false;
    }


    this.updateForm();

    // console.log('payload paxlist->', this.payload);

          this._itineraryService.modalClosed$.subscribe((edit: boolean) => {
            const payload = localStorage.getItem("payload");
             // Parsea 'payload' como un array de objetos JSON
            const payloadArray = JSON.parse(payload);
            // Realizar acciones cuando se cierre el modal
            console.log('El modal se ha cerrado.');
            if(!edit) {

              this.origin = localStorage.getItem("origin");
              this.getForm = JSON.parse(localStorage.getItem("form"));



              // Extrae el valor de 'qtyPax' del primer objeto en el array
              this.qtyAdults = payloadArray[0].qtyPax;
              this.qtyChilds = 0;

                // Recorre cada objeto en 'payloadArray'
                for (const payloadObj of payloadArray) {
                // Accede a la propiedad 'roomConfiguration' dentro de 'others.hotels'
                const roomConfiguration = payloadObj.others.hotels.roomConfiguration;

                // Recorre cada objeto en 'roomConfiguration' y suma la cantidad de niños
                for (const room of roomConfiguration) {
                  this.qtyChilds += room.children.length;
                }


              }

              this.invoke = 3;
              this.show = true;
              this.loading = false;
              this.editProduct = false;
            } else if (edit) {
              this.origin = localStorage.getItem("origin");
              this.getForm = JSON.parse(localStorage.getItem("form"));



              // Extrae el valor de 'qtyPax' del primer objeto en el array
              this.qtyAdults = payloadArray[0].qtyPax;
              this.qtyChilds = 0;
              this.qtyRoom = 1;
                // Recorre cada objeto en 'payloadArray'
                for (const payloadObj of payloadArray) {
                // Accede a la propiedad 'roomConfiguration' dentro de 'others.hotels'
                const roomConfiguration = payloadObj.others.hotels.roomConfiguration;

                // Recorre cada objeto en 'roomConfiguration' y suma la cantidad de niños
                for (const room of roomConfiguration) {
                  this.qtyChilds += room.children.length;
                }
              }
              this.invoke = 3;
              this.show = true;
              this.loading = true;
              this.qtyProducts = 1;
              this.editProduct = true;
              this.chargeOnInit = true;
          }
    });


  }



    updateForm(setting?: boolean) {
    this.loading = true;
    this.chargeOnInit = true;
    // console.log("updateForm", this.paxList);
    //this.paxList.datestart = this.payload[0]["arrivalDate"];
    //this.paxList.dateend =  this.payload[0]["departureDate"];

    this.paxList.datestart = new Date(this.payload[0]["arrivalDate"]);
    this.paxList.datestart = new Date(
      this.paxList.datestart.getUTCFullYear(),
      this.paxList.datestart.getUTCMonth(),
      this.paxList.datestart.getUTCDate()
    );


    this.paxList.dateend = new Date(this.payload[0]["departureDate"]);
    this.paxList.dateend = new Date(
      this.paxList.dateend.getUTCFullYear(),
      this.paxList.dateend.getUTCMonth(),
      this.paxList.dateend.getUTCDate()
    );
    // console.log("updateForm post moment", this.paxList);

    this.fillAdultsAndChilds(this.origin, this.payload[0].others);
    this.paxList.stay = moment(this.paxList.dateend).diff(this.paxList.datestart, "days");
    this.paxList.nationality = this.payload[0].nationality;
    this._form = this._formBuilder.group({
      arrivalDate: [ this.paxList.datestart , Validators.required],
      departureDate: [this.paxList.dateend, Validators.required],
      adults: [this.paxList.adults ? this.paxList.adults : 0],
      destination:[this.getForm.destination],
      city: 'BUENOS AIRES',
      childrens: [this.paxList.children ? this.paxList.children : 0],
      nationality: ['', Validators.required],
      room: this._setRooms(),
    });


    // console.log("this._form",  this._form);
  }

  fillAdultsAndChilds(category: any, object: any) {
    if (category !== undefined && category !== null) {
      switch (category) {
        case "HOTELS":
          this.getAdultsAndChild(object);
        break;
        case "PTHEMES":
          object = this._payload;
          if (object !== undefined && object.others !== undefined) {
            this.paxList.adults = parseInt(object.qtyPax);
            this.paxList.children = object.others.tickets.children.length;
          } else {
            this.paxList.children = "To be defined";
            this.paxList.adults = "To be defined";
          }
          break;
        case "TOURS":
          if (object !== undefined && object["tours"] !== undefined) {
            this.paxList.adults = object["tours"]["adults"];
            this.paxList.children = object["tours"]["children"].length;
            //this.paxList.children = object['tours']['children'][0];
          } else {
            this.paxList.children = "To be defined";
            this.paxList.adults = "To be defined";
          }
          break;
        case "TRANSPORTS":
          if (object !== undefined && object["transfers"] !== undefined) {
            this.paxList.adults = object["transfers"]["paxList"]["adults"];
            this.paxList.children = object["transfers"]["paxList"]["children"].length;
          } else {
            this.paxList.children = "To be defined";
            this.paxList.adults = "To be defined";
          }
          break;
        case "INSURANCE":
          if (object !== undefined && object["insurance"] !== undefined) {
            this.paxList.adults = object["insurance"]["adults"].length;
            this.paxList.children = object["insurance"]["children"].length;
          } else {
            this.paxList.children = "To be defined";
            this.paxList.adults = "To be defined";
          }
          break;
        case "DISNEY":
          object = this._payload.room;
          this.paxList.children = object !== undefined && object["adults"] !== undefined ? object["childs"].length : "To be defined";
          this.paxList.adults = object !== undefined && object["childs"] !== undefined ? object["adults"] : "To be defined";
          break;
        case "MULTIDESTINATION":
          switch (this._payload.categoriesId[0]) {
            case 6:
              if (object !== undefined && object["tours"] !== undefined) {
                this.paxList.adults = object["tours"]["adults"];
                this.paxList.children = object["tours"]["children"].length;
              } else {
                this.paxList.children = "To be defined";
                this.paxList.adults = "To be defined";
              }
              break;
            case 8:
              if (object !== undefined && object["transfers"] !== undefined) {
                this.paxList.adults = object["transfers"]["paxList"]["adults"];
                this.paxList.children = object["transfers"]["paxList"]["children"].length;
              } else {
                this.paxList.children = "To be defined";
                this.paxList.adults = "To be defined";
              }
              break;
            default:
              this.getAdultsAndChild(object);
              break;
          }
          break;
      }
    }
  }

  fillData() {
    if (this.actualItinerary["destination"].length > 0) {
      this._getData(true);
    } else {
      this._getData(false);
    }
  }

  _getData(destination: boolean) {
    if (destination) {
      this.paxList.stay = 0;
      this.actualItinerary["destination"].forEach(
        (destination) => {
          this.paxList.stay += destination["destination_info"]["nights"];
          this.paxList.nights.push({
            destination: destination["iata_code"],
            nights: destination["destination_info"]["nights"],
          });
        }
      );
      this.paxList.datestart = moment(this._payload["arrivalDate"]);
      this.paxList.dateend = moment(new Date(this._payload["arrivalDate"])).add(this.paxList.stay, "days").format("YYYY-MM-DD");
      this.fillAdultsAndChilds(this.origin, this._payload.others);
    } else {
      if (!destination && this._payload !== null && this._payload !== undefined) {
          this.paxList.datestart = moment(this._payload["arrivalDate"]);
          this.paxList.dateend = this._payload["categoriesId"][0] === 0 ? "To be defined" : this._payload["departureDate"];
          this.fillAdultsAndChilds(this.origin, this._payload.others);
          this.paxList.stay =
          this._payload["categoriesId"][0] === 0 ? "To be defined" : moment(this.paxList.dateend).diff(this.paxList.datestart, "days");
        } else {
          this.paxList.datestart = "To be defined";
          this.paxList.dateend = "To be defined";
          this.paxList.children = "To be defined";
          this.paxList.adults = "To be defined";
          this.paxList.stay = "To be defined";
      }
    }

  /*  if (this.actualItinerary !== null && this._payload !== null) {
      this._initForms();
    } */
  }

  getAdultsAndChild(object: any) {
    if (object !== null && object !== undefined && object.hotels !== null && object.hotels !== undefined) {
      let qtyAdults = 0;
      let qtyChildren = 0;
      object.hotels.roomConfiguration.forEach((room) => {
        qtyAdults += parseInt(room.adults);
        qtyChildren += room.children.length;
      });
      this.paxList.children = qtyChildren;
      this.paxList.adults = qtyAdults;
    }

    // console.log("object getAdultsAndChild", object);
  }

  _initForms() {
    if (this.invoke === 1) {
      this._form = this._formBuilder.group({
        room: this._setRooms(),
        start_day: this.paxList.datestart ? new Date(this.paxList.datestart) : null,
      });
    } else {
      try {
        this._form = this._formBuilder.group({
        //  arrivalDate: [this.originalArrivalDate, Validators.required],
         // departureDate: [this.originalDepartureDate, Validators.required],
          adults: 960,//[this.paxList.adults ? this.paxList.adults : 0],
          childrens: [this.paxList.children ? this.paxList.children : 0],
          room: this._setRooms(),
        });
      } catch (error) {
        console.log("error", error);
      }
    }

    try {
      if (this._payload) {
        var arrivalDate = moment(this._payload.arrivalDate).add(1, "days").format("YYYY-MM-DD");
        this._form.controls["arrivalDate"].setValue(new Date(arrivalDate));

        var departureDate = moment(this._payload.departureDate).add(1, "days").format("YYYY-MM-DD");
        this._form.controls["departureDate"].setValue(new Date(departureDate));
      }
    } catch (error) {}
  }

  //presionar  buscar
  validateOverlapMulti(template: TemplateRef<any>) {
    console.log(template)
    if (this.actualItinerary["destination"].length > 1) {
      // console.log('form value ->', this._form.value.arrivalDate);

      var arrivalDateForm = moment(this._form.value.arrivalDate.toString().split(",")[0]).format("YYYY-MM-DD");
      var departureDateform = moment(this._form.value.departureDate.toString().split(",")[0]).format("YYYY-MM-DD");
      let destiny = this.actualItinerary["destination"].find((x) => x.iata_code == this.payload[0].destination);
      let index = this.actualItinerary["destination"].indexOf(destiny);
      //this.payload[index].departureDate = moment(this._form.value.departureDate.toString().split(",")[0]).format("YYYY-MM-DD");
      //this.payload[index].arrivalDate = moment(this._form.value.arrivalDate.toString().split(",")[0]).format("YYYY-MM-DD");

      var backDestiny = this.payload[index - 1];
      var nextDestiny = this.payload[index + 1];


        if (backDestiny) {
          if (moment(arrivalDateForm) > moment(backDestiny.departureDate) || moment(arrivalDateForm) < moment(backDestiny.departureDate)) {
            this.arrivalModify = true;
          }
        }

        if (nextDestiny) {
          if (moment(departureDateform) > moment(nextDestiny.arrivalDate) || moment(departureDateform) < moment(nextDestiny.arrivalDate)) {
            this.departureModify = true;
          }
        }

        if (this.arrivalModify || this.departureModify) {
          this.closeModal();

          this.openModal(template);
        } else {
          //this._roomsApply();
        }

      } else {
        this._roomsApply();
      }
      this.updateForm(true);
    }

  _roomsApply(event?: Event) {
    // console.log('destination del actualitinerary ->', this.actualItinerary["destination"]);

    let destiny = this.actualItinerary["destination"].find((x) => x.iata_code == this.payload[0].destination);
    if (destiny) {
      let index = this.actualItinerary["destination"].indexOf(destiny);
      this.actualItinerary["destination"][index]["destination_info"]["arrival"] = moment( this._form.value.arrivalDate.toString().split(",")[0]).format("YYYY-MM-DD");
      this.actualItinerary["destination"][index]["destination_info"]["departure"] = moment(this._form.value.departureDate.toString().split(",")[0]).format("YYYY-MM-DD");
      // this._itineraryService.emitActiveItinerary([this.actualItinerary], true);
    }
    this.payload[0]["arrivalDate"] = moment(this._form.value.arrivalDate.toString().split(",")[0]).format("YYYY-MM-DD");
    this.payload[0]["departureDate"] = moment(this._form.value.departureDate.toString().split(",")[0]).format("YYYY-MM-DD");
// console.log(this._form)
    const roomDist : any[] = [];
    this._form.value["room"].forEach((room) => {
      const people = [];
      const dist: roomSearchConfigurationItem = {
        adults: 0,
        children: [],
      };
      const adults = room.qtyAdults;
      for (let j = 0; j < room.childsage.length; j++) {
        people.push(room.childsage[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);
    });

    // console.log("roomDist",roomDist);

    //Configuracion de Room
    switch (this.payload[0].categoriesId[0]) {

      case 1: //hotel
         this.payload[0]["others"]["hotels"]["roomConfiguration"] = roomDist;
        break;
      case 6: //tour
        this.payload[0]["others"]["tours"] = roomDist[0];
        break;
      case 8:
        this.payload[0]["others"]["transfers"]["vehiclesDist"] = roomDist;
        break;
    }



    if (this.modify) {
      this.editable = true;
    }

    if (localStorage.getItem("payload")) {
      localStorage.removeItem("payload");
    }

    if (localStorage.getItem("form")) {
      localStorage.removeItem("form");
    }

    // console.log(this.payload)

    this._form["destination"] = this.payload[0]["destination"];
        const sumaAdultos = roomDist.reduce((total, room) => {
      return total + parseInt(room.adults, 10); // Asegúrate de convertir el valor a número
    }, 0);

    this.payload[0].qtyPax = sumaAdultos;

    this.payload[0].qtyProduct = roomDist.length;

    if(this.paxList.nationality !== this._form.value.nationality) {
      console.log(this.countries)
      const nationality = this.countries.find(c => c.country_name === this._form.value.nationality);
      this.payload[0].nationality = nationality.country_code;
    }

    localStorage.setItem("payload", JSON.stringify(this.payload));
    localStorage.setItem("form", JSON.stringify(this._form.value));

    // console.log("payload actualzaido", this.payload);
    // console.log("form actualzaido", this._form);

    if (this.invoke === InvokeEnum.HEAD_BAND) {
      if (this.actualItinerary.destination && this.actualItinerary.destination[0]) {
        const arrival = moment(this._form.value.start_day.toString().split(",")[0]).format("YYYY-MM-DD");
        this.actualItinerary["destination"][0]["destination_info"]["arrival"] = arrival;
        this.actualItinerary["arrivalDate"] = arrival;
      }

      this.payload["arrivalDate"] = moment(this._form.value.start_day.toString().split(",")[0]).format("YYYY-MM-DD");
     // this._itineraryService.emitActiveItinerary([this.actualItinerary], true);
    }

    if (this.invoke !== 3) {
      this._itineraryService.payload = this._payload;
      this._itineraryService.payloadSubject.next(this._payload);
    }



    this.modify = false;
    this._getData(this.actualItinerary["destination"].length > 1 ? true : false);
    this.modalRef.hide();
    this._emitReload();
  }


  ngOnChanges() {
    this.nights = this.getNights();
  }


  _initLoadData(payload: any) {
    this._payload = payload;
    if (!this._payload) {
      this._payload = JSON.parse(localStorage.getItem("payload"));
    }
    this.origin = this.resultService._getOrigin();
    if (this._payload !== undefined) {
      this.fillData();
    }
  }

  initArrays() {
    for (let index = 0; index < 29; index++) {
      if (index > 0) {
        if (index < 8) {
          this.maxRooms.push(index);
        }
        this.maxAdults.push(index);
      }
      this.maxChildren.push(index);
    }
  }

  validate(template: TemplateRef<any>) {
    let destinations = this.actualItinerary["destination"];
    var overlap = false;

    var dest = new Promise((resolve, reject) => {
      destinations.forEach(function (value, key) {
        var nextObject = destinations[key + (1 % destinations.length)];

        if (nextObject) {
          if (new Date(value.destination_info.departure).getTime() > new Date(nextObject.destination_info.arrival).getTime()) {
            overlap = true;
            //@ts-ignore
            resolve();
          }
        }
      });
    });

    dest.then(() => {
      if (overlap) this.openModal(template);
    });
  }



  configPax() {
  /*  let destiny = this.actualItinerary["destination"].find((x) => x.iata_code == this._payload.destination);
    let index = this.actualItinerary["destination"].indexOf(destiny);
    const roomDist = [];

    this._form.value["room"].forEach((room) => {
      const people = [];
      const dist: roomSearchConfigurationItem = {
        adults: 0,
        children: [],
      };
      const adults = room.qtyAdults;
      for (let j = 0; j < room.childsage.length; j++) {
        people.push(room.childsage[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);
    });

    //Configuracion de Room
    switch (this._payload.categoriesId[0]) {
      case 6:
        this._payload["others"]["tours"] = roomDist[0];
        break;
      case 8:
        this._payload["others"]["transfers"]["vehiclesDist"] = roomDist;
        break;
      default:
        /// this._payload["others"]["hotels"]["roomConfiguration"] = roomDist;
        break;
    }

    switch (this.payload[index].categoriesId[0]) {
      case 6:
        this.payload[index]["others"]["tours"] = roomDist[0];
        break;
      case 8:
        this.payload[index]["others"]["transfers"]["vehiclesDist"] = roomDist;
        break;
      default:
        //  this.payload[index]["others"]["hotels"]["roomConfiguration"] = roomDist;
        break;
    }

    this._payload.qtyProduct = roomDist.length;
    this.payload[index] = roomDist.length; */
  }

  _emitReload() {
    this.editable = false;
    // console.log("this.payload[0]", this.payload);
    this.emitReload.emit(this.payload[0]);
  }

  _emitReSearch() {
    let destiny = this.actualItinerary["destination"].find((x) => x.iata_code == this._payload.destination);
    let index = this.actualItinerary["destination"].indexOf(destiny);
    this.actualItinerary["destination"][index]["destination_info"]["arrival"] = moment(
      this._form.value.arrivalDate.toString().split(",")[0]
    ).format("YYYY-MM-DD");
    this.actualItinerary["destination"][index]["destination_info"]["departure"] = moment(
      this._form.value.departureDate.toString().split(",")[0]
    ).format("YYYY-MM-DD");

    //Configuracion de nueva fecha al seleccionado
    this._payload["arrivalDate"] = moment(this._form.value.arrivalDate.toString().split(",")[0]).format("YYYY-MM-DD");
    this._payload["departureDate"] = moment(this._form.value.departureDate.toString().split(",")[0]).format("YYYY-MM-DD");

    this.payload[index]["arrivalDate"] = moment(this._form.value.arrivalDate.toString().split(",")[0]).format("YYYY-MM-DD");
    this.payload[index]["departureDate"] = moment(this._form.value.departureDate.toString().split(",")[0]).format("YYYY-MM-DD");

    var a = moment(this._form.value.arrivalDate);
    var b = moment(this._form.value.departureDate);
    var difference = b.diff(a, "days");

    if (this.modify) {
      this.editable = true;
    }

    if (this.departureModify) {
      for (let i = index; i < this.payload.length; i++) {
        if (i != index) {
          var departureDateForm = this.payload[i - 1].departureDate;
          var nightsObj = this.paxList.nights.find((element) => element.destination == this.payload[i].destination);
          var newDepartureDate = moment(moment(departureDateForm)).add(nightsObj.nights, "days").format("YYYY-MM-DD");
          this.payload[i].arrivalDate = departureDateForm;
          this.payload[i].departureDate = newDepartureDate;
          let dest = this.actualItinerary["destination"].find((x) => x.iata_code == this.payload[i].destination);
          let aux = this.actualItinerary["destination"].indexOf(dest);

          this.actualItinerary["destination"][aux]["destination_info"]["arrival"] = departureDateForm;
          this.actualItinerary["destination"][aux]["destination_info"]["departure"] = newDepartureDate;
        }
      }
    }

    if (this.arrivalModify) {
      for (let i = index; i >= 0; i--) {
        if (i != index) {
          var arrivalDateForm = this.payload[i + 1].arrivalDate;
          //busco la cantidad de noches anterior
          var nightsObj = this.paxList.nights.find((element) => element.destination == this.payload[i].destination);
          var newArrivalDate = moment(moment(arrivalDateForm)).subtract(nightsObj.nights, "days").format("YYYY-MM-DD");

          this.payload[i].arrivalDate = newArrivalDate;
          this.payload[i].departureDate = arrivalDateForm;
          let dest = this.actualItinerary["destination"].find((x) => x.iata_code == this.payload[i].destination);
          let aux = this.actualItinerary["destination"].indexOf(dest);

          this.actualItinerary["destination"][aux]["destination_info"]["arrival"] = newArrivalDate;
          this.actualItinerary["destination"][aux]["destination_info"]["departure"] = arrivalDateForm;
        }
      }
    }

    this.actualItinerary.arrivalDate = this.payload[0].arrivalDate;
    this.actualItinerary.departureDate = this.payload[this.payload.length - 1].arrivalDate;
    this._itineraryService.emitActiveItinerary([this.actualItinerary], true);
    this.closeModal();
    this.editable = false;
    this.modify = false;
    // this.emitReSearch.emit(this.payload);
  }


  openModalWithClass(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: "gray modal-lg modal-edit-search" }));
  }


  formArray(form: FormGroup, key: string) {
    return (<FormArray>form.get(key)).controls;
  }



  _adultModify() {
    this.modify = true;
  }


  changePaxAdultTours() {
    this._payload["others"]["tours"].adults = parseFloat(this._form.get("adults").value);

    //cambia en el 0 porque tour solo tiene 0 room
    this.room.controls[0].patchValue({
      qtyAdults: parseFloat(this._form.get("adults").value),
    });
    this.origin = localStorage.getItem("origin").toString();
  }

  changePaxChildrenTours(room) {
    //cambia en el 0 porque tour solo tiene 0 room
    this.room.controls[0].patchValue({
      qtyChilds: parseFloat(this._form.get("childrens").value),
    });
    this._addChild(room);
  }

  get room(): FormArray {
    return this._form.get("room") as FormArray;
  }

  _setRooms(): FormArray {
    let pay;
    switch (this.payload[0].categoriesId[0]) {


      case 1:
        pay = this.payload[0]["others"]["hotels"]["roomConfiguration"];

        // console.log("_setRooms roomConfiguration", pay);
        break;

      case 6:
        pay = [this.payload[0]["others"]["tours"]];
        break;
      case 8:
        pay = this.payload[0]["others"]["transfers"]["vehiclesDist"];
        break;

      case 12:
        pay = [this.payload[0]["room"]];
        break;
      case 0:
        pay = this.payload[0]["others"]["hotels"]["roomConfiguration"];
        break;
      default:
        break;
    }
    const room_ = this._formBuilder.array([]);
    if (pay !== undefined && pay !== null) {
      pay.forEach((room) => {
        const _childsAges = this._formBuilder.array([]);
        if (room.children !== null && room.children !== undefined) {
          room.children.forEach((_age) => {
            _childsAges.push(
              this._formBuilder.group({
                age: [_age, Validators.compose([Validators.required, CustomValidator.soloNumerosValidator, Validators.max(17)])],
              })
            );
          });
        }

        room_.push(
          this._formBuilder.group({
            qtyChilds: [_childsAges.length, Validators.required],
            qtyAdults: [room.adults, [Validators.required, Validators.min(1)]],
            childsage: _childsAges,
          })
        );

       /* room_.push(
          this._formBuilder.group({
            qtyChilds: [_childsAges.length, Validators.required],
            qtyAdults: [room.adults, [Validators.required, Validators.min(1)]],
            childsage: _childsAges,
          })
        );*/
      });
    }
    // console.log("room_", room_);
    return room_;
  }

  _removeRoom(index: number) {
    const room = this._form.get("room") as FormArray;
    room.removeAt(index);
    this.modify = true;
  }

  _addRoom() {
    this.modify = true;
    const room = this._form.get("room") as FormArray;
    const _childsAges = this._formBuilder.array([]);

    room.push(
      this._formBuilder.group({
        qtyChilds: [_childsAges.length],
        qtyAdults: [1],
        childsage: _childsAges,
      })
    );
  }

  _addChild(room: FormGroup) {
    this.modify = true;
    setTimeout(() => {
      const ages: any = room.get("childsage") as FormArray;
      const qty: number = +room.value["qtyChilds"];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(
            this._formBuilder.group({
              id: [i],
              // tslint:disable-next-line:max-line-length
              age: [
                0,
                Validators.compose([Validators.required, CustomValidator.soloNumerosValidator, Validators.min(0), Validators.max(17)]),
              ],
            })
          );
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  cancel() {
    //this._initForms();
    this.modalRef.hide();
  }



  setDepartureDate(event: any) {
    if (event !== null) {
      this.minDateDeparture = new Date(event);
      this.setOptions();
      return;
    }
  }

  setArrivalDate(event: any) {
    if (event !== null) {
      this.maxDateArrival = new Date(event);
      this.setOptions("arrival");
      return;
    }
  }

  setOptions(tipo: String = "departure"): void {
    if (tipo != "departure") {
      this.bsConfig = Object.assign({}, { maxDate: this.maxDateArrival });
      if (this.datepickerArrival) this.datepickerArrival.setConfig();
      return;
    }
    this.bsConfig = Object.assign({}, { minDate: this.minDateDeparture });
    if (this.datepickerDeparture) this.datepickerDeparture.setConfig();
  }

  _getDeparture(arrivalDate: any, nights?: number) {
    let departureDate = new Date(arrivalDate.getUTCFullYear(), arrivalDate.getUTCMonth(), arrivalDate.getUTCDate());
    if (nights) {
      departureDate.setDate(departureDate.getDate());
    } else {
      departureDate.setDate(departureDate.getDate() + nights);
    }
    return departureDate;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  closeModal(): void {
    this.modalRef.hide();
  }

  getNights() {
    let totNight = 0;
    this.actualItinerary.destination.map((destination) => {
      totNight += destination.destination_info.nights;
    });
    return totNight;
  }

  getNumberValueAdults(): void {
    this._restService.numberAdultsObs$.subscribe((res: number) => {
      this.valueAdultsObs = res;
    });
  }

  getCountries() {
    this._itineraryService._restService.doGet("api", "/utilities/countries").subscribe({
      next: (res: any) => {
        console.log(res)
        if(res.Countrys && res.Countrys.length > 0) {
          this.countries = res.Countrys;
          this.filteredCountries = this.countries;

          const nationality = this.filteredCountries.find(c => c.country_code === this.payload[0].nationality);
          this.selectedIsNationality = nationality?.country_name;

        }
      }
    })
  }

  changeNationality(countryId: any) {
    console.log(countryId);
  }

  filterCountries(searchText: string) {
    if (searchText) {
      this.filteredCountries = this.countries.filter(country =>
        country.ZoneName.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredCountries = this.countries;
    }
    this.cdr.detectChanges();
  }

  displayCountry = (country: any) => {
    console.log(country)
    if (!this.filteredCountries) {
      console.error('filteredCountries is not defined');
      return '';
    }
    let countryData = this.filteredCountries.find(c => c.IataCode === country);
    if(country === null) {
      countryData = null;
    }
    console.log(countryData)
    this.cdr.detectChanges();
    return countryData ? `${countryData.ZoneName}` : '';
  }
}
