import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core/core.module';
////import { SharedModule } from '../shared/shared.module';
import { ErrorRoutingModule } from './error.routing.module';
import { ErrorComponent } from './error.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        //CoreModule,
        
        ErrorRoutingModule,
    ],
    declarations: [
        ErrorComponent
    ]
})
export class ErrorModule { }
