import { ItineraryService } from 'app/shared/itinerary.service';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../authorization/authorization.service';
import { setRoles, setPermissions, setAgency, setAgent, loadAgency, setCategories, loadCurrencyById, setCurrency } from './profiles.actions';

@Injectable()
export class AuthEffects {

  loadRoles$ = createEffect(() => this.actions$.pipe(
    ofType('[Auth] Load Roles'),
    mergeMap(() => this.authService.getProfiles()
      .pipe(
        map(roles => setRoles({ roles })),
        catchError(() => of({ type: '[Auth] Load Roles Failed' }))
      ))
    )
  );

  loadPermissions$ = createEffect(() => this.actions$.pipe(
    ofType('[Auth] Load Permissions'),
    mergeMap(() => this.authService.listToModulesUser()
      .pipe(
        map(permissions => setPermissions({ permissions })),
        catchError(() => of({ type: '[Auth] Load Permissions Failed' }))
      ))
    )
  );

  loadAgency$ = createEffect(() => this.actions$.pipe(
    ofType(loadAgency),
    switchMap(action => this.authService.getAgencyName(action.clientId)
      .pipe(
        map(agency => {
          console.log('Agency data received:', agency); // Log the data
          return setAgency({ agency });
        }),
        catchError(() => of({ type: '[Agency] Load Agency Failed' }))
      ))
    )
  );


  loadAgent = createEffect(() => this.actions$.pipe(
    ofType('[Agent] Load Agent'),
    mergeMap(() => this.authService._getAgent()
      .pipe(
        map((agent: any) => setAgent({ agent })),
        catchError(() => of({ type: '[Agent] Load Agent Failed' }))
      ))
    )
  );

  // MISCELLANEO

loadCategories$ = createEffect(() => this.actions$.pipe(
  ofType('[Categories] load Categories'),
  mergeMap(() => this.itineraryService.getCategories()
    .pipe(
      map(categories => setCategories({ categories })),
      catchError(() => of({ type: '[Categories] Set Categories Failed' }))
    ))
  )
);

loadCurrency$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadCurrencyById), // Utiliza la acción tipada
    mergeMap(({ id }) =>
      this.authService.getCurrencyConfig(id).pipe(
        map(currency => setCurrency({ currency })), // Acción a despachar en caso de éxito
        catchError(error => of({ type: '[Currency] Load Currency Failed', error })) // Manejo de errores
      )
    )
  )
);

  constructor(
    private actions$: Actions,
    private authService: AuthorizationService,
    private itineraryService: ItineraryService
  ) {}
}
