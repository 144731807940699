import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItineraryService } from '../itinerary.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'usb-item-selected',
  templateUrl: './item-selected.component.html',
  styleUrls: ['./item-selected.component.scss']
})
export class ItemSelectedComponent implements OnInit {

  @Input() categoryName: string;
  @Input() destino: string;
  @Input() payload: any;
  @Output() collapse = new EventEmitter<boolean>();

  itemsSelect: any[];
  childs: string;
  adults: string;
  star: any[];
  option:boolean=false;

  subscription: Subscription;

  constructor(private _itineraryService: ItineraryService) { }

  ngOnInit() {

    console.log("PAYLOAD RECIBIDO EN ITEMS SELECT ", this.payload);
    //console.log("NOMBRE DE CATEGORIA", this.categoryName);

    if( this.categoryName != 'TOURS'){
      this.option=true;
      //console.log("la categoria es hotel u otra, se muestra la fecha?" , this.option);
    }

    this.itemsSelect = [];
    this._initData();
  }

  _initData() {
    this.subscription = this._itineraryService.getItemSelectedMultidestination().subscribe((data:any) => {

      console.log('data multidestination item select ---',data);
      
      this.itemsSelect = [];

      if (data.data) {
        if (data.data.items && data.data.items.length > 0) {
          var category = data.category ? data.category : this.categoryName;
          const destiny = this.destino ? this.destino : data.destiny;

          data.data.items.forEach(item => {
            if (category === item.categoryName) {
              //console.log('Entra en category == category name');
              if (item.categoryHotel) {
                //this.initArrayStar(parseInt(item.categoryHotel));
              }
              let product = item.payloadUpdate !== undefined && item.payloadUpdate.products !== undefined ? item.payloadUpdate.products[0] : item.payload;
              if (product) {
                this.setChildsAndAdults(product);
                if (product.destination === destiny) {
                  let element = this.itemsSelect.filter(itemSave => itemSave.id === item.id);
                  if (element.length === 0) {
                    this.itemsSelect.push(item);
                  }

                  //this._emitCollapse(false);
                }
              }
            }
          });
        } 
        if(data.delete!=undefined){
          //console.log('entro en undefined');
          //this.itemsSelect = [];
          //localStorage.removeItem('itemsSelect');
        }

        console.log("this.itemsSelect", this.itemsSelect);

        // if(data.delete==undefined){
        //   console.log('entro en else undefined');
        //   if(localStorage.getItem('itemsSelect')){
        //     console.log('reviso el localStorage');
            
        //     if( JSON.parse(localStorage.getItem('itemsSelect')).length > 0){

        //       this.itemsSelect = JSON.parse(localStorage.getItem('itemsSelect'));
        //       if (this.itemsSelect[0].categoryHotel) {
        //         this.initArrayStar(parseInt(this.itemsSelect[0].categoryHotel));
        //       }
        //     }
        //   }
        // }
        // localStorage.setItem('itemsSelect', JSON.stringify(this.itemsSelect));
        // console.log('guardo la nueva data multidestination item select en local ---',this.itemsSelect);

      }

      //this.subscription.unsubscribe();
    },
    error => {
      console.log('Error: ', error);
      //this.subscription.unsubscribe();
    });

   

  }

  setChildsAndAdults(product: any) {
    if (product !== undefined && product.others !== undefined) {
      this.childs = product.others.childs;
      this.adults = product.qtyPax;
    }
  }

  initArrayStar(category: number) {
    //console.log('dhsgjshs', category);
    this.star = new Array(category);
  }

  _emitCollapse(close: boolean) {
    this.collapse.emit(close);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
