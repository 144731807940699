import { AngularSvgIconModule } from 'angular-svg-icon';
import { GroupByPipe, OrderByPipe } from './itinerary-reservation.pipes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule, TabsModule, BsDatepickerModule, TypeaheadModule, BsModalRef } from 'ngx-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TruncateModule } from 'ng2-truncate';

import { SummaryReservationComponent } from './summary-reservation/summary-reservation.component';
import { StepsReservationComponent } from './steps-reservation/steps-reservation.component';
import { BodyReservationComponent } from './body-reservation/body-reservation.component';
import { ItineraryReservationComponent } from './itinerary-reservation.component';
import { ItineraryReservationRoutingModule } from './itinerary-reservation-routing.module';

import { AccordionModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormPaxesReservationComponent } from './body-reservation/form-paxes-reservation/form-paxes-reservation.component';
import { ConfirmReservationComponent } from './confirm-reservation/confirm-reservation.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CountdownModule } from 'ngx-countdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ItineraryDetailModule } from 'app/itinerary-detail/itinerary-detail.module';
import { ItineraryService } from 'app/shared/itinerary.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from 'app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip'


defineLocale('es', esLocale);

// function countdownConfigFactory(): Config {
//     return { template: `$!h!:$!m!:$!s!` };
// }

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgxPaginationModule,
        ItineraryReservationRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        TruncateModule,
        ModalModule.forRoot(),
        CarouselModule,
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        CountdownModule,
        NgxSpinnerModule,
        AngularSvgIconModule.forRoot(),
        TypeaheadModule.forRoot(),
        ItineraryDetailModule,
        SharedModule,
        TooltipModule

        // ModalModule.forRoot()
    ],
    declarations: [
        ItineraryReservationComponent,
        ConfirmReservationComponent,
        SummaryReservationComponent,
        BodyReservationComponent,
        FormPaxesReservationComponent,
        GroupByPipe,
        OrderByPipe,
        StepsReservationComponent,
    ],
    providers: [
      ItineraryService,
      BsModalRef
        // { provide: CountdownConfig, useFactory: countdownConfigFactory }
    ],
    exports: [
      StepsReservationComponent
    ]
})
export class ItineraryReservationModule { }
