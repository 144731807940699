import { Component, OnInit, Input, ViewContainerRef, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { ResultsSearchService } from '../../home/results-search.service';
import { ItineraryService } from '../itinerary.service';
import { BsModalService, BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
//import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import * as moment from 'moment';
import { flightIn, flightOut } from './flight.modal';
import { Observable } from 'rxjs/Observable';
import { UbicacionesService } from '../../home/search-box/service/ubicaciones.service';
import { CustomValidator } from '../../shared/customValidators';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../material-modal-confirm/material-modal-confirm.component';
 import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'usb-flight-modal',
  templateUrl: './flight-modal.component.html',
  styleUrls: ['./flight-modal.component.scss'],
  providers: [UbicacionesService]
})
export class FlightModalComponent implements OnInit {

  @Input() invoke: number; // If its 0, comes from sidebar footer, 1 from head-band
  @Input() _actualItinerary: any;
  @Input() modeView: any;

  flightIn: flightIn;
  flightOut: flightOut;
  checked = false;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  modalRef: BsModalRef;
  minDate: Date;
  _formFlights: FormGroup;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  ismeridian = true;
  selectedAirportIn: any;
  selectedAirlineIn: any;
  selectedAirportOut: any;
  selectedAirlineOut: any;

  flightId: any;
  _longAirportIn: string;
  _longAirlineIn: string;
  _longAirportOut: string;
  _longAirlineOut: string;
  _airportIn: any;
  airportIn: any;
  airlineIn: any;
  airportOut: any;
  airlineOut: any;
  _airlineOut: any;
  _airportOut: any;
  _airlineIn: any;
  valueAirlineIn: any;
  valueAirlineCodeIata: any;
  minDateDeparture: Date;
  maxDateArrival: Date;
  bsConfig: object;
  message = [];

  isLoadingResult: boolean;
  errorMsg: string;
  keyword = 'nameAirline';

  _flightNumberIn: any;
  __airlineIn: any;

  constructor(private modalService: BsModalService,
    private route: Router,
    private ubicacionesService: UbicacionesService,
    private _restService: RestService,
    private _formBuilder: FormBuilder,
    private _itineraryService: ItineraryService,
    private datePipe: DatePipe,
    public vcr: ViewContainerRef,
//    public _sanitizer:DomSanitizer,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,

    ) {
    this.minDate = new Date();
    this.dpConfig.containerClass = 'theme-red';
    this.minDate.setDate(this.minDate.getDate());
    //this.toastr.clearAllToasts();
    //this.toastr.setRootViewContainerRef(this.vcr);

  }

  ngOnInit() {
    this._initFlights();
    this._initData();
  }
  _initData() {

    this._itineraryService.flight.subscribe(resp => {
      if (resp !== undefined) {
        this.checked = false;
        this._initFlights();
      }

    });


  }

  checkflight() {
    this.message.splice(0, this.message.length);
    const a = this._formFlights;
    let result = true;
    let arrivalDateIn;
    let departureDateIn;
    let departureDateOut;
    let eArrivalDate;
    let edepartureDate;
    let arrivalHourIn;
    let departureHourOut;
    let departureHourIn;
    let epickupTime;
    let edropoffTime;
    let pi;
    let pd;
    let menorDate;
    let mayorDate;
    arrivalDateIn = moment(a.value['arrivalDateIn'].toString().split(',')[0]).format('YYYY-MM-DD');
    departureDateIn = moment(a.value['departureDateIn'].toString().split(',')[0]).format('YYYY-MM-DD');
    departureDateOut = moment(a.value['departureDateOut'].toString().split(',')[0]).format('YYYY-MM-DD');
    departureHourIn = moment(a.value['departureHourIn'].toString().split(',')[0]).format('HH:mm');
    arrivalHourIn = moment(a.value['arrivalHourIn'].toString().split(',')[0]).format('HH:mm');
    departureHourOut = moment(a.value['departureHourOut'].toString().split(',')[0]).format('HH:mm');
    const hd = parseInt(departureHourIn.split(':')[0], 10) * 60 + parseInt(departureHourIn.split(':')[1], 10);
    let ho = parseInt(departureHourOut.split(':')[0], 10) * 60 + parseInt(departureHourOut.split(':')[1], 10);
    let hi = parseInt(arrivalHourIn.split(':')[0], 10) * 60 + parseInt(arrivalHourIn.split(':')[1], 10);
    const arrivalDate = [];
    const departureDate = [];
    this._itineraryService._restService.doGet('app', 'itineraries/' + this._actualItinerary['id']).subscribe(
      (response:any) => {
        if (response.data.items.length > 0) {
          let index = 0;
          response.data.items.forEach(e => {
            if (e.categoryName === 'TRANSPORTS') {
              setTimeout(() => {
                arrivalDate[index] = e.arrivalDate + ' ' + e.pickupTime;
                departureDate[index] = e.departureDate + ' ' + e.dropoffTime;
                index++;
              }, 100);
            }
          });
          /// evaluar los destinos con transport
          setTimeout(() => {
            menorDate = new Date(departureDate[0]);
            mayorDate = new Date(arrivalDate[0]);
            for (let i = 0; i < arrivalDate.length; i++) {
              const arrDate = new Date(arrivalDate[i]);
              // tslint:disable-next-line:max-line-length
              if (moment(arrDate.toString().split(',')[0]).format('YYYY-MM-DD') < moment(menorDate.toString().split(',')[0]).format('YYYY-MM-DD')) {
                menorDate = arrDate;
              }
            }
            for (let i = 0; i < departureDate.length; i++) {
              const _arrDate = new Date(departureDate[i]);
              // tslint:disable-next-line:max-line-length
              if (moment(_arrDate.toString().split(',')[0]).format('YYYY-MM-DD') > moment(mayorDate.toString().split(',')[0]).format('YYYY-MM-DD')) {
                mayorDate = _arrDate;
              }
            }
            edepartureDate = moment(menorDate.toString().split(',')[0]).format('YYYY-MM-DD');
            eArrivalDate = moment(mayorDate.toString().split(',')[0]).format('YYYY-MM-DD');
            epickupTime = moment(menorDate.toString().split(',')[0]).format('HH:mm');
            edropoffTime = moment(mayorDate.toString().split(',')[0]).format('HH:mm');
            pi = parseInt(epickupTime.split(':')[0], 10) * 60 + parseInt(epickupTime.split(':')[1], 10);
            pd = parseInt(edropoffTime.split(':')[0], 10) * 60 + parseInt(edropoffTime.split(':')[1], 10);
            if (departureDateIn > edepartureDate) {
              result = false;
              // tslint:disable-next-line:max-line-length
              // //this.toastr.error('Error, Enter a lower Departure IN  date  to the transfer Pickup IN: ' + edepartureDate, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a lower Departure IN  date  to the transfer Pickup IN: ' + edepartureDate);
            }
            if (arrivalDateIn > edepartureDate) {
              result = false;
              // tslint:disable-next-line:max-line-length
              //  //this.toastr.error('Error, Enter a lower Arrival IN date to the transfer Pickup IN: ' + edepartureDate, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a lower Arrival IN date to the transfer Pickup IN: ' + edepartureDate);
            }
            if ((hd > hi) && (arrivalDateIn === departureDateIn)) {
              result = false;
              // tslint:disable-next-line:max-line-length
              //this.toastr.error('Error, Enter a lower Arrival IN time  to the Deaperture IN time: ' + arrivalHourIn, 'Error', { toastLife: 5000 });
            }

            hi = hi + 75; // 1.15 hrs
            if ((hi > pi) && (arrivalDateIn === edepartureDate)) {
              result = false;
              // tslint:disable-next-line:max-line-length
              //  //this.toastr.error('Error, Enter a lower Arrival IN time (- 1:15hrs) to the transfer Pickup IN: ' + epickupTime, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a lower Arrival IN time (- 1:15hrs) to the transfer Pickup IN: ' + epickupTime);
            }

            /*if (departureDateOut < departureDateIn) {
              result = false;
              // tslint:disable-next-line:max-line-length
             // //this.toastr.error('Error, Enter a larger Departure Out date to the Departure IN the Flight: ' + departureDateIn, 'Error', { toastLife: 5000 });
             this.message.push('Error, Enter a larger Departure Out date to the Departure IN the Flight: ' + departureDateIn);
            } */

            /*if (departureDateIn > arrivalDateIn) {
              result = false;
              // tslint:disable-next-line:max-line-length
             // //this.toastr.error('Error, Enter a lower Departure In date to the Arrivate IN the Flight: ' + arrivalDateIn, 'Error', { toastLife: 5000 });
             this.message.push('Error, Enter a lower Departure In date to the Arrivate IN the Flight: ' + arrivalDateIn);
            }*/

            /* if (arrivalDateIn < departureDateIn) {
               result = false;
               // tslint:disable-next-line:max-line-length
              // //this.toastr.error('Error, Enter a  larger Arrival In date to the Departure IN the Flight: ' + arrivalDateIn, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a  larger Arrival In date to the Departure IN the Flight: ' + arrivalDateIn);
             } */

            /* if (departureDateOut < arrivalDateIn) {
               result = false;
               // tslint:disable-next-line:max-line-length
              //  //this.toastr.error('Error, Enter a larger Departure Out date  to the Arrivate IN the Flight: ' + arrivalDateIn, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a larger Departure Out date  to the Arrivate IN the Flight: ' + arrivalDateIn);
             }*/

            if (departureDateOut > eArrivalDate) {
              result = false;
              // tslint:disable-next-line:max-line-length
              // //this.toastr.error('Error, Enter a lower Departure Out date  to the transfer Pickup Out: ' + eArrivalDate, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a lower Departure Out date  to the transfer Pickup Out: ' + eArrivalDate);
            }

            ho = ho + 210;
            if ((ho > pd) && (departureDateOut === eArrivalDate)) {
              result = false;
              // tslint:disable-next-line:max-line-length
              // //this.toastr.error('Error, Enter a lower Departure Out time (- 3:30hrs) to the transfer Pickup Out: ' + edropoffTime, 'Error', { toastLife: 5000 });
              this.message.push('Error, Enter a lower Departure Out time (- 3:30hrs) to the transfer Pickup Out: ' + edropoffTime);
            }
            if (result) {
              this._flightsApply();
            }
          }, 100);

        } else {

          if ((hd > hi) && (arrivalDateIn === departureDateIn)) {
            result = false;
            // tslint:disable-next-line:max-line-length
            //  //this.toastr.error('Error, Enter a lower Arrival IN time  to the Deaperture IN time: ' + arrivalHourIn, 'Error', { toastLife: 5000 });
            this.message.push('Error, Enter a lower Arrival IN time  to the Deaperture IN time: ' + arrivalHourIn);
          }
          /*if (departureDateOut < departureDateIn) {
            result = false;
            // tslint:disable-next-line:max-line-length
          //  //this.toastr.error('Error, Enter a larger Departure Out date to the Departure IN the Flight: ' + departureDateIn, 'Error', { toastLife: 5000 });
            this.message.push('Error, Enter a larger Departure Out date to the Departure IN the Flight: ' + departureDateIn);
          }

          if (departureDateIn > arrivalDateIn) {
            result = false;
            // tslint:disable-next-line:max-line-length
           // //this.toastr.error('Error, Enter a lower Departure In date to the Arrivate IN the Flight: ' + arrivalDateIn, 'Error', { toastLife: 5000 });
           this.message.push('Error, Enter a lower Departure In date to the Arrivate IN the Flight: ' + arrivalDateIn);
          }

          if (arrivalDateIn < departureDateIn) {
            result = false;
            // tslint:disable-next-line:max-line-length
           // //this.toastr.error('Error, Enter a  larger Arrival In date to the Departure IN the Flight: ' + arrivalDateIn, 'Error', { toastLife: 5000 });
           this.message.push('Error, Enter a  larger Arrival In date to the Departure IN the Flight: ' + arrivalDateIn);
          }

          if (departureDateOut < arrivalDateIn) {
            result = false;
            // tslint:disable-next-line:max-line-length
          //  //this.toastr.error('Error, Enter a larger Departure Out date  to the Arrivate IN the Flight: ' + arrivalDateIn, 'Error', { toastLife: 5000 });
          this.message.push('Error, Enter a larger Departure Out date  to the Arrivate IN the Flight: ' + arrivalDateIn);

        } */


          if (result) {
            this._flightsApply();
          }
        }
      }, error => {
        console.log('Error: ' + error);
      });



  }

  closeModal(): void {
    this.modalRef.hide();
  }

  _removeFlight(index?: number) {
    const message = '¿Estas seguro de eliminar este Vuelo?';
    var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
      width: '350px',
      data: {message: message},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result){
         this.flightIn = null;
         this.flightOut = null;
         this.resetForm();
         this._itineraryService.removeFlight(this.flightId);
      }else {
      }
    });
  }
  _flightsApply() {

    const a = this._formFlights;
    const arrivalDateIn = new Date(a.value['arrivalDateIn']);
    const departureDateIn = new Date(a.value['departureDateIn']);
    const arrivalHourIn = new Date(a.value['arrivalHourIn']);
    const departureHourIn = new Date(a.value['departureHourIn']);


    // tslint:disable-next-line:max-line-length
    const arrIn = new Date(arrivalDateIn.getFullYear(), arrivalDateIn.getMonth(), arrivalDateIn.getDate(), arrivalHourIn.getHours(), arrivalHourIn.getMinutes());
    // tslint:disable-next-line:max-line-length
    const depIn = new Date(departureDateIn.getFullYear(), departureDateIn.getMonth(), departureDateIn.getDate(), departureHourIn.getHours(), departureHourIn.getMinutes());
    // out
    // const arrivalDateOut = new Date(a.value['arrivalDateOut']);
    const departureDateOut = new Date(a.value['departureDateOut']);
    // const arrivalHourOut = new Date(a.value['arrivalHourOut']);
    const departureHourOut = new Date(a.value['departureHourOut']);
    // tslint:disable-next-line:max-line-length
    // const arrOut = new Date(arrivalDateOut.getFullYear(), arrivalDateOut.getMonth(), arrivalDateOut.getDate(), arrivalHourOut.getHours(), arrivalHourOut.getMinutes());
    const depOut = new Date(departureDateOut.getFullYear(), departureDateOut.getMonth(), departureDateOut.getDate(), departureHourOut.getHours(), departureHourOut.getMinutes());

    if (a.value['id'] === null) {



      const flightPayload = {
        // in

        // tslint:disable-next-line:max-line-length
        airlineIn: a.value['airlineIn']['nameAirline'] === undefined ? a.value['airlineIn'] : a.value['airlineIn']['nameAirline'],
        flyNumberIn:  a.value['flightNumberIn'],
        departureIn: this.datePipe.transform(depIn, 'yyyy-MM-dd HH:mm:ss'),
        arrivalIn: this.datePipe.transform(arrIn, 'yyyy-MM-dd HH:mm:ss'),
        // tslint:disable-next-line:max-line-length
        airportIn: a.value['airportIn']['nameAirport'] === undefined ? a.value['airportIn'] : a.value['airportIn']['nameAirport'],
        airportIataCodeIn: this.selectedAirportIn,
        // out
        // tslint:disable-next-line:max-line-length
        airlineOut: a.value['airlineIn']['nameAirline'] === undefined ? a.value['airlineIn'] : a.value['airlineIn']['nameAirline'],
        flyNumberOut:  a.value['flightNumberOut'],
        departureOut: this.datePipe.transform(depOut, 'yyyy-MM-dd HH:mm:ss'),
        arrivalOut: this.datePipe.transform(depOut, 'yyyy-MM-dd HH:mm:ss'), // arrOut
        // tslint:disable-next-line:max-line-length
        airportOut: a.value['airportOut']['nameAirport'] === undefined ? a.value['airportOut'] : a.value['airportOut']['nameAirport'],
        airportIataCodeOut: this.selectedAirportOut,
        airlineIataCodeOut :  this.valueAirlineCodeIata,
        itinerary: this._actualItinerary['id']
      };
      this._itineraryService._restService.doPost('app', 'fly_itineraries', flightPayload).subscribe(
        Response => {
          //this.toastr.success('Flight saved successfully', 'Congratulations', { toastLife: 5000 });
          this._itineraryService.flightSubject.next(flightPayload);
          this.modalRef.hide();
        },
        Error => {
          console.log('Error', Response);
          //this.toastr.error('Error saving the flight', 'Error', { toastLife: 5000 });
        }
      );
    } else {

      const flightPayload = {
        // in
        // tslint:disable-next-line:max-line-length
        airlineIn: a.value['airlineIn']['nameAirline'] === undefined ? a.value['airlineIn'] : a.value['airlineIn']['nameAirline'],
        flyNumberIn: a.value['flightNumberIn'],
        departureIn: this.datePipe.transform(depIn, 'yyyy-MM-dd HH:mm:ss'),
        arrivalIn: this.datePipe.transform(arrIn, 'yyyy-MM-dd HH:mm:ss'),
        // tslint:disable-next-line:max-line-length
        airportIn: a.value['airportIn']['nameAirport'] === undefined ? a.value['airportIn'] : a.value['airportIn']['nameAirport'],
        airportIataCodeIn: this.selectedAirportIn,
        airlineIataCodeOut:  this.valueAirlineCodeIata,
        // out
        // tslint:disable-next-line:max-line-length
        airlineOut: a.value['airlineIn']['nameAirline'] === undefined ? a.value['airlineIn'] : a.value['airlineIn']['nameAirline'],

        flyNumberOut: a.value['flightNumberOut'],
        departureOut: this.datePipe.transform(depOut, 'yyyy-MM-dd HH:mm:ss'),
        arrivalOut: this.datePipe.transform(depOut, 'yyyy-MM-dd HH:mm:ss'), // arrOut
        // tslint:disable-next-line:max-line-length
        airportOut: a.value['airportOut']['nameAirport'] === undefined ? a.value['airportOut'] : a.value['airportOut']['nameAirport'],
        airportIataCodeOut: this.selectedAirportOut,
      };


      this._itineraryService._restService.doPut('app', 'fly_itineraries/' + a.value['id'], flightPayload).subscribe(
        Response => {
          //this.toastr.success('Flight updated successfully', 'Congratulations', { toastLife: 5000 });
          this._itineraryService.flightSubject.next(flightPayload);
          this.modalRef.hide();
        },
        Error => {
          //this.toastr.error('Error updating the flight', 'Error', { toastLife: 5000 });
        }
      );
    }
  }

  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' })
    );
  }

  _initFlights() {
    if (!this.checked) {
      let flight = null;
      this._itineraryService._restService.doGet('app', 'itinerary/' + this._actualItinerary['id'] + '/fly').subscribe(
        Response => {
          // setTimeout(() => {
           console.log('Has flights associated', Response);
          if (Response.success === false && Response.message === 'Fly not found') {
            this._initFlightsForm();
            return;
          } else {
            if(Response['data'][0]) {

              flight = Response['data'][0];
              console.log(flight)
              this.iniFlightObject();
              // in

              this.flightId = flight['id'];
              this.flightIn.departureDateIn = new Date(flight['departureIn']);
              this.flightIn.arrivalDateIn = new Date(flight['arrivalIn']);
              this.flightIn.airportIn = flight['airportIn'];
              this.flightIn.airportIataCodeIn = flight['airportIataCodeIn']
              this.flightIn.airlineIn = flight['airlineIn'];
              this.flightIn.flightNumberIn = flight['flyNumberIn'];
              this.flightIn.airlineIataCodeOut = flight['airlineIataCodeOut'];
              this.valueAirlineCodeIata = flight['airlineIataCodeOut'];
              this.flightIn._flightNumberIn =  flight['flightNumberIn'];

              // out
              this.flightOut.departureDateOut = new Date(flight['departureOut']);
              //this.flightOut.arrivalDateOut = new Date(flight['arrivalOut']);
              this.flightOut.airportIataCodeOut = flight['airportIataCodeOut'];
              this.flightOut.airportOut = flight['airportOut'];
              this.flightOut.airlineOut = flight['airlineOut'];
              this.flightOut.flightNumberOut = flight['flyNumberOut'];
              this.flightOut._flightNumberOut = flight['flightNumberIn'];
              this.flightOut.airlineIataCodeOut = flight['flightNumberOut'];
              flight === null ? this._initFlightsForm() : this._initFlightsForm(flight);
            }
          }
          // }, 10000);
        },
        Error => {
          //  console.log('Has no flights associated', Error);
          this._initFlightsForm();
        }
      );
      /*  setTimeout(() => {
         flight === null ? this._initFlightsForm() : this._initFlightsForm(flight);
       }, 1000); */


      this.checked = true;
    }


  }

  iniFlightObject() {
    this.flightIn = {
      departureDateIn: null,
      arrivalDateIn: null,
      airportIn: null,
      airportIataCodeIn: null,
      airlineIn: null,
      _flightNumberIn: null,
      flightNumberIn: null,
      airlineIataCodeOut : null,
    };
    this.flightOut = {
      departureDateOut: null,
      // arrivalDateOut: null,
      airportIataCodeOut: null,
      airportOut: null,
      airlineOut: null,
      _flightNumberOut: null,
      flightNumberOut: null,
      airlineIataCodeOut : null
    };

  }

  /***********************************************Auto Complete********************************************/
  _myListFormatterAirport = (data: any) => {
    const html = `<span class='fa fa-plane'style='color:#a9a4a4'>
                      </span>
                      <span style='font-size:15px;cursor:pointer;line-height:2.1;'>
                        ${ this.capitalize(data['nameAirport'])}
                      </span>
                      <span style=' font-weight: 500;font-size:14px;color:#a9a4a4'>
                       (${data['codeIataAirport']})
                      </span>`;
     return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  _myListFormatterAirline = (data: any) => {

      const html = `<span class='fa fa-building-o'style='color:#a9a4a4'>
      </span>
      <span style='font-size:15px;cursor:pointer;line-height:2.1;'>
        ${ this.capitalize(data['nameAirline'])}
      </span>
      <span style=' font-weight: 500;font-size:14px;color:#a9a4a4'>
       (${data['codeIataAirline']})
      </span>`;

    return this._sanitizer.bypassSecurityTrustHtml(html);

  }


  capitalize(val: any) {
    return val.toLowerCase()
            .trim()
            .split(' ')
            .map( v => v[0].toUpperCase() + v.substr(1) )
            .join(' ');
  }

  _myCallbackAirportIn(data: any): string {
    if (data) {
      this.selectedAirportIn = data['codeIataAirport']; // codeIataCity
     return `${data['nameAirport']} (${data['codeIataAirport']}) (${data['codeIataAirport']})`;
    }
  }

  _myCallbackAirportOut(data: any): string {
   if (data) {
    this.selectedAirportOut =  data['codeIataAirport']; //his._airportOut['codeIataAirport'];
    return `${data['nameAirport']} (${data['codeIataAirport']})`;
   }
  }

  _myCallbackAirlineIn(data: any): string {

    if (data) {
      this.selectedAirlineIn = data['codeIataAirline'];
      this.valueAirlineCodeIata = this.selectedAirlineIn;
      this._formFlights.patchValue({
        _flightNumberIn:  this.selectedAirlineIn,
        _flightNumberOut:  this.selectedAirlineIn,
        airlineOut : data['nameAirline'],
      });
      return `${data['nameAirline']} (${data['nameAirline']})`;
    }
  }

  _myCallbackAirlineOut(data: any): string {
   //4 this._longAirlineOut = this._airlineOut['nameAirline'];
   if (data) {
    this.selectedAirlineOut = this._airlineOut['codeIataAirline'];
    return `${data['nameAirline']} (${data['codeIataAirline']})`;
   }
  }

  observableSourceAirportIn = (keyword: any): Observable<any[]> => {
    this.isLoadingResult = true;
    const results = this.ubicacionesService.getAirports(keyword);
    if (results !== undefined) {
      this.isLoadingResult = false;
      return results;
    } else {
      this.errorMsg = results['message'];
    }
  }

  observableSourceAirlineIn = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getAirlines(keyword);
  }

  observableSourceAirportOut = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getAirports(keyword);
  }

  /*observableSourceAirlineOut = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getAirlines(keyword);
  }*/


  selectEvent(item: any) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  searchCleared() {
    // this.countries = [];
  }

  onFocused(e) {
    // do something
  }


  /***********************************************Auto Complete********************************************/

  resetForm ()  {
    this._formFlights.patchValue({
        id: null,
        airlineIn: null,
      _flightNumberIn: null,
        flightNumberIn: null,
        departureDateIn: null,
        arrivalDateIn:   null,
        arrivalHourIn: null,
        departureHourIn:  null,
        airportIn: null,
        airlineOut: null,
        _flightNumberOut:  null,
        valueAirlineCodeIata: null,
        flightNumberOut: null,
        departureDateOut:  null,
        departureHourOut:  null,
        airportOut: null
      });
  }

  _initFlightsForm(flight?: any) {


    if (this._formFlights !== undefined) {

      this._formFlights.setValue({
        id: flight ? flight['id'] : null,
        // in
        airlineIn: flight ? this.flightIn.airlineIn : null,
        // tslint:disable-next-line:max-line-length

        _flightNumberIn: flight ? this.flightIn.airlineIataCodeOut : null, // this.flightIn.flightNumberIn.split('-')[0] : null,
        flightNumberIn: flight ? this.flightIn.flightNumberIn : null,
        departureDateIn: flight ? this.flightIn.departureDateIn : null,
        arrivalDateIn: flight ? this.flightIn.arrivalDateIn : null,


        arrivalHourIn: flight ? this.flightIn.arrivalDateIn : null,
        departureHourIn: flight ? this.flightIn.departureDateIn : null,
        airportIn: flight ? this.flightIn.airportIn : null,
        // out
        airlineOut: flight ? this.flightIn.airlineIn : null,
        _flightNumberOut: flight ? this.flightOut.airlineIataCodeOut : null,
        valueAirlineCodeIata: flight ? this.flightOut.airlineIataCodeOut : null,
        flightNumberOut: flight ? this.flightOut.flightNumberOut : null,
        departureDateOut: flight ? this.flightOut.departureDateOut : null,
        departureHourOut: flight ? this.flightOut.departureDateOut : null,
        airportOut: flight ? this.flightOut.airportOut : null
      });
    } else {


     ///  this.valueAirlineCodeIata = this.flightIn.airlineIataCodeOut;

      this._formFlights = this._formBuilder.group({
        id: [flight ? flight['id'] : null],
        // in
        airlineIn: [flight ? this.flightIn.airlineIn : null, Validators.required],
        _flightNumberIn: [flight ? this.flightIn.airlineIataCodeOut : null, Validators.required],
       // valueAirlineCodeIata: flight ? this.flightOut.airlineIataCodeOut : null,
        flightNumberIn: [flight ? this.flightIn.flightNumberIn : null, Validators.required],
        departureDateIn: [flight ? this.flightIn.departureDateIn : null, Validators.compose([Validators.required,
        (c: FormControl) => {
          if (
            this._formFlights !== undefined &&
            c.value !== null &&
            this._formFlights.get('arrivalDateIn').value !== null &&
            this._formFlights.get('arrivalDateIn').value !== undefined
          ) {
            const valid = (c.value <= this._formFlights.get('arrivalDateIn').value) ? null : { invalidDate: true };
            this._formFlights.controls['arrivalDateIn'].setErrors(valid);
            return (c.value <= this._formFlights.get('arrivalDateIn').value) ? null : { invalidDate: true };
          }
        }
        ])],
        arrivalDateIn: [flight ? this.flightIn.arrivalDateIn : null, Validators.compose([Validators.required,
        (c: FormControl) => {
          if (
            this._formFlights !== undefined &&
            c.value !== null &&
            this._formFlights.get('departureDateIn').value !== null &&
            this._formFlights.get('departureDateIn').value !== undefined
          ) {
            const valid = (c.value >= this._formFlights.get('departureDateIn').value) ? null : { invalidDate: true };
            this._formFlights.controls['departureDateIn'].setErrors(valid);
            return (c.value >= this._formFlights.get('departureDateIn').value) ? null : { invalidDate: true };
          }
        }])
        ],
        arrivalHourIn: [flight ? this.flightIn.arrivalDateIn : null, Validators.required],
        departureHourIn: [flight ? this.flightIn.departureDateIn : null, Validators.required],
        airportIn: [flight ? this.flightIn.airportIn : null, Validators.required],
        // out

        airlineOut: [flight ? this.flightIn.airlineIn : null, Validators.required],
        _flightNumberOut: [flight ? this.flightIn._flightNumberIn : null, Validators.required],
        flightNumberOut: [flight ? this.flightOut.flightNumberOut : null, Validators.required],
        departureDateOut: [flight ? this.flightOut.departureDateOut : null, Validators.required],
        departureHourOut: [flight ? this.flightOut.departureDateOut : null, Validators.required],
        airportOut: [flight ? this.flightOut.airportOut : null, Validators.required]
      });



      this._flightNumberIn = flight ? this.flightIn.airlineIataCodeOut : null;
      this.__airlineIn =  flight ? this.flightIn.airlineIn : null;

      const a = this._formFlights;

      if (a.value['airlineIn'] !== '') {
        this.valueAirlineIn = a.value['airlineIn'];
      }
    }


  }
  toggleMode(): void {
    this.ismeridian = !this.ismeridian;
  }
  cancel() {
    this.modalRef.hide();
  }


  subStrings(str){
    if(str.length>18){
      return str.slice(0,18)
    }else{
      return str
    }
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }
}
