<div id="multi-destination" class="container-fluid">
  
  <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
  
  <div class="row">

    <div id="head-band justify-content-between" class="col-xg-12 col-lg-12 col-md-12 col-sm-12">
      <usb-head-band></usb-head-band>
    </div><!-- /head-band -->

  </div>

  <div class="row">

    <div id="main-content" class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row">

        <div id="left-sidebar" class="col-xg-3 col-lg-3 col-md-12 col-sm-12">

          <div class="row" id="itinerary-component">
              <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <usb-itinerary-sidebar [payload]="_payload" [modeView]="'MULTIDESTINATION'" (isLoading)="_onLoading($event)"></usb-itinerary-sidebar>
              </div>
          </div>

          <div class="row">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">

            </div>
          </div>

        </div><!-- left-sidebar -->

        <div id="map-keypoint-component" class="col-xg-9 col-lg-9 col-md-12 col-sm-12">
        
        <!--           
         <usb-map class="row"></usb-map>

          <usb-key-point></usb-key-point> -->

        </div>
        <!-- /map-keypoint-component -->

      </div>

    </div><!-- /main-content -->
  </div>


</div>
