import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatArrivalDate } from '../../../../../../utils/Functions';
import { ItineraryService } from '../../../../../../shared/itinerary.service';
import { MaterialModalConfirmComponent } from '../../../../../../shared/material-modal-confirm/material-modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';

@Component({
  selector: 'usb-item-rentacar',
  templateUrl: './item-rentacar.component.html',
  styleUrls: ['./item-rentacar.component.scss']
})
export class ItemRentacarComponent implements OnInit {

  @Input() dato: any;
  @Input() pickUp? : number;
  @Input() isCheckIn: boolean;
  @Input() partialReserve: boolean;
  @Output() itemToPartial = new EventEmitter<number>();

  public formatDate = formatArrivalDate;

  bsModalRef?: BsModalRef;

  constructor(private itineraryService: ItineraryService, private modalService: BsModalService, private dialog : MatDialog) { }

  ngOnInit() {

    //console.log('--------------------------------------------')
    console.log("this.dato", this.dato);
    console.log('pickUp==>', this.pickUp);
    //console.log('partial==>', this.partialReserve);
  }

  ngOnChanges() {
    console.log('partial==>', this.partialReserve);
  }

  removeItem(item: any) {
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Quieres eliminar este elemento?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if(result) {
        this.itineraryService.remove(item);
      } else {

      }
      confirmSubscription.unsubscribe();
    });
    //console.log(item);
    //this.itineraryService.remove(item);
  }

  toPartialReserve(event, item: any){
    if ( event.target.checked ) {
      this.itineraryService.updateItineraryPartial(item);
      this.itemToPartial.emit(1);
    } else {
      this.itineraryService.updateItineraryPartial(item,true);
      this.itemToPartial.emit(-1);
    }
  }

  subtractDays(date){
    return moment(moment(date)).subtract(2, 'days').format('YYYY-MM-DD');
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    if (str) {
     let e = str.toLowerCase()
     return e.replace(/\b\w/g, l => l.toUpperCase())
  }
  }

  fontPrice(price){
    if((price.length - 6) > 0){
      const fact = price.length - 6
      const size = 25 - (1.5 * fact)
      return size + 'px'
    }
  }
}
