import { Injectable } from "@angular/core";
// import {Http, Response, Headers} from '@angular/http';
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";

import { UsblickCommonEnum } from "../usblick-common-enum";
import { ApiService } from "../../../itinerary/shared/api.service";
import { ItineraryService } from "../../../shared/itinerary.service";
import { UserService } from "./user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { setting } from '../../../../../setting';
import { map } from "rxjs/operators";

@Injectable()
export class AuthService {
  public apiBookingUrl: string;
  //user
  _name: string;
  _email: string;
  _agentID: number;
  //agent
  _agencyName: string;
  _agencyID: string;
  _imagen_logotype: string;

  public appUrl = setting.appUrl

  constructor(private _http: HttpClient, private itineraryService: ItineraryService, public userService: UserService) {
    this.apiBookingUrl = setting.apiBookingUrl;

    // tslint:disable-next-line:indent
    if (setting.ActivatedGuard) {

      this._getAgent();
      // console.log('active _getAgent()')


    } else {
      // tslint:disable-next-line:indent
      this.userService.userActive = {
        address: "None",
        agency_commission: "20",
        agency_id: "1",
        agent_id: "2",
        created_by: "1",
        email: "agency@demo.com",
        email_user: "agente@usblick.com",
        enable: "1",
        first_name: "Agente",
        imagen_logotype: "",
        last_name: "Agente",
        name_agency: "Agencia",
        name_business: "Business",
        name_role: "Agent",
        pay_mode: "1",
        phone: "04245555555",
        status: "1",
        tax_id: "J-00000000",
        user_id: "2",
        profitability: "0",
      };

      this.userService._name = "Agente Agente";
      this.userService._email = "agente@usblick.com";
      this.userService._agentID = 2;
      this.userService._agencyName = "Agencia";
      this.userService._agencyID = 1;
      this.userService.activeUserSubject.next(userService.userActive);
      this.userService._imagen_logotype = "";
      const prueba = this.userService.userActive;
      console.log('estoy aca', prueba)

      localStorage.setItem("selectedAgency", JSON.stringify(this.userService.userActive));


    }

  }

  validate(data: any, type: string) {
    const json = JSON.stringify(data);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        "client-id": "environment.client_id",
        "client-secret": "environment.client_secret",
        Authorization: "Bearer " + this.getCookie("auth_token"),
      }),
    };
    return this._http.post(this.apiBookingUrl + UsblickCommonEnum.validService + json, httpOptions);
  }

  logout(data: any) {
    const json = JSON.stringify(data);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        "client-id": "environment.client_id",
        "client-secret": "environment.client_secret",
        Authorization: "Bearer " + this.getCookie("auth_token"),
      }),
    };
    // console.log("logount", this.apiBookingUrl + UsblickCommonEnum.logoutService + json);
    return this._http.post(this.apiBookingUrl + UsblickCommonEnum.logoutService + json, httpOptions);
  }

  getCookie(name: string) {
    //console.log ("document.cookie", document.cookie);
    var nameEQ = encodeURIComponent(name) + UsblickCommonEnum.equalChar;
    var ca = document.cookie.split(UsblickCommonEnum.dotCommaChar);
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === UsblickCommonEnum.spaceChar) c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  }

  /**
   * Cookie Register
   * @param cname
   * @param cvalue
   * @param exdays exdays = -1,  to delete  cookie.|  exdays = null, to inifinite.
   */
  setCookie(cname: string, cvalue: string, exdays: number) {
    if (exdays) {
      var d = new Date();
      var totalmseconds =
        exdays *
        UsblickCommonEnum.hoursByDay *
        UsblickCommonEnum.minutesByHour *
        UsblickCommonEnum.secondsByMinute *
        UsblickCommonEnum.milisecondBySecond;
      d.setTime(d.getTime() + totalmseconds);
      var expires = UsblickCommonEnum.expiresCookie + d.toUTCString();
      document.cookie = cname + UsblickCommonEnum.equalChar + cvalue + UsblickCommonEnum.dotCommaChar + expires + UsblickCommonEnum.pathCookie;
    } else {
      document.cookie = cname + UsblickCommonEnum.equalChar + cvalue + UsblickCommonEnum.pathCookie;
    }
  }

  _getAgent() { ;
    let url = setting.apiBookingUrl;
    let headers = new HttpHeaders();

    headers.append("Authorization", "Bearer " + this.getCookie("auth_token"));
    headers.append("Content-Type", "application/json");
    this._http
      .get(url + "user/current")
      .catch(this.handleError)
      .subscribe(
        (Response) => {
          console.log(Response)
          this.userService._profile = Response["det"]["profile"];
          this.userService._name = Response["det"]["first_name"] + " " + Response["det"]["last_name"];
          this.userService._email = Response["det"]["email"];
          this.userService._agentID = Response["det"]["id"];
          // console.log(Response)
          this.getAgencyName(this.userService._agentID);
        },
        (Error) => {
          console.log("Error: ", Error);
          window.location.href = this.appUrl;
        }
      );
      // console.log(this.userService._agentID)
  }

  // tslint:disable-next-line:member-ordering
  getAgencyName(agentId: any) {
    let url = setting.apiBookingUrl;

    const headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("client-id", environment.client_id);
    headers.append("client-secret", environment.client_secret);
    headers.append("Authorization", "Bearer " + this.getCookie("auth_token"));
    this._http
      .get(url + "agents/" + agentId, { headers: headers })
      .map((response: any) => response)
      //.catch(this.handleError)
      .subscribe(
        (response: any) => {
          console.log('response del agencyName', response.data[0]);
          this.userService.userActive = response.data[0];
          this.userService.activeUserSubject.next(response);
          this.userService._imagen_logotype = response.data[0].imagen_logotype;
          this.userService._channel_id = response.data[0].channel_id;
          this.userService._licenseId = response.data[0].license;
          this.userService._marketId = response.data[0].location_agency_id;
          this.userService._agencyID = response.data[0].agency_id;
        },
        (error) => {
          console.log(error);
        }
      );
  }


  getProfiles(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', 'Bearer ' + this.getCookie('auth_token'));
    console.log(headers);
    console.log(`${this.apiBookingUrl}profile/list`, { headers: headers});
    return this._http.get<any>(`${this.apiBookingUrl}profile/list`, { headers: headers}).
    pipe(map(res => res.data));
}
  // Handling errors in response
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error.json() || "";
      const err = body.error || JSON.stringify(body);
      errMsg = `${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }
}
