import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatArrivalDate } from '../../../../../../utils/Functions';
import { ItineraryService } from '../../../../../../shared/itinerary.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'usb-item-disney',
  templateUrl: './item-disney.component.html',
  styleUrls: ['./item-disney.component.scss'],
  providers: [DatePipe]
})
export class ItemDisneyComponent implements OnInit {
  @Input() dato: any;
  @Input() isCheckIn: boolean;
  @Input() partialReserve: boolean;
  @Output() itemToPartial = new EventEmitter<number>();
  @Output() itemDelete = new EventEmitter<any>();

  public formatDate = formatArrivalDate;

  constructor(private datePipe: DatePipe, private itineraryService: ItineraryService) { }

  ngOnInit() {
    //console.log(this.dato);
  }
  getDate() {
    // return this.isCheckIn ? this.datePipe.transform(moment(this.dato.hotel.dateIni), 'MMM dd, yyyy') :
    // this.datePipe.transform(moment(this.dato.hotel.dateEnd), 'MMM dd, yyyy');
    return this.isCheckIn ? this.datePipe.transform(this.dato.hotel.dateIni, 'MMM dd, yyyy') :
    this.datePipe.transform(this.dato.hotel.dateEnd, 'MMM dd, yyyy');
  }
  getPax() {
    let adults = 0;
    let children = 0;
    this.dato.form.room.forEach(room => {
      adults += Number.parseInt(room.qtyAdults);
      children += room.qtyChilds;
    });
    return adults + ' ' + (adults !== 1 ? 'adults - ' : 'adult - ') +
    children + ' ' + (children !== 1 ? 'children' : 'child');
  }
  stars() {
    const array = [];
    for (let index = 0; index < this.dato.categoryHotel; index++) {
      array.push(index);
    }
    return array;
  }
  toPartialReserve(event, item: any){
      if ( event.target.checked ) {
        this.itineraryService.updateItineraryPartial(item);
        this.itemToPartial.emit(1);
      } else {
        this.itineraryService.updateItineraryPartial(item, true);
        this.itemToPartial.emit(-1);
      }
    }
  
  removeItem(item: any) {
    console.log(item);
    this.itineraryService.remove(item);
    this.itemDelete.emit(item);
  }

  fontPrice(price){
    if((price.length - 6) > 0){
      const fact = price.length - 6
      const size = 25 - (1.5 * fact)
      return size + 'px'
    }
  }
}
