import { Routes, RouterModule } from '@angular/router';
import { Route } from '../core/route.service';
import { ErrorComponent } from './error.component';
import { extract } from '../core/i18n.service';
import { NgModule } from '@angular/core';

const route: Routes =[
    { path: '**', component: ErrorComponent, data: { titulo: extract('Error') } }
];

@NgModule({
    imports: [RouterModule.forChild(route)],
    exports: [RouterModule],
    providers: []
})
export class ErrorRoutingModule { }
