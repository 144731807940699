import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter, ViewChild, AfterViewInit, HostListener, ElementRef, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { RestService } from "../../shared/rest.service";
import { Procesed } from "../../shared/enum-procesed";
import { ItineraryService } from "../../shared/itinerary.service";
import { ModalDirective, BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { PaxListModalComponent } from "../../shared/pax-list-modal/pax-list-modal.component";
import { ModalsSearchComponent } from "../../shared/modals-search/modals-search.component";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { ResultsSearchService } from "../../home/results-search.service";
import { ModalsComponent } from "../../shared/modals/modals.component";
import { MatDialog } from "@angular/material/dialog";
import { MaterialModalConfirmComponent } from "../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../shared/material-modal-alert/material-modal-alert.component";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { MatCheckbox } from "@angular/material";
import { TitleFilterPipe } from '../../shared/titleFilter.pipe';
import { setting } from "../../../../setting";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AmenitiesFilter, FiltersHotels, FiltersRentacar, SliderShow } from "./filters-model";
import { PriceFilterPipe } from "app/shared/slider.pipe";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "usb-category-result",
  templateUrl: "./category-result.component.html",
  styleUrls: ["./category-result.component.scss"],
  providers: [
    TitleFilterPipe,
    PriceFilterPipe
  ]
})
export class CategoryResultComponent implements OnInit, AfterViewInit, OnDestroy {
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);

  @Input() payload: any;
  @Input() results: any;
  @Input() _paxAdults: any;
  @Input() _paxChildren: any;
  @Input() index: any;
  @Input() _pax: any;
  @Input() _payloadForm: any;
  @Input() show: any;
  @Input() _multiModal: any;
  @Input() modalRoomRef: any;
  @Input() origin: string;
  @Input() tabSelected: string;
  @Input() categoryName: string;
  @Input() text: string;
  @Input() resultPaged: any;
  @Input() _applyFilter: any;

  @Output() arrayOut = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() payload_ = new EventEmitter<any>();
  @Output() results_ = new EventEmitter<any>();
  @Output() _origin = new EventEmitter<any>();
  @Output() _filtersRentacar = new EventEmitter<any>();
  @Output() emitReSearch = new EventEmitter<any>();
  @Output() emitViewMap = new EventEmitter<any>();

  @ViewChild(ModalDirective) modalCompare: ModalDirective;
  @ViewChild("ModalRooms") public formModal: any;
  @ViewChild(PaxListModalComponent) modalPax: PaxListModalComponent;
  @ViewChild(ModalsSearchComponent) modalSearch: ModalsSearchComponent;
  @ViewChild("map") gmapElement: any;
  @ViewChild('filterContainer') filterContainer: ElementRef;
  // filtros
  @ViewChild("popularBreakfast") public popularBreakfast: MatCheckbox;
  @ViewChild("popularBreakfastNo") public popularBreakfastNo: MatCheckbox;

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (this.filterContainer && !this.filterContainer.nativeElement.contains(event.target)) {
      this.showFilters = false;
    }
  }

  coordinatesSidebar = {lat: 0, lng: 0};
  config = { ignoreBackdropClick: true, keyboard: false, class: 'modal-dialog-centered' };

  page: any;
  ModalRooms: any;
  modalRef: BsModalRef;
  _actualItinerary: any;
  category: string;
  loaded: boolean;
  _current = 1;
  _total: number;
  contentCollapsed = true;
  _currentGlobal = 1;
  _items: number;
  editable = false;
  itemToCompare: any = [];
  disbaledCompare: boolean = false;
  ori: any;
  emitLoad: boolean = false;
  orderFilter = 0;
  nightsTotalDiff:number = 0;
  cityNameForm:any;
  currentInfo:any;
  markerArray: google.maps.Marker[] = [];
  selectedStars: number = 0; // Número de estrellas seleccionadas
  booleanHideIcons:boolean = false;
  selectedAmenities: string[] = [];
  filterInitialized: boolean;
  resultTotal: number;
  priceResetLow:number = 0;
  priceResetHigh:number = 0;
  private hasExecutedElse: boolean = false;
  orderPriceby =   "priceLow";
  orderOptions = {
    priceLow: "Menor Precio",
    priceHigh: "Mayor Precio",
    masRelevantes: "Mas Relevantes",
  };
  showOrder = false;
  showFilters = false;

  isChecked = true;

  countFilterApply = 0;
  selectedFilters: any = [];
  resultReload: boolean = false;

  public getClientsSettingAppBooking: any;
  textBarCharge: string = 'Cargando más resultados...';


  // MISCELANEOS
  resultFilterCount = [];
  totalProduct = 0;
  filterLowPrice: any = 0;
  filterHighPrice: any = 0;
  initialLowPrice: any;
  initialHigthPrice: any;
  optionsSliderPrice: Options = SliderShow;
  bsModalRef?: BsModalRef;

  // HOTELES
  public amenitiesFilter: any[] = AmenitiesFilter;
  public filters: any = FiltersHotels;

  // RENT-A-CAR
  public  filtersRentacar: any = FiltersRentacar;

  viewMapsResult = false;
  viewCardResult = false;

  allpost;
  notEmptyPost = true;
  notscrolly = true;
  resultsFilter: any;
  resultsAux: any;
  init = false;
  nuevoObjeto = [];
  nuevoObjetoAux = [];
  private intervalId: any; // Variable para almacenar el ID del intervalo
  first_charge: boolean = true;
  categoryId: number;
  resultsReset: any;
  reloadAgain: boolean = true;
  private reloadAgainSubject = new BehaviorSubject<boolean>(this.reloadAgain);
  currencyItinerary: any = null;

  constructor(
    private modalService: BsModalService,
    public vcr: ViewContainerRef,
    private _restService: RestService,
    public serviceItinerary: ItineraryService,
    public userService: UserService,
    public resultsSearch: ResultsSearchService,
    private dialog: MatDialog,
    private titleFilter: TitleFilterPipe,
    private sliderFilter: PriceFilterPipe,
    private itineraryService: ItineraryService,
    private route: Router,
    private eRef: ElementRef,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.init = false;
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }

  ngOnInit() {
    console.log('payload ->', this.payload);

    console.log('this.results ->', this.results);

    if(this.origin === 'TOURS'){
      console.log('results tours ->', this.results);
      this.resultPaged = this.results;
    }

    this.cityNameForm = JSON.parse(localStorage.getItem('form')); // Carga el formulario del LocalStorage
    this.categoryId = this.payload.categoriesId[0]; // id de categoría
    this.reloadAgainSubject.subscribe((value) => {
      this.reloadAgain = value; // Actualiza reloadAgain con el valor emitido
      if (value) {
        this.initializeSearchReload();
      } else {
        clearInterval(this.intervalId); // Detén el intervalo si reloadAgain es false
      }
    });

    // Inicialización del valor de reloadAgain
    this.reloadAgain = true;
    this.reloadAgainSubject.next(this.reloadAgain);
    this.handleCategoryValidation(); // Validación según la categoría seleccionada
    if(this.categoryId === 6) {
      this.initializeRentacarFilters(); // Inicializa filtros de Rent-a-Car
    }
  }

  ngOnDestroy(): void {
    // Limpia el intervalo al destruir el componente
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

    /**
   * Inicializa la recarga periódica de búsqueda.
   *
   * Este método se encarga de ejecutar la función `reloadSearch` cada 5 segundos.
   *
   * @payload -> datos que se envían a la función de recarga.
   */
    initializeSearchReload(): void {
      this.intervalId = setInterval(() => {
        if (this.reloadAgain) {
          this.reloadSearch(this.payload, true);
        } else {
          clearInterval(this.intervalId);
        }
      }, 5000);
    }

  /**
   * Valida los resultados según la categoría seleccionada.
   *
   * Si la categoría es hotel (id 1), ajusta los resultados y el estado de visibilidad de íconos.
   * Si no, verifica si los resultados están vacíos y ajusta los íconos en consecuencia.
   *
   * @results -> arreglo de resultados que se muestra en pantalla.
   * @resultTotal -> cantidad total de resultados.
   * @booleanHideIcons -> estado para ocultar o mostrar íconos.
   */
  handleCategoryValidation(): void {
    if (this.categoryId === 1) {
     this.deleteResponsePropertiesHotel(); // elimina varias propiedades específicas del objeto `results`
      this.resultsAux = [...this.results];
      this.resultTotal = this.results.length;
      this.booleanHideIcons = false;

    } else {
      if (this.results.length == 0) {

        this.booleanHideIcons = true;
      }
      this.resultsAux = this.results;
      this.resultTotal = this.results.length;
    }
    console.log('category-results', this.results);

    if(this.results && this.results.noResultMessage) {
      const initialState: ModalOptions = {
        initialState: {
          data: 'No se encontraron datos, para los parámetros suministrados'
        }
      }
      const modalOptions = Object.assign({}, initialState, this.config);
      this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);

        const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
          if(result) {
            this.route.navigate(['/home']);
          }
        });
    } else {
      this.handleOrderByPriceLow(); // Ordenar por precio bajo si es necesario
      this.configureCategorySpecificActions(); // Acciones según la categoría (HOTEL o RENT-A-CAR)
      this.prepareResultsForFilter(); // Preparar resultados y aplicar filtros
      this.setItinerary(); // Configura el itinerario
      this.subscribeToItineraryService(); // Maneja eventos del servicio de itinerario
    }
  }

  /**
 * @method deleteResponsePropertiesHotel
 *
 * @description
 * Este método elimina varias propiedades específicas del objeto `results`. Las propiedades eliminadas son las que contienen datos relacionados con la búsqueda y respuesta del hotel, como la fecha de llegada, la categoría del hotel, el destino, y otros detalles adicionales.
 *
 * @returns {void}
 * No retorna ningún valor.
 *
 * @example
 * Llamada al método para eliminar propiedades innecesarias de un objeto de resultados
 * this.deleteResponsePropertiesHotel();
 *
 */
  deleteResponsePropertiesHotel(): void{
    delete this.results['arrivalDate'];
    // delete this.results['categoryName'];
    delete this.results['destination'];
    delete this.results['others'];
    delete this.results['payload'];
    delete this.results['qtyPax'];
    delete this.results['searchId'];
    delete this.results['form'];
  }

  /**
   * Inicializa los filtros de Rent-a-Car.
   *
   * Este método resetea las listas de selección de categoría y marca en la búsqueda de Rent-a-Car.
   *
   * @filtersRentacar.category.checkedArray -> lista de categorías seleccionadas.
   * @filtersRentacar.brand.checkedArray -> lista de marcas seleccionadas.
   */
  initializeRentacarFilters(): void {
    this.filtersRentacar.category.checkedArray = [];
    this.filtersRentacar.brand.checkedArray = [];
  }

  /**
   * Ordena los resultados por precio más bajo.
   *
   * Si la variable `orderPriceby` tiene el valor "priceLow", se llama al método `orderPriceLow`
   * para ordenar los resultados por precio ascendente.
   *
   * @orderPriceby -> parámetro que determina si los resultados deben ser ordenados por precio.
   */
  handleOrderByPriceLow(): void {
    if (this.orderPriceby == "priceLow") {
      this.orderPriceLow();
    }
  }

  /**
   * Configura acciones específicas basadas en la categoría seleccionada.
   *
   * Dependiendo del id de la categoría (`categoriesId`), se ejecutan funciones específicas:
   * - HOTEL (id 1): no realiza acciones adicionales.
   * - RENT-A-CAR (id 2): llama al método `setCarGroup`.
   *
   * @payload.categoriesId -> lista de ids de categorías.
   */
  configureCategorySpecificActions(): void {
    switch (this.categoryId) {
      case 1: // HOTEL
        break;
      case 2:  // RENT-A-CAR
        this.setCarGroup();
        break;
      default:
        break;
    }
  }

  /**
   * Prepara los resultados para aplicar filtros.
   *
   * Este método prepara los resultados, aplicando filtros y obteniendo el total de resultados.
   * Además, si los resultados no son nulos o indefinidos, se aplica un filtro adicional y se
   * obtienen los precios mínimos y máximos.
   *
   * @resultsFilter -> resultados filtrados para ser mostrados.
   * @total -> total de resultados.
   */
  prepareResultsForFilter(): void {
    this.resultsFilter = {...this.results};

    if (this.results !== undefined && this.results !== null) {
      this._total = this.results.length;
      this.resetFilter();
      this.searchFilter();
      this.searchPriceLowAndHigh();
      this.filterResults();
    }
  }

  /**
   * Suscribe el componente a eventos del servicio de itinerario.
   *
   * Este método maneja dos eventos emitidos por `itineraryService`:
   * - `modalClosed$`: actualiza el formulario de la ciudad cuando el modal se cierra.
   * - `currencySuccessEmit`: muestra un mensaje de éxito y actualiza las páginas cuando
   *   se establece una nueva moneda en el itinerario.
   * - `currencyRemoveEmit`: resetea los resultados cuando se remueve la moneda del itinerario.
   *
   * @itineraryService.modalClosed$ -> evento para actualizar el formulario de ciudad.
   * @itineraryService.currencySuccessEmit -> evento para cambiar la moneda del itinerario.
   * @itineraryService.currencyRemoveEmit -> evento para quitar la moneda del itinerario.
   */
  subscribeToItineraryService(): void {
    this.itineraryService.modalClosed$.subscribe(() => {
      console.log('actualizando nombre ciudad');
      this.cityNameForm = JSON.parse(localStorage.getItem('form'));
    });

    this.itineraryService.currencySuccessEmit.subscribe({
      next: (res: any) => {
        if (res) {
          this.currencyItinerary = res;
          this.hasExecutedElse = false;
          this.toastr.success(`Se ha establecido ${res} como moneda del itinerario activo`);
          this.getPagesNew(res);
        }
      }
    });

    this.itineraryService.currencyRemoveEmit.subscribe({
      next: (res: any) => {
        if (res) {
          this.hasExecutedElse = false;
          this.results = this.resultsAux;
          this.getPagesNew(null);
        }
      }
    });
  }



  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  textEmitter(text: any) {
    this.text = text;
    this.results = this.titleFilter.transform(this.resultsAux, this.text);
    this.filterResults();
    // this.getPagesNew();
  }

  stars() {
    const stars = [];
    for(let i = 0; i < 5; i++) {
      stars.push('/assets/img/app/star-icon.png');
    }
    return stars;
  }

  filterStar(selectedStars: number) {
    this.selectedStars = selectedStars;

    if (this.selectedStars >= 0) {
      this.results = this.resultsAux.filter(result => {
        if (result.additionalInfo && result.additionalInfo.starRating) {
          // Convierte la calificación de estrellas a número antes de comparar
          const starRating = parseFloat(result.additionalInfo.starRating);
          return starRating === this.selectedStars + 1;
        }
        return false;
      });
      this.filterResults();
      this.getPagesNew();
    } else {
      // Si no se ha seleccionado una clasificación de estrellas, muestra todos los resultados
      this.results = this.resultsAux;
    }

    // console.log('Filtered results:', this.results);
  }


  filterAmenities(event: boolean, amenitie: string): void {
    if (event) {
      // Agrega el amenitie a la lista de amenities seleccionados
      if (!this.selectedAmenities.includes(amenitie)) {
        this.selectedAmenities.push(amenitie);
      }
    } else {
      // Quita el amenitie de la lista de amenities seleccionados
      const index = this.selectedAmenities.indexOf(amenitie);
      if (index > -1) {
        this.selectedAmenities.splice(index, 1);
      }
    }

    this.results = this.resultsAux;

    if (this.selectedAmenities.length > 0) {
      this.results = this.results.filter(result => {
        if (result.additionalInfo && result.additionalInfo.amenities) {
          return this.selectedAmenities.every(amenity =>
            result.additionalInfo.amenities.some(a => a.name === amenity)
          );
        }
        return false;
      });
    }
    this.filterResults();
    this.getPagesNew();
  }


 // busca la cantidad  de veces que sale el valor en el json
  getQtyItem(value: any, type: any) {
      let item: any;
      item = this.results;
      switch (String(type)) {
        case 'name': // Marca
          return  item.filter(o => o.additionalInfo.carRental.optionRates.suplier.name === value).length;
        break;
        case 'category': // categoria
          return item.filter(o => o.additionalInfo.carRental.category === value).length;
        break;
        case 'type':  // Puerta
          return item.filter(o => o.additionalInfo.carRental.type === value).length;
        break;
        default:
        break;
      }
  }


   // busca la cantidad  de veces que sale el valor en el json
   getImgItem(value: any, type: any) {
    let item: any;
    let imag : any;
    item =  this.resultsFilter;
    switch (String(type)) {
      case 'name': // Marca
        return  item.filter(o => o.additionalInfo.carRental.optionRates.suplier.name === value);
      break;
      case 'category': // categoria
      // tslint:disable-next-line:max-line-length
          imag = item.filter((o) => o.additionalInfo.carRental.category=== value).map((res) => res.additionalInfo.images[0].image)[0];
         return imag;
      break;
      case 'type':  // Puerta
        return item.filter(o => o.additionalInfo.carRental.type === value).length;
      break;
      default:
      break;
    }
}

  searchFilter() {
    this.resultFilterCount = [];
    this.totalProduct = 0;
    this.resultFilterCount = this.results;
    if (this.resultFilterCount.length > 0) {
      this.resultFilterCount.map((item) => {
        switch (String(this.payload.categoriesId[0])) {
          case '1': //  HOTEL
          this.totalProduct++;
          break;
          case '2': //  RENT-A-CAR
              // brand
              if (item.additionalInfo.carRental.optionRates.suplier.name) {
                this.filtersRentacar.brand.brandArray.push(item.additionalInfo.carRental.optionRates.suplier.name);
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.brand.brandArray = this.filtersRentacar.brand.brandArray.filter((item_, index) => {
                  return this.filtersRentacar.brand.brandArray.indexOf(item_) === index;
                });
               }
              // category
              if (item.additionalInfo.carRental.category) {
                this.filtersRentacar.category.categoryArray.push(item.additionalInfo.carRental.category);
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.category.categoryArray = this.filtersRentacar.category.categoryArray.filter((item_, index) => {
                  return this.filtersRentacar.category.categoryArray.indexOf(item_) === index;
                });

              }
              // type
              if (item.additionalInfo.carRental.type) {
                this.filtersRentacar.type.typeArray.push(item.additionalInfo.carRental.type);
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.type.typeArray = this.filtersRentacar.type.typeArray.filter((item_, index) => {
                  return this.filtersRentacar.type.typeArray.indexOf(item_) === index;
                });
              }
              this.totalProduct++;

              // buscar cantidad de marca
              for (let index = 0; index <  this.filtersRentacar.brand.brandArray.length; index++) {
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.brand.brandCant[index] = this.getQtyItem(this.filtersRentacar.brand.brandArray[index], 'name');

              }

              // buscar cantidad de categoria
              for (let index = 0; index <  this.filtersRentacar.category.categoryArray.length; index++) {
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.category.categoryCant[index] = this.getQtyItem(this.filtersRentacar.category.categoryArray[index], 'category');
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.category.img[index]  = this.getImgItem(this.filtersRentacar.category.categoryArray[index], 'category');


              }

              // buscar cantidad de puerta
              for (let index = 0; index <  this.filtersRentacar.type.typeArray.length; index++) {
                // tslint:disable-next-line:max-line-length
                this.filtersRentacar.type.typeCant[index] = this.getQtyItem(this.filtersRentacar.type.typeArray[index], 'type');
              }
          break;
          default:
          break;
        }
      });
    }



  }

  filterArray(array: any, fields: any, value: any) {
      array = array.filter((item) => {
      const found = fields.every((field, index) => {
        if (field == 'name') {
          // tslint:disable-next-line:max-line-length
          return item.additionalInfo.carRental.optionRates.suplier[field] && item.additionalInfo.carRental.optionRates.suplier[field] === value[index];
        }

        if ((field == 'category' || field == 'type') )   {
          return item.additionalInfo.carRental[field] && item.additionalInfo.carRental[field] === value[index];
        }
      });
         return found;
     });
     return array;
  }

  resetFilter() {
    this.countFilterApply = 0;
    this.selectedStars = 0;
    this.selectedFilters = [];
    this.resultFilterCount = [];
    this.selectedAmenities = [];
    this.filtersRentacar.category.checkedArray = [];
    this.filtersRentacar.brand.checkedArray = [];
    this.filtersRentacar.type.checkedArray = [];
  }



  //// parte Funcionar
  filterResults() {
     this.filterInitialized = true;
     switch (String(this.payload.categoriesId[0])) {
       case '1': //  HOTEL
                    /// aplicar filtros
              if (this.orderPriceby == "priceLow") {
                  this.orderPriceLow();
              }

              if (this.orderPriceby == "priceHigh") {
                this.orderPriceHigh();
              }
              this.getPagesNew();
              this.searchFilter();
       break;
       case '2':  //  RENT-A-CAR
              if (this.selectedFilters.length > 0 ) {
                let results = [];
                  this.selectedFilters.forEach(filter_ => {
                      results = [];
                      results = this.filterArray(this.results, [filter_.type], [filter_.value]);
                      if (results != undefined ) {
                        this.results = results;
                      }
                  });
                }
                this.mapMarker();
                this.getPagesNew();
                this.searchFilter();
                this._filtersRentacar.emit(this.filtersRentacar);
       break;
       default:
       break;
     }
  }

  applyFilter(event: any, value: any, type: any, _id: any) {
    let selectedFiltersAux = [];
    let filter: any;
    const id = type + '_' + _id;
    if (event.target) {
      switch (String(this.payload.categoriesId[0])) {
        case '1': // HOTEL
          if (event.target.checked) {
            this.countFilterApply++;
          } else {
            if (this.countFilterApply >= 0) {
              this.countFilterApply--;
              if (this.countFilterApply < 0) {
                this.countFilterApply = 0;
              }
            }
          }


        break;
        case '2': // RENT-A-CAR
          if ((event.target.checked))  {
              filter = {
                order: this.orderFilter + 1,
                id: id,
                type: type,
                value: value
              };
              this.selectedFilters.push(filter);

          } else {
            selectedFiltersAux =  this.selectedFilters.filter((element) => element.id !== id);
            this.selectedFilters =  selectedFiltersAux;
          }
        break;
        default:
        break;
      }

  } else {

    switch (String(this.payload.categoriesId[0])) {
      case '1':
        if(event.pointerType === 0) {
          this.filters.lowPrice = event.value;
        } else {
          this.filters.HighPrice = event.highValue;
        }

        let result: any;
        if (event != "") {
            if (event != "") {
              this.results = this.sliderFilter.transform(this.resultsAux, this.filterLowPrice, this.filterHighPrice);
              // Añadir la propiedad categoryName directamente al array como clave dinámica
              (this.results as any).categoryName = this.resultsFilter.categoryName;
              this.filterResults();
            }
        }
      break;
      default:
        this.filterResults();
        break;
    }

  }





  }

  clearFilter() {
    this.results = [];
    // Convertir el objeto en un array con la estructura que deseas
    this.results = this.resultsAux;
    this.resetFilter();
    this.searchFilter();
    this.searchPriceLowAndHigh();
    this.filterResults();
    this.selectedStars = 0;
    this.text = '';
    this.resetPriceSlider();
    // this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
    // this.filterLowPrice =  this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
  }

  orderPriceLow() {
    switch (this.categoryId) {
      case 1: //  HOTEL
     // let qtyRomms = this.payload?.others?.hotels?.roomConfiguration.length;
      this.results.sort((a, b) => {
        const aRooms = a?.additionalInfo?.rooms ?? [];
        const bRooms = b?.additionalInfo?.rooms ?? [];

        const aMinPrice = aRooms.length
          ? Math.min.apply(
              null,
              aRooms
                .sort((roomA, roomB) => roomA.room.optionNightsNetTotalProf - roomB.room.optionNightsNetTotalProf)
                .map(room => room.room.optionNightsNetTotalProf)
            )
          : Infinity; // Si no hay rooms, devolvemos Infinity para que se vaya al final de la lista.

        const bMinPrice = bRooms.length
          ? Math.min.apply(
              null,
              bRooms
                .sort((roomA, roomB) => roomA.room.optionNightsNetTotalProf - roomB.room.optionNightsNetTotalProf)
                .map(room => room.room.optionNightsNetTotalProf)
            )
          : Infinity; // Si no hay rooms, devolvemos Infinity para que se vaya al final de la lista.

        return aMinPrice - bMinPrice;
      });

      break;
      case 2:  //  RENT-A-CAR
      this.results.sort(
        (a, b) =>
             a.additionalInfo.carRental.netPriceProf - b.additionalInfo.carRental.netPriceProf
         );
      break;
      case 6: //  TOUR
        this.results.sort(
          (a, b) =>
            Math.min.apply(
              null,
              a.additionalInfo.tours.options
                .sort(function (a, b) {
                  return (a.totalPriceProf) -  (b.totalPriceProf);
                })
                .map((resultObj) => resultObj.totalPriceProf)
            ) -
            Math.min.apply(
              null,
              b.additionalInfo.tours.options
                .sort(function (a, b) {
                  return (a.totalPriceProf) -  (b.totalPriceProf);
                })
                .map((resultObj) => resultObj.totalPriceProf)
            )
        );

      break;
      default:
      break;
   }
   this.page = 1;

  }




  orderPriceHigh() {
    switch (String(this.payload.categoriesId[0])) {
      case '1': //  HOTEL
      this.results.sort((a, b) => {
        const aRooms = a?.additionalInfo?.rooms ?? [];
        const bRooms = b?.additionalInfo?.rooms ?? [];

        const aMinPrice = aRooms.length
          ? Math.min.apply(
              null,
              aRooms
                .sort((roomA, roomB) => roomA.room.optionNightsNetTotalProf - roomB.room.optionNightsNetTotalProf)
                .map(room => room.room.optionNightsNetTotalProf)
            )
          : Infinity; // Si no hay rooms, devolvemos Infinity para que se vaya al final de la lista.

        const bMinPrice = bRooms.length
          ? Math.min.apply(
              null,
              bRooms
                .sort((roomA, roomB) => roomA.room.optionNightsNetTotalProf - roomB.room.optionNightsNetTotalProf)
                .map(room => room.room.optionNightsNetTotalProf)
            )
          : Infinity; // Si no hay rooms, devolvemos Infinity para que se vaya al final de la lista.

        return bMinPrice - aMinPrice;
      });
      break;
      case '2':  //  RENT-A-CAR
        this.results.reverse(
          (a, b) =>
            b.additionalInfo.carRental.netPriceProf -
            a.additionalInfo.carRental.netPriceProf
        );

      break;
      case '6': //  TOUR      this.results= this.sortRecursive(this.results);
       this.results.reverse(
          (a, b) =>
          Math.max.apply(
            null,
              a.additionalInfo.tours.options
                .reverse(function (a, b) {
                  return a.totalPriceProf -  b.totalPriceProf;
                })
                .map((resultObj) => resultObj.totalPriceProf)
            ) -
            Math.max.apply(
              null,
              b.additionalInfo.tours.options
                .reverse(function (a, b) {
                  return a.totalPriceProf - b.totalPriceProf;
                })
                .map((resultObj) => resultObj.totalPriceProf)
            )
        );

      break;

      default:
      break;
    }
    this.page = 1;


  }
  searchPriceLowAndHigh() {
    let resulPriceLow = [];
    let resulPriceHigh = [];
    if (this.results.length > 0) {
      switch (String(this.payload.categoriesId[0])) {
        case '1': //  HOTEL
        resulPriceLow = this.results.map((data: any) => {

            if (data.additionalInfo) {
              // Captura el valor mínimo de netPriceProf
              const lowestPrice = Math.min.apply(
                null,
                data.additionalInfo.rooms.map((room: any) => {
                  // Revisa que room?.room?.netPriceProf sea un número válido
                  return room?.room?.optionNightsNetTotalProf || Infinity;
                })
              );

              // Si se encontró algún precio válido, devolverlo
              return lowestPrice !== Infinity ? lowestPrice : undefined;
            }
            return undefined; // Si no hay additionalInfo, devolver undefined
        });

          // Luego puedes filtrar los valores undefined y obtener el mínimo general
          // Aplanar el array manualmente
          const flattenedPrices = resulPriceLow.reduce((acc: any[], val: any[]) => acc.concat(val), []);
          // Filtrar los valores undefined
          const validPrices = flattenedPrices.filter((price: any) => price !== undefined);
          // Obtener el precio mínimo
          const minPrice = Math.min(...validPrices);
          resulPriceLow = [minPrice];


          resulPriceHigh = this.results.map((data: any) => {

              if (data && data.additionalInfo) {
                // Captura el valor máximo de netPriceProf
                const highestPrice = Math.max.apply(
                  null,
                  data.additionalInfo.rooms.map((room: any) => {
                    // Revisa que room?.room?.netPriceProf sea un número válido
                    return room?.room?.optionNightsNetTotalProf || -Infinity;
                  })
                );

                // Si se encontró algún precio válido, devolverlo
                return highestPrice !== -Infinity ? highestPrice : undefined;
              }
              return undefined; // Si no hay additionalInfo, devolver undefined

          });

          // Aplanar el array manualmente
          const flattenedPricesHigh = resulPriceHigh.reduce((acc: any[], val: any[]) => acc.concat(val), []);
          // Filtrar los valores undefined
          const validHighPrices = flattenedPricesHigh.filter((price: any) => price !== undefined);

          // Obtener el precio máximo
          const maxPrice = Math.max(...validHighPrices);
          resulPriceHigh = [maxPrice];


        this.filterLowPrice = Math.floor(Math.min.apply(null, resulPriceLow));
        this.filterHighPrice = Math.floor(Math.max.apply(null, resulPriceHigh));
        break;
        case '6': //TOURS
        this.filterLowPrice = this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
        this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
        break;
        case '2': //  RENT-A-CAR
            // tslint:disable-next-line:max-line-length
            this.filterLowPrice = this.results.map(function(el: any) {return el.additionalInfo.carRental.netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
            // tslint:disable-next-line:max-line-length
            this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.carRental.netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
          break;
        default:
        break;

      }
      this.optionsSliderPrice.floor = this.filterLowPrice;
      this.optionsSliderPrice.ceil = this.filterHighPrice;

    }
  }

  setCarGroup() {
    let i = 0;
    this.results =  this.results.filter((data, index, j) =>
          index === j.findIndex((t) => (t.title == data.title))
    );
  }
indexResolveResult = 0;
  getPagesNew(currency?: any, reload?: boolean) {
    this.resultPaged = [];
    if(currency !== null) {
      if(!currency) {
        currency = this.userService?.itineraryActive[0]?.currency;
      }

      // if (currency) {
      //   // Filtrar los objetos que cumplen con la condición
      //   this.results = this.results.filter((item: any) => {
      //     // Asegúrate de que `item` sea un array antes de hacer un `filter`
      //     console.log(item)
      //     if(item && item.additionalInfo) {
      //       return item.some((element: any) => {
      //         // Comprobar si el elemento tiene la propiedad adicional y si el currency coincide
      //         const currencyItem = element?.additionalInfo?.rooms?.[0]?.room?.pricing?.currency;
      //         return currencyItem === currency;
      //       });
      //     }
      //   });
      // }

    }
    if (this.results.length > 0) {
      const searchID =
        this.results["searchId"] === null || this.results["searchId"] === undefined
          ? this.results[0]["searchId"]
          : this.results["searchId"];
      if(this.origin === 'HOTELS') {
        // if(this.first_charge === false) {
          this.resultPaged = [this.results];

        // } else {
          // this.resultPaged = this.results;
        //   this.first_charge = false;
        // }
        this._items = this.results.length;
      } else {
        this.resultPaged = this.results;
        //  this._colocarSearchIdEnlosHoteles(this.results);
        this._items = this.results.length;
      }
      if (this.index == 0) {
        this._emitLoading(false);
      }
      if(reload === true) {
        this.handleCategoryValidation(); // Validación según la categoría seleccionada
      }
      this._total = this.resultPaged.length;
      if(reload === false) {
        this._setArrivalAndDepartureDate(this.resultPaged, this.results.form);
        this.serviceItinerary.sendMessageSubscriptorItemSelected(this.results.destination, this.categoryName);
      }

    } else {
      if(this.origin == 'RENT-A-CAR'){
        this.filterInitialized = false;
      }
      if(this.filterInitialized === false) {
        const initialState: ModalOptions = {
          initialState: {
            data: 'No se encontraron datos, para los parámetros suministrados'
          }
        }
        const modalOptions = Object.assign({}, initialState, this.config);
        this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);

          const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
            if(result) {
              this.route.navigate(['/home']);
            }
          });
      } else {
        if(this.hasExecutedElse === false) {
          this.hasExecutedElse = true;
          const initialState: ModalOptions = {
            initialState: {
              data: 'No se encontraron datos, para los parámetros suministrados'
            }
          }
          const modalOptions = Object.assign({}, initialState, this.config);
          this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);
          this.clearFilter();

         }

      }
    }
  }

  capitalizeFirstLetter(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  toggleCollapse() {
    this.contentCollapsed = !this.contentCollapsed;
  }

  _onPayload(payload: any) {
    this.payload_.emit(payload);
  }

  _onResults(results: any) {
    this.results_.emit(results);
  }

  _onLoading(isLoading: boolean) {
    this.isLoading.emit(isLoading);
  }

  _onOrigin(origin: any) {
    this._origin.emit(origin);
  }

  onPageChange(page: number) {
    this.page = page;
    window.scrollTo(0, 0);
  }


  openModal(result: any) {
    var dialogRef = this.dialog.open(ModalsComponent, {
      height: "680px",
      width: "900px",
      data: {
        modalRoomRef: this.modalRef,
        product: result,
        productQty: this.payload.qtyProduct,
        form: this.results.form,
        origin: "HOTELS",
        arrayResults: this.results,
      },
      //disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {

      // if(result){

      // }else{

      // }
    });
  }

  moveMapToCoordinates(hotelSelected) {
    // console.log(hotelSelected);

    // let icon = `<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
    // <image href="assets/logo.png" height="200" width="200"/>
    // </svg>`;

    // //se inicializa el mapa con zoom 4
    // let geocoder: any;
    // let mapa: any;
    // let infowindow = null;
    // let currentInfoWindows = null;
    // let mapOptions = {
    // center: new google.maps.LatLng(28.3256749, -81.51555309999999),
    // zoom: 4,
    // mapTypeId: google.maps.MapTypeId.ROADMAP,
    // zoomControl: true,
    // };

    // mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    // // const resultados = await this.resultPaged;
    // const resultados = this.results;


    // for (let i = 0; i < resultados.length; i++) {
    //   const item = hotelSelected;
    //   const data = {
    //   image: item.additionalInfo.images.length > 0 ? item.additionalInfo.images[0].image : "",
    //   title: item.title ? item.title : "",
    //   rooms: item.additionalInfo.rooms ? item.additionalInfo.rooms : null,
    //   netPriceProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPriceProf : "",
    //   netPrice: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPrice : "",
    //   roomPackageRefPriceProf: item.additionalInfo.roomsPackage ? item.additionalInfo.roomsPackage[0].netPriceProf : "",
    //   optionNightsNetTotal: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotal : "",
    //   // tslint:disable-next-line:max-line-length
    //   optionNightsNetTotalProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotalProf : "",
    //   blickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].blickoins : "",
    //   creditBlickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].creditBlickoins : "",
    //   percentage: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].percentage : "",
    //   latlng: item.coordinates ? item.coordinates : "",

    // };


    //   geocoder = new google.maps.Geocoder();
    //   const title = item.title;
    //   let addIcon = "favorite_border";
    //   // (click)="openModal(${item})"
    //   const infowindow = new google.maps.InfoWindow({
    //       content: `
    //       <div id="hotel-result-component" class="d-flex justify-content-between" style="width: 30rem;height: 12em; background: white !important;">
    //         <div class="d-flex container-img" style="width:40% !important; overflow: hidden">
    //           <img class="img-responsive" style="width: 80%;height: 100% !important; object-fit: contain" src="${
    //           data?.image
    //           }" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
    //         </div>
    //         <div class="d-flex flex-column container-detalles p-3" style="width: 60% !important;">
    //           <div class="d-flex justify-content-end">
    //             <span class="material-icons add-icon add_circle" id="fav${i}"></span>
    //             <span class="material-icons add-icon cancel" id="close"></span>

    //         </div>
    //          <div class="d-flex" style="max-height: 50px; overflow: hidden;margin-top: .7em;">
    //           <p class="m-0"
    //             style="font-size: 18px; letter-spacing: 0px; line-height: 1; color: #272727; font-weight: 600; font-family: "Poppins", sans-serif;"
    //             title="${data.title}">${data.title}</p>
    //         </div>
    //         <div class="d-flex flex-column">
    //           <div class="d-flex">
    //             <p class="m-0" style="font-size: 20px; letter-spacing: -1px; line-height: 1; color: #272727; font-weight: 600; font-family: "Poppins", sans-serif;">USD <span>${
    //               data?.netPriceProf ? data.netPriceProf : ""
    //             }</span></p>
    //         </div>
    //         <div>
    //          <p style="font-size: 10px; font-weight: 500;margin-bottom:0;">Estadia total</p>
    //         </div>

    //         <!-- <div class="d-flex flex-column">
    //           <p class="m-0" style="font-size:10px; color: red;  font-weight: bold;">P. Costo:&nbsp;${data.netPrice}</p>
    //           <p class="m-0" style="font-size: 13px !important; color: #272727; font-weight: 600; font-family: "Poppins", sans-serif;">P/Night</p>
    //         </div> -->
    //         </div>
    //           <div class="d-flex justify-content-between margin-bottom: 5px;">
    //           <div class="d-flex align-items-middle">
    //             <i class="icon icon-medal mr-1"></i>
    //             <span class="font-point d-flex align-items-center">
    //               ${data.blickoins ? data.blickoins : "X"}&nbsp;Bks
    //             </span>
    //             <span class="font-point d-flex align-items-center">
    //               <p class="p-night m-0 pl-2">$&nbsp;${data.blickoins ? data.creditBlickoins : "X"}</p>
    //               </span>
    //           </div>
    //           <div class="d-flex flex-row">
    //           <!--  <span class="font-desc" style=" font-family: 'Lato', sans-serif  !important;">15</span>
    //             <i class="icon icon-descuento"></i> -->
    //           </div>
    //           </div>
    //         </div>
    //       </div>

    //   `,
    //   });

    //   const latlng = {
    //   lat: parseFloat(data.latlng.latitude),
    //   lng: parseFloat(data.latlng.longitude),
    //   };

    //   // setTimeout( () => {
    //   geocoder.geocode({ location: latlng }).then((response) => {
    //     if (response.results[0]) {
    //     const marker = new google.maps.Marker({
    //       position: latlng,
    //       map: mapa,
    //       title: title,
    //       draggable: false,
    //       label: {
    //         text: "USD " + data.netPriceProf.toString(),
    //         className: "tooltip-one",
    //       },
    //       icon: " ",
    //   });

    //   this.markerArray.push(marker);

    //   if (hotelSelected) {
    //     // Cerrar la ventana de información si se hace clic afuera del modal
    //     const closeInfoWindowOnOutsideClick = (event: any) => {
    //       const infowindowContent = document.querySelector('.gm-style-iw') as HTMLElement;
    //       if (infowindowContent && !infowindowContent.contains(event.target)) {
    //         infowindow.close();
    //         document.removeEventListener('click', closeInfoWindowOnOutsideClick);
    //       }
    //     };

    //     // Agregar evento de clic al documento para cerrar la ventana de información al hacer clic afuera
    //     document.addEventListener('click', closeInfoWindowOnOutsideClick);

    //     if (currentInfoWindows) {
    //       currentInfoWindows.close();

    //     }

    //     infowindow.open(mapa, marker);
    //     currentInfoWindows = infowindow;
    //   }


    //   marker.addListener('click', () =>{

    //     if(currentInfoWindows){
    //     currentInfoWindows.close()
    //     }

    //     infowindow.open(mapa, marker);
    //     currentInfoWindows = infowindow;
    //   });

    //   google.maps.event.addListener(infowindow, "domready", () => {
    //     const el = document.getElementById("fav" + i);
    //     const close = document.getElementById("close");
    //     el.addEventListener("click", (event) =>{
    //       this.openModal(item);
    //   })

    //   close.addEventListener('click', (event) =>{
    //     infowindow.close();
    //   })

    //   });

    //   google.maps.event.trigger(mapa, "resize");
    //     mapa.panTo(marker.getPosition());
    //     mapa.setZoom(10);
    //     } else {
    //     console.log("No se obtuvo la ubicacion del mapa: " + status);
    //     }
    //     });
    //   // }, 2000 * i);

    //          // Abre la ventana de información cuando se carga hotelSelected


    //   }
    //   mapa.set("parent", this);



}

ngAfterViewInit() {
  this.mapMarker();
}


async mapMarker() {
  // if (!this.gmapElement) {
  //   return;
  // }

  // let icon = `<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
  //               <image href="assets/logo.png" height="200" width="200"/>
  //             </svg>`;

  // // Inicializa el mapa con zoom 4
  // let geocoder: any;
  // let mapa: any;
  // let infowindow = null;
  // let currentInfoWindows = null;
  // let mapOptions = {
  //   center: new google.maps.LatLng(28.3256749, -81.51555309999999),
  //   zoom: 4,
  //   mapTypeId: google.maps.MapTypeId.ROADMAP,
  //   zoomControl: true,
  // };

  // mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

  // const resultados = await this.results;

  // for (let i = 0; i < resultados.length; i++) {
  //   const item = resultados[i];
  //   const data = {
  //     image: item.additionalInfo.images.length > 0 ? item.additionalInfo.images[0].image : "",
  //     title: item.title ? item.title : "",
  //     rooms: item.additionalInfo.rooms ? item.additionalInfo.rooms : null,
  //     netPriceProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPriceProf : "",
  //     netPrice: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPrice : "",
  //     roomPackageRefPriceProf: item.additionalInfo.roomsPackage ? item.additionalInfo.roomsPackage[0].netPriceProf : "",
  //     optionNightsNetTotal: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotal : "",
  //     optionNightsNetTotalProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotalProf : "",
  //     blickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].blickoins : "",
  //     creditBlickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].creditBlickoins : "",
  //     percentage: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].percentage : "",
  //     latlng: item.coordinates ? item.coordinates : "",
  //   };

  //   geocoder = new google.maps.Geocoder();
  //   const title = item.title;
  //   let addIcon = "favorite_border";
  //   const infowindow = new google.maps.InfoWindow({
  //     content: `
  //     <div id="hotel-result-component" class="d-flex justify-content-between" style="width: 30rem;height: 12em; background: white !important;">
  //       <div class="d-flex container-img" style="width:40% !important; overflow: hidden">
  //         <img class="img-responsive" style="width: 100%;height: 100% !important;" src="${data?.image}" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
  //       </div>
  //       <div class="d-flex flex-column container-detalles p-3" style="width: 60% !important;">
  //         <div class="d-flex justify-content-end">
  //           <span class="material-icons add-icon add_circle" id="fav${i}"></span>
  //           <span class="material-icons add-icon cancel" id="close"></span>
  //         </div>
  //         <div class="d-flex" style="max-height: 50px; overflow: hidden;margin-top: .7em;">
  //           <p class="m-0" style="font-size: 18px; letter-spacing: 0px; line-height: 1; color: #272727; font-weight: 600; font-family: 'Poppins', sans-serif;" title="${data.title}">${data.title}</p>
  //         </div>
  //         <div class="d-flex flex-column">
  //           <div class="d-flex">
  //             <p class="m-0" style="font-size: 20px; letter-spacing: -1px; line-height: 1; color: #272727; font-weight: 600; font-family: 'Poppins', sans-serif;">USD <span>${data?.netPriceProf ? data.netPriceProf : ""}</span></p>
  //           </div>
  //           <div>
  //             <p style="font-size: 10px; font-weight: 500;margin-bottom:0;">Estadia total</p>
  //           </div>
  //         </div>
  //         <div class="d-flex justify-content-between margin-bottom: 5px;">
  //           <div class="d-flex align-items-middle">
  //             <i class="icon icon-medal mr-1"></i>
  //             <span class="font-point d-flex align-items-center">${data.blickoins ? data.blickoins : "X"}&nbsp;Bks</span>
  //             <span class="font-point d-flex align-items-center">
  //               <p class="p-night m-0 pl-2">$&nbsp;${data.blickoins ? data.creditBlickoins : "X"}</p>
  //             </span>
  //           </div>
  //           <div class="d-flex flex-row"></div>
  //         </div>
  //       </div>
  //     </div>`,
  //   });

  //   const latitude = parseFloat(data.latlng.latitude);
  //   const longitude = parseFloat(data.latlng.longitude);

  //   if (!isNaN(latitude) && !isNaN(longitude)) {
  //     const latlng = {
  //       lat: latitude,
  //       lng: longitude,
  //     };

  //     geocoder.geocode({ location: latlng }).then((response) => {
  //       if (response.results[0]) {
  //         const marker = new google.maps.Marker({
  //           position: latlng,
  //           map: mapa,
  //           title: title,
  //           draggable: false,
  //           label: {
  //             text: "USD " + data.netPriceProf.toString(),
  //             className: "tooltip-one",
  //           },
  //           icon: " ",
  //         });

  //         this.markerArray.push(marker);

  //         marker.addListener('click', () => {
  //           if (currentInfoWindows) {
  //             currentInfoWindows.close();
  //           }

  //           infowindow.open(mapa, marker);
  //           currentInfoWindows = infowindow;
  //         });

  //         google.maps.event.addListener(infowindow, "domready", () => {
  //           const el = document.getElementById("fav" + i);
  //           const close = document.getElementById("close");
  //           el.addEventListener("click", (event) => {
  //             this.openModal(item);
  //           });

  //           close.addEventListener('click', (event) => {
  //             infowindow.close();
  //           });
  //         });

  //         google.maps.event.trigger(mapa, "resize");
  //         mapa.panTo(marker.getPosition());
  //         mapa.setZoom(10);
  //       } else {
  //         console.log("No se obtuvo la ubicacion del mapa: " + status);
  //       }
  //     });
  //   } else {
  //     // console.error("Invalid latlng:", latitude, longitude);
  //   }
  // }
  // mapa.set("parent", this);
}


  _getCitys(param: any) {
 /*   this._restService.doGet("api", "/searchlocations/" + param).subscribe(
      (res) => {
        for (let index = 0; index < res["Cities"].length; index++) {
          if (res["Cities"][index]["cityIataCode"] === param) {
            this.ori = res["Cities"][index]["cityName"];
          }
        }
      },
      (Error) => {
        console.log("Error", Error);
      }
    );*/
  }

  setItinerary() {
    if (this.userService.itineraryActive.length > 0) {
      this._actualItinerary = this.userService.itineraryActive[0];
    } else {
      let itinerary$ = this.serviceItinerary.getItinerary$();
      itinerary$.subscribe(
        (data: any) => {
          if (data !== undefined && data !== null) {
            if (data[0] !== undefined) {
              this._actualItinerary = data[0];
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
        }
      );
    }
  }

  _emitViewMap(event: boolean) {
    this.emitViewMap.emit(event);
  }

  _emitReSearch(event: Event) {
    this.emitReSearch.emit(event);
  }

  reloadSearch(payload: any, reload?: boolean) {
    console.log(payload)


    if(reload === false) {
      this._onLoading(true)
      this.contentCollapsed = true;
      this.results = [];
      this.resultPaged = [];

      this._onPayload(payload);
      this.payload = payload;
      this._emitLoading(true);
      this.modalPax._initLoadData(payload);
    }

    if (this.modalSearch && reload === false) {
      this.modalSearch._initLoadData(payload);
    }

    if(reload === true) {
      this.resultReload = true;
    }
    this._restService.doPost("app", "search", payload).subscribe({
     next: (response: any) => {


        console.log(response);
        if (response !== null && response.results !== null) {
          //  this._colocarSearchIdEnlosHoteles(response);
          //  for (let i = 0; i < response.results.length; i++) {
          //    this.results[i] = response.results[i];
          //   }
          this.results = response.results;

           // Ordenar el array en base a optionNightsNetTotalProf

            if(this.payload.categoriesId[0] === 1) {
              this.results.sort((a, b) => {
                const valueA = a.additionalInfo.rooms[0]?.optionNightsNetTotalProf || 0;
                const valueB = b.additionalInfo.rooms[0]?.optionNightsNetTotalProf || 0;

                return valueA - valueB;
              });
            }

          if (this.results.length > 0) {
            this.getPagesNew(this.currencyItinerary, reload);
            if(reload === false) {
              this._emitLoading(false);
              this.itineraryService.notifyModalClosed(false);
            }
              this.resultReload = false;
              if(reload && response.results.length  === this.results.length) {
                this.reloadAgain = false;
                this.reloadAgainSubject.next(this.reloadAgain);
                this.textBarCharge = 'Carga de resultados completada';
              }
          } else {
            this.resultPaged.length = 0;
            const initialState: ModalOptions = {
              initialState: {
                message:'No se encontraron Resultados'
              }
            };
            this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
          if(reload === false) {
            this._emitLoading(false);

            const initialState: ModalOptions = {
              initialState: {
                message: 'Ocurrió un error al intentar encontrar productos. Inténtalo de nuevo'
              }
            };

            this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
            const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
                if (result === true) {
                  this.reloadSearch(payload);
                } else {
                  this.modalRef.hide();
                  this.route.navigate(["/home"]);
                }
            });
          } else {
            this.textBarCharge = 'Carga de resultados completada';
            this.reloadAgainSubject.next(this.reloadAgain);
            this.reloadAgain = false;
          }
        }
    });
  }

  _colocarSearchIdEnlosHoteles(response: any) {
    response.results.forEach((item) => {
      item.searchId = response.searchId;
    });
  }
  setQtyChildren(qty: any) {
    this.editable = true;
  }
  getQtyChildren() {
    let children = 0;
    const rooms = this.payload.others.hotels.roomConfiguration;
    rooms.forEach((room) => {
      children += room["children"].length;
    });
    return children;
  }
  /**
   *
   * @param searchResponse
   */
  setCategory(searchResponse: string) {
    switch (searchResponse) {
      case Procesed.TOURS:
        this.category = "Tours";
        break;
      case Procesed.HOTELS:
        this.category = "Hotels";
        break;
      case Procesed.TRANSPORTS:
        this.category = "Transports";
        break;
      case Procesed.INSURANCE:
        this.category = "Insurance";
        break;
    }
  }

  _emitLoading(isLoading: boolean) {
    this.loaded = isLoading;
    this.isLoading.emit(isLoading);
  }

  _setArrivalAndDepartureDate(response: any, form: any) {
    if (form !== undefined) {
      /*const arrival = form.value['arrivalDate'] !== undefined ? form.value['arrivalDate'] : form.value['firstArrivalDate'];
      const departure = form.value['arrivalDate'] !== undefined ? form.value['departureDate'] : form.value['secondArrivalDate'];
      response.forEach(item => {
        item.dateIni = moment(arrival.toString().split(',')[0]).format('YYYY-MM-DD');
        item.dateEnd = moment(departure.toString().split(',')[0]).format('YYYY-MM-DD');
      }); */
    }
    this.arrayOut.emit(this.resultPaged);
  }

  closeCollapse(close: boolean) {
    this.contentCollapsed = close;
  }

  getUrlImagePark(title: string) {
    let url = "assets/img/app/parque-tematico.jpg"; //default
    if (title !== undefined && title !== null) {
      title = title.toUpperCase();
      if (title.includes("UNIVERSAL")) {
        return (url = "assets/img/app/universal-ptheme.jpg");
      } else if (title.includes("DISNEY")) {
        return (url = "assets/img/app/walt-disney-world.jpg");
      } else if (title.includes("SEAWORLD")) {
        return (url = "assets/img/app/seaword-park-theme.jpg");
      } else if (title.includes("DISCOVERY COVE")) {
        return (url = "assets/img/app/pthemes-discovery-cove.png");
      } else if (title.includes("LEGOLAND")) {
        return (url = "assets/img/app/legoland-florida.jpg");
      } else if (title.includes("VOLCANO BAY")) {
        return (url = "assets/img/app/themeparks-volcanobay.png");
      } else if (title.includes("ISLAND")) {
        return (url = "assets/img/app/island-aventure-themeparks.png");
      }
      return url;
    }
  }

  toCompare() {
    if (this.itemToCompare.length < 2 || this.itemToCompare.length > 3) {
      //this.toastr.error('You can only make a comparison with 2 or 3 articles');
    } else {
      //abrir modal
      this.modalCompare.show();
    }
  }

  addToCompare(item: any) {
    if (item.action == "add") {
      if (this.itemToCompare.length <= 3) {
        this.itemToCompare.push(item.item);
        if (this.itemToCompare.length == 3) {
          this.disbaledCompare = true;
        }
      }
    } else {
      let index = this.itemToCompare.findIndex((val) => val.title == item.item.title);
      this.itemToCompare.splice(index, 1);
      this.disbaledCompare = false;
    }
  }

  modalCompareClose(event: any) {
    this.modalCompare.hide();
    //this.toastr.success('Product added successfully to your itinerary');
  }

  public changePage(event: any) {
    this._current = event;
  }

  public more() {
    this._currentGlobal++;
  }

  // tslint:disable-next-line:member-ordering
  setProfitability(results: any) {
    results.forEach((item) => {
      switch (this.origin) {
        case "HOTELS":
          // Si es room
          if (item.additionalInfo.rooms) {
            // tslint:disable-next-line:max-line-length
            this.serviceItinerary
              .getProfitability(item.additionalInfo.rooms[0].netPrice, item.categoryId, item.additionalInfo.rooms[0].providerId)
              .subscribe((data: any) => {
                item.additionalInfo.rooms[0].netPriceProf = parseFloat(data["totalPrice"]);
                //  item.additionalInfo.rooms[0].refPriceFlag =  parseFloat(data['totalPrice']);
                item.additionalInfo.rooms[0].percentage = parseFloat(data["percentage"]);
                // item.additionalInfo.rooms[0].optionNightsNetTotal =  parseFloat(data['netprice']);
                // item.additionalInfo.rooms[0].percentage = parseFloat(data['percentage']);
                item.additionalInfo.rooms[0].blickoins = parseFloat(data["blickoins"]);
                item.additionalInfo.rooms[0].creditBlickoins = parseFloat(data["creditBlickoins"]);
              });
            /*  item.additionalInfo.rooms.forEach(room => {
              this.serviceItinerary.getProfitability(room.optionNightsNetTotal, item.categoryId, room.providerId)
                .subscribe((data: any) => {
                  room.optionNightsNetTotalProf = parseFloat(data['totalPrice']);
                  room.percentage = parseFloat(data['percentage']);
                  room.blickoins =  parseFloat(data['blickoins']);
                  room.creditBlickoins = parseFloat(data['creditBlickoins']);

                });
              this.serviceItinerary.getProfitability(room.netPrice, item.categoryId, room.providerId)
                .subscribe((data: any) => {
                  room.netPrice =   parseFloat(data['netprice']);
                  room.optionNightsTotalFlag =  parseFloat(data['totalPrice']);
                });
            }); */
          }

          //Si es package
          if (item.additionalInfo.roomsPackage) {
            // tslint:disable-next-line:max-line-length
            this.serviceItinerary
              .getProfitability(item.additionalInfo.roomsPackage[0].netPrice, item.categoryId, item.providerId)
              .subscribe((data: any) => {
                item.additionalInfo.roomsPackage[0].netPriceProf = data.split("-")[0];
                // item.additionalInfo.roomsPackage[0].refPriceFlag = data.split('-')[1];
                item.additionalInfo.roomsPackage[0].percentage = data.split("-")[2];
              });
            item.additionalInfo.roomsPackage.forEach((room) => {
              this.serviceItinerary.getProfitability(room.optionNightsNetTotal, item.categoryId, item.providerId).subscribe((data: any) => {
                room.optionNightsNetTotalProf = data.split("-")[0];
                // room.optionNightsTotalFlag = data.split('-')[1];
                room.percentage = data.split("-")[2];
              });
            });
          }
          //Si es tour
          if (item.additionalInfo.tours) {
            item.additionalInfo.tours.options.forEach((option) => {
              this.serviceItinerary.getProfitability(option.prices.adult, item.categoryId, item.providerId).subscribe((data: any) => {
                option.prices.adultProf = data.split("-")[0];
                option.prices.adultFlag = data.split("-")[1];
              });
              this.serviceItinerary.getProfitability(option.prices.child, item.categoryId, item.providerId).subscribe((data: any) => {
                option.prices.childProf = data.split("-")[0];
                option.prices.childFlag = data.split("-")[1];
                option.prices.percentage = data.split("-")[2];
              });
              this.serviceItinerary.getProfitability(option.totalPrice, item.categoryId, item.providerId).subscribe((data: any) => {
                option.totalPriceProf = data.split("-")[0];
                option.totalPriceFlag = data.split("-")[1];
                option.percentage = data.split("-")[2];
                option.blickoins = parseFloat(data.split("-")[3]);
                option.creditBlickoins = data.split("-")[4];
              });
            });
          }
          //Si es transport
          if (item.additionalInfo.transfers) {
            this.serviceItinerary
              .getProfitability(item.additionalInfo.transfers.totalPrice, item.categoryId, item.providerId)
              .subscribe((data: any) => {
                item.additionalInfo.transfers.totalPriceProf = parseFloat(data.split("-")[0]);
                item.additionalInfo.transfers.totalPriceFlag = data.split("-")[1];
                item.additionalInfo.transfers.percentage = parseFloat(data.split("-")[2]);
                item.additionalInfo.transfers.vehicles[0].units = parseFloat(data.split("-")[2]);
                item.additionalInfo.transfers.blickoins = parseFloat(data.split("-")[3]);
                item.additionalInfo.transfers.creditBlickoins = data.split("-")[4];
              });

            // calcular profility a los items modal
            item.additionalInfo.transfers.vehicles.forEach((vehicle) => {
              this.serviceItinerary.getProfitability(vehicle.price, item.categoryId, item.providerId).subscribe((data: any) => {
                vehicle.PriceProf = parseFloat(data.split("-")[0]);
              });
            });
          }
          break;
        case "MULTIDESTINATION":
        case "TRANSPORTS":
          // tslint:disable-next-line:max-line-length
          this.serviceItinerary
            .getProfitability(item.additionalInfo.transfers.totalPrice, item.categoryId, item.providerId)
            .subscribe((data: any) => {
              item.additionalInfo.transfers.totalPriceProf = data.split("-")[0];
              item.additionalInfo.transfers.totalPriceFlag = data.split("-")[1];
              item.additionalInfo.transfers.percentage = data.split("-")[2];
              item.additionalInfo.transfers.blickoins = parseFloat(data.split("-")[3]);
              item.additionalInfo.transfers.creditBlickoins = data.split("-")[4];
            });

          // calcular profility a los items modal
          item.additionalInfo.transfers.vehicles.forEach((vehicle) => {
            this.serviceItinerary.getProfitability(vehicle.price, item.categoryId, item.providerId).subscribe((data: any) => {
              vehicle.PriceProf = parseFloat(data.split("-")[0]);
            });
          });

          break;
        case "TOURS":
          item.additionalInfo.tours.options.forEach((option) => {
            this.serviceItinerary.getProfitability(option.prices.adult, item.categoryId, item.providerId).subscribe((data: any) => {
              option.prices.adultProf = data.split("-")[0];
              option.prices.adultFlag = data.split("-")[1];
              option.prices.percentage = data.split("-")[2];
            });
            this.serviceItinerary.getProfitability(option.prices.child, item.categoryId, item.providerId).subscribe((data: any) => {
              option.prices.childProf = data.split("-")[0];
              option.prices.childFlag = data.split("-")[1];
              option.prices.percentage = data.split("-")[2];
            });
            this.serviceItinerary.getProfitability(option.totalPrice, item.categoryId, item.providerId).subscribe((data: any) => {
              option.totalPriceProf = data.split("-")[0];
              option.totalPriceFlag = data.split("-")[1];
              option.percentage = data.split("-")[2];
              option.blickoins = parseFloat(data.split("-")[3]);
              option.creditBlickoins = data.split("-")[4];
            });
          });
          break;
        case "RENT-A-CAR":
          // tslint:disable-next-line:max-line-length
          this.serviceItinerary
            .getProfitability(item.additionalInfo.carRental.totalPrice, item.categoryId, item.providerId)
            .subscribe((data: any) => {
              item.additionalInfo.carRental.totalPriceProf = data.split("-")[0];
              item.additionalInfo.carRental.totalPriceFlag = data.split("-")[1];
              item.additionalInfo.carRental.percentage = data.split("-")[2];
              item.additionalInfo.carRental.blickoins = parseFloat(data.split("-")[3]);
              item.additionalInfo.carRental.creditBlickoins = data.split("-")[4];
            });
          break;
        case "PACKAGES":
          if (item.additionalInfo.tours) {
            this.serviceItinerary
              .getProfitability(item.additionalInfo.tours.options[0].prices.adult, item.categoryId, item.providerId)
              .subscribe((data: any) => {
                item.additionalInfo.tours.options[0].prices.adultProf = data.split("-")[0];
                item.additionalInfo.tours.options[0].prices.adultFlag = data.split("-")[1];
                item.additionalInfo.tours.options[0].prices.percentage = data.split("-")[2];
              });
          } else {
            if (item.additionalInfo.roomsPackage) {
              this.serviceItinerary
                .getProfitability(item.additionalInfo.roomsPackage[0].netPrice, item.categoryId, item.providerId)
                .subscribe((data: any) => {
                  item.additionalInfo.roomsPackage[0].netPriceProf = data.split("-")[0];
                  //  item.additionalInfo.roomsPackage[0].refPriceFlag = data.split('-')[1];
                  item.additionalInfo.roomsPackage[0].percentage = data.split("-")[2];
                });
            }
          }
          break;
        case "DISNEY":
          if (item.additionalInfo !== null) {
            item.additionalInfo.rooms.forEach((room) => {
              this.serviceItinerary.getProfitability(room.netPrice, item.categoryId, item.providerId).subscribe((data: any) => {
                room.netPriceProf = data.split("-")[0];
                //  room.refPriceFlag = data.split('-')[1];
                room.percentage = data.split("-")[2];
              });
              this.serviceItinerary.getProfitability(room.optionNightsNetTotal, item.categoryId, item.providerId).subscribe((data: any) => {
                room.optionNightsTotalProf = data.split("-")[0];
                // room.optionNightsTotalFlag = data.split('-')[1];
                room.percentage = data.split("-")[2];
              });
            });
          }
          break;
        case "PTHEMES":
          item.additionalInfo.options.forEach((option) => {
            this.serviceItinerary.getProfitability(option.priceAdults, item.categoryId, item.providerId).subscribe((data: any) => {
              option.priceAdultsProf = data.split("-")[0];
              option.priceAdultsFlag = data.split("-")[1];
              option.percentage = data.split("-")[2];
            });
          });

          item.additionalInfo.options.forEach((option) => {
            this.serviceItinerary.getProfitability(option.priceChild, item.categoryId, item.providerId).subscribe((data: any) => {
              option.priceChildProf = data.split("-")[0];
              option.priceChildFlag = data.split("-")[1];
              option.percentage = data.split("-")[2];
            });
          });

          item.additionalInfo.options.forEach((option) => {
            this.serviceItinerary.getProfitability(option.optionTotal, item.categoryId, item.providerId).subscribe((data: any) => {
              option.optionTotalProf = data.split("-")[0];
              option.optionTotalFlag = data.split("-")[1];
              option.percentage = data.split("-")[2];
            });
          });
          break;
        case "INSURANCE":
          this.serviceItinerary
            .getProfitability(item.additionalInfo.totalPrice, item.categoryId, item.providerId)
            .subscribe((data: any) => {
              item.additionalInfo.totalPriceProf = data.split("-")[0];
              item.additionalInfo.totalPriceFlag = data.split("-")[1];
              item.additionalInfo.percentage = data.split("-")[2];
            });
          break;
      }
    });
    this.arrayOut.emit(this.resultPaged);
  }

  /**
   *
   * @description Reset valores de precios (slider)
   * @returns void
   */
  resetPriceSlider():void {
    if(this.results.length > 0){
      switch (String(this.payload.categoriesId[0])) {
        case '1': //HOTELS
        this.priceResetLow;
        this.priceResetHigh;
          break;
        case '6': //TOURS
        this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
        this.filterLowPrice =  this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
          break;
        case '2': //RENTACAR
        this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.carRental.netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
        this.filterLowPrice = this.results.map(function(el: any) {return el.additionalInfo.carRental.netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
          break;

        default:
          this.filterHighPrice = 0;
          this.filterLowPrice = 0;
          break;
      }

      // console.log('price low', this.priceResetLow);
      // console.log('price high', this.priceResetHigh);
      // this.filterLowPrice = this.priceResetLow;
      // this.filterHighPrice = this.priceResetHigh;
    }

  }

}


