<div class="row" style="min-height: 128px; height: 100%">
  <div class="col-8 content-status d-flex flex-column justify-content-between">
    <div class="row content-fee" style="margin-left: 5px">
      <div class="col-7">
        <div class="form-group mb-0 row justify-content-center adjust-fee-percentage">
          <label class="d-flex col-form-label font-poppins font-weight-bold font-verde text-fee mr-1">Fee</label>
          <div class="d-flex content-input-fee mr-1">
            <input
              type="text"
              class="form-control font-poppins font-weight-bold f-16 input-fee"
              (change)="updateItemsFee($event)"
              pattern="/^[1-9]\d{6,10}$/"
              [ngModel]="item.item.status_id === 1 ? percentage : item.item.percentageAgent"
              min="0"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [disabled]="
                activeGlobalFee ||
                item.item.status_id === 2 ||
                item.item.status_id === 5 ||
                item.item.isPendigApprovePay === true ||
                item.item.isPaidAgency === true ||
                item.item.isPaidProvider === true ||
                item.item.isBilled === true ||
                modifications
              "
            />
          </div>
          <label class="d-flex col-form-label font-poppins font-weight-bold font-verde text-fee">%</label>
          <div class="d-flex" style="margin-left: 5px">
            <a style="cursor: pointer; position: absolute; top: 2px; left: 47px !important; z-index: 1" title="NR">
              <span class="icon-nr font-poppins" *ngIf="item.item.nr">NR</span>
            </a>
            <div></div>
          </div>
        </div>
      </div>
      <div
        class="col-5 d-flex justify-content-end align-self-center adjust-status"
        *ngIf="status !== 'price-non-available' && status !== 'room-non-available' && status !== 'non-available'"
      >
        <span *ngIf="item.item.status_id === 1" class="status-fee product-status-saved">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 2" class="status-fee product-status-reserved">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 3" class="status-fee product-status-locked">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 4" class="status-fee product-status-denied">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 5" class="status-fee product-status-cancelled">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 6" class="status-fee product-status-paid-agency">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 7" class="status-fee product-status-paid-provider">{{ item.item.status }}</span>
        <span *ngIf="item.item.status_id === 8" class="status-fee product-status-completed">{{ item.item.status }}</span>
      </div>
    </div>
    <div class="row conditions" *ngIf="item.item.status_id == 5">
      <div class="col-5" style="font-size: 11px !important; margin-left: 10rem">
        <p class="font-poppins text-right m-0">Fecha cancelación</p>
        <p class="red font-poppins text-right font-weight-bold m-0" *ngIf="item.item.status_id === 5" style="font-size: 11px !important">
          {{ item.item.updateDate | date : "dd/MM/yyyy" }}
        </p>
      </div>
    </div>
    <div class="row conditions">
      <!-- CASO 1  NO HAY CAMBIOS-->

      <div class="col-7" *ngIf="!item.item?.syncProduct?.alert && showTextChangeDetailsProducts">
        <div class="row align-items-center justify-content-start">
          <span class="red date-verifed nt-poppins pt-2 font-weight-bold m-0" *ngIf="item.item?.syncProduct?.dateTimeVerifed">
            Última Verificación {{ item.item?.syncProduct?.dateTimeVerifed | date : "dd/MM/yyyy" }}
          </span>
          <span class="date-verifed nt-poppins pt-3 font-weight-bold m-0 f-10" *ngIf="item.item?.syncProduct?.message">
            <div class="d-flex mr-2 px-0 position-icon-check">
              <i class="icon-check-green">&nbsp;&nbsp;</i>
              <span class="ml-2">{{ item.item?.syncProduct?.message }} </span>
            </div>
          </span>
        </div>
      </div>

      <!-- OCURRIO UN CAMBIO -->
      <div class="col-7 mb-2 mt-1 ml-1" *ngIf="item.item?.syncProduct?.alert && showTextChangeDetailsProducts">
        <!--ALERT -->
        <div class="d-flex flex-row align-items-end ml-4" *ngIf="booleanAlert">
          <img src="/assets/img/app/little-icons/alert_n.svg" class="non-available-img mr-2" alt="" />
          <p class="m-0 warning">ALERTA</p>
        </div>

        <!-- CASO 2  CAMBIO DE PRECIOS -->
        <div class="row mx-auto flex-column mt-2 ml-4" *ngIf="item.item?.syncProduct?.priceNonAvailable && showTextChangeDetailsProducts">
          <div class="d-flex flex-column px-0 ml-4">
            <p class="m-0 d-flex" style="font-size: 10px; font-size: 11px; color: #3e3f40; font-weight: 800">
              {{ item.item?.syncProduct?.message }}
            </p>
            <button type="button" class="px-0 m-0 selector-x non-available-btns font-poppins d-flex" (click)="acceptNewPrice()">
              <span style="color: #00d397; font-size: 15px; font-weight: 600"
              >
                {{currencyItinerary}} {{ item.item?.syncProduct?.newPrice?.profility?.netPriceBooking | number : "1.2-2" }}</span
              >
              <p class="m-0 d-flex ml-2" style="border-radius: 6px; font-size: 11px; background-color: #3e3f40; padding: 3px; color: white">
                OK
              </p>
            </button>
          </div>
          <div class="d-flex flex-column mx-auto px-0">
            <button
              type="button"
              class="px-0 m-0 mt-4 selector-x non-available-btns font-poppins d-flex align-items-start justify-content-between"
              (click)="acceptOthersSearch()"
            >
              <p class="m-0 d-flex ml-2" style="border-radius: 6px; font-size: 11px; background-color: #00d397; padding: 6px; color: white">
                Nueva Búsqueda
              </p>
            </button>
          </div>
        </div>

        <!-- CASO 3  PRODUCTO NO DISPONIBLE -->
        <div class="row mx-auto flex-column mt-2" *ngIf="item.item?.syncProduct?.nonAvailable">
          <div class="d-flex flex-column mx-auto px-0">
            <p class="m-0 d-flex" style="font-size: 10px; font-size: 11px; color: #3e3f40; font-weight: 800">
              {{ item.item?.syncProduct?.message }}
            </p>
          </div>
          <div class="d-flex flex-column mx-auto px-0">
            <button
              type="button"
              class="px-0 m-0 mt-4 selector-x non-available-btns font-poppins d-flex align-items-start justify-content-between"
              (click)="deleteItem()"
            >
              <p class="m-0 d-flex ml-2" style="border-radius: 6px; font-size: 11px; background-color: #e93a55; padding: 6px; color: white">
                BORRAR
              </p>
            </button>
          </div>
        </div>
      </div>
      <!-- POLITICAS DE CANCELACIÓN -->
      <div
        class="col-5"
        *ngIf="item.item.status_id != 1 && item.item.status_id != 5 && item?.item?.cancellation_policy"
        style="font-size: 11px !important"
      >
        <p class="font-poppins text-right m-0">Fecha Expiración</p>
        <p
          class="red font-poppins text-right font-weight-bold m-0"
          *ngIf="item?.item?.cancellation_policy[0]?.from"
          style="font-size: 11px !important"
        >
          {{ item?.item?.cancellation_policy[0]?.from | date : "dd/MM/yyyy" }}
        </p>
        <p
          class="red font-poppins text-right font-weight-bold m-0"
          *ngIf="!item?.item?.cancellation_policy[0]?.from"
          style="font-size: 11px !important"
        >
          No Disponible
        </p>
        <p
          class="font-poppins text-right font-weight-bold m-0"
          *ngIf="item?.item?.cancellation_policy[0]?.descript_polecy"
          style="font-size: 11px !important"
        >
        {{currencyItinerary}} {{ item?.item?.cancellation_policy[0]?.amount }}
        </p>
      </div>
    </div>

    <div style="margin-left: 26px">
      <div
        class="d-flex"
        *ngIf="
          item.item.status_id == 1 && status !== 'price-non-available' && status !== 'room-non-available' && status !== 'non-available'
        "
      >
        <ng-container *ngIf="!(item.item?.syncProduct?.alert && showTextChangeDetailsProducts)">
          <div
            class="d-flex align-items-center"
            *ngIf="partialReserve === true"
            (change)="toPartialReserve($event, item)"
            ng-checked="item.status_id==3"
            style="width: 85px"
          >
            <span class="d-flex">
              <label class="custom-radio-checkbox m-0">
                <input class="custom-radio-checkbox__input" type="checkbox" name="partial_reserve" />
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
              </label>
            </span>
            <p class="font-poppins f-12 text-parcial-reserve m-0">RESERVA PARCIAL</p>
          </div>
        </ng-container>
      </div>
      <div
        class="d-flex justify-content-end w-100 px-3"
        *ngIf="
          ((item.item.status_id === 2 && item.item.isPendigApprovePay === true) ||
            item.item.isPaidAgency === true ||
            item.item.isPaidProvider === true ||
            item.item.isBilled === true) &&
          status !== 'price-non-available' &&
          status !== 'room-non-available' &&
          status !== 'non-available'
        "
      >
        <span class="indicadores ind-pap mr-1" *ngIf="item.item.isPendigApprovePay === true">PAP</span>
        <span class="indicadores ind-pa mr-1" *ngIf="item.item.isPaidAgency === true">PA</span>
        <span class="indicadores ind-pp mr-1" [ngClass]="{ 'ind-pp': item.item.isPaidProvider === true }">PP</span>
        <span class="indicadores ind-b" [ngClass]="{ 'ind-b': item.item.isBilled === true }">F</span>
      </div>
    </div>
  </div>

  <!-- PRECIOS -->
  <div class="col-4 card-price d-flex align-items-center pl-0">
    <div class="row mx-auto flex-column adjust-card-price">
      <div class="d-flex flex-column px-0" style="text-align: end">
        <p class="font-poppins f-12 font-weight-bold text-price text-uppercase mb-0 text-price-net-rate text-start">Tarifa Neta</p>
        <p
          class="font-poppins number-price text-start"
          [ngStyle]="{
            'font-size': netPriceBooking | dynamicFontSize: 20 : fontSizeAdjustments,
            'text-decoration': item.item?.syncProduct?.priceNonAvailable ? 'line-through' : 'none'
          }"
        >
          <!-- Modificación, cualquier cosa volver a utilizar esta propiedad. {{currencyItinerary}} {{ netPriceBookingFreeTax | number : '1.2-2' }} -->
          {{currencyItinerary}} {{ netPriceBooking > 0 ? netPriceBooking : netPriceBookingFreeTax | number : '1.2-2' }}
        </p>
      </div>
      <div class="d-flex flex-column px-0 position-relative" style="text-align: end"  *ngIf="taxes && taxes.length > 0 || taxSum > 0">
        <span>
          <!-- COMENTADO YOVANNY 09-07-2024  <span *ngIf="taxSum > 0"> -->
          <div class="d-flex justify-content-end align-items-center">
            <div class="info-tool d-flex flex-column mr-1" *ngIf="taxes && taxes.length > 0">
              i
              <span class="tooltip-info shadow-md">
                <table>
                  <tr>
                    <th style="text-align: start">
                      <p class="pl-4">Descripción</p>
                    </th>
                    <th>
                      <p>Impuesto</p>
                    </th>
                  </tr>
                  <ng-container *ngFor="let tax of taxes">
                    <tr>
                      <td style="text-align: start">
                        <p class="mb-2">{{ tax.description }}</p>
                      </td>
                      <td>
                        <p>{{currencyItinerary}} {{ tax.amount | number: '1.2-2' }}</p>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </span>
            </div>
            <p class="font-poppins f-12 font-weight-bold text-price text-uppercase mb-0 text-price-net-rate text-start">Impuesto</p>
          </div>
          <p
            class="font-poppins number-price text-start"
            [ngStyle]="{
              'font-size':  taxSum | dynamicFontSize: 20 : fontSizeAdjustments,
              'text-decoration': item.item?.syncProduct?.priceNonAvailable ? 'line-through' : 'none'
            }"
          >
          {{currencyItinerary}} {{ taxSum | number : "1.2-2" }}
          </p>
        </span>
      </div>
      <div class="d-flex flex-column px-0 mt-2" style="text-align: end">
        <p class="font-poppins font-verde f-12 font-weight-bold text-uppercase text-price mb-0 text-start">Tarifa Total</p>

        <!-- COMENTADO YOVANNY 09-07-2024
         <p *ngIf="!showTextChangeDetailsProducts" class="font-poppins number-price font-verde mb-0 text-start">  {{ netPriceBooking > 0 ? netPriceBooking : netPriceBookingFreeTax | number : "1.2-2" }}</p>
       -->

        <!-- {{ optionNightsNetTotalProf > 0 ? optionNightsNetTotalProf : optionNightsNetTotalFreeTax | number : "1.2-2" }} -->
        <p
          *ngIf="showTextChangeDetailsProducts"
          class="font-poppins number-price font-verde mb-0 text-start"
          [ngStyle]="{
            'font-size': netPriceBooking | dynamicFontSize: 20 : fontSizeAdjustments,
            'text-decoration': item.item?.syncProduct?.priceNonAvailable ? 'line-through' : 'none'
          }"
        >
        {{currencyItinerary}} {{ (netPriceBooking > 0 ? netPriceBooking : netPriceBookingFreeTax ) | number : "1.2-2" }}
        </p>
      </div>
      <div class="d-flex flex-column mt-4 adjust-bks">
        <div class="d-flex align-items-center">
          <img src="/assets/img/app/little-icons/badge-bks.png" class="img-fluid icon-premium-point" />
          <p *ngIf="!showTextChangeDetailsProducts" class="font-point text-left f-16 m-0" style="color: #facb49 !important">
            &nbsp;{{ blickoins | number : "1.2-2" }} Bks.
          </p>
          <p
            *ngIf="showTextChangeDetailsProducts"
            class="font-point text-left f-16 m-0"
            style="color: #facb49 !important"
            [ngStyle]="{
              'font-size':  blickoins | dynamicFontSize: 16 : fontSizeAdjustments,
              'text-decoration': item.item?.syncProduct?.priceNonAvailable ? 'line-through' : 'none'
            }"
          >
            &nbsp;{{ blickoins | number : "1.2-2" }} BKS
          </p>
        </div>
        <div>
          <p *ngIf="!showTextChangeDetailsProducts" class="font-point text-right f-16 m-0" style="color: #facb49 !important; line-height: 1;"

          [ngStyle]="{
            'font-size':  creditBlickoins | dynamicFontSize: 16 : fontSizeAdjustments}">
            &nbsp;{{ creditBlickoins | number : "1.2-2" }} USD.
          </p>
          <p
            *ngIf="showTextChangeDetailsProducts"
            class="font-point text-right f-16 m-0"
            style="color: #facb49 !important; line-height: 1;"
            [ngStyle]="{
              'font-size': fontPrice(item.item?.profility?.netPriceBooking),
              'text-decoration': item.item?.syncProduct?.priceNonAvailable ? 'line-through' : 'none'
            }"
          >
            &nbsp;{{ creditBlickoins | number : "1.2-2" }} USD
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="white" type="line-scale">
  <p style="color: white">Loading...</p>
</ngx-spinner>
