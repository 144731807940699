import { Component, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ResultsSearchService } from '../../home/results-search.service';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { ItineraryService } from '../itinerary.service';
import { FormBuilder, FormControl, AbstractControl, ValidationErrors, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
//import { ToastsManager } from 'ng2-toastr';
import { Observable } from 'rxjs';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';

@Component({
  selector: 'usb-head-band',
  templateUrl: './head-band.component.html',
  styleUrls: ['./head-band.component.scss'],
  providers: [DatePipe]
})
export class HeadBandComponent implements OnInit {

  _actualItinerary: any;
  modalRef: BsModalRef;
  itinerary$: Observable<any[]>;


  constructor(private _router: Router,
    private _itineraryService: ItineraryService,
    public vcr: ViewContainerRef,
    private resultsSearch: ResultsSearchService,
    public userService:UserService
  ) {
    //this.toastr.setRootViewContainerRef(this.vcr);
  }

  ngOnInit() {
    this._initData();
  }
  
  _initData() {

    this.itinerary$ = this._itineraryService.getItinerary$();
    this.itinerary$.subscribe((data:any) => {
      if ((data !== undefined) && (data !== null)) {
        if (data[0] !== undefined) {
          this._actualItinerary = data[0];
        } else {
          if(this.userService.itineraryActive){
            this._actualItinerary = this.userService.itineraryActive[0];
          }else{
            this._actualItinerary = null;
          }
        }
      }
    });

    if(!this._actualItinerary){
      this._actualItinerary = this.userService.itineraryActive[0];
    }

  }
  _backToMain() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
    window.history.back();
  }
}
