import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ResultsSearchService } from '../../../../home/results-search.service';

@Component({
  selector: 'usb-packages-result',
  templateUrl: './packages-result.component.html',
  styleUrls: ['./packages-result.component.scss']
})
export class PackagesResultComponent implements OnInit {

  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;

  constructor(private searchService: ResultsSearchService) {
  }

  ngOnInit() {
    if (this.origin === null || this.origin === undefined) {
      this.origin = this.searchService._getOrigin();
    }
    console.log("RESULTADOS", this.result)
  }

  stars() {
    const array = [];
    const item = Number.parseInt(this.result.additionalInfo.category);
    for (let index = 0; index < item; index++) {
      array.push(index);
    }
    return array;
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }
  isNull(str){
    let nulls = (str == null)? true : false
    return nulls
  }

}

