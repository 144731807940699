export enum Procesed {
    MULTIDESTINATION = 'MULTIDESTINATION',
    HOTELS = 'HOTELS',
    TOURS = 'TOURS',
    CRUISES = 'CRUISES',
    RENT_A_CAR = 'RENT-A-CAR',
    TRANSPORTS = 'TRANSPORTS',
    PACKAGES = 'PACKAGES',
    DISNEY = 'DISNEY',
    PTHEMES = 'PTHEMES',
    INSURANCE = 'INSURANCE'
}