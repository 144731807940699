import { HttpClient , HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
@Injectable()

export class HomeService {
	constructor(private _http: HttpClient) {
	}


}