<div class="row" id="visited-component">
  <div class="triangle"></div>
    <div class="col-12">
        <div class="bg-visited-component" style="padding-bottom: 68px;" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
            <div class="row">
                <div class="col-12">
                    <span class="font-poppins title-recomendados" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RECOMENDADOS</span>
                </div>
            </div>

            <div class="row pt-3">
                <div class="col-12">
                    <div class="px-3">
                      <img src="../../../assets/img/app/Visited/imgpsh_fullsize_anim.jpg" width="315px" alt="">
                      <div class="d-flex flex-column">
                        <p class="title-visited" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">TAYLOR SWIFT</p>
                        <p class="date-visited" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">10 Noviembre - Estadio River Plate</p>
                        <p class="adress-visited">Buenos Aires</p>
                        <p class="description-visited">En su nuevo show "The Eras Tour", Taylor repasa lo mejor de sus 10 álbumes. Es un tributo por todas sus eras musicales, incluyendo su último trabajo, Midnights, lanzado el 21 de octubre de 2022.</p>
                        <div class="d-flex justify-content-end" [ngStyle]="{'border-bottom': '1px dotted ' + (getClientsSettingAppBooking?.clients_setting_global?.color_main_3 || 'black')}" style="padding-bottom: 25px;">
                          <!-- <button type="button" class="btn btn-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">CLICK AQUÍ</button> -->
                        </div>
                      </div>
                        <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/oUDizYOQjmM0" allowfullscreen></iframe>
                        </div>
                        <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                            <div class="row">
                                <div class="col-8">
                                    <p class="card-text text-left font-poppins font-weight-bold">Universal Studios</p>
                                </div>
                                <div class="col-4">
                                    <span class="text-uppercase reserve px-2 font-poppins" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">reserve</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 40px">
                <div class="col-12">
                    <div class="px-3">
                      <div class="middle-circle-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"></div>
                      <div class="d-flex flex-column text-card-visited">
                        <p class="title-card-visited">HOP ON / OFF</p>
                        <p class="subtitle-card-visited">Conocé Buenos Aires en pocas horas !!!</p>
                        <div class="d-flex justify-content-end" style="margin-left: 196px">
                          <button type="button" class="badge rounded-pill btn-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RESERVAR</button>
                        </div>
                      </div>
                      <img src="../../../assets/img/app/recomended/hop.png" width="315px" alt="">
                        <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/aFVvCBDYS58" allowfullscreen></iframe>
                        </div>
                        <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                            <div class="row">
                                <div class="col-8">
                                    <p class="card-text text-left font-poppins font-weight-bold">Kissimme</p>
                                </div>
                                <div class="col-4">
                                    <span class="text-uppercase reserve px-2 font-poppins" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">reserve</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-12">
                    <div class="px-3">
                      <div class="middle-circle-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"></div>
                      <div class="d-flex flex-column text-card-visited">
                        <p class="title-card-visited">LAWTENNIS CLUB</p>
                        <p class="subtitle-card-visited">Tennis internacional en Palermo</p>
                        <div class="d-flex justify-content-end" style="margin-left: 196px">
                          <button type="button" class="badge rounded-pill btn-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RESERVAR</button>
                        </div>
                      </div>
                      <img src="../../../assets/img/app/recomended/tennis.png" width="315px" alt="">
                        <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7SvUgiaIvH8" allowfullscreen></iframe>
                        </div>
                        <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                            <div class="row">
                                <div class="col-8">
                                    <p class="card-text text-left font-poppins font-weight-bold">Fort Lauderdale</p>
                                </div>
                                <div class="col-4">
                                    <span class="text-uppercase reserve px-2 font-poppins">reserve</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-12">
                    <div class="px-3">
                      <div class="middle-circle-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"></div>
                      <div class="d-flex flex-column text-card-visited">
                        <p class="title-card-visited">BRESH</p>
                        <p class="subtitle-card-visited">La mejor fiesta tecno vivila en Mar del Plata</p>
                        <div class="d-flex justify-content-end" style="margin-left: 196px">
                          <button type="button" class="badge rounded-pill btn-card-visited" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">RESERVAR</button>
                        </div>
                      </div>
                      <img src="../../../assets/img/app/recomended/bresh.png" width="315px" alt="">
                        <!-- <div class="embed-responsive embed-responsive-16by9" id="video">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ItIaOmlZjbk" allowfullscreen></iframe>
                        </div>
                        <div class="card-block bg-white pt-1 pb-4 px-3 foot-card">
                            <div class="row">
                                <div class="col-8">
                                    <p class="card-text text-left font-poppins font-weight-bold">Key West</p>
                                </div>
                                <div class="col-4">
                                    <span class="text-uppercase reserve px-2 font-poppins" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">reserve</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-circle" style="margin-top: 40px;"></div>
    </div>
</div>
<!-- <div class="row cont-recomendado">
    <div class="container col-12 text-center bg-dark">
        <div class="card-group">
            <div class="card border-0 m-1">
              <div class="embed-responsive embed-responsive-4by3" id="video">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/oUDizYOQjmM0" allowfullscreen></iframe>
              </div>
                <div class="card-block">
                    <p class="card-text text-left">Universal Studios</p>
                </div>
            </div>
        </div>
    </div>
    <div class=" container col-12 text-center bg-dark">
        <div class="card-group">
            <div class="card border-0 m-1">
              <div class="embed-responsive embed-responsive-4by3" id="video">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/aFVvCBDYS58" allowfullscreen></iframe>
              </div>
                <div class="card-block">
                    <p class="card-text text-left">Kissimme</p>
                </div>
            </div>
        </div>
    </div>
    <div class=" container col-12 text-center bg-dark">
        <div class="card-group">
            <div class="card border-0 m-1">
              <div class="embed-responsive embed-responsive-4by3" id="video">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7SvUgiaIvH8" allowfullscreen></iframe>
              </div>
              <div class="card-block">
                    <p class="card-text text-left">Fort Lauderdale</p>
                </div>
            </div>
        </div>
    </div>
    <div class=" container col-12 text-center bg-dark">
        <div class="card-group">
            <div class="card border-0 m-1">
                <div class="embed-responsive embed-responsive-4by3" id="video">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ItIaOmlZjbk" allowfullscreen></iframe>
                </div>
                <div class="card-block">
                    <p class="card-text text-left">Key West</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
