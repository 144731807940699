import { Injectable } from "@angular/core";
import { RestService } from "../shared/rest.service";
import { HttpClient } from "@angular/common/http";
import { setting } from "../../../setting";

@Injectable()
export class FidelityService {
  creditPointActives: any = 0;
  creditDepositActives: any = 0;
  creditLimit: any = 0;

  depositActives: any;

  constructor(private http: HttpClient, private restService: RestService) {}

  getCalculatePoints(categoryId: any, netPrice: any, priceProf: any): any {
    const url = "fidelitys/calculateBlickoinsFidelity/" + categoryId + "/" + netPrice + "/" + priceProf;
    this.restService.doGet("app", url).subscribe(
      (response: any) => {
        return response;
      },
      (err) => {
        console.log("No existe información, para calcular los puntos");
      }
    );
  }

  getCalculatePointsByCategory(itineraryItems: any) {
    let pointCategory = 0;
    if (itineraryItems.length > 0) {
      itineraryItems.forEach((item) => {
        switch (item.categoryName) {
          case "HOTELS":
            //console.log('HOTELS fidelity service', item);
            pointCategory = pointCategory + item.blickoins;
            break;
          case "TOURS":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "TRANSPORTS":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "DISNEY":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "CRUISES":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "RENT-A-CAR":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "INSURANCE":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "PTHEMES":
            pointCategory = pointCategory + item.blickoins;
            break;
          case "PACKAGES":
            pointCategory = pointCategory + item.blickoins;
            break;
        }
      });
      return pointCategory;
    }
  }

  getAgentSummary(agentId: any, agencyId: any): any {
    let url = `${setting.apiBookingUrl}agentSummary/${agentId}/${agencyId}`;
    return this.http.get(url, { headers: this.restService._headers() }).map((response: any) => response);
  }
}
