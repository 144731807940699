<!--*********************** Hotel CheckIN ******************** -->

<div class="row mt-3" *ngIf="isCheckIn && dato">
  <!-- <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;">
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(dato.arrivalDate) | date:'dd'}}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(dato.arrivalDate) | date:'MMM'}}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(dato.arrivalDate) | date:'yy'}}</p>
        </div>
        <div class="row mt-2" style="margin-left: 52px !important; margin-top: -53px !important;">
          <span class="icon-line icon-bg-ck-in">
            <i class="icon-hotel-ck-in"></i>
          </span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 pt-2 container-items">

    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center text-center adjust-image" style="height: 150px !important; overflow: hidden;">
        <img  class="img-fluid adjust-photo" src="{{dato.imagen}}" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';"  alt="hotel" style="height: 150px;"
          width="100%">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex justify-content-between py-1 adjust-description">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{dato.title}}</p>
            <div style="margin-top: -5px !important;">
              <span><i *ngFor="let star of generateStars(dato.categoryHotel)" class="icon-estrella"
                  style="line-height: 1; color: #ff9503; margin-top: -20px; padding-top: 0px;"></i></span>
            </div>
            <div style="margin-top: 10px;">
              <span class="p-0 spaced" style="padding-top: 20px !important; margin-top: 10px;">
                <span style="font-size: 15px !important; font-weight: 600;">
                  <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                  {{dato.location}}
                </span>
              </span>
              <br>
              <span class="p-0 m-0  spaced">
                <p class="p-0 m-0 inter" style="font-size: 12px; font-weight: 500;">
                  1 Hab. |&nbsp;
                  {{dato.payloadUpdate.products[0].others.hotels.roomConfiguration[0].adults}} Adultos&nbsp;|&nbsp;
                  {{dato.payloadUpdate.products[0].others.hotels.roomConfiguration[0].children.length}}
                  Niños&nbsp;|&nbsp;
                  {{dato.stay?.nights}} Noches
                </p>
              </span>
              <div class="d-flex font-inline flex-row">
                <span class="check">Check-in</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="py-0" style="margin-top: 1px; width: 98px !important;">
              <!-- <span style="width: 10%" *ngIf="dato.status_id === 1" class="status product-status-saved">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 2" class="status product-status-reserved">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 3" class="status product-status-locked">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 4" class="status product-status-denied">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 5" class="status product-status-cancelled">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 6" class="status product-status-paid-agency">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 7" class="status product-status-paid-provider">{{dato.status}}</span>
              <span *ngIf="dato.status_id === 8" class="status product-status-completed">{{dato.status}}</span> -->

              <span *ngIf="dato.status_id === 1" style="width: 10%" class="status product-status-saved">{{dato.status}}</span>
              <span *ngIf="dato.isPendigApprovePay" class="status product-status-reserved"> Reserve</span>
              <span *ngIf="dato.status_id === 3" class="status product-status-locked">Locked</span>
              <span *ngIf="dato.status_id === 4" class="status product-status-denied">Denied</span>
              <span *ngIf="dato.status_id === 5" class="status product-status-cancelled">Cancelled</span>
              <span *ngIf="dato.isPaidAgency" class="status product-status-paid-agency">Paid</span>
              <span *ngIf="dato.isPaidProvider" class="status product-status-paid-provider">Completed</span>
              <span *ngIf="dato.isBilled" class="status product-status-completed">{{dato.status}}</span>

            </div>
            <!-- <div class="py-0" style="width: 20px !important; margin-top: 3px;"> -->
              <!-- <a type="button"  style="cursor: pointer;"> (click)="openModal()" -->
                <!-- <i class="icon-info"></i>
              </a>
              <div>
              </div>
            </div> -->
            <div class="py-0" style="width: 20px !important; margin-left: 4px; margin-top: 1px !important;">
              <a style="cursor: pointer;">
                <span class="icon-nr font-poppins" *ngIf="this.dato.nr">NR</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2 px-0 text-center bg-blue icon-accion">

        <div *ngIf="dato.status_id === 1" class="icons-item text-right"
          [ngStyle]="{'margin-top': !partialReserve ? '12px' : '9px'}">
          <!-- <span *ngIf="partialReserve===false||partialReserve===undefinid">
            <i class="far fa-check-circle" style="color: #00d397;"></i>
          </span> -->
          <span *ngIf="partialReserve===true">
            <label class="custom-radio-checkbox">
              <input class="custom-radio-checkbox__input" type="checkbox" (change)="toPartialReserve($event, dato)"
                ng-checked="dato.status_id==3">
              <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            </label>
          </span>
        </div>

        <!--
          //iconos que se quitaron.
          <div class="d-flex justify-content-around">
          <span class="icon-circle">
            <span><i class="fas fa-pen-square"></i></span>
            <a style="cursor: pointer; margin-left: 5px;" > (click)="modal()"
              <i class="icon-info" aria-hidden="true"></i>
            </a>
          </span>
          <span class="icon-circle">
            <span><i class="fas fa-pen-square"></i></span>
            <a style="cursor: pointer; margin-left: 5px;" (click)="edit()">
              <i class="icon-edit" aria-hidden="true"></i>
            </a>
          </span>
          <span class="icon-circle">
           <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(dato)">
              <i class="fas fa-trash-alt text-cente" style="color: #ff003f"></i>
            </button>
            <a style="cursor: pointer;" (click)="removeItem(dato)">
              <i class="icon-delete" aria-hidden="true"></i>
            </a>
          </span>
        </div>
         -->

        <div class="date-expiration pt-2 text-right" style="padding-right: 10px;" *ngIf="dato.expirationDate">
          <span class="font-poppins" style="font-size:11px; font-weight: 400;">
            Expiration Day
          </span>
          <br>
          <span class="font-poppins text-uppercase" style="font-size: 11px; font-weight: bold; color: #00d397;">
            {{ subtractDays(dato.expirationDate) | date:'mediumDate' }}
          </span>
        </div>

        <!--<div class="icons-item" style="margin-top: 5px;">
          <span><i class="far fa-check-circle" style="color: #00d397"></i></span>
          <span><i class="fas fa-pen-square"></i></span>
          <span><i class="fas fa-trash-alt text-cente" style="color: #ff003f"></i></span><br> [ngStyle]="{'font-size': fontPrice(dato.optionNightsNetTotalProf)}"
        </div>-->
        <div class="d-flex flex-column justify-content-end pt-1 text-right adjust-price" style="margin-top: 1px; padding-right: 10px;">
          <span class="title-destiny-start text-nowrap" style="font-size:18px!important;" >{{currencyItinerary}} {{dato.optionNightsNetTotalProf > 0 ? dato.optionNightsNetTotalProf : dato.optionNightNetTotalFreeTax | number:'1.2-2'}}</span>
          <span style="font-family: 'Poppins', sans-serif;font-size: 12px;">imp. incl.</span>
          <!--<span>$ 5335,20</span>-->
        </div>
      </div>
    </div>
  </div>
</div>

<!--*********************** Hotel CheckOUT ******************** -->

<div class="row mt-3" *ngIf="!isCheckIn && dato">
  <!-- <div class="col-2">
    <div class="row">
      <div class="col">
        <div class="text-center destiny-sumary-date-general" style="max-width: 37px;">
          <p class="text-center" style="font-size: 18px !important; margin-bottom: 1px !important;" >{{ formatDate(dato.departureDate) | date:'dd'}}</p>
          <p class="text-center" style="font-size: 10px !important; margin-bottom: 1px !important;" >{{ formatDate(dato.departureDate) | date:'MMM'}}</p>
          <p class="text-center" style="font-size: 13px !important; margin-bottom: 1px !important;" >{{ formatDate(dato.departureDate) | date:'yy'}}</p>

        </div>
        <div class="row mt-2 hotel-item-rent" style="margin-left: 52px !important; margin-top: -53px !important;">
          <span class="icon-line icon-bg-ck-in">
            <i class="icon-hotel-ck-out"></i>
          </span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center text-center adjust-image" style="height: 150px !important; overflow: hidden;">
          <img  class="img-fluid adjust-photo" src="{{dato.imagen}}" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';"  alt="hotel" style="height: 150px;"
          width="100%">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex justify-content-between py-1 adjust-description">
          <div>
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{dato.title}}</p>
            <div style="margin-top: -5px;">
              <span><i *ngFor="let star of generateStars(dato.categoryHotel)" class="icon-estrella"
                  style="line-height: 1; color: #ff9503; margin-top: -20px; padding-top: 0px;"></i></span>
            </div>
            <div style="margin-top: 10px;">
              <span class="p-0 spaced" style="padding-top: 20px !important; margin-top: 10px;">
                <!-- <span style="font-size: 15px !important; font-weight: bold;">
                  <i class="fas fa-map-marker-alt text-center" style="color: #00d397;"></i>
                  {{stingUpperCase(dato.location)}}
                </span> -->
              </span>
              <br>
              <span class="p-0 m-0  spaced">
                <p class="p-0 m-0 inter" style="font-size: 12px; font-weight: 500;">
                  1 Hab. |
                  {{dato.payloadUpdate.products[0].others.hotels.roomConfiguration[0].adults}}&nbsp;Adultos&nbsp; |
                  {{dato.payloadUpdate.products[0].others.hotels.roomConfiguration[0].children.length}}&nbsp; Niños |
                  {{dato.stay?.nights}} Noches
                </p>
              </span>
              <div class="d-flex font-inline flex-row">
                <span class="check check-out">Check-Out</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
