<div *ngIf="itemsSelect.length > 0" class="py-1">
  <span class="p-0 d-flex flex-column" *ngFor="let item of itemsSelect; let i = index">
    <span class="name-hotel">{{item.title}}</span>
    <span class="descrip-hotel py-1"> {{item.roomType}}</span>
    <span class="i-star d-flex py-1" >
      <i class="icon-estrella" aria-hidden="true" *ngFor="let s of star"></i>
    </span>
    <span class="p-0">
      <span class="descrip-hotel d-flex">

        <!-- <span>START : {{item.arrivalDate}} | </span>
        <span *ngIf="categoryName!='TOURS'" >OUT:  {{item.departureDate}} </span> -->
        <span>CHECK-IN : {{payload.arrivalDate}}  </span>
        <span *ngIf="option" >| CHECK-OUT:  {{payload.departureDate}} </span>

        <i class="icon-multiple-users-silhouette px-2" aria-hidden="true"></i>{{adults}}
        <i class="icon icon-menor px-2" aria-hidden="true"></i> {{childs}}
        <i class="icon-moon-phase-outline px-2" aria-hidden="true"></i> {{item.nights}}
      </span>
    </span>
  </span>
</div>

<div *ngIf="itemsSelect.length==0" class="py-1">

  <span class="p-0 d-flex flex-column">

    <span class="name-hotel">Reserve Date:</span>
    <span class="p-0">

      <span class="descrip-hotel d-flex">

        <span>CHECK-IN : {{payload.arrivalDate}}  </span>
        <span *ngIf="option" >| CHECK-OUT:  {{payload.departureDate}} </span>

      </span>
    </span>


  </span>
</div>
