<div class="card card-info d-flex flex-column my-3 pb-1 border-table-current">
  <span class="card-info-title flex-column label-current ml-3 p-1">
    <span style="margin-left:3px;">CURRENT DAYS</span>
  </span>
  <div class="d-flex px-4 pt-2">
    <div class="flex-column d-flex" style="width: 265px;">
      <span class="card-info-title flex-column mb-1">
        <span>Room: </span>
      </span>
      <span class=" flex-column description-modal"> {{item.title}} </span>
      <span class=" flex-column description-modal"> {{item.roomType}} </span>
    </div>
    <div class="flex-column px-2 d-flex max-people">
      <span class="card-info-title mb-1">
        <span>Fecha In: </span>
      </span>
      <span class="description-modal"> {{ item.arrivalDate }} </span>
      <span class="description-modal card-info-title" style="font-style: italic; font-weight: 800 !important;"> {{item.nights}}
        noches</span>
    </div>
    <div class="flex-column px-2 d-flex rooms-price">
      <span class="card-info-title mb-1">
        Fecha Out:
      </span>
      <span class="description-modal"> {{ item.departureDate }} </span>
    </div>
    <div class="flex-column px-2 d-flex options">
      <span class="card-info-title mb-2">
        <span>PRECIO ANTERIOR</span>
      </span>
      <span class="description-modal" aria-hidden="true">
        <span class="card-info-title"> USD $ {{ item.netPriceBooking | number:'1.2-2' }}</span>
      </span>
      <div class="flex-column d-flex mt-4">
        <span class="card-info-title flex-column">
          <span class="free-cancelation"> FC </span>
          <span class="description-modal title-fc" class="ml-1"> FREE CANCELATION </span>
        </span>
        <span class="flex-column mb-2">
          <span style="padding: 17px"> </span>
          <span class="description-modal">Hasta {{ subtractDays(item.cancellation_policy) | date:'dd/mm/yyyy h:m'  }} </span>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <div class="container" style="text-align: center;">
    <div class="row">
      <div class="col-12">
        <span class="i-arrow-down"></span>
      </div>
    </div>
  </div>
</div>

<div class="card card-info d-flex flex-column my-3 pb-1 border-table-new">
  <span class="card-info-title flex-column label-new ml-3 p-1">
    <span style="margin-left:3px;">NEW DAYS </span>
  </span>
  <div class="d-flex px-4 pt-2">
    <div class="flex-column d-flex">
      <span class="card-info-title flex-column mb-1" style="width: 265px;">
        <span>Room: </span>
      </span>
      <span class=" flex-column description-modal"> {{roomSelect.roomType}} </span>
    </div>
    <div class="flex-column px-2 d-flex max-people">
      <span class="card-info-title mb-1">
        <span>Fecha In: </span>
      </span>
      <span class="description-modal"> {{ item.arrivalDate }}</span>
      <span class="description-modal card-info-title" style="font-style: italic; font-weight: 800 !important;"> (9 noches)</span>
    </div>
    <div class="flex-column px-2 d-flex rooms-price">
      <span class="card-info-title mb-1">
        Fecha Out:
      </span>
      <span class="description-modal">{{ item.departureDate }}</span>
    </div>
    <div class="flex-column px-2 d-flex options">
      <span class="card-info-title mb-2">
        <span>PRECIO NUEVO</span>
      </span>
      <span class="description-modal" aria-hidden="true">
        <span class="card-info-title"> USD $ {{ roomSelect.optionNightsNetTotalProf | number:'1.2-2' }}</span>
      </span>
      <div class="flex-column d-flex mt-4">
        <span class="card-info-title flex-column">
          <span class="free-cancelation"> FC </span>
          <span class="description-modal title-fc" class="ml-1"> FREE CANCELATION </span>
        </span>
        <span class="flex-column mb-2">
          <span style="padding: 17px"> </span>
          <span class="description-modal">Hasta {{ subtractDays(roomSelect.cancellation_policy) | date:'dd/mm/yyyy h:m'  }} </span>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="card card-info d-flex flex-column my-3 pb-1" style="border: none;">
  <div class="row mr-1">
    <div class="col-5">
    </div>
    <div class="col-7 pt-4 pb-3" style="background-color: #f0f0f0; font-size: 16px; font-weight: 600;">
  
      <div class="row pb-1" style="font-size: 16px; font-weight: 600;">
        <div class="col-7 pl-0 pr-2" style="text-align: end; ">TARIFA ACTUAL</div>
        <div class="col-5">USD $ {{ item.netPriceBooking | number:'1.2-2' }}</div>
      </div>

       <div class="row bg_rate_new">
        <div class="col-7 pt-3 pl-0 pr-2" style="line-height: 15px;padding-bottom: 10px;">
          <div class="flex-column d-flex">
            <span class="flex-column" style="text-align: end !important; color: #fff;">
              <span> NUEVA TARIFA </span>
            </span>
            <span class="flex-column" style="text-align: end !important">
              <span style="font-size: 11px !important;"> + USD {{ (item.netPriceBooking -roomSelect.optionNightsNetTotalProf)  | number:'1.2-2' }}  SOBRE PRECIO ACTUAL </span>
            </span>
          </div>
        </div>
        <div class="col-5 pt-3" style="color: #fff; font-size: 19px !important;">USD $ {{ roomSelect.optionNightsNetTotalProf | number:'1.2-2' }}</div>
        </div>

        <div class="row pt-2" style="font-size: 16px; font-weight: 600;">
          <div class="col-7 pl-0 pr-2" style="text-align: end; ">PRECIO TOTAL DE LA RESERVA</div>
          <div class="col-5">USD $ {{ roomSelect.optionNightsNetTotalProf | number:'1.2-2' }}</div>
        </div>

      <div class="row" style="font-size: 12px; font-weight: 600;">
        <div class="col-7 pl-0 pr-2 pt-1" style="text-align: end; ">Total Pendiente</div>
        <div class="col-5">USD $ {{ item.netPriceBooking | number:'1.2-2' }}</div>
      </div>

      <div class="row" style="font-size: 12px; font-weight: 600;">
        <div class="col-7 pl-0 pr-2 pt-1" style="text-align: end; ">Total Reserva</div>
        <div class="col-5">USD $ {{ roomSelect.optionNightsNetTotalProf | number:'1.2-2' }}</div>
      </div>
    </div>
  </div>
</div>
