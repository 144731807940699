<div class="row p-0 mt-2 mb-3">
  <div class="col-2 pr-2">
    <div  class="conteiner-icon bg-gray p-2">
      <img src="/assets/img/app/little-icons/adventure-sports-white.svg" class="img-fluid" alt="">
    </div>
  </div>

  <div class="col-10">
    <div class="row">
      <div class="col-12">
        <p class="title font-poppins pl-2">
          {{stingUpperCase(item.title)}}
        </p>
      </div>
      <div class="col-12">
        <i class="icon-placeholder font-verde pl-2"></i>
        <span class="font-poppins location-text">{{item.location}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <p class="subtitle font-poppins pl-2">
          {{stingUpperCase(item.name)}}
          <i class=" icon icon-adults" aria-hidden="true">&nbsp;&nbsp;</i>
          {{item.payloadUpdate?.products[0]?.others?.tours?.adults}} &nbsp;
          <i class="icon icon-child" aria-hidden="true">&nbsp;&nbsp;</i>
          {{item.payloadUpdate?.products[0]?.others?.tours?.children?.length}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- <span class="p-0 d-flex flex-column align-content-center">
  <span class="title">
    <strong class="title-cap">
      <i class="icon icon-actividades_exterior">&nbsp;</i>{{item.title}}</strong>
  </span>
  <span class="p-0 subtitle">
    <i class=" icon icon-multiple-users-silhouette" aria-hidden="true"></i>
    {{item.payloadUpdate?.products[0]?.others?.tours?.adults}} Adults  
    <i class="icon icon-menor" aria-hidden="true"></i>
    {{item.payloadUpdate?.products[0]?.others?.tours?.children?.length}} Children
</span>
<span class="p-0">
    <span class="subtitle">
        {{item.name}} | {{item.duration}} 
      </span>
</span>
 <p class="p-0 description">Status {{item.status_id}} </p>
<span class="p-0">
    <p class="p-0 description">
       
    </p>
</span> -->