<!-- <div class="width-100" *ngIf="isCheckIn && dato"> -->
<div class="row justify-content-between mb-3">
    <div class="col-2 text-right pr-0 my-auto">
      <span class="circle">
        <i class="icon-small icon-seguro iconstart text-center pl-1"></i>
      </span>
    </div>
    <div class="col p-0 ml-2 item-detail">
      <div class="d-flex ">
        <div class="col-xg-11 col-lg-11 px-0 m-0 flex-column font-descrip">
          <span class="title-item d-flex align-items-center">
            <span class="pr-3">{{item.title}}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="col-4 text-center">
      <span classs="align-items-center d-flex ml-5 pl-5 pr-0" id="expiration-date">
        21 DEC 2018
      </span>
    </div>
  </div>
