import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { setting } from '../../../../setting';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private _http: HttpClient) {

  }
  getArticle(type: number, id: number, clientId: number): Observable<any> {
    const url = `${setting.authApiUrl}article/${type}/${id}/${clientId}`
    return this._http.get<any>(url);
  }
}
