import { Component, OnInit, Input, ViewContainerRef, ElementRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';

import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { UbicacionesService } from '../../service/ubicaciones.service';
import { RestService } from '../../../../shared/rest.service';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap';
import { CustomValidator } from '../../../../shared/customValidators';
//import { ToastsManager } from 'ng2-toastr';

import { searchPayload } from '../../../../utils/search-functions';
import { PTHEMES } from '../../../../home/search-box/categories/pthemes/response/searchResponsePthemes';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';
@Component({
  selector: 'usb-pthemes',
  templateUrl: './pthemes.component.html',
  styleUrls: ['./pthemes.component.scss']
})
export class PthemesComponent implements OnInit {


  @Input() _close: boolean;

  @Output() origin = new EventEmitter<any>();
  @Output() modal = new EventEmitter<string>();
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();


  @ViewChild('dpDeparture') datepickerDeparture: BsDatepickerDirective;
  @ViewChild('dpArrival') datepickerArrival: BsDatepickerDirective;

  @ViewChild("destination") myInputField: ElementRef;
  
  _form: FormGroup;
  minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  _maxPersons: any; // : [0,1,2,3,4,5];
  isCollapsed = false;
  _longDestination: string;
  destiny: any;
  selectedDestiny: any;
  _resultsQuery: any;
  _findData: boolean;
  noMostrar: boolean = false;
  passengers: string;

  constructor(
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private _router: Router,
    private _restService: RestService,
    private ubicacionesService: UbicacionesService,
    public vcr: ViewContainerRef,
//    
    public userService: UserService
  ) {
    this._maxPersons = Array.from(Array(51).keys());
    this.minDate = new Date();
    this.dpConfig.containerClass = 'theme-red';
    //this.toastr.setRootViewContainerRef(this.vcr);
  }

  ngOnInit() {
    this.passengers = "Pasajeros";
    this.initForm();
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.myInputField.nativeElement.focus();
    }, 500);
  }

  initForm() {
    this._form = this.fb.group({
      destination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      arrivalDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('departureDate').value !== null &&
          this._form.get('departureDate').value !== undefined
        ) {
          const valid = (c.value < this._form.get('departureDate').value) ? null : { invalidDate: true };
          this._form.controls['departureDate'].setErrors(valid);
          return (c.value < this._form.get('departureDate').value) ? null : { invalidDate: true };
        }
      }
      ])],
      departureDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('departureDate').value !== null &&
          this._form.get('departureDate').value !== undefined
        ) {
          const valid = (c.value > this._form.get('arrivalDate').value) ? null : { invalidDate: true };
          this._form.controls['arrivalDate'].setErrors(valid);
          return (c.value > this._form.get('arrivalDate').value) ? null : { invalidDate: true };
        }
      }
      ])],
      qtyAdults: [0, [Validators.required, Validators.min(1)]],
      qtyChilds: [0, Validators.required],
      childsage: this.fb.array([]),
    });

  }
  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    if ((this.selectedDestiny !== undefined) && (this.selectedDestiny !== null)) {
      if (event) {
        event.preventDefault();
      }
      this._emitLoading(true);
      const payload = searchPayload('PTHEME', '', form, this.selectedDestiny);

      this._restService.doPost('app', 'search', dummy || payload).subscribe(
        (response:any) => {
          const payloadArray = [searchPayload('PTHEME', '', form, this.selectedDestiny)];
          this.payload.emit(payloadArray);
          if (response !== null && response.results !== null) {
            this._setSearchIdInPThemes(response);
            this._setArrivalAndDepartureDate(response, form);
          }
          this._handleResponse(response, 'PTHEMES', form);
          this._emitOrigin('PTHEMES');
          this._emitLoading(false);
          this.results.emit(PTHEMES);   /// temporal hasta q exista resultados reales

          this._router.navigate(['/results']);
        }, error => {
          this._emitLoading(false);
          //this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
        }
      );
    } else {
      this._form.get('destination').setErrors({ invalidDestination: true });
    }
    if (event) {
      event.preventDefault();
    }

  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }


  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = form.value['arrivalDate'];
        this._resultsQuery.destination = this._longDestination;

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = 'No results found for the search';
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
       /// this.results.emit(arrayResults);
      

      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = 'No results found for the search';
      this.results.emit(this._resultsQuery);
    }
  }

  _setArrivalAndDepartureDate(response: any, form) {
    response.results.forEach(obj => {
      obj.dateIni = moment(form.value['arrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
      obj.dateEnd = moment(form.value['departureDate'].toString().split(',')[0]).format('YYYY-MM-DD');
    });
  }

  _setSearchIdInPThemes(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  _addChild() {
    setTimeout(() => {
      const ages: any = this._form.get('childsage') as FormArray;
      const qty: number = +this._form.value['qtyChilds'];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  formArray(form: FormGroup, key: string) {
    return (<FormArray>form.get(key)).controls;
  }
  // tslint:disable-next-line:one-line
  mostrarMensaje() {
    // tslint:disable-next-line:curly
    this.noMostrar = false;
    if (+this._form.value['qtyAdults'] === 0) {
      this.noMostrar = true;
      return true;
    }

    return false;
  }


  /***********************************************Auto Complete********************************************/
  _myListFormatter(data: any): string {
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _myCallback(data: any): string {
    this._longDestination = this.destiny['cityName'];
    this.selectedDestiny = this.destiny['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }
  observableSource = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getCitys(keyword);
  }
  /***********************************************Auto Complete********************************************/


  _openModal() {
    this.isCollapsed = true;
    this._close = false;
    setTimeout(() => {
      this.modal.emit('true');
    }, 100);
  }
  _over() {
    this.modal.emit('false');
  }

  clickOutside(event: any) {
    this.setPassanger();
  }
  _confirm() {
    if (!this.mostrarMensaje()) {
      this.isCollapsed = false;
    }
    this.setPassanger();
  }
  setPassanger() {
    let adults = this._form.value['qtyAdults'];
    let childs = this._form.value['qtyChilds'];

    let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
    adults = adults != undefined ? adults + " Adultos " : null;
    childs = childs != undefined ? " - " + childs + " Niños " : null;

    this.passengers = noSelected ? "Pasajeros" : adults + childs;
  }

  async nextDate(event: any){
    if(event!==null){
      await this.datepickerArrival.toggle();
      this.datepickerDeparture.toggle();
      return;
    }
  }
}
