import { Component, OnInit, Input, ViewContainerRef, Output , EventEmitter, ViewChild} from '@angular/core';
import { ModalSearchTransportsComponent } from '../../shared/modals-search/modal-search-transports/modal-search-transports.component';
//import { ToastsManager } from 'ng2-toastr';
@Component({
  selector: 'usb-modals-search',
  templateUrl: './modals-search.component.html',
  styleUrls: ['./modals-search.component.scss']
})
export class ModalsSearchComponent implements OnInit {


  public options = {
    position: ['top', 'right'],
    timeOut: 460,
    lastOnBottom: true,
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    maxLength: 60
  };
  //@Input() __payload: any;
  __payload: any;
  @Input() origin: any;
  @Input() _index: any;
  @Input() _ori: any;
  @Input() cityIataCode?: any;
  //@Input() __multiModal: any;
  @Input() modalRoomRef: any;
  @Output() emitReload = new EventEmitter<any>();
  @Output() emitIndex  = new EventEmitter<any>();
  @Output() isLoading  = new EventEmitter<boolean>();
  @ViewChild(ModalSearchTransportsComponent) modalSearchTransports: ModalSearchTransportsComponent;

  constructor(
    //public toastr: ToastsManager
    ) { }

  ngOnInit() {
  }

  _emitReload(payload: any) {
    this.emitReload.emit(payload);
    this._onLoading(true);
  }

  _onLoading(isLoading: boolean) {
    this.isLoading.emit(isLoading);
  }

  _emitIndex(index: any) {
    this.emitIndex.emit(index);
  }
  hideModal() {
      this.modalRoomRef.hide();
  }
  _initLoadData(payload: any) {
    this.__payload = payload;
    this.modalSearchTransports._initLoadData(payload);
  }

}
