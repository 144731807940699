import { Component, OnInit, Inject } from "@angular/core";
// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ItineraryService } from "../itinerary.service";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CarouselConfig } from "ngx-bootstrap/carousel";

@Component({
  selector: "usb-modal-sync-product",
  templateUrl: "./modal-sync-product.component.html",
  providers: [
    {
      provide: CarouselConfig,
      useValue: {
        interval: 2500,
        noPause: false,
        showIndicators: false,

      },
    },
  ],
  styleUrls: ["./modal-sync-product.component.scss"],
})
export class ModalSyncProductComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  children = 0;
  adults = 0;
  data: any;
  images: any = [
    "assets/img/app/loader-images/load1.jpg",
    "assets/img/app/loader-images/load2.jpg",
    "assets/img/app/loader-images/load3.jpg",
  ];

  slides = [
    { image: "assets/img/app/loader-images/load1.jpg", text: "First" },
    { image: "assets/img/app/loader-images/load2.jpg", text: "Second" },
    { image: "assets/img/app/loader-images/load2.jpg", text: "Third" },
  ];
  noWrapSlides = false;
  showIndicator = true;

  constructor(
    // public bsModalRef: BsModalRef,
    private itineraryService: ItineraryService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public item: any,
    public dialogRef: MatDialogRef<ModalSyncProductComponent>,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // console.log("data ModalSyncProductComponent", this.item);
/*
    if (this.payload == undefined && this.payload.length === 0) {
      this.payload = JSON.parse(localStorage.getItem("payload"));
    }

    if (this.payload[0]) {
      this.data = this.payload[0];
    } else {
      this.data = this.payload;
    }
    

    const rooms = this.data.others.hotels.roomConfiguration;
    rooms.forEach((room) => {
      this.children += this.data ? room["children"].length : 0;
      this.adults += this.data ? parseInt(room["adults"]) : 0;
    }); */
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
