import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { UsblickCommonEnum } from "./app/pages/com.usblick.common/usblick-common-enum";
import { setting } from '../setting';



@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor{

    intercept(
        req: HttpRequest<any>, 
        next: HttpHandler): Observable<HttpEvent<any>>    
    {

        //Escribir codigo
		// headers.append('Accept', 'application/json');
		// headers.append('Content-Type', 'application/json');
		// headers.append('client-id', environment.client_id);
		// headers.append('client-secret', environment.client_secret);
		// headers.append('Authorization', 'Bearer ' + this.getCookie('auth_token'));

        const token = this.getCookie("auth_token");
        //console.log("verificamos el token  ", token);

        if(!req.headers.has('Content-Type')){
            req = req.clone({headers: req.headers.set('Content-Type', 'application/json') });
        }

        if(!req.headers.has('Accept')){
            req = req.clone({headers: req.headers.set('Accept', 'application/json') });
        }

        if(!req.headers.has('client-id')){
            req = req.clone({headers: req.headers.set('client-id', environment.client_id) });
        }

        if(!req.headers.has('client-secret')){
            req = req.clone({headers: req.headers.set('client-secret', environment.client_secret) });
        }

        if(!req.headers.has('ApiUser')){
            req = req.clone({headers: req.headers.set('ApiUser', setting.ApiUser) });
        }

        if(!req.headers.has('ApiKey')){
            req = req.clone({headers: req.headers.set('ApiKey', setting.ApiKey) });
        }

        if(this.getCookie('auth_token')){
            req = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + this.getCookie('auth_token')) });
        }


        return next.handle(req);
        //throw new Error("Method not implemented.");
    }

    getCookie(name : string){
		//console.log ("document.cookie", document.cookie);
		var nameEQ = encodeURIComponent(name) + UsblickCommonEnum.equalChar;
	    var ca = document.cookie.split(UsblickCommonEnum.dotCommaChar);
	    for (var i = 0; i < ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) === UsblickCommonEnum.spaceChar)
	            c = c.substring(1, c.length);
	        if (c.indexOf(nameEQ) === 0)
	            return decodeURIComponent(c.substring(nameEQ.length, c.length));
	    }
	    return null;
	}
    
}