import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItineraryService } from '../../../../shared/itinerary.service';
//import { ToastsManager } from 'ng2-toastr';

@Component({
  selector: 'usb-insurance-result',
  templateUrl: './insurance-result.component.html',
  styleUrls: ['./insurance-result.component.scss']
})
export class InsuranceResultComponent implements OnInit {

  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;
  @Input() disbaledCompare: boolean;
  @Input() _itemToCompare: any;

  @Output() addToCompare = new EventEmitter<any>();
  @Output() toCompare = new EventEmitter<any>();
  

  constructor(
//    
    private itineraryService: ItineraryService
  ) { }

  ngOnInit() {
  }

  checkDisbaledCompare(): boolean{
    let index = this._itemToCompare.findIndex(val=>val.title==this.result.title);
    if(this.disbaledCompare && index==-1){
      return true;
    } 
    else{
      return false;
    }
  }

  showCompareButton(){
    let index = this._itemToCompare.findIndex(val=>val.title==this.result.title);
    if(index!==-1){
      return true;
    } 
    else{
      return false;
    }
  }

  add() {
    const optionToReserved = this.result.additionalInfo;
    this.addToItinerary(optionToReserved, this.result);
  }

  addToItinerary(item: any, result: any) {
    item.categoryName = this.origin,
      // item.form = this.form !== undefined ? this.form.value : undefined,
      item.payload = this.payload,
      item.id = result.id + "-" + item.id,
      item.idProduct = result.productId,
      //item.destination = result.destination,
      item.origin = result.origin,
      item.nameAdapter = result.nameAdapter,
      item.searchId = this.arrayResults[0].searchId,
      item.providerId = result.providerId,
      item.providerName = result.providerName,
      item.title = result.title,
      item.location = result.additionalInfo.location,
      item.arrivalDate = result.dateIni,
      item.departureDate = result.dateEnd,
      item.updateDate = new Date(),
      item.payloadUpdate = this._payloadUpdateInsurance(this.payload);
    item.destination = this.itineraryService.getDestinationInfo(this.form, item);
    this.itineraryService.sendMessageSubscriptorLoading(true);
    this.itineraryService.add(item).subscribe(
      (response:any) => {
        console.log('Product added successfully', response);
        if (typeof response === 'string') {
          //this.toastr.error(response);
        } else {
          //this.toastr.success('Product added successfully to your itinerary');
        }
        this.itineraryService.sendMessageSubscriptorLoading(false);
      },
      error => {
        console.log('Product not added', error);
        this.itineraryService.sendMessageSubscriptorLoading(false);
        //this.toastr.error('An error occurred, product was not added', 'Error');
      }
    );
  }

  _payloadUpdateInsurance(payload: any): Object {

    console.log(this.origin);

    return {
      products: [{
        providerId: this.result.providerId,
        nameAdapter: this.result.nameAdapter,
        categoryId: this.result.categoryId,
        productId: this.result.id,
        productName: this.result.title,
        origin: this.result.origin,
        destination: this.result.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.result.dateIni,
        departureDate: this.result.dateEnd,
        qtyProduct: 1,
        qtyPax: 0,
        others: {
          insurance: payload.others.insurance
        }
      }]
    };
  }

  _addToCompare(event: any, item: any){
    let objeto = {item: item, action: (event.target.checked) ? 'add' : 'del' };
    this.addToCompare.emit(objeto);
  }

  _toCompare(){
    this.toCompare.emit();
  }
}
