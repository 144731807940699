import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ShellComponent } from './shell/shell.component';
import { HeaderComponent } from './shell/header/header.component';
import { FooterComponent } from './shell/footer/footer.component';
import { I18nService } from './i18n.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../shared/shared.module';
import { AuthorizatedGuard } from "../pages/com.usblick.common/guard/authorizated.guard";
import { AuthService } from "../pages/com.usblick.common/auth/auth.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from '../../http-config.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
// import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap';

// export function createHttpService(backend: ConnectionBackend,
//                                   defaultOptions: RequestOptions,
//                                   httpCacheService: HttpCacheService) {
//   return new HttpService(backend, defaultOptions, httpCacheService);
// }

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,

    //NgbModule,
    RouterModule,
    //BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    SharedModule,
    // CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
    ShellComponent,
    FooterComponent
  ],
  providers: [
    // AuthenticationService,
    // AuthenticationGuard,
    { provide: HTTP_INTERCEPTORS, useClass:HttpConfigInterceptor, multi: true },
    AuthorizatedGuard,
    AuthService,
    //I18nService,
    //HttpCacheService,
    // {
    // provide: Http,
    // deps: [XHRBackend, RequestOptions, HttpCacheService],
    // useFactory: createHttpService
    // },
  ],
  exports: [
    HeaderComponent,
    ShellComponent,
    FooterComponent
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
