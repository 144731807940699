import { Component, OnInit, Input, ViewContainerRef, ElementRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';

import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { UbicacionesService } from '../../service/ubicaciones.service';
import { RestService } from '../../../../shared/rest.service';
import { BsDatepickerConfig, BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RoomConfigurationItem } from '../hotels/roomConfigurationItem';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';
import { ItineraryService } from '../../../../shared/itinerary.service';
import { MaterialModalConfirmComponent } from '../../../../shared/material-modal-confirm/material-modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';
//import { ToastsManager } from 'ng2-toastr';

@Component({
    selector: 'usb-multi-destination',
    templateUrl: './multi-destination.component.html',
    styleUrls: ['./multi-destination.component.scss'],
    providers: [UbicacionesService]
})
export class MultiDestinationComponent implements OnInit {

    @Input() form: FormGroup;
    @Output() payload = new EventEmitter<any>();
    @Output() results = new EventEmitter<any>();
    @Output() isLoading = new EventEmitter<boolean>();
    @Output() origin = new EventEmitter<any>();
    @Input() _close: boolean;
    @Output() modal = new EventEmitter<string>();

    modalRef: BsModalRef;
    _findData: boolean;
    _resultsQuery: any;
    _form: FormGroup; // Form Group Activities
    _maxPersons: any; // : [0,1,2,3,4,5];
    minDate: Date;
    dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    isCollapsed = false;
    validate = false;
    roomsAndPassengers: string;

    constructor(
        private fb: FormBuilder,
        private _routeActive: ActivatedRoute,
        private _router: Router,
        private _restService: RestService,
        private ubicacionesService: UbicacionesService,
        private modalService: BsModalService,
        public userService: UserService,
        private _itineraryService:ItineraryService,
        public dialog:MatDialog,
        //private toastr:ToastsManager,
        public vcr: ViewContainerRef,
    ) {
        this._maxPersons = Array.from(Array(5).keys());
        this.minDate = new Date();
        this.dpConfig.containerClass = 'theme-red';
        //this.toastr.setRootViewContainerRef(this.vcr);
    }

    ngOnInit(): void {
        this.roomsAndPassengers = "Habitaciones y pasajeros";
        this._form = this.fb.group({
            // Search basic
            arrivalDate: [null, Validators.required],
            room: this.fb.array([]),
            accessRoom: [false],
            qtyProduct: [1, [Validators.required, Validators.min(1), Validators.max(10)]],
        });
        this._addRoom();

        console.log("userService.itineraryActive", this.userService.itineraryActive);
    }

    _addRoom() {
        const room = this._form.get('room') as FormArray;
        room.push(this.fb.group({
            qtyAdults: [0, [Validators.required, Validators.min(1)]],
            qtyChilds: [0, Validators.required],
            childsage: this.fb.array([])
        }));
        if (room.length < 1 || !room.length) {
            this._form.get('qtyProduct').setValue(0);
        } else {
            this._form.get('qtyProduct').setValue(room.length);
        }
    }

    _removeRoom(index: number) {
        const room = this._form.get('room') as FormArray;
        room.removeAt(index);
        this._form.get('qtyProduct').setValue(room.length);
    }

    // realiza la busqueda en el endpoint
    _onSubmit(form: FormGroup, event?: Event, template?: TemplateRef<any>) {

        console.log("this.userService.itineraryActive", this.userService.itineraryActive);
        console.log("form", form);

        if(this.userService.itineraryActive.length>0 && this.userService.itineraryActive[0].arrivalDate){

            let formDate = moment(form.value.arrivalDate.toString().split(',')[0]).format('YYYY-MM-DD');
            console.log("formDate", formDate);

            if(this.userService.itineraryActive[0].arrivalDate != formDate){

                console.log("Las fechas son diferentes, abre modal");
               const message = 'Desea cambiar la fecha del file, ¿Se borrara los items actuales?';
                var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
                    width: '465px',
                    data: {message:message},
                    disableClose: true
                });

                dialogRef.afterClosed().subscribe(result => {
                    console.log('The dialog was closed', result);
                    if(result){

                        this._deleteItems(form, event);

                    }else{
                        this.directSubmitActualDate(form, event);
                    }
                });

                  //this.openModal(template);


            }else{
                console.log("Las fechas son iguales, no abre modal y sigue");
                this.directSubmit(form, event,null);
            }

        }else{
            console.log("itinerairo viene vacio");
            this.directSubmit(form, event, null);
        }


    }

    directSubmit(form: FormGroup, event: Event, closeModal: boolean){
        if (event) {
            event.preventDefault();
        }

        if(closeModal){
            this.closeModal();
        }

        const payload = this._payload(form);
        console.log("payload enviado", payload);
        localStorage.setItem('payload', JSON.stringify(payload));
        this._emitLoading(true);
        this.payload.emit(payload);
        this._emitOrigin('MULTIDESTINATION');
        this._router.navigate(['/multi-destination']);
    }

    directSubmitActualDate(form: FormGroup, event: Event){

        console.log("form not mod", form);
        form.get('arrivalDate').setValue(this.userService.itineraryActive[0].arrivalDate);
        console.log("form mod", form);

        if (event) {
            event.preventDefault();
        }
        //this.closeModal();
        const payload = this._payload(form);
        console.log("payload enviado", payload);
        localStorage.setItem('payload', JSON.stringify(payload));
        this._emitLoading(true);
        this.payload.emit(payload);
        this._emitOrigin('MULTIDESTINATION');
        this._router.navigate(['/multi-destination']);
    }

    _payload(form: FormGroup) {
        const room = form.get('room') as FormArray;
        const payload = {
            providersId: null,
            categoriesId: [0],
            origin: '',
            destination: form.value['destination'],
            priceFrom: 0,
            priceTo: 20000,
            arrivalDate: moment(form.value['arrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD'),
            departureDate: null,
            qtyProduct: +form.value['qtyProduct'],
            qtyPax: this._getAdultsChildrenQty('qtyAdults', form.value['room']),
            others: {
                serialized: false,
                maxRequestTimeOnSeconds: 20,
                minNumResults: 1,
                maxNumResultsPerAdapter: "",
                hotels: {
                    roomConfiguration: this._getRoomDist(form)
                }
            }
        };
        return payload;
    }

    _deleteItems(form: FormGroup, event: Event){

        //verifico si hay mas de 1 destino
        if(this.userService.itineraryActive[0].destination.length > 0){

            let index = 0;
            this.userService.itineraryActive[0].destination.forEach(destiny => {

                const itemToDelete = this.userService.itineraryActive[0].destination[index];
                console.log(itemToDelete);

                const items = [];

                for (let i = 0; i < this.userService.itineraryActive[0].items.length; i++) {

                    //verifica si cada item tiene payload Update
                    if (this.userService.itineraryActive[0].items[i].payloadUpdate !== undefined && this.userService.itineraryActive[0].items[i].payloadUpdate !== null) {

                        //verifica si el destino del producto es el mismo destino que el destino padre
                        if (this.userService.itineraryActive[0].items[i].payloadUpdate.products[0].destination === itemToDelete.iata_code) {
                            console.log("encontre item para eliminar");
                            items.push(this.userService.itineraryActive[0].items[i]);
                        }
                    }
                }

                //elimina los q encontro
                if (items.length > 0) {
                  for (let i = 0; i < items.length; i++) {
                      console.log("elimino item");
                    this._itineraryService.remove(items[i]);
                  }
                }

                var departureDateForm;

                //si es el primer destino asigna fecha del formulario
                if(index>0){
                    departureDateForm = this.userService.itineraryActive[0].destination[index-1].destination_info.departure;
                    console.log("newDepartureDate", departureDateForm);
                    //si es el segundo o mas, asigna fecha del destino anterior
                }else{
                    departureDateForm = moment(form.value['arrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD'),
                    console.log("newDepartureDate", departureDateForm);
                }

                var nights = destiny.destination_info.nights;
                console.log("nightsObj", nights);

                var newDepartureDate = moment(moment(departureDateForm)).add(nights, 'days').format('YYYY-MM-DD');
                console.log("newDepartureDate despues de suma", newDepartureDate);

                destiny.destination_info.arrival = departureDateForm;
                destiny.destination_info.departure = newDepartureDate;


                destiny.destination_info.roomDist = this._getRoomDist(form, "");
                destiny.destination_info.adults = this._getAdultsChildrenQty('qtyAdults', this._form.value['room']);
                destiny.destination_info.childs = this._getAdultsChildrenQty('qtyChilds', this._form.value['room']);
                destiny.destination_info.rooms = this._form.value['room'].length;


                if(index == this.userService.itineraryActive[0].destination.length - 1){
                    console.log("index entro al ultimao", this.userService.itineraryActive);
                    this.userService.itineraryActive[0].destination = [];
                    this._itineraryService.emitActiveItinerary(this.userService.itineraryActive, true);
                    this.directSubmit(form, event,null);
                }
                index++;
                console.log("index ++", index);
            });
        }

    }

    _handleResponse(response: any, categoryName: string, form: FormGroup) {
        if (response && response.results != null) {
            try {
                this._resultsQuery = response.results;
                this._resultsQuery.categoryName = categoryName;
                this._resultsQuery.arrivalDate = form.value['arrivalDate'];

                if (!response.results.length) {
                    this._resultsQuery.noResultMessage = 'No results found for the search';
                }
                // se anexa el formulario actual
                this._resultsQuery.form = form;
                this.results.emit(this._resultsQuery);
            } catch (e) {
                this._resultsQuery = [];
                this._restService.page = 1;
            }
        } else {
            this._resultsQuery = [];
            this._resultsQuery.noResultMessage = 'No results found for the search';
            this.results.emit(this._resultsQuery);
        }
    }




    formRoom(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }

    _setAges(form?: FormGroup) {
        const ages = [];
        let cont = 0;
        for (let i = 0; i < form.value.room.length; i++) {
            const childsage = form.value.room[i].childsage;
            for (let j = 0; j < childsage.length; j++) {
                ages[cont] = { id: cont, age: childsage[j].age };
                cont++;
            }
        }
        return ages;
    }

    _getRoomDist(form?: FormGroup, item?:any) {
        const roomDist = [];
        const rooms = form.value.room;
        for (let i = 0; i < rooms.length; i++) {
            const people = [];
            const dist: RoomConfigurationItem = {
                adults: 0,
                children: [],
                boardNameCode: item.boardNameCode,
                condition:  item.rateClassId,
                roomId:item.roomId,
                roomCode:item.roomCode,
                bookingCode:item.bookingCode
            };
            const adults = rooms[i].qtyAdults;
            const children = rooms[i].childsage;
            for (let j = 0; j < children.length; j++) {
                people.push(children[j].age);
            }
            dist.adults = adults;
            dist.children = people;
            roomDist.push(dist);
        }
        return roomDist;
    }

    _getAdultsChildrenQty(key: string, room: Array<any>) {
        let qty = 0;
        key === 'qtyAdults' ? room.map(obj => obj.qtyAdults).forEach(element => qty += +element)
            : room.map(obj => obj.qtyChilds).forEach(element => qty += +element);
        return qty;
    }

    _addChild(item: FormGroup) {
        setTimeout(() => {
            const ages: any = item.get('childsage') as FormArray;
            const qty: number = +item.value['qtyChilds'];
            if (qty > ages.length) {
                for (let i = ages.length; i < qty; i++) {
                    ages.push(this.fb.group({
                        id: [i],
                        age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])]
                    }));
                }
            } else {
                for (let i = ages.length - 1; i >= qty; i--) {
                    ages.removeAt(i);
                }
            }
        }, 10);
    }

    _emitLoading(isLoading: boolean) {
        this._findData = isLoading;
        this.isLoading.emit(isLoading);
    }

    _emitOrigin(origin: any) {
        this.origin.emit(origin);
    }

    _confirm() {
        this.isCollapsed = false;
        this.validate = true;
        this.setRoomAndPassanger();
    }
    _openModal() {
        this.isCollapsed = true;
        this.validate = false;
        this._close = false;
        setTimeout(() => {
            this.modal.emit('true');
        }, 100);
    }
    _over() {
        this.modal.emit('false');
    }

    clickOutside(event: any) {
        this.setRoomAndPassanger();
    }
    compareDate(event: any) {
        const getArrivalDate = moment(event.toString().split(',')[0]).format('YYYY-MM-DD');
        const getDepartureDate = moment(event.toString().split(',')[1]).format('YYYY-MM-DD');
        if (getArrivalDate === getDepartureDate) {
            return false;
        }
    }

    /***********************************************Auto Complete********************************************/
    _myListFormatter(data: any): string {
        return `${data['cityName']} (${data['cityIataCode']})`;
    }

    observableSource = (keyword: any): Observable<any[]> => {
        return this.ubicacionesService.getCitys(keyword);
    }
    /***********************************************Auto Complete********************************************/

    setRoomAndPassanger() {
        let rooms = this._form.value['room'].length.toString();
        let adults = this._getAdultsChildrenQty('qtyAdults', this._form.value['room']).toString();
        let childs = this._getAdultsChildrenQty('qtyChilds', this._form.value['room']).toString();
        let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
        rooms = rooms != undefined ? rooms + " Hab. " : null;
        adults = adults != undefined ? " - " + adults + " Adultos " : null;
        childs = childs != undefined ? " - " + childs + " Niños " : null;

        this.roomsAndPassengers = noSelected ? "Habitaciones y pasajeros" : rooms + adults + childs;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    }

    closeModal(): void {
        this.modalRef.hide();
    }

    verifyItinerary(){
        if(this.userService.itineraryActive.length == 0){
            console.log("error");
            //this.toastr.warning("Verify itinerary information", 'Important!!');
        }else{
        }
    }

}
