import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';

@Component({
  selector: 'usb-option-p-themes',
  templateUrl: './option-p-themes.component.html',
  styleUrls: ['./option-p-themes.component.scss']
})
export class OptionPThemesComponent implements OnInit {

  @Input() option: any;
  @Input() select: any; // : [0,1,2,3,4,5];
  @Input() index: number;
  //@Input() toastr: ToastsManager;
  @Output() calculateTotalPrice = new EventEmitter();

  constructor() { }

  ngOnInit() {
    /*console.log("OptionPThemesComponent ");
    console.log("Option "+ this.option);
    console.log("select "+ this.select);
    console.log("index "+ this.index);
    console.log("Option "+ this.option);*/

   }

  
  public onSelectionChange(index: any) {
    //console.log("ItemSeleccionado "+  index);
    this.calculateTotalPrice.emit();
  }

}
