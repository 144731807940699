import { createAction, props } from "@ngrx/store";

export const setRoles = createAction (
  '[Auth] Set Roles',
  props<{roles: string[]}>()
);

export const setPermissions = createAction(
  '[Auth] Set Permissions',
  props<{permissions: string[]}>()
);

export const setAgent = createAction(
  '[Auth] load Agent',
  props<{agent: string[]}>()
)

export const loadAgency = createAction(
  '[Agency] Load Agency',
  props<{ clientId: number }>()
);

export const setAgency = createAction(
  '[Agency] Set Agency',
  props<{ agency: string[] }>()
);

// Categories

export const setCategories = createAction(
  '[Categories] Set Categories',
  props<{ categories: string[] }>()
);

export const loadCurrencyById = createAction(
  '[Currency] Load Currency By Id',
  props<{ id: number }>()
);

export const setCurrency = createAction(
  '[Currency] Set Currency',
  props<{ currency: string[] }>()
);

export const clearAuthState = createAction('[Auth] Clear State');
