import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'usb-cruises-result',
  templateUrl: './cruises-result.component.html',
  styleUrls: ['./cruises-result.component.scss']
})
export class CruisesResultComponent implements OnInit {

  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;

  constructor() { }

  ngOnInit() {
  }

}
