import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { RestService } from '../shared/rest.service';
import 'rxjs/add/operator/map';
import { UsblickCommonEnum } from '../pages/com.usblick.common/usblick-common-enum';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AgentsSummaryService {

  constructor(private http: HttpClient, private restService: RestService) { }

  // Método para calcular
   // categoryId: categoria a calcular
   // netPrice: precio neto o costo
   // priceProf: precio con incremento de ganancia sobre el neto
 
 
    getSummaryAgent(agentId: any, agencytId: any): any {
      const url = 'agentSummary/' +  + agentId + '/' + agencytId;
      console.log('entro----->', url);
        this.restService.doGet('app', url).subscribe(
        (response:any) => {
          console.log('response----->', response);
        return  response;
      }, err => {
        console.log('No existe información,agente');
      });
    }


}
