import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TruncateModule } from 'ng2-truncate';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalsComponent } from '../../../../shared/modals/modals.component';

@Component({
  selector: 'usb-activities-result',
  templateUrl: './activities-result.component.html',
  styleUrls: ['./activities-result.component.scss']
})
export class ActivitiesResultComponent implements OnInit {

  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;

  modalRef: BsModalRef;
  viewTour:boolean = false;

  constructor(private modalService: BsModalService, private dialog:MatDialog, public spinner:NgxSpinnerService) { }

  ngOnInit() {
   /* console.log("this.result", this.result);
    console.log("this.payload", this.payload);
    console.log("this.form", this.form);
    console.log("this.arrayResults", this.arrayResults); */
  } 

  openModal(template?: TemplateRef<any>) {

    var dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '860px',
      data: {
        modalRoomRef: this.modalRef,
        product: this.result, 
        productQty: this.payload.qtyProduct, 
        form: this.form, 
        origin: 'TOURS', 
        arrayResults: this.arrayResults, 
        payloadUpdate: this.payload,
      },
      //disableClose: true
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result){

        this.spinner.show();
      }
    });
  }

}
