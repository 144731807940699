<div id="{{'contentPDF-'+i }}">

  <div style="display:block;float:left;clear:both;width:100%">
    <div class="col-md-6 " style="float:left;max-width: 200px;">
      <div>
        <img src="assets/img/default-logo-agency.png" alt="logo" width="auto" height="58px">
      </div>
    </div>
    <div class="col-md-6" style="float:right;">
      <br>
      <div style="margin-left:160px;font-size:14px;color:#032541;line-height:60%">
        <p><b>{{agency.name_agency}}&nbsp;&nbsp;</b></p>
        <p>{{agency.email_user}}&nbsp;&nbsp;</p>
        <p>{{agency.phone}}&nbsp;&nbsp;</p>
      </div>
    </div>
  </div>

  
  <!-- <div style="height:1px;background-color: #032541 !important;display:block;float:left;clear:both;width:100%">
    <span>&nbsp;&nbsp;</span>
  </div> -->
 
  
  <!-- <br>  -->

  <!-- <div style="display:block;float:left;clear:both;width:100%;">
    <div class="col-md-4" style="float:left;width:525px;letter-spacing: -1px;">
      <h5 class="font-weight-bold m-0 text-capitalize" style="font-size: 16px;">{{item.title}}</h5>
      <p class="font-weight-bold m-0" style="font-size: 13px;">{{item.destination.iata_code}} - US</p>
      <p style="font-size:13px;">
        <span style="color: green"><b>check-in: </b></span> {{item.arrivalDate }} &nbsp;&nbsp;
        <span style="color: red"><b>check-out: </b> </span> {{item.departureDate }}
      </p>
    </div>


    <div class="col-md-4" style="float:left;width:195px;">
      <p class="font-weight-bold">TARIFA NETA</p>
      <p class="font-weight-bold">$ {{item.totalPrice | number:'1.2-2'}}</p>
    </div>
  </div> -->

</div>