import {AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[custom-validator]',
  })

export class CustomValidator{
    static customEmailValidator(control: AbstractControl): ValidationErrors {
        const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(((([a-zA-Z\-0-9]){2,15}\.){1,5})[a-zA-Z]{1,3})$/;
        if (control.value && !emailRegexp.test(control.value)) {
          return { invalidemail: true };
        }
      }
      static cellphoneValidator(control: AbstractControl): ValidationErrors {
        const cellphone = /^[0-9]{6,20}$/;
        if (control.value && !cellphone.test(control.value)) {
          return { invalidcellphone: true };
        }
      }
      static soloNumerosValidator(control: AbstractControl): ValidationErrors {
        const soloNumeros = /^[0-9]+$/;
        if (control.value && !soloNumeros.test(control.value)) {
          return { invalidsoloNumeros: true };
        }
      }

      static soloNumerosValidatorOne(control: AbstractControl): ValidationErrors {
        const soloNumeros = /^[1-9]+$/;
        if (control.value && !soloNumeros.test(control.value)) {
          return { invalidsoloNumeros: true };
        }
      }

      static phoneValidator(control: AbstractControl): ValidationErrors {
        const cellphone = /(^[+])+\d{1,3} \d{1,3}[-]\d{4,9}$/;
        if (control.value && !cellphone.test(control.value)) {
          return { invalidcellphone: true };
        }
      }
      static namesValidator(control: AbstractControl): ValidationErrors {
        const name = /(^[A-z\s]{2,50}$)|((^[A-z\s]{2,25})[-][A-z\s]{2,25}$)|((^[A-z\s]{2,25}) [A-z\s]{2,25}$)/;
        if (control.value && !name.test(control.value)) {
          return { invalidname: true };
        }
      }
      static fullNameValidator(control: AbstractControl): ValidationErrors {
        const name = /((^[A-z\s]{2,25})( [A-z\s]{2,25}?)?$)/;
        if (control.value && !name.test(control.value)) {
          return { invalidname: true };
        }
      }
      static countryValidator(control: AbstractControl): ValidationErrors {
        const country = /(^[A-z\s]{2,20}$)|((^[A-z\s]{2,10})[-][A-z\s]{2,10}$)|((^[A-z\s]{2,10}) [A-z\s]{2,10}$)/;
        if (control.value && !country.test(control.value)) {
          return { invalidcountry: true };
        }
      }

      static notOnlySpaceBlank(control: AbstractControl): ValidationErrors{
        if (control.value !== null && control.value !== undefined && control.value !==  '' && control.value.trim() ==  '') {
          return { spaceOnlyBlank: true };
         }
      }
}
