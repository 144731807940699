<div class="container-fluid">
    <div class="jumbotron text-center">
      <h1>
        <span translate>{{titulo}}</span>
      </h1>
      <p>
        <i class="fa fa-bookmark-o"></i><span translate>{{message}}</span> 
      </p>
    </div>
  </div>
