import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatArrivalDate } from '../../../../../../utils/Functions';
import { ItineraryService } from '../../../../../../shared/itinerary.service';

@Component({
  selector: 'usb-item-insurance',
  templateUrl: './item-insurance.component.html',
  styleUrls: ['./item-insurance.component.scss']
})
export class ItemInsuranceComponent implements OnInit {

  @Input() dato: any;
  @Input() pickUp? : number;
  @Input() isCheckIn: boolean;
  @Input() partialReserve: boolean;
  @Output() itemToPartial = new EventEmitter<number>();

  public formatDate = formatArrivalDate;

  constructor(private itineraryService: ItineraryService) { }

  ngOnInit() {
    //console.log(this.dato);
    //console.log('pickUp==>',this.pickUp);
  }

  removeItem(item: any) {
    console.log(item);
    this.itineraryService.remove(item);
  }

  toPartialReserve(event, item: any){
    if ( event.target.checked ) {
      this.itineraryService.updateItineraryPartial(item);
      this.itemToPartial.emit(1);
    } else {
      this.itineraryService.updateItineraryPartial(item,true);
      this.itemToPartial.emit(-1);
    }
  }

  fontPrice(price){
    if((price.length - 6) > 0){
      const fact = price.length - 6
      const size = 25 - (1.5 * fact)
      return size + 'px'
    }
  }
}
