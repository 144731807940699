<!-- PICKUP -->
<div class="row mt-3" *ngIf="isCheckIn">
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center text-center" style="max-height: 150px !important; overflow: hidden;">
        <img class="img-fluid" [src]="dato.images[0].thumbnail" alt="hotel" style="max-height: 150px;" width="100%">
      </div>
      <div class="col-7 hotel-item-description py-2 px-0">
        <div class="d-flex flex-column py-1">
          <!-- Title & location -->
          <div class="row px-3">
            <div class="d-flex flex-column" style="width: calc(100% - 150px);">
              <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{stingUpperCase(dato?.title)}}</p>
              <div style="margin-top: -5px !important;">
                <span class="sub-title">
                  {{stingUpperCase(dato?.gearbox)}}
                </span>
              </div>
            </div>
            <div class="d-flex flex-row" style="width: 150px;">
              <div class="py-0" style="margin-top: 1px; width: 98px !important;">
                <span style="width: 10%" *ngIf="dato.status_id === 1" class="status product-status-saved">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 2" class="status product-status-reserved">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 3" class="status product-status-locked">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 4" class="status product-status-denied">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 5" class="status product-status-cancelled">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 6" class="status product-status-paid-agency">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 7" class="status product-status-paid-provider">{{dato.status}}</span>
                <span *ngIf="dato.status_id === 8" class="status product-status-completed">{{dato.status}}</span>
              </div>
              <div class="py-0" style="width: 20px !important; margin-top: 3px;">
                <a type="button" style="cursor: pointer;">
                  <!--(click)="modalRoom.show()" -->
                  <i class="icon-info"></i>
                </a>
                <div>
                </div>
              </div>
              <!-- <div class="py-0" style="width: 20px !important; margin-left: 4px; margin-top: 1px !important;">
                <a style="cursor: pointer;">
                  <span class="icon-nr font-poppins">NR</span>
                </a>
              </div> -->
            </div>
          </div>
          <!-- Description car -->
          <div class="flex flex-column">
            <div style="margin-top: 10px;">
              <div class="d-flex flex-column">
                <span class="p-0 spaced d-flex">
                  <span style="font-size: 12px; font-weight: 500;">{{dato.paxQuantity ? dato.paxQuantity : "No disponible"}} Asientos | {{dato.bagsQuantity ? dato.bagsQuantity : "No disponible"}} Equipaje | {{dato.doors ? dato.doors : "No disponible"}} Puertas | {{dato.airCondition ? 'Aire acondicionado' : ''}} </span>
                </span>
                <span class="p-0 m-0  spaced">
                  <p style="line-height: 1;font-size: 12px; font-weight: 500;">
                    <span  class="check">LUGAR DE RECOGIDA</span>&nbsp;
                       {{dato.pickupname}}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2 px-0 text-center bg-blue py-2 icon-accion">

        <div *ngIf="dato.status_id === 1" class="icons-item text-right" [ngStyle]="{'margin-top': !partialReserve ? '10px' : '7px'}">
          <!-- <span *ngIf="partialReserve===false||partialReserve===undefinid">
            <i class="far fa-check-circle" style="color: #00d397;"></i>
          </span> -->
          <span *ngIf="partialReserve===true">
            <label class="custom-radio-checkbox">
              <input class="custom-radio-checkbox__input" type="checkbox" (change)="toPartialReserve($event, dato)"
                ng-checked="dato.status_id==3">
              <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            </label>
          </span>
          <span>
            <a style="cursor: pointer; margin-left: 5px;">
              <i class="icon-edit" aria-hidden="true"></i>
            </a>
          </span>
          <span>
            <a style="cursor: pointer;" (click)="removeItem(dato)">
              <i class="icon-delete" aria-hidden="true"></i>
            </a>
          </span>
        </div>

        <div class="date-expiration pt-2 text-right" style="padding-right: 10px;" *ngIf="dato.expirationDate">
          <span class="font-poppins" style="font-size:11px; font-weight: 400;">
            Expiration Day
          </span>
          <br>
          <span class="font-poppins text-uppercase" style="font-size: 11px; font-weight: bold; color: #00d397;">
            {{ subtractDays(dato.expirationDate) | date:'mediumDate' }}
          </span>
        </div>

        <div class="amount pt-1 text-right" style="margin-top: 20px; padding-right: 10px;">
          <span class="title-destiny-start text-nowrap" [ngStyle]="{'font-size': fontPrice(dato.netPriceAgent)}"> $ {{dato.netPriceAgent | number:'1.2-2' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DROPOFF -->
<!-- <div class="row mt-3" *ngIf="pickUp !== undefined && pickUp === 0"> -->
<div class="row mt-3" *ngIf="!isCheckIn">
  <div class="col-12 pt-2 container-items">
    <div class="row p-3 container-item-card" style="border: 1px solid #dfdfdf; border-radius: 5px;">
      <div class="col-3 justify-content-center text-center" style="max-height: 150px !important; overflow: hidden;">
        <img class="img-fluid" [src]="dato.images[0].thumbnail" alt="hotel" style="max-height: 150px;" width="100%">
      </div>
      <div class="col-9 hotel-item-description py-2 px-0">
        <div class="d-flex flex-column py-1">
          <div class="d-flex flex-column">
            <p class="tittle" style="line-height: 1; margin-top: 5px !important;">{{stingUpperCase(dato?.title)}}</p>
            <div style="margin-top: -5px !important;">
              <span class="sub-title">
                {{stingUpperCase(dato?.gearbox)}}
              </span>
            </div>
          </div>
          <div class="d-flex flex-column" style="margin-top: 10px;">
            <span class="p-0 spaced d-flex">
              <span style="font-size: 12px; font-weight: 500;">{{dato.paxQuantity ? dato.paxQuantity : "No disponible"}} Asientos | {{dato.bagsQuantity ? dato.bagsQuantity : "No disponible"}} Equipaje | {{dato.doors ? dato.doors : "No disponible"}} Puertas | {{dato.airCondition ? 'Aire acondicionado' : ''}} </span>
            </span>
            <span class="p-0 m-0  spaced">
              <p style="line-height: 1;font-size: 12px; font-weight: 500;">
                <span  class="check check-out">LUGAR DE ENTREGA</span>&nbsp;
                   {{dato.dropoffname}}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>