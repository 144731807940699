import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'sortByDestination'
})
export class SortByDestinationPipe implements PipeTransform {
  transform(itinerary: any, itemsArray: any, datePipe: DatePipe): any {
    const _array:any = [];
    itinerary.destination.forEach(destination => {
      _array.push({
        city: destination.destination_info.cityName ? destination.destination_info.cityName : destination.iata_code,
        arrival: datePipe.transform(destination.destination_info.arrival, 'MMM dd, yyyy'),
        departure: datePipe.transform(destination.destination_info.departure, 'MMM dd, yyyy'),
        iata: destination.iata_code,
        items: []
      });
    });
    _array.forEach(destination => {
      const dest = destination.items;
      itemsArray.forEach(item => {
        let itemDestination = (item.item.destination) ? item.item.destination.iata_code : item.item.payloadUpdate.products[0].destination;
        if (destination.iata === itemDestination) {
          dest.push(item);
        }
      });
    });
    _array.forEach((destination, index) => {
      if (destination.items.length === 0) {
        _array.splice(index, 1);
      }
    });
    return _array;
  }
}
