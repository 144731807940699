<!-- <simple-notifications [options]="options"></simple-notifications> -->

<div *ngIf="product !== undefined" class=""
     >

  <!-- <usb-modal-hotel *ngIf="origin==='HOTELS' || origin==='MULTIDESTINATION'" [hotel]="product" [productQty]="productQty" [form]="form" [view_info]="view_info"
    [payloadUpdate]="payloadUpdate" [itineraryItem]="itineraryItem" [origin]="origin" [arrayResults]="arrayResults" (refModal)="hideModal($event)">
  </usb-modal-hotel> -->
  <div
        *ngIf="closeModal"
        style="height: 680px;"
        usbLoadingSplash
        [appLoading]="appLoading"
        [fullscreen]="false"
    ></div>

<ng-container *ngIf="!closeModal">
  <usb-modal-hotel  *ngIf="origin==='HOTELS'" [departureDate]="departureDate" [hotel]="product" [productQty]="productQty" [form]="form" [view_info]="view_info"
    [payloadUpdate]="payloadUpdate" [itineraryItem]="itineraryItem" [origin]="origin" [arrayResults]="arrayResults"  [tabActive]="tabActive"  (refModal)="hideModal($event)">
  </usb-modal-hotel>

  <usb-modal-pthemes *ngIf="origin==='PTHEMES'" [ptheme]="product" [origin]="origin" [form]="form" [payloadUpdate]="payloadUpdate"
    [itineraryItem]="itineraryItem" [arrayResults]="arrayResults" (refModal)="hideModal($event)"></usb-modal-pthemes>

  <usb-modal-disney *ngIf="origin==='DISNEY'" [hotel]="product" [origin]="origin" [productQty]="productQty" [form]="form" [payloadUpdate]="payloadUpdate"
    [arrayResults]="arrayResults" [_item]="_item"  (refModal)="hideModal($event)">
  </usb-modal-disney>

  <usb-modal-transports *ngIf="origin==='TRANSPORTS'" [service]="product" [origin]="origin" [productQty]="productQty" [form]="form" [view_info]="view_info"
   [payloadUpdate]="payloadUpdate"  [payload]="payload"  [arrayResults]="arrayResults" [_item]="_item" (refModal)="hideModal($event)">
  </usb-modal-transports>

  <usb-modal-rentacar *ngIf="origin==='RENT-A-CAR'" [rentaCar]="product" [origin]="origin"  [productQty]="productQty" [form]="form" [payloadUpdate]="payloadUpdate"
    [itineraryItem]="itineraryItem" [arrayResults]="arrayResults" (refModal)="hideModal($event)"></usb-modal-rentacar>

  <usb-modal-insurance *ngIf="origin==='INSURANCE'" [insurance]="product" [origin]="origin" [form]="form" [payloadUpdate]="payloadUpdate" [payload]="payload"
    [itineraryItem]="itineraryItem" [arrayResults]="arrayResults" (refModal)="hideModal($event)"></usb-modal-insurance>

  <usb-modal-tours *ngIf="origin==='TOURS'" [tour]="product" [origin]="origin" [payloadUpdate]="payloadUpdate" [itineraryItem]="itineraryItem"
    [arrayResults]="arrayResults" [form]="form" (refModal)="hideModal($event)"></usb-modal-tours>

  <usb-modal-packages *ngIf="origin==='PACKAGES'" [packages]="product" [productQty]="productQty" [form]="form" [payloadUpdate]="payloadUpdate"
    [itineraryItem]="itineraryItem" [origin]="origin" [arrayResults]="arrayResults" (refModal)="hideModal($event)">
  </usb-modal-packages>
</ng-container>

</div>
