<div class="t-content" id="pthemes">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <div class="form-row pb-5">
      <div class="form-group col-xg-12 col-lg-3 col-md-12 col-sm-12 inp">
        <label for="destination">&nbsp;</label>
        <input ngui-auto-complete formControlName='destination' maxlength="20" type="text" name="destino"
          class="form-control input-search-box" value-formatter="cityName" placeholder="Destino" [(ngModel)]="destiny"
          [source]="observableSource.bind(this)" display-property-name="cityName" (valueChanged)="_myCallback($event)"
          [list-formatter]="_myListFormatter" autocomplete="off" #destination>
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('required')">Este campo es obligatorio</span>
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidcountry')">Formato de destino no válido.</span>
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidDestination')">Destino no válido, seleccione un destino válido de la lista.</span>
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">
        <div class="form-row">
          <div class="form-group col-xg-4 col-lg-6 col-md-12 col-sm-12 pr-0">
            <input formControlName="arrivalDate" type="text" class="form-control input-search-box btn-border-right" id="arrivalDate"
              placeholder="Check-in     -" autocomplete="off" bsDatepicker [minDate]="minDate" [bsConfig]="dpConfig"
              #dpArrival="bsDatepicker" (bsValueChange)="nextDate($event)" readonly>
            <span class="help-block text-danger"
              *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('required')">Este campo es obligatorio.</span>
            <small class="error-label"
              *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('invalidDate')">
              * ¡El check-in debe ser una fecha anterior al check-out!
            </small>

          </div>
          <div class="form-group col-xg-4 col-lg-6 col-md-12 col-sm-12 pl-0">
            <input formControlName="departureDate" type="text" class="form-control input-search-box btn-border-left" id="departureDate"
              placeholder="Check-out" autocomplete="off" bsDatepicker [minDate]="minDate" [bsConfig]="dpConfig"
              #dpDeparture="bsDatepicker" readonly>
            <span class="help-block text-danger"
              *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('required')">Este campo es obligatorio.</span>
            <small class="error-label"
              *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('invalidDate')">
              * ¡El check-out debe ser una fecha posterior al check-in!
            </small>


          </div>

        </div>
      </div>
      <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12">
        <button type="button" class="form-control btn-usb-rp" (click)="_openModal()" [attr.aria-expanded]="!isCollapsed"
          aria-controls="false">
          {{passengers}}
          <span class="arrow-sort fa fa-sort"></span>
        </button>
        <div (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close"
          (click)="_over()">
          <div class="row">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
              <div class="card px-3">
                <div class="d-flex flex-row align-items-start justify-content-start py-2">
                  <div class="d-flex flex-column mr-2">
                    <label for="adults">Adultos</label>
                    <select formControlName="qtyAdults" class="custom-select form-control-sm" id="adults" name="adults"
                      [value]="0">
                      <option *ngFor="let qty of _maxPersons" [value]="qty">{{ qty }}</option>
                    </select>
                  </div>
                  <div class="d-flex flex-column">
                    <label for="childs">Niños</label>
                    <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs"
                      (ngModelChange)="_addChild()" name="childs" [value]="0">
                      <option *ngFor="let qty of _maxPersons" [value]="qty">{{ qty }}</option>
                    </select>
                  </div>
                  <div class="px-2 d-flex w-100">
                    <!-- Children age inputs -->
                    <div id="childrenAges" class="col-12 m-0 p-0" *ngIf="formArray(_form, 'childsage').length > 0">
                      <label for="age">Edad de niños</label>
                      <div class="d-flex p-0 m-0">
                        <div formArrayName="childsage" *ngFor="let age of formArray(_form, 'childsage'); let j = index;"
                          class="col-3 mr-1 select-ages">
                          <div [formGroupName]="j" class="col-12 p-0">
                            <input formControlName="age" min=0 max=17 limit-to="14"
                              class="form-control col-10 input-age" value-formatter="0" placeholder="0" name="age">
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let age of formArray(_form, 'childsage'); let j = index;">
                        <span class="help-block text-danger d-flex w-100 text-nowrap"
                          *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>La edad de los niños {{j+1}} es incorrecta</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-end flex-row">
                  <div class="p-2 justify-content-end">
                    <span class="help-block text-danger col-12" *ngIf="noMostrar"><br>
                      Debe seleccionar al menos un adulto.</span>
                    <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                      class="btn btn-outline-danger btn-sm" type="button">
                      Confirmar

                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-groupcol-xg-4 col-lg-2 col-md-12 col-sm-12">
        <button class="btn btn-usblick" type="submit"
          [disabled]="!_form.valid || _findData || userService.itineraryActive.length == 0">
          <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
          Buscar
        </button>
      </div>
    </div>

  </form>
</div>