import { Component, OnInit, Input, ViewContainerRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { RestService } from '../../../../shared/rest.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';

import { ItineraryService } from '../../../../shared/itinerary.service';
import { Procesed } from '../../../../shared/enum-procesed';

@Component({
  selector: 'usb-p-themes-result',
  templateUrl: './p-themes-result.component.html',
  styleUrls: ['./p-themes-result.component.scss']
})
export class PThemesResultComponent implements OnInit {

  @Input() payload: any;
  @Input() arrayResults: any;
  @Input() result: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;
  @Input() imageItem: string;

  public itemToReserve: any;

  arrayAges: any;
  arrayAgesAdults: any;
  _findData = false;
  isCollapsed: boolean = true;
  modalRef: BsModalRef;

  constructor(
    private _router: Router,
    private vcr: ViewContainerRef,
    private _itineraryService: ItineraryService,
    private _rest: RestService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef
  ) {
    console.log("Resultados parques tematicos" + this.arrayResults);
    console.log("Resultado parque" + this.result);
    console.log("Payload de busqueda parque tematico" + this.payload);
    console.log("Origin de busqueda" + this.origin);
    console.log("Formulario" + this.form);
  }

  ngOnInit(): void {
    console.log(this.form);
    if (this.arrayResults != undefined && this.arrayResults.arrivalDate != undefined && this.arrayResults.departureDate != undefined) {
      this.result.departureDate = this.arrayResults.departureDate;
      this.result.arrivalDate = this.arrayResults.arrivalDate;
    }
    if (this.result.reservations === undefined) {
      this.result.reservations = 0;
      this.result.payloadReserve = this._payloadReserve.bind(this);
      this.result.payloadUpdate = this._payloadUpdate.bind(this);
      this.result.payloadUpdateReserva = this._payloadUpdateReserva.bind(this);
    }
  }

  _payloadUpdateReserva(form: FormGroup, oldReserva: any): Object[] {
    return this._payloadReserve(form).map(obj => {
      return Object.assign({}, oldReserva, obj);
    });
  }

  _payloadReserve(form: FormGroup): Object[] {
    const obj = JSON.parse(JSON.stringify(this.result));
    obj.additionalInfo.options = obj.additionalInfo.options.filter(item => item.reserved);
    return obj.additionalInfo.options.map(option => {
      return {
        product: {
          providerId: obj.providerId,
          categoryId: obj.categoryId,
          productId: +obj.productId,
          origin: obj.origin,
          destination: obj.destination,
          additionalInfo: {
            arrival: obj.arrivalDate + 'T00:00:00',
            departure: obj.departureDate + 'T00:00:00',
            paxList: {
              pax: [...form.value['adult'], ...form.value['child']]
            },
            optionId: +option.optionId,
          },
          nameAdapter: obj.nameAdapter
        },
        id: null,
        serviceResponse: null
      };
    });
  }

  _edit(event: Event) {
    if (event) {
      event.preventDefault();
    }
    this._router.navigate(
      [
        '/pages/diagnostico/products/query',
        {
          reserva: JSON.stringify(this._payloadfindSimilar()),
          oldReserva: JSON.stringify({
            proveedorIdOld: this.result.proveedorId,
            IdReservationOld: this.result.id,
            nameAdaptadorOld: this.result.nameAdaptador
          })
        }
      ]
    );
  }

  _payloadfindSimilar(): Object {
    return {
      idProviders: [this.result.providerId],
      idCategorys: [+this.result.categoryId],
      origin: this.result.origin,
      destination: this.result.destination,
      pricefrom: 0,
      priceto: 200000,
      arrivalDate: this.result.additionalInfo.arrival.substr(0, 10),
      departureDate: this.result.additionalInfo.departure.substr(0, 10),
      QtyProduct: 1,
      QtyPax: +this._paxList.filter(obj => obj.Type === 'Adults').length,

      timein: null,
      timeout: null,
      serializado: false,
      child: +this._paxList.filter(obj => obj.Type === 'Child').length,

      childage: this._paxList.filter(obj => obj.Type === 'Child').map(obj => { return { age: +obj.Age } }),
      adultsage: this._paxList.filter(obj => obj.Type === 'Adults').map(obj => { return { age: +obj.Age } }),
      orderColumn: null,
      orderType: null,
      maxRequestTimeOnSeconds: 20,
      minNumResults: 3
    };
  }

  get _paxList() {
    if (this.result.additionalInfo.paxList.type.length) {
      return this.result.additionalInfo.paxList.type;
    }
    return [this.result.additionalInfo.paxList.type];
  }

  _refresh(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log(this.form)
    this._findData = true;
    console.log('/search/products------------------> _refresh PThemesResultComponent',  this.result.payloadUpdate(this.form));
    this._rest.doPost('api', '/search/products', this.result.payloadUpdate(this.form)).subscribe(
      (response:any) => {
        this._findData = false;
        if (response.status && response.results && response.results[0]) {
          this.result.additionalInfo.options.forEach(item => {
            if (item.reserved) {
              this._reservation(item);
            }
          });
          Object.assign(this.result, response.results[0]);
          this.ngOnInit();
          return;
        }
        console.log('Ocurrio un error al realizar la actualizacion.');
      }, error => {
        this._findData = false;
        console.log(error.message);
      }
    );
  }

  _reservation(opcion: any) {
    this.arrayResults.filter(reservation => reservation.reservations > 0).forEach(ticket => {
      ticket.reservations = 0;
      ticket.additionalInfo.options.filter(obj => obj.reserved).forEach(opcion => {
        opcion.reserved = false;
      });
    });
    opcion.reserved = true;
    this.result.reservations = 1;
  }

  _payloadUpdate(form: FormGroup): Object {

    this.arrayAges = this.getQtyChilds(form);
    this.arrayAgesAdults = this.getQtyAdults(form);

    return {
      products: [
        {
          'providerId': this.result.providerId,
          'nameAdapter': this.result.nameAdapter,
          'categoryId': this.result.categoryId,
          'productId': this.result.productId,
          'productName': this.result.title,
          'origin': this.result.origin,
          'destination': this.result.destination,
          'priceFrom': null,
          'priceTo': null,
          'arrivalDate': this.result.arrivalDate,
          'departureDate': this.result.departureDate,
          'qtyProduct': form.value.qtyProduct,
          'qtyPax': form.value.qtyAdults,
          'others': {
            child: +form.value.qtyChilds,
            //maxRequestTimeOnSeconds: 10,
            //minNumResults: 3,
            //serialized: false,
            ages: this.arrayAges,
            agesAdult: this.arrayAgesAdults
          }
        }
      ]
    }
  }

  _reformat(data: string) {
    if (data) {
      //return moment(data).format('DD-MM-YYYY');
    }
  }

  _getCapacity(cap: number = 0): number[] {
    return Array.from(Array(+cap).keys());
  }

  _getOptions(options: any): any[] {
    if (options && options.forEach) {
      return options;
    }
    if (options && options.RoomID) {
      return [options];
    }
    return [];
  }

  private getQtyChilds(form: FormGroup) {
    let child: any;
    if (typeof form.value.childsage != 'undefined' && form.value.childsage.length > 0) {
      if (form.value.childsage.length === 1) {
        child = {
          int: [form.value.childsage[0].age]
        };
      }
      if (form.value.childsage.length > 1) {
        var edades = [];
        form.value.childsage.forEach(function (value) {
          edades.push(value.age);
        });
        child = {
          int: edades
        };
      }
    } else {
      child = {
        int: []
      };
    }
    return child;
  }

  private getQtyAdults(form: FormGroup) {
    let adults: any;
    if (typeof form.value.adultsage != 'undefined' && form.value.adultsage.length > 0) {
      if (form.value.adultsage.length === 1) {
        adults = {
          int: [form.value.adultsage[0].age]
        };
      }
      if (form.value.adultsage.length > 1) {
        var edades = [];
        form.value.adultsage.forEach(function (value) {
          edades.push(value.age);
        });
        adults = {
          int: edades
        };
      }
    } else {
      adults = {
        int: []
      };
    }
    return adults;
  }

  public openModal(template: TemplateRef<any>, opcion: any) {
    this._reservation(opcion);
    opcion.payloadUpdate = JSON.stringify(this._payloadUpdate(this.form));
    opcion.categoryName = Procesed.PTHEMES;
    this.itemToReserve = opcion;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  isNull(str){
    let nulls = (str == null)? true : false
    return nulls
  }
}
