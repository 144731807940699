import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../../shared/rest.service';
import { UbicacionesService } from '../../service/ubicaciones.service';
import { CustomValidator } from '../../../../shared/customValidators';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
//import { ToastsManager } from 'ng2-toastr';

import { searchPayload } from '../../../../utils/search-functions';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';

@Component({
  selector: 'usb-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
  providers: [DatePipe]
})
export class InsuranceComponent implements OnInit {

  @Input() form: FormGroup;
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() origin = new EventEmitter<any>();
  @Input() _close: boolean;
  @Output() modal = new EventEmitter<string>();

  @ViewChild('dpDeparture') datepickerDeparture: BsDatepickerDirective;
  @ViewChild('dpArrival') datepickerArrival: BsDatepickerDirective;
  
  @ViewChild("destination") myInputField: ElementRef;

  destineVale: boolean;
  originValue: boolean;
  selectediataCodeDestiny: any;
  selectediataCodeOrigin: any;

  _findData: boolean;
  _resultsQuery: any;
  _form: FormGroup;
  _maxPersons: any;
  minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  isCollapsed = false;
  aged: number;
  validate = false;
  selectedDestiny: any;
  _longDestination: string;
  destiny: any;
  originVar: any;
  roomsAndPassengers: string;
  passengers: string;

  constructor(
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private _router: Router,
    private _restService: RestService,
    private ubicacionesService: UbicacionesService,
//    
    private datePipe: DatePipe,
    public userService: UserService,
  ) {
    this._maxPersons = Array.from(Array(51).keys());
    this.minDate = new Date();
    this.dpConfig.containerClass = 'theme-red';
    this.aged = 0;
  }

  ngOnInit(): void {
    this.roomsAndPassengers = "Rooms and passengers";
    this._form = this.fb.group({
      // Search basic
      origin: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      destination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      arrivalDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('departureDate').value !== null
        ) {
          const valid = (c.value < this._form.get('departureDate').value) ? null : { invalidDate: true };
          this._form.controls['departureDate'].setErrors(valid);
          return (c.value < this._form.get('departureDate').value) ? null : { invalidDate: true };
        }
      }
      ])],
      departureDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('departureDate').value !== null
        ) {
          const valid = (c.value > this._form.get('arrivalDate').value) ? null : { invalidDate: true };
          this._form.controls['arrivalDate'].setErrors(valid);
          return (c.value > this._form.get('arrivalDate').value) ? null : { invalidDate: true };
        }
      }
      ])],
      room: this.fb.array([]),
      serviceType: [],
      qtyProduct: [1, [Validators.required, Validators.min(1), Validators.max(7)]]
    });
    this._addRoom();
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.myInputField.nativeElement.focus();
    }, 500);
  }


  _removeRoom(prueba){

  }


  formRoom(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }

  _addRoom() {
    const room = this._form.get('room') as FormArray;
    room.push(this.fb.group({
      qtyAdults: [0, [Validators.required, Validators.min(1)]],
      qtyChilds: [0, Validators.required],
      childsage: this.fb.array([]),
      adultsage: this.fb.array([]),
    }));
    if (room.length < 1 || !room.length) {
      this._form.get('qtyProduct').setValue(0);
    } else {
      this._form.get('qtyProduct').setValue(room.length);
    }
  }

  // realiza la busqueda en el endpoint
  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    this.originValue = ((this.selectediataCodeOrigin !== undefined) && (this.selectediataCodeOrigin !== null));
    this.destineVale = ((this.selectediataCodeDestiny !== undefined) && (this.selectediataCodeDestiny !== null));
    if ((this.originValue) && (this.destineVale)) {
      if (event) {
        event.preventDefault();
      }
      this._emitLoading(true);
      const _payload = searchPayload('INSURANCE', '', form, this.selectediataCodeDestiny, this.selectediataCodeOrigin);

      this._restService.doPost('app', 'search', _payload).subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        (response:any) => {
          console.log('Busqueda exitosa', response);
          // _response = Response['status'] ? Response : this.transportsService.getTransports(payload);
          // tslint:disable-next-line:max-line-length
          this.payload.emit([searchPayload('INSURANCE', '', form, this.selectediataCodeDestiny, this.selectediataCodeOrigin)]);
          if (response !== null && response.results !== null) {
            this._colocarSearchIdEnlosHoteles(response);
          }
          this._handleResponse(response, 'INSURANCE', form);
          this._emitOrigin('INSURANCE');
          this._emitLoading(false);
          this._router.navigate(['/results']);
        },
        error => {
          this._emitLoading(false);
          console.log('Error en la busqueda', error);
          //this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
        });

    } else {
      // tslint:disable-next-line:curly
      if (!this.originValue) {
        this._form.get('firstOrigin').setErrors({ invalidDestination: true });
      }
      if (!this.destineVale) {
        this._form.get('firstDestination').setErrors({ invalidDestination: true });
      }

    }
  }

  getFormattedDate(date: any, format: string): any {
    if (format === 'time') {
      const _date = date ? this.datePipe.transform(date, 'HH:mm') : '13:00';
      return _date;
    } else {
      const _date = date ? this.datePipe.transform(date, 'yyyy-MM-dd') : '2018-05-30';
      return _date;
    }
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  _colocarSearchIdEnlosHoteles(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }

  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = form.value['arrivalDate'];

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = 'No results found for the search';
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        this.results.emit([this._resultsQuery]);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = 'No results found for the search';
      this.results.emit(this._resultsQuery);
    }
  }

  _confirm() {
    this.isCollapsed = false;
    this.setPassanger();
  }

  _openModal() {
    console.log(this._longDestination)
    this.isCollapsed = true;
    this.validate = false;
    this._close = false;
    setTimeout(() => {
      this.modal.emit('true');
    }, 100);
  }

  _over() {
    //console.log('here')
    this.modal.emit('false');
  }

  clickOutside(event: any) {
    this.setPassanger();
  }
  
  _addAdult(item: FormGroup) {
    setTimeout(() => {
      const ages: any = item.get('adultsage') as FormArray;
      const qty: number = +item.value['qtyAdults'];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [18, Validators.compose([Validators.required, Validators.min(18), Validators.max(99)])]
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  _addChild(item: FormGroup) {
    setTimeout(() => {
      const ages: any = item.get('childsage') as FormArray;
      const qty: number = +item.value['qtyChilds'];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])]
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }


  /***********************************************Auto Complete********************************************/
  _myListFormatter(data: any): string {
    console.log(data)
    return `${data['name']} (${data['iataCode']})`;
  }

  _myCallbackDestiny(data: any): string {
    this.selectediataCodeDestiny = data['iataCode'];
    return `${data['name']} (${data['iataCode']})`;
  }

  _myCallbackOrigin(data: any): string {
    this.selectediataCodeOrigin = data['iataCode'];
    return `${data['name']} (${data['iataCode']})`;
  }

  observableSource = (keyword: any): Observable<any[]> => {
    return this.ubicacionesService.getCountrys(keyword);
  }
  /***********************************************Auto Complete********************************************/

  setPassanger() {
    let adults = (!this._form.value['room'][0]['qtyAdults']) ? 0 : this._form.value['room'][0]['qtyAdults'];
    let childs = (!this._form.value['room'][0]['qtyChilds']) ? 0 : this._form.value['room'][0]['qtyChilds'];

    let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
    adults = adults != undefined ? adults + " Adultos " : null;
    childs = childs != undefined ? " - " + childs + " Niños " : null;

    this.passengers = noSelected ? "Pasajeros" : adults + childs;
  }

  async nextDate(event: any){
    if(event!==null){
      await this.datepickerArrival.toggle();
      this.datepickerDeparture.toggle();
      return;
    }
  }
}
