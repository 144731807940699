import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { ItineraryReservationComponent } from './itinerary-reservation.component';
import { ItineraryDetailComponent } from '../itinerary-detail/itinerary-detail.component';
import { ConfirmReservationComponent } from './confirm-reservation/confirm-reservation.component';

const routes: Routes =[
    { path: 'itinerary-reservation', component: ItineraryReservationComponent, data: { title: extract('Reserva de itinerario') } },
    //{ path: 'itinerary-reservation-config', component: ItineraryReservationComponent, data: { title: extract('Itinerary Reservation') } },
    // { path: 'reservation-confirmation', component: ItineraryReservationComponent, data: { title: extract('Itinerary Reservation') } },
];

const routeDetail: Routes =[
    { path: 'reservation-confirmation', component: ItineraryDetailComponent, data: { title: extract('Confirmación de reservación') } }
  ];

@NgModule({
    imports: [RouterModule.forChild(routes), RouterModule.forRoot(routeDetail, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: []
})
export class ItineraryReservationRoutingModule { }
