
export var PTHEMES: any[] = [
    {
      providerId: "c38caa90-f019-11e7-b791-2702c92c19de",
      providerName: "Majestic",
      nameAdapter: "Majestic_Others",
      searchId: "258643437165150655",
      id: "1222",
      title: "UNIVERSAL PARK TO PARK TICKETS",
      description: null,
      categoryId: 4,
      productId: "1222",
      origin: null,
      destination: "ORL",
      dateIni: "2018-02-18",
      dateEnd: null,
      serviceResponse: null,
      qtyPax: 0,
      additionalInfo: {
          location: "USA - ORLANDO, UNIVERSAL STUDIOS AREA(FLORIDA)",
          childAgeLimit: "9",
          images: [
            {
              description: "image_theme park",
              image: "https://media.tacdn.com/media/attractions-splice-spp-674x446/09/20/24/09.jpg",
              thumbnail: "https://media.tacdn.com/media/attractions-splice-spp-674x446/09/20/24/09.jpg"
            }
          ],

          options: [
              {
                  optionId: 13,
                  name: "TICKET PARA 2 PARQUES 2 DIAS PARQUE(UNIVERSAL STUDIOS FLORIDA)",
                  description: "Ticket válido para una (1) persona con acceso ilimitado de dos (2) días calendario",
                  duration: 2,
                  from: "2018-04-26T00:00:00",
                  to: "2018-04-30T00:00:00",
                  paxAdults: 2,
                  priceAdults: "299.0000",
                  paxChild: 1,
                  priceChild: "287.0000",
                  optionTotal: "885.0000",
                  priceAdultsProf: "350.0000"
              }
          ]
      }
  }
  ];