<div id="activities-result-component" class="d-flex justify-content-between">
  <div class="d-flex container-img">
    <img class="img-hotel" *ngIf="result?.additionalInfo?.images[0]" src="{{result.additionalInfo.images[0].image }}" alt="Tours">
  </div>
  <div class="d-flex flex-column container-detalles justify-content-between p-3">
    <div class="d-flex justify-content-between">
      <div class="d-flex" style="width: 40%; max-height: 40px; overflow: hidden;">
        <p class="title m-0" [attr.title]="result.title">{{result?.title}}</p>
      </div>
      <div class="d-flex justify-content-end" style="width: 60%;">
        <div class="d-flex content-icons px-1" *ngIf="viewTour">
          <div class="d-flex">
            <i class="icon icon-view mr-1"></i>
          </div>
          <div class="d-flex font-view px-1">
            <p class="m-0" style="line-height: 1;">2500 <br> views</p>
          </div>
        </div>
        <div *ngIf="false" class="d-flex content-icons px-1">
          <i class="icon icon-video-1"></i>
        </div>
        <div *ngIf="false" class="d-flex content-icons px-1">
          <i class="icon icon-video-2"></i>
        </div>
        <div class="d-flex content-icons pl-1">
          <i class="icon icon-time"></i>
          <span class="font-days pl-0"> {{result?.additionalInfo?.tours.options[0].duration.slice(0, 4)}} DÍAS</span>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="description pt-1 d-flex flex-column">
        <p>{{result.description | truncate : 130 : "  "}}</p>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-start">
      <div class="d-flex flex-row">
        <span class="address-resumen-t d-flex align-items-center">
          <i class="icon-placeholder" aria-hidden="true"></i>
          <p class="px-1 m-0 description-location">{{result?.additionalInfo?.location}}</p>
        </span>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between" *ngIf="!result.additionalInfo.tours">
      <div class="d-flex flex-row">
        <div class="d-flex align-items-center px-2 includes">
          <p class="m-0"> INCLUDED</p>
        </div>
        <div class="d-flex">
          <i class="icon icon-wifi"></i>
        </div>
        <div class="d-flex">
          <i class="icon icon-transport"></i>
        </div>
        <div class="d-flex">
          <i class="icon icon-speak"></i>
        </div>
      </div>
      <div class="d-flex flex-row">
        <span class="font-desc" style=" font-family: 'Lato', sans-serif  !important;">15</span>
        <i class="icon icon-descuento"></i>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <img class="img-logo" *ngIf="result?.logo_tours_prov" src="{{result?.logo_tours_prov}}" alt="logo">
      </div>
      <div class="d-flex align-items-middle">
        <i class="icon icon-medal mr-1"></i>
        <span class="font-point d-flex">
          {{result?.additionalInfo?.tours?.options[0]?.blickoins}}&nbsp;Bks.
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column container-price justify-content-end py-3">
    <div class="d-flex flex-column px-3 pb-3">
      <div class="d-flex">
        <p class="m-0 fromPrice">Desde</p>
      </div>
        <!-- <span style="font-size: 10px; color: red;  font-weight: bold;">P. Costo:&nbsp;{{result.additionalInfo.tours.options[0].totalPrice | number:'1.2-2'}}</span>
        -->
          <div class="d-flex">
        <div *ngIf="!(result?.additionalInfo?.tours?.options[0]?.totalPriceProf ? true : false)" >
          <a class="loader text-center justify-content-center">
            <i class="fa fa-spinner fa-spin"></i>
          </a>
        </div>
        <p class="m-0 priceMoney" *ngIf="(result?.additionalInfo?.tours?.options[0]?.totalPrice ? true : false)"> {{result.additionalInfo.currency}} <span>
          {{ result?.additionalInfo?.tours?.options[0]?.totalPriceProf | number:'1.2-2'}}</span></p>
      </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="result">
      <button type="button" (click)="openModal();" class="btn btn-usblick-pink btn-green justify-content-center w-100 py-1 font-weight-bold" 
      [disabled]="!(result?.additionalInfo?.tours?.options[0]?.totalPriceProf ? true : false)">
          AGREGAR
      </button>
    </div>
  </div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size = "medium" color="#fa1a1a" type="line-scale">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  
    <!-- <div class="d-flex col-12 m-0 p-0 pos-div">
        <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12 hotel-image">
            <div class="col-12 m-0 p-0">
              <img class="img-hotel" *ngIf="result?.additionalInfo?.image && result?.additionalInfo?.image[0]" src="{{result.additionalInfo.image[0].image }}" alt="Tours"> -->
              <!-- <img class="img-hotel" *ngIf="!result?.additionalInfo?.images[0]" src="assets/img/app/tours_default.png" alt="Tours"> -->
            <!-- </div>
          </div>
          <div class="col-xg-7 col-lg-7 col-md- 5 col-sm-12 hotel-details py-2 pl-3  pr-3 pt-3">
            <div class="row">
                  <div class="col-xg-7 col-lg-5 col-md-5 col-sm-12 ">
                      <h4>{{result.title}}</h4>
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 font-view text-center">
                      <i class="icon icon-view border-left"></i>
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 font-view pl-0">
                      <span >2500</span>
                      <p style="line-height:2px"> views</p>
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 pl-0">
                      <i class="icon icon-video-1 border-left"></i> 
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 pl-0">
                      <i class="icon icon-video-2 border-right border-left"></i> 
                  </div>
                  <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 pl-4">
                    <i class="icon icon-time"></i> 
                </div>
                <div class="col-xg-7 col-lg-2 col-md-2 col-sm-12 pl-2">
                      <span class="font-days pl-0"> {{result?.additionalInfo?.tours.options[0].duration}}</span>
                </div>
            </div>
            <div class="description pt-1 d-flex flex-column">
            <p>{{result.description | truncate : 130 : "  "}}</p>
            <div class="flex-row p-2 d-flex" style=" margin-left: -15px;">
              <span class="address-resumen-t">
                <i class="icon icon-placeholder fa-2x" aria-hidden="true"></i>
                <strong class="px-1 description-location">{{result?.additionalInfo?.location}}</strong>
              </span>
            </div>
            </div>
              <div class="row pt-2" *ngIf="!result.additionalInfo.tours">
                        <div class="col-xg-7 col-lg-2 col-md-5 col-sm-12 ">
                            <span class="font-green"> INCLUDED</span>      
                          </div>
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 font-view text-center">
                            <i class="icon icon-wifi"></i>
                        </div>
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 font-view text-center">
                            <i class="icon icon-transport"></i>
                        </div>
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center">
                            <i class="icon icon-speak"></i>
                        </div>
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center">
                           
                        </div>
                        <div class="col-xg-7 col-lg-4 col-md-1 col-sm-12 text-center">
                        </div>
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center pr-0">
                          <span class="font-desc">15</span>
                        </div>
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12 text-center pl-0">
                            <i class="icon icon-descuento"></i>
                        </div> -->
                  <!--</div>-->  
                <!-- <div class="col-12 m-1 p-0">
                    <img class="img-logo" *ngIf="result?.logo" src="{{result.logo }}" alt="logo">
                  </div> -->
              <!-- </div>
              <div class="row  pt-2">
                        <div class="col-xg-7 col-lg-1 col-md-1 col-sm-12  pl-2 ">
                            <img class="img-logo" *ngIf="result?.logo" src="{{result.logo }}" alt="logo">
                        </div>
                        <div class="col-xg-7 col-lg-9 col-md-1 col-sm-12 font-view text-center">
                        </div>
                        <div class="col-xg-7 col-lg-2 col-md-1 col-sm-12 pl-4" >
                          <i class="icon icon-medal"> 
                            <span class="font-point" style="padding-left:24px;">
                              {{result?.additionalInfo?.tours?.options[0]?.blickoins}}&nbsp;Bks.
                            </span>
                          </i>
                        </div>
              </div>
          </div>
            <div class="col-xg-2 col-lg-2 col-md-3  col-sm-12 flex-column align-items-middle d-flex continue">
              <div class="mt-auto flex-column pt-0 pl-3" style="margin-top: 23px !important;">
                    <span class="text-price">{{result.additionalInfo.tours.options[0].totalPriceFlag}}From
                    </span>
                    <div *ngIf="!(result.additionalInfo.tours.options[0].totalPriceProf ? true : false)">
                        <a class="loader text-center justify-content-center ">
                            <i class="fa fa-spinner fa-spin"></i>
                        </a>
                    </div>
                    <br>
                    <span *ngIf="(result.additionalInfo.tours.options[0].totalPriceProf ? true : false)"
                          class="search-price text-right">
                          <span class="search-price text-right"> <span class="usd text-right"> USD </span>                         
                           {{ result.additionalInfo.tours.options[0].totalPriceProf | number:'1.2-2'}}
                    </span>
                    </span>
                    <br> -->
                    <!-- <span style="font-size: 12px; color: red;  font-weight: bold;">P. Costo:&nbsp;{{result.additionalInfo.tours.options[0].totalPrice | number:'1.2-2'}}</span>  -->
            <!-- </div>
              <div class="mt-auto py-2 text-center" *ngIf="result"> -->

                  <!-- <button  type="button" (click)="ModalRooms.show()" class="btn btn-usblick-pink btn-green  mb-3 justify-content-center w-100 py-1 font-weight-bold"
                  [disabled]="!(result.additionalInfo.tours.options[0].totalPriceProf ? true : false)">
                    ADD
                  </button>

                  <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" 
                  [modalRoomRef]="ModalRooms"
                  [product]="result" 
                  [productQty]="payload.qtyProduct" 
                  [form]="form" 
                  [origin]="'TOURS'" 
                  [arrayResults]="arrayResults" 
                  [payloadUpdate]="payload">
                  </usb-modals> -->
<!-- 
                  <button  type="button" (click)="openModal(ModalRooms);" class="btn btn-usblick-pink btn-green  mb-3 justify-content-center w-100 py-1 font-weight-bold"
                  [disabled]="!(result.additionalInfo.tours.options[0].totalPriceProf ? true : false)">
                    ADD
                  </button> -->

          
                  <!-- <ng-template #ModalRooms>

                    <usb-modals
                    [modalRoomRef]="modalRef"
                    [product]="result" 
                    [productQty]="payload.qtyProduct" 
                    [form]="form" 
                    [origin]="'TOURS'" 
                    [arrayResults]="arrayResults" 
                    [payloadUpdate]="payload">
                    ></usb-modals>

                  </ng-template> -->
<!-- 
              </div>
            </div>
    </div> -->
</div>