import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-ptheme-detail-summary',
  templateUrl: './item-ptheme-detail-summary.component.html',
  styleUrls: ['./item-ptheme-detail-summary.component.scss']
})
export class ItemPthemeDetailSummaryComponent implements OnInit {
  @Input() item: any;
  constructor() { }

  ngOnInit() {
  }

}
