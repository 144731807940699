import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ItineraryService } from '../shared/itinerary.service';
import { RestService } from '../shared/rest.service';
import { CountdownEvent } from 'ngx-countdown';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../pages/com.usblick.common/auth/user.service';
import { MaterialModalAlertComponent } from '../shared/material-modal-alert/material-modal-alert.component';
import { setting } from '../../../setting';

@Component({
  selector: 'usb-itinerary-reservation',
  templateUrl: './itinerary-reservation.component.html',
  styleUrls: ['./itinerary-reservation.component.scss']
})

export class ItineraryReservationComponent implements OnInit {
  from: string = 'total-reserve';
  step: any;
  itineraryId: number;
  itineraryItems: any = [];
  itineraryClient: any;
  itinerary: any = [];
  paxes: any = [];
  _loading: boolean = true;
  formSended: boolean = false;
  buttonReserve: String = "Next";
  nextStep: String = "three";
  permitedConfiguration:boolean = true;
  paxesInItems: Array<any> = [];
  partialReserve: boolean = false;
  totalPrice: any = 0;
  valueArrItems:any;
  status:string;
  numberPosition: number;
  totalAdults: number;
  totalChildren: number;
  clientSelecFormPaxes: any[] = [];


  // @ViewChild(CountdownEvent) counter: CountdownEvent;

  public getClientsSettingAppBooking: any;

  config:any = {
    //format: "HH:mm:ss",
    template: '$!m!:$!s!',

    // format: 'm:s',
    size:'medium',
    //className:'clock-style'
  }

  verifyTimerPax(){
    if(this.paxes){
      if(this.paxes.length > 0){
        console.log("this.paxes.length * 180 ", this.paxes.length * 180);
        return this.paxes.length * 180
      }else{
        return 0;
      }
    }else{
      return 0;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private itineraryService: ItineraryService,
    private router: Router,
    public _restService: RestService,
    private dialog:MatDialog,
    public userService:UserService,
    private cdr: ChangeDetectorRef
  ) {
     this.status = 'false';
     this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    }

  ngOnInit() {
    this._loading = true;

    // this.counter.begin();
    this.getActiveItinerary();
  }


  onFinished(event:CountdownEvent){

    if(event.action === 'done'){
      var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
        width: '450px',
        data: 'Tu tiempo ha expirado, volverás al resumen para verificar disponibilidad',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if(result){
          this._restService.setBooleanTimer(true);
          this.router.navigateByUrl("/summary");
        }
      });
    }



  }

  getActiveItinerary(){
    this.route.queryParams.subscribe(params => {
      this.from = (params["from"]) ? params["from"] : 'total-reserve';
      this.step = (params["step"]) ? params["step"] : 'two';

      this.userService.from = (params["from"]) ? params["from"] : 'total-reserve';
      this.userService.stepReservation = (params["step"]) ? params["step"] : 'two';

      this.partialReserve = (this.from=='partial-reserve') ? true : false;
      this.itineraryId = (params["itineraryId"]) ? params["itineraryId"] : null;

      // console.log ('itineraryId', this.itineraryId);
      // console.log ('step', this.step);
    });

    // console.log('paso==>',this.step);
    switch (this.step) {
      case 'two':
        this.buttonReserve = "Next";
        this.nextStep = "three";
        break;
      case 'three':
        this.buttonReserve = "Reserve";
        this.nextStep = "four";
        break;
      default:
        this.buttonReserve = "Next";
        this.nextStep = "three";
        break;
    }
    this.getItineraryInfo();
  }


  getItineraryInfo() {
    setTimeout(() => {
        if (this.itineraryId) {
          // console.log('CONSULTAR EL ITINERARIO ==> ',this.itineraryId);
            this._restService.doGet('app', 'itineraries/' + this.itineraryId).subscribe((response: any) => {
            this.userService.itineraryActive[0] = response.data;   // nuevo
            // console.log('respuesta ==> ',response.data);
            // console.log('respuesta 2 ==> ',this.userService.itineraryActive[0]);
            // console.log('items ==> ', response.data.items);
            const products = [];
            response.data.items.forEach(element => {
              if (element.status_id == 1 ) { products.push(element); }
            });


            // console.log('items ==> products  ', products);
            this.itinerary = response.data;
            if (this.itinerary && this.itinerary.items && Array.isArray(this.itinerary.items)) {
              // Calcula la suma de paxAdults y paxChildren
              this.totalAdults = this.itinerary.items.reduce((sum, item) => sum + (item.paxAdults || 0), 0);
              this.totalChildren = this.itinerary.items.reduce((sum, item) => sum + (item.paxChildren || 0), 0);
            }

            // console.log("this.itinerary getItineraryInfo",   this.itinerary);
            // console.log("partialReserve", this.partialReserve);
            this.partialReserve
            this.itineraryItems = (this.partialReserve == false) ? products : this.getItemsPartial(products);
            this.totalPrice =  this.itinerary.totalNetPriceBooking;
            // tslint:disable-next-line:max-line-length
            // this.paxes = this.setArrayPaxes(Array(this.itinerary.totalAdultsMax).fill(0), Array(this.itinerary.totalAdultsMax).fill(0));
            this.paxes = this.setArrayPaxes(this.itinerary);
            this.permitedConfiguration = this._permitedConfiguration(this.itineraryItems);
            // console.log('Fin busca this.paxes', this.paxes);
            // console.log('Fin busca activo itineraryItems' , this.itineraryItems);
            // tslint:disable-next-line:max-line-length
            this.itineraryItems = this.itineraryItems.sort((a, b) => this.convertDates(a.arrivalDate) - this.convertDates(b.arrivalDate));
            this.config.leftTime = setting.defauldTimeBooking * 60;
            // 600 + (this.verifyTimerPax())
            this._loading = false;
          }, error => {
            console.log('Error: ' + error);
          });
        } else {
          this.itineraryService.activeItinerary.subscribe((data: any) => {
            if (data !== undefined && data!==true) {
              this.itinerary = data[0];
              this.itineraryItems = (this.partialReserve == false) ? data[0].items : this.getItemsPartial(data[0].items);
              this.totalPrice =  this.itinerary.totalNetPriceBooking;
              // tslint:disable-next-line:max-line-length
              this.paxes = this.setArrayPaxes(this.itinerary);
              this.permitedConfiguration = this._permitedConfiguration(this.itineraryItems);
              this._loading = false;
              this.config.leftTime = setting.defauldTimeBooking * 60;
              // 600 + (this.verifyTimerPax())
            }
          });
        }
      }, 500);
  }

  convertDates (fechaString) : any{
    var fechaSp = fechaString.split("-");
    var anio = new Date().getFullYear();
    if (fechaSp.length == 3) {
      anio = fechaSp[0];
    }
    var mes = fechaSp[1] - 1;
    var dia = fechaSp[2];

    return new Date(anio, mes, dia);
  }

  getItemsPartial (items): any {
    let itemsPartial: any = [];
    items.forEach(item => {
      if(item.partial_selected==1){
        itemsPartial.push(item);
      }
    });
    console.log('estos van a partial==>', itemsPartial);

    return itemsPartial;
  }


  _permitedConfiguration(items): boolean{
    let status = true;
    return status;
  }

  getItineraryInfoReserve(){
    if(this.itineraryId){
      this._restService.doGet('app','itineraries/' + this.itineraryId).subscribe((response:any) => {
        this.itinerary = response;
          this.itinerary = response.data;
          this.makeReserve();
      }, error => {
        console.log('Error: ' + error);
      });
    } else {
      this.itineraryService.activeItinerary.subscribe((data: any) => {
        if (data !== undefined){
          this.itinerary = data[0];
          this.makeReserve();
        }

      });
    }
  }

  setArrayPaxes(itinerary): Array<any> {
    this.paxes = []; // Limpia el array paxes antes de agregar nuevos elementos
    console.log(itinerary)

    let totalAdults = 0;
    let totalChildren = 0;

    if (itinerary && itinerary.items && Array.isArray(itinerary.items)) {
      // Calcula la suma de paxAdults y paxChildren
      totalAdults = itinerary.items.reduce((sum, item) => sum + (item.paxAdults || 0), 0);
      totalChildren = itinerary.items.reduce((sum, item) => sum + (item.paxChildren || 0), 0);
    }

    let num = 0;
    let numChild = 0;

    for (let index = 1; index <= totalAdults; index++) {
      let holder = (index === 1) ? ' - Titular' : '';
      this.paxes.push({
        title: 'Adulto ' + index + holder,
        type: 'adult',
        number: index
      });
    }

    for (let index = 1; index <= totalChildren; index++) {
      this.paxes.push({
        title: 'Niño ' + index,
        type: 'children',
        number: index
      });
    }

    return this.paxes;
  }

  goNextStep(){
    if(this.nextStep=='four'){
      // reservar
     // console.log('reserva==>', this.paxesInItems);

      //buscar los paxes registrados para comparar
      this.getItineraryInfoReserve();


    } else {
      let navigationExtras: NavigationExtras = {
        queryParams: {
            "from": this.from,
            "step": this.nextStep
        }
      };

      this.router.navigate(["itinerary-reservation"], navigationExtras);
    }
  }

  makeReserve() {
    //console.log("item para reserva==>",this.itineraryItems);
    //console.log("paxes en itinerario==>",this.itinerary.paxList);
    //console.log("paxes seteados==>",this.paxesInItems);
  }

  onChange(paxes: any){
    //console.log('del hijo al padre==>',paxes);
    this.paxesInItems = paxes;
    // paxes.forEach(i => {
    //   this.paxesInItems.push(i);
    // });
  }

  existHotel(){
    if(this.userService.itineraryActive.length > 0){
      if(this.userService.itineraryActive[0].items.length>0){
        let exist = this.userService.itineraryActive[0].items.find( element => element.categoryName == "HOTELS" );

        if(exist){
          return true;
        }else{
          return false;
        }
      }
    }
  }

  clientSelectedArr(clientSelectFormPaxes: any[]) {
    this.clientSelecFormPaxes = clientSelectFormPaxes;
  }
}
