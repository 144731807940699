<div class="t-content" id="transport">
    <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>

        <div class="form-row">
            <div class="form-group align-items-center m-0 py-0 px-1 col-lg-2 col-xg-2 col-md-12 col-sm-12 scroll">
                <label for="origin">&nbsp;</label>
                <input ngui-auto-complete formControlName="firstOrigin" maxlength="20" type="text" name="origin"
                    class="form-control input-search-box" value-formatter="cityName" [(ngModel)]="ori" placeholder="Origen"
                    (valueChanged)="_myCallbackOrigin($event)" [source]="observableSource.bind(this)"
                    display-property-name="cityName" [list-formatter]="_myListFormatter" accept-user-input="false"
                    autocomplete="off" #destination>
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstOrigin').touched && _form.get('firstOrigin').hasError('required')">Este campo es obligatorio.</span>
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstOrigin').touched && _form.get('firstOrigin').hasError('invalidcountry')">Formato de origen no válido.</span>
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstOrigin').touched && _form.get('firstOrigin').hasError('invalidDestination')">Origen no válido, seleccione un origen válido de la lista.</span>
            </div>
            <div class="form-group m-0 py-0 px-1 col-lg-3 col-xg-2 col-md-12 col-sm-12 scroll">
                <label for="origin">&nbsp;</label>
                <input ngui-auto-complete formControlName="pickUp" type="text" name="origin" class="form-control input-search-box custom-select"
                    value-formatter="address" placeholder="Lugar de recogida" [source]="observableSourceOri.bind(this)"
                    display-property-name="address" (valueChanged)="_myCallbackPickup($event)"
                    [list-formatter]="_myListFormatterAdd">

                <span class="help-block text-danger"
                    *ngIf="_form.get('pickUp').touched && _form.get('pickUp').hasError('required')">Este campo es obligatorio.</span>
                <span class="help-block text-danger"
                    *ngIf="_form.get('pickUp').touched && _form.get('pickUp').hasError('invalidLocation')">Ubicación no válida, seleccione una ubicación válida de la lista.</span>
            </div>
            <div class="form-group m-0 py-0 px-1 col-lg-2 col-xg-2 col-md-12 col-sm-12 scroll">
                <label for="Destination">&nbsp;</label>
                <input ngui-auto-complete formControlName="firstDestination" maxlength="20" type="text"
                    name="destination" class="form-control input-search-box" value-formatter="cityName" placeholder="Destino"
                    [(ngModel)]="dest" (valueChanged)="_myCallbackDestiny($event)"
                    [source]="observableSource.bind(this)" display-property-name="cityName"
                    [list-formatter]="_myListFormatter" accept-user-input="false" autocomplete="off">
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstDestination').touched && _form.get('firstDestination').hasError('required')">Este campo es obligatorio.</span>
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstDestination').touched && _form.get('firstDestination').hasError('invalidcountry')">Formato de origen no válido.</span>
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstDestination').touched && _form.get('firstDestination').hasError('invalidDestination')">Ubicación no válida, seleccione una ubicación válida de la lista.</span>
            </div>
            <div class="form-group m-0 py-0 px-1 col-lg-3 col-xg-2 col-md-12 col-sm-12 scroll">
                <label for="Destination">&nbsp;</label>
                <input ngui-auto-complete formControlName="dropOff" type="text" name="destination" class="form-control input-search-box custom-select"
                    value-formatter="address" placeholder="Punto de entrega" [source]="observableSourceDest.bind(this)"
                    (valueChanged)="_myCallbackDropoff($event)" display-property-name="address"
                    [list-formatter]="_myListFormatterAdd">


                <span class="help-block text-danger"
                    *ngIf="_form.get('dropOff').touched && _form.get('dropOff').hasError('required')">Este campo es obligatorio.</span>
                <span class="help-block text-danger"
                    *ngIf="_form.get('dropOff').touched && _form.get('dropOff').hasError('invalidLocation')">Dirección no válida, seleccione un destino válido de la lista.</span>
            </div>
            <div class="form-group m-0 px-1 pb-1 pt-0 col-xg-4 col-lg-2 col-md-12 col-sm-12">
                <label for="passengers">&nbsp;</label>
                <button type="button" class="form-control btn-usb-rp d-flex align-items-center" (click)="_openModal()"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="false">
                    <p class="p-0 m-0 text-overflow align-self-center align-middle font-poppins">{{passengers}}</p>
                    <span class="arrow-sort fa fa-sort"></span>
                </button>
                <span class="help-block text-danger"
                    *ngIf="(((_close && isCollapsed)) && (_form.value['firstqtyAdults'] == 0))">Al menos selecciona 1 adulto.</span>
                <div (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close"
                    (click)="_over()">
                    <div class="row">
                        <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
                            <div class="card">
                                <div
                                    class="d-flex flex-row col-xg-12 col-lg-12 col-md-12 col-sm-12 align-items-center justify-content-start m-0 p-1">
                                    <div class="col-xg-4 col-lg-4 col-md-4 col-sm-4 m-0">
                                        <br>
                                        <label for="adults">Adultos</label>
                                        <select formControlName="firstqtyAdults" class="custom-select form-control-sm"
                                            id="adults" name="adults" [value]="0">
                                            <option *ngFor="let qty of _maxPersons" [value]="qty">{{ qty }}</option>
                                        </select>
                                    </div>
                                </div>
                                <hr>
                                <div
                                    class="d-flex flex-row col-xg-12 col-lg-12 col-md-12 col-sm-12 align-items-start justify-content-start m-0 p-1">
                                    <div class="col-xg-4 col-lg-4 col-md-4 col-sm-4 m-0">
                                        <label for="childs">Niños</label>
                                        <select formControlName="firstqtyChilds" class="custom-select form-control-sm"
                                            id="childs" (ngModelChange)="_addChild('firstchildsage','firstqtyChilds')"
                                            name="childs" [value]="0">
                                            <option *ngFor="let qty of _maxPersons" [value]="qty">{{ qty }}</option>
                                        </select>
                                    </div>
                                    <div class="p-0 m-0 col-8">
                                        <!-- Children age inputs -->
                                        <div id="childrenAges" class="col-12 m-0 p-0"
                                            *ngIf="formArray(_form, 'firstchildsage').length > 0">
                                            <label for="age">Edades de los niños</label>
                                            <div class="d-flex flex-row">
                                                <div formArrayName="firstchildsage"
                                                    *ngFor="let age of formArray(_form, 'firstchildsage'); let j = index;"
                                                    class="col-3 select-ages">
                                                    <div [formGroupName]="j" class="col-12 p-0">
                                                        <input formControlName="age" min=0 max=17 limit-to="14"
                                                            class="form-control col-10 p-3 input-age"
                                                            value-formatter="0" placeholder="0" name="age">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngFor="let age of formArray(_form, 'firstchildsage'); let j = index;">
                                                <span class="help-block text-danger d-flex w-100 text-nowrap"
                                                    *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>La edad de los niños {{j+1}} es incorrecta</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="d-flex justify-content-end flex-row">
                                    <div class="p-2 justify-content-end">
                                        <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed"
                                            aria-controls="false" class="btn btn-outline-danger btn-sm" type="button">
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-xg-4 col-lg-3 col-md-6 col-sm-6 px-2 mx-0">
                <label for="arrivalDate">&nbsp;</label>
                <input formControlName="firstArrivalDate" type="text" class="form-control input-search-box" id="departureDate"
                    placeholder="Fecha de recogida" autocomplete="off" bsDatepicker [minDate]="minDate"
                    [bsConfig]="dpConfig" readonly>
                <span class="help-block text-danger"
                    *ngIf="_form.get('firstArrivalDate').touched && _form.get('firstArrivalDate').hasError('required')">Este campo es obligatorio</span>
                <small class="error-label"
                    *ngIf="_form.get('firstArrivalDate').touched && _form.get('secondArrivalDate').value !== null && _form.get('firstArrivalDate').hasError('invalidDate')">
                    * ¡El check-in debe ser una fecha anterior al check-out!
                </small>
            </div>
            <div class="col-xg-3 col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center hours px-1 mx-0">
                <label for="durations">&nbsp;</label>
                <timepicker formControlName="firstTime" [showMeridian]="ismeridian"></timepicker>
            </div>
            <div class="form-group col-xg-3 col-lg-3 col-md-6 col-sm-6 px-2 mx-0"
                [style.visibility]="isOpen ? 'visible' : 'hidden'">
                <label for="arrivalDate">&nbsp;</label>
                <input formControlName="secondArrivalDate" type="text" class="form-control input-search-box" id="departureDate"
                    placeholder="Fecha de salida de recogida" autocomplete="off" bsDatepicker [minDate]="minDate"
                    [bsConfig]="dpConfig">
                <span class="help-block text-danger"
                    *ngIf="_form.get('secondArrivalDate').touched && _form.get('secondArrivalDate').hasError('required')">Este campo es obligatorio</span>
                <small class="error-label"
                    *ngIf="_form.get('secondArrivalDate').touched && _form.get('firstArrivalDate').value !== null && _form.get('secondArrivalDate').hasError('invalidDate')">
                    * ¡El check-in debe ser una fecha anterior al check-out!
                </small>
            </div>
            <div class="col-xg-3 col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center hours px-1 mx-0"
                [style.visibility]="isOpen ? 'visible' : 'hidden'">
                <label for="durations">&nbsp;</label>
                <timepicker formControlName="secondTime" [showMeridian]="ismeridian"></timepicker>
            </div>
            <div class="form-group col-xs-12 col-lg-2 col-md-4 justify-content-start align-middle inp">
                <button class="btn btn-usblick" type="submit" [disabled]="!_form.valid" data-toggle="modal">
                    <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
                    Buscar
                </button>
            </div>

        </div>
        <div class="d-flex form-row flex-row justify-content-start pb-5" id="selector">
            <div class="d-flex p-2 form-group align-items-center justify-content-end ">
                <div class="radio d-flex p-0 align-items-center justify-content-between">
                    <label class="d-flex align-items-center">
                        <!-- <input formControlName="type" class="form-check-input" type="radio" name="type"
                            id="inlineRadio1" value="oneTrip" (change)="handleChange('oneTrip')">
                        <span class="cr">
                            <i class="cr-icon fa fa-circle"></i>
                        </span> -->
                        <input formControlName="type" type="radio" name="type" id="inlineRadio1" value="oneTrip"
                            (change)="handleChange('oneTrip')">
                        <span class="cr">
                            <i class="cr-icon fas fa-check"></i>
                        </span>
                    </label>
                </div>
                <label class="px-2">Un viaje</label>

            </div>
            <div class="d-flex p-2 form-group align-items-center justify-content-end">
                <div class="radio d-flex p-0 align-items-center justify-content-between">
                    <label class="d-flex align-items-center">
                        <!-- <input formControlName="type" class="form-check-input" type="radio" name="type"
                            id="inlineRadio1" value="roundTrip" (change)="handleChange('roundTrip')">
                        <span class="cr">
                            <i class="cr-icon fa fa-circle"></i>
                        </span> -->
                        <input formControlName="type" type="radio" name="type" id="inlineRadio1" value="roundTrip"
                            (change)="handleChange('roundTrip')">
                        <span class="cr">
                            <i class="cr-icon fas fa-check"></i>
                        </span>
                    </label>
                </div>
                <label class="px-2">Ida y Vuelta</label>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-xg-5 col-lg-5 col-xs-0 p-0 align-items-center justify-content-between">
            </div>
            <div class="form-group col-xg-7 col-lg-7 col-xs-0 p-0 align-items-center justify-content-between">
                <p *ngFor="let info  of message" class="error-label">
                    * {{info}}
                </p>
            </div>
        </div>
    </form>
</div>