<div class="d-block card container col col-xg-12 col-lg-12 col-md-12 col-xs-12 text-center w-100 p-0 m-0 py-5" style="z-index: 0;">
  <div class="top-content">
    <div class="container-fluid">
      <owl-carousel-o [options]="customOptions" class="destination-big">
        <ng-container *ngFor="let destination of destinations; let i = index">
          <ng-template carouselSlide>
            <div (click)="articleRedirect(i + 1)" class="container-link">
            <img [src]="destination.image" alt="{{destination.title}} - imagen" loading="lazy" class="img-fluid mx-auto d-block image-brightness">
            <div class="carousel-caption d-none d-md-block p-0">
              <div>
                <h6 class="text-center destination-title">{{destination.title.substr(0, 6)}} <span><br>{{destination.title.substr(6)}}</span></h6>
              </div>
              <div class="gallery-btn text-center">
                <button class="btn btn-usblick" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"></button>
              </div>
              <p class="text-center destination-sub-title">{{destination.subtitle}}</p>
              <hr class="separator">
              <p class="text-center destination-footer-title">{{destination.description}}</p>
              <div class="row m-0">
                <div class="col-md-4 col-sm-12 col-12 mt-lg-0 pr-3 pl-3"></div>
                <div class="col-md-4 col-sm-12 col-12 mt-lg-0 pr-3 pl-3">
                  <div class="row justify-content-center align-content-center">
                    <div class="col-md-4 col-sm-12 col-12 mt-lg-0 mt-4 py-2 pt-3 pr-3 pl-3">
                      <i aria-hidden="true" class="icon-camera"></i>
                    </div>
                    <div class="col-md-4 col-sm-12 col-12 mt-lg-0 mt-4 py-2 pt-3 pr-3 pl-3">
                      <i aria-hidden="true" class="icon-video-camera"></i>
                    </div>
                    <div class="col-md-4 col-sm-12 col-12 mt-lg-0 mt-4 py-2 pt-3 pr-3 pl-3">
                      <i aria-hidden="true" class="icon-speech-bubbles-comment-option"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 col-12 mt-lg-0 pr-3 pl-3"></div>
              </div>
            </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
