<div class="t-content">
    <form (ngSubmit)="_onSubmit(_form, $event, template)" [formGroup]="_form" novalidate>
        <!--***********Basic Data*************-->
        <div class="form-row pt-3">
            <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12">
                <label for="arrivalDate">&nbsp;</label>
                <input formControlName="arrivalDate" type="text" class="form-control input-search-box" id="arrivalDate" placeholder="Fecha" autocomplete="off" bsDatepicker [minDate]="minDate" [bsConfig]="dpConfig" readonly>
                <!--<label for="validateDate" class="error-label" *ngIf="showLabel">The dates can not be the same</label>-->
            </div>
            <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12">
                <label for="roomandperson">&nbsp;</label>
                <button type="button" class="form-control btn-usb-rp font-poppins" style="font-size: 14px; font-weight: 500;" (click)="_openModal();verifyItinerary()" [attr.aria-expanded]="!isCollapsed" aria-controls="false">
          {{roomsAndPassengers}}
          <span class="arrow-sort fa fa-sort"></span>
        </button>
                <div *ngFor="let room of _form.value['room']; let i = index">
                    <span class="help-block text-danger" *ngIf="((validate || (_close && isCollapsed)) && (room['qtyAdults'] == 0))">Al menos selecciona 1 adulto en la habitación
            {{i+1}}.</span>
                </div>
                <div (click)="_over()" (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close" class="justify-content-end">
                    <div class="row">
                        <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
                            <div class="card">

                                <div formArrayName="room" *ngFor="let item of formRoom(_form, 'room'); let i = index" class="d-flex flex-row col-12 align-items-center justify-content-between p-2" style="position: relative;">
                                    <div [formGroupName]="i" class="d-flex flex-row align-items-center justify-content-between col-12 p-0">
                                        <div class="p-2 col-2" style="padding:25px 0 0 0 !important;">Hab. {{i + 1}}</div>
                                        <div class="p-0 m-0 col-2">
                                            <label for="adults">Adultos</label>
                                            <select formControlName="qtyAdults" class="custom-select form-control-sm " id="adults" name="adults" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                                        </div>
                                        <div class="p-0 m-0 col-2">
                                            <label for="childs">Niños</label>
                                            <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs" (ngModelChange)="_addChild(item)" name="childs" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                                        </div>
                                        <div class="p-0 col-5">
                                            <!-- Children age inputs -->
                                            <div id="childrenAges" class="p-1 col-12" *ngIf="item.get('childsage').length > 0">
                                                <label for="age">Edad de Niños</label>
                                                <div class="col-12 p-0 m-0">
                                                    <div formArrayName="childsage" *ngFor="let age of formRoom(item, 'childsage'); let j = index;" class="col-3 select-ages">
                                                        <div [formGroupName]="j" class="col-12 p-0 m-0">
                                                            <input formControlName="age" min=0 max=17 limit-to="14" class="form-control col-11 p-3 input-age" placeholder="0" name="age" required>
                                                        </div>
                                                    </div>
                                                    <div *ngFor="let age of formRoom(item, 'childsage'); let j = index;">
                                                        <span class="help-block text-danger col-12" *ngIf="age.controls['age'].hasError('min') ||age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>La edad de los niños {{j+1}} es incorrecta</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-0 justify-content-start align-items-center text-center" style="position: absolute; right: 0;">
                                            <button (click)="_removeRoom(i)" class="btn btn-outline-danger col-12 input-age btn-sm" [disabled]="_form.get('qtyProduct').value===1">
                        <i class="fa fa-times"></i>
                      </button>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                                <hr>
                                <div class="d-flex justify-content-between flex-row">
                                    <div class="p-2 justify-content-start">
                                        <button type="button" [disabled]="_form.get('qtyProduct').value===7" (click)="_addRoom()" class="btn btn-outline-danger btn-sm">
                      Agregar Habitación
                    </button>
                                        <span class="errors" *ngIf="_form.get('qtyProduct').value===7"><br> El número máximo de habitaciones es 7.</span>
                                    </div>
                                    <div class="p-2 justify-content-end">
                                        <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false" class="btn btn-outline-danger btn-sm" type="button">
                      Confirmar
                    </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-xg-4 col-lg-2 col-md-12 col-sm-12">
            </div>
            <div class="form-group col-xg-3 col-lg-2 col-md-12 col-sm-12 justify-content-end align-middle btns">
                <button class="btn btn-usblick" type="submit" [disabled]="(!_form.valid || _findData || userService.itineraryActive.length == 0)">
          <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
          Buscar
        </button>
            </div>

        </div>
        <div class="form-row">
            <div class="form-group col-xg-4 col-lg-4 col-xs-12 col-md-4 justify-content-start align-middle py-2">
                <div class="checkbox">
                    <label>
            <input formControlName="accessRoom" type="checkbox" value="">
            <span class="cr">
              <i class="cr-icon fa fa-check"></i>
            </span>
            <label class="form-check-label">Acceso a habitaciones para discapacitados. </label>
                    </label>
                </div>
            </div>

            <div class="form-group col-lg-5 col-xs-12 col-md-4 justify-content-start align-middle">
            </div>
            <div class="form-group col-lg-4 col-xs-12 col-md-4 justify-content-start align-middle">

            </div>
        </div>
    </form>
</div>

<ng-template #template>
    <form method="POST">
        <div class="modal-body text-center" id="confirm">
            <p>"Desea cambiar la fecha del file, ¿Se borrara los items actuales?"</p>
            <button type="button" data-placement="bottom" class="btn btn-sm btn-usblick" title="Yes" (click)="_deleteItems(_form, $event)">Yes</button>
            <button type="button" class="btn btn-outline-danger btn-usblick btn-sm" (click)="directSubmitActualDate(_form, $event);">No</button>
        </div>
    </form>
</ng-template>
