import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-hotel-detail-summary',
  templateUrl: './item-hotel-detail-summary.component.html',
  styleUrls: ['./item-hotel-detail-summary.component.scss']
})
export class ItemHotelDetailSummaryComponent implements OnInit {
  @Input() item: any;
  @Input() i: any;

  constructor() { }

  ngOnInit() {
   console.log('Item input: ',this.item);
  }

}
