import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-tours',
  templateUrl: './item-tours.component.html',
  styleUrls: ['./item-tours.component.scss']
})
export class ItemToursComponent implements OnInit {

  @Input() item: any;

  constructor() { }

  ngOnInit() {
    console.log("Item", this.item);

  }
  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }
}
