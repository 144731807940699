import { Component, OnInit, Input } from '@angular/core';
import { setting } from '../../../../setting';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'usb-steps-reservation',
  templateUrl: './steps-reservation.component.html',
  styleUrls: ['./steps-reservation.component.scss']
})
export class StepsReservationComponent implements OnInit {

  @Input() step:string;
  @Input() permitedConfiguration:boolean;

  public getClientsSettingAppBooking: any;
  currentStep: number;

  constructor( private activatedRoute: ActivatedRoute ) {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }

  ngOnInit() {
    this.currentStep = 1;
    this.activatedRoute.url.subscribe((url) => {
      if(url.toString().includes('itinerary-detail')) {
        this.currentStep = 1;
      } else if(url.toString().includes('itinerary-reservation')) {
        this.currentStep = 2;
      } else if(url.toString().includes('DetailsSummaryItinerary')) {
        this.currentStep = 3;
      }
    })
  }

}
