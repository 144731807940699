<div class="col-12 mt-3" *ngIf="item">
    <div class="row card-itinerary p-0 py-3 pl-2" [ngClass]="{'card-itinerary-non-available': ((status === 'price-non-available') || (status === 'room-non-available') || (status === 'price-non-available') || (status === 'non-available'))}">
        <!-- icono actividad registrada -->
        <div class="d-flex content-icons p-0">
            <div class="icon-itinerary-activity" (click)="select()">
                <img class="img-fluid icon-items"
                    src="  {{  item.item.categoryName === 'TOURS' ? '/assets/img/app/little-icons/adventure-sports-green.svg' :
                            item.item.categoryName === 'HOTELS' ? '/assets/img/app/little-icons/bed-green.svg' :
                            item.item.categoryName === 'PTHEMES' ? '/assets/img/app/icon-pthemes-green.svg' :
                            item.item.categoryName === 'TRANSPORTS' ? '/assets/img/app/icon-bus-green.svg' :
                            item.item.categoryName === 'DISNEY' ? '/assets/img/app/disney-green.svg' :
                            item.item.categoryName === 'RENT-A-CAR' ? '/assets/img/app/icon-car-green.svg' :
                            item.item.categoryName === 'INSURANCE' ? '/assets/img/app/icon-seguro-green.svg' :
                            item.item.categoryName === 'CRUISES' ? '/assets/img/app/icon-crucero-green.svg' : '/assets/img/app/icon-maletas-green.svg'
                        }}
                        "
                    alt="">
            </div>
        </div>
        <!-- item detail -->
        <div class="d-flex content-detail px-3">
            <usb-item-details
                [itinerary]="itinerary"
                [reservation]="reservation"
                [modifications]= "modifications"
                [item]="item.item"
                [selected]="selected"
                [status]="status"
                (delete)="deleteItem($event)"
                (cancel)="cancelReservations($event)"
                (reSearch)="reSearch($event)"
                style="width: 100% !important;"
            >
            </usb-item-details>
        </div>
        <!-- item status -->
        <div class="d-flex content-status p-0">
            <usb-item-status *ngIf="_status"
            [reservation]="reservation"
            [modifications]= "modifications_"
            (accept)="acceptNewPrice()"
            (reserve)="reserveItem()"
            (delete)="deleteItem($event)"
            (reSearch)="reSearch($event)"
            [status]="_status"
            [item]="item"
            [showTextChangeDetailsProducts]="showTextChangeDetailsProducts"
            (update)="updateItem($event)"
            [selected]="selected"
            [partialReserve]="partialReserve"
            (itemToPartial)="_itemToPartial($event)"
            [changePriceFromReserve]="changePriceFromReserve"
            (reloadItinerary)="_reloadItinerary()"
            style="width: 100% !important;">
        </usb-item-status>
        </div>
    </div>
</div>
