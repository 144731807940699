import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicFontSize'
})
export class DynamicFontSizePipe implements PipeTransform {

  transform(value: number, baseSize: number = 16, adjustments: { [key: number]: number } = { 5: 0, 7: -2, 9: -4 }): string {
    if (!value) {
      return `${baseSize}px`; // Tamaño base si no hay valor
    }

    const digitCount = value.toString().replace('.', '').length; // Longitud excluyendo el punto decimal

    // Encontrar el ajuste correspondiente
    let fontSize = baseSize;
    for (const [digitThreshold, adjustment] of Object.entries(adjustments)) {
      if (digitCount >= +digitThreshold) {
        fontSize = baseSize + adjustment;
      }
    }

    return `${fontSize}px`; // Devolver tamaño ajustado como string
  }

}
