import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-insurance-detail-summary',
  templateUrl: './item-insurance-detail-summary.component.html',
  styleUrls: ['./item-insurance-detail-summary.component.scss']
})
export class ItemInsuranceDetailSummaryComponent implements OnInit {
  @Input() item: any;
  constructor() { }

  ngOnInit() {
  }

}
