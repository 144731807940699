import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';
import { setting } from '../../../../setting';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'usb-material-modal-confirm',
  templateUrl: './material-modal-confirm.component.html',
  styleUrls: ['./material-modal-confirm.component.scss']
})
export class MaterialModalConfirmComponent implements OnInit {

  public comparisonRouteImagenLogo: any;
  public paymentData: any[] = [];
  public paymentModal: any;
  public message: string = '';
  public origin: string;
  public currencyItinerary: string;

  public confirmResult: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MaterialModalConfirmComponent>,
    public userService: UserService,
    public bsModalRef: BsModalRef
  )
  { }

  ngOnInit() {
    console.log(this.message)
    console.log('data modal', this.data);

    this.comparisonRouteImagenLogo = setting.apiStorageBooking;
    if(this.paymentModal) {
      this.paymentData = this.paymentModal.payment;
      console.log(this.paymentData)
    }
    // if(this.data.message) {
    //   this.message = this.data.message;
    // }
    if(this.message && this.origin === 'clients') {
      this.data.message = this.message;
    }

    if(this.message && this.origin !== 'clients') {
      this.data.message = this.message;
    }


  }

  get agencyImagen(): string {
    let agencyImagen = this.userService._imagen_logotype;
    if (agencyImagen === this.comparisonRouteImagenLogo || agencyImagen === '') {
        return agencyImagen =  'assets/img/sin-logo.png';
    } else {
        return agencyImagen;
    }
}

confirm(): void {
  console.log('confirm');
  this.confirmResult.next(true);
  this.bsModalRef.hide();
}

decline(): void {
  this.confirmResult.next(false);
  this.bsModalRef.hide();
}


}
