import { Component, OnInit, ViewChild, NgModule, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';

//import { ToastsManager } from 'ng2-toastr';
import { FormGroup } from '@angular/forms';
import { ItineraryService } from '../../itinerary.service';
import { RoomConfigurationItem } from '../../../home/search-box/categories/hotels/roomConfigurationItem';
import { CookieService } from 'ngx-cookie';
import { isValidOwener, fillOwnerMessage } from '../../../utils/Functions';
import {  } from 'google-maps';

@Component({
  selector: 'usb-modal-packages',
  templateUrl: './modal-packages.component.html',
  styleUrls: ['./modal-packages.component.scss']
})
export class ModalPackagesComponent implements OnInit {
  @Input() productQty: number;
  @Input() packages: any;
  @Input() results: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;

  @Output() refModal = new EventEmitter<any>();

  @ViewChild('gmap') gmapElement: any;

  max: number;
  rate = 4;
  ratereview = 4.5;
  isReadonly: boolean;
  x = 5;
  y = 2;
  select: any[] = [];
  totalQty = 0;
  single: any[];
  view: any[] = [200, 150];
  disableButton: boolean = true;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = '.';
  showYAxisLabel = true;
  yAxisLabel = '%';

  colorScheme = {
    domain: ['#00a680', '#00a680', '#00a680', '#00a680', '#00a680']
  };

  tab: string = '#itinerary'
  subtab: string = '#mega'
  collapseList: any = [
    {
      'collapseOne': false,
      'collapseTwo': false,
      'collapseThree': false,
      'collapseFour': false
    },
    {
      'collapseOne': false,
      'collapseTwo': false,
      'collapseThree': false,
      'collapseFour': false
    }
  ]
  imgPortada: any = [
    '/assets/img/app/package/pack-img.png',
    '/assets/img/app/package/pack-img2.png'
  ]
  imgList: any = [
    // [
    //   '/assets/img/app/disney-hotel/disney_0.jpg',
    //   '/assets/img/app/disney-hotel/disney_1.jpg',
    //   '/assets/img/app/disney-hotel/disney_2.jpg',
    //   '/assets/img/app/disney-hotel/disney_3.jpg',
    //   '/assets/img/app/disney-hotel/disney_4.jpg',
    //   '/assets/img/app/disney-hotel/disney_5.jpg',
    //   '/assets/img/app/disney-hotel/disney_6.jpg',
    //   '/assets/img/app/disney-hotel/disney_7.jpg'
    // ],
    // [
    //   '/assets/img/app/disney-hotel/disney_0.jpg',
    //   '/assets/img/app/disney-hotel/disney_1.jpg',
    //   '/assets/img/app/disney-hotel/disney_2.jpg',
    //   '/assets/img/app/disney-hotel/disney_3.jpg',
    //   '/assets/img/app/disney-hotel/disney_4.jpg',
    //   '/assets/img/app/disney-hotel/disney_5.jpg'
    // ]
  ]
  imgSelect: any = [0,0]
  valueDate: any = 'Select date'
  openDates: any = false

  _cookieClient: any = undefined;
  constructor(
    public vcr: ViewContainerRef,
    private _itineraryService: ItineraryService,
    private _cookieService: CookieService,) {
    this.single = [
      {
        'name': 'Excelente',
        'value': 74
      },
      {
        'name': 'Muy Bueno',
        'value': 19
      },
      {
        'name': 'Regular',
        'value': 6
      },
      {
        'name': 'Malo',
        'value': 1
      },
      {
        'name': 'Pésimo',
        'value': 0
      }
    ];
  }


  ngOnInit() {
    console.log("Packages seleccionado", this.packages);
    console.log('Este es Array Result', this.arrayResults);
    console.log('Este es itinerary Item', this.itineraryItem);
    this.cacheAdd();

    if (this.packages !== undefined && this.packages.additionalInfo !== undefined) {
      if (this.packages.additionalInfo.rooms !== undefined) {
        this.createSelectsOfRoom(this.packages.additionalInfo.rooms.length, this.productQty + 1);
      }
      if (this.packages.additionalInfo.roomsPackage !== undefined) {
        this.createSelectsOfRoom(this.packages.additionalInfo.roomsPackage.length, this.productQty + 1);
      }
    }

    //this.mapMarker();
  }


  cacheReturn(){

    // var aux = (this.arrayResults===undefined) ? ((this.itineraryItem.payloadUpdate.products[0].others!==undefined) ? this.itineraryItem.payloadUpdate.products[0].others : '') : this.arrayResults.others;
    if(localStorage.getItem('others')){
      console.log('Se asigno others de cache');
      return JSON.parse(localStorage.getItem('others'));
    }else{
      return (this.arrayResults===undefined) ? ((this.itineraryItem.payloadUpdate.products[0].others!==undefined) ? this.itineraryItem.payloadUpdate.products[0].others : '') : this.arrayResults.others;
    }

  }

  cacheAdd(){
    if(this.arrayResults && !this.itineraryItem){
      if(!localStorage.getItem('others')){
        console.log('Se guardo others');
        localStorage.setItem('others',JSON.stringify(this.arrayResults.others));
      }
    }
  }

  mapMarker(){
    //se inicializa el mapa con zoom 4
    var geocoder;
    var mapa;
    var mapOptions = {
      center: new google.maps.LatLng(28.3256749, -81.51555309999999),
      zoom: 4,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    //seteamos el maa
    mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

    //buscamos las coordenadas con la direccion dada del hotel
    geocoder = new google.maps.Geocoder;
    var title = this.packages.title;
    geocoder.geocode( { 'address': this.packages.additionalInfo.location.trim()}, (results, status) => {
      if (status.toString() == 'OK') {
        var marker = new google.maps.Marker({
            map: mapa,
            position: results[0].geometry.location,
            title: title
        });

        google.maps.event.trigger(mapa, "resize");
        //colocamos el market
        mapa.panTo(marker.getPosition());
        mapa.setZoom(16);
      } else {
        console.log('No se obtuvo la ubicacion del mapa: ' + status);
      }
    });
    mapa.set('parent', this);
  }

  /**
   * Metodo que crea los select que se veran reflejados en las habitaciones.
   * @param qty Indica la cantidad de select que se crearan (se crea 1 por cada room del hotel).
   * @param maxProductSelect Indica los valores del array (se le agrega 1 mas por el valor 0).
   */
  createSelectsOfRoom(qty: number, maxProductSelect: number) {
    for (let i = 0; i < qty; i++) {
      this.select.push({
        selectArray: Array.from(Array(maxProductSelect).keys()),
        selectValue: 0,
      });
    }
  }

  calculateTotalPrice() {
    this.totalQty = 0;
    this.disableButton = true;
    this.select.forEach((obj, index) => {
      if(this.packages.additionalInfo.rooms){
        this.totalQty += this.packages.additionalInfo.rooms[index].optionNightsNetTotal * (+obj.selectValue);
        this.packages.additionalInfo.rooms[index].reservations = +obj.selectValue;
        this.packages.additionalInfo.rooms[index].reserved = +obj.selectValue > 0 ? true : false;
        if ((+obj.selectValue) > 0) {
          this.disableButton = false;
        }
      }
      if(this.packages.additionalInfo.roomsPackage){
        this.totalQty += this.packages.additionalInfo.roomsPackage[index].optionNightsNetTotal * (+obj.selectValue);
        this.packages.additionalInfo.roomsPackage[index].reservations = +obj.selectValue;
        this.packages.additionalInfo.roomsPackage[index].reserved = +obj.selectValue > 0 ? true : false;
        if ((+obj.selectValue) > 0) {
          this.disableButton = false;
        }
      }
    });
  }

  modalRomms() {
    this.max = 10;
    this.isReadonly = true;
  }
  modalRoom() {
    this.isReadonly = true;
  }

  continue() {
    console.log('---ModalRoomsComponet--- continue()---');
    const productQtyNowSelect = this.productQtyNowSelect(this.select);
    if (productQtyNowSelect === this.productQty) {
      if (this.packages.additionalInfo.reserveMultiHab || this.select.filter(obj => obj.selectValue > 0).length === 1) {
        const roomToReserved = (this.packages.additionalInfo.rooms) ? this.packages.additionalInfo.rooms.filter(obj => obj.reserved === true) : this.packages.additionalInfo.roomsPackage.filter(obj => obj.reserved === true);
        roomToReserved.forEach(element => {
          this.addItinerary(element, this.packages);
        });
      } else {
        //this.toastr.info('This hotel does not support the selection of several room types.');
      }
    } else {
      //${productQtyNowSelect}
      //this.toastr.info(`Please select ${this.productQty} rooms`);
    }
  }

  productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach(obj => total += +obj.selectValue);
    return total;
  }

  addItinerary(item: any, packages: any, reservation?: any) {
    //validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      this._emitRefModal('warning - Warning - ' + fillOwnerMessage());
      return;
    }

    console.log('ModalRoomsComponent--- addItinerary()');
    console.log(this.origin);
    console.log(this.arrayResults);
    item.id = packages.id + '-' + item.roomId;
    item.searchId = (this.arrayResults===undefined) ? ((this.itineraryItem.searchId!==undefined) ? this.itineraryItem.searchId : '') : this.arrayResults[0].searchId;
    item.providerId = packages.providerId;
    // item.form = this.form.value;
    item.providerName = packages.providerName;
    item.hotelId = packages.id;
    item.idProduct = packages.productId;
    item.categoryName = 'PACKAGES';
    item.title = packages.title;
    item.location = packages.additionalInfo.location;
    item.arrivalDate = packages.dateIni;
    item.departureDate = packages.dateEnd;
    item.updateDate = new Date();
    item.categoryHotel = packages.additionalInfo.category;

    // se agrega el payload de busqueda de producto al objeto room
    console.log('ModalRoomsComponent--- addItinerary()--- form ', this.form);
    if (this.form === null || this.form === undefined) {
      console.log(this.payloadUpdate);
      if (this.payloadUpdate !== undefined) {
        item.payloadUpdate = this.payloadUpdate;
      } else {
        item.payloadUpdate = {
          products: [{
            providerId: this.packages.providerId,
            nameAdapter: this.packages.nameAdapter,
            categoryId: this.packages.categoryId,
            productId: this.packages.id,
            productName: this.packages.title,
            origin: this.packages.origin,
            destination: this.packages.destination,
            priceFrom: 0,
            priceTo: 10000.00,
            arrivalDate: this.packages.dateIni,
            departureDate: this.packages.dateEnd,
            qtyProduct: this.productQty,
            qtyPax: (this.arrayResults===undefined) ? ((this.itineraryItem.payloadUpdate.products[0].qtyPax!==undefined) ? this.itineraryItem.payloadUpdate.products[0].qtyPax : '') : this.arrayResults.qtyPax,
            others: this.cacheReturn()
          }]
        };
      }
    } else {
      item.payloadUpdate = this._payloadUpdatePackages(this.form);
      console.log('harakiri 2', item);
    }

    console.log("se envia a get destination", item);

    //se adiciona destination_info
    item.destination = this._itineraryService.getDestinationInfo(this.form, item);

    //console.log('item ytinerary' ,this.itineraryItem);

    if (this.itineraryItem !== undefined) {
      item.destination = this.itineraryItem.destination;
      this._itineraryService.remove(this.itineraryItem, item);
      this._emitRefModal('success - Congratulations - Product updated succesfully');
    } else {
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(item).subscribe((response:any) => {
        console.log('Add-Success: ', response);
        if (typeof response === 'string') {
          this._emitRefModal('error - Error - ' + response);
        } else {
          this._emitRefModal('success - Congratulations - Product added succesfully to your itinerary');
        }
        this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary['items']);
        this._itineraryService.sendMessageSubscriptorLoading(false);
        if (reservation) {
          this._itineraryService._restService.doPost('app', 'reservations', reservation).subscribe((response:any) => {
            console.log('Reservation-Success: ', response);
          }, error => {
            console.log('Reservation-Error: ', error);
          });
        }
      }, error => {
        console.log('Add-Error: ', error);
        this._emitRefModal('error - Error - Item was not added to your itinerary, please check the console');
        this._itineraryService.sendMessageSubscriptorLoading(false);
      });
    }
  }


  _payloadUpdatePackages(form: FormGroup): Object {
    let qtyPax;
    let others;
    console.log(this.origin);
    if (this.origin === undefined || this.origin == 'PACKAGES') {
      others = {
        serialized: false,
        maxRequestTimeOnSeconds: 20,
        minNumResults: 1,
        maxNumResultsPerAdapter: "",
        hotels: {
          roomConfiguration: this._getRoomDist(form)
        }
      }
    }
    return {
      products: [{
        providerId: this.packages.providerId,
        nameAdapter: this.packages.nameAdapter,
        categoryId: this.packages.categoryId,
        productId: this.packages.id,
        productName: this.packages.title,
        origin: this.packages.origin,
        destination: this.packages.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.packages.dateIni,
        departureDate: this.packages.dateEnd,
        qtyProduct: this.productQty,
        qtyPax: qtyPax,
        others: others
      }]
    };
  }
  _getRoomDist(form?: any, item?:any ) {
    const roomDist = [];
    const rooms = form.value.room;
    for (let i = 0; i < rooms.length; i++) {
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: [],
        boardNameCode: item.boardNameCode,
        condition: item.rateClassId,
        roomId:item.roomId,
        roomCode:item.roomCode,
        bookingCode:item.bookingCode
      };
      const adults = rooms[i].qtyAdults;
      const children = rooms[i].childsage;
      for (let j = 0; j < children.length; j++) {
        people.push(children[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);
    }
    return roomDist;
  }


  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  tabs(idtab){
    this.tab= idtab
  }

  subtabs(idsubtab){
    this.subtab = idsubtab
  }

  prev(i){
    if(this.imgSelect[i]>0){
      this.imgSelect[i]=this.imgSelect[i]-1
    }
    if(this.imgSelect[i]<=5){
      this.scroll('l',i)
    }
  }
  next(i){
    if(this.imgSelect[i]<(this.imgList[i].length-1)){
      this.imgSelect[i]++
    }
    if(this.imgSelect[i]>=5){
      this.scroll('r',i)
    }
  }
  scroll(dir,id){
    let idc = 'slide-miniatura'+id
    let content = document.getElementById(idc)
    const stepr = 150
    const stepl = -150
    if(dir=='l'){
      content.scrollLeft += stepl
    }else if(dir=='r'){
      content.scrollLeft += stepr
    }
  }
  counter(i: number) {
    return new Array(i);
  }
  twoLines(str){
    const lines = str.split(',')
    return lines
  }
}
