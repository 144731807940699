import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './article.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ArticleRoutingModule } from './article.routing';
import { SharedModule } from 'app/shared/shared.module';
import { ArticleService } from './article.service';

@NgModule({
  declarations: [ArticleComponent],
  imports: [
    CommonModule,
    ArticleRoutingModule,
    NgxPaginationModule,
    SharedModule
  ],
  exports: [ArticleComponent],
  providers: [ArticleService]
})
export class ArticleModule { }
