<!-- <span>
  <usb-vouchers-reservation [item]=item [itinerary]=itinerary  [id]=item.id ></usb-vouchers-reservation>
</span> -->

<div class="d-flex flex-column justify-content-between" style="min-height: 128px; height:100%">
  <div class="row" style="margin-top: -10px;">
    <div class="col-10">
      <div class="row">
        <div class="col p-0 pt-2 pl-3">
          <p class="font-poppins title-item m-0 pt-1"> {{item.title ? stingUpperCase(item.title) : stingUpperCase(item.title)}}  <span class="d-flex flex-nowrap" *ngIf="(item.categoryName === 'DISNEY') || (item.categoryName === 'HOTELS')">
            <i *ngFor="let star of stars(); let i=index" class="icon-estrella"></i>
          </span></p>
          <span *ngIf="(item.categoryName === 'RENT-A-CAR')" class="subtitle font-lato">
            {{ item?.category }} , {{ (((item?.type_definition).length) > 80)? (item.type_definition.substring(0,80)) : item?.type_definition }}
          </span>
          <div *ngIf="(item.categoryName === 'HOTELS')" class="font-poppins location-text m-0 mt-2">
            <!-- <i class="icon-placeholder font-verde"></i>
            <span>{{stingUpperCase(getLocation())}}</span> -->
            <div class="rooms-details d-flex flex-column font-poppins location-text">
              <div class="room-type" style="width: 15rem;">
                <span>{{item.roomType}}</span>
              </div>
              <div class="rate-class">
                <span>{{item.rateClassId == 'R' ? 'Reembolsable' : 'No reembolsable'}}</span>
              </div>
              <div class="date-room">
                <span>{{item.arrivalDate | date: 'dd/MM/yy'}} - {{item.departureDate | date: 'dd/MM/yy'}}</span>
              </div>
            </div>
            <!-- Puede que la variable de hotelReservationId cambie a providerReservationId -->
            <div class="mt-2" style="color:#d40057" *ngIf="item.status_id === 2">Localizador: {{item?.resultReserve?.providerReservationId}}</div>
          </div>
          <div *ngIf="(item.categoryName === 'RENT-A-CAR')" class="font-poppins location-text m-0 mt-2">
            <div>Lugar de Recogida: {{item.pickupname}}</div>
            <div>Lugar de Entrega: {{item.dropoffname}}</div>
            <div style="color:#d40057" *ngIf="item.status_id === 2">Localizador: {{item?.resultReserve?.additionalInfo?.idReservation}}   <!--Locator --> </div>
          </div>
          <div *ngIf="(item.categoryName === 'TOURS')" class="font-poppins location-text m-0 mt-2">
            <i class="icon-placeholder font-verde"></i>
            <span>{{item.location}}</span>
            <div style="color:#d40057" *ngIf="item.status_id === 2">Localizador: {{item?.resultReserve?.additionalInfo?.idReservation}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- calificacion -->


  </div>
  <div class="row" *ngIf="selected">
    <!-- detalles -->
    <div class="col-9 p-0 pt-2 pl-3">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <!-- <p class="font-poppins" *ngIf="(item.categoryName !== 'INSURANCE') && (item.categoryName !== 'TRANSPORTS')">
                            {{more ? (getDescription()) : (getDescription() | truncate : 250 : "  ")}}
                            <a *ngIf="!more" (click)="_more()" class="more">...More</a>
                        </p> -->

            <div *ngIf="item.categoryName === 'TRANSPORTS'">
              <div class="description-car d-flex pl-2 justify-content-between align-items-center">
                <div *ngIf="item['vehicles'][0]['maxPax'] !== null" class="flex-column d-flex text-center pl-2">
                  <span class="capacity p-0 m-0">{{item['vehicles'][0]['maxPax']}}<i class="icon icon-asiento p-0 m-0"></i></span>
                  <span class="detail-description-car">Seating</span>
                </div>
                <div *ngIf="item['vehicles'][0]['maxBag'] !== null" class="flex-column d-flex text-center">
                  <span class="capacity">{{item['vehicles'][0]['maxBag']}}<i class="icon icon-bag"></i></span>
                  <span class="detail-description-car">Bag</span>
                </div>
                <div class="flex-column d-flex text-center pr-2">
                  <i class="icon icon-ai"></i>
                  <span class="d-flex detail-description-car">AirCondition</span>
                </div>
              </div>
              <div><b>Pickup Location:</b> {{item.vehicles[0].transferZoneName}}</div>
              <div><b>Dropoff Location:</b> {{item.vehicles[1].transferZoneName}}</div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="(status === 'denied') && reservation" class="flex-column d-flex f-12">
              <div class="d-flex">
                <b>Pax Details</b>
              </div>
              <div class="d-flex pax-details">
                <span *ngFor="let person of reservation.paxList; let in=index">
                  {{person.first_name + ' ' + person.last_name + (person.age > 17 ? '' : '(' + person.age + ')')}}
                  {{in === (reservation.paxList.length - 1) ? '.' : ','}}
                </span>
              </div>
            </div>
            <div *ngIf="(status === 'reserved') && reservation" class="flex-column d-flex">
              <div class="d-flex f-12 w-500" *ngIf="(item.categoryName === 'HOTELS') || (item.categoryName === 'DISNEY')">
                <b>CheckIn</b>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getDate(true)}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <b>CheckOut</b>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getDate(false)}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- <b>Nights</b>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getNights()}}</span> -->
              </div>
              <div class="d-flex f-12 w-500" *ngIf="(item.categoryName !== 'HOTELS') && (item.categoryName !== 'DISNEY')">
                <b>In</b>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getDate(true)}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <b>Out</b>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getDate(false)}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <br>
              <div *ngIf="(item.categoryName === 'HOTELS') || (item.categoryName === 'DISNEY')" class="d-flex f-12">
                <div *ngFor="let room of reservation.resultReserve.additionalInfo.reservationDetail; let i=index">
                  <b>Room {{i + 1}}</b>
                  <div class="d-flex f-12 pax-details">
                    <span *ngFor="let person of room.pax; let in=index">
                      {{person.first_name + ' ' + person.last_name + ((person.type === 'Adult') || (person.type === 'Adults') ? '' : '(' + person.age + ')') + (in === (room.pax.length - 1) ? '.' : ', ')}}
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="(item.categoryName !== 'HOTELS') && (item.categoryName !== 'DISNEY') && reservation.resultReserve.additionalInfo"
                class="d-flex f-12 flex-column">
                <div class="d-flex">
                  <b>Pax Details</b>
                </div>
                <div class="d-flex">
                  <span *ngFor="let person of reservation.paxList; let in=index">
                    {{person.first_name + ' ' + person.last_name + (person.age > 17 ? '' : '(' + person.age + ')') + (in === (reservation.paxList.length - 1) ? '.' : ', ')}}
                  </span>
                </div>
              </div>
              <br>
              <div class="d-flex f-12">
                <b>Cancellation policies</b>
              </div>
              <div
                *ngIf="(!reservation.resultReserve.additionalInfo.cxlPolicy || reservation.resultReserve.additionalInfo.cxlPolicy.length === 0)"
                class="d-flex f-12">
                No cancellation policies for this reservation.
              </div>
              <div
                *ngIf="reservation.resultReserve.additionalInfo.cxlPolicy && (reservation.resultReserve.additionalInfo.cxlPolicy.length > 0)"
                class="d-flex f-12 w-500">
                Date From&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date
                To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Description
              </div>
              <div *ngFor="let cancellation of reservation.resultReserve.additionalInfo.cxlPolicy; let i=index" class="d-flex f-12 i-gray">
                <span>{{getClxDate(cancellation.from)}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getClxDate(cancellation.to)}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{getClxFee(cancellation.cxlCharge)}}%</span>
              </div>
              <br>
              <div class="d-flex f-12">
                <b>Restrictions</b>
              </div>
              <div class="d-flex f-12 descr">
                <span>{{reservation.resultReserve.additionalInfo.serviceDetail ? reservation.resultReserve.additionalInfo.serviceDetail.description : 'No restrictions available.'}}</span>
              </div>
              <br>
              <div class="d-flex f-12">
                <b>Observations</b>
              </div>
              <div class="d-flex f-12 descr">
                <span>{{reservation.resultReserve.description ? reservation.resultReserve.description : 'No observations available.'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- puntos, btn delete -->
    <div class="col-9 p-0 pt-2 pl-3" *ngIf="item.status_id != 5">
      <div class="row">
        <div class="col d-flex align-items-end justify-content-between">
          <div class="d-flex">
            <!-- Facturas, recibos Vouchers *ngIf="(item.status_id==2) && item.isPendigApprovePay===true || item.isPaidAgency===true || item.isPaidProvider===true || item.isBilled===true"-->
            <div class="d-flex flex-row justify-content-between container-btn-tickets">
              <span *ngIf="permissionsMap['booking.itinerarios.reciboproducto']">
                <i class="i-recibo mr-2 btn-recibo"  *ngIf="item.isPendigApprovePay===false && item.isPaidAgency===false && item.isPaidProvider===false && item.isBilled===false"></i>
              </span>
              <span *ngIf="permissionsMap['booking.itinerarios.voucher']">
                <i class="i-voucher mr-2  btn-voucher"  (click)="vouchersReservationPdf(item.id)" visible="item.status_id!=2 && item.status_id!=5" *ngIf="item.status != 'Quote'"></i>
              </span>
              <div class="separator" *ngIf="permissionsMap['booking.itinerarios.voucher']"></div>
                <div *ngIf="permissionsMap['booking.itinerarios.detalle']" class="btn-inf rounded-circle btn-detalle">
                  <img src="/assets/img/app/little-icons/clipboard-green.svg" class="img-fluid " alt="" style="width: 22px; height: 23px; margin-top: 2px; cursor: pointer;">
                </div>
              <div class="separator" *ngIf="permissionsMap['booking.itinerarios.elminarproducto'] || permissionsMap['booking.itinerarios.editarproducto'] || permissionsMap['booking.itinerarios.agregarproducto']"></div> <!-- *ngIf="item.status_id === 1 || item.status_id === 4 || item.status_id === 5"-->
              <span *ngIf="permissionsMap['booking.itinerarios.elminarproducto'] && item.status !== 'Reservado'">
                <i class="i-delete btn-eliminar"  (click)="removeItem()"></i>
              </span>
              <span *ngIf="item.status == 'Reservado'">
                <i class="i-cancelar btn-cancelar"  (click)="removeItem()"></i>
              </span>
              <span *ngIf="permissionsMap['booking.itinerarios.editarproducto']">
                <i class="i-edit btn-editar"  *ngIf="!item.isPaidAgency" (click)="edit()"></i>
              </span>
              <span *ngIf="permissionsMap['booking.itinerarios.agregarproducto']">
                <i class="i-add btn-agregar"  (click)="updateItem(item)"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="white" type="line-scale">
  <p style="color: white"> Actualizando Item {{item.title}}... </p>
</ngx-spinner>

