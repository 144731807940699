import { Injectable } from '@angular/core';

@Injectable()
export class FeatureCollection {
    type: string;
    features: Feature[];
}

export class Feature {
    type: string;
    properties: FeatureProperty;
    geometry: FeatureGeometry;
}

export class FeatureProperty {
    value: number;
    text: string;
}

export class FeatureGeometry {
    type: string;
    coordinates: number[];
}

let populations: Object = {
    
};

let markers: FeatureCollection = {
    type: "FeatureCollection",
    features: [
        {
            coordinates: [-74.0059728, 40.7127753],
            text: 'New York City 24%',
            value: 8406 ,
        },
        {
            coordinates: [-81.37923649999999, 28.5383355  ],
            text: 'Orlando 24%',
            value: 8281
        },
        {
            coordinates: [-80.19179020000001, 25.7616798   ],
            text: 'Miami 5%',
            value: 5399
        },
        {
            coordinates: [-96.79698789999998, 32.7766642  ],
            text: 'Dallas 64%',
            value: 12111
        },
        {
            coordinates: [-118.2436849, 34.0522342  ],
            text: 'Los Angeles 24%',
            value: 8922
        },
        {
            coordinates: [-115.13982959999998, 36.1699412  ],
            text: 'Las Vegas , Nevada 5%',
            value: 7181
        }
        
    ].map(function (data) {
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: data.coordinates
            },
            properties: {
                value: data.value,
                text: data.text
            }
        };
    })
};



@Injectable()
export class Service {
    getPopulations(): Object {
        return populations;
    }

    getMarkers(): FeatureCollection {
        return markers;
    }
}
