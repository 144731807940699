import 'rxjs/add/operator/finally';
import { Component, OnInit } from '@angular/core';
import { ResultsSearchService } from './results-search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalLoadingComponent } from '../shared/modal-loading/modal-loading.component';
import { MatDialog } from '@angular/material/dialog';
import { setting } from '../../../setting';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  quote: string;
  isLoading: boolean;
  _payload: any;
  _results: any;
  _origin: string;
  _close: boolean;
  modal: boolean;

	public domain: string;
	public getClientsSettingAppBooking: any;
	public loadSetting : boolean;


  constructor(
    private resultsSearch: ResultsSearchService,
    public spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
  ) {
		this.domain = setting.c_domain;
		this.loadSetting = false;
    this.getClientsSettingAppBooking =  JSON.parse((localStorage.getItem(setting.name)));
  }

  ngOnInit() {
    this.isLoading = true;
    this._close = false;
    this.modal = false;
    window.scrollTo(0, 0);
    this.clear();
  }

  clear(){
    if(localStorage.getItem("others")){
      // console.log("se limpio others");
      localStorage.removeItem("others");
    }

   /* if(localStorage.getItem("paxList")){
      localStorage.removeItem("paxList");
    }*/

    if(localStorage.getItem("origin")){
      localStorage.removeItem("origin");
    }

    if(localStorage.getItem("results")){
      localStorage.removeItem("results");
    }

    if(localStorage.getItem("payload")){
      localStorage.removeItem("payload");
    }


    if(localStorage.getItem("payloadModal")){
      localStorage.removeItem("payloadModal");
    }



    if(localStorage.getItem("form")){
      localStorage.removeItem("form");
    }

    //localStorage.clear();
  }

  _onPayload(payload: any) {
    console.log('Recibido payload: ', payload);
    this._payload = payload;
 //   localStorage.setItem('payload', JSON.stringify(payload));
    this.resultsSearch._setPayload(payload);
  }

  _onResults(results: any) {
  //  console.log('Recibido resultados: ', results);
    this._results = results;
 //   localStorage.setItem('results',JSON.stringify(results));
    this.resultsSearch._setResponse(results);
  }

  _onOrigin(origin: any) {
   // console.log('Recibido origen: ', origin);
    localStorage.setItem('origin',origin);
    this._origin = origin;
    this.resultsSearch._setOrigin(origin);
  }

  _onLoading(isLoading: boolean) {
   // console.log('Is Searching?: ' + isLoading);
   // console.log('Is this._payload?: ' + this._payload);
    this.isLoading = isLoading;
    // this.spinnerService.show();
    if  ((this.isLoading)) {
        this.openModalLoading(JSON.parse(localStorage.getItem('payload')));
    } else {
        this.dialog.closeAll();
    }
  }


 /// modal loading
  openModalLoading (data: any) {
    var dialogRef = this.dialog.open(ModalLoadingComponent, {
      width: '500px',
      id: "loadModal",
      data: data,
      disableClose: true
    });

    /* dialogRef.afterClosed().subscribe(result => {
    });*/
  }

  _onClick() {
    if (this.modal) {
      this._close = true;
      this.modal = false;
    }
    else {
      this.modal = true;
    }
  }
  openModal($event) {
  /*  if ($event === 'true') {
      this.modal = true;
      this._close = false;
    } else {
      this.modal = false;
      this._close = false;
    }*/
  }
}
