import { Component, Input, OnInit, ViewChild, OnDestroy, TemplateRef } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { RestService } from "../../shared/rest.service";
import { ItineraryService } from "../../shared/itinerary.service";
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { RangeDateItinerary } from "../interfaz/rangeDateItinerary";
import { FormGroup} from "@angular/forms";
import { Observable } from "rxjs/Observable";
import { ResultsSearchService } from "../../home/results-search.service";
import { DatePipe } from "@angular/common";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { MaterialModalConfirmComponent } from "../material-modal-confirm/material-modal-confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { setting } from "../../../../setting";
import { Router } from "@angular/router";
import { ApiService } from "../../itinerary/shared/api.service";
import { SortableComponent } from "ngx-bootstrap";


type TView = "MULTIDESTINATION" | "RESULT";

@Component({
  selector: "usb-itinerary-sidebar",
  templateUrl: "./itinerary-sidebar.component.html",
  styleUrls: ["./itinerary-sidebar.component.scss"],
  providers: [DatePipe],
})
export class ItinerarySidebarComponent implements OnInit, OnDestroy {
  @ViewChild("modalRooms") modalRooms: ModalDirective;

  @Input() modeView: TView;
  @Input() payload: any;
  @Input() results: any;
  @Input() origin?: any;
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() changeTabEmitter = new EventEmitter<any>();
  @Output() _itinerary = new EventEmitter<any>();
  itinerary$: Observable<any[]>;

  /* REORDER */
  @ViewChild(SortableComponent) sortableComponent: SortableComponent;
  loadingReording: boolean = false;
  _activeItinerary: any;
  _destinations: any[] = [];
  _arrivalDate: string;
  _payloadArray = [];
  disableButton: Boolean;
  /* /REORDER */
  itineraryTotal: any;
  itineraryItems: any;
  product: any;
  romTaken: boolean;
  activeItineraryForm: any = null;
  showProduct = false;
  subscription: Subscription;
  modalRef: BsModalRef;
config = {
  animated: true,
  keyboard: true,
  backdrop: 'static', // Esto asegura que el modal no se cierre al hacer clic fuera
  ignoreBackdropClick: true, // Ignora los clics en el fondo para evitar el cierre del modal
};
  id: any;
  buttonDisabled: Boolean = false;
  multidestination: any;
  itinerary: any;
  nuevoObjeto = [];
  nuevoObjetoAux = [];

  nuevoObjeto2 = [];
  nuevoObjetoAux2 = [];

  totalNetPriceBooking = 0;
  totalBlickoins = 0;
  private _form: FormGroup;

  public getClientsSettingAppBooking: any;

  cities: string[] = [];
  lastItemHotelGet: any;
  lastItemHotelService: any;
  adultsValue: number = 0;
  booleanGetItinerary:boolean = false;
  qtyChildReserve: any;
  appLoading: boolean = false;
  deleteItem: boolean;

  bsModalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private itineraryService: ItineraryService,
    private datePipe: DatePipe,
    private resultSearchService: ResultsSearchService,
    private _rest: RestService,
    public userService: UserService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.itineraryService.valueUltimateDate$.subscribe((res: any) => {
      this.lastItemHotelGet = res;
    });
    this.itineraryService.booleanModalActive$.subscribe((res:any) =>{
      this.booleanGetItinerary = res;
    })

  }



  ver(item) {
    // console.log("item ---> ", item);
  }

  orderItinerary(itinerary) {
    // console.log("seleccion de itinerary --->", itinerary);
// console.log(itinerary)
    if (itinerary !== undefined) {
      this.nuevoObjeto = itinerary.items.reduce(function (r, a) {
        r[a.destination.destination_info.cityName] = r[a.destination.destination_info.cityName] || [];
        r[a.destination.destination_info.cityName].push(a);
        return r;
      }, Object.create(null));

      console.log("array nuevoObjeto", this.nuevoObjeto);

      this.nuevoObjetoAux = Object.keys(this.nuevoObjeto).map((key) => ({
        city: key,
        items: this.groupItemsByDates(this.nuevoObjeto[key]),
      }));

      console.log('metodo orderItinerary', this.nuevoObjetoAux);
    }
  }

  groupItemsByDates(items: any[]): any[] {
    const groupedItems: any[] = [];

    items.forEach((item) => {
      const city = item.destination.destination_info.cityName;
      const arrivalDate = item.arrivalDate;
      const departureDate = item.departureDate;

      // Buscar la ciudad en el arreglo de groupedItems
      const cityIndex = groupedItems.findIndex((group) => group.city === city);

      if (cityIndex === -1) {
        // La ciudad no ha sido agregada todavía, crear un nuevo grupo para la ciudad
        const cityGroup = {
          city: city,
          items: [
            {
              arrivalDate: arrivalDate,
              departureDate: departureDate,
              items: [item],
            },
          ],
        };
        groupedItems.push(cityGroup);
      } else {
        // La ciudad ya existe en el arreglo de groupedItems, buscar si hay algún grupo con las mismas fechas de entrada y salida
        const dateGroupIndex = groupedItems[cityIndex].items.findIndex(
          (group) =>
            group.arrivalDate === arrivalDate && group.departureDate === departureDate
        );

        if (dateGroupIndex === -1) {
          // No hay grupo con las mismas fechas, crear un nuevo grupo dentro de la ciudad
          const dateGroup = {
            arrivalDate: arrivalDate,
            departureDate: departureDate,
            items: [item],
          };
          groupedItems[cityIndex].items.push(dateGroup);
        } else {
          // Hay un grupo con las mismas fechas, agregar el item al grupo existente
          groupedItems[cityIndex].items[dateGroupIndex].items.push(item);
        }
      }
    });

    // Asegurarse de que cada grupo de fechas tenga un array
    groupedItems.forEach((cityGroup) => {
      cityGroup.items.forEach((dateGroup) => {
        // console.log(dateGroup)
        dateGroup.items = Array.isArray(dateGroup.items) ? dateGroup.items : [dateGroup.items];
      });
    });
// console.log(groupedItems)
    return groupedItems;
  }

  getItinerary(id) {

      if (id && this.booleanGetItinerary) {
        this.apiService.doGet("itineraries/" + id).subscribe(
          (response: any) => {
            // console.log("getItinerary refrescando", response);
              this.itinerary = response;
              this.orderItinerary(this.itinerary);
              this.userService.items = response.items;
          },
          (error) => {
            console.log("Error: " + error);
          }
        );
      }
  }

  ngOnInit() {
    // this.getLastDataServ();


    this.loadComponent();
    this.userService.origin = this.origin;
    this.multidestination = this.userService.multidestination;
    if (this.userService.itineraryActive.length > 1){
      this.userService.fillCart(this.userService.itineraryActive);
      this.totalNetPriceBooking =  parseFloat(this.userService.itineraryActive[0].totalNetPriceBooking);
      this.totalBlickoins =  this.userService.itineraryActive[0].totalBlickoins;
    }

    this.itinerary$ = this.itineraryService.getItinerary$();

    //refrescar
    this.itinerary$.subscribe((data:any) => {
      if (data) {
        // console.log("data al refrescar", data);
        if(Object.keys(data).length > 0) {
          this.getItinerary(data[0].id);
        }
        if (data[0] !== undefined && data[0] !== null) {

          if  (this.itinerary == undefined) {
               this.itinerary = data[0];
          }

          this._activeItinerary = data;
          this.itineraryTotal = data[0].totalPrice;
          this._destinations = [];
          if (data[0].destination.length > 0) {
            for (let i = 0; i < data[0].destination.length; i++) {
              const arrival = data[0].destination[i].destination_info.arrival.split('-');
              const departure = data[0].destination[i].destination_info.departure.split('-');
              this._destinations.push({
                cityIataCode: data[0].destination[i].iata_code,
                cityName: data[0].destination[i].destination_info.cityName,
                nights: data[0].destination[i].destination_info.nights,
                adults: data[0].destination[i].destination_info.adults,
                childs: data[0].destination[i].destination_info.childs,
                rooms: data[0].destination[i].destination_info.rooms,
                roomsDist: data[0].destination[i].destination_info.roomDist,
                yy: this.datePipe.transform(data[0].destination[i].destination_info.arrival, 'yy'),
                mm: this._getMonthName(arrival[1]),
                dd: arrival[2],
                yyy: this.datePipe.transform(data[0].destination[i].destination_info.departure, 'yy'),
                mmm: this._getMonthName(departure[1]),
                ddd: departure[2]
              });
            }
          }else {
          }

        }

      }
    });

    if (this.origin == null || this.origin == undefined) {
      this.origin = this.resultSearchService._getOrigin();
    }
    this.itinerary = this.userService.itineraryActive[0];

    if (this.userService.itineraryActive.length > 0 ) {
      if (this.userService.itineraryActive[0].items.length  == 0){
        this.buttonDisabled = true;
      }
      else
        this.buttonDisabled = false;
    }

    if ( this.itinerary !== null ) {

      this.orderItinerary(this.itinerary);
    }
    // console.log("getItinerary sin refrescar ", this.itinerary);

    this.itineraryService.deleteItem.subscribe((data: any) => {
      this.deleteItem = data;
    })

  }


  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }



  /* REORDER */
  _reorderDestinations() {
    this.loadingReording = true;
    this._modifyDates();
    setTimeout(() => {
      this._emitDestinations(true);
    }, 500);

    setTimeout(() => {
      this.loadingReording = false;
    }, 2700);
  }

  _modifyDates() {
    this._setPayloadArray(this._destinations);
    if (this._activeItinerary !== undefined) {
      this._activeItinerary[0].arrivalDate = this._arrivalDate;
      this._activeItinerary[0].destination = [];
      for (let i = 0; i < this._destinations.length; i++) {
        const date = this._payloadArray[i].arrivalDate.split("-");
        this._destinations[i].yy = this.datePipe.transform(this._arrivalDate, "yy");
        this._destinations[i].mm = this._getMonthName(date[1]);
        this._destinations[i].dd = date[2];

        const destination = {
          iata_code: this._destinations[i]["cityIataCode"],
          destination_info: {
            childs: this._destinations[i]["childs"],
            adults: this._destinations[i]["adults"],
            rooms: this._destinations[i]["rooms"],
            roomDist: this._destinations[i]["roomsDist"],
            position: i + 1,
            nights: this._destinations[i].nights,
            arrival: this._payloadArray[i]["arrivalDate"],
            departure: this._payloadArray[i]["departureDate"],
            cityName: this._destinations[i]["cityName"],
          },
        };

        this._activeItinerary[0].destination.push(destination);
      }
    }
  }

  _setPayloadArray(destinations: any) {
    this._payloadArray = [];

    for (let i = 0; i < destinations.length; i++) {
      let arrival = null;
      if (this.payload !== undefined && this.payload !== null) {
        if (this.payload.others !== undefined && this.payload.others !== null) {
          var qtyAdults = 0;
          this.payload.others.hotels.roomConfiguration.forEach((room) => {
            qtyAdults += parseInt(room.adults);
          });
        }
      }
      if (this._arrivalDate !== undefined || this._arrivalDate !== null) {
        arrival = new Date(i === 0 ? this._arrivalDate : this._payloadArray[i - 1].departureDate);
        this._payloadArray[i] = {
          arrivalDate: i === 0 ? this._arrivalDate : this._payloadArray[i - 1].departureDate,
          categoriesId: [1],
          departureDate: this._getDeparture(arrival, destinations[i].nights),
          origin: this.payload === undefined ? "MULTIDESTINATION" : this.payload.origin,
          destination: destinations[i]["cityIataCode"],
          others: this.payload !== undefined ? this.payload.others : null,
          priceFrom: this.payload === undefined ? "MULTIDESTINATION" : this.payload.priceFrom,
          priceTo: this.payload === undefined ? 20000 : this.payload.priceTo,
          providersId: this.payload === undefined ? null : this.payload.providersId,
          qtyPax: qtyAdults,
          qtyProduct: this.payload !== undefined ? this.payload.others.hotels.roomConfiguration.length : null,
        };
      } else {
        //this.toastr.error('Arrival date must defined in home.', 'Unknown Arrival Date.');
      }
    }
  }

  _getDeparture(arrivalDate: any, nights: number) {
    arrivalDate.setDate(arrivalDate.getDate() + (nights + 1));
    const month = arrivalDate.getMonth() < 9 ? "0" + (arrivalDate.getMonth() + 1) : arrivalDate.getMonth() + 1;
    const day = arrivalDate.getDate() < 10 ? "0" + arrivalDate.getDate() : arrivalDate.getDate();
    return arrivalDate.getFullYear() + "-" + month + "-" + day;
  }

  _emitDestinations(update: boolean) {
    if (this._destinations.length > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
    this.itineraryService.emitActiveItinerary(this._activeItinerary, update);
  }
  _getMonthName(month: number) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Octr", "Nov", "Dec"];
    if (month !== null) {
      return monthNames[month - 1];
    }
  }
  /* /REORDER */

  gettotalNetPriceBooking() {
    if (this.userService.itineraryActive.length > 0) {
      this.totalNetPriceBooking = this.userService.itineraryActive[0].totalNetPriceBooking;
    } else {
      this.totalNetPriceBooking = 0;
    }
    return this.totalNetPriceBooking;
  }

  getTotalBlickoins() {
    if (this.userService.itineraryActive.length > 0) {
      this.totalBlickoins = this.userService.itineraryActive[0].totalBlickoins;
    } else {
      this.totalBlickoins = 0;
    }
    return this.totalBlickoins;
  }

  loadComponent() {
    this._emitLoading(true);
    this.itinerary$ = this.itineraryService.getItinerary$();
    this.itinerary$.subscribe((itinerary) => {
      if (itinerary !== null && itinerary !== undefined && Object.keys(itinerary).length > 0) {
        this.totalNetPriceBooking = parseFloat(itinerary[0].totalNetPriceBooking);
        this.totalBlickoins = itinerary[0].totalBlickoins;
        if (this.origin !== undefined && this.origin === "MULTIDESTINATION") {
          this.fillMultidestination(itinerary);
        } else {
          this.fillItinerary(itinerary);
        }
        this._emitLoading(false);

        if (itinerary[0].items.length == 0) this.buttonDisabled = true;
        else this.buttonDisabled = false;
      }
    });



  }

  funcion(itinerary) {
    if (itinerary !== null && itinerary !== undefined) {
      if (this.origin !== undefined && this.origin === "MULTIDESTINATION") {
        this.fillMultidestination(itinerary);
      } else {
        this.fillItinerary(itinerary);
      }
      this._emitLoading(false);
    }
  }

  _emitLoading(isLoading: boolean) {
    this.isLoading.emit(isLoading);
  }

  _emitItinerary(itinerary: any) {
    this._itinerary.emit(itinerary);
  }


  fillMultidestination(itinerary: any) {
    this.multidestination = undefined;
    this._emitItinerary(itinerary);
    let departureDate;
    let arrivalDate;

    if (itinerary[0] !== undefined) {
      for (let i = 0; i < itinerary[0].destination.length; i++) {
        let items: any[] = [];
        const destiny = itinerary[0].destination[i];

        if (destiny.destination_info.arrival !== null && destiny.destination_info.departure !== null) {
          arrivalDate = destiny.destination_info.arrival.split("-");
          departureDate = destiny.destination_info.departure.split("-");
        } else {
          const dateRange: RangeDateItinerary = this.itineraryService.evaluateDateItineraryRange(itinerary[0].items);
          arrivalDate = dateRange.arrivalDate.toString().split("-");
          departureDate = dateRange.departureDate.toString().split("-");
        }

        //cantidad de items
        for (let j = 0; j < itinerary[0].items.length; j++) {
          const item = itinerary[0].items[j];

          //payload se le asigna el producto si existe
          let payload = item.payloadUpdate && item.payloadUpdate.products ? item.payloadUpdate.products[0] : item.payload;
          if (payload && payload.destination === destiny.iata_code) {
            //console.log('entro a payload && payload.destination === destiny.iata_code');
            //se añade item a items array
            items[j] = {
              adult: payload.qtyPax,
              children: payload.others.child,
              item: item,
              product: null,
              categoryName: item.categoryName,
            };
            //si tiene payload el item hace una busqueda de product
            if (item.payloadUpdate) {
              this._rest.doPost("app", "search/products", item.payloadUpdate).subscribe(
                (response: any) => {
                  if (response && response.status && response.results) {
                    if (response.results[0] !== undefined) {
                      items[j].product = response.results[0];
                    } else {
                      items[j].product = response.results;
                    }
                  }
                },
                (error) => {
                  console.log(error.message);
                }
              );
            }


            if (this.multidestination === undefined) {
              this.multidestination = [];
            }

            let multiExist;

            if (this.multidestination.lenght > 0) {
              //busca si existe ya un destino que le pertenece a ese producto
              multiExist = this.multidestination.find((element: any) => element.city == destiny.destination_info.cityName);
              //console.log('encontro que ya existe un destino en multi con ese nombre?', multiExist);
            }

            if (multiExist) {
              let index = this.multidestination.indexOf(multiExist);
              this.multidestination[index].items.push(items[j]);
            } else {
              const anioArrival = arrivalDate[0].substring(2);
              const anioDeparture = departureDate[0].substring(2);

              this.multidestination[i] = {
                dd: arrivalDate[2].length > 2 ? arrivalDate[2].substring(0, 2) : arrivalDate[2],
                ddd: departureDate[2].length > 2 ? departureDate[2].substring(0, 2) : departureDate[2],
                mm: this.getMonthName(arrivalDate[1]),
                yy: anioArrival,
                city: destiny.destination_info.cityName,
                items: items.filter(Boolean),
              };
            }

            //si 1+1=2 es igual al ultimo  de items recibidos

            /*if (j + 1 === itinerary[0].items.length) {
              if (this.multidestination === undefined) {
                this.multidestination = [];
              }
              const anioArrival = arrivalDate[0].substring(2);
              const anioDeparture = departureDate[0].substring(2);

              this.multidestination[i] = {
                dd: arrivalDate[2].length > 2 ? arrivalDate[2].substring(0, 2) : arrivalDate[2],
                mm: this.getMonthName(arrivalDate[1]),
                yy: anioArrival,
                city: destiny.destination_info.cityName,
                items: items.filter(Boolean)
              };
            }*/
          }


        }

        if (itinerary[0].items.length < 1) {
          this.multidestination = undefined;
        }
      }
    }
  }

  fillItinerary(itinerary: any) {
    let departureDate;
    let arrivalDate;

    if (itinerary.length > 0 && this.itinerary != undefined) {
      //  this.itinerary = [];

      for (let i = 0; i < itinerary[0].items.length; i++) {
        let arrival;
        arrivalDate =
          itinerary[0].items[i].categoryName == "DISNEY"
            ? itinerary[0].items[i].hotel.dateIni.split("-")
            : itinerary[0].items[i].arrivalDate.split("-");
        departureDate =
          itinerary[0].items[i].categoryName == "DISNEY"
            ? itinerary[0].items[i].hotel.dateEnd.split("-")
            : itinerary[0].items[i].departureDate.split("-");
        arrival = moment(itinerary[0].items[i].arrivalDate);

        const item = itinerary[0].items[i];

        const anioArrival = arrivalDate !== null ? arrivalDate[0].substring(2) : null;

        const anioDeparture = departureDate !== null ? departureDate[0].substring(2) : null;

        this.itinerary[i] =
          item["categoryName"] === "DISNEY"
            ? this.getDisneyItem(item)
            : {
                orderDateDestiny:
                  this.datePipe.transform(arrival, "yy") +
                  "-" +
                  this.datePipe.transform(arrival, "MM") +
                  "-" +
                  this.datePipe.transform(arrival, "dd"),
                dd: arrivalDate[2].length > 2 ? arrivalDate[2].substring(0, 2) : arrivalDate[2],
                mm: this.getMonthName(arrivalDate[1]),
                yy: anioArrival,
                city: item.payloadUpdate ? item.payloadUpdate.products[0].destination : item.payload.destination,
                adult: item.payloadUpdate ? item.payloadUpdate.products[0].qtyPax : null,
                children: item.payloadUpdate ? item.payloadUpdate.products[0].others.child : null,
                item: item,
                ddd: departureDate[2].length > 2 ? departureDate[2].substring(0, 2) : departureDate[2],
                mmm: this.getMonthName(departureDate[1]),
                yyy: anioDeparture,
                product: null,
                categoryName: item.categoryName,
              };
      }
      this.id = itinerary.id;

      this.itineraryItems = itinerary[0].items;
    } else {
      this.itinerary = [];
    }
  }

  getDisneyItem(_item: any): object {
    const arrival: any = moment(_item["form"]["arrivalDate"]);
    const departure: any = moment(_item["form"]["departureDate"]);
    const nights: any = departure.diff(arrival, "days");
    const item = {
      orderDateDestiny:
        this.datePipe.transform(arrival, "yy") +
        "-" +
        this.datePipe.transform(arrival, "MM") +
        "-" +
        this.datePipe.transform(arrival, "dd"),
      itemInfo: _item,
      city: "ORL",
      id: _item["id"],
      idProduct: _item["hotel"]["id"],
      dd: this.datePipe.transform(arrival, "dd"),
      mm: this.datePipe.transform(arrival, "MMM"),
      yy: this.datePipe.transform(arrival, "yy"),
      ddd: this.datePipe.transform(departure, "dd"),
      mmm: this.datePipe.transform(departure, "MMM"),
      yyy: this.datePipe.transform(departure, "yy"),
      destination: _item["hotel"]["destination"],
      nights: nights,
      categoryName: "DISNEY",
    };
    // console.log('Le item', item);
    return item;
  }

  removeItem(item?: any, index?: number, i?: number) {
    const initialState: ModalOptions = {
      initialState: {
        message: 'Esta seguro de eliminar el producto?'
      }
    };
      // Combina el estado inicial con la configuración del modal
    const modalConfig:any = {
      ...initialState,
      ...this.config,
    };

    // Pasa modalConfig al servicio para mostrar el modal
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, modalConfig);
    this.deleteItem = false;
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
console.log(result)
      if(result) {
        console.log(this.itineraryService.activeItinerary[0])
        if (index !== -1) {
          console.log('ingresa acá')
          this.toastr.success('File sin moneda asignada');
          this.itineraryService.currencyRemoveEmit.next(item.currency);
          this.itineraryService.remove(item.item !== undefined ? item.item : item);
          // this.router.navigate(["results"]);
        }
      } else {
        this.deleteItem = true;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  confirm(): void {
    this.itineraryService.createItineraryNew();
    this.modalRef.hide();
  }

  decline(): void {
    this.itineraryService.createItineraryNew(true);
    this.modalRef.hide();
  }

  saveTitleItinerary(title: any) {
    this.itineraryService.save("save", false, title);
  }

  openModalWithClass(modal: any) {
    modal.show();
  }

  _updateItem(item: any, event: Event, template: TemplateRef<any>) {
    if (item !== null && item.value !== null && item.value.item !== null) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this._emitLoading(true);

      this._rest.doPost("app", "search/products", item.value.item.payloadUpdate).subscribe(
        (response: any) => {
          if (response && response.status && response.results) {
            this.showProduct = true;
            this.openModal(template);
            this._emitLoading(false);
            return;
          }
        },
        (error) => {
          this._emitLoading(false);
          console.log(error.message);
        }
      );
    }
  }

  getMonthName(month: number) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Octr", "Nov", "Dec"];
    if (month !== null) {
      return monthNames[month - 1];
    }
  }

  isEditable(categoryName: any) {
    if (categoryName != null && (categoryName === "RENT-A-CAR" || categoryName === "TRANSPORTS")) {
      return false;
    }
    return true;
  }

  changeTab() {
    this.changeTabEmitter.emit(true);
  }

  stingUpperCase(str) {
    let e = str.toLowerCase();
    return e.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  public isLastIndex(index: number, array: any[]): boolean {
    return index === array.length - 1;
  }

  getLastDataServ() {
    let resDataService = [];
    let lastData: any;

    //console.log('itineraryItems ->', this.itineraryItems);

    this.itinerary$ = this.itineraryService.getItinerary$();

    this.itinerary$.subscribe((res: any) => {
      resDataService = res[0].items;
      lastData = resDataService[resDataService.length - 1];
      this.itineraryService.valueUltimateDate$.next(lastData);
    });
  }

  buttonDeleteItem(item: any): boolean {
      console.log(item);
    return false;
  }


}
