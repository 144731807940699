import { HttpClient , HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import { setting } from '../../setting';

@Injectable()

export class AppService {
	public baseUrl: string;
	public apiBookingUrl: string;
	constructor(private _http: HttpClient) {
		this.baseUrl = setting.apiBTIUrl;
		this.apiBookingUrl = setting.apiBookingUrl	
	}
	getClientsSettingAppBooking() {
		let params: HttpParams = new HttpParams();	
		params = params.append('name', setting.name);
		return this._http.get(this.baseUrl + 'getClientsSettingAppBooking', { params: params });
	}
}