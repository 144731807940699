import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService } from "ngx-bootstrap";


export interface FormPaxesReservationData {

  getClientsSettingAppBooking: any;

  dpConfig: Partial<BsDatepickerConfig>,
  minDateAdult: Date;
  maxDateChild: Date;

  resultReserve: any[];
  // clientes & clientes asociados
  clientArr: any[];
  clientsAssociated: any[];
  numberDocumentClient: any[];
  clientsAssociatedSelected: boolean;
  clientIdArr: any[];

    // utilidades
  countries: any[];
  states: any[];
  cities: any[];
  relationShips: any[];
  relationChild: any[];
  adultsPax: any[];

  paxList: any[];

  reserving: boolean;

  formInvalid: boolean;

    // complete child whit parent
  email_parent: string;
  address_parent: string;
  city_parent: string;
  type_document_parent: string;
  country_parent: string;
  state_parent: string;
  phone_code_parent: string;

  relationShip: any;
  phone_code: any;
  phone_code_2: any;

  // validate number document
  documentErrorDuplicate: boolean;
  documentClientExists: boolean;
  documentNumberPaxlistArrAux: any[];
  typeDocumentSelect: number;
  indexPaxTypeDocumentSelect: number;

  //asociated client
  booleanHeadline: boolean;

  booleanSelectCountries: boolean;
  booleanSelectState: boolean;

  generalForm: any,

  contadorBirthDate: number;

}

export const formPaxesReservationData: FormPaxesReservationData = {

  getClientsSettingAppBooking: {},

  dpConfig: new BsDatepickerConfig(),
  minDateAdult: new Date,
  maxDateChild: new Date,

  resultReserve: [],
    // clientes & clientes asociados
  clientArr: [],
  clientsAssociated: [],
  numberDocumentClient: [],
  clientsAssociatedSelected: false,
  clientIdArr: [],

  // utilidades
  countries: [],
  states: [],
  cities: [],
  relationShips: [],
  relationChild: [],
  adultsPax: [],

  paxList: [],

  //generate payload reserve
  reserving: false,

  formInvalid: false,

  // complete child whit parent
  email_parent: null,
  address_parent: null,
  city_parent: null,
  type_document_parent: null,
  country_parent: null,
  state_parent: null,
  phone_code_parent: null,

  relationShip: null,
  phone_code: null,
  phone_code_2: null,

  // validate number document
  documentErrorDuplicate: false,
  documentClientExists: false,
  documentNumberPaxlistArrAux: [],
  typeDocumentSelect: null,
  indexPaxTypeDocumentSelect: null,

  //asociated client
  booleanHeadline: false,

  booleanSelectCountries: false,
  booleanSelectState: false,

  generalForm: null,

  contadorBirthDate: 0,


}

export interface PaxEmpty {
  clientId: any,
  address: any,
  birth_date: any,
  city: any,
  clientSelected: any,
  countryId: any,
  document_number: any,
  document_type_number: any,
  document_type_id: any,
  email: any,
  firstname: any,
  idUnique: any,
  last_name: any,
  phone: any,
  phone_2: any,
  phone_2_code: any,
  phone_2_code_selected: any,
  phone_code: any,
  phone_code_selected: any,
  postal_code: any,
  relation_ship: any,
  relation_ship_catalogue_id: any,
  state_id: any,
  title:any,
}


export const paxEmpty: PaxEmpty = {
  clientId: null,
  address: null,
  birth_date: null,
  city: null,
  clientSelected: null,
  countryId: null,
  document_number: null,
  document_type_number: null,
  document_type_id: null,
  email: null,
  firstname: null,
  idUnique: null,
  last_name: null,
  phone: null,
  phone_2: null,
  phone_2_code: null,
  phone_2_code_selected: null,
  phone_code: null,
  phone_code_selected: null,
  postal_code: null,
  relation_ship: null,
  relation_ship_catalogue_id: null,
  state_id: null,
  title: null
}

export interface Titles {
  id: string;
  name: string;
}


export const titles: Titles[] =[

    { id: "Mr.", name: "Mr." },
    { id: "Mrs.", name: "Mrs." },
    { id: "Ms.", name: "Ms." },

]

export interface RoutesAPI {
  app: string,
  api: string,
  countries: string;
  states: string;
  cities: string;
  updatePaxlist: string;
  reservation: string;
  reservation_confirmation: string;
  relation_ship_catalogue: string;
}

export const routes: RoutesAPI = {
  app: "app",
  api: "api",
  countries: '/utilities/countries',
  states: '/utilities/destinations/',
  cities: '/utilities/cities/',
  updatePaxlist: 'itineraries/update/paxlist/',
  relation_ship_catalogue: 'relationShips',
  reservation: '/reservation',
  reservation_confirmation: '/reservation-confirmation',
}
