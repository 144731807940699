
 // MISCELANEOS

import { LabelType, Options } from "@angular-slider/ngx-slider";

 export const SliderShow: Options = {
  floor: Math.floor(0), // inicio precio mas bajo
  ceil: Math.floor(0), // fin  precio mas bajo
  //  step: 5,
  translate: (value: number, label: LabelType): string => {
    switch (label) {
      case LabelType.Low:
        return "<b>Min:</b> $ " + Math.floor(value);
      case LabelType.High:
        return "<b>Max:</b> $ " + Math.floor(value);
      default:
        return "$" + value;
    }
  },
};


 // HOTEL

 export const AmenitiesFilter = [
  {value: '', name: 'Accesible por ascensor'},
  {value: '', name: 'Acceso a pisos superiores por ascensor'},
  {value: '', name: 'Acceso a pisos superiores solo por escaleras'},
  {value: '', name: 'Adaptador'},
  {value: '', name: 'Aeróbicos'},
  {value: '', name: 'Alfombrado'},
  {value: '', name: 'Almohada de plumas'},
  {value: '', name: 'Almohada sin plumas'},
  {value: '', name: 'Bañera de hidromasaje'},
  {value: '', name: 'Baño adaptado'},
  {value: '', name: 'Billar'},
  {value: '', name: 'Bingo'},
  {value: '', name: 'Bolos'},
  {value: '', name: 'Botes de basura'},
  {value: '', name: 'Buceo'},
  {value: '', name: 'Caja de depósitos de seguros'},
  {value: '', name: 'Calabaza'},
  {value: '', name: 'Calefacción'},
  {value: '', name: 'Cama plegable'},
  {value: '', name: 'Camas extralargas (5.20 mts.)'},
  {value: '', name: 'Caminar en la ducha'},
  {value: '', name: 'Campo de golf'},
  {value: '', name: 'Canales por cable'},
  {value: '', name: 'Canotaje'},
  {value: '', name: 'Chimenea'},
  {value: '', name: 'Ciclismo'},
  {value: '', name: 'Clase de cocina'},
  {value: '', name: 'Comedia en vivo'},
  {value: '', name: 'Computadora'},
  {value: '', name: 'Consola de juego - PS2'},
  {value: '', name: 'Consola de juego - PS3'},
  {value: '', name: 'Consola de juego - PS4'},
  {value: '', name: 'Consola de juego - Xbox 360'},
  {value: '', name: 'Cubierta de piscina'},
  {value: '', name: 'Dardos'},
  {value: '', name: 'El area de espera'},
  {value: '', name: 'Enchufe cerca de la cama'},
  {value: '', name: 'Entrada privada'},
  {value: '', name: 'Equipo de bádminton'},
  {value: '', name: 'Equipo de tenis'},
  {value: '', name: 'Equitación'},
  {value: '', name: 'Escritorio'},
  {value: '', name: 'Eventos deportivos en vivo (emisión)'},
  {value: '', name: 'Excursiones en bicicleta'},
  {value: '', name: 'Fregadero bajo'},
  {value: '', name: 'Galerías de arte temporales'},
  {value: '', name: 'Guardarropa o armario'},
  {value: '', name: 'Habitaciones comunicadas disponibles'},
  {value: '', name: 'Hierro'},
  {value: '', name: 'Hipoalergénica'},
  {value: '', name: 'Hora feliz'},
  {value: '', name: 'Inodoro con pasamanos'},
  {value: '', name: 'Inodoro elevado'},
  {value: '', name: 'Insonorización'},
  // {value: '', name: 'Instalaciones para deportes acuáticos'},
  {value: '', name: 'IPad'},
  {value: '', name: 'Mantas eléctricas'},
  {value: '', name: 'Mini golf'},
  {value: '', name: 'Mosquitero'},
  {value: '', name: 'Música en vivo/actuación'},
  {value: '', name: 'Noches de cenas temáticas'},
  {value: '', name: 'Noches de cine'},
  {value: '', name: 'Parque acuatico'},
  {value: '', name: 'Parte menos profunda'},
  {value: '', name: 'Pescar'},
  {value: '', name: 'Pijama'},
  {value: '', name: 'Piscina con vista'},
  {value: '', name: 'Piscina de agua salada'},
  {value: '', name: 'Piscina de inmersión'},
  {value: '', name: 'Piscina en el techo'},
  {value: '', name: 'Piscina infinita'},
  {value: '', name: 'Piscina temperada'},
  {value: '', name: 'Pisos de madera o parquet'},
  {value: '', name: 'Pista de tenis'},
  {value: '', name: 'Playa'},
  {value: '', name: 'Portatil'},
  {value: '', name: 'Prensa de pantalones'},
  {value: '', name: 'Productos de limpieza'},
  {value: '', name: 'Reproductor de CD'},
  {value: '', name: 'Reproductor de DVD'},
  {value: '', name: 'Rollo en la ducha'},
  {value: '', name: 'Rutas de bares'},
  {value: '', name: 'Secadora'},
  {value: '', name: 'Senderismo'},
  {value: '', name: 'Servicio de planchado'},
  {value: '', name: 'Servicios en la habitación cunas'},
  {value: '', name: 'Silla de ducha'},
  {value: '', name: 'Snorkel'},
  {value: '', name: 'Sofá'},
  {value: '', name: 'Sofa cama'},
  {value: '', name: 'Suelo de baldosas de mármol'},
  {value: '', name: 'Tarjeta de emergencia en baño'},
  {value: '', name: 'Tendedero'},
  {value: '', name: 'Tenis de mesa'},
  {value: '', name: 'Tiro al arco'},
  {value: '', name: 'Toallas de piscina'},
  {value: '', name: 'Toda la unidad accesible en silla de ruedas'},
  {value: '', name: 'Toda la unidad ubicada en la planta baja'},
  {value: '', name: 'Tour o clase sobre la cultura local'},
  {value: '', name: 'Tours de caminata'},
  {value: '', name: 'Ventilador'},
  {value: '', name: 'Vestidor'},
  {value: '', name: 'Windsurf'},
  {value: '', name: 'Yukatas'}
 ]

 export const FiltersHotels = {
  popular: {
    breakfast: [0, "CONTINENTAL BREAKFAST", "-1", "boardName", false],
    breakfastNo: [0, "ROOM ONLY", "-1", "boardName", false],
    nr: [0, "NOR", "-1", "rateClass", false],
    wifi: false,
    others: false,
  },
  stars: [false, false, false, false],
  services: {
    breakfast: false,
    bulter: false,
    phoneFree: false,
    others: false,
  },
  zona: {
    beach: false,
    city: false,
    others: false,
  },
};


 // RENT-A-CAR
 export const FiltersRentacar = {
  category: {
    categoryArray: [],
    checkedArray: [],
    categoryCant: [],
    img: []
  },
  brand: {
    brandArray: [],
    checkedArray: [],
    brandCant: [],

  },
  type: {
    typeArray: [],
    checkedArray: [],
    typeCant: [],
  },
  lowPrice: 0,
  HighPrice: 0,

};
