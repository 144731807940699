import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'usb-hotel-room',
  templateUrl: './hotel-room.component.html',
  styleUrls: ['./hotel-room.component.scss']
})
export class HotelRoomComponent implements OnInit  {
  @Input() item: any;
  @Input() room: any;
  @Input() allRoom: any;
  @Input() select: any[];
  @Input() index: number;
  @Input() productQty: number;
  @Input() reserveMultiHab: boolean;
  
  @Output() calculateTotalPrice = new EventEmitter<any>();
  qtySelect = 0;

  roomsIndex = 0;

  @Input() images: any[];

  step = null
  imgSelect = 0;
  uniqBoardName = {};
  uniqRateClass = {};

  uniq_ = {};
  roomSelect: any;
  checkSelect: any;
  roomBoardName: any;
  roomRateClass: any;
  amenitiesHotelRooms = [];
  constructor() {
  }

  ngOnInit() {
    console.log("this.room ngOnInit", this.room);
    this.resetSelect();
    this.roomSelect =  this.roomBoardName[this.roomsIndex];
    console.log(" roomSelect_ this.roomSelect",  this.roomSelect);
   //  this.getAmenitiesHotelRooms();
  }

  getAmenitiesHotelRooms() {
    
   /* this.amenitiesHotelRooms = this.result.additionalInfo.amenities.filter(function (amenite: any) {
        return amenite.onlist == 1 && amenite.ame_type === 'hotel';
      })
      .map(function (amenite: any) {
        return amenite;
      });

    console.log("this.getAmenitiesHotelResul", this.ameninitiesResul); */
  }

  resetSelect () {
    this.roomBoardName = this.room;
    this.roomRateClass = this.room;
    this.uniqBoardName = {};
    this.uniqRateClass = {};
    console.log(" resetSelect roomBoardName", this.roomBoardName);
    // tslint:disable-next-line:max-line-length
    this.roomBoardName = this.roomBoardName.filter(obj => ! this.uniqBoardName[obj.boardName] && (this.uniqBoardName[obj.boardName] = true));
    // tslint:disable-next-line:max-line-length
    this.roomRateClass = this.roomRateClass.filter(obj => ! this.uniqRateClass[obj.rateClass] && (this.uniqRateClass[obj.rateClass] = true));
    console.log("this.roomBoardName",     this.roomBoardName);
  }

  onChangeBoardName(roomsIndex: any, select: any, index: any) {
     const boardName   =  select.target.options[select.target.options.selectedIndex].text;
     let roomsSearch =   this.room;
     const roomsSearchAux  = [];
     const roomsSearchAux_  = [];
     let i = 0;
     const uniq = {};
     if (roomsIndex == "-1") {
         this.resetSelect();
          this.roomSelect =  this.roomBoardName[0];
        } else {
          this.roomSelect =  this.roomBoardName[roomsIndex];
          // asignar valores a la condicion
          roomsSearch = roomsSearch.filter(function (entry: any) {
            return entry.boardName === boardName;
         });
     //    roomsSearch = roomsSearch.filter(obj => obj.boardName = boardName);

         roomsSearch = roomsSearch.filter(function (obj: any) {
          return obj.boardName = boardName;
         });
         roomsSearch = roomsSearch.reduce(function(r, a) {
            r[a.rateClass] = r[a.rateClass] || [];
            r[a.rateClass].push(a);
            return r;
            }, Object.create(null));
            Object.keys(roomsSearch).map(key => {
              roomsSearchAux[i] = roomsSearch[key];
              i++;
            }
          );
          let j = 0;
         roomsSearchAux.forEach(element => {
            roomsSearchAux_[j] =   element.filter(obj => !uniq[obj.rateClass] && (uniq[obj.rateClass] = true))[0];
            j++;
          });
         this.roomRateClass = roomsSearchAux_;
      }
      let j;
      j = 0;
      this.allRoom.forEach(element => {
        element.forEach(element_ => {
          if ((document.getElementById(element_.roomId) as HTMLInputElement)) {
              (document.getElementById(element_.roomId) as HTMLInputElement).checked = false;
              (document.getElementById(element_.roomId) as HTMLInputElement).disabled = false;
            }
        });
        if (document.getElementById(j) as HTMLInputElement) {
          (document.getElementById(j) as HTMLInputElement).style.border =  "1px solid #00000020";
        }
     j++;
    });
      this.calculateTotalPrice.emit(0);
  }

  onChangeRateClass(roomsIndex: any, select: any, index: any) {
   /* const rateClass   =  select.target.options[select.target.options.selectedIndex].text;
    let roomsSearch =   this.room;
    const roomsSearchAux  = [];
    const roomsSearchAux_  = [];
    let i = 0;
    const uniq = {};
    if (roomsIndex == "-1") {
         this.resetSelect();
         this.roomSelect =  this.roomRateClass[0];
       } else {
         this.roomSelect =  this.roomRateClass[roomsIndex];
         // asignar valores a la condicion
         roomsSearch = roomsSearch.filter(function (entry: any) {
           return entry.rateClass === rateClass;
        });
          roomsSearch = roomsSearch.filter(function (obj: any) {
            return obj.rateClass = rateClass;
        });
          
        roomsSearch = roomsSearch.reduce(function(r, a) {
           r[a.rateClass] = r[a.rateClass] || [];
           r[a.rateClass].push(a);
           return r;
           }, Object.create(null));
           Object.keys(roomsSearch).map(key => {
             roomsSearchAux[i] = roomsSearch[key];
             i++;
           }
         );
         let j = 0;
        roomsSearchAux.forEach(element => {
           roomsSearchAux_[j] =  element.filter(obj => !uniq[obj.boardName] && (uniq[obj.boardName] = true))[0];
           j++;
         });
        this.roomBoardName = roomsSearchAux_; 
     }
     let j;
     j = 0;
     this.allRoom.forEach(element => {
       element.forEach(element_ => {
         if ((document.getElementById(element_.roomId) as HTMLInputElement)) {
             (document.getElementById(element_.roomId) as HTMLInputElement).checked = false;
             (document.getElementById(element_.roomId) as HTMLInputElement).disabled = false;
           }
       });
       if (document.getElementById(j) as HTMLInputElement) {
         (document.getElementById(j) as HTMLInputElement).style.border =  "1px solid #00000020";
       }
    j++;
   });*/
     this.calculateTotalPrice.emit(0);
  }

  setStep(index: number) {
    this.step = index;
  }
  /**
   * La funcion se encarga de evaluar la cantidad que se pide.
   * Se le manda 1 para que los demas select aparezcan con 1 elemento que sera el 0.
   * @param select Recibe el elemento colocado por el usuario al seleccionar la cantidad.
   */
   selectRoom (room: any, index: any) {
    const checkCurrect = (document.getElementById(room.roomId) as HTMLInputElement).checked;
    let checkAll = false;
      if ( checkCurrect ===  true) {
        checkAll = true;
        // tslint:disable-next-line:max-line-length
        (document.getElementById(index) as HTMLInputElement).style.border =  "3px solid #00d096";
        this.calculateTotalPrice.emit(room);
      } else {
        this.calculateTotalPrice.emit(0);
        (document.getElementById(room.roomId) as HTMLInputElement).disabled = false;
        checkAll = false;
        (document.getElementById(index) as HTMLInputElement).style.border =  "1px solid #00000020";
      }
      this.allRoom.forEach(element => {
        // tslint:disable-next-line:no-unused-expression
        element.forEach(element_ => {
        if (element_.roomId != room.roomId) {
              if ((document.getElementById(element_.roomId) as HTMLInputElement)) {
                // tslint:disable-next-line:max-line-length
                (document.getElementById(element_.roomId) as HTMLInputElement).disabled = checkAll;
            }
        }
        });
      });
      console.log ("(room.roomId", room.roomId);
  }

  private addRoomToSelect(select: any) {

    this.select = select;
    /*const productQtyNowSelect = this.productQtyNowSelect(this.select);
    const productQtyLeft = this.productQty - productQtyNowSelect;
    this.select.forEach((obj, index) => {
      obj.selectArray = Array.from(Array(+obj.selectValue + productQtyLeft + 1).keys());
    }); */
  //  this.calculateTotalPrice.emit();
  }

  private productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach(obj => total += +obj.selectValue);
    return total;
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  prev(){
    if(this.imgSelect>0){
      this.imgSelect=this.imgSelect-1
    }
    if(this.imgSelect<=5){
      this.scroll('l')
    }
  }

  next(){
    if(this.imgSelect<(this.images.length-1)){
      this.imgSelect++
    }
    if(this.imgSelect>=5){
      this.scroll('r')
    }
  }
  scroll(dir){
    let idc = 'slide-miniatura'
    let content = document.getElementById(idc)
    const stepr = 150
    const stepl = -150
    if(dir=='l'){
      content.scrollLeft += stepl
    }else if(dir=='r'){
      content.scrollLeft += stepr
    }
  }
}
