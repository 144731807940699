<header class="justify-items-around fixed-top-header">
  <!-- style="z-index: 10;fixed-top" -->
  <div id="section-top-header" class="row align-items-center" style="padding: 0px 72px;">
    <div class="d-flex justify-content-start align-items-center text-truncate align-self-center col-lg-7 col-md-12 col-12 px-0"
      style="height: 31px !important; right: 50px;">
      <ul class="nav justify-content-xg-end justify-content-lg-end">
        <li class="nav-item mr-3">
          <i class="bi bi-whatsapp text-white"></i>
          <a href="https://wa.me/{{wsp_number}}" target="_blank" rel="noopener noreferrer" class="font-phone">&nbsp;{{ getClientsSettingAppBooking?.wsp_phone_number }}</a>
        </li>
        <li class="nav-item">
          <i class="bi bi-telephone-fill text-white"></i>
          <a href="#" class="font-phone">&nbsp;{{ getClientsSettingAppBooking?.phone_number_one }}</a>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-end text-truncate align-self-center col-lg-5 col-md-12 col-12 px-0" style="height: 31px !important;">
      <ul class="nav justify-content-end" >
        <li class="nav-item d-flex align-items-center pr-3">
          <a href="#" class="lang active-lang" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">EN</a>
        </li>
        <li class="nav-item d-flex align-items-center pr-3">
          <a href="#" class="lang">ES</a>
        </li>
        <li class="nav-item d-flex align-items-center pr-3 mr-5">
          <a href="#" class="lang">PT</a>
        </li>
        <li class="nav-item help-container mr-3 align-self-center">
          <a href="https://help.fusionpax.com/" target="_blank" class="mx-2" title="help"><i class="icon-help"></i></a>
        </li>
        <li class="nav-item ml-3 align-items-center d-flex">
            <a href="https://wa.me/5454115031200" target="_blank" rel="noopener noreferrer">
              <!-- <i class="bi bi-whatsapp mb-0" style="font-size: 1.15em"></i> -->
              <img class="icons-rs" src="/assets/img/app/icons-redes/whatsapp.png" alt="">
            </a>
          </li>
           <li class="nav-item ml-3 align-items-center d-flex" *ngIf="getClientsSettingAppBooking?.clients_setting_social_networks[1]?.url != ''">
            <a [href]="getClientsSettingAppBooking?.clients_setting_social_networks[1]?.url" target="_blank" rel="noopener noreferrer">
              <!-- <span class="position-relative" style="display: inline-block;">
                <i class="bi bi-circle-fill" style="font-size: 1.15em;"></i>
                <i class="bi bi-instagram position-absolute" style="font-size: .75em; top: 50%; left: 50%; transform: translate(-50%, -50%); color: black;"></i>
              </span> -->
              <img class="icons-rs" src="/assets/img/app/icons-redes/instagram.png" alt="">
            </a>
          </li>
          <li class="nav-item ml-3 align-items-center d-flex" *ngIf="getClientsSettingAppBooking?.clients_setting_social_networks[3]?.url != ''">
            <a [href]="getClientsSettingAppBooking?.clients_setting_social_networks[3]?.url" target="_blank" rel="noopener noreferrer">
              <!-- <span class="position-relative" style="display: inline-block;">
                <i class="bi bi-circle" style="font-size: 1.15em; position: relative; z-index: 0;"></i>
                <i class="bi bi-linkedin" style="font-size: .75em; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1;"></i>
              </span> -->
              <img class="icons-rs" src="/assets/img/app/icons-redes/linkedin.png" alt="">
            </a>
          </li>
          <li class="nav-item ml-3 align-items-center d-flex" *ngIf="getClientsSettingAppBooking?.clients_setting_social_networks[0]?.url != ''">
            <a [href]="getClientsSettingAppBooking?.clients_setting_social_networks[0]?.url" target="_blank">
              <!-- <i class="bi bi-facebook mb-0" style="font-size: 1.15em"></i> -->
              <img class="icons-rs" src="/assets/img/app/icons-redes/facebook.png" alt="">
            </a>
          </li>
      </ul>
      <!-- <div class="d-flex justify-content-end text-truncate align-self-center col-lg-2 col-md-12 col-12 px-0" style="height: 31px !important; margin-left: 5px;">
        <ul class="nav justify-content-end" *ngFor="let item of arraySocials" >
          <li class="nav-item ml-3 align-items-center d-flex" *ngIf="item.url != null">
            <a [href]="item.url" target="_blank" rel="noopener noreferrer">
              <img class="icons-rs" [src]="item.imgSocial" alt="">
            </a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>

  <!-- https://wa.me/5454115031200 -->

  <div id="section-header" class="row header-top align-items-center py-0">
    <div class="d-flex justify-content-start text-truncate align-self-center col-lg-5 col-md-12 col-12 pl-0 pr-0" style="margin-top: -38px;">
      <div class="btn-group align-content-start" dropdown id="hamburger">
        <button dropdownToggle type="button" class="btna btn-sm" style="padding: 0px 16.5px !important;">
          <i class="i-menu-hamb"></i>
        </button>
        <div *dropdownMenu class="dropdown-menu" role="menu">
          <div class="dropdownmenu-card py-4">
            <ul style="list-style: none">
              <li role="menuitem">
                <button (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                  class="btn btn-outline-light btn-sm">
                  <i class="fa fa-times"></i>
                </button>
              </li>
              <li class="dropdown-separador"></li>
              <li role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="goHome()">Inicio</p></li>
              <li class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.cuenta.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('account')">Cuenta</p></li>
              <li *ngIf="permissionsMap['booking.cuenta.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.clientes.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('clients')">Clientes</p></li>
              <li *ngIf="permissionsMap['booking.clientes.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.blickoins.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('fidelity')">Blickoins</p></li>

              <li *ngIf="permissionsMap['booking.blickoins.habilitar']" class="divider dropdown-divider"></li>
              <!-- <li role="menuitem"><a class="dropdown-item" href="#credits">Creditos</a></li>
              <li class="divider dropdown-divider"></li> -->
              <!-- <li *ngIf="limitCreditAccess" role="menuitem"><a class="dropdown-item" href="#credits-limit">Límite de crédito</a></li>
              <li *ngIf="limitCreditAccess" class="divider dropdown-divider"></li> -->
              <li *ngIf="permissionsMap['booking.depositoengarantia.habilitar']" role="menuitem"><p class="mb-0 dropdown-item" style="cursor:pointer" (click)="mannagementRedirect('depositWarranty')">Garantías de depósito</p></li>
              <li *ngIf="permissionsMap['booking.depositoengarantia.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.transacciones.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('transactions')">Transacciones </p></li>
              <li *ngIf="permissionsMap['booking.transacciones.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.pagos.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('payments')">Pagos</p></li>
              <li *ngIf="permissionsMap['booking.pagos.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.condicionespago.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('agencyPaymentCondition')">Condiciones de pago</p></li>
              <li *ngIf="permissionsMap['booking.condicionespago.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.itinerarios.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('itineraries')">Files</p></li>
              <li *ngIf="permissionsMap['booking.itinerarios.habilitar']" class="divider dropdown-divider"></li>
              <li *ngIf="permissionsMap['booking.dashboard.habilitar']" role="menuitem"><p class="dropdown-item mb-0" style="cursor:pointer" (click)="mannagementRedirect('dashboard')">Dashboard</p></li>
              <li *ngIf="permissionsMap['booking.dashboard.habilitar']" class="divider dropdown-divider"></li>
            </ul>
          </div>
        </div>
      </div>
      <ul class="nav align-items-center" style="height: 64px !important;">
        <li class="nav-item separator-point items" style="margin-top: -25px;">

          <img src="../../../../assets/img/app/little-icons/badge-bks.png" alt="" style="margin-top: 10px; margin-left: 20px;">
          <!-- <i class="icon-point-agent-header menu-sesion font-menu nav-link text-truncate ml-2 mr-0" aria-hidden="true"></i> -->
        </li>
        <li class="nav-item items" *ngIf="permissionsMap['booking.blickoins.habilitar']">
          <p class="menu-sesion font-menu nav-link text-truncat point-agent font-lato"
            (click)="goTo('pointxagent')"
            style="margin-right: 13px; margin-top: -10px; cursor: pointer;">
            BKS {{ userService.balanceBlickoins? userService.balanceBlickoins : 0}}
          </p>
        </li>
        <li class="nav-item items" *ngIf="permissionsMap['booking.blickoins.habilitar'] === undefined">
          <p class="menu-sesion font-menu nav-link text-truncat point-agent font-lato"
            style="margin-right: 13px; margin-top: -10px;">
            BKS {{ userService.balanceBlickoins? userService.balanceBlickoins : 0}}
          </p>
        </li>
        <li class="nav-item items" *ngIf="permissionsMap['booking.blickoins.habilitar']">
          <p class="menu-sesion font-menu nav-link text-truncat point-agent font-lato"
            (click)="goTo('pointxagent')"
            style="margin-right: 13px; margin-top: -10px; cursor: pointer;">
            $ {{ userService.creditBlickoins | number : '1.2-2'}}
          </p>
        </li>
        <li class="nav-item items" *ngIf="permissionsMap['booking.blickoins.habilitar'] === undefined">
          <p class="menu-sesion font-menu nav-link text-truncat point-agent font-lato"
            style="margin-right: 13px; margin-top: -10px;">
            $ {{ userService.creditBlickoins | number : '1.2-2'}}
          </p>
        </li>
        <li class="nav-item separator-int items" style="margin-bottom: 15px;">

          <!-- <i class="icon-cash-money-header menu-sesion font-menu nav-link text-truncate ml-2 mr-0" aria-hidden="true"></i> -->
        </li>
        <li class="nav-item items">
          <svg-icon src="../../../../assets/img/app/header/DOLAR.svg"
            class="menu-sesion font-menu nav-link text-truncate"
            [ngStyle]="{'fill':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
            style="margin-top: -25px;">
        </svg-icon>
      </li>
      <li class="nav-item items">
        <p [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
              style="font-family: 'Lato', sans-serif;font-size: 14px; font-weight: 700; margin-right: 15px; margin-top: -10px;">
              $ {{ userService.creditLimits | number : '1.2-2' }} LC
          </p>
          <!-- <p *ngIf="limitCreditAccess" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}" (click)="goTo('creditLimits')"
              style="font-family: 'Lato', sans-serif;font-size: 14px; font-weight: 700; margin-right: 15px; margin-top: -10px; cursor: pointer;">
              $ {{ userService.creditLimits }} LC
          </p>
          <p *ngIf="!limitCreditAccess" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
              style="font-family: 'Lato', sans-serif;font-size: 14px; font-weight: 700; margin-right: 15px; margin-top: -10px;">
              $ {{ userService.creditLimits }} LC
          </p> -->
        </li>
        <li class="nav-item separator-int items" style="margin-top: -20px;">
          <span style="color: white !important" *ngIf="!isAgencyPaymentCondition" class="mt-3">
            <i class="fa fa-spinner fa-spin"></i>
          </span>
          <div style="width:27px; height:27px;margin-top: 2px;">
            <img src="{{apiRouteFile+iconPaymentCondition}}" *ngIf="iconPaymentCondition"
               style="width:auto !important; height:26px !important; margin-top: 3px !important; margin-left: 15px; border-radius: 50rem;"
              [ngStyle]="{'fill':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}" />
          </div>
        </li>
        <li class="nav-item items" *ngIf="namePaymentCondition && permissionsMap['booking.itinerarios.pagos']">
          <a href="#agency-payment-condition" class="menu-sesion font-menu nav-link payment-status font-lato ml-4" style="margin-top: -10px;">
            <p [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">{{namePaymentCondition}}</p></a>
        </li>
        <li class="nav-item items" *ngIf="namePaymentCondition && permissionsMap['booking.itinerarios.pagos'] === undefined">
          <a class="menu-sesion font-menu nav-link payment-status font-lato ml-4" style="margin-top: -10px;">
            <p [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">{{namePaymentCondition}}</p></a>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-center col-xg-4 col-lg-2 col-md-7 col-10  p-0">
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-2 col-1 px-0 justify-content-center" align="center"> <!--  src="assets/img/logo/logo.png"  United State Travel Solution -->
          <img  src="{{routeUploadFile+getClientsSettingAppBooking?.logo_path_two }}" alt="Logo Booking" class="d-block pt-2 w-100" style="cursor: pointer;" (click)="_home()">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end text-truncate align-self-center col-lg-5 col-md-12 col-12 pl-0" id="menu-right" style="margin-top: -38px;">
      <ul class="nav justify-content-end" style="height: 64px !important;">
        <li class="nav-item items" style="margin-right: 5px;">
          <a class="font-menu nav-link datos-header-right items-distance">{{ fechaa }}</a>
        </li>
        <li class="nav-item items" *ngIf="username" style="padding-top: 19px !important;">
          <i class="icon icon-separador" aria-hidden="true" style="font-size: 16px !important;"></i>
        </li>
        <li class="nav-item items items-distance" *ngIf="username">
          <a class="font-menu nav-link datos-header-right">{{ username }}</a>
        </li>
        <li class="nav-item items" *ngIf="agencyName" style="padding-top: 19px !important;">
          <i class="icon icon-separador" aria-hidden="true" style="font-size: 16px !important;"></i>
        </li>
        <li class="nav-item items items-distance" *ngIf="agencyName">
          <a class="font-menu nav-link datos-header-right">{{ agencyName }}</a>
        </li>
        <li class="nav-item items" style="margin-right: 10px;">
          <div style="width:40px; height: 40px;">
            <img *ngIf="agencyImagen" class="imag-circle" [src]="agencyImagen" alt="Logo Agency" (click)="_home()">
          </div>
        </li>
        <li class="nav-item separator-point items">
          <button type="button" class="close" (click)="logout()" placement="bottom" style="margin-left: 15px;">
            <i class="icon-power" aria-hidden="true" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <!-- <ng-container *ngIf="createItineraryAccess"> -->
    <div [ngStyle]="{'visibility': permissionsMap['booking.itinerarios.crear'] ? 'visible' : 'hidden', 'background-color': sectionHeader?.background_itinerary_owner}" id="section-bottom" [ngClass]="{'hidden-section': shouldHideSection()}"
> <!---Aca-->
      <!-- <div id="section-bottom"  [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"> -->
      <usb-itinerary-owner></usb-itinerary-owner>
    </div>
  <!-- </ng-container> -->
</header>
