<div id="rentacar-result-component" class="container w-100">
  <div class="row mt-2 mb-2">
    <div class="col-10">
      <div class="row">
        <div class="col-3 rentacar-image">
          <div class="row align-items-end" style="height: 140px">
            <div class="col-12 text-center mt-4 d-flex container-img" style="width:100%; margin: auto;">
              <img width="100%" height="100%" src="{{result.additionalInfo.images[0].image}}"
                onerror="if (this.src != 'assets/img/app/rent-a-car-default.png') this.src = 'assets/img/app/rent-a-car-default.png';">
            </div>
          </div>
          <div class="row ml-3 pt-3" style="width: 109%;" *ngIf="result?.additionalInfo?.carRental?.space_distribution.Adultos && totalChildrenResult">
            <div class="col-3 px-0">
              <div class="row  justify-content-start">
                <div class="m-0 p-0">
                  <i class="icon-adults"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="count-settings font-poppins pl-1">
                    {{result?.additionalInfo?.carRental?.space_distribution.Adultos? result?.additionalInfo?.carRental?.space_distribution.Adultos.slice(1) : 0 }}</span>
                </div>
              </div>
            </div>
            <div class="col-3 px-0">
              <div class="row  justify-content-start">
                <div class="m-0 p-0">
                  <i class="icon-child"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="count-settings font-poppins pl-1">
                    {{result?.additionalInfo?.carRental?.space_distribution.Adultos? totalChildrenResult.slice(1) : 0 }}</span>
                </div>
              </div>
            </div>
            <div class="col-3 px-0">
              <div class="row  justify-content-start">
                <div class="m-0 p-0">
                  <i class="icon-bags"></i>
                </div>
                <div class="m-0 p-0">
                  <span
                    class="count-settings font-poppins pl-1">{{ result?.additionalInfo?.carRental?.space_distribution.bag ? result?.additionalInfo?.carRental?.space_distribution.handbag.slice(1) : 0 }}</span>
                </div>
              </div>
            </div>
            <div class="col-3 px-0">
              <div class="row justify-content-start">
                <div class="m-0 p-0">
                  <i class="icon-handbag"></i>
                </div>
                <div class="m-0 p-0">
                  <span
                    class="count-settings font-poppins pl-1">{{result?.additionalInfo?.carRental?.space_distribution.handbag ? result?.additionalInfo?.carRental?.space_distribution.handbag.slice(1) : 0 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 rentacar-details pb-2 pl-4  pt-4 d-flex flex-column">
          <div class="row">
            <div class="col-6 px-0">
              <p class="title font-lato font-dark">{{stingUpperCase(result.title)}}&nbsp;
                <br>
                <!--  <span class="gearbox font-lato">{{result?.additionalInfo?.carRental?.gearbox}}</span>-->
                <span class="gearbox font-lato">
                  {{ result?.additionalInfo?.carRental?.category }} , {{ (((result?.additionalInfo?.carRental?.type_definition).length) > 80)? (result?.additionalInfo?.carRental?.type_definition.substring(0,80)) : result?.additionalInfo?.carRental?.type_definition }}
                </span>

              </p>
            </div>
            <div class="col-6">
              <div class="row" style="height: 25px !important; overflow: hidden;">
                <div class="col-2  px-0">
                  <!-- <i class="icon-video-1 px-0 mx-auto"></i>  -->
                </div>
                <div class="col-2 px-0">
                  <!--   <i class="icon-video-2 px-0 mx-auto"></i>  -->
                </div>
                <div class="col-3 content-icons-top" *ngIf="false">
                  <div class="row justify-content-md-center">
                    <div class="col-6 px-2">
                      <i class="icon-view"></i>
                    </div>
                    <div class="col-6 pl-0 text-view">
                      <p class="view font-lato text-center">
                        2500 <br>
                        view
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-5 content-icons-top">
                  <p class="days text-nowrap text-center font-lato">
                    <span class="days-nro">{{result?.totaldays}}</span>&nbsp;DÍAS
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- includes rent-car -->
          <div class="row mt-0 pr-1">
            <div class="col-7 mr-6">
              <div class="row">
                <div class="m-0 p-0">
                  <i class="icon-kilometro"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="text-settings font-poppins pl-1">Kilometraje Ilimitado</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="m-0 p-0">
                  <i class="icon-fuel"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="text-settings font-poppins pl-1">{{result?.additionalInfo?.carRental?.fuel_aircondition}} </span>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row">
                <div class="m-0 p-0">
                  <i class="icon-aircon"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="text-settings font-poppins pl-1">Air conditioning</span>
                </div>
              </div>

              <div class="row mt-2">
                <div class="m-0 p-0">
                  <i class="icon-automatic"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="text-settings font-poppins pl-1">{{result?.additionalInfo?.carRental?.gearbox ? "Caja automática" : "No disponible"}}</span>
                </div>
              </div>
            </div>
           <!-- <div class="col-3">
              <div class="row">
                <div class="m-0 p-0">
                  <i class="icon-check"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="text-settings font-poppins font-verde pl-1">Free Cancelation</span>
                </div>
              </div>
              <div class="row mt-2">
               <div class="m-0 p-0">
                  <i class="icon-check"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="text-settings font-poppins font-verde pl-1">Fre Amendments</span>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="row pl-2 pb-3">
        <div class="col-3 mt-2">
          <div class="row">
            <div class="col-4 px-0 text-center">
              <div class="mr-auto">
     <!-- 
                onerror="if (this.src != 'assets/img/app/rent-a-car-default.png') this.src = 'assets/img/app/rent-a-car-default.png';"-->
                <img width="100%" height="100%" src="{{result.additionalInfo?.carRental?.optionRates?.suplier?.img}}" onerror="if (this.src != 'assets/img/app/rent-a-car-default.png') this.src = 'assets/img/app/rent-a-car-default.png';">
              </div>
            </div>
            <div class="col-4 px-0">

            </div>
            <div class="col-4 px-0">

            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-3">
              <div class="row pl-2">
                <div class="m-0 p-0">
                  <span class="font-verde font-lato" style="font-size: 12px !important; font-weight:bold;line-height: 1; height:0px">LUGAR DE RECOGIDA</span>
                </div>
              </div>
              <div class="row pl-2">
                <div class="m-0 p-0">
                  <span class="font-verde font-lato" style="font-size: 12px !important; font-weight:bold;line-height: 1; height:0px">LUGAR DE ENTREGA</span>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="row">
                <div class="m-0 p-0 pl-2">
                  <span class="text-location font-lato" style="line-height: 1;">{{ result?.additionalInfo?.carRental?.LocationDetails.PickUp.Name  }} </span>
                </div>
              </div>
              <div class="row">
                <div class="m-0 p-0 pl-2">
                  <span class="text-location font-lato" style="line-height: 1;">{{ result?.additionalInfo?.carRental?.LocationDetails.DropOff.Name  }} </span>
                </div>
              </div>
            </div>
            <div class="col-2 pt-2">
              <div class="row">
                <div class="m-0 p-0">
                  <i class="icon-points mr-2"></i>
                </div>
                <div class="m-0 p-0">
                  <span class="font-points font-poppins align-middle">
                    {{result?.additionalInfo?.carRental?.blickoins > 0 ? result?.additionalInfo?.carRental?.blickoins : "X" }}&nbsp;Bks.
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 p-0 " style="background-color:#d8d8d8 !important">
      <div class="col-xg-2 col-lg-2 col-md-3 col-sm-12 flex-column align-items-middle d-flex continue">
        <div class="d-flex flex-column container-price justify-content-center py-3" style="margin-top: 42px;">
          <p class="m-0 fromPrice text-center pt-4" style="line-height: 0;">Tarifa {{result?.totaldays}} día desde</p>
          <p class="priceMoney text-center" *ngIf="result?.additionalInfo?.carRental">
            <span style="font-size: 20px !important; font-weight: 700 !important">{{result.additionalInfo.carRental.currency}}</span>{{ result?.additionalInfo?.carRental?.totalPriceProf | number:'1.2-2'}}
          </p>  
          <div class="row pl-1" style="width:155px">
            <div class="col-2">
              <p class="text-nowrap text-center font-lato">
                <span class="bg_cg">CG</span>&nbsp; 
              </p>
            </div>
            <div class="col-8 text-nowrap">
              <p class="text_cg font-lato text-nowrap align-middle">
                <span style="font-weight:800; font-size: 12px;">CANC.GRATIS </span> 
                HASTA {{ result?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.PolicyRules[0].DateFrom  | date:'dd/MM/yyyy'}}
                <br>
                <span style="font-weight:800; font-size: 11px; color: red;" *ngIf="!result?.additionalInfo?.carRental?.optionRates?.cancellation_policy?.PolicyRules[0].DateFrom">
                  {{dateEndSearch}}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-end py-3 ml-2">
          <button type="button" (click)="openModal(ModalRooms);" style="width: 119px !important;"
            [disabled]="result?.additionalInfo?.carRental?.netPriceProf == 0"
            class="btn btn-usblick-pink btn-green justify-content-center w-100 py-1 font-weight-bold">
            AGREGAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
