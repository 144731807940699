<div class="t-content">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <!--***********Basic Data*************-->
    <div class="form-row pt-3">
      <div class="form-group col-xg-6 col-lg-2 col-md-12 col-sm-12" id="form-origin">
        <input ngui-auto-complete formControlName='origin' maxlength="20" type="text" name="origin" class="form-control input-search-box"
          value-formatter="name" placeholder="Origen" [(ngModel)]="originVar" [source]="observableSource.bind(this)"
          display-property-name="name" (valueChanged)="_myCallbackOrigin($event)" [list-formatter]="_myListFormatter"
          autocomplete="off" #destination>
        <span class="help-block text-danger"
          *ngIf="_form.get('origin').touched && _form.get('origin').hasError('required')">Este campo es requerido.</span>
        <span class="help-block text-danger"
          *ngIf="_form.get('origin').touched && _form.get('origin').hasError('invalidcountry')">Formato de destino no válido.</span>
      </div>
      <div class="form-group col-xg-6 col-lg-2 col-md-12 col-sm-12" id="form-destination">
        <input ngui-auto-complete formControlName='destination' maxlength="20" type="text" name="destino"
          class="form-control input-search-box" value-formatter="name" placeholder="Destino" [(ngModel)]="destiny"
          [source]="observableSource.bind(this)" display-property-name="name"
          (valueChanged)="_myCallbackDestiny($event)" [list-formatter]="_myListFormatter" autocomplete="off">
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('required')">Este campo es obligatorio.</span>
        <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidcountry')">Formato de destino no válido.</span>
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">
        <div class="form-row">
          <div class="form-group col-xg-4 col-lg-6 col-md-12 col-sm-12 pr-0">
            <input formControlName="arrivalDate" type="text" class="form-control input-search-box btn-border-right" id="arrivalDate"
              placeholder="Check-in     -" autocomplete="off" bsDatepicker [minDate]="minDate" [bsConfig]="dpConfig"
              #dpArrival="bsDatepicker" (bsValueChange)="nextDate($event)" readonly>
            <span class="help-block text-danger"
              *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('required')">Este campo es requerido</span>
            <small class="error-label"
              *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('invalidDate')">
              * ¡El check-in debe ser una fecha anterior al check-out!
            </small>


          </div>
          <div class="form-group col-xg-4 col-lg-6 col-md-12 col-sm-12 pl-0">
            <input formControlName="departureDate" type="text" class="form-control input-search-box btn-border-left" id="departureDate"
              placeholder="Check-out" autocomplete="off" bsDatepicker [minDate]="minDate" [bsConfig]="dpConfig"
              #dpDeparture="bsDatepicker" readonly>
            <span class="help-block text-danger"
              *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('required')">Este campo es requerido.</span>
            <small class="error-label"
              *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('invalidDate')">
              * ¡El check-out debe ser una fecha posterior al check-in!
            </small>



          </div>
        </div>
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">

        <button type="button" class="form-control btn-usb-rp" (click)="_openModal()" [attr.aria-expanded]="!isCollapsed"
          aria-controls="false">
          {{passengers}}
          <span class="arrow-sort fa fa-sort"></span>
        </button>
        <div *ngFor="let room of _form.value['room']; let i = index">
          <span class="help-block text-danger"
            *ngIf="((validate || (_close && isCollapsed)) && (room['qtyAdults'] == 0))">Al menos selecciona 1 adulto
            {{i+1}}.</span>
        </div>
        <div (click)="_over()" (collapsed)="clickOutside($event)" (expanded)="($event)"
          [collapse]="!isCollapsed || _close" class="justify-content-end">
          <div class="row">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
              <div class="card">
                <div formArrayName="room" *ngFor="let item of formRoom(_form, 'room'); let i = index"
                  class="d-flex flex-row col-12 align-items-center justify-content-between p-2">
                  <div [formGroupName]="i"
                    class="d-flex flex-row align-items-start justify-content-between col-12 p-0">
                    <!-- <div class="p-2 col-2" style="padding:25px 0 0 0 !important;">Room {{i + 1}}</div> -->
                    <div class="p-0 pt-1 m-0 col-2">
                      <label for="adults">Adultos</label>
                      <select formControlName="qtyAdults" class="custom-select form-control-sm " id="adults"
                        (ngModelChange)="_addAdult(item)" name="adults" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <div class="p-0 pt-1 m-0 col-2">
                      <label for="childs">Niños</label>
                      <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs"
                        (ngModelChange)="_addChild(item)" name="childs" [value]="0">
                        <option *ngFor="let qty of _maxPersons" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <div class="p-0 col-5">
                      <!-- Adults age inputs -->
                      <div id="adultsAges" class="p-1 col-12" *ngIf="item.get('adultsage').length > 0">
                        <label for="age">Edades de Adultos (18+)</label>
                        <div class="d-flex flex-column p-0 m-0">
                          <div class="d-flex">
                            <div formArrayName="adultsage" *ngFor="let age of formRoom(item, 'adultsage'); let j = index;"
                              class="col-3 select-ages">
                              <div [formGroupName]="j" class="col-12 p-0">
                                <input formControlName="age" min=18 max=99 limit-to="99"
                                  class="form-control col-11 p-3 input-age" value-formatter="0" placeholder="18"
                                  name="age">
                              </div>
                            </div>
                          </div>
                          <div *ngFor="let age of formRoom(item, 'adultsage'); let j = index;">
                            <span class="help-block text-danger d-flex w-100 text-nowrap"
                              *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') ||age.controls['age'].hasError('max') || age.controls['age'].hasError('required')">
                              <br>La edad del adulto {{j+1}} es incorrecta</span>
                          </div>
                        </div>
                      </div>
                      <!-- Children age inputs -->
                      <div id="childrenAges" class="p-1 col-12" *ngIf="item.get('childsage').length > 0">
                        <label for="age">Edad de Niños</label>
                        <div class="d-flex flex-column p-0 m-0">
                          <div class="d-flex">
                            <div formArrayName="childsage" *ngFor="let age of formRoom(item, 'childsage'); let j = index;"
                              class="col-3 select-ages">
                              <div [formGroupName]="j" class="col-12 p-0">
                                <input formControlName="age" min=0 max=17 limit-to="14"
                                  class="form-control col-11 p-3 input-age" value-formatter="0" placeholder="0"
                                  name="age">
                              </div>
                            </div>
                          </div>
                          <div *ngFor="let age of formRoom(item, 'childsage'); let j = index;">
                            <span class="help-block text-danger d-flex w-100 text-nowrap"
                              *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')">
                              <br>La edad de los niños {{j+1}} es incorrecta</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="p-0 justify-content-start align-items-center text-center">
                      <button (click)="_removeRoom(i)" class="btn btn-outline-danger col-12 input-age btn-sm"
                        [disabled]="_form.get('qtyProduct').value===1">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                    <hr>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-end flex-row">
                  <div class="p-2 justify-content-end">
                    <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                      class="btn btn-outline-danger btn-sm" type="button">
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-groupcol-xg-4 col-lg-2 col-md-12 col-sm-12">
        <button class="btn btn-usblick" type="submit"
          [disabled]="(!_form.valid || _findData || userService.itineraryActive.length == 1)">
          <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
          Buscar
        </button>
      </div>

    </div>
    <div class="form-row col-12 pt-1" style="margin-top: -17px; padding-bottom: 0px;">
      <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12 justify-content-start py-2 align-content-center">
        <div class="checkbox">
          <label>
            <input type="checkbox" value="" checked>
            <span class="cr">
              <i class="cr-icon fa fa-check"></i>
            </span>
            <label class="form-check-label">
              Cancelación segura</label>
          </label>
        </div>
      </div>
      <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12 justify-content-start py-2 align-content-center">
        <div class="checkbox">
          <label>
            <input type="checkbox" value="" checked>
            <span class="cr">
              <i class="cr-icon fa fa-check"></i>
            </span>
            <label class="form-check-label">
              Enfermedades preexistentes</label>
          </label>
        </div>
      </div>
      <div class="form-group col-xg-4 col-lg-4 col-md-12 col-sm-12 justify-content-start py-2 align-content-center">
        <div class="checkbox">
          <label>
            <input type="checkbox" value="" checked>
            <span class="cr">
              <i class="cr-icon fa fa-check"></i>
            </span>
            <label class="form-check-label">Embarazada</label>
          </label>
        </div>
      </div>
    </div>



  </form>
</div>