import { ItineraryReservationModule } from './../itinerary-reservation/itinerary-reservation.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserModule } from '@angular/platform-browser';
//import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DetailsSummaryItineraryComponent } from './details-summary-itinerary.component';
import { ItemRentacarDetailSummaryComponent } from './services-details-summary/item-rentacar-detail-summary/item-rentacar-detail-summary.component';
import { CardPriceSummaryComponent } from './card-price-summary/card-price-summary.component';
import { ServicesDetailsSummaryComponent } from './services-details-summary/services-details-summary.component';
import { ItemHotelDetailSummaryComponent } from './services-details-summary/item-hotel-detail-summary/item-hotel-detail-summary.component';
import { ItemInsuranceDetailSummaryComponent } from './services-details-summary/item-insurance-detail-summary/item-insurance-detail-summary.component';
import { ItemPthemeDetailSummaryComponent } from './services-details-summary/item-ptheme-detail-summary/item-ptheme-detail-summary.component';
import { ItemDisneyDetailSummaryComponent } from './services-details-summary/item-disney-detail-summary/item-disney-detail-summary.component';
import { ItemTransportDetailSummaryComponent } from './services-details-summary/item-transport-detail-summary/item-transport-detail-summary.component';
import { ItemToursDetailSummaryComponent } from './services-details-summary/item-tours-detail-summary/item-tours-detail-summary.component';
import { DetailsSummaryItineraryRoutingModule } from './details-summary-itinerary-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalPostBookingComponent } from 'app/shared/modal-post-booking/modal-post-booking.component';
////import { SharedModule } from '../shared/shared.module'; //nuevo

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        //NgxChartsModule,

        //BsDropdownModule,
        DetailsSummaryItineraryRoutingModule,
        SharedModule,
        ItineraryReservationModule,
        BrowserAnimationsModule
    ],
    declarations: [
        DetailsSummaryItineraryComponent,
        CardPriceSummaryComponent,
        ServicesDetailsSummaryComponent,
        ItemHotelDetailSummaryComponent,
        ItemInsuranceDetailSummaryComponent,
        ItemPthemeDetailSummaryComponent,
        ItemDisneyDetailSummaryComponent,
        ItemTransportDetailSummaryComponent,
        ItemToursDetailSummaryComponent,
        ItemRentacarDetailSummaryComponent,
    ],
    providers: [

    ],
    entryComponents: [
      ModalPostBookingComponent
    ]
})
export class DetailsSummaryItineraryModule { }
