import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { ItinerarySummaryComponent } from './itinerary-summary.component';

const route: Routes =[
    { path: 'summary', component: ItinerarySummaryComponent, data: { titulo: extract('Summary') } }
];

@NgModule({
    imports: [RouterModule.forChild(route)],
    exports: [RouterModule],
    providers: []
})

export class ItinerarySummaryRoutingModule { }