import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { DetailsSummaryItineraryComponent } from './details-summary-itinerary.component';

const routes: Routes =[
    { path: 'DetailsSummaryItinerary', component: DetailsSummaryItineraryComponent, data: { title: extract('Details Summary Itinerary') } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class DetailsSummaryItineraryRoutingModule { }
