import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFilter'
})
export class PriceFilterPipe implements PipeTransform {
  transform(array: any, lowPrice: number, highPrice: number): any {
    if (!array || (lowPrice === null && highPrice === null)) {
      return array;  // No hay precio para filtrar, devuelve el array sin cambios.
    }

    // Filtramos los hoteles según el precio mínimo y máximo de las habitaciones
    return array.filter((hotel: any) => {
      // Filtrar habitaciones dentro del rango de precios
      const validRooms = hotel.additionalInfo.rooms.filter((room: any) =>
        room.room.optionNightsNetTotalProf >= lowPrice && room.room.optionNightsNetTotalProf <= highPrice
      );

      // Si existen habitaciones válidas dentro del rango, lo incluimos en el resultado
      return validRooms.length > 0;
    });
  }
}
