import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { formatArrivalDate } from '../../../../../../utils/Functions';
import { ItineraryService } from '../../../../../../shared/itinerary.service';
import { RestService } from '../../../../../../shared/rest.service';
import { ModalDirective } from 'ngx-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'usb-item-transport',
  templateUrl: './item-transport.component.html',
  styleUrls: ['./item-transport.component.scss']
})
export class ItemTransportComponent implements OnInit {
  @Input() data: any;
  @Input() isCheckIn: boolean;
  @Input() partialReserve: boolean;
  @Output() itemToPartial = new EventEmitter<number>();
  @Output() itemDelete = new EventEmitter<any>();

  @ViewChild('modalRooms') modalRooms: ModalDirective;

  dato
  
  public formatDate = formatArrivalDate;
  constructor(private itineraryService: ItineraryService, private restService :RestService) { }

  ngOnInit() {
    const payloadTransport = [];
    payloadTransport.push(this.data);
    console.log('DATA ----->', payloadTransport);
    //this.getProduct();
  }

  getProduct(){

    if (this.data.payloadUpdate) {
      console.log('/search/products------------------> getProduct ItemTransportComponent',  this.data.payloadUpdate);
      this.restService.doPost('app', 'search/products', this.data.payloadUpdate).subscribe( 
        (response:any) => {

          console.log("get product", response);

          if (response && response.status && response.results) {
            if (response.results[0] !== undefined) {
              this.data.product = response.results[0];
            } else {
              this.data.product = response.results;
            }
          }

          console.log("this.dato transport actualizado", this.data);
        }, error => {
          console.log(error.message);
        }
      );
    }
  }

  removeItem(item: any) {
    console.log(item);
    this.itineraryService.remove(item);
    this.itemDelete.emit(item);
  }

  toPartialReserve(event, item: any){
    if ( event.target.checked ) {
      this.itineraryService.updateItineraryPartial(item);
      this.itemToPartial.emit(1);
    } else {
      this.itineraryService.updateItineraryPartial(item,true);
      this.itemToPartial.emit(-1);
    }
  }

  subtractDays(date){
    return moment(moment(date)).subtract(2, 'days').format('YYYY-MM-DD');
  }
  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }
  fontPrice(price){
    if((price.length - 6) > 0){
      const fact = price.length - 6
      const size = 25 - (1.5 * fact)
      return size + 'px'
    }
  }
}
