import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ItineraryService} from '../shared/itinerary.service';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'http://localhost:3000/run';
@Injectable()

export class SendEmailService {
  sendEmailUrl = '/send';
  _agencyEmail: any;
  constructor(
    private http: HttpClient,
    private itineraryService: ItineraryService
    ) { }

  sendMail(itinerary: any, obje: any, to: any ): Observable<any> {
    this.itineraryService._restService.doGet('app', 'agents/1').subscribe(
      Response => {
        this._agencyEmail = Response['data'][0]['email'];
        console.log('Enviado ----->');
    },
      Error => {
        console.log('Error', Error);
    });
    const uri = `${apiUrl + this.sendEmailUrl}`;
    const obj = {
      name:  to,
      email: this._agencyEmail,
      text: itinerary.id,
      message: obje
    };
    return  this.http.post(uri, obj);
  }
}