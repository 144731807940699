import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { ContentResultComponent } from './content-result.component';

const route: Routes =[
    { path: 'results', component: ContentResultComponent, data: { titulo: extract('Results') } }
];

@NgModule({
    imports: [RouterModule.forChild(route)],
    exports: [RouterModule],
    providers: []
})
export class ContentResultRoutingModule { }
