<div *ngIf="dato" class="width-100">
  <div class="row mt-2">
    <div class="col-2 p-0 m-0 text-center">
      <span class="date-itinerary-s">{{getDate()}}</span>
    </div>
    <!-- <div class="col-auto mx-0 px-0 space-primer-nivel"></div> -->
    <div class="col-auto mr-4">
      <span class="circle">
        <i class="icon-small icon-disney iconregular"></i>
      </span>
    </div>
    <div class="col-xg-9 col-lg-9 flex-column px-0 mx-0 d-flex space-items">
      <div class="d-flex ">
        <div class="col-xg-10 col-lg-10 px-0 m-0 flex-column font-descrip">
          <span class="title-destiny-start d-flex align-items-center">{{dato.hotel.title}}
            <i *ngFor="let star of stars(); let i=index" class="icon-estrella px-1"></i>
          </span>
          <span class="p-0 spaced">
            <span>
              <i class="icon-placeholder" aria-hidden="true"></i>{{dato.hotel.additionalInfo.address}} {{dato.hotel.additionalInfo.location}}</span>
          </span>
          <span class="p-0 m-0  spaced">
            <p class="p-0 m-0 inter">
              {{dato.room.nights}}
              <i class="icon-moon-phase-outline" aria-hidden="true"></i>| {{dato.room.roomType}}
            </p>
          </span>
          <span class="p-0 m-0 spaced">
            <p class="p-0 m-0 inter">
              <i class="icon-restaurant"></i> {{dato.mealPlan ? dato.mealPlan.title : 'None'}} |
              <i class="icon-actividades_exterior"></i>{{dato.days}} {{dato.ticket ? dato.ticket.title : 'None'}} | {{getPax()}}
            </p>
          </span>
        </div>
        <div class="col-2 d-inline m-0 p-0 text-right" *ngIf="isCheckIn">
          <div *ngIf="partialReserve===true" class="pretty p-round p-thick p-bigger p-icon p-smooth">
            <input type="checkbox" (change)="toPartialReserve($event, dato)" ng-checked="dato.status_id==3" />
            <div class="state">
              <i class="icon fa fa-check"></i>
              <label></label>
            </div>
          </div>
          <!-- <span>
            <i class="icon-pencil"></i>
          </span> -->
          <span>
            <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(dato)">
              <i class="icon-papelera"></i>
            </button>
          </span>

        </div>
      </div>

      <div class="d-flex font-inline flex-row" *ngIf="isCheckIn">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class=" spaced font-start d-flex pr-5 mr-5"></span>
          <span classs="align-items-center d-flex ml-5 pl-5 pr-0">Tarifa Neta: {{ dato.paxAdults }} Adults - {{ dato.paxChildren
            }} Children</span>
          <span class="title-destiny-start pt-auto pr-4" [ngStyle]="{'font-size': fontPrice(dato.optionNightsNetTotalProf)}">$&nbsp;{{ dato.totalPrice | number:'1.2-2' }}</span>
        </div>
      </div>
      <div class="d-flex font-inline flex-row" *ngIf="isCheckIn">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class=" spaced font-start d-flex"><b> Check-In </b></span>
        </div>
      </div>


      <div class="d-flex font-inline flex-row" *ngIf="!isCheckIn">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class="spaced font-end d-flex"><b> Check-Out </b></span>
        </div>
      </div>
    </div>

  </div>
</div>