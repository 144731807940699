<div class="d-flex justify-content-between content-result">
  <div class="d-flex image">
    <img class="img-pack"
      [src]="result.additionalInfo.images[0].image ? result.additionalInfo.images[0].image : '/assets/img/app/package/pack-img.png'"
      alt="">
  </div>
  <div class="d-flex data flex-column justify-content-between">
    <div class="row">
      <div class="col-5">
        <h4 class="title font-lato">{{stingUpperCase(result.title)}}</h4>
      </div>
      <div class="col-7 px-0">
        <ul class="nav justify-content-end">
          <li style="border-left: 1px solid #000000; padding-left: 5px; padding-right: 10px;">
            <div class="d-flex">
              <i class="icon-view"></i>
              <span class="text-views font-opensans">2500<br>views</span>
            </div>
          </li>
          <li style="border-left: 1px solid #000000; padding: 0px 10px;">
            <i class="icon-vid-1"></i>
          </li>
          <li style="border-left: 1px solid #000000; padding: 0px 8px;">
            <i class="icon-vid-2"></i>
          </li>
          <li style="border-left: 1px solid #000000; padding: 0px 15px;">
            <p class="days text-nowrap text-center font-lato mb-0">
              <span class="days-nro">07</span>&nbsp;&nbsp;DAYS&nbsp;
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-12">
        <p class="text-description font-lato">
          {{ !(isNull(result.description))? result.description : 'El proveedor no añadió ninguna descripción del servicio.' }}
        </p>
      </div>
    </div>
    <div class="row justify-content-between align-items-end">
      <div class="d-flex" style="padding: 0px 15px;">
        <ul class="nav">
          <li style="width: 70px;">
            <p class="text-included border-data font-poppins pl-1 mb-0">included</p>
          </li>
          <li class="ml-2">
            <i class="icon-transport"></i>
          </li>
          <li class="ml-2">
            <i class="icon-food"></i>
          </li>
          <li>
            <i class="icon-coffee"></i>
          </li>
          <li class="ml-2">
            <i class="icon-music"></i>
          </li>
          <li class="ml-2">
            <i class="icon-vouchers"></i>
          </li>
        </ul>
      </div>
      <div class="d-flex text-right" style="width: 76px; margin-right: 15px;">
        <span class="font-lato text-off">15&nbsp;<i class="icon-off"></i></span>
      </div>
    </div>
    <div class="row justify-content-between align-items-end">
      <div class="d-flex" style="padding: 0px 15px;">
        <ul class="nav">
          <li style="width: 70px;">
            <p class="text-included pl-1 border-data font-poppins mb-0">cities</p>
          </li>
          <li class="ml-2 city-item">
            <p class="city-name mb-0 font-poppins">miami</p>
          </li>
          <li class="ml-1 city-item">
            <p class="city-name mb-0 font-poppins">orlando</p>
          </li>
          <li class="ml-1 city-item">
            <p class="city-name mb-0 font-poppins ">tampa</p>
          </li>
        </ul>
      </div>
      <div class="d-flex text-right" style="width: 82px; margin-right: 15px;">
        <span class="font-points font-poppins align-middle">
          <i class="icon-points"></i>&nbsp;
          500&nbsp;Bks.
        </span>
      </div>
    </div>
  </div>
  <div class="row m-0 price justify-content-center">
    <div class="d-flex m-0 p-0 align-self-end data-price">
      <p class="text-price p-0 m-0 font-opensans">From <br><span class="money">usd</span>&nbsp;<span
          class="number">3800</span></p>
    </div>
    <div class="d-flex m-0 p-0 content-button-add align-self-end">
      <button (click)="ModalRooms.show()" class="btn btn-block btn-add font-poppins">add</button>
      <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
        [modalRoomRef]="ModalRooms" [product]="result" [productQty]="payload.qtyProduct" [form]="form" [origin]="origin"
        [arrayResults]="arrayResults"></usb-modals>
    </div>
  </div>
</div>

<!-- <div id="packages-result-component" >
  <div class="d-flex col-12 m-0 p-0 pos-div">
      <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12 packages-image">
          <div class="col-12 m-0 p-0">
            <img class="img-packages" *ngIf="result?.additionalInfo?.image && result?.additionalInfo?.image[0]" src="{{result.additionalInfo.image[0].image}}" alt="hotel">
            <img class="img-packages" *ngIf="!result?.additionalInfo?.image" src="assets/img/app/hotel_default.png" alt="hotel">
          </div>
        </div>
        <div class="col-xg-7 col-lg-7 col-md- 5 col-sm-12 packages-details py-2 pl-2">
            <h4>{{result.title}}</h4>
            <div class="flex-row d-flex justify-content-between location">
                <span class="d-flex">
                    <i class="icon-placeholder"></i>{{result?.additionalInfo?.location}}
                </span>
                <span class="d-flex">
                    <i class="icon icon-find-user"></i> 123456 views
                  </span>
            </div>
            
            <div class="description pt-1 d-flex flex-column">
               <p>{{result.additionalInfo.rooms[0].mealPlan.name}}</p>
              <p>{{result.description | truncate : 130 : "  "}}<a (click)="ModalRooms.show()" class="more">...More</a></p>
               <div class="d-flex services justify-content-end pt-2">
                <div class="d-flex mt-auto">
                  <i class="icon-valet-parking px-1"></i>
                  <i class="fas fa-wifi px-1"></i>
                  <i class="fa fa-cutlery px-1"></i>
                  <i class="icon-swimming pl-1"></i>
                </div>
              </div> 
            </div>
            <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12">
              <div class="col-12 m-1 p-0">
                <img class="img-logo" *ngIf="result?.logo" src="{{result.logo }}" alt="logo">
              </div>
            </div>
          </div>
            <div class="col-xg-2 col-lg-2 col-md-3  col-sm-12 flex-column align-items-middle d-flex continue">
              <div *ngIf="result.serviceResponse" class="d-flex flex-row justify-content-center rating-star">
                  <i *ngFor="let star of stars(); let i=index" class="icon-estrella px-1"></i>
              </div>
              <div class="d-flex justify-content-center mt-auto flex-column text-right">
                <span class="text-price">{{(result?.additionalInfo?.tours ? result?.additionalInfo?.tours.options[0].prices.adultFlag : (result?.additionalInfo?.roomsPackage ? result?.additionalInfo?.roomsPackage[0].refPriceFlag : ''))}} From</span>
                <span class="packages-price">${{((result?.additionalInfo?.tours) ? result?.additionalInfo?.tours.options[0].prices.adult : ((result?.additionalInfo?.roomsPackage) ? result?.additionalInfo?.roomsPackage[0].refPrice : 0)) | number:'1.0-0'}}</span>
                <span class="text-price">P/Night</span>
              </div>
            <div class="d-flex justify-content-center packages-price">
                <span class="text-center text-price">${{result.additionalInfo.rooms[0].netPrice | number:'1.0-2'}} P/Night</span>
            </div> -
            <div class="d-flex mt-auto py-2">
                <button type="button" (click)="ModalRooms.show()" class="btn btn-usblick-pink justify-content-center w-100 py-1">Add</button>
                <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" [modalRoomRef]="ModalRooms"
                  [product]="result" [productQty]="payload.qtyProduct" [form]="form" [origin]="origin" [arrayResults]="arrayResults"></usb-modals>
            </div>
          </div>
  
  </div>
  
  </div> 
 -->