<div class="modal-dialog modal-lg">

<!-- comentado mientars se resuelve el tema del build 02/03/2020
   [__origin]="origin"
    [__ori]="__payload.destination"
    [__index]="index"
    [___payload]="__payload" 
        [cityIataCode]="cityIataCode"
  -->

  <usb-modal-search-transports *ngIf="origin==='TRANSPORTS'" 
    (refModal)="hideModal()"
    (emitIndex)="_emitIndex($event)"
    (emitReload)="_emitReload($event)">
  
  </usb-modal-search-transports>

  <!-- <usb-modal-hotel *ngIf="origin==='HOTELS' || origin==='MULTIDESTINATION'" [hotel]="product" [productQty]="productQty" [form]="form"
    [payloadUpdate]="payloadUpdate" [itineraryItem]="itineraryItem" [origin]="origin" [arrayResults]="arrayResults" (refModal)="hideModal($event)">
  </usb-modal-hotel>

  <usb-modal-pthemes *ngIf="origin==='PTHEMES'" [ptheme]="product" [origin]="origin" [form]="form" [payloadUpdate]="payloadUpdate"
    [itineraryItem]="itineraryItem" [arrayResults]="arrayResults" (refModal)="hideModal($event)"></usb-modal-pthemes>

  <usb-modal-disney *ngIf="origin==='DISNEY'" [hotel]="product" [origin]="origin" [productQty]="productQty" [form]="form" [payloadUpdate]="payloadUpdate"
    [arrayResults]="arrayResults" [_item]="_item" (refModal)="hideModal($event)">
  </usb-modal-disney>


  
  <usb-modal-rentacar *ngIf="origin==='RENT-A-CAR'" [rentaCar]="product" [origin]="origin" [form]="form" [payloadUpdate]="payloadUpdate"
    [itineraryItem]="itineraryItem" [arrayResults]="arrayResults"></usb-modal-rentacar>

  <usb-modal-insurance *ngIf="origin==='INSURANCE'" [insurance]="product" [origin]="origin" [form]="form" [payloadUpdate]="payloadUpdate" [payload]="payload"
    [itineraryItem]="itineraryItem" [arrayResults]="arrayResults" (refModal)="hideModal($event)"></usb-modal-insurance>

  <usb-modal-tours *ngIf="origin==='TOURS'" [tour]="product" [origin]="origin" [payloadUpdate]="payloadUpdate" [itineraryItem]="itineraryItem"
    [arrayResults]="arrayResults" [form]="form" (refModal)="hideModal($event)"></usb-modal-tours> -->
</div>