import { BsModalService, BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { Component, TemplateRef, OnInit, EventEmitter, Output, Input, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../shared/rest.service';

import { Observable } from 'rxjs/Observable';

import { DatePipe, UpperCasePipe } from '@angular/common';
import { AddressesService } from '../../../home/search-box/service/addresses.service';
import { CustomValidator } from '../../../shared/customValidators';
////import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ResultsSearchService } from '../../../home/results-search.service';

//import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

import { ResultsMultidestinations } from '../../../content-result/category-result/results.multidestinations';
import { PayloadCategoryMultidestinations } from '../../../content-result/category-result/payload.category';
import { ItineraryService } from '../../itinerary.service';
import * as moment from 'moment';
import { UserService } from '../../../pages/com.usblick.common/auth/user.service';
import { UbicacionesService } from '../../../home/search-box/service/ubicaciones.service';
import { TransportsService } from '../../../home/search-box/service/transportsMockUp.service';






@Component({
  selector: 'usb-modal-search-transports',
  templateUrl: './modal-search-transports.component.html',
  styleUrls: ['./modal-search-transports.component.scss'],
  providers: [
    //NgbAccordionConfig, 
    //TransportsService, 
    DatePipe, 
    AddressesService, 
    UpperCasePipe, 
    //UbicacionesService
  ]
})

export class ModalSearchTransportsComponent implements OnInit {

  payloadCategory: PayloadCategoryMultidestinations;
  resultsMultidestinations: ResultsMultidestinations;
  destineValue: boolean;
  destineVale: boolean;
  originValue: boolean;
  selectediataCodeDestiny: any;
  selectediataCodeOrigin: any;
  iataCodeOrigin: any;
  nameOrigin: any;
  iataCodeDestiny: any;
  nameDestination: any;
  modalRef: BsModalRef;
  _results: any;
  isOpen = true;

  @Output() origin = new EventEmitter<any>();
  @Output() modal = new EventEmitter<string>();
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() show = new EventEmitter<boolean>();
  @Output() emitReload = new EventEmitter<any>();
  @Output() emitIndex = new EventEmitter<any>();

 /* @Input() __origin: any;
  @Input() __multiModal: any;
  @Input() __ori: any;
  @Input() ___payload: any;
  Input() cityIataCode: any;*/
  __origin: any;
  /// __multiModal: any;
  __ori: any;
  ___payload: any;
  cityIataCode: any;


  @Input() resultsFound: any;
  @Input() results_: any = [];
  @Input() __index: any;
  @Output() refModal = new EventEmitter<any>();

  _contentCollapsed = true;
  _resultsQuery: any;
  _findData: boolean;
  _form: FormGroup;
  minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  _maxPersons: any;
  isCollapsed = false;
  ismeridian = false;
  _close = false;
  dest: any;
  ori: any;
  pickup: any;
  dropoff: any;
  flight: any;
  _origin: any;
  passengers: string;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  _actualItinerary: any;
  departureDateIn: any;
  arrivalDateIn: any;
  departureDateOut: any;
  airportIn: any;
  idFlight: any;
  itinerary: any;
  message = [];

  constructor(

    private resultsSearch: ResultsSearchService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private datePipe: DatePipe,
    //  vcr: ViewContainerRef,
    private upperPipe: UpperCasePipe,
    private _router: Router,
    private _restService: RestService,
    private _formBuilder: FormBuilder,
    private ubicacionesService: UbicacionesService,
    private _addressService: AddressesService,
    private transportsService: TransportsService,
    private _itineraryService: ItineraryService,
    public userService: UserService
  ) {

    this.dpConfig.containerClass = 'theme-red';
    this.minDate = new Date();
    /// //this.toastr.clearAllToasts();
    // //this.toastr.setRootViewContainerRef(vcr);


  }

  ngOnInit() {
    // //this.toastr.clearAllToasts();
    this.getFlight();
    this.initForm();
    this._origin = this.resultsSearch._getOrigin();
    this._getCitys(this.__ori);
    //  this._maxPersons = Array.from(Array(this.___payload['qtyPax'] + 1).keys());
    this._maxPersons = Array.from(Array(5).keys());
  }

  getFlight() {
    this._itineraryService.getActiveItinerary().subscribe(
      (data:any) => {
        if (data) {
          this._actualItinerary = data[0];
          this.itinerary = this._actualItinerary['id'];
          let flight = null;
          if (this.itinerary) {
            // tslint:disable-next-line:max-line-length
            this._itineraryService._restService.doGet('app', 'itinerary/' + this.itinerary + '/fly').subscribe(
              Response => {
                if (Response.success === false && Response.message === 'Fly not found') { // no existe vuelo
                  console.log('flights nuLL', Response);
                  // this.initForm();
                } else {
                  flight = Response['data'][0];
                  console.log('Has flights associated', Response);
                  this.idFlight = flight['id'];
                  this.departureDateIn = new Date(flight['departureIn']);
                  this.arrivalDateIn = new Date(flight['arrivalIn']);
                  this.departureDateOut = new Date(flight['departureOut']);
                  this.airportIn = flight['airportIn'];
                  setTimeout(() => {
                    //  this.loadinitForm();
                  }, 100);
                }
              },
              Error => {
                console.log('Has no flights associated', Error);
              }
            );
          }
        }
      },
      error => {
        console.log(error);
      }
    );
    this._itineraryService.consultActiveItinerary(this.userService._agentID);
  }

  _initLoadData(payload: any) {
    this.___payload = payload;
    // this._maxPersons = Array.from(Array(this.___payload['qtyPax'] + 1).keys());
  }

  _getCitys(param: any) {
    this._restService.doGet('api', '/searchlocations/' + param).subscribe(res => {
      for (let index = 0; index < res['data'].length; index++) {
        if (res['data'][index]['cityIataCode'] === param) {
          this.ori = res['data'][index]['cityName'];
        }
      }
    },
      Error => {
        console.log('Error', Error);
      });
  }

  _emitRefModal() {
    this.refModal.emit();
  }

  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' })
    );
  }

  toggleCollapse() {
    console.log(this._contentCollapsed);
    this._contentCollapsed = !this._contentCollapsed;
  }

  closeCollapse(close: boolean) {
    this._contentCollapsed = close;
  }


  handleChange(evt: any) {
    if (evt === 'roundTrip') {
      this.isOpen = true;
      this._form.get('secondArrivalDate').setValidators(Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('firstArrivalDate').value !== null &&
          this._form.get('firstArrivalDate').value !== undefined
        ) {
          const valid = (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
          this._form.controls['firstArrivalDate'].setErrors(valid);
          return (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
        }
      }]));
      this._form.get('secondTime').setValidators(Validators.required);
      this._form.get('secondArrivalDate').updateValueAndValidity();
      this._form.get('secondTime').updateValueAndValidity();
    } else {
      this.isOpen = false;
      this._form.get('secondArrivalDate').clearValidators();
      this._form.get('secondArrivalDate').updateValueAndValidity();
      this._form.get('secondTime').clearValidators();
      this._form.get('secondTime').updateValueAndValidity();
    }
  }

  initForm() {
    this.selectediataCodeOrigin = this.cityIataCode;
    this._form = this.fb.group({
      type: ['roundTrip', Validators.required],
      /// firstOrigin: [this.__ori !== undefined ? null : this.__ori],
      firstOrigin: [this.__ori, Validators.compose([Validators.required, CustomValidator.countryValidator])],
      firstDestination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      // pickUp: [null, Validators.required],
      pickUp: ['', Validators.required],
      dropOff: ['', Validators.required],
      // tslint:disable-next-line:max-line-length
      // firstArrivalDate: [null, Validators.compose([Validators.required])],
      firstArrivalDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('secondArrivalDate').value !== null &&
          this._form.get('secondArrivalDate').value !== undefined
        ) {
          const valid = (c.value <= this._form.get('secondArrivalDate').value) ? null : { invalidDate: true };
          this._form.controls['secondArrivalDate'].setErrors(valid);
          return (c.value <= this._form.get('secondArrivalDate').value) ? null : { invalidDate: true };
        }
      }
      ])],
      //firstTime: [this.arrivalDateIn == null ? null : this.arrivalDateIn, Validators.required],
      firstTime: [null, Validators.required],
      firstqtyAdults: [0, [Validators.required, Validators.min(1)]],
      firstqtyChilds: [0, Validators.required],
      firstchildsage: this.fb.array([]),
      // secondArrivalDate: [null, Validators.compose([Validators.required])],
      secondArrivalDate: [null, Validators.compose([Validators.required,
      (c: FormControl) => {
        if (
          this._form !== undefined &&
          c.value !== null &&
          this._form.get('firstArrivalDate').value !== null &&
          this._form.get('firstArrivalDate').value !== undefined
        ) {
          const valid = (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
          this._form.controls['firstArrivalDate'].setErrors(valid);
          return (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
        }
      }])
      ],
      // secondTime: [this.departureDateOut == null ? null : this.departureDateOut, Validators.required],
      secondTime: [null, Validators.required],
    });

  }

  loadinitForm() {
    this.selectediataCodeOrigin = this.cityIataCode;
    this._form = this.fb.group({
      type: ['roundTrip', Validators.required],
      /// firstOrigin: [this.__ori !== undefined ? null : this.__ori],
      firstOrigin: [this.__ori, Validators.compose([Validators.required, CustomValidator.countryValidator])],
      firstDestination: ['', Validators.compose([Validators.required, CustomValidator.countryValidator])],
      // pickUp: [null, Validators.required],
      pickUp: ['', Validators.required],  // this.airportIn
      dropOff: ['', Validators.required],
      // tslint:disable-next-line:max-line-length
      firstArrivalDate: [null, Validators.compose([Validators.required])],
      //  firstArrivalDate: [this.arrivalDateIn == null ? null : this.arrivalDateIn, Validators.compose([Validators.required,   
      // firstTime: [this.arrivalDateIn == null ? null : this.arrivalDateIn, Validators.required],
      firstTime: [null, Validators.required],
      firstqtyAdults: [0, [Validators.required, Validators.min(1)]],
      firstqtyChilds: [0, Validators.required],
      firstchildsage: this.fb.array([]),
      secondArrivalDate: [null, Validators.compose([Validators.required])],
      // secondArrivalDate: [this.departureDateOut == null ? null : this.departureDateOut, Validators.compose([Validators.required,
      // secondTime: [this.departureDateOut == null ? null : this.departureDateOut, Validators.required],
      secondTime: [null, Validators.required],
    });
  }

  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    this.message.splice(0, this.message.length);
    if (this.checkFlight()) {
      this.message.length = 0;
      this.goSearch(form, event, dummy); // llamar al metodo para efectuar la busqueda
    }

    /* if  ((this.idFlight !== undefined)) {
         if (this.checkFlight()) {
             this.goSearch(form, event, dummy); // llamar al metodo ara efectuar la busqueda
         }
     } else {
         this.goSearch(form, event, dummy); // llamar al metodo ara efectuar la busqueda
     }*/
  }


  showError(mensaje: any) {

    // //this.toastr.error(mensaje, 'Error', { toastLife: 150000 });

  }

  checkFlight() {
    let result = true;
    const a = this._form;
    let firstArrivalDate;
    let secondArrivalDate;
    let firstTime;
    let secondTime;
    let arrivalHourIn;
    let arrivalDateIn;
    let departureDateOut;
    let departureHourOut;

    // Transport
    firstArrivalDate = moment(a.value['firstArrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
    firstTime = moment(a.value['firstTime'].toString().split(',')[0]).format('HH:mm');
    let ft = parseInt(firstTime.split(':')[0], 10) * 60 + parseInt(firstTime.split(':')[1], 10);
    secondArrivalDate = moment(a.value['secondArrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
    secondTime = moment(a.value['secondTime'].toString().split(',')[0]).format('HH:mm');
    let st = parseInt(secondTime.split(':')[0], 10) * 60 + parseInt(secondTime.split(':')[1], 10);

    /*if (firstArrivalDate >  secondArrivalDate) {
      result = false;
      // tslint:disable-next-line:max-line-length
   //   this.showError('Error, Enter a lower Pickup In Date to the Pickup OUT: ' + firstArrivalDate);
      this.message.push('Error, Enter a lower Pickup In Date to the Pickup OUT: ' + firstArrivalDate);
    }*/

    if ((ft > st) && (firstArrivalDate === secondArrivalDate)) {
      result = false;
      // tslint:disable-next-line:max-line-length
      //this.showError('Error, Enter a lower Pickup IN time  to the Pickup OUT time ' + secondTime);
      this.message.push('Error, Enter a lower Pickup IN time  to the Pickup OUT time ' + secondTime);
    }
    if ((this.idFlight !== undefined)) {
      arrivalDateIn = moment(this.arrivalDateIn.toString().split(',')[0]).format('YYYY-MM-DD');
      arrivalHourIn = moment(this.arrivalDateIn.toString().split(',')[0]).format('HH:mm');
      const at = parseInt(arrivalHourIn.split(':')[0], 10) * 60 + parseInt(arrivalHourIn.split(':')[1], 10);
      departureDateOut = moment(this.departureDateOut.toString().split(',')[0]).format('YYYY-MM-DD');
      departureHourOut = moment(this.departureDateOut.toString().split(',')[0]).format('HH:mm');
      const _at = parseInt(departureHourOut.split(':')[0], 10) * 60 + parseInt(departureHourOut.split(':')[1], 10);

      if (firstArrivalDate > arrivalDateIn) {
        result = false;
        // tslint:disable-next-line:max-line-length
        // this.showError('Error, Enter a lower Pickup In Date to the Flight Arrival IN: ' + arrivalDateIn);
        this.message.push('Error, Enter a lower Pickup In Date to the Flight Arrival IN: ' + arrivalDateIn);
      }

      ft = ft + 75; // 1.15 hrs
      if ((ft > at) && (firstArrivalDate === arrivalDateIn)) {
        result = false;
        // tslint:disable-next-line:max-line-length
        //  this.showError('Error, Enter a lower Pickup IN time (- 1:45hrs) to the Flight Arrival IN: ' + arrivalHourIn);
        this.message.push('Error, Enter a lower Pickup IN time (- 1:45hrs) to the Flight Arrival IN: ' + arrivalHourIn);
      }

      if (secondArrivalDate > departureDateOut) {
        result = false;
        // tslint:disable-next-line:max-line-length
        // this.showError('Error, Enter a lower Pickup Out Date to the Flight Deaperture IN: ' + departureDateOut);
        this.message.push('Error, Enter a lower Pickup Out Date to the Flight Deaperture IN: ' + departureDateOut);
      }

      st = st + 210; // 1.15 hrs

      if ((st > _at) && (secondArrivalDate === departureDateOut)) {
        result = false;
        // tslint:disable-next-line:max-line-length
        //this.showError('Error, Enter a lower Pickup OUT time (- 3:30hrs) to the Flight Deaperture IN: ' + arrivalHourIn);
        this.message.push('Error, Enter a lower Pickup OUT time (- 3:30hrs) to the Flight Deaperture IN: ' + departureHourOut);
      }
    }
    return result;
  }


  goSearch(form: FormGroup, event?: Event, dummy?: Object) {

    this.originValue = ((this.selectediataCodeOrigin !== undefined) && (this.selectediataCodeOrigin !== null));
    this.destineVale = ((this.selectediataCodeDestiny !== undefined) && (this.selectediataCodeDestiny !== null));

    if ((this.destineVale)) {
      if (event) {
        event.preventDefault();
      }
      this._emitLoading(true);
      const payload = this._payload(form, this.selectediataCodeDestiny, this.selectediataCodeOrigin);
      this._emitOrigin('MULTIDESTINATION');
      this._emitLoading(false);
      this._emitRefModal();
      this.emitReload.emit(payload);
      this.emitIndex.emit(this.__index);
    } else {
      if (!this.originValue) {
        this._form.get('firstOrigin').setErrors({ invalidDestination: true });
      }
      if (!this.destineVale) {
        this._form.get('firstDestination').setErrors({ invalidDestination: true });
      }
    }
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }

  _colocarSearchIdEnlosHoteles(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }
  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = form.value['arrivalDate'];

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = 'No results found for the search';
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
        this.results.emit(arrayResults);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = 'No results found for the search';
      this.results.emit(this._resultsQuery);
    }
  }
  _payload(form: FormGroup, iataCodeDestiny: string, iataCodeOrigin: string): Object {
    const payloadTransport = [];
    const payload = {
      providersId: null,
      categoriesId: [8],
      origin: iataCodeOrigin,
      destination: iataCodeDestiny,
      priceFrom: null,
      priceTo: null,
      arrivalDate: this.getFormattedDate(form.value['firstArrivalDate'], 'date'),
      departureDate: this.getFormattedDate(form.value['secondArrivalDate'], 'date'),
      qtyProduct: 1,
      qtyPax: +form.value['firstqtyAdults'],
      others: {
        maxRequestTimeOnSeconds: 20,
        minNumResults: 3,
        maxNumResultsPerAdapter: "",
        transfers: {
          transferType: 'Regular / Premium',
          pickupTime: this.getFormattedDate(form.value['firstTime'], 'time'),
          // dropoffTime: this.getFormattedDate(form.value['secondTime'], 'time'),
          dropoffTime: form.value['type'] !== 'oneTrip' ? this.getFormattedDate(form.value['secondTime'], 'time') : null,
          pickupLocation: {
            id: this.pickup['id'],
            type: this.pickup['type'],
            providerId: this.pickup['providerId'],
            nameAdapter: this.pickup['nameAdapter']
          },
          dropoffLocation: {
            id: this.dropoff['id'],
            type: this.dropoff['type'],
            providerId: this.dropoff['providerId'],
            nameAdapter: this.dropoff['nameAdapter']
          },
          paxList: {
            adults: +form.value['firstqtyAdults'],
            children: this.getAges(form.value['firstchildsage'])
          },
          vehiclesDist: [
            {
              adults: +form.value['firstqtyAdults'],
              children: this.getAges(form.value['firstchildsage'])
            }
          ],
          oneWay: form.value['type'] !== 'oneTrip' ? false : true
        }
      }
    };
    payloadTransport.push(payload);
    console.log('pay desde MILTIDESTINATION ----->', payloadTransport);
    return payload;
  }

  getAges(ages: Array<any>): any {
    const _ages = [];
    ages.forEach(age => {
      _ages.push(age['age']);
    });
    return _ages;
  }

  getFormattedDate(date: any, format: string): any {
    if (format === 'time') {
      const _date = date ? this.datePipe.transform(date, 'HH:mm') : '13:00';
      return _date;
    } else {
      const _date = date ? this.datePipe.transform(date, 'yyyy-MM-dd') : '2018-05-30';
      return _date;
    }
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  _emitReload() {
    this.emitReload.emit(this._payload);
  }

  _addChild(key: string, key2: string) {
    setTimeout(() => {
      const ages: any = this._form.get(key) as FormArray;
      const qty: number = +this._form.value[key2];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  formArray(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }

  /***********************************************Auto Complete********************************************/
  _myListFormatter(data: any): string {
    return `${data['cityName']} (${data['cityIataCode']})`;
  }
  _myListFormatterAdd(data: any): string {
    return `${data['address']} (${data['type']})`;
  }

  myListFormatterAdd(data: any): string {
    return `${data['nameAirline']} (${data['codeIataCity']})`;
  }

  observableSourceOri = (keyword: any): Observable<any[]> => {
    let type: any;
    const results = this._addressService._getAddress(this.cityIataCode, keyword, 8, type);
    if (results !== undefined) {
      return results;
    }
  }

  observableSourceDest = (keyword: any): Observable<any[]> => {
    let type: any;
    if ((this.pickup.hasOwnProperty('type')) && (this._form.get('pickUp').value !== '')) {
      type = this.pickup['type'];
    }
    const results = this._addressService._getAddress(this.dest['cityIataCode'], keyword, 8, type);
    if (results !== undefined) {
      console.log('results' + results);
      return results;
    }
  }

  _myCallbackPickup($event) {
    if (($event !== null) && ($event instanceof Object)) {
      this.pickup = $event;
    } else {
      this._form.get('pickUp').setErrors({ invalidLocation: true })
    }
  }

  _myCallbackDropoff($event) {
    if (($event !== null) && ($event instanceof Object)) {
      this.dropoff = $event;
      console.log('drop', this.dropoff);
    } else {
      this._form.get('dropOff').setErrors({ invalidLocation: true })
    }
  }

  observableSource = (keyword: any): Observable<any[]> => {
    const results = this.ubicacionesService.getCitys(keyword);
    if (results !== undefined) {
      return results;
    }
  }

  _myCallbackDestiny(data: any): string {
    this.selectediataCodeDestiny = this.dest['cityName'];
    this.selectediataCodeDestiny = this.dest['cityIataCode'];
    // this.nameDestination = this.dest['cityName'];
    // this.iataCodeDestiny = this.dest['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }

  _myCallbackOrigin(data: any): string {
    this.selectediataCodeOrigin = this.ori['cityName'];
    this.selectediataCodeOrigin = this.ori['cityIataCode'];
    // this.nameOrigin = this.ori['cityName'];
    // this.iataCodeOrigin = this.ori['cityIataCode'];
    return `${data['cityName']} (${data['cityIataCode']})`;
  }
  /***********************************************Auto Complete********************************************/

  _over() {
    this.modal.emit('false');
  }

  clickOutside(event: any) {
    this.setPassanger();
  }

  _openModal() {
    this.isCollapsed = true;
    this._close = false;
    setTimeout(() => {
      this.modal.emit('true');
    }, 100);
  }

  _confirm() {
    this.isCollapsed = false;
    this.setPassanger();
  }

  setPassanger() {
    let adults = this._form.value['firstqtyAdults'];
    let childs = this._form.value['firstqtyChilds'];
    let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
    adults = adults != undefined ? adults + " Adults " : null;
    childs = childs != undefined ? " - " + childs + " Childs " : null;

    this.passengers = noSelected ? "Passengers" : adults + childs;
  }
  setResultTab() {

    if (this.resultsMultidestinations.resultsTransport !== undefined) {
      this._results = this.resultsMultidestinations.resultsTransport;
    } else {
      // tslint:disable-next-line:max-line-length
      const payload = this.payloadCategory.payloadTransport !== undefined ? this.payloadCategory.payloadTransport : this.loadPayloadTransport(this._payload);
      this.loadResult('TRANSPORTS', payload);
    }
  }

  loadResult(category: string, payloadArray: any[]) {
    if (payloadArray !== undefined && payloadArray.length > 0) {
      //this.isLoading = true;
      this._results = [];
      this.resultsSearch._setPayload(payloadArray);
      for (let i = 0; i < payloadArray.length; i++) {

        this._restService.doPost('app', 'search', payloadArray[i]).subscribe((response:any) => {
          if (response !== null && response.results !== null) {
            this._setSearchIdInResults(response);
          }
          this._results[i] = response.results;
          this._results[i].qtyPax = payloadArray[i].qtyPax;
          this._results[i].others = payloadArray[i].others;
          this._results[i].destination = payloadArray[i].destination;
          this._results[i].payload = payloadArray[i];
          this._results[i].categoryName = category;

          if (!response.results.length) {
            this._results[i].noResultMessage = 'No results found for the search';
          }

          this.resultsMultidestinations.resultsTransport = this._results;
          // this.isLoading = false;
        }, error => {
          this._results[i] = [];
          this._results[i].noResultMessage = 'No results found for the search';
          // this.isLoading = false;
          console.log(error);
        });
      }
    }
  }

  _setSearchIdInResults(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }

  loadPayloadTransport(pay: any): any[] {
    var payloadTransport = [];
    if (pay !== undefined && pay.length > 0) {
      pay.forEach(element => {
        const pax = this.getPaxList(element);
        const payload = {
          providersId: null,
          categoriesId: [8],
          origin: element.origin,
          destination: element.destination,
          priceFrom: null,
          priceTo: null,
          arrivalDate: element.arrivalDate,
          departureDate: element.departureDate,
          qtyProduct: 1,
          qtyPax: element.qtyPax,
          others: {
            maxRequestTimeOnSeconds: 20,
            minNumResults: 3,
            maxNumResultsPerAdapter: "",
            transfers: {
              transferType: 'Regular / Premium',
              pickupTime: "01:00",
              dropoffTime: "01:00",
              pickupLocation: {
                id: "MCO",
                type: "airport",
                providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                nameAdapter: "Hotel_Beds_Address"
              },
              dropoffLocation: {
                id: "540005",
                type: "hotel",
                providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                nameAdapter: "Hotel_Beds_Address"
              },
              paxList: pax,
              vehiclesDist: [
                pax
              ],
              oneWay: false
            }
          }
        };
        payloadTransport.push(payload);
      });
      this.payloadCategory.payloadTransport = payloadTransport;
    }
    console.log('pay trans', payloadTransport);

    return payloadTransport;
  }

  getPaxList(element: any): any {

    var children = [];
    var adults = 0;
    if (element.others !== undefined && element.others !== null) {
      element.others.hotels.roomConfiguration.forEach(room => {
        adults += parseInt(room.adults);
        children = room.children;
      })
    }
    return { adults, children };
  }

}
