<div id="insurance-result-component">
  <div class="d-flex col-12 m-0 p-0 pos-div">
    <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12 insurance-image">
      <div class="col-12 mb-1 p-0 d-flex justify-content-center">
        <img class="img-insurance" *ngIf="!result?.additionalInfo?.images" src="assets/img/app/logo-{{result.nameAdapter}}.png" alt="insurance">
        <img class="img-insurance" *ngIf="result?.additionalInfo?.images" src="assets/img/app/seguro_default.png" alt="insurance">
      </div>
      <div class="compare d-flex justify-content-center align-items-center">
        <a class="btn-compare" *ngIf="_itemToCompare.length>=1 && showCompareButton()" (click)="_toCompare()">Compare</a>
        <div class="pretty p-round p-thick p-bigger p-icon p-smooth">
          <input type="checkbox" (change)="_addToCompare($event, result)" [disabled]="checkDisbaledCompare()"/>
          <div class="state">
            <i class="icon fa fa-check"></i>
            <label></label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xg-7 col-lg-7 col-md- 5 col-sm-12 insurance-details py-2 pl-2">
      <h4>{{result.title}}</h4>
      <div class="description pt-1 d-flex flex-column">
        <p *ngIf="result.description">{{result.description | truncate : 250 : " "}}
          <a (click)="ModalRooms.show()" class="more">...More</a>
        </p>
        <div *ngIf="!result.description" class="list-benefit d-flex justify-content-center mx-2">
          <div *ngFor="let benefit of result.additionalInfo.benefits; let i = index">
            <div class="flex-columm mx-3 first-benefits" *ngIf="i==0">
                <i class="icon-medical-i">&nbsp;</i>
                <span class="font-small d-flex">Medical</span>
                <span class="font-small-blue">{{benefit.detail}}</span>
            </div>
            <div class="flex-columm mx-2 mt-1 first-benefits" *ngIf="i==1">
                <i class="icon-accidental-i d-flex">&nbsp;</i>
                <span class="font-small">Medical assistance</span>
                <span class="font-small-blue">{{benefit.detail}}</span>
            </div>
            <div class="flex-columm mx-2 mt-1 first-benefits" *ngIf="i==9">
                <i class="icon-dental-i d-flex">&nbsp;</i>
                <span class="font-small">Dental Emergency</span>
                <span class="font-small-blue">{{benefit.detail}}</span>
            </div>
            <div class="flex-columm mx-2 mt-1 first-benefits" *ngIf="i==4">
                <i class="icon-package-i d-flex">&nbsp;</i>
                <span class="font-small">Package</span>
                <span class="font-small-blue ">{{benefit.detail}}</span>
            </div>
            <div class="flex-columm mx-2 mt-1 first-benefits" *ngIf="i==2">
                <i class="icon-preexisting-i d-flex">&nbsp;</i>
                <span class="font-small">Pre-existing disease</span>
                <span class="font-small-blue">{{benefit.detail}}</span>
            </div>

          </div>
        </div>
        <div class="col-xg-3 col-lg-3 col-md-4 m-0 p-0 col-sm-12">
          <div class="col-12 m-1 p-0">
            <img class="img-logo" *ngIf="result?.logo" src="{{result.logo }}" alt="logo">
          </div>
        </div>
        <a *ngIf="!result.description" (click)="ModalRooms.show()" class="more justify-content-end d-flex mt-5">See conditions
          <i class="icon-flecha-despl-i text-center d-flex ml-2">&nbsp;</i></a>
        

      </div>
    </div>
    <div class="col-xg-2 col-lg-2 col-md-3  col-sm-12 flex-column align-items-middle d-flex continue">
      <div class="d-flex justify-content-center mt-auto flex-column text-right">
        <span class="text-price">{{result['additionalInfo']['totalPriceFlag']}}Price</span>
        <div *ngIf="!(result['additionalInfo']['totalPriceProf'] ? true : false)">
            <a class="loader text-center justify-content-center ">
                <i class="fa fa-spinner fa-spin"></i>
            </a>
        </div>
        <span *ngIf="(result['additionalInfo']['totalPriceProf'] ? true : false)"
              class="insurance-price">${{result['additionalInfo']['totalPriceProf'] | number:'1.0-0'}}</span>
        <span class="text-price">P/Person</span>
      </div>
      <div class="d-flex mt-auto py-2">
        <button type="button" (click)="ModalRooms.show()" class="btn btn-usblick-pink justify-content-center w-100 py-1">Add</button>
        <usb-modals bsModal #ModalRooms="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" [modalRoomRef]="ModalRooms"
          [product]="result" [productQty]="1" [form]="form" [origin]="origin" [arrayResults]="arrayResults" [payload]="payload"></usb-modals>
      </div>
    </div>

  </div>
</div>
