import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../../core/route.service';
import { extract } from '../../core/i18n.service';
import { DashboardComponent } from './dashboard.component';
// import { ManagementItineraryComponent } from '../../management-itinerary/management-itinerary.component';
// import { FidelityComponent } from '../../fidelity/fidelity.component';

const routes: Routes =[
  { path: 'dashboard', component: DashboardComponent, data: { title: extract('Dashboard') } }
];

// const rootRoutes: Routes =[
//   {
//     path: 'management/:status_id',
//     component: ManagementItineraryComponent,
//     data: { title: extract('ManagementItinerary') }
//   },
  // {
  //   path: 'fidelity',
  //   component: FidelityComponent,
  //   data: { title: extract('Fidelity') }
  // }
// ];

@NgModule({
  imports: [RouterModule.forChild(routes),
    // RouterModule.forRoot(rootRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  providers: []
})
export class DashboardRoutingModule { }
