import * as startOfDay from 'date-fns';
import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';

export function formatArrivalDate(date: string) {
  return startOfDay.format(startOfDay.parse(date), 'DD MMM YYYY');
}
export function generateArray(length: number = 0): number [] {
  if(!isNaN(length)){
    return Array(parseInt(length.toString())).fill(0);
  } else{
    return Array(0).fill(0);
  }
}

export function isValidOwener(cookieCliente: any): boolean{
  let valid = true;
  let _cookieClient: any = undefined;
  _cookieClient = (cookieCliente != undefined) ?  cookieCliente : undefined;
  if (_cookieClient !== undefined) {
    let data = _cookieClient.split("||");
    if(data[2].trim()=='' || data[2].trim()=='false' || data[4].trim()=='' || data[3].trim()==''){
      valid = false;
    }
  }
  return valid;
}
export function fillOwnerMessage(): string{
  return 'Please fill information about owner!';
}
