import { Component, OnInit, Input, ViewContainerRef, ElementRef, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, AbstractControl, ValidationErrors, FormGroup, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/map";

import * as moment from "moment";
import { Observable } from "rxjs/Observable";
import { RestService } from "../../rest.service";
import { BsDatepickerConfig, BsDatepickerDirective } from "ngx-bootstrap";
import { CustomValidator } from "../../customValidators";
import { ItineraryService } from "../../itinerary.service";
import { RoomConfigurationItem } from "./roomConfigurationItem";
import { searchPayload } from "../../../utils/search-functions";
import { getPayload  } from "../../../utils/search-functions";
import { _getRoomDist  } from "../../../utils/search-functions";
import { NgxSpinnerService } from "ngx-spinner";

import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { MaterialModalConfirmComponent } from "../../material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../material-modal-alert/material-modal-alert.component";
import { MatDialog } from "@angular/material/dialog";
import * as dayjs from "dayjs";
import { DatePipe } from "@angular/common";
import { ModalLoadingComponent } from "../../../shared/modal-loading/modal-loading.component";

@Component({
  selector: "usb-pax-list-modal-booking",
  templateUrl: "./pax-list-modal-booking.component.html",
  styleUrls: ["./pax-list-modal-booking.component.scss"],
  providers: [DatePipe],
})
export class PaxListModalBookingComponent implements OnInit {
  @Input() _close: boolean;
  @Input() item: any;
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() nextTab = new EventEmitter<boolean>();
  @Output() origin = new EventEmitter<any>();
  @Output() modal = new EventEmitter<string>();

  @ViewChild("dpDeparture") datepickerDeparture: BsDatepickerDirective;
  @ViewChild("dpArrival") datepickerArrival: BsDatepickerDirective;

  @ViewChild("destination") myInputField: ElementRef;

  _findData: boolean;
  _resultsQuery: any;
  _form: FormGroup;
  _maxAdults: any;
  _maxChildren: any;
  minDate: dayjs.Dayjs = dayjs().subtract(0, "days");
  maxDate: dayjs.Dayjs = dayjs().add(12, "month");
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  isCollapsed = false;
  validate = false;
  selectedDestiny: any;
  _longDestination: string;
  destiny: any;
  roomsAndPassengers: string;
  minDateDeparture: Date;
  maxDateArrival: Date;
  bsConfig: object;
  dateRange: any;
  isLoading_ = false;
  destinationOptions: Observable<any[]>;
  destinationOptions_: Observable<any[]>;
  modalLoading: any;
  constructor(
    private fb: FormBuilder,
    private _routeActive: ActivatedRoute,
    private _router: Router,
    private _restService: RestService,
    private _itineraryService: ItineraryService,
    public vcr: ViewContainerRef,
    private _sanitizer: DomSanitizer,
    public userService: UserService,
    private dialog: MatDialog,
  private ngxSpinner: NgxSpinnerService,
  ) {
    this._maxAdults = Array.from({ length: 10 }, (_, i) => i + 1);
    this._maxChildren = Array.from(Array(11).keys());
    this.minDateDeparture = new Date();
    this.maxDateArrival = new Date();
    this.dpConfig.containerClass = "theme-red";
  }

  ngOnInit(): void {

    console.log("item", this.item);

   // this.dateRange = { start: '2022-02-14T04:00:00.000Z', end: '2022-02-18T03:59:59.000Z' };
   this.dateRange = { start: this.item.arrivalDate , end:  this.item.departureDate };


    const roomConfiguration = this.item.payloadUpdate.products[0].others.hotels.roomConfiguration;
    console.log("roomConfiguration.length", roomConfiguration.length);

    console.log("roomConfiguration", roomConfiguration);

    const room = [];
    roomConfiguration.forEach((data) => {
      let ageChildrenId = 0;
      const agesChildren = [];
      data.children.forEach((qtyChildren) => {
        agesChildren.push(this.fb.group({ d: ageChildrenId, age: qtyChildren }));
        ageChildrenId++;
      });

      room.push(
        this.fb.group({
          qtyAdults: [data.adults, [Validators.required, Validators.min(1)]],
          qtyChilds: [data.children.length, Validators.required],
          childsage: this.fb.array(agesChildren),
        })
      );
    });

    console.log("roomr", room);
    console.log("item llegando al buscador", this.item);
    this.roomsAndPassengers = "Rooms and passengers";

    this._form = this.fb.group({
      // tslint:disable-next-line:max-line-length
      destination: [this.item.destination.iata_code],
      // tslint:disable-next-line:max-line-length
      arrivalDateDepartureDate: ["", Validators.compose([Validators.required, CustomValidator.countryValidator])],
      room: this.fb.array(room),
      serviceType: [],
      qtyProduct: [1, [Validators.required, Validators.min(1), Validators.max(7)]],
    });

    if (room.length < 1 || !room.length) {
      this._form.get("qtyProduct").setValue(0);
    } else {
      this._form.get("qtyProduct").setValue(room.length);
    }


    this.setRoomAndPassanger();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.myInputField.nativeElement.focus();
    }, 500);
  }

  formRoom(form: FormGroup, key: string) {
    return (<FormArray>form.get(key)).controls;
  }

  _addRoom() {
    const room = this._form.get("room") as FormArray;
    room.push(
      this.fb.group({
        qtyAdults: [1, [Validators.required, Validators.min(1)]],
        qtyChilds: [0, Validators.required],
        childsage: this.fb.array([]),
      })
    );
    if (room.length < 1 || !room.length) {
      this._form.get("qtyProduct").setValue(0);
    } else {
      this._form.get("qtyProduct").setValue(room.length);
    }
  }

  _removeRoom(index: number) {
    const room = this._form.get("room") as FormArray;
    room.removeAt(index);
    this._form.get("qtyProduct").setValue(room.length);
  }


  // realiza la busqueda en el endpoint
  _onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
    const items: any[] = [];
    // this.updateMySelection("");

    items[0] = {
      adult: this.item.paxAdults,
      children: this.item.paxChildren,
      item: this.item,
      product: null,
      categoryName: this.item.categoryName,
    };

    console.log("HOTELS", this.dateRange, form, this.selectedDestiny);
    //this.selectedDestiny = "BUE";
    ///  this.openModalLoading(this.item.payloadUpdate);

    const dateRange = { start: form.value['arrivalDateDepartureDate'].start , end: form.value['arrivalDateDepartureDate'].end};
    const payload = searchPayload('HOTELS', dateRange, form, this.selectedDestiny);
    this._findData = false;
    const roomDist = _getRoomDist(form);
    let qtyAdults: any = 0;
    let qtychildren: any = 0;
    let boardNameCode: any = 0;

    let qtyPax: any = 0;
    let  arrivalDate: any = '';
    let  departureDate: any = '';


    for (let i = 0; i < roomDist.length; i++) {
      // tslint:disable-next-line:radix
      qtyAdults   = Number.parseInt(qtyAdults) +  Number.parseInt(roomDist[i].adults);
      // tslint:disable-next-line:radix
      qtychildren = Number.parseInt(qtychildren) + Number.parseInt(roomDist[i].children.length);

    }
    // tslint:disable-next-line:radix
    qtyPax =  Number.parseInt(qtyAdults) + Number.parseInt(qtychildren);
    console.log("qtyPax",  qtyPax);
    arrivalDate =  form.value['arrivalDateDepartureDate'].start;
    departureDate = form.value['arrivalDateDepartureDate'].end;
    // tslint:disable-next-line:max-line-length
    arrivalDate =   arrivalDate.$d ? moment(arrivalDate.$d.toString().split(',')[0]).format('YYYY-MM-DD') : moment(arrivalDate.toString().split(',')[0]).format('YYYY-MM-DD');
     // tslint:disable-next-line:max-line-length
    departureDate =   departureDate.$d ? moment(departureDate.$d.toString().split(',')[0]).format('YYYY-MM-DD') : moment(departureDate.toString().split(',')[0]).format('YYYY-MM-DD');

    console.log("form end", arrivalDate );
    console.log("form start",   departureDate);
    console.log("item",   this.item);
    this.item.payloadUpdate.products[0].productId = this.item.hotelId;

 //   this.item.payloadUpdate.products[0].providerId = 'HTB192348';

    this.item.payloadUpdate.products[0].arrivalDate = arrivalDate;
    this.item.payloadUpdate.products[0].departureDate = departureDate;
    this.item.payloadUpdate.products[0].qtyPax = qtyPax;
    this.item.payloadUpdate.products[0].qtyProduct = roomDist.length
    roomDist[0].boardNameCode = this.item.boardNameCode;
    roomDist[0].condition = this.item.rateClassId;

    roomDist[0].roomCode = this.item.roomCode;
    this.item.payloadUpdate.products[0].others.hotels.roomConfiguration = roomDist;
    let payload_: any;
    payload_ = {
      search: this.item.payloadUpdate,
      itinerary_id: this.item.itinerary_id,
      product_id: this.item.id,
      category_id: this.item.category_id,
    }
    console.log ("roomDist", roomDist);

    this._findData = false;
        if (payload) {           // getPayload(this.item)
          this.ngxSpinner.show();   ////products
          console.log('/search/products------------------> _onSubmit PaxListModalBookingComponent',   payload_);
          this._restService.doPost("app", "search/products", payload_).subscribe(
          (response: any) => {
            if (response && response.status && response.results) {
              this._findData = true;
              if (response.results[0] !== undefined) {
                console.log("item response", response.results[0]);
                items[0].product = response.results[0];
              } else {
                console.log("item response", response.results);
                items[0].product = response.results;
              }
              this.ngxSpinner.hide();
             // this.results.emit(items);
              // this.nextTab.emit(true);
            }
          },
          (error) => {
            console.log(error.message);
          }
      );
    }
  }

  /// modal loading
  openModalLoading(data: any) {
    this.modalLoading = this.dialog.open(ModalLoadingComponent, {
      width: "500px",
      id: "loadModal",
      data: data,
      disableClose: true,
    });

    /* dialogRef.afterClosed().subscribe(result => {
    });*/
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }
  _colocarSearchIdEnlosHoteles(response: any) {
    response.results.forEach((obj) => {
      obj.searchId = response.searchId;
    });
  }
  _setArrivalAndDepartureDate(response: any, form) {
    response.results.forEach((obj) => {
      obj.dateIni = moment(this.dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD");
      obj.dateEnd = moment(this.dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD");
    });
  }
  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = moment(this.dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD"); // form.value['arrivalDate'];
        this._resultsQuery.destination = this._longDestination;

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = "No results found for the search";
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
        this.results.emit(arrayResults);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = "No results found for the search";
      this.results.emit(this._resultsQuery);
    }
  }
  _confirm() {
    this.isCollapsed = false;
    this.validate = true;
    this.setRoomAndPassanger();
  }
  _openModal() {
    this.isCollapsed = true;
    this.validate = false;
    this._close = false;
    setTimeout(() => {
      this.modal.emit("true");
    }, 100);
  }
  _over() {
    this.modal.emit("false");
  }
  clickOutside(event: any) {
    this.setRoomAndPassanger();
  }

  _getRoomDist(form?: FormGroup) {
    const roomDist = [];
    const rooms = form.value.room;
    for (let i = 0; i < rooms.length; i++) {
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: []
      };
      const adults = rooms[i].qtyAdults;
      const children = rooms[i].childsage;
      for (let j = 0; j < children.length; j++) {
        people.push(children[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);
    }
    return roomDist;
  }

  _getAdultsChildrenQty(key: string, room: Array<any>) {
    let qty = 0;
    key === "qtyAdults"
      ? room.map((obj) => obj.qtyAdults).forEach((element) => (qty += +element))
      : room.map((obj) => obj.qtyChilds).forEach((element) => (qty += +element));
    return qty;
  }

  _addChild(item: FormGroup) {
    setTimeout(() => {
      const ages: any = item.get("childsage") as FormArray;
      const qty: number = +item.value["qtyChilds"];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(
            this.fb.group({
              id: [i],
              age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
            })
          );
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }
  compareDate(event: any) {
    const getArrivalDate = moment(event.toString().split(",")[0]).format("YYYY-MM-DD");
    const getDepartureDate = moment(event.toString().split(",")[1]).format("YYYY-MM-DD");
    if (getArrivalDate === getDepartureDate) {
      return false;
    }
  }

  /***********************************************Auto Complete********************************************/
  _myListFormatter = (data: any) => {
    /*  const html = `
                      <div style='font-size:15px;cursor:pointer;line-height:2.1;text-transform: capitalize; padding-left:10px;color:#5cc999; border:none '>
                        ${ this.capitalize(data['cityName'])}
                         <span style=' font-weight: 500;font-size:14px;color:#464646'>
                         , Estados Unidos
                           </span>
                      </div>
                      `;
        return this._sanitizer.bypassSecurityTrustHtml(html); */
  };

  capitalize(val: any) {
    return val
      .toLowerCase()
      .trim()
      .split(" ")
      .map((v) => v[0].toUpperCase() + v.substr(1))
      .join(" ");
  }

  observableSource = (keyword: any): Observable<any[]> => {
    return;
  };

  displayFn(name: any) {
    if (name) {
      if (name.cityName != undefined) {
        return name.cityName;
      }
      if (name.nameAirport != undefined) {
        return name.nameAirport;
      }
    }
  }

  updateMySelection(event: any) {
    /* if (event) {
      this.destiny = event.cityName ? event.cityName : "";
      this.selectedDestiny = event.cityIataCode ? event.cityIataCode : "";
      this._longDestination = event.cityName ? event.cityName : "";
      this.selectedDestiny = event.cityIataCode ? event.cityIataCode : "";
    } */
    this.destiny = "Miami";
    this.selectedDestiny = "MIA";
    this._longDestination = "Miami";
    this.selectedDestiny = "MIA";
  }

  /***********************************************Auto Complete********************************************/

  setRoomAndPassanger() {
    let rooms = this._form.value["room"].length.toString();
    let adults = this._getAdultsChildrenQty("qtyAdults", this._form.value["room"]).toString();
    let childs = this._getAdultsChildrenQty("qtyChilds", this._form.value["room"]).toString();
    let noSelected = parseInt(adults) === 0 && parseInt(childs) === 0;
    rooms = rooms != undefined ? rooms + " Rooms " : null;
    adults = adults != undefined ? " - " + adults + " Adults " : null;
    childs = childs != undefined ? " - " + childs + " Childs " : null;

    this.roomsAndPassengers = noSelected ? "Rooms and passengers" : rooms + adults + childs;
  }

  async setDepartureDate(event: any) {
    console.log(event);

    if (event !== null) {
      this.minDateDeparture = await new Date(event);
      await this.setOptions("departure");
      //this.datepickerDeparture.toggle();

      setTimeout(() => {
        this.datepickerDeparture.toggle();
      }, 200);
      return;
    }
    //this.minDateDeparture = new Date();
  }

  setArrivalDate(event: any) {
    if (event !== null) {
      this.maxDateArrival = new Date(event);
      this.setOptions("arrival");
      return;
    }
    //this.maxDateArrival = new Date();
  }

  setOptions(tipo: String): void {
    if (tipo != "departure") {
      this.bsConfig = Object.assign({}, { maxDate: this.maxDateArrival });
      //this.datepickerArrival.setConfig();
      return;
    }

    this.bsConfig = Object.assign({}, { minDate: this.minDateDeparture });
    //this.datepickerDeparture.setConfig();
  }

}
