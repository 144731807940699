import { Injectable } from '@angular/core';

@Injectable()
export class ResultsSearchService {
  _payload: any;
  _response: any[];
  _origin: string;

  constructor() { }

  _setPayload(payload: any) {
    this._payload = payload;
  }

  _setResponse(response: any, manyLocations?: any) {
    this._response = response;
  }

  _getPayload() {
    return this._payload;
  }

  _getResponse() {
    return this._response;
  }

  _setOrigin(origin: any) {
    this._origin = origin;
  }
  _getOrigin() {
    return this._origin;
  }
}
