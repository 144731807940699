import { Injectable } from '@angular/core';

@Injectable()
export class ActivitiesService {

    public getResults(arrivalDate: any, destination: string, origin: string): Array<any> {
        const results = [
            {
                'status': true,
                'message': 'Results were found',
                'totalRegs': 2,
                'searchId': '20180218_4_2-4_1_9_f1bbf728eaf44cc78d75451a9adb7c4e_',
                'results': [
                    {
                        'providerId': 'c38d4060-f019-11e7-8204-9136922233a2',
                        'providerName': 'Nombre Proveedor',
                        'id': '103dd0',
                        'title': 'Service\'s name',
                        'description': 'Service\'s description and extras.',
                        'categoryId': 1,
                        'productId': '103dd0_138507680080218_4_2-4_1_9_f1bbf728eaf44cc78d75451a9adb7c4e_',
                        'origin': null,
                        'destination': 'YXU',
                        'dateIni': arrivalDate,
                        'dateEnd': '2018-04-29',
                        'serviceResponse': null,
                        'nameAdapter': 'Hotel_Pro',
                        'additionalInfo': {
                            'location': 'USA - ORLANDO(FLORIDA)',
                            'images': [
                                {
                                    'description': 'image_',
                                    'image': 'http://www.dominio.com/imagen/hoteles/hotel1866_4.jpg',
                                    'thumbnail': 'http://www.dominio.com/imagen/hoteles/hotel1866_4th.jpg'
                                }
                            ],
                            'specialNotes': [
                                {
                                    'note': 'Resort Package Fee Mandatorio: $28.00 más impuestos, por habitación.....',
                                    'from': '2016-06-02T00:00:00',
                                    'to': '2019-04-30T00:00:00'
                                }
                            ],
                            'tours': {
                                'company': 'BodyGloves',
                                'ageChildrens': {
                                    ageFrom: 4,
                                    ageTo: 12
                                },
                                'options': [
                                    {
                                        'optionId': 'ASFG124',
                                        'name': 'Tour General',
                                        'prices': {
                                            'adult': 30.00,
                                            'child': 20.00
                                        },
                                        'availableDates': [
                                            '2016-04-01',
                                            '2016-04-02'
                                        ],
                                        'duration': '2 days',
                                        'totalPrice': 130.00
                                    },
                                    {
                                        'optionId': 'ASFG122',
                                        'name': 'Tour General',
                                        'prices': {
                                            'adult': 30.00,
                                            'child': 20.00
                                        },
                                        'availableDates': [
                                            '2016-04-01',
                                            '2016-04-02'
                                        ],
                                        'duration': '2 days',
                                        'totalPrice': 130.00
                                    }
                                ]
                            }
                        }
                    }
                ]
            }];
        return results;
    }
}
