


export interface ClientData {

  typeAction: string;

  mainClient?: any;
  relationId?: number;

  checkboxChecked?: boolean;
  client_associated_id: any;

  assosiateClient?: any;
  index?: any;
  fromAssociates?: boolean;

  states: any[];

  type: string;
  clients: any[];
  clientAssociatedArr: any[];
  clientAssociated: boolean;

  shildClients: any;
  client: any;
  clientsAssociatedArr: any[];
  relationShips: any;
  countriesData: any;
  arrayAssociated: any;
  clientSelectedForm: boolean;
  styleFormDisable: boolean;

}

export const clientData: ClientData = {

typeAction: '',

 mainClient: null,
 relationId: -1,
 checkboxChecked: false,
 client_associated_id: null,
 assosiateClient: null,
 index: null,
 fromAssociates: false,
 states: [],
 clients: [],
 clientAssociatedArr: [],
 type: null,
 clientAssociated: false,
 shildClients: [],
 client: null,
 clientsAssociatedArr: [],
 relationShips: [],
 countriesData: [],
 arrayAssociated: null,
 clientSelectedForm: false,
 styleFormDisable: false
}
