import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgModule } from '@angular/core';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ContentResultRoutingModule } from './content-result-routing.module';
import { ContentResultComponent } from './content-result.component';
import { CategoryResultComponent } from './category-result/category-result.component';
import { VisitedComponent } from './visited/visited.component';
////import { SharedModule } from '../shared/shared.module';
import { ResultsSearchService } from '../home/results-search.service';
import { HotelResultComponent } from './category-result/result/hotel-result/hotel-result.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule, TabsModule, BsDropdownModule, AccordionModule } from 'ngx-bootstrap';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { ActivitiesResultComponent } from './category-result/result/activities-result/activities-result.component';
import { TransportResultComponent } from './category-result/result/transport-result/transport-result.component';
import { SummaryResultComponent } from './category-result/result/summary-result/summary-result.component';
import { DisneyResultComponent } from './category-result/result/disney-result/disney-result.component';
import { PThemesResultComponent } from './category-result/result/p-themes-result/p-themes-result.component';
import { RentACarResultComponent } from './category-result/result/rent-a-car-result/rent-a-car-result.component';
import { TruncateModule } from 'ng2-truncate';
import { CruisesResultComponent } from './category-result/result/cruises-result/cruises-result.component';
import { PackagesResultComponent } from './category-result/result/packages-result/packages-result.component';
import { InsuranceResultComponent } from './category-result/result/insurance-result/insurance-result.component';
import { ItinerarySummaryModule } from '../itinerary-summary/itinerary-summary.module';
//import { LoadingModule } from 'ngx-loading';
import { configLoading } from '../shared/config.loading';
import { CompareModalComponent } from '../shared/compare-modal/compare-modal.component';
//import { NgxLoadingModule } from 'ngx-loading';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatSliderModule} from '@angular/material/slider';
import {NgxSliderModule } from '@angular-slider/ngx-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    imports: [
        MatSliderModule,
        NgxSliderModule,
        CommonModule,
        TranslateModule,
        ContentResultRoutingModule,
        NgxPaginationModule,
        ModalModule.forRoot(),
        CarouselModule,
        TabsModule.forRoot(),
        //BsDropdownModule.forRoot(),
        ItinerarySummaryModule,

        BrowserAnimationsModule,
        //NgxChartsModule,
        FormsModule,
        TruncateModule,
        //NgxLoadingModule.forRoot(configLoading),
        AccordionModule.forRoot(),
        //NgbModule.forRoot(),
        NgxSpinnerModule,
        SharedModule,
        MatCheckboxModule,
        MatIconModule,
        ScrollingModule,
        AngularSvgIconModule.forRoot()
     //   InfiniteScrollModule
    ],
    declarations: [
        ContentResultComponent,
        CategoryResultComponent,
        HotelResultComponent,
        VisitedComponent,
        ActivitiesResultComponent,
        TransportResultComponent,
        SummaryResultComponent,
        DisneyResultComponent,
        PThemesResultComponent,
        RentACarResultComponent,
        CruisesResultComponent,
        PackagesResultComponent,
        InsuranceResultComponent,
        CompareModalComponent,

    ],

    providers: [
        ResultsSearchService,

    ]
})
export class ContentResultModule { }
