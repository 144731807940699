import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../../shared/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ItineraryService } from "../../../shared/itinerary.service";

@Component({
  selector: 'usb-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss']
})
export class ConfirmBookingComponent implements OnInit {
  reservation: any = [];
  reservationId: any;
  @Input() item: any;
  @Input() roomSelect: any;
  @Input() paxesInItems: any;
  

  constructor(
    private _restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private itineraryService: ItineraryService,
  ) { }

  ngOnInit() {
    console.log('ConfirmBookingComponent item', this.item);
    console.log('ConfirmBookingComponent roomSelect', this.roomSelect);
    console.log('ConfirmBookingComponent paxesInItems', this.paxesInItems);

    this.route.queryParams.subscribe(params => {
      this.reservationId = (params["reservationId"]) ? params["reservationId"] : '1';
    });
  }

  subtractDays(date){
    return moment(moment(date)).subtract(2, 'days').format('YYYY-MM-DD');
  }
}
