import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'itinerary-list',
  templateUrl: './itinerary-list.component.html',
  styleUrls: ['./itinerary-list.component.scss']
})
export class ItineraryListComponent implements OnInit {
  @Input() data: any;

  ngOnInit() {

  }
}
