  <div id="hotel-result-component" class="d-flex justify-content-between" *ngIf="!mapView">
    <div class="d-flex container-img">
      <img style="width: -webkit-fill-available" src="{{result?.additionalInfo?.cover_image}}"
      onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
      <!-- images[0]?.image -->
    </div>

    <div class="d-flex flex-column container-detalles justify-content-between p-3">
      <div class="d-flex justify-content-between">
        <div class="d-flex" style="width: calc(100% - 225px); overflow: hidden;">
          <!-- [attr.title]="showMoreButton ? result.title : result.title.slice(0, 25)" -->
          <p class="title m-0" >
            <!-- showMoreButton ? result.title : result.title.slice(0, 25) -->
            {{result.title}}
            <!-- <span *ngIf="result.title.length > 25 && !showMoreButton" (click)="showMoreButton = true"
              style="font-size: 14px;color: #00d096; font-weight: bold; font-family: 'Lato'; cursor: pointer;">...more</span>
            <span *ngIf="showMoreButton" (click)="showMoreButton = false"
              style="font-size: 14px;color: #d00000; font-weight: bold; font-family: 'Lato'; cursor: pointer">...less</span> -->
          </p>
        </div>
        <div class="d-flex justify-content-end" style="width: 225px;">
          <div style="margin-top: -16px; margin-right: 10px;" class="custom-tooltip-aux" title="Comentarios">
            <img src="../../../../../assets/img/app/itinerary/learning.png"  alt="">
          </div>
          <div style="margin-top: -16px; margin-right: 10px;" class="custom-tooltip-aux" title="Learner">
            <img src="../../../../../assets/img/app/itinerary/chat.png"  alt="">
          </div>

          <div class="d-flex content-icons px-1">
            <div class="d-flex">
              <i class="icon icon-view mr-1"></i>
            </div>
            <div class="d-flex font-view px-1">
              <i class="m-0" style="line-height: 1;">{{ view_hotel }} <br> views</i>
            </div>
          </div>
          <div class="d-flex content-icons px-1">
            <!-- result.video_ecomerce -->
            <i class="icon icon-video-1 custom-tooltip" style="cursor: pointer;" title="Video comercial" (click)="openVideoModal(result.video_ecomerce)"></i>
          </div>
          <div class="d-flex content-icons px-1">
            <!-- result.video_institut -->
            <i class="icon icon-video-2 custom-tooltip" title="Video institucional" style="cursor: pointer; margin-right: 30px;" (click)="openVideoModal(result.video_institut)"></i>
          </div>
          <!-- <div class="d-flex content-icons pl-1">
            <button class="numberDay mr-1"> {{ result.totaldays }}</button>
            <span class="font-days pl-0"> DAYS</span>
          </div> -->
          <!-- <div>
            <svg-icon src="../../../../../assets/img/app/cancel-subscription.svg" style="fill: #e80051; cursor: pointer;"></svg-icon>
          </div> -->
        </div>
      </div>
      <div class="d-flex" style="margin-top: 0px;">
        <i class="icon-placeholder" aria-hidden="true"></i>
        <p class="location" style="margin-left: 5px;">{{result.additionalInfo?.location}}</p>
      </div>
      <div class="d-flex">
        <div class="description pt-1 d-flex flex-column">
          <p>{{result.description | truncate : 130 : "  "}} <a (click)="openModal(ModalRooms, 'info');" style="cursor: pointer;"
              class="more">...more</a> </p>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <div *ngIf="ameninitiesResul.length > 0">
          <!-- <div class="d-flex align-items-center px-2 includes">
          <p class="m-0"> INCLUDED</p>
        </div>
      -->
          <div class="d-flex flex-row">
            <div *ngFor="let amenitie of ameninitiesResul">
              <div class="d-flex">
                <img class="i-result-included" [src]="amenitie.image" [alt]="amenitie.name">
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row">
          <!-- <span class="font-desc" style=" font-family: 'Lato', sans-serif  !important;">15</span>
        <i class="icon icon-descuento"></i>-->
        </div>
        <!--- <div class="d-flex flex-row" *ngIf="result?.additionalInfo?.rooms[0]?.rateClass === 'NRF' || result?.additionalInfo?.rooms[0]?.rateClass === 'NOR'" >
       <span class="description-modal" aria-hidden="true"   >
          <i class="fa fa-check"></i> NO REEMBOLSABLE
        </span>
      </div>-->
        <!-- <div class="d-flex flex-row"> -->
        <!-- <span class="description-modal" aria-hidden="true">
           <i class="fa fa-check"></i> {{result?.additionalInfo?.rooms[0]?.rateClass }}
         </span> -->
        <!-- <span class="description-modal" aria-hidden="true"  *ngIf="result?.additionalInfo?.rooms[0]?.rateClass === 'NR'">
            <i class="fa fa-check"></i> NO REEMBOLSABLE
          </span>
        </div> -->
        <!-- <div class="flex-column justify-content-end py-1" *ngIf="result?.additionalInfo?.rooms[0]?.rateClass == 'R'" >
            <div class="d-flex align-items-middle">
              <span class="d-flex font-poppins f-12 text-right m-0" style="font-size: 12px;">
                Cancelación free Hasta
              </span>
            </div>
            <div class="d-flex align-items-middle">
              <span class="red d-flex font-poppins f-12 text-right m-0 font-weight-bold"
              style="font-size: 12px; padding-left: 71px;" *ngIf="result?.additionalInfo?.rooms[0]?.cancellation_policy.from">
               {{ subtractDays(result?.additionalInfo?.rooms[0]?.cancellation_policy.from) | date:'dd/mm/yyyy h:m'  }}
              </span>
            </div>
        </div> -->
      </div>
      <div class="d-flex justify-content-between" style="height: 30px;">
        <div>
          <img class="img-logo" *ngIf="result?.logo " src="{{result?.logo}}" alt="logo">
        </div>
        <div class="d-flex align-items-middle">
          <img src="../../../../../assets/img/app/little-icons/badge-bks.png" alt="" style="margin-right: 5px;">
          <span class="font-point d-flex align-items-center">
            {{ result?.additionalInfo?.rooms[0]?.room?.blickoins | number:'1.2-2' }}&nbsp;Bks
          </span>
          <span class="font-point d-flex align-items-center">
            <p class="p-night m-0 pl-2">$&nbsp;{{ result?.additionalInfo?.rooms[0]?.room?.creditBlickoins | number:'1.2-2' }}</p>
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column container-price">
      <div class="d-flex flex-column px-3" style="margin-left: 10px; position: relative;">
        <div>
          <p class="fromPrice" style="margin-bottom: 0px;margin-top: 15px;height: 18px;">Desde</p>
        </div>
        <div *ngIf="result?.additionalInfo?.rooms" >
           <p [ngClass]="{'priceMoney':countValueDot <= 4, 'count-dot-value': countValueDot > 4}" style="margin-bottom: 0px;height: 55px;">{{result?.additionalInfo?.rooms[0]?.room?.pricing?.currency}}
            <span>{{  result?.additionalInfo?.rooms[0]?.room?.optionNightsNetTotalProf | number:'1.2-2'}}</span></p>
          </div>
        <div>
             <p class="p-night" style="margin-bottom: 25px;">Total Estadia</p>
             <p style="font-size: 11px; font-weight: 600; position: absolute; bottom: -4px;">Imp. incluidos</p>

        </div>
      </div>
      <div class="d-flex justify-content-center" style="margin-bottom: 12px" *ngIf="result?.additionalInfo?.rooms[0]?.room?.rateClass == 'R'">
        <span class="bg-icon-cg">
          <p class="icon-cg">CG</p>
        </span>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <span class="description-modal-cg" aria-hidden="true">
              REEMBOLSABLE
            </span>
            <span class="sub-decription-modal-cg">Hasta
              <span class="red font-poppins  text-right m-0 font-weight-bold" style="font-size:9px;" *ngIf="result?.additionalInfo?.rooms[0]?.cancellationPolicy[0]?.from">
              {{ result?.additionalInfo?.rooms[0]?.cancellationPolicy[0].from | date:'dd/MM/yyyy' }}
            </span>
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center" style="margin-bottom: 12px" *ngIf="result?.additionalInfo?.rooms[0]?.room?.rateClass === 'NR'">
        <span class="bg-icon-nr">
          <p class="icon-nr">NR</p>
        </span>
        <div class="d-flex flex-row">
          <span class="description-modal-nr" aria-hidden="true">
            NO REEMBOLSABLE
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" (click)="openModal(ModalRooms,'rooms');" [disabled]="result?.additionalInfo?.rooms[0]?.room?.optionNightsNetTotalProf === 0" class="btn btn-add" style="border-radius: 50rem">AGREGAR</button>
      </div>
    </div>
  </div>

  <!-- Result hotes - map view -->
  <ng-container *ngIf="mapView">
  <div id="hotel-result-component-mapview"
       class="d-flex justify-content-between"
       style="cursor: pointer;"
       (click)="getHotelChoiceID(result)"
        >
    <div class="d-flex container-img" style="width:35% !important;" >
      <img class="img-hotel-view-map img-fluid" *ngIf="result?.additionalInfo?.images && result?.additionalInfo?.images[0]"
        src="{{result.additionalInfo?.images[0].image}}" alt="hotel">
      <img class="img-hotel-view-map img-fluid " *ngIf="!result?.additionalInfo?.images" src="assets/img/app/hotel_default.png" alt="hotel">
    </div>
    <div class="d-flex flex-column container-detalles-map-view justify-content-between p-3" style="width: 65% !important;">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <p class="title-onMap m-0" [attr.title]="result.title">{{result.title}}</p>
        </div>
        <div class="d-flex">
        <span class="material-icons add-icon" [ngStyle]="{'color': (addIcon==='add_circle') ? 'black': 'green'}"
          (mouseover)="addIcon='add_circle'" (mouseout)="addIcon='add_circle'" (click)="openModal(ModalRooms,'rooms');">
          {{addIcon}}
        </span>
      </div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex" *ngIf="result.additionalInfo?.rooms">
          <div *ngIf="result.additionalInfo?.rooms?.length > 0">
            <a class="loader text-center justify-content-center" *ngIf="!result.additionalInfo?.rooms[0]?.room?.netPriceProf ">
              <i class="fa fa-spinner fa-spin"></i>
            </a>
          </div>
          <p class="m-0 price-onMap">{{result?.additionalInfo?.rooms[0]?.room?.pricing?.currency}} <span>{{ result?.additionalInfo?.rooms[0]?.room?.netPriceProf | number:'1.2-2'}}</span></p>
        </div>

        <div class="d-flex" *ngIf="result?.additionalInfo?.roomsPackage">
          <div *ngIf="result?.additionalInfo?.roomsPackage?.length > 0">
            <a class="loader text-center justify-content-center" *ngIf="!result?.additionalInfo?.roomsPackage[0]?.room?.netPriceProf">
              <i class="fa fa-spinner fa-spin"></i>
            </a>
          </div>
          <p class="m-0 priceMoney">USD <span>{{ result?.additionalInfo?.roomsPackage[0]?.room?.netPriceProf | number:'1.0-0'}}</span></p>
        </div>

        <div class="d-flex flex-column">
          <!-- <p class="m-0" style="font-size:10px; color: red;  font-weight: bold;">P. Costo:&nbsp;{{result?.additionalInfo?.rooms[0]?.optionNightsNetTotal | number:'1.2-2'}}</p>-->
          <p class="p-night m-0" style="font-size: 13px !important; font-weight: 600;">P/Night</p>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-middle">
          <i class="icon icon-medal mr-1"></i>
          <span class="font-point d-flex align-items-center">
            {{result?.additionalInfo?.rooms?.length >0 ? result?.additionalInfo?.rooms[0]?.room?.blickoins : "X" }}&nbsp;BKS
          </span>
          <span class="font-point d-flex align-items-center">
            <p class="p-night m-0 pl-2">
              USD&nbsp;{{result?.additionalInfo?.rooms?.length >0 ? result?.additionalInfo?.rooms[0]?.room?.creditBlickoins : "X" }}</p>
          </span>
        </div>
        <div class="d-flex flex-row">
          <!-- <span class="font-desc" style=" font-family: 'Lato', sans-serif  !important;">15</span>
          <i class="icon icon-descuento"></i> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
