<div class="modal-content" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div id="modalrooms">
    <!-- header modal start -->
    <div class="row">
      <div class="col-12">
        <p class="title-modal font-opensans m-0">{{stingUpperCase(packages?.title)}}</p>
      </div>
      <div class="col-12">
        <div class="d-flex flex-row">
          <i class="icon-placeholder-green"></i>
          <p class="font-location font-poppins mb-0">
            {{twoLines(packages?.additionalInfo?.location)[0]}}
            <br>
            {{twoLines(packages?.additionalInfo?.location)[1]}}
          </p>
          <!-- USA - ORLANDO <br> UNIVERSAL STUDIOS AREA(FLORIDA) -->
        </div>
      </div>
    </div>
    <!-- header modal end -->

    <!-- nav-tab modal start -->
    <div class="row mt-3">
      <div class="col-12">
        <ul class="nav justify-content-between">
          <li [ngClass]="{'nav-pills-active': tab=='#itinerary'}" class="nav-pills font-poppins"
            (click)="tabs('#itinerary')">
            ITINERARY</li>
          <li [ngClass]="{'nav-pills-active': tab=='#rooms'}" class="nav-pills font-poppins" (click)="tabs('#rooms')">
            HOTELS</li>
          <li [ngClass]="{'nav-pills-active': tab=='#book'}" class="nav-pills font-poppins" (click)="tabs('#book')">
            BOOK</li>
        </ul>
      </div>
    </div>
    <!-- nav-tab modal end -->

    <!-- content-tabs start -->
    <div class="row">
      <!-- itinerary -->
      <div class="col-12 mt-3" id="itinerary"
        [ngClass]="{'d-block' : tab == '#itinerary', 'd-none' : tab !== '#itinerary'}">
        <!-- list itinerary  -->
        <div class="d-flex flex-row items-itinerary">
          <div class="d-flex flex-column date-itinerary justify-content-between align-items-center">
            <div class="content-date d-flex flex-column align-items-center justify-content-center">
              <p class="day font-opensans m-0">16</p>
              <p class="month font-opensans m-0">jun</p>
            </div>
            <div class="d-flex timeline"></div>
          </div>
          <div class="d-flex flex-column desc-itinerary">
            <p class="head-desc font-opensans"><span class="day-desc">DAY 1</span> CARTAGENA DE INDIAS - HOTEL, CITY
              TOUR , SALIDA NOCHE</p>
            <p class="text-desc font-opensans m-0">Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido
              el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona
              que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró
              hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de
              relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s
              con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
        </div>
        <div class="d-flex flex-row items-itinerary">
          <div class="d-flex flex-column date-itinerary justify-content-between align-items-center">
            <div class="content-date d-flex flex-column align-items-center justify-content-center">
              <p class="day font-opensans m-0">17</p>
              <p class="month font-opensans m-0">jun</p>
            </div>
            <div class="d-flex timeline"></div>
          </div>
          <div class="d-flex flex-column desc-itinerary">
            <p class="head-desc font-opensans"><span class="day-desc">DAY 2</span> CARTAGENA DE INDIAS - PLAYAS </p>
            <p class="text-desc font-opensans m-0">Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido
              el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona
              que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró
              hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de
              relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s
              con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más.
            </p>
          </div>
        </div>
        <div class="d-flex flex-row items-itinerary">
          <div class="d-flex flex-column date-itinerary justify-content-between align-items-center">
            <div class="content-date d-flex flex-column align-items-center justify-content-center">
              <p class="day font-opensans m-0">18</p>
              <p class="month font-opensans m-0">jun</p>
            </div>
            <div class="d-flex timeline"></div>
          </div>
          <div class="d-flex flex-column desc-itinerary">
            <p class="head-desc font-opensans"><span class="day-desc">DAY 3</span> SAN ANDRÉS - PLAYAS </p>
            <p class="text-desc font-opensans m-0">Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido
              el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona
              que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró
              hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de
              relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s
              con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más.
            </p>
          </div>
        </div>
        <div class="d-flex flex-row items-itinerary">
          <div class="d-flex flex-column date-itinerary justify-content-between align-items-center">
            <div class="content-date d-flex flex-column align-items-center justify-content-center">
              <p class="day font-opensans m-0">19</p>
              <p class="month font-opensans m-0">jun</p>
            </div>
            <div class="d-flex timeline" style="display: none !important;"></div>
          </div>
          <div class="d-flex flex-column desc-itinerary">
            <p class="head-desc font-opensans"><span class="day-desc">DAY 4</span> SAN ANDRÉS - PLAYAS - TOUR ISLA </p>
            <p class="text-desc font-opensans m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
              repellat mollitia dolorem doloremque fuga quae. Aut sit hic similique velit beatae veritatis aliquid quos
              placeat consequatur ducimus, maiores culpa magni voluptates. Aut amet alias fugit libero sint iusto
              voluptas laboriosam velit maiores nesciunt atque quibusdam quod excepturi voluptatem, ipsa maxime laborum
              odit architecto aperiam repudiandae accusamus ducimus voluptatum a eius? Enim, fuga eum. Laudantium hic
              nostrum aspernatur id placeat minima nemo, officia ab consectetur excepturi, cupiditate tenetur delectus
              laborum magnam nihil, harum error expedita repellat beatae! Iusto tenetur repellat repudiandae culpa
              beatae optio necessitatibus, quo, reprehenderit minima dignissimos ut quidem voluptate facere magni vitae,
              error ad obcaecati reiciendis iste. Explicabo.</p>
          </div>
        </div>

      </div>
      <!-- hotels -->
      <div class="col-12 mt-3" id="rooms" [ngClass]="{'d-block' : tab == '#rooms', 'd-none' : tab !== '#rooms'}">
        <div class="row mb-2">
          <div class="col-12">
            <ul class="nav justify-content-between subnav">
              <li [ngClass]="{'subnav-pills-active': subtab=='#standard'}" class="subnav-pills col-4 font-poppins"
                (click)="subtabs('#standard')">SAN ANDRES</li>
              <li [ngClass]="{'subnav-pills-active': subtab=='#mega'}" class="subnav-pills col-4 font-poppins"
                (click)="subtabs('#mega')">CARTAGENA</li>
              <li [ngClass]="{'subnav-pills-active': subtab=='#nkd'}" class="subnav-pills col-4 font-poppins"
                (click)="subtabs('#nkd')">BOGOTA</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-12" [ngClass]="{'d-block' : subtab == '#standard', 'd-none' : subtab !== '#standard'}"
            id="standard">
            SAN ANDRES
          </div>
          <div class="col-12" [ngClass]="{'d-block' : subtab == '#mega', 'd-none' : subtab !== '#mega'}" id="mega">
            <div class="row">
              <div class="col-12">
                <div class="item-room d-flex flex-column mb-3" *ngFor="let collapse of collapseList; index as i">
                  <div class="d-flex flex-row" style="height: 150px !important; overflow: hidden;">
                    <div class="image-hotel">
                      <img [src]="imgPortada[i]" [alt]="imgPortada[i]">
                    </div>
                    <div class="hotel-name p-2">
                      <div class="row">
                        <div class="col-12">
                          <p class="data-item font-lato m-0 p-0">Cartagena de Indias</p>
                        </div>
                        <div class="col-12">
                          <p class="title-hotel font-opensans m-0 p-0">HOTEL ESTELAR</p>
                        </div>
                        <div class="col-12">
                          <!-- <bar-rating [(rate)]="rate" [max]="6" [theme]="'fontawesome'" class="ml-auto bar-rating-stars"
                            [readOnly]="true"></bar-rating> -->
                          <div class="d-flex flex-row">
                            <i *ngFor="let s of counter(rate)" class="fas fa-star stars rate-stars"></i>
                            <i *ngFor="let s of counter(6-rate)" class="fas fa-star stars empty-stars"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="capacity-colum d-flex flex-column justify-content-between p-2">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-opensans m-0 p-0"><i class="icon-two-people"></i>&nbsp;Capacidad</p>
                          <p class="data-item font-opensans m-0 p-0">2 por habitación</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-opensans m-0 p-0">Tipo de Habitación</p>
                          <p class="data-item font-opensans m-0 p-0">DBLEY</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-opensans m-0 p-0">Tipo de Cama</p>
                          <p class="data-item font-opensans m-0 p-0">Cama doble</p>
                        </div>
                      </div>
                    </div>
                    <div class="opciones-colum p-2">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-opensans m-0 p-0">Opciones</p>
                          <p class="option-item font-opensans m-0 p-0 mt-2"><span
                              class="i-not-included font-poppins">x</span>&nbsp;&nbsp;&nbsp;No incluye régimen de
                            comida
                          </p>
                          <p class="option-item font-opensans m-0 p-0 mt-2"><span
                              class="i-not-included font-poppins">x</span>&nbsp;&nbsp;&nbsp;No podés cancelar ni
                            realizar canbios
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="price-colum d-flex flex-column justify-content-between p-2">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0 font-verde">Precio Por Noche <br> $ 150 </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label class="custom-radio-checkbox mb-0">
                            <input class="custom-radio-checkbox__input" type="checkbox">
                            <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <p class="text-precio font-opensans mb-0">Total Precio</p>
                          <p class="nro-precio font-opensans mb-0">$ 240</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-3">
                    <div id="{{'accordion'+i}}">
                      <!-- Imagenes -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingOne'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseOne}" data-toggle="collapse"
                          [attr.data-target]="'#collapseOne'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseOne'+i"
                          (click)="collapse.collapseOne=!collapse.collapseOne;collapse.collapseTwo=false;collapse.collapseThree=false">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            IMAGENES
                          </button>
                          <i class="i-cam float-right mt-1 mr-3"></i>
                        </div>

                        <div id="{{'collapseOne'+i}}" class="collapse" [attr.aria-labelledby]="'headingOne'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12 content-imgSelect">
                                <img [src]="imgList[i][imgSelect[i]]" class="img-gallery" alt="">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="d-flex bg-description pt-2 px-3">
                                  <p class="epigrafe">{{stingUpperCase('Lorem ipsum dolor sit amet')}}</p>
                                </div>
                                <div class="d-flex bg-description pt-2 px-4 content-miniatura">
                                  <!-- left scroll -->
                                  <i class="scroll-left" *ngIf="imgList[i].length > 5" (click)="scroll('l',i)"></i>

                                  <div class="d-flex justify-content-between slide-miniatura"
                                    id="{{'slide-miniatura'+i}}">
                                    <img [src]="img" class="img-miniatura mr-3"
                                      *ngFor="let img of imgList[i]; index as j"
                                      [ngClass]="{selectedImg: imgSelect[i]==j}" (click)="imgSelect[i]=j">
                                  </div>

                                  <!-- right scroll -->
                                  <i class="scroll-right" *ngIf="imgList[i].length > 5" (click)="scroll('r',i)"></i>
                                </div>
                                <div class="d-flex bg-description justify-content-center py-4">
                                  <span class="font-poppins pagination-img">
                                    {{imgSelect[i]+1 +' of '+ imgList[i].length}}
                                  </span>
                                  <a class="ml-5 pagination-btn" (click)="prev(i)" title="previous">
                                    <i class="i-arrow-left" *ngIf="imgSelect[i]>0"></i>
                                    <i class="i-arrow-left-gray" *ngIf="imgSelect[i]==0"></i>
                                  </a>
                                  <a class="ml-5 pagination-btn" (click)="next(i)" title="next">
                                    <i class="i-arrow-right" *ngIf="imgSelect[i]<(imgList[i].length-1)"></i>
                                    <i class="i-arrow-right-gray" *ngIf="imgSelect[i]==(imgList[i].length-1)"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Descripción -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingTwo'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseTwo}" data-toggle="collapse"
                          [attr.data-target]="'#collapseTwo'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseTwo'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=!collapse.collapseTwo;collapse.collapseThree=false">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            DESCRIPCIÓN
                          </button>
                          <i class="i-list float-right mt-1 mr-3"></i>
                        </div>
                        <div id="{{'collapseTwo'+i}}" class="collapse" [attr.aria-labelledby]="'headingTwo'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body bg-description">
                            <p class="title-description font-poppins mb-0">Titulo Descripción</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Facere nisi iusto debitis repudiandae, ut quae ipsum, nam neque quo
                              consequatur beatae dolorum blanditiis expedita, sunt laudantium. Iusto totam voluptate
                              enim!</p>
                            <br>
                            <p class="check-description font-poppins mb-0">HORARIOS CHECK</p>
                            <p class="check-description font-poppins mb-0">Check in: 4 pm</p>
                            <p class="check-description font-poppins mb-0">Check out: 10 am</p>
                            <br>
                            <p class="alert-description title-description font-poppins mb-0">Salidas Anticipadas</p>
                            <p class="alert-description text-description font-poppins mb-0">Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Eum quibusdam labore, mollitia consequatur porro alias nulla
                              repudiandae corporis. Aut, quas.</p>
                            <br>
                            <p class="title-description font-poppins mb-0">Otro texto</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Voluptatem tempora vel non iste omnis fugit fuga eius porro quos.
                              Quisquam tempore deleniti necessitatibus fugiat blanditiis, perferendis eos explicabo
                              delectus non!</p>
                          </div>
                        </div>
                      </div>
                      <!-- Politicas de Cancelacion -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingThree'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseThree}" data-toggle="collapse"
                          [attr.data-target]="'#collapseThree'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseThree'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=false;collapse.collapseThree=!collapse.collapseThree">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            POLÍTICAS DE CANCELACIÓN Y PENALIDADES
                          </button>
                          <i class="i-cancel float-right mt-1 mr-3"></i>
                        </div>
                        <div id="{{'collapseThree'+i}}" class="collapse" [attr.aria-labelledby]="'headingThree'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body bg-description">
                            <p class="title-description font-poppins">Titulo Politicas de cancelación</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Facere nisi iusto debitis repudiandae, ut quae ipsum, nam neque quo
                              consequatur beatae dolorum blanditiis expedita, sunt laudantium. Iusto totam voluptate
                              enim!</p>
                            <br>
                            <p class="check-description font-poppins mb-0">Items</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <br>
                            <p class="title-description font-poppins">Algun otro titulo u apartado</p>
                            <p class="text-description font-poppins">Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Voluptatem tempora vel non iste omnis fugit fuga eius porro quos. Quisquam tempore
                              deleniti necessitatibus fugiat blanditiis, perferendis eos explicabo delectus non!</p>
                          </div>
                        </div>
                      </div>
                      <!-- Mapa -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingFour'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseFour}" data-toggle="collapse"
                          [attr.data-target]="'#collapseFour'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseFour'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=false;collapse.collapseThree=false;collapse.collapseFour=!collapse.collapseFour;mapMarker()">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            MAPA
                          </button>
                          <i class="icon-placeholder float-right mt-1 mr-3" style="font-size: 20px;"></i>
                        </div>
                        <div id="{{'collapseFour'+i}}" class="collapse" [attr.aria-labelledby]="'headingFour'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body">
                            <div class="d-flex flex-row mb-3">
                              <div class="col-12 content-dir py-2">
                                <p class="direccion-maps mb-0">Universal Blvd, 6725 Adventure Way, Orlando</p>
                              </div>
                            </div>
                            <div #gmap id="map" style="min-height: 480px; margin: 0xp"></div>
                            <div class="d-flex flex-row mt-3">
                              <div class="col-12 p-0">
                                <p class="title-description">Titulo Ubicación</p>
                                <p class="description-maps">Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                  Ratione dolorem atque ad quod, qui laudantium exercitationem ex quibusdam nisi sit
                                  commodi praesentium velit et ducimus repellendus dolorum laboriosam in blanditiis.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" [ngClass]="{'d-block' : subtab == '#nkd', 'd-none' : subtab !== '#nkd'}" id="nkd">
            BOGOTA
          </div>
        </div>
      </div>
      <!-- book -->
      <div class="col-12 mt-3 mb-3" id="book" [ngClass]="{'d-block' : tab == '#book', 'd-none' : tab !== '#book'}">
        <div class="d-flex flex-row justify-content-between book">
          <div class="d-flex flex-column book-name">
            <p class="book-title font-opensans">Name</p>
            <p class="book-city font-opensans m-0">CARTAGENA
              SAN ANDRES</p>
          </div>
          <div class="d-flex flex-column book-lang">
            <p class="book-title font-opensans">Idioma</p>
            <p class="book-lang-name font-opensans m-0">English</p>
          </div>
          <div class="d-flex flex-column book-duration">
            <p class="book-title font-opensans">Duration</p>
            <p class="book-days font-opensans m-0">7 DAYS</p>
          </div>
          <div class="d-flex flex-column book-date">
            <p class="book-title font-opensans"><i class="icon-date"></i>&nbsp; Date</p>
            <input class="form-control book-select-date font-opensans" (click)="openDates=!openDates" type="text"
              name="" [value]="valueDate" [ngClass]="{'book-select-date-open' : openDates}" readonly>
            <div class="date-disp" [ngClass]="{'show' : openDates}">
              <p class="font-opensans date-select my-1"
                (click)="valueDate='2020-11-01 / 2020-11-07'; openDates=!openDates">2020-11-01 /
                2020-11-07</p>
              <p class="font-opensans date-select my-1"
                (click)="valueDate='2020-11-08 / 2020-11-14'; openDates=!openDates">2020-11-08 /
                2020-11-14</p>
              <p class="font-opensans date-select my-1"
                (click)="valueDate='2020-11-15 / 2020-11-21'; openDates=!openDates">2020-11-15 /
                2020-11-21</p>
            </div>
          </div>
          <div class="d-flex flex-column book-detail-price">
            <p class="book-title font-opensans">Adult Price</p>
            <p class="book-price font-opensans m-0">$ 110 (18 -99 years)</p>
            <p class="book-title font-opensans mt-2">Children Price</p>
            <p class="book-price font-opensans m-0">$ 90 (0 -17 years)</p>
          </div>
          <div class="d-flex flex-column book-total-price justify-content-start">
            <div class="d-flex flex-column">
              <p class="title-total-price font-opensans m-0">Total</p>
              <p class="nro-total-price font-opensans m-0">USD 240</p>
            </div>
            <div class="d-flex flex-column mt-2">
              <p class="label-select font-poppins m-0">Select</p>
              <label class="custom-radio-checkbox mb-0">
                <input class="custom-radio-checkbox__input" type="checkbox">
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Total -->
    <div class="d-flex flex-column mb-2" *ngIf="tab !== '#itinerary'">
      <div class="d-flex flex-row total-bottom">
        <div class="col-8 my-auto">
          <p class="font-poppins total-location mb-0"><span class="total-day">7 days</span> cartagena - san
            andres</p>
        </div>
        <div class="col-4 my-auto">
          <p class="font-poppins total-price text-right mb-0 pr-3">Total: $240</p>
        </div>
      </div>
    </div>
    <!-- content-tabs end -->
    <div class="row row pt-3 pb-4">
      <div class="col-12">
        <button class="btn btn-continue font-poppins float-right">CONTINUE</button>
      </div>
    </div>
  </div>
</div>


<!-- <div id="modalrooms">
  <div class="modal-content p-4">
    <div class="flex-row">
    </div>
    <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome'" class="ml-auto" [readOnly]="true"></bar-rating>
    <div class="flex-column px-1 py-0 d-flex ">
      <h3 class="d-flex pt-2 mb-0 ">{{packages?.title}}</h3>
      <span class="address-resumen">
        <i class="icon icon-placeholder" aria-hidden="true"></i> -->
<!-- <strong class="px-1"> 1201 NW LEJEUNE ROAD, MIAMI - AIRPORT 33126</strong> -->
<!-- <strong class="px-1">{{packages?.additionalInfo?.location}}</strong>
      </span>
    </div>
    <div class="d-flex justify-content-start py-0 mb-1">
      <div class="d-flex pl-1 pt-0 align-items-center rating-visits">
        {{ratereview}}
        <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true"
          class="p-1 br"></bar-rating>
      </div>
    </div>
    <hr>
    <div class="modal-body py-2">
      <div>
        <tabset class="tabcontainer"> -->
<!--***********************************-->
<!--***********TABS FOR ROOMS**********-->
<!--***********************************-->
<!-- <tab heading="ROOMS" id="tab-rooms">
            <div *ngIf="packages?.additionalInfo?.rooms">
              <ng-template ngFor let-room let-i="index" [ngForOf]="packages?.additionalInfo?.rooms">
                <usb-room [room]='room' [select]="select" [index]="i" [productQty]="productQty"
                  [reserveMultiHab]="packages.additionalInfo.reserveMultiHab" 
                  (calculateTotalPrice)="calculateTotalPrice()"></usb-room>
              </ng-template>
            </div>
            <div *ngIf="packages?.additionalInfo?.roomsPackage">
              <ng-template ngFor let-room let-i="index" [ngForOf]="packages?.additionalInfo?.roomsPackage">
                <usb-room [room]='room' [select]="select" [index]="i" [productQty]="productQty"
                  [reserveMultiHab]="packages.additionalInfo.reserveMultiHab" 
                  (calculateTotalPrice)="calculateTotalPrice()"></usb-room>
              </ng-template>
            </div>

            <div class="card card-total-price mb-5 text-right">
              Total: ${{totalQty | number:'1.2-2'}}
            </div>
          </tab> -->
<!--***********************************-->
<!--***********TABS FOR MAP************-->
<!--***********************************-->
<!-- <tab heading="MAP" id="tab-map" (select)="mapMarker()">
            <div class="card card-info d-flex flex-row my-3">
              <div class="flex-row p-2 d-flex">
                <span class="address-resumen-t">
                  <i class="icon icon-placeholder" aria-hidden="true"></i>
                  <strong class="px-1">{{packages?.additionalInfo?.location}}</strong>
                </span>
              </div>
            </div> -->
<!-- MAPA - HOTEL -->
<!-- <div class="card border-0 my-3"> -->
<!-- <input class="style-global allwidth-global" #autocomplete type="text" placeholder="" > -->
<!-- <div #gmap id="map" style="min-height: 300px; margin: 0xp"></div> -->
<!-- <img src="assets/img/app/map-hotel-miami.jpg" alt="Map Intercontinental Miami" class="d-block w-100"> -->
<!-- </div> -->
<!-- / MAPA - HOTEL -->
<!-- <div class="card card-cercanos">
              Distancia a Destinos cercanos: Hialeah (Florida) 8.1 Km - Miami Beach 13.1 Km Playa de Hallandele 23.7 Km
              Fort Lauderdale 38.5 km
            </div>
          </tab> -->
<!--***********************************-->
<!--**********TABS FOR Photos**********-->
<!--***********************************-->
<!-- <tab heading="PHOTOS" id="tab-photos"> -->
<!-- Carousel -->
<!-- <div class="my-3 img-position">
              <carousel>
                <ng-template [ngIf]="packages?.additionalInfo?.image">
                  <slide *ngFor="let image of packages.additionalInfo.image">
                    <img src="{{image.image}}" alt="Image" class="d-block w-100">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                  </slide>
                </ng-template>
                <ng-template [ngIf]="!packages?.additionalInfo?.image">
                  <slide>
                    <img src="assets/img/app/hotel_default.png" alt="Image" class="d-block w-100">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                  </slide>
                </ng-template>
              </carousel>
            </div> -->
<!-- / Carousel -->
<!-- </tab>

        </tabset>
      </div>
      <div class="flex-row py-4 px-4">
        <button type="button" class="pull-right btn btn-sm btn-usblick" (click)="continue()"
          [disabled]="disableButton">Continue</button>
      </div>
    </div>
  </div>
</div> -->