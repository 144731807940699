export enum UsblickCommonEnum {
    cookieName = 'auth_token',
    Bearer = 'Bearer',
    redirectQuery= '?redirect=',
    headerAuthorization = 'Authorization',
    spaceChar = ' ',
    equalChar = "=",
    dotCommaChar = ';',
    headerContentType = 'Content-Type',
    appJsonType  = 'application/json',
    validService = 'valid',
    logoutService = 'logout',
    userCurrentService = 'user/current',
    expiresCookie = "expires=",
    pathCookie = ";path=/",
    hoursByDay = 24,
    minutesByHour = 60,
    secondsByMinute = 60,
    milisecondBySecond = 1000,
    emptyResponse = 0,
    emptyString = '',
    emptyValue = -1,
    okResp = 1
}