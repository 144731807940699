
import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { setting } from '../../../../setting';
import { Router } from '@angular/router';

@Component({
  selector: 'usb-destination-big',
  templateUrl: './destination-big.component.html',
  styleUrls: ['./destination-big.component.scss']
})
export class DestinationBigComponent implements OnInit {
  @Input() getClientsSettingAppBooking: any;
  public sectionDestinationBig: any;
  public routeUploadFile:any;
  destinations: any = [];
  linkArticle = [
    { url: '#/article-one' },
    { url: '#/article-two' },
    { url: '#/article-three' }
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<img src="../../assets/img/app/arrow-izq-slider.png" title="arrow-izq-slider" />', '<img src="../../assets/img/app/arrow-der-slider.png"  title="arrow-der-slider"/> '],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      },
      1140: {
        items: 2
      },
      1340: {
        items: 2
      },
      1540: {
        items: 2
      },
      1740: {
        items: 2
      },
      1940: {
        items: 2
      }
    },
    nav: true
    }



  constructor( private router: Router) {
    // this.getClientsSettingAppBooking =  JSON.parse((localStorage.getItem(setting.name)));
    this.routeUploadFile =  setting.routeUploadFile;
   // console.log("llegando al destination-big component", this.getClientsSettingAppBooking);

   }

  ngOnInit() {
    this.sectionDestinationBig  = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_destination_big);
    for (let index = 0; index < this.sectionDestinationBig?.info.length; index++) {
      this.destinations[index] =
      {
        image: this.routeUploadFile + this.sectionDestinationBig?.info[index].image,
        title: this.sectionDestinationBig?.info[index].title,
        subtitle:  this.sectionDestinationBig?.info[index].subtitle,
        description:  this.sectionDestinationBig?.info[index].description,
        url: this.linkArticle[index].url
      }
    }
  }

  articleRedirect(index: number): void {
    const typeArticle = 'big'; // Aquí puedes configurar la variable small como necesites
    const id = index; // Usar el índice o el id que se pase como parámetro
    const active = 0
    this.router.navigate([`/article/${typeArticle}/${id}/${this.getClientsSettingAppBooking.id}/${active}`]);
  }

}
