import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RestService } from '../../rest.service';
import { NavigationExtras, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ItineraryService } from '../../itinerary.service';
import { UserService } from '../../../pages/com.usblick.common/auth/user.service';


@Component({
  selector: "usb-form-paxes-booking",
  templateUrl: "./form-paxes-booking.component.html",
  styleUrls: ["./form-paxes-booking.component.scss"],
  providers: [DatePipe],
})
export class FormPaxesBookingComponent implements OnInit {
  @Input() adults: any;
  @Input() children: any;
  @Input() permitedConfiguration: boolean;
  //@Input() paxes: any = [];
  @Input() paxNumber: Number;
  @Input() itineraryId: any;
  @Input() step: string;
  @Input() itineraryItems: any = [];
  @Input() clientOwner: any;
  @Input() itinerary: any;
  @Output() _loading = new EventEmitter<any>();
   @Output() steps = new EventEmitter<any>();

  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  resultReserve: any = [];
  maxDateAdult: Date;
  minDateChild: Date;
  maxDateChild: Date;
  reserving = false;
  _form: FormGroup;
  formInvalid = false;
  clients: any;
  clientsSelected: any = [];
  countries: any;
  states: any = [];
  cities: any = [];
  titles = [
    { id: "Mr.", name: "Mr." },
    { id: "Mrs.", name: "Mrs." },
    { id: "Ms.", name: "Ms." },
  ];
  relations = [
    { id: "Parent", name: "Parent" },
    { id: "Child", name: "Child" },
    { id: "Spouse", name: "Spouse" },
    { id: "Sibling", name: "Sibling" },
  ];

  paxes: any = [];
  paxList: any = [];



  constructor(
    private fb: FormBuilder,
    public _restService: RestService,
    private router: Router,
    private _itineraryService: ItineraryService,
    public userService: UserService
  ) {
    this.dpConfig.containerClass = "theme-red";

    //fechas
    this.minDateChild = new Date();
    this.maxDateAdult = new Date();
    this.maxDateChild = new Date();

    this.maxDateAdult.setDate(this.maxDateAdult.getDate() - 6570);
    this.maxDateChild.setDate(this.maxDateChild.getDate());
    this.minDateChild.setDate(this.maxDateChild.getDate() - 6570);
  }

  ngOnInit() {

    if (this.itinerary.paxList != null) {
      this.paxList = this.itinerary.paxList;
     } else {
     //  this.paxList[0] = this.clientOwner;
     }
 
    console.log("this.itinerary setArrayPaxes", this.itinerary);

    console.log("paxList", this.paxList);
    // tslint:disable-next-line:max-line-length
    this.paxes = this.setArrayPaxes(Array(this.itinerary.totalAdults).fill(0), Array(this.itinerary.totalChildren).fill(0));
    console.log("paxes", this.paxes);
    this.getAssociateClient();
    this.getCountries();
    this.setForm();
    console.log("clientOwner", this.clientOwner);
  }

  setArrayPaxes(Adults: any, Children: any): Array<any> {
    let num = 0;
    Adults.forEach(i => {
      num = num + 1;
      let holder = (num==1 ) ? ' - Holder' : '';
      this.paxes.push({
        title: 'Adult ' + (num) + holder,
        type: 'adult',
        number: num
      });

    });

    num = 0;
    Children.forEach(i => {
      num = num + 1;
      this.paxes.push({
        title: 'Children ' + num,
        type: 'children',
        number: num
      });
    });
    return this.paxes;
  }

  getAssociateClient() {
    this._itineraryService._restService.doGet("app", "clients/associates/" + this.clientOwner.id).subscribe(
      (response: any) => {
        console.log("response clients", response);
        if (response.data.length > 0) {
          this.clients = response.data;
          this.clients.forEach((element) => {
            element.selected = false;
            element.paxSelect = null;
          });
          console.log("response this.clients", this.clients);
        }
      },
      (err) => {
        console.log("No existe informacion, clientes!", err);
      }
    );
  }

  getCountries(i?: number, setting?: boolean, client?: any) {
    this._itineraryService._restService.doGet("api", "/utilities/search/countries").subscribe(
      (response: any) => {
        this.countries = response["Countrys"];
        console.log("this.countries", this.countries);
        if (setting) {
          const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
          let country = this.countries.find((element) => element.code == client.country);
          if (country) {
            faControl["controls"]["country"].setValue(country);
            this.getStates(i, true, client);
          }
        }
      },
      (err) => {
        console.log("No existe informacion, country!", err);
      }
    );
  }

  getStates(i: any, setting?: boolean, client?: any) {
    const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
    console.log("faControl['controls']['country']", faControl["controls"]["country"]);

    this._itineraryService._restService.doGet("api", "/utilities/states/" + faControl["controls"]["country"].value.id).subscribe(
      (response: any) => {
        console.log("response states", response);
        if (response.length > 0) {
          this.states[i].states = response["states"];

          if (setting) {
            const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
            console.log("faControl en 0", faControl);
            let state = this.states[i].states.find((element) => element.code == client.state);

            if (state) {
              console.log("encontre state", state);
              faControl["controls"]["state"].setValue(state);
              /// this.getCities(i,true, client);
            }
          }
        } else {
          this.states[i].states = [];
        }
      },
      (err) => {
        console.log("No existe informacion, clientes!", err);
      }
    );
  }

  getCities(i: any, setting?: boolean, client?: any) {
    const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
    console.log("faControl['controls']['state']", faControl["controls"]["state"]);

    this._itineraryService._restService.doGet("app", "citiesByStates/" + faControl["controls"]["state"].value.id).subscribe(
      (response: any) => {
        console.log("response cities", response);

        if (response.length > 0) {
          this.cities[i].cities = response;

          if (setting) {
            const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
            console.log("faControl en 0", faControl);
            /*  let city = this.cities[i].cities.find(element => element.id == client.city);
    
          if(city){
            console.log("encontre city", city);
            faControl['controls']['city'].setValue(city);
          } */
          }
        } else {
          //   this.cities[i].cities = [];
        }
      },
      (err) => {
        console.log("No existe informacion, clientes!", err);
      }
    );
  }

  toStr(string: any): string {
    return JSON.stringify(string);
  }

  selectClientFunction(client: any, i: any) {
    client = JSON.parse(client);
    let clientAux = this.clients.find((element) => element.id == client.id);

    this.setClient(clientAux, i);

    console.log("this.clients", this.clients);
    console.log("client", clientAux);
    console.log("i", i);

    let clientFoundIndex: number = this.clients.indexOf(clientAux);
    console.log("clientFoundIndex", clientFoundIndex);

    let clientSelectedFound = this.clients.find((element) => element.paxSelect == i);
    console.log("clientFound", clientSelectedFound);

    if (clientSelectedFound) {
      let clientFoundIndexNew = this.clients.indexOf(clientSelectedFound);
      this.clients[clientFoundIndexNew].selected = false;
      this.clients[clientFoundIndexNew].paxSelect = null;
    }

    this.clients[clientFoundIndex].selected = true;
    this.clients[clientFoundIndex].paxSelect = i;
    console.log("this.clients", this.clients);
    //this.clientsSelected.push(clientAux);
  }

  setClient(client: any, i: any) {
    console.log("client", client);
    const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
    console.log(faControl);

    faControl["controls"]["title"].setValue(client.title);
    faControl["controls"]["gender"].setValue(client.gender);
    faControl["controls"]["first_name"].setValue(client.first_name);
    faControl["controls"]["last_name"].setValue(client.last_name);
    faControl["controls"]["birth_date"].setValue(new DatePipe("en-US").transform(client.birth_date, "dd-MM-yyyy"));
    faControl["controls"]["document_type_id"].setValue(client.document_type_id_type_id);
    faControl["controls"]["document_number"].setValue(client.document_type_id_number);
    faControl["controls"]["email"].setValue(client.email ? client.email : "");
    faControl["controls"]["phone"].setValue(client.phone ? client.phone : "");
    faControl["controls"]["phone_2"].setValue(client.phone2 ? client.phone_2 : "");
    faControl["controls"]["address"].setValue(client.address ? client.address : "");
    faControl["controls"]["postal_code"].setValue(client.postal_code ? client.postal_code : "");
    faControl["controls"]["relation_ship"].setValue(client.relation_ship ? client.relation_ship : "");
    faControl["controls"]["city"].setValue(client.city ? client.city : "");

    let country = this.countries.find((element) => element.id == client.country_id);


      console.log("client.state_id", client.state_id);

    let state = this.states.find((element) => element.id == client.state_id);

    if (country) {
      console.log("encontre country", country);
      faControl["controls"]["country"].setValue(country);
    }

    if (state) {
      console.log("encontre state", state);
      faControl["controls"]["state"].setValue(state);
    }



    this.getStates(i, true, client);
  }

  resetClient(i: any) {
    let clientSelectedFound = this.clients.find((element) => element.paxSelect == i);
    console.log("clientFound", clientSelectedFound);

    if (clientSelectedFound) {
      let clientFoundIndexNew = this.clients.indexOf(clientSelectedFound);
      this.clients[clientFoundIndexNew].selected = false;
      this.clients[clientFoundIndexNew].paxSelect = null;

      const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
      //faControl['controls'].bsku.setValue(sku);

      console.log(faControl);
      faControl["controls"]["title"].setValue(null);
      faControl["controls"]["gender"].setValue(null);
      faControl["controls"]["first_name"].setValue(null);
      faControl["controls"]["last_name"].setValue(null);
      faControl["controls"]["birth_date"].setValue(null);
      faControl["controls"]["document_type_id"].setValue(null);
      faControl["controls"]["document_number"].setValue(null);
      faControl["controls"]["email"].setValue(null);
      faControl["controls"]["phone"].setValue(null);
      faControl["controls"]["relation_ship"].setValue(null);

      //new
      faControl["controls"]["country"].setValue(null);
      faControl["controls"]["state"].setValue(null);
      faControl["controls"]["city"].setValue(null);
      faControl["controls"]["address"].setValue(null);
      faControl["controls"]["postal_code"].setValue(null);
    }
  }

  verifyClientExist(i: any) {
    if (this.clients) {
      let clientSelectedFound = this.clients.find((element) => element.paxSelect == i);
      //console.log("clientFound", clientSelectedFound);
      if (clientSelectedFound) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setForm() {

    let relation_ship = null;
    this._form = this.fb.group({
      paxes: this.fb.array([]),
    });
    let guest = this._form.get("paxes") as FormArray;
    this.paxes.forEach((i, index) => {
      this.states.push({
        states: [],
      });

      this.cities.push({
        cities: [],
      });

     // console.log("his.itinerary.paxList.length ", this.paxList.length);
      console.log("paxes.length", this.paxes.length);


      if (index === 0) {
        relation_ship = " ";
      } else { 
        relation_ship = null;
      }


      if (this.paxList[index]) {
        console.log(" this.paxList[index].genr", this.paxList[index].gender);
        if (this.paxList[index].relation_ship == null) {
            this.paxList[index].relation_ship = " ";
           relation_ship = " ";
        }
      }



      guest.push(
        this.fb.group({
          gender: [this.paxList[index] ? this.paxList[index].gender : null, Validators.required],
          title: [this.paxList[index] ? this.paxList[index].title : null, Validators.required],
          first_name: [this.paxList[index] ? this.paxList[index].first_name : null, Validators.required],
          last_name: [this.paxList[index] ? this.paxList[index].last_name : null, Validators.required],
          // tslint:disable-next-line:max-line-length
          birth_date: [
            this.paxList[index] ? new DatePipe("en-US").transform(this.paxList[index].birth_date, "dd-MM-yyyy") : null,
            Validators.required,
          ],
          document_type_id: [this.paxList[index] ? this.paxList[index].document_type_id_type_id : null, Validators.required],
          // tslint:disable-next-line:max-line-length
          document_number: [
            this.paxList[index] ? this.paxList[index].document_type_id_number : null,
            [Validators.required, Validators.pattern("^[0-9]*$")],
          ],
          email: [this.paxList[index] ? this.paxList[index].email : null, [Validators.required, Validators.email]],
          phone: [this.paxList[index] ? this.paxList[index].phone : null, Validators.pattern("^[0-9]*$")],
          phone_2: [this.paxList[index] ? this.paxList[index].phone_2 : null],
          // tslint:disable-next-line:max-line-length
          country: [this.paxList[index] ?  this.countries.find((element) => element.id == this.paxList[index].country_id) : null, Validators.required],
          // tslint:disable-next-line:max-line-length
          state: [this.paxList[index] ? this.states.find((element) => element.id ==  this.paxList[index].state_id): null, Validators.required],
          city: [this.paxList[index] ? this.paxList[index].city : null, Validators.required],
          address: [this.paxList[index] ? this.paxList[index].address : null],
          relation_ship: [this.paxList[index] ?  this.paxList[index].relation_ship : relation_ship, Validators.required],
          postal_code: [this.paxList[index] ? this.paxList[index].postal_code : null, Validators.pattern("^[0-9]*$")],
        })
      );
      if (this.paxList[index]) {
        this.getCountries(index, true, this.paxList[index]);
      }
    });
    //console.log("this._form", this._form);
    //console.log("this.states", this.states);
   // console.log("this.cities", this.cities);
  }

  setClientCountry(client: any, i: any) {
    const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
    console.log("faControl en 0", faControl);
    let country = this.countries.find((element) => element.id == client.country_id);

    if (country) {
      console.log("encontre country", country);
      faControl["controls"]["country"].setValue(country);
    }

    this.getStates(i, true);
  }

  _onSubmit(event: Event) {
    if (event) {
      event.preventDefault();
    }
    let paxesData = [];

    this._form.value.paxes.forEach((i, index) => {
      console.log("i ", i);
      const faControl = (<FormArray>this._form.controls["paxes"]).at(index);
      i.country = i.country.id;
      i.state = i.state.id;
      paxesData.push(i);
    });

    const payload = {
      paxList: paxesData,
    };

    console.log("payload pax", payload);
    //save to itinerary
    this._restService.doPut("app", "itineraries/update/paxlist/" + this.itineraryId, payload).subscribe(
      (response: any) => {
        console.log("response update pax", response);

        if (response.success) {
          if (this.permitedConfiguration) {
            this.itinerary.paxList = paxesData;
            this.userService.itineraryActive[0].paxList = paxesData;
            this.userService.stepReservation = "three";
          } else {
            this.reserving = true;
        //    this.goReservation(paxesData);
          }
        }
      },
      (error) => {
        console.log("Save-Error: " + error);
      }
    );
  }

  goBack() {
    this.steps.emit(2);
 }

  goReservation(paxes: any) {
    this.resultReserve = [];

    this.resultReserve.push({
      itinerary_id: this.itineraryId,
      starDate: moment(),
      endDate: moment(),
      status_id: 4,
      qtyReservedProduct: 0,
      totalNetPrice: 0,
    });

    this.itineraryItems.forEach((item) => {
      const _payload = this._payload(item, paxes);
      this.makeReserve(_payload, item, paxes);
    });

    return true;
  }

  _payload(item: any, paxes: any): any {
    let productId =
      item.categoryName == "INSURANCE" && item.nameAdapter == "Universal_Assistance"
        ? item.title
        : item.categoryName == "DISNEY"
        ? item.id
        : item.idProduct;
    let searchId = item.categoryName == "DISNEY" ? item.arrayResults[0]["searchId"] : item.searchId;
    let providerId = item.categoryName == "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId;
    let _id: string = item.id;

    if (item.categoryName == "INSURANCE") {
      let idProduct = _id.split("/-/");
      _id = idProduct[0];
    }

    const payload = [
      {
        product: {
          searchId: searchId,
          id: _id,
          providerId: providerId,
          providerName: item.providerName,
          title: item.title,
          description: item.description,
          categoryId: item.category_id,
          productId: productId,
          origin:
            item.categoryName != "DISNEY" ? (item.payloadUpdate.products[0].origin ? item.payloadUpdate.products[0].origin : null) : null,
          destination:
            item.categoryName != "DISNEY"
              ? item.payloadUpdate.products[0].destination
                ? item.payloadUpdate.products[0].destination
                : "KOA"
              : item.destination.iata_code,
          dateIni:
            item.categoryName == "DISNEY"
              ? new DatePipe("en-US").transform(item.destination.destination_info.arrival, "yyyy-MM-dd")
              : item.arrivalDate,
          dateEnd:
            item.categoryName == "DISNEY"
              ? new DatePipe("en-US").transform(item.destination.destination_info.departure, "yyyy-MM-dd")
              : item.departureDate,
          serviceResponse: null,
          nameAdapter: item.categoryName != "DISNEY" ? item.payloadUpdate.products[0].nameAdapter : "Majestic_Disney",
          //informacion dependiente de cada categoria a reservar
          additionalInfo: this.setAdditionalInfo(item, paxes),
        },
        id: null,
        serviceResponse: null,
      },
    ];
    return payload;
  }

  makeReserve(payload: any, item: any, paxes: any) {
    this._restService.doPost("api", "/reservation", payload).subscribe(
      (response: any) => {
        if (response !== null && response.results !== null && response.status !== false) {
          //guardar en variable los resultados
          this.resultReserve.push({
            status_id: response[0].status === true ? 1 : 0,
            resultReserve: response[0].status === true ? response[0].result : response[0].message,
            category_id: item.category_id,
            providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId,
            netPrice: response[0].status === true ? item.totalPrice : 0,
            paxList: this.setPaxesReserve(paxes),
            uid: item.id,
          });
          if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
            //guardar en API
            this.saveReservation(this.resultReserve);
          }
          //guardar la reserva en tablas
        } else {
          this.resultReserve.push({
            status_id: 0,
            resultReserve: response.message,
            category_id: item.category_id,
            netPrice: 0,
            providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId,
            paxList: this.setPaxesReserve(paxes),
            uid: item.id,
          });

          if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
            this.saveReservation(this.resultReserve);
          }
        }
        console.log("algo paso reserva: ", response);
      },
      (error) => {
        this.resultReserve.push({
          status_id: 0,
          resultReserve: error,
          category_id: item.category_id,
          netPrice: 0,
          paxList: this.setPaxesReserve(paxes),
          uid: item.id,
        });

        if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
          this.saveReservation(this.resultReserve);
        }
        console.log(error);
      }
    );
  }

  saveReservation(reserve: any) {
    console.log("this.resultReserve", this.resultReserve);

    reserve["itinerario_id"] = this.itineraryId;
    this._restService.doPost("app", "reservations", reserve).subscribe(
      (response: any) => {
        if (response.success == true) {
          //mandamos a confirmation
          this.confirmationRedirect(response.data.reservation_id);
        }
      },
      (error) => {
        console.log("error en API: ", error);
      }
    );
  }

  setAdditionalInfo(item: any, paxes: any): any {
    let additionalInfo;
    switch (item.categoryName) {
      case "HOTELS":
        additionalInfo = {
          hotelId: item.hotelId,
          roomQty: 1,
          arrival: item.arrivalDate,
          departure: item.departureDate,
          rooms: [
            {
              bookParam: item.bookParam,
              roomId: item.roomId ? item.roomId : item.hotelId,
              optionId: item.optionId ? item.optionId : null,
              accomodationId: item.accomodationId ? item.accomodationId : null,
              maxPax: 1,
              refPrice: item.refPrice,
              paxList: {
                pax: this.setPaxesReserve(paxes),
              },
            },
          ],
        };
        break;
      case "TOURS":
        additionalInfo = {
          tours: [
            {
              company: item.company ? item.company : "Agencia de pruebas",
              optionId: item.optionId,
              paxAdults: item.paxAdults,
              paxChildren: item.paxChildren,
              bookingCode: item.bookingCode ? item.bookingCode : null,
              paxList: this.setPaxesReserve(paxes),
              answers: item.questions.length > 0 ? [] : [],
              sessionCode: item.questions.length > 0 ? [] : null,
              languageCode: item.questions.length > 0 ? [] : null,
            },
          ],
        };
        break;
      case "TRANSPORTS":
        additionalInfo = {
          transfers: [
            {
              oneWay: item.payload.others.transfers.oneWay,
              contractList: item.contractList,
              pickupTime: item.pickupTime,
              dropoffTime: item.dropoffTime,
              pickupLocation: {
                id: item.payload.others.transfers.pickupLocation.id,
                type: item.payload.others.transfers.pickupLocation.type,
              },
              dropoffLocation: {
                id: item.payload.others.transfers.dropoffLocation.id,
                type: item.payload.others.transfers.dropoffLocation.type,
              },
              goingWay: {
                transfersTypeLeg: item.vehicles[0].transfersTypeLeg ? item.vehicles[0].transfersTypeLeg : "IN",
                transferCode: item.vehicles[0].transferCode ? item.vehicles[0].transferCode : "0|0|0",
                transferType: item.vehicles[0].transferType ? item.vehicles[0].transferType : "P",
                vehicleType: item.vehicles[0].transferType ? item.vehicles[0].transferType : 9,
                travelInfo: "xyz",
                travelCompanyName: "avior airline",
                travelDate: item.pickupTime,
                travelTime: item.pickupTime,
              },
              returnWay: {
                transfersTypeLeg: item.vehicles[1].transfersTypeLeg ? item.vehicles[1].transfersTypeLeg : "OUT",
                transferCode: item.vehicles[1].transferCode ? item.vehicles[1].transferCode : "1|0|0",
                transferType: item.vehicles[1].transferType ? item.vehicles[1].transferType : "P",
                vehicleType: item.vehicles[1].transferType ? item.vehicles[1].transferType : 9,
                travelInfo: "abc",
                travelCompanyName: "avianca",
                travelDate: item.dropoffTime,
                travelTime: item.dropoffTime,
              },
              paxList: this.setPaxesReserve(paxes),
            },
          ],
        };
        break;
      case "INSURANCE":
        additionalInfo = {
          insurance: {
            price: item.totalPrice,
            paxList: this.setPaxesReserve(paxes, true),
          },
        };
        break;
      case "PTHEMES":
        additionalInfo = {
          arrival: item.arrivalDate,
          departure: item.departureDate,
          paxList: {
            pax: this.setPaxesReserve(paxes),
          },
          optionId: item.optionId,
        };
        break;
      case "DISNEY":
        additionalInfo = {
          searchId: item.hotel["additionalInfo"]["searchId"],
          hotelId: item.hotel.id,
          roomId: item.room.roomId,
          accId: item.room.accomodationId,
          rateId: item.plan.rateId,
          ticketTypeId: item.ticket !== undefined ? item.ticket.id : 0,
          mealPlanId: item.mealPlan !== null ? item.mealPlan.id : 0,
          arrivalDate: item.destination.destination_info.arrival,
          departureDate: item.destination.destination_info.departure,
          days: item.days,
          noExpiration: false,
          paxList: this.setPaxesReserve(paxes, true),
        };
        break;

      default:
        break;
    }
    return additionalInfo;
  }

  setPaxesReserve(pax: any, insurance: boolean = false): any {
    let paxes = [];
    let i = 0;
    pax.forEach((pax) => {
      let Age = moment().diff(pax.birth_date, "years");
      if (insurance == true) {
        paxes.push({
          gender: pax.gender,
          // title: pax.title,
          first_name: pax.first_name,
          last_name: pax.last_name,
          identificationType: pax.document_type_id,
          identification: pax.document_number,
          birthDate: pax.birth_date,
          age: Age,
          email: "agency@demo.com",
          phone: "7236229081",
          address: "Agencia de prueba AR",
          country: "AR",
          city: "BUENOS AIRES",
          state: "MENDOZA",
          holder: i == 0 ? "holder" : "",
        });
      } else {
        paxes.push({
          title: pax.title,
          first_name: pax.first_name,
          last_name: pax.last_name,
          age: Age,
          email: pax.email,
          phone: pax.phone,
          phone_2: pax.phone_2,
          address: pax.address,
          country: pax.county,
          city: pax.city,
          state: pax.state,
          holder: i == 0 ? "holder" : "",
        });
      }
      i = i + 1;
    });
    return paxes;
  }

  confirmationRedirect(reservationId: number) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: this.itineraryId,
        stat: "true",
        screen: "reservation",
        reservationId: reservationId,
      },
    };
    this.router.navigate(["/reservation-confirmation"], navigationExtras);
  }

  checkProductConfirm(event: any): boolean {
    // console.log(this._form);
    let checked = true;
    this.formInvalid = false;
    let formValues = (<FormArray>this._form.get("paxes")).at(event);

    if (!formValues.get("gender").valid) checked = false;

    if (!formValues.get("email").valid) checked = false;

    if (!formValues.get("postal_code").valid) checked = false;

    if (!formValues.get("phone").valid) checked = false;

    if (!formValues.get("title").valid) checked = false;

    if (!formValues.get("first_name").valid) checked = false;

    if (!formValues.get("last_name").valid) checked = false;

    if (!formValues.get("birth_date").valid) checked = false;

    if (!formValues.get("document_type_id").valid) checked = false;

    if (!formValues.get("document_number").valid) checked = false;

    if (!formValues.get("state").valid) checked = false;

    if (!formValues.get("country").valid) checked = false;

    if (!formValues.get("city").valid) checked = false;

    if (formValues.get("relation_ship").value === null && event > 0) {
      checked = false;
      this.formInvalid = true;
    }

    return checked;
    // return false;
    // let paxesFilter = this.paxesInItems.filter(val=>val.itemId==item.id);
    // return (paxesFilter.length == (item.paxAdults + item.paxChildren)) ? true : false
  }
  checkForm() {
    console.log(this._form);
  }
}
