import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from "../../../pages/com.usblick.common/auth/auth.service";
import { UsblickCommonEnum } from '../../../pages/com.usblick.common/usblick-common-enum';
import { setting } from "../../../../../setting";


@Component({
  selector: 'usb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() clientsSettings: any;

  menuHidden = true;
  public getClientsSettingAppBooking: any;
  public sectionFooter: any;
  public routeUploadFile:any;
  isHome: boolean = true;
  currentYear: any;
  public wsp_number: string;

  constructor(
    private router: Router,
    private authenticationService: AuthService) {


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/home')) {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      }
    });
  }

  ngOnInit() {
    this.routeUploadFile =  setting.routeUploadFile;
    this.clientData();
    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  clientData(): void {
    this.getClientsSettingAppBooking = this.clientsSettings;

    let wspNumber = this.getClientsSettingAppBooking?.wsp_phone_number;

    // Eliminar el signo + y los espacios en blanco
    wspNumber = wspNumber.replace(/\+/g, '').replace(/\s/g, '');

    this.wsp_number = wspNumber;

    this.sectionFooter = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_footer);
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }
  setLanguage(language: string) {
  }

  logout() {
    this.authenticationService.logout({
      _token : this.authenticationService.getCookie(UsblickCommonEnum.cookieName)
    })
      .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  itinerary(){
    this.router.navigate(['itinerary']);
  }
  get username(): string {
    const name = "NmbrrePrueba";
    return name ? name : null;
  }

  articleRedirect(index): void {
    const typeArticle = 'segment'; // Aquí puedes configurar la variable small como necesites
    const id = index; // Usar el índice o el id que se pase como parámetro
    const active = 0
    this.router.navigate([`/article/${typeArticle}/${id}/${this.getClientsSettingAppBooking.id}/${active}`]);
  }
}
