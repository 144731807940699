<div class="card p-0" style="background-color:  #f4f4f4" *ngIf="agency">
  <!-- <div class="d-flex flex-row justify-content-between align-items-center header-pay px-4">
    <h5 class="color-green font-weight-bold font-poppins m-0">FORMAS DE PAGO</h5>
    <div class="d-flex align-items-center">
      <p class="p-0 m-0 mr-2 font-poppins">TOTAL IMPORTE</p>
      <p class="p-0 m-0 monto font-poppins">$ {{totalPrice | number:'1.2-2'}}</p>
    </div>
  </div> -->

  <div class="p-4">
    <h5 class="font-poppins m-0" style="font-size: 18px;">FORMAS DE PAGO</h5>
    <p *ngIf="creditCardDataPending.length === 0" class="p-0 m-0 font-poppins" style="font-size: 24px; font-weight: 600;">Total importe: {{currencyItinerary}} {{totalPrice | number:'1.2-2'}}</p>
    <p *ngIf="creditCardDataPending.length > 0 && !creditCardPayPartial" class="p-0 m-0 font-poppins" style="font-size: 24px; font-weight: 600;">Total importe: {{currencyItinerary}} {{amountCreditCard | number:'1.2-2'}}</p>
    <p *ngIf="creditCardPayPartial" class="p-0 m-0 font-poppins" style="font-size: 24px; font-weight: 600;">Total importe: {{currencyItinerary}} {{totalPrice | number:'1.2-2'}}</p>
  </div>

  <div class="d-flex flex-column px-4 pb-4">
    <!-- BLICKOINS -->
    <ng-container *ngIf="agency && blickoinsPay">

      <div class="pay-method d-flex align-items-center justify-content-between flex-row mt-3 position-relative"
           (click)="pointsChange();">

        <!-- <input type="checkbox" class="ml-3" (change)="pointsChange()" [(ngModel)]="isPointsSelected">  -->
        <span class="label-check ml-2 font-poppins">POR BLICKOINS</span>
        <span class="mb-0 d-flex">
          <label class="custom-radio-checkbox mb-0" *ngIf="verifyPaymentMethod(1)">
            <input class="custom-radio-checkbox__input" type="checkbox" (change)="pointsChange()"
              [(ngModel)]="isPointsSelected">
            <!-- <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span> -->
          </label>
        </span>

        <!-- <i class="mr-3 ml-auto"
          ></i> -->
          <span style="position: absolute;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      right: 0;
                      height: 45px;
                      width: 55px;"
                      [ngStyle]="{'background-color': isPointsSelected ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}">
             <i class="icons-caret" [ngStyle]="{'filter': isPointsSelected ? 'grayscale(1)' : ''}"></i>
          </span>

      </div>

      <div *ngIf="isPointsSelected">

        <div class="row mx-0 pt-3 pb-3"  style="background-color: #deeae7;border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;">

          <div class="col-7 pl-4">
            <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">Forma de pago</p>
            <p class="font-poppins" style="font-size: 13px; font-weight: 600;">BLICKOINS</p>
            <!-- <p class="forma-pago-text font-poppins">
              <i class="icons-points ml-0 mr-2"></i>
              FORMA DE PAGO POR CREDITOS DE BLICKOINS
            </p>

            <div class="d-flex flex-column justify-content-center text-center box-points"
              [ngClass]="{'border-red': (amountPoints > creditBlickoinsActives) || (amountPoints > totalPrice) || (amountPoints < 0)}">
              <p class="mb-0 total-points font-poppins">TOTAL BLICKOINS <span class="ml-2">$ {{creditBlickoinsActives | number:'1.2-2'}}</span>
              </p>
              <P class="mb-0 saldo-points font-poppins"
                [ngClass]="{'color-red': (creditBlickoinsActives - amountPoints) < 0}">
                SALDO BLICKCOINS
                <span class="ml-2" [ngClass]="{'color-red': (creditBlickoinsActives - amountPoints) < 0}">
                  $ {{creditBlickoinsActives - amountPoints | number:'1.2-2'}}
                </span>

              </P>
            </div> -->
          </div>
          <div class="col-5">
            <div *ngIf="totalPrice" style="display: flex; justify-content: space-around;">
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">TARIFA NETA</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 600;">{{currencyItinerary}} {{ totalPrice | number:'1.2-2' }}</p>
              </div>
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 700;">A PAGAR</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 700;">{{currencyItinerary}} {{ (leftTotal > 0 ? leftTotal : 0) | number:'1.2-2' }}</p>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class=" col-12 d-flex justify-content-between">
              <div class="col-6 mt-3 d-flex flex-column justify-content-center text-center box-points" style="border-radius: 6px;"
                  [ngClass]="{'border-red': (amountPoints > creditBlickoinsActives) || (amountPoints > totalPrice)}">
                  <p class="mb-0 total-points font-poppins">TOTAL BLICKOINS <span class="ml-2">{{currencyItinerary}} {{creditBlickoinsActives | number:'1.2-2'}}</span>
                  </p>
                  <P class="mb-0 saldo-points font-poppins"
                    [ngClass]="{'color-red': (amountPoints > creditBlickoinsActives) || (amountPoints > totalPrice)}">
                    SALDO BLICKCOINS
                    <span class="ml-2" [ngClass]="{'color-red': (amountPoints > creditBlickoinsActives) || (amountPoints > totalPrice)}">
                      {{currencyItinerary}} {{( (creditBlickoinsActives - amountPoints) > 0 ? (creditBlickoinsActives - amountPoints) : creditBlickoinsActives) | number:'1.2-2'}}
                    </span>

                  </P>
              </div>
              <div class="col-6 pt-3" style="display: flex; justify-content: flex-end;">
              <div class="d-flex flex-row justify-content-end  pt-3" style="display: flex; justify-content: flex-end;">
                <div class="mr-2">
                  <input class="form-control input total-pay py-0 font-poppins"  id="numberInput" style="appearance: none;border-radius: 50rem;width:132.20px;height:38px; background-color: #f4f4f4;" min="0"
                          type="number"
                          #inputPoints
                          name="amountPoints"
                          [(ngModel)]="amountPoints"
                          placeholder="0,00"
                          [ngClass]="{'border-red': (amountPoints > totalPrice)}"
                          (input)="calculateLeftTotal()"
                          (wheel)="preventScroll($event)"
                          [disabled]="creditBlickoinsActives === 0">
                  <p *ngIf="amountPoints <= totalPrice && amountPoints >= 0" class="mb-0 text-center text-totals font-poppins" style="font-size: 12px !important;"
                      >PAGO A REALIZAR</p>
                  <p *ngIf="amountPoints < 0" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Monto invalido</p>
                  <p *ngIf="amountPoints > totalPrice" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Máximo excedido</p>
                </div>
                <div>
                  <button type="btn"
                          class="btn font-poppins"
                          [disabled]="creditBlickoinsActives === 0 || leftTotal === 0"
                          style="font-size:12px;font-weight: 600; border-radius: 50rem;border: 1px solid black;height:38px;"
                          [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                          (click)="payTotal(1)">PAGAR COMPLETO</button>
                </div>
              </div>
              </div>
              <!-- <div class="d-flex flex-row justify-content-end align-items-end ">
                <p class="mb-1 mr-3 text-totals font-poppins color-red font-weight-bold">RESTAN</p>
                <div class="form-control mt-3 input font-poppins">
                  <p class="monto-faltante">${{ leftTotal | number:'1.2-2' }} </p>
                </div>
              </div> -->
            </div>
          </div>
        </div>

      </div>

    </ng-container>

    <!-- DEPOSIT -->
    <ng-container *ngIf="agency && creditDepositPay">
      <div class="pay-method d-flex align-items-center justify-content-between flex-row mt-2 position-relative" (click)="creditChange()">
        <span class="label-check ml-2 font-poppins">POR DEPOSITO</span>
        <span class="mb-0 d-flex">
          <label class="custom-radio-checkbox mb-0"  *ngIf="verifyPaymentMethod(3)">
            <input class="custom-radio-checkbox__input"
                    type="checkbox"
                    [(ngModel)]="isDepositSelected"
              (change)="creditChange()">
            <!-- <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span> -->
          </label>
        </span>
        <span style="position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              right: 0;
              height: 45px;
              width: 55px;"
              [ngStyle]="{'background-color': isDepositSelected ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}">


        <!-- <i class="mr-3 ml-auto"
          [ngClass]="{'icons-profile': !isDepositSelected, 'icons-profile-dark': isDepositSelected}"></i> -->
          <i class="icons-caret" [ngStyle]="{'filter': isDepositSelected ? 'grayscale(1)' : ''}"></i>
        </span>
      </div>

      <div *ngIf="isDepositSelected">
        <div class="row mx-0 pt-3 pb-3"  style="background-color: #deeae7;border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;">
          <div class="col-7 pl-4">
            <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">Forma de pago</p>
            <p class="font-poppins" style="font-size: 13px; font-weight: 600;">DEPOSITO</p>


            <!-- <p class="forma-pago-text font-poppins">
              <i class="icons-profile ml-0 mr-2"></i>
              FORMA DE PAGO POR DEPOSITO EN GARANTÍA
            </p>
            <div class="d-flex flex-column justify-content-center text-center box-points"
              [ngClass]="{'border-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice) || (amountDeposit<0)}">
              <p class="mb-0 total-points font-poppins">TOTAL DEPOSITO EN GARANTÍA <span class="ml-2">$
                  {{creditDepositActives | number:'1.2-2'}}</span>
              </p>
              <P class="mb-0 saldo-points font-poppins"
                [ngClass]="{'color-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice) || (amountDeposit<0)}">
                SALDO CREDITOS
                <span class="ml-2"
                  [ngClass]="{'color-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice) || (amountDeposit<0)}">
                  $ {{creditDepositActives - amountDeposit | number:'1.2-2'}}
                </span>
              </P>
            </div> -->
          </div>
          <div class="col-5">
            <div *ngIf="totalPrice" style="display: flex; justify-content: space-around;">
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">TARIFA NETA</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 600;">{{currencyItinerary}} {{ totalPrice | number:'1.2-2' }}</p>
              </div>
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 700;">A PAGAR</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 700;">{{currencyItinerary}} {{ (leftTotal > 0 ? leftTotal : 0) | number:'1.2-2' }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 d-flex justify-content-between">
              <div class=" col-6 mt-3 d-flex flex-column justify-content-center text-center box-points" style="border-radius: 6px;"
              [ngClass]="{'border-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice)}">
              <p class="mb-0 total-points font-poppins">TOTAL DEPOSITO EN GARANTÍA <span class="ml-2">{{currencyItinerary}}
                  {{creditDepositActives | number:'1.2-2'}}</span>
              </p>
              <P class="mb-0 saldo-points font-poppins"
              [ngClass]="{'color-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice)}">
              SALDO CRÉDITOS
                <span class="ml-2"
                      [ngClass]="{'color-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice)}">
                      {{currencyItinerary}} {{ (amountDeposit >= 0 && (creditDepositActives - amountDeposit) > 0) ? (creditDepositActives - amountDeposit) : creditDepositActives | number:'1.2-2'}}
                </span>
              </P>
            </div>

            <div class="col-6 pt-3" style="display: flex; justify-content: flex-end;">
              <div class="mr-2">
                  <input class="form-control input font-poppins"
                          id="numberInput"
                          style="appearance: none;border-radius: 50rem;width:132.20px;height:38px;background-color: #f4f4f4;" min="0"
                          type="number"
                          placeholder="0,00"
                          (wheel)="preventScroll($event)"
                          [ngClass]="{'border-red': (amountDeposit > totalPrice)}"
                          #inputDeposit
                          name="amountDeposit"
                          [max]="totalPrice"
                          [(ngModel)]="amountDeposit"
                          (input)="calculateLeftTotal()"
                          [disabled]="creditDepositActives === 0">
                <p *ngIf="amountDeposit <= totalPrice && amountDeposit >= 0" class="mb-0 text-center text-totals font-poppins" style="font-size: 12px !important;">PAGO A REALIZAR</p>
                <p *ngIf="amountDeposit < 0" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Monto invalido</p>
                <p *ngIf="amountDeposit > totalPrice" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Máximo excedido</p>
              </div>
              <div>
                <button type="btn"
                        class="btn font-poppins"
                        style="font-size:12px;font-weight: 600; border-radius: 50rem;border: 1px solid black;height:38px;"
                        [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                        [disabled]="creditDepositActives === 0 || leftTotal === 0"
                        (click)="payTotal(2)">PAGAR COMPLETO</button>
              </div>
            </div>
          </div>

          <!-- <div class="col-6">
            <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-content-end">
                <p class="mb-0 mr-3 text-totals font-poppins">PAGO A REALIZAR</p>
                <input class="form-control input font-poppins" min="0"
                  [ngClass]="{'border-red': (amountDeposit > creditDepositActives) || (amountDeposit > totalPrice) || (amountDeposit<0)}"
                  type="text" #inputCredits name="amountDeposit" [(ngModel)]="amountDeposit" (change)="calculateLeftTotal()">
              </div>
              <div class="d-flex flex-row justify-content-end align-items-end ">
                <p class="mb-1 mr-3 text-totals font-poppins color-red font-weight-bold">RESTAN</p>
                <div class="form-control mt-3 input font-poppins">
                  <p class="monto-faltante">${{ leftTotal | number:'1.2-2' }} </p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      </div>

    </ng-container>

    <!-- TRANSFERENCIA -->
    <ng-container *ngIf="agency && transferPay">

      <div class="pay-method d-flex align-items-center justify-content-between flex-row mt-2 position-relative" (click)="tranferChange()">
        <span class="label-check ml-2 font-poppins">POR TRANSFERENCIA</span>
        <span class="mb-0 d-flex">
          <label class="custom-radio-checkbox mb-0"  *ngIf="verifyPaymentMethod(4)">
            <input class="custom-radio-checkbox__input" type="checkbox" [(ngModel)]="isTranferSelected"
              (change)="tranferChange()" >
            <!-- <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span> -->
          </label>
        </span>
        <span style="position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              right: 0;
              height: 45px;
              width: 55px;"
              [ngStyle]="{'background-color': isTranferSelected ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}">

          <i class="icons-caret" [ngStyle]="{'filter': isTranferSelected ? 'grayscale(1)' : ''}"></i>

        </span>
        <!-- <i class="mr-3 ml-auto"
          [ngClass]="{'icons-transfer': !isTranferSelected, 'icons-transfer-dark': isTranferSelected}"></i> -->
      </div>

      <div *ngIf="isTranferSelected">
        <div class="row mx-0 pt-3 pb-3"   style="background-color: #deeae7;border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;">
          <div class="col-7 pl-4">
            <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">Forma de pago</p>
            <p class="font-poppins" style="font-size: 13px; font-weight: 600;">TRANSFERENCIA</p>
          </div>
          <div class="col-5">
            <div *ngIf="totalPrice" style="display: flex; justify-content: space-around;">
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">TARIFA NETA</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 600;">{{currencyItinerary}} {{ totalPrice | number:'1.2-2' }}</p>
              </div>
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 700;">A PAGAR</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 700;">{{currencyItinerary}} {{ (leftTotal > 0 ? leftTotal : 0) | number:'1.2-2' }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 d-flex justify-content-between">
              <div class="col-6 d-flex flex-row mt-3">


                <div style="position: relative">
                  <input
                          id="file"
                          type="file"
                          accept=".jpg, .jpeg"
                          style="cursor: pointer"
                          (change)="addPhoto($event)"
                          multiple
                          [disabled]="amountTranfer === 0 || amountTranfer === null || amountTranfer < 0 || filesArrayTransfer()" >
                  <!-- <label for="file">
                    <i class="icons-upload-cloud mr-2"></i>
                    {{ fileName ? ( (fileName.length>8) ? (fileName | slice:0:10)+'..':(fileName)) : "Cargar cupon 1"}}
                  </label> -->
                    <p class="mt-1 lc-block" *ngIf="errorImageDimension" style="position:absolute; font-size: 10px; color: red;bottom: -2.5em; left: 2px;">El tamaño máximo permitido es de 1400px X 2100px</p>
                    <p class="mt-1 lc-block" *ngIf="errorImageFormat" style="position:absolute; font-size: 10px; color: red;bottom: -2.5em; left: 2px;">El formato permitido es .JPG</p>
                    <p class="mt-1 lc-block" *ngIf="errorImageSize" style="position:absolute; font-size: 10px; color: red;bottom: -2.5em; left: 2px;">El Peso máximo admitido es de 2mb.</p>
                  <label for="file" style="border-radius: 50rem;height:38px;display:flex; justify-content: center;align-items: center;border: 1px solid black;" [ngStyle]="{'filter': amountTranfer === 0 || amountTranfer === undefined ||  amountTranfer === null  || amountTranfer < 0 ? 'grayscale(1)' : 'grayscale(0)'}">
                    <i class="icons-upload-cloud mr-2"></i>
                    {{ filesArray.length < 3 ? 'Cargar cupon ' + (filesArray.length + 1) : 'Subida completada' }}
                  </label>

                  <div class="d-flex" style="position: relative;">
                    <ng-container *ngFor="let image of filesArray;let i = index">
                      <div class="mr-3">
                        <img [src]="image.url" alt="" width="50px" height="50px">
                        <div class="remove-photo text-white" (click)="removeIMG(i)" style="display:flex;justify-content: center; cursor: pointer;">
                          <div style="font-size: 12px;margin-left:1px;">x</div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
                <div class="col-6 pt-3" style="display: flex; justify-content: flex-end;">
                  <div class="mr-2">
                    <input class="form-control input font-poppins"
                            type="number"
                            id="numberInput"
                            style="appearance: none;border-radius: 50rem;width:132.20px;height:38px;background-color: #f4f4f4;"
                            name="amountTranfer"
                            min="0"
                            [(ngModel)]="amountTranfer"
                            placeholder="0,00"
                            (input)="calculateLeftTotal()"
                            (wheel)="preventScroll($event)"
                            #inputTranfer
                            [ngClass]="{'border-red': (amountTranfer > totalPrice)}">
                    <p *ngIf="amountTranfer <= totalPrice && amountTranfer >= 0" class="mb-0 text-center text-totals font-poppins" style="font-size: 12px !important;">PAGO A REALIZAR</p>
                    <p *ngIf="amountTranfer < 0" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Monto invalido</p>
                    <p *ngIf="amountTranfer > totalPrice" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Máximo excedido</p>
                  </div>
                  <div>
                    <button type="btn"
                    class="btn font-poppins"
                    style="font-size:12px;font-weight: 600; border-radius: 50rem;border: 1px solid black;height:38px;"
                    [disabled]="leftTotal === 0"
                    [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                    (click)="payTotal(3)">PAGAR COMPLETO</button>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-12">
            <p class="mt-4 mb-0 mr-2" style="font-size: 10pt;text-align: end; color: black;">Queda sujeto a validación en las próximas 48hs de recibido el comprobante</p>
          </div>
        </div>
        <!-- <div class="row pt-3">
          <div class="col-6 pl-4">
            <p class="forma-pago-text font-poppins">
              <i class="icons-transfer ml-0 mr-2"></i>
              FORMA DE PAGO POR TRANSFERENCIA
            </p>

            <p class="mt-0 mb-0" style="font-size: 9pt;">Queda sujeto a validación en las próximas 48hs de recibido el comprobante.</p>

            <div class="d-flex flex-column justify-content-center text-center"
                 style="position: relative">
              <input id="file" type="file" accept=".jpg, .jpeg" (change)="addPhoto($event)" multiple> -->
              <!-- <label for="file">
                <i class="icons-upload-cloud mr-2"></i>
                {{ fileName ? ( (fileName.length>8) ? (fileName | slice:0:10)+'..':(fileName)) : "Cargar cupon 1"}}
              </label> -->
                <!-- <p class="mt-1 lc-block" *ngIf="errorImageDimension" style="position:absolute; font-size: 10px; color: red;bottom: -2.5em; left: 2px;">El tamaño máximo permitido es de 1400px X 2100px</p>
                <p class="mt-1 lc-block" *ngIf="errorImageFormat" style="position:absolute; font-size: 10px; color: red;bottom: -2.5em; left: 2px;">El formato permitido es .JPG</p>
                <p class="mt-1 lc-block" *ngIf="errorImageSize" style="position:absolute; font-size: 10px; color: red;bottom: -2.5em; left: 2px;">El Peso máximo admitido es de 2mb.</p>
              <label for="file">
                <i class="icons-upload-cloud mr-2"></i>
                {{ filesArray.length < 3 ? 'Cargar cupon ' + (filesArray.length + 1) : 'Subida completada' }}
              </label>

              <div class="d-flex" style="position: relative;">
                <ng-container *ngFor="let image of filesArray;let i = index">
                  <div class="mr-3">
                    <img [src]="image.url" alt="" width="50px" height="50px">
                    <div class="remove-photo text-white" (click)="removeIMG(i)" style="cursor: pointer;">
                      <span>x</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-content-end">
                <p class="mb-0 mr-3 text-totals font-poppins">PAGO A REALIZAR</p>
               <input class="form-control input font-poppins" type="text" name="amountTranfer" min="0"
                  [(ngModel)]="amountTranfer" (change)="calculateLeftTotal()" #inputTranfer
                  [ngClass]="{'border-red': (amountTranfer > totalPrice)}">
              </div>
              <div class="d-flex flex-row justify-content-end align-items-end ">
                <p class="mb-1 mr-3 text-totals font-poppins color-red font-weight-bold">RESTAN</p>
                <div class="form-control mt-3 input font-poppins">
                  <p class="monto-faltante">${{ leftTotal | number:'1.2-2' }} </p>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </div>

    </ng-container>


    <!-- TARJETA DE CREDITO -->
    <ng-container  *ngIf="agency && creditCardPay">
      <div class="pay-method d-flex align-items-center justify-content-between flex-row mt-2 position-relative"
        (click)="cardChange()" >
        <span class="label-check ml-2 font-poppins">POR TARJETA</span>
        <span class="mb-0 d-flex">
          <label class="custom-radio-checkbox mb-0"
                 *ngIf="verifyPaymentMethod(2) ">
            <input class="custom-radio-checkbox__input"
              type="checkbox"
              (change)="cardChange()"
              [(ngModel)]="isCardSelected">
            <!-- <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span> -->
          </label>
        </span>
        <span style="position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              right: 0;
              height: 45px;
              width: 55px;"
              [ngStyle]="{'background-color': isCardSelected ? getClientsSettingAppBooking?.clients_setting_global?.color_main_3 : 'white'}"
        >
         <!-- <i class="mr-3 ml-auto"
          [ngClass]="{'icons-credit-car': !isCardSelected, 'icons-credit-car-dark': isCardSelected}"></i>  -->
          <i class="icons-caret" [ngStyle]="{'filter': isCardSelected ? 'grayscale(1)' : ''}"></i>
       </span>
      </div>
      <div *ngIf="isCardSelected">
        <div class="row mx-0 pt-3 pb-3" style="background-color: #deeae7; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;">
          <div class="col-7 pl-4">
            <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">Forma de pago</p>
            <p class="font-poppins" style="font-size: 13px; font-weight: 600;">TARJETA</p>
          </div>
          <div class="col-5">
            <div *ngIf="totalPrice" style="display: flex; justify-content: space-around;">
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 600;">TARIFA NETA</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 600;">{{currencyItinerary}} {{ totalPrice | number:'1.2-2' }}</p>
              </div>
              <div>
                <p class="font-poppins" style="margin-bottom: 0;font-size: 13px; font-weight: 700;">A PAGAR</p>
                <p class="font-poppins" style="font-size: 18px; font-weight: 700;">{{currencyItinerary}} {{ (leftTotal > 0 ? leftTotal : 0) | number:'1.2-2' }}</p>
              </div>
            </div>
            <!-- <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-content-end">
                <p class="mb-0 mr-3 text-totals font-poppins">PAGO A REALIZAR</p>
                <input class="form-control input font-poppins" type="text" name="amountCard" min="0" [disabled]="isSelectModePay"
                [(ngModel)]="amountCard" (change)="calculateLeftTotal()" #inputCard>
              </div>
                <p class="mb-1 mr-3 text-totals font-poppins color-red font-weight-bold">RESTAN</p>
                <div class="form-control mt-3 input font-poppins">
                  <p class="monto-faltante">${{ leftTotal | number:'1.2-2' }} </p>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-end align-items-end pt-3" style="z-index: 0 ">
                <ngx-paypal [config]="payPalConfig">
                </ngx-paypal>
              </div>
            </div> -->
          </div>
          <div class="col-12" *ngIf="!creditCardPending">
            <div class="col-12 d-flex justify-content-between">
              <div class="col-7 d-flex flex-row mt-3" style="z-index: 0 " >
                <ngx-paypal [config]="payPalConfig" style="width: 100%;" *ngIf="amountCard <= totalPrice && amountCard >= 0 && !totalPayCreditCard">
                </ngx-paypal>
              </div>
              <div class="col-5 pt-3" style="display: flex; justify-content: flex-end;">
                <div class="mr-2">
                  <input class="form-control input font-poppins"
                         id="numberInput"
                         type="number"
                         style="appearance: none;border-radius: 50rem;width:132.20px;height:38px;background-color: #f4f4f4;"
                         name="amountCard"
                         min="0"
                         [disabled]="isSelectModePay"
                         [(ngModel)]="amountCard"
                         (wheel)="preventScroll($event)"
                         placeholder="0,00"
                         [ngClass]="{'border-red': (amountCard > totalPrice)}"
                         (input)="calculateLeftTotal()"
                         #inputCard>
                  <p *ngIf="amountCard <= totalPrice && amountCard >= 0" class="mb-0 text-center text-totals font-poppins" style="font-size: 12px !important;">PAGO A REALIZAR</p>
                  <p *ngIf="amountCard < 0" class="mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Monto invalido</p>

                  <p *ngIf="amountCard > totalPrice" class=
                            "mb-0 text-center text-danger text-totals font-poppins" style="font-size: 12px !important;">Máximo excedido</p>
                </div>
                <div>
                  <button type="btn"
                          class="btn font-poppins"
                          style="font-size:12px;font-weight: 600; border-radius: 50rem;border: 1px solid black;height:38px;"
                          [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                          [disabled]="leftTotal === 0"
                          (click)="payTotal(4)">PAGAR COMPLETO</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card-table-payments">
              <p class="title-card font-poppins mb-1 ml-3">
                PAGOS PENDIENTES POR GUARDAR
              </p>
              <div class="table-payments-container">
                <table class="font-poppin" style="font-size: 12px !important">
                  <thead>
                    <tr>
                      <th> ID </th>
                      <th>
                      <div class="d-flex justify-content-start align-items-center">
                        FECHA <i class="i-calendar ml-2"></i>
                      </div>
                      </th>
                      <th> HORA </th>
                      <th> MONTO </th>
                      <th> PAGO TARJETA </th>
                      <th> ESTADO DE PAGO </th>
                    </tr>
                  </thead>
                  <tbody >
                    <ng-template ngFor let-paymentCreditCards [ngForOf]="paymentCreditCards" let-i="index">
                      <tr *ngIf="paymentCreditCards.payment_masters_id === null">
                        <td class=""> {{paymentCreditCards.payment_id}} </td>
                        <td class=""> {{parseDate(paymentCreditCards.created_at)}} </td>
                        <td class=""> {{parseHour(paymentCreditCards.created_at)}} </td>
                        <td class=""> {{paymentCreditCards.amount | number:'1.2-2'}} </td>
                        <td class="">
                          <span class="status-payment" [ngClass]="{'status-payment-approved': paymentCreditCards.status.toLowerCase() == 'approved'}">{{paymentCreditCards.status}}</span>
                        </td>
                        <td class="" *ngIf="paymentCreditCards.details[0].status_id === 1"> Pendiente creación de pago</td>
                      </tr>
                    </ng-template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="5" class="total-table">
                           TOTAL PAGADO: {{ totalPaymentCreditCards  | number:'1.2-2' }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center pr-4 mt-3 ml-auto"  *ngIf="onApprove">
            <i class="icons-success mr-2"></i>
            <p class="m-0 pay-success">SU PAGO FUE EXITOSO</p>
          </div>
        </div>
      </div>
    </ng-container>


    <!-- CREDITS -->
    <!-- <ng-container *ngIf="agency && creditPay">
      <div class="pay-method d-flex align-items-center flex-row p-2 mt-3"
        [ngClass]="{'method-selected': isCreditSelected,'method-disabled':(!verifyPaymentMethod(5))}" (click)="pointsCreditChange();">

        <span class="mb-0 d-flex">
          <label class="custom-radio-checkbox mb-0" *ngIf="verifyPaymentMethod(5)">
            <input class="custom-radio-checkbox__input" type="checkbox" (change)="pointsCreditChange()"
              [(ngModel)]="isCreditSelected">
            <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
          </label>
        </span>
        <span class="label-check ml-2 font-poppins">POR CUENTA CORRIENTE</span>
        <i class="mr-3 ml-auto" [ngClass]="{'icons-points': !isCreditSelected, 'icons-points-dark': isCreditSelected}"></i>
        <i class="mr-2" [ngClass]="{'icons-caret': !isCreditSelected, 'icons-caret-dark': isCreditSelected}"></i>
      </div>

      <div *ngIf="isCreditSelected">
        <div class="row pt-3">
          <div class="col-6 pl-4">
            <p class="forma-pago-text font-poppins">
              <i class="icons-points ml-0 mr-2"></i>
              FORMA DE PAGO POR CUENTA CORRIENTE
            </p>
            <div class="d-flex flex-column justify-content-center text-center box-points"
              [ngClass]="{'border-red': (amountCredit > creditsActives) || (amountCredit > totalPrice) || (amountCredit < 0)}">
              <p class="mb-0 total-points font-poppins">TOTAL CUENTA CORRIENTE <span class="ml-2">$ {{creditsActives | number:'1.2-2'}}</span>
              </p>
              <P class="mb-0 saldo-points font-poppins"
                [ngClass]="{'color-red': (creditsActives - amountCredit) < 0}">
                SALDO CUENTA CORRIENTE
                <span class="ml-2" [ngClass]="{'color-red': (creditsActives - amountCredit) < 0}">
                  $ {{creditsActives - amountCredit | number:'1.2-2'}}
                </span>
              </P>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-content-end">
                <p class="mb-0 mr-3 text-totals font-poppins">PAGO A REALIZAR</p>
              <input class="form-control  input font-poppins" min="0"
                  [ngClass]="{'border-red': (amountCredit > creditsActives) || (amountCredit > totalPrice) || (amountCredit<0)}"
                  type="text" #inputPoints name="amountCredit" [(ngModel)]="amountCredit"
                  (change)="calculateLeftTotal()">
              </div>
              <div class="d-flex flex-row justify-content-end align-items-end ">
                <p class="mb-1 mr-3 text-totals font-poppins color-red font-weight-bold">RESTAN</p>
                <div class="form-control mt-3 input font-poppins">
                  <p class="monto-faltante">${{ leftTotal | number:'1.2-2' }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container> -->
  </div>
</div>
