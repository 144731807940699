<div class=" navbar navbar-expand-xg navbar-expand-lg navbar-expand-md align-items-center top-content pb-5 pt-1" style="padding: 0px;">
  <div   class="col-xg-12 col-lg-12 col-md-12 col-xs-12 photos-modal-hotel"  style="background-color: #fff" style="border-radius: 6px; padding: 0px;">
    <owl-carousel-o [options]="customOptionsPhotos" class="shortcuts">
      <ng-container *ngFor="let img of images" >
        <ng-template carouselSlide>
          <img style="height: 450px; border-radius: 6px; width:100%; object-fit:cover;"  [src]="img.image" alt="" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
