import { NgModule} from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { extract } from '../core/i18n.service';
import { HomeComponent } from './home.component';
import { DetailsSummaryItineraryComponent } from '../details-summary-itinerary/details-summary-itinerary.component';
// import { TransactionComponent } from '../transaction/transaction.component';
// import { PaymentsComponent } from '../payments/payments.component';
// import { DepositWarrantyComponent } from '../deposits-warranty/deposits-warranty.component';
// import { FidelityComponent } from '../fidelity/fidelity.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { setting } from '../../../setting';
import { UsblickCommonEnum } from 'app/pages/com.usblick.common/usblick-common-enum';
import { RedirectGuard } from 'app/details-summary-itinerary/RedirectGuard';

import { Store } from '@ngrx/store';
import { AppState } from 'app/core/profiles/storeProfile';
import { selectPermissions, selectRoles } from 'app/core/profiles/profiles.selectors';
import { HttpHeaders } from '@angular/common/http';


  const routes: Routes = [

  { path: 'home', component: HomeComponent, data: { title: extract('Home') } },
  { path: 'details-summary-itinerary', component: DetailsSummaryItineraryComponent, canActivate: [RedirectGuard] },
  {
    path: 'article',
    loadChildren: () => import('./article/article.module').then(m => m.ArticleModule)
  },
  {
    path: 'mannagement',
    loadChildren: () => import('../core/shell/header/profile-mannagement/profile-mannagement.module').then(m => m.ProfileMannagementModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {
  private headersSubject = new BehaviorSubject<HttpHeaders | null>(null);
  public apiBookingUrl: string;

  public moduleProfile: any;

  roles$: Observable<string[]>;
  permissions$: Observable<string[]>;
  permissionsMap: { [key: string]: boolean } = {};

  constructor(
              private router: Router,
              private store: Store<AppState>) {
    this.apiBookingUrl = setting.apiBookingUrl;
    // console.log(this.moduleProfile)
    this.roles$ = this.store.select(selectRoles);
    this.permissions$ = this.store.select(selectPermissions);
    // Despacha las acciones para cargar roles y permisos
    this.store.dispatch({ type: '[Auth] Load Roles' });
    this.store.dispatch({ type: '[Auth] Load Permissions' });

    this.permissions$.subscribe(permissions => {
      this.permissionsMap = this.createPermissionsMap(permissions);
      this.hasAccess(this.permissionsMap);
    });

  }

  createPermissionsMap(permissions: any[]): { [key: string]: any } {
    const map: { [key: string]: any } = {};
    permissions.forEach((permission) => {
      // Utiliza el nombre del permiso como clave
      map[permission.name] = permission;
    });
    return map;
  }

  public getHeaders(): Observable<HttpHeaders> {
    return new Observable((observer) => {
      const authToken = this.getCookie('auth_token');
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('client-id', setting.client_id);
      headers = headers.set('client-secret', setting.client_secret);
      headers = headers.set('Authorization', 'Bearer ' + authToken);
      observer.next(headers);
      observer.complete();
    });
  }

  	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	public getCookie(name: string){
		const nameEQ = encodeURIComponent(name) + UsblickCommonEnum.equalChar;
		const ca = document.cookie.split(UsblickCommonEnum.dotCommaChar);
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === UsblickCommonEnum.spaceChar)
						{c = c.substring(1, c.length);}
				if (c.indexOf(nameEQ) === 0)
						{return decodeURIComponent(c.substring(nameEQ.length, c.length));}
		}
		return null;
    }

    hasAccess(permissions: any) {

            //  if(Object.keys(permissions).length > 0) {

            //     if(permissions['booking.pagos.habilitar'] === undefined) {
            //       window.location.href = setting.appUrl;
            //     }

            //     if(permissions['booking.pagos.habilitar']) {
            //        // Para agregar una ruta dinámicamente, puedes usar el método `resetConfig` del Router.
            //       this.router.resetConfig([
            //         ...this.router.config,
            //         {
            //           path: 'payments', // Definir la nueva ruta
            //           component: PaymentsComponent, // El componente que se debe cargar
            //           data: { title: extract('My Payments') } // Datos asociados a la ruta
            //         }
            //       ]);
            //     }
            //     if(permissions['booking.depositoengarantia.habilitar']) {
            //       this.router.resetConfig([
            //         ...this.router.config,
            //         {
            //           path: 'deposits-warranty',
            //           component: DepositWarrantyComponent,
            //           data: { title: extract('My Deposits Warranty') }
            //         }
            //       ])
            //     }
            //     if(permissions['booking.blickoins.habilitar']) {
            //       this.router.resetConfig([
            //         ...this.router.config,
            //         {
            //           path: 'fidelity',
            //           component: FidelityComponent,
            //           data: { title: extract('My Blickoins') }
            //         }
            //       ])
            //     }
            //     if(permissions['booking.limitecredito.habilitar']) {
            //       this.router.resetConfig([
            //         ...this.router.config,
            //         {
            //           path: 'credits-limit',
            //           component: CreditLimitComponent,
            //           data: { title: extract('My Limit Credits') }
            //         }
            //       ])
            //     }
            //     if(permissions['booking.transacciones.habilitar']) {
            //       this.router.resetConfig([
            //         ...this.router.config,
            //         {
            //           path: 'transaction',
            //           component: TransactionComponent,
            //           data: { title: extract('My Tansactions') }
            //         }
            //       ])
            //     }
            //   }
      }

 }
