import { Component, OnInit } from '@angular/core';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FidelityService } from '../../fidelity/fidelity.service';
import { AgentsService } from '../../agents/agents.service';

@Component({
  selector: 'usb-nr-block-modal',
  templateUrl: './nr-block-modal.component.html',
  styleUrls: ['./nr-block-modal.component.scss']
})
export class NrBlockModalComponent implements OnInit {

  totalPriceNR:any = 0;
  points;
  agency:any;

  constructor(
    public userService:UserService, 
    private dialog:MatDialog, 
    public dialogRef: MatDialogRef<NrBlockModalComponent>, 
    public fidelityService : FidelityService,
    private agentsService:AgentsService
  ) { }

  ngOnInit() {

    this.agency = JSON.parse(localStorage.getItem('selectedAgency'));
    console.log("agency",this.agency);
    this.points = this.fidelityService.creditPointActives;
    this.reviewPricesNR();
    //this.getPoints();
  }

  reviewPricesNR(){
    this.totalPriceNR = 0;
    console.log("this.totalPriceNR", this.totalPriceNR);
    this.userService.itineraryActive[0].items.forEach(element => {
      if(element.nr){  
        this.totalPriceNR = this.totalPriceNR + element.netPriceBooking;
      }
    });
    console.log("totalPriceNR", this.totalPriceNR);
  }

  close(){
    this.dialogRef.close();
  }
}
