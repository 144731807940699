import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { AuthService } from "../../../pages/com.usblick.common/auth/auth.service";
import { UsblickCommonEnum } from "../../../pages/com.usblick.common/usblick-common-enum";
import { AuthorizatedGuard } from "../../../pages/com.usblick.common/guard/authorizated.guard";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { CookieService } from "ngx-cookie";
import { MatDialog } from "@angular/material/dialog";
import { FidelityService } from "../../../fidelity/fidelity.service";
import { AgentsService } from "../../../agents/agents.service";
import { AgentsSummaryService } from "../../../agents-summary/agents-summary.service";
import { MaterialModalConfirmComponent } from "../../../shared/material-modal-confirm/material-modal-confirm.component";
import { setting } from "../../../../../setting";
import { Store } from "@ngrx/store";
import { AppState } from "app/core/profiles/storeProfile";
import { clearAuthState } from '../../profiles/profiles.actions';
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit,OnChanges {
  @Input() clientsSettings: any;
  @Input() namePaymentCondition: any;
  @Input() iconPaymentCondition: any;
  @Input() isAgencyPaymentCondition: boolean = false;
  @Input() creditDeposits: any = 0;
  @Input() creditBlickoins: any = 0;
  // @Input() balanceBlickoins: any = 0;
  @Input() creditLimits: any = 0;
  @Input() permissionsMap: { [key: string]: boolean } = {};
  fechaa = moment().format("DD - MM - YYYY");
  menuHidden = true;
  isCollapsed = false;
  pointsAvailable: any = 0;

  creditsDeposits: any = 0;
  notLogoHeader:any;

  public getClientsSettingAppBooking: any;
  public sectionHeader: any;

  public routeUploadFile: any;
  public apiRouteFile: any;

  public agencyLogo: any;
  public getClientsSettingAppAuth: any;
  public wsp_number: string;
  public moduleProfile: any[] = [];
  arraySocials:any[] = [];

  bsModalRef?: BsModalRef;


  constructor(
                private router: Router,
                private authenticationService: AuthService,
                private authorizatedGuard: AuthorizatedGuard,
                public userService: UserService,
                private _cookieService: CookieService,
                private _router: Router,
                public dialog: MatDialog,
                public fidelityService: FidelityService,
                public agentsService: AgentsService,
                public agentsSummaryService: AgentsSummaryService,
                private store: Store<AppState>,
                private cdr: ChangeDetectorRef,
                private modalService: BsModalService,
              ) { }

  ngOnInit() {
    this.setRoutes();
    this.clientData();
    console.log(this.userService.balanceBlickoins)
  }

  ngAfterViewInit(): void {

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClientsSettingAppBooking = this.clientsSettings;
    this.cdr.detectChanges();
  }

  setRoutes(): void {
    this.routeUploadFile = setting.routeUploadFile;
    this.apiRouteFile = setting.apiStorageBooking;
  }

  clientData(): void {
    this.notLogoHeader = "../../../../assets/img/sin-logo.png"

    let wspNumber = this.getClientsSettingAppBooking?.wsp_phone_number;

    // Eliminar el signo + y los espacios en blanco
    wspNumber = wspNumber.replace(/\+/g, '').replace(/\s/g, '');

    this.wsp_number = wspNumber;
    this.sectionHeader = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_header);
  }

  shouldHideSection(): boolean {
    const currentRoute = this.router.url;
    return currentRoute === "/article-one" || currentRoute === "/article-two" || currentRoute === "/article-three";
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    //this.i18nService.language = language;
  }

  goTo(value: any) {
    let url;
    switch (value) {
      case "pointxagent":
        url = "mannagement/fidelity";
        break;
      case "creditLimits":
        url = "mannagement/credits-limit";
        break;
    }
    this._router.navigate([url  ]);
  }

  logout() {
    const initialState: ModalOptions = {
      initialState: {
        message: 'Saliendo de booking ¿Estás seguro?'
      }
    };

    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if(result) {
        if (result) {
          this.authenticationService
            .logout({
              token: this.authenticationService.getCookie(UsblickCommonEnum.cookieName),
              email: this.authenticationService._email,
            })
            .subscribe(
              (data: any) => {
                if (data.res == 1) {
                  this.authenticationService.setCookie("auth_token", "", -1);
                } else {
                  this.authenticationService.setCookie("auth_token", "", -1);
                }
                this.store.dispatch(clearAuthState());
                //    localStorage.clear();
                this._cookieService.remove("clientActive");
                this.authorizatedGuard.letsRedirect();
              },
              (error) => {
                this.store.dispatch(clearAuthState());
                localStorage.removeItem("selectedAgency");
                this.authorizatedGuard.letsRedirect();
              }
            );
        } else {
          //console.log("no dijo nada");
        }
      } else {

      }
      confirmSubscription.unsubscribe();
    });
  }

  itinerary() {
    this.router.navigate(["itinerary"]);
  }

  _home() {
    this.router.navigate(["/"]);
  }

  get username(): string {
    const name = this.userService._name;
    return name ? name : null;
  }

  get agencyName(): string {
    const agencyName = this.userService._agencyName;
    return agencyName ? agencyName : null;
  }


 get agencyImagen(): string {
    let agencyImagen = this.userService._imagen_logotype;
    if (agencyImagen === this.apiRouteFile || agencyImagen === '') {
        return agencyImagen =  'assets/img/sin-logo.png';
    } else {
        return agencyImagen;
    }
}

  goItinerary() {
    this._router.navigate(["/management", 10]);
  }

  mannagementRedirect(mannagementOption: any) {
    this.router.navigate([`mannagement/${mannagementOption}`]);
  }

  goHome() {
    this._router.navigate(['home']);
  }
}
