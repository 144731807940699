import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'itinerary'
})
export class ItineraryFilterPipe implements PipeTransform {
  transform(array: any, values: any): any {
    const _array = [];
    console.log(values);
    console.log(array);
    array.forEach(itinerary => {
      const add = !this.endDate(itinerary, values) ? false : !this.startDate(itinerary, values) ? false :
        !this.expirationDate(itinerary, values) ? false : !this.creationDate(itinerary, values) ? false :
        !this.product(itinerary, values) ? false :
        !this.ownerEmail(itinerary, values) ? false : !this.ownerName(itinerary, values) ? false : true;

      if (add) {
        _array.push(itinerary);
      }
    });
    return _array;
  }
  endDate(itinerary: any, values: any): boolean {
    let end = false;
    const destination = itinerary.destination;
    if (values.end_date) {
      const departure = destination.length === 0 ? moment(itinerary.departureDate) :
      moment(destination[destination.length - 1].destination_info.departure);
      const val = moment(values.end_date);
      if (val.diff(departure, 'days') === 0) {
        end = true;
      }
    } else {
      end = true;
    }
    console.log('end', end);
    return end;
  }
  startDate(itinerary: any, values: any): boolean {
    let start = false;
    const destination = itinerary.destination;
    if (values.start_date) {
      const arrival = itinerary.arrivalDate ? moment(itinerary.arrivalDate) :
      moment(destination[destination.length - 1].destination_info.arrival);
      const val = moment(values.start_date);
      if (val.diff(arrival, 'days') === 0) {
        start = true;
      }
    } else {
      start = true;
    }
    console.log('start', start);
    return start;
  }
  expirationDate(itinerary: any, values: any): boolean {
    return true;
  }
  creationDate(itinerary: any, values: any): boolean {
    let creation = false;
    if (values.creation_date) {
      const it = moment(itinerary.created_at);
      const val = moment(values.creation_date);
      if (it.diff(values.creation_date, 'days') === 0) {
        creation = true;
      }
    } else {
      creation = true;
    }
    console.log('creation', creation);
    return creation;
  }
  // operationType(itinerary: any, values: any): boolean {
  //   let type = true;
    
  //   return type;
  // }
  ownerEmail(itinerary: any, values: any): boolean {
    const itMail: string = (itinerary.clients) ? itinerary.clients.email : null;
    const valMail: string = values.owner_email;
    if (valMail) {
      if (itMail) {
        const email =  itMail.toLowerCase() === valMail.toLowerCase() ? true : false;
        console.log('email', email);
        return email;
      }
    } else {
      return true;
    }
  }
  ownerName(itinerary: any, values: any): boolean {
    const itName: string = (itinerary.clients) ? (itinerary.clients.first_name + ' ' + itinerary.clients.last_name) : null;
    const valName: string = values.owner_name;
    if (valName) {
      if (itName) {
        const name =  itName.toLowerCase() === valName.toLowerCase() ? true : false;
        console.log('name', name);
        return name;
      }
    } else {
      return true;
    }
  }
  product(itinerary: any, values: any): boolean {
    let product = false;
    if (values.product !== 'Any') {
      const val: string = values.product;
      itinerary.items.forEach(item => {
        const it: string = item.categoryName;
        if (it.toLowerCase() === val.toLowerCase()) {
          product = true;
        }
      });
    } else {
      product = true;
    }
    console.log('product', product);
    return product;
  }
}
