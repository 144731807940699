import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItineraryService } from '../itinerary.service';
//import { ToastsManager } from 'ng2-toastr';
import { isValidOwener, fillOwnerMessage } from '../../utils/Functions';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'usb-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrls: ['./compare-modal.component.scss']
})
export class CompareModalComponent implements OnInit {

  constructor(
    private itineraryService: ItineraryService,
//    
    private _cookieService: CookieService,
  ) { }

  @Input() payload: any;
  @Input() arrayResults: any;
  @Input() origin: string;
  @Input() form: FormGroup;
  @Input() itemToCompare: any = [];
  @Output() modalCompareClose = new EventEmitter<any>();

  ngOnInit() {
  }

  add(item: any) {
    const optionToReserved = item.additionalInfo;
    this.addToItinerary(optionToReserved, item);
  }

  addToItinerary(item: any, result: any) {
    //validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      //this.toastr.warning(fillOwnerMessage());
      return;
    }
    item.categoryName = this.origin;
    item.form = this.form !== undefined ? this.form.value : undefined;
    item.payload = this.payload;
    item.id = result.id + '/-/' + result.title;
    item.idProduct = (result.providerName=='Universal Assistance') ? result.title : result.productId;
    //item.destination = result.destination,
    item.origin = result.origin;
    item.nameAdapter = result.nameAdapter;
    item.searchId = this.arrayResults[0].searchId;
    item.providerId = result.providerId;
    item.providerName = result.providerName;
    item.title = result.title;
    item.location = result.additionalInfo.location;
    item.arrivalDate = result.dateIni;
    item.departureDate = result.dateEnd;
    item.updateDate = new Date();
    item.payloadUpdate = this._payloadUpdateInsurance(this.payload, item);
    item.destination = this.itineraryService.getDestinationInfo(this.form, item);
    this.itineraryService.sendMessageSubscriptorLoading(true);
    this.itineraryService.add(item).subscribe(
      (response:any) => {
        console.log('Product added successfully', response);
        if (typeof response === 'string') {
          //this.toastr.error(response);
        } else {
          // //this.toastr.success('Product added successfully to your itinerary');
          this.modalCompareClose.emit();
        }
        this.itineraryService.sendMessageSubscriptorLoading(false);
      },
      error => {
        console.log('Product not added', error);
        this.itineraryService.sendMessageSubscriptorLoading(false);
        //this.toastr.error('An error occurred, product was not added', 'Error');
      }
    );
  }

  _payloadUpdateInsurance(payload: any, item: any): Object {

    console.log(this.origin);

    return {
      products: [{
        providerId: item.providerId,
        nameAdapter: item.nameAdapter,
        categoryId: 10,
        productId: item.id,
        productName: item.title,
        origin: item.origin,
        destination: item.payload.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: item.arrivalDate,
        departureDate: item.departureDate,
        qtyProduct: 1,
        qtyPax: 0,
        others: {
          insurance: payload.others.insurance
        }
      }]
    };
  }


}
