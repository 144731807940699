import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'usb-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public titulo: string;
  public message: string;
  
  constructor() {
    this.titulo = "Pagina no encontrada!";
    this.message = "La Pagina que intenta acceder no existe";
  }

  ngOnInit() {
  }

}
