import { RouterStateSnapshot,  ActivatedRouteSnapshot,  Router, Route, CanLoad, CanActivate} from '@angular/router';
import { Injectable, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { UsblickCommonEnum } from '../usblick-common-enum';
import { setting } from '../../../../../setting';


@Injectable()
export class AuthorizatedGuard implements CanLoad, CanActivate {
  private module : string;
  public url : string;

  constructor(
    private _router:Router, 
    private _auth:AuthService
    ) {
    this.module = environment.module;
    this.url = window.location.href;
  }

  canLoad (route: Route) {
    return this.canMethod();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
    //console.log("CANACTIVATE esta funcionando");
    
   return this.canMethod();
  }

  canMethod () {
    // console.log(document.cookie);
    if (setting.ActivatedGuard) {
      let token = this._auth.getCookie(UsblickCommonEnum.cookieName);
      if(token) { // token exists

          let data = {
            token: token,
            module: this.module
          };

          this._auth.validate(data, UsblickCommonEnum.Bearer).subscribe(
            (data:any) => { // valid token
                if ( data.res == UsblickCommonEnum.emptyResponse)  { // invalid user, it doesn't belongs to this module.
                    // console.log(`invalid user, it doesn't belongs to this module: ` + data);
                    
                    //this.letsRedirect();
                } else {
                  let user_role = data.det[1]; // this is the user's role!.
                  // console.log("data.res => " + JSON.stringify(data));
                }
            },
            error => { //  other error
              // console.log("ERROR ANTONIO: " + error);
              this.letsRedirect();
            }
          );
          return true;
      } else { // user don't have token
          // console.log('no hay token');
          this.letsRedirect();
      }
    } else {
      return true;
    }    
  }

  letsRedirect(){
    window.location.href = setting.appUrl + UsblickCommonEnum.redirectQuery + encodeURIComponent(this.url);
  }

}