import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItineraryService } from '../../../itinerary.service';
import { RestService } from '../../../rest.service';

@Component({
  selector: 'usb-transaction-master',
  templateUrl: './transaction-master.component.html',
  styleUrls: ['./transaction-master.component.scss']
})
export class TransactionMasterComponent implements OnInit {

  @Input() itinerary: any;
  @Input() items:any;
  @Input() totalPrice:any;
  @Input() paymentMethods;

  clientData:any = {
    name: null,
    mail: null,
    phone: null
  };

  flightInfo:any = {
    thisdepartureDate: null,
    arrivalDate: null,
    airport: null,
    airline: null,
    flightNumber: null
  };

  paxList:any;
  destinations:any;

  agency:any;

  constructor(
    public restService: RestService,
    private itineraryService: ItineraryService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    //console.log("itinerary", this.itinerary);
    this.agency = JSON.parse(localStorage.getItem('selectedAgency'));
    //console.log("agency ", this.agency);

    this.clientData = this.itineraryService.getClientData(this.itinerary); // Obtener los datos del cliente
    //this.flightInfo = this._initFlights(this.itinerary); // obtener los datos del vuelo
    this.paxList = this.itineraryService.getPaxList(this.itinerary);
    this.destinations = this.itineraryService.destinationsPerItinerary(this.itinerary);
  }

  verifyAmount(id){
    let aux:any = this.paymentMethods.find(element=> element.id == id);
    if(aux){
      return aux.amount;
    }else{
      return null;
    }
  }

}
