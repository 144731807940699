<div id="modaltransport">
  <div class="modal-content  p-4 ">
    <div class="flex-row">
        </div>
    <div class="flex-column px-1 py-0 d-flex ">
      <h3 class="d-flex pt-2 mb-0 ">{{ori}}</h3>
      <span class="address-resumen">
        <i class="icon icon-placeholder" aria-hidden="true"></i>
        <strong class="px-1">{{cityIataCode}}</strong>
      </span>
      <usb-flight-modal class="justify-content-between align-items-center d-flex flex-row w-60" *ngIf="_actualItinerary" [invoke]="1" [_actualItinerary]="_actualItinerary"></usb-flight-modal>

    </div>
    <hr>
    <div class="modal-body py-2">
      <form  (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate > <!-- style=" display:none !important;" -->
        <div class="d-flex form-row flex-row justify-content-start" id="selector" >
          <div class="d-flex p-2 form-group align-items-center justify-content-end ">
            <div class="radio d-flex p-0 align-items-center justify-content-between">
              <label class="d-flex align-items-center">
                <input formControlName="type" class="form-check-input" type="radio" name="type" id="inlineRadio1" value="oneTrip" (change)="handleChange('oneTrip')"> 
                <span class="cr">
                  <i class="cr-icon fa fa-circle"></i>
                </span>
              </label>
            </div>
            <label class="px-2">One Trip</label>
            
          </div>
          <div class="d-flex p-2 form-group align-items-center justify-content-end">
            <div class="radio d-flex p-0 align-items-center justify-content-between">
              <label class="d-flex align-items-center">
                <input formControlName="type" class="form-check-input" type="radio" name="type" id="inlineRadio1" value="roundTrip" (change)="handleChange('roundTrip')">
                <span class="cr">
                  <i class="cr-icon fa fa-circle"></i>
                </span>
              </label>
            </div>
            <label class="px-2">Round Trip</label>
          </div>
        </div>
        <div class="d-flex form-row flex-xg-row flex-lg-row flex-md-column flex-sm-column justify-content-between mb-0">
          <div class="form-group align-items-center m-0 py-0 px-1 col-lg-6 col-xg-6 col-md-12 col-sm-12 scroll">
            <label for="origin">&nbsp;</label>
            <input ngui-auto-complete formControlName="firstOrigin" maxlength="20" type="text" name="origin" class="form-control"
            value-formatter="cityName"
            [(ngModel)]="ori"
            placeholder="Origin"
            [value]=__ori
            [list-formatter]="_myListFormatterAdd" 
            display-property-name="cityName" readonly>
          </div>
          <div  class="form-group m-0 py-0 px-1 col-lg-6 col-xg-6 col-md-12 col-sm-12 scroll">
            <label for="origin">&nbsp;</label>
            <input ngui-auto-complete formControlName="pickUp" type="text" name="origin" class="form-control"
            value-formatter="address"
            placeholder="Pickup Location"
            [source]="observableSourceOri.bind(this)"
            display-property-name="address"
            (valueChanged)="_myCallbackPickup($event)"
            [list-formatter]="_myListFormatterAdd" >
            <span class="help-block text-danger" *ngIf="_form.get('pickUp').touched && _form.get('pickUp').hasError('required')">This field is required.</span>
            <span class="help-block text-danger" *ngIf="_form.get('pickUp').touched && _form.get('pickUp').hasError('invalidLocation')">Invalid location, please select a valid location from the list.</span>
          </div>
          <div class="form-group m-0 py-0 px-1 col-lg-6 col-xg-6 col-md-12 col-sm-12 scroll">
            <label for="Destination">&nbsp;</label>
            <input ngui-auto-complete formControlName="firstDestination" maxlength="20" type="text" name="destination" class="form-control"
            value-formatter="cityName"
            placeholder="Destination"
            [(ngModel)]="dest"
            (valueChanged)="_myCallbackDestiny($event)"
            [source]="observableSource.bind(this)"
            display-property-name="cityName"
            [list-formatter]="_myListFormatter" accept-user-input="false" autocomplete="off">
            <span class="help-block text-danger" *ngIf="_form.get('firstDestination').touched && _form.get('firstDestination').hasError('required')">This field is required.</span>
            <span class="help-block text-danger" *ngIf="_form.get('firstDestination').touched && _form.get('firstDestination').hasError('invalidcountry')">Invalid destination format.</span>
            <span class="help-block text-danger" *ngIf="_form.get('firstDestination').touched && _form.get('firstDestination').hasError('invalidDestination')">Invalid location, please select a valid location from the list.</span>
           </div>
            <div class="form-group m-0 py-0 px-1 col-lg-6 col-xg-6 col-md-12 col-sm-12 scroll">
              <label for="Destination">&nbsp;</label>
              <input ngui-auto-complete formControlName="dropOff"  type="text" name="destination" class="form-control"
              value-formatter="address"
              placeholder="Dropoff Location"
              [source]="observableSourceDest.bind(this)"
              (valueChanged)="_myCallbackDropoff($event)"
              display-property-name="address"
              [list-formatter]="_myListFormatterAdd">
              <span class="help-block text-danger" *ngIf="_form.get('dropOff').touched && _form.get('dropOff').hasError('required')">This field is required.</span>
              <span class="help-block text-danger" *ngIf="_form.get('dropOff').touched && _form.get('dropOff').hasError('invalidLocation')">Invalid address, please select a valid destination from the list.</span>
             </div>
        </div>
        <div class="d-flex form-row flex-xg-row flex-lg-row flex-md-column flex-sm-column justify-content-between arrow">     
          <div class="form-group m-0 py-0 px-1 col-lg-auto col-xg-auto col-md-12 col-sm-12">
            <label for="arrivalDate">&nbsp;</label>
            <input formControlName="firstArrivalDate" type="text" class="form-control" id="departureDate" placeholder="Pickup In Date" autocomplete="off" bsDatepicker
              [minDate]="minDate" [bsConfig]="dpConfig" readonly>
              <span class="help-block text-danger" *ngIf="_form.get('firstArrivalDate').touched && _form.get('firstArrivalDate').hasError('required')">This field is required.</span>
              <small class="error-label" *ngIf="_form.get('firstArrivalDate').touched && _form.get('secondArrivalDate').value !== null && _form.get('firstArrivalDate').hasError('invalidDate')">
                  * Check-in must be a date before Check-out!
              </small>
          </div>
          <div  class="form-group m-0 px-1 pb-1 pt-0 d-flex justify-content-start hours col-lg-auto col-xg-auto col-md-12 col-sm-12" >
            <label for="durations">&nbsp;</label>
            <timepicker formControlName="firstTime" [showMeridian]="ismeridian"></timepicker>
          </div>
          <div class="form-group m-0 py-0 px-1 col-lg-auto col-xg-auto col-md-12 col-sm-12 justify-content-start" [style.visibility]="isOpen ? 'visible' : 'hidden'"> 
              <label for="arrivalDate">&nbsp;</label>
              <input formControlName="secondArrivalDate" type="text" class="form-control" id="departureDate" placeholder="Pickup Out Date" autocomplete="off" bsDatepicker
                [minDate]="minDate" [bsConfig]="dpConfig">
                <span class="help-block text-danger" *ngIf="_form.get('secondArrivalDate').touched && _form.get('secondArrivalDate').hasError('required')">This field is required.</span>
                <small class="error-label" *ngIf="_form.get('secondArrivalDate').touched && _form.get('firstArrivalDate').value !== null && _form.get('secondArrivalDate').hasError('invalidDate')">
                    * Check-in must be a date before Check-out!
                </small>
          </div>
          <div class="form-group m-0 px-1 pb-1 pt-0 d-flex justify-content-start hours col-lg-auto col-xg-auto col-md-12 col-sm-12" [style.visibility]="isOpen ? 'visible' : 'hidden'">
                <label for="durations">&nbsp;</label>
                <timepicker formControlName="secondTime" [showMeridian]="ismeridian" ></timepicker>
          </div>
          <div class="form-group m-0 px-1 pb-1 pt-0 col-xg-4 col-lg-3 col-md-12 col-sm-12">
            <label for="passengers">&nbsp;</label>
            <button type="button" class="form-control btn-usb-rp" (click)="_openModal()"  [attr.aria-expanded]="!isCollapsed" aria-controls="false">
              {{passengers}}
            </button>
            <span class="help-block text-danger" *ngIf="(((_close && isCollapsed)) && (_form.value['firstqtyAdults'] == 0))">At least select 1 adult.</span>
            <div (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close" (click)="_over()">
              <div class="row">
                <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
                  <div class="card">
                    <div class="d-flex flex-row col-xg-12 col-lg-12 col-md-12 col-sm-12 align-items-center justify-content-start m-0 p-1">
                      <div class="col-xg-4 col-lg-4 col-md-4 col-sm-4 m-0">
                        <br>
                        <label for="adults">Adults</label>
                        <select formControlName="firstqtyAdults" class="form-control form-control-sm m-0 p-0" id="adults"
                          name="adults" [value]="0">
                          <option *ngFor="let qty of _maxPersons" [value]="qty">{{ qty }}</option>
                        </select>
                      </div>
                    </div>
                    <hr>
                    <div class="d-flex flex-row col-xg-12 col-lg-12 col-md-12 col-sm-12 align-items-center justify-content-start m-0 p-1">
                      <div class="col-xg-4 col-lg-4 col-md-4 col-sm-4 m-0">
                        <label for="childs">Children</label>
                        <select formControlName="firstqtyChilds" class="form-control form-control-sm m-0 p-0" id="childs" (ngModelChange)="_addChild('firstchildsage','firstqtyChilds')"
                          name="childs" [value]="0">
                          <option *ngFor="let qty of _maxPersons" [value]="qty">{{ qty }}</option>
                        </select>
                      </div>
                      <div class="p-0 m-0 col-8">
                        <!-- Children age inputs -->
                        <div id="childrenAges" class="col-12 m-0 p-0" *ngIf="formArray(_form, 'firstchildsage').length > 0">
                          <label for="age">Edad niños</label>
                          <div class="col-12 py-2 pl-2 pr-0">
                            <div formArrayName="firstchildsage" *ngFor="let age of formArray(_form, 'firstchildsage'); let j = index;" class="col-3 select-ages">
                              <div [formGroupName]="j" class="col-12 p-0">
                                <input formControlName="age" min=0 max=17 limit-to="14" class="form-control col-10 p-3 input-age" value-formatter="0"
                                  placeholder="0" name="age">
                              </div>
                            </div>
                          </div>
                          <div *ngFor="let age of formArray(_form, 'firstchildsage'); let j = index;">
                            <span class="help-block text-danger col-12" *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>Age of children {{j+1}} is incorrect</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end flex-row">
                      <div class="p-2 justify-content-end">
                        <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false" class="btn btn-outline-danger btn-sm"
                          type="button">
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-xg-4 col-lg-4 col-xs-0 col-md-1 justify-content-start align-middle">
          </div>
          <div class="form-group col-xg-4 col-lg-4 col-xs-12 col-md-10 text-center align-middle">
            <button class="btn btn-usblick" type="submit" [disabled]="!_form.valid" data-toggle="modal">
              <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
              Buscar
            </button>
          </div>
          <div class="form-group col-xg-4 col-lg-4 col-xs-0 col-md-1 justify-content-start align-middle">
      
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-xg-5 col-lg-5 col-xs-0 p-0 align-items-center justify-content-between">
            </div>
            <div class="form-group col-xg-7 col-lg-7 col-xs-0 p-0 align-items-center justify-content-between">
              <p  *ngFor="let info  of message" class="error-label">
                * {{info}}
              </p>
            </div>
          </div>
        <br> <br> <br> <br>
      </form>
    </div>
  </div>
</div>