import { Component, OnInit, NgModule, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';
import { FormGroup } from '@angular/forms';
import { ItineraryService } from '../../itinerary.service';
import { DatePipe } from '@angular/common';
import { RestService } from '../../rest.service';

// import { anyConfig, any } from '@ng-bootstrap/ng-bootstrap';

import { isValidOwener, fillOwnerMessage } from '../../../utils/Functions';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'usb-modal-disney',
  templateUrl: './modal-disney.component.html',
  styleUrls: ['./modal-disney.component.scss'],
  providers: [DatePipe]
})

export class ModalDisneyComponent implements OnInit {
  @Input() productQty: number;
  @Input() hotel: any;
  @Input() results: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() _item?: any;

  @Output() refModal = new EventEmitter<any>();

  max: number;
  rate = 4;
  ratereview = 4.5;
  isReadonly: boolean;
  x = 5;
  y = 2;
  select: any[] = [];
  totalQty = 0;
  single: any[];
  view: any[] = [200, 150];
  categories = [];
  price: any;
  // options
  rates = [];
  ticketsPrice: any;
  days = [];
  daysArray = [];
  ticketResult: any;
  ticket: any;
  _days: any;
  mealResult: any;
  _rated: any;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = '.';
  showYAxisLabel = true;
  item: any;
  yAxisLabel = '%';
  selected: any;
  _resultsQuery: any;
  categoryId: any;
  providerId: any;

  colorScheme = {
    domain: ['#00a680', '#00a680', '#00a680', '#00a680', '#00a680']
  };

  tab: string = '#rooms'
  subtab: string = '#mega'
  collapseList: any = [
    {
      'collapseOne': false,
      'collapseTwo': false,
      'collapseThree': false
    },
    {
      'collapseOne': false,
      'collapseTwo': false,
      'collapseThree': false
    }
  ]
  imgList: any = [
    // insertar imagenes de disney
    // [
    //   '/assets/img/app/disney-hotel/disney_0.jpg',
    //   '/assets/img/app/disney-hotel/disney_1.jpg',
    //   '/assets/img/app/disney-hotel/disney_2.jpg',
    //   '/assets/img/app/disney-hotel/disney_3.jpg',
    //   '/assets/img/app/disney-hotel/disney_4.jpg',
    //   '/assets/img/app/disney-hotel/disney_5.jpg',
    //   '/assets/img/app/disney-hotel/disney_6.jpg',
    //   '/assets/img/app/disney-hotel/disney_7.jpg'
    // ],
    // [
    //   '/assets/img/app/disney-hotel/disney_0.jpg',
    //   '/assets/img/app/disney-hotel/disney_1.jpg',
    //   '/assets/img/app/disney-hotel/disney_2.jpg',
    //   '/assets/img/app/disney-hotel/disney_3.jpg',
    //   '/assets/img/app/disney-hotel/disney_4.jpg',
    //   '/assets/img/app/disney-hotel/disney_5.jpg'
    // ]
  ]
  imgSelect: any = [0,0]

  constructor(
    private datePipe: DatePipe,
    public vcr: ViewContainerRef,
    private _restService: RestService,
    private _itineraryService: ItineraryService,
    //private config: anyConfig,
    private _cookieService: CookieService,) {
    //this.toastr.setRootViewContainerRef(this.vcr);
    //config.type = 'dark';


    this.single = [
      {
        'name': 'Excelente',
        'value': 74
      },
      {
        'name': 'Muy Bueno',
        'value': 19
      },
      {
        'name': 'Regular',
        'value': 6
      },
      {
        'name': 'Malo',
        'value': 1
      },
      {
        'name': 'Pésimo',
        'value': 0
      }
    ];
  }


  ngOnInit() {
    setTimeout(() => {
      this.selected = null;
      if ((this._item !== null) && (this._item !== undefined)) {
        this.form = this._item['itemInfo']['form'];
        this.arrayResults = this._item['itemInfo']['arrayResults'];
        this.hotel = this._item['itemInfo']['hotel'];
      }
      this.categories = this.setCategories();
    }, 100);
  }

  /**
   * Metodo que crea los select que se veran reflejados en las habitaciones.
   * @param qty Indica la cantidad de select que se crearan (se crea 1 por cada room del hotel).
   * @param maxProductSelect Indica los valores del array (se le agrega 1 mas por el valor 0).
   */
  createSelectsOfRoom(qty: number, maxProductSelect: number) {
    for (let i = 0; i < qty; i++) {
      this.select.push({
        selectArray: Array.from(Array(maxProductSelect).keys()),
        selectValue: 0,
      });
    }
  }
  selectRoom(room: any) {
    this.selected = room;
    this.totalQty = this.selected['optionNightsNetTotal'];
    this.price = {
      optionNightsNetTotal: this.selected['optionNightsNetTotal']
    };

  }
  // getRates(room: any, event: any) {
  //   this.rates = [];
  //   this._restService.doPost('api', '/disney/step/rates', this._payload(0)).subscribe(
  //     Response => {
  //       console.log('Recibido', Response);
  //       this._resultsQuery = Response.results;
  //       this._resultsQuery.forEach(meal => {
  //         meal['additionalInfo']['type'].forEach(type => {
  //           this.rates.push({
  //             name: meal['additionalInfo']['name'] + ' - ' + type['type'].split('T')[0] + ' Tickets',
  //             rateId: type['rateId']
  //           });
  //         });
  //       });
  //       event.activeIds.slice(0, 1);
  //       event.activeIds = ['Meal'];
  //       console.log('Rates', this.rates);
  //     },
  //     Error => {
  //       console.log('Error', Error)
  //     }
  //   );
  // }
  showDays(ticket: any) {
    this.ticket = ticket;
    let id = 0;
    this.daysArray = [];
    this.days = [];
    this.ticket['additionalInfo']['days'].forEach(day => {
      this.days.push(day.split(' ')[0] + ' days');
      this.daysArray.push({
        id: day,
        name: day.split(' ')[0] + ' days'
      });
      id++;
    });
  }
  setDay(event: any) {
    this._days = event['id'];
  }
  getPrice(event: any) {
    this._restService.doPost('api', '/disney/step/tickets/price', this._payload(3)).subscribe(
      Response => {
        console.log('Recibido precio', Response);
        if (Response['message'] === 'ERROR: looks like we got no XML document') {
          this.ticketsPrice = {
            additionalInfo: {
              netPrice: 0,
              totalPrice: 0
            }
          };
          //this.toastr.error('This ticket plan is not available at this moment.');
        } else {
          this.ticketsPrice = Response['results'];
          this._itineraryService.getProfitability(this.ticketsPrice.additionalInfo.netPrice, this.ticketsPrice.categoryId, this.ticketsPrice.providerId)
          .subscribe((data: any) => {
            this.ticketsPrice.additionalInfo.netPriceProf = data.split('-')[0];
            this.ticketsPrice.additionalInfo.netPriceFlag = data.split('-')[1];
            this.ticketsPrice.additionalInfo.percentage = data.split('-')[2];
          });
          this._itineraryService.getProfitability(this.ticketsPrice.additionalInfo.totalPrice, this.ticketsPrice.categoryId, this.ticketsPrice.providerId)
          .subscribe((data: any) => {
            this.ticketsPrice.additionalInfo.totalPriceProf = data.split('-')[0];
            this.ticketsPrice.additionalInfo.totalPriceFlag = data.split('-')[1];
            this.ticketsPrice.additionalInfo.percentage = data.split('-')[2];
          });
          event.activeIds.slice(0, 1);
          event.activeIds = ['Summary'];
        }
        this.totalQty += this.ticketsPrice['additionalInfo']['totalPrice'];
      },
      Error => {
        console.log('Error', Error)
      }
    );
    this._restService.doPost('api', '/disney/step/price', this._payload(4)).subscribe(
      Response => {
        console.log('Recibido precio total', Response);
        this.price = Response['results'];
        this._itineraryService.getProfitability(this.price.optionNightsNetTotal, this.categoryId, this.providerId)
        .subscribe((data: any) => {
          this.price.optionNightsNetTotalProf = data.split('-')[0];
          this.price.optionNightsTotalFlag = data.split('-')[1];
          this.price.percentage = data.split('-')[2];
        });
        this.totalQty = this.price['optionNightsNetTotal'];
        event.activeIds.slice(0, 1);
        event.activeIds = ['Summary'];
      },
      Error => {
        console.log('Error', Error)
      }
    );
  }
  callRates(rate: any, event: any) {
    console.log(this.price);
    this.totalQty = (this.price['optionNightsNetTotal'] !== undefined) && (this.price['optionNightsNetTotal'] !== null) ? this.price['optionNightsNetTotal'] : this.price['optionNightsNetTotal'];
    this._rated = rate;
    this.ticketResult = null;
    this.ticketsPrice = null;
    this.mealResult = null;
    if (this._rated['name'].split(' - ')[1] === 'Con Tickets') {
      this._restService.doPost('api', '/disney/step/tickets', this._payload(1)).subscribe(
        Response => {
          console.log('Recibido ticket', Response);
          this.ticketResult = Response['results'];
          event.activeIds.slice(0, 1);
          event.activeIds = ['Ticket'];
        },
        Error => {
          console.log('Error', Error)
        }
      );
    }
    if (this._rated['name'].split(' - ')[0] !== 'Solo Habitación') {
      this._restService.doPost('api', '/disney/step/meals', this._payload(2)).subscribe(
        Response => {
          console.log('Recibido meal', Response);
          this.mealResult = Response['results'][0];
          this._itineraryService.getProfitability(this.mealResult.additionalInfo.netPrice, this.mealResult.categoryId, this.mealResult.providerId)
          .subscribe((data: any) => {
            this.mealResult.additionalInfo.netPriceProf = data.split('-')[0];
            this.mealResult.additionalInfo.netPriceFlag = data.split('-')[1];
            this.mealResult.additionalInfo.percentage = data.split('-')[2];
          });
          this._itineraryService.getProfitability(this.mealResult.additionalInfo.totalPrice, this.mealResult.categoryId, this.mealResult.providerId)
          .subscribe((data: any) => {
            this.mealResult.additionalInfo.totalPriceProf = data.split('-')[0];
            this.mealResult.additionalInfo.totalPriceFlag = data.split('-')[1];
            this.mealResult.additionalInfo.percentage = data.split('-')[2];
          });
          this.totalQty += this.mealResult['additionalInfo']['totalPriceProf'];
          if (this._rated['name'].split(' - ')[1] !== 'Con Tickets') {
            event.activeIds.slice(0, 1);
            event.activeIds = ['Summary'];
          }
        },
        Error => {
          console.log('Error', Error);
          //this.toastr.error('There are not meal plans available at this moment.');
          this.mealResult = {
            additionalInfo: {
              netPrice: 0,
              totalPrice: 0
            }
          };
        }
      );
    }
    if (this._rated['name'] === 'Solo Habitación - Sin Tickets') {
      event.activeIds.slice(0, 1);
      event.activeIds = ['Summary'];
    }
  }
  _payload(type: number): Object {
    if (type === 0) {
      const payload = {
        arrivalDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['arrivalDate'] : this.form['arrivalDate'], 'yyyy-MM-dd'),
        categoriesId: [12],
        departureDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['departureDate'] : this.form['departureDate'], 'yyyy-MM-dd'),
        hotelId: this.hotel['additionalInfo']['id'],
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        priceFrom: null,
        priceTo: null,
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        roomId: this.selected['roomId'],
        searchId: this.arrayResults[0]['searchId']
      };
      return payload;
    } else if (type === 1) {
      const payload = {
        arrivalDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['arrivalDate'] : this.form['arrivalDate'], 'yyyy-MM-dd'),
        categoriesId: [12],
        departureDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['departureDate'] : this.form['departureDate'], 'yyyy-MM-dd'),
        hotelId: this.hotel['additionalInfo']['id'],
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        priceFrom: null,
        priceTo: null,
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        rateId: this._rated['rateId'],
        roomId: this.selected['roomId'],
        searchId: this.hotel['additionalInfo']['searchId']
      };
      return payload;
    } else if (type === 2) {
      const payload = {
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        categoriesId: [12],
        searchId: this.hotel['additionalInfo']['searchId'],
        hotelId: this.hotel['additionalInfo']['id'],
        roomId: this.selected['roomId'],
        priceFrom: null,
        priceTo: null,
        arrivalDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['arrivalDate'] : this.form['arrivalDate'], 'yyyy-MM-dd'),
        departureDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['departureDate'] : this.form['departureDate'], 'yyyy-MM-dd'),
        rateId: this._rated['rateId']
      };
      return payload;
    } else if (type === 3) {
      const payload = {
        others: {
          maxRequestTimeOnSeconds: 60,
          serialized: false
        },
        typeId: this.ticket['additionalInfo']['id'],
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        categoriesId: [12],
        searchId: this.hotel['additionalInfo']['searchId'],
        hotelId: this.hotel['additionalInfo']['id'],
        roomId: this.selected['roomId'],
        priceFrom: null,
        priceTo: null,
        arrivalDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['arrivalDate'] : this.form['arrivalDate'], 'yyyy-MM-dd'),
        departureDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['departureDate'] : this.form['departureDate'], 'yyyy-MM-dd'),
        rateId: this._rated['rateId'],
        days: this._days
      };
      return payload;
    } else {
      const payload = {
        others: {
          rateId: this._rated['rateId'],
          maxRequestTimeOnSeconds: 60,
          serialized: false,
          days: this._days
        },
        mealPlanId: (this.mealResult !== null && this.mealResult !== undefined) ? this.mealResult['additionalInfo']['id'] : 0,
        ticketTypeId: this.ticket['additionalInfo']['id'],
        providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
        categoriesId: [12],
        searchId: this.hotel['additionalInfo']['searchId'],
        hotelId: this.hotel['additionalInfo']['id'],
        roomId: this.selected['roomId'],
        accId: this.selected['accomodationId'],
        priceFrom: null,
        priceTo: null,
        arrivalDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['arrivalDate'] : this.form['arrivalDate'], 'yyyy-MM-dd'),
        departureDate: this.datePipe.transform(this.form.value !== undefined ? this.form.value['departureDate'] : this.form['departureDate'], 'yyyy-MM-dd'),
        rateId: this._rated['rateId']
      };
      return payload;
    }
  }
  cancel(event: any) {
    setTimeout(() => {
      this.selected = null;
      this._days = null;
      this.rates = [];
      this.daysArray = [];
      this.days = [];
      this._rated = null;
      this._resultsQuery = [];
      this.mealResult = null;
      this.ticketsPrice = null;
      this.ticketResult = null;
      this.ticket = null;
      this.totalQty = 0;
      event.activeIds.slice(0, 1);
      event.activeIds = ['Room'];
    }, 30);
  }
  calculateTotalPrice() {
    this.totalQty = 0;
    this.select.forEach((obj, index) => {
      this.totalQty += this.hotel.additionalInfo.rooms[index].optionNightsNetTotal * (+obj.selectValue);
      this.hotel.additionalInfo.rooms[index].reservations = +obj.selectValue;
      this.hotel.additionalInfo.rooms[index].reserved = +obj.selectValue > 0 ? true : false;
    });
  }

  modalRomms() {
    this.max = 10;
    this.isReadonly = true;
  }
  modalRoom() {
    this.isReadonly = true;
  }
  setCategories(): Array<any> {
    const _categories = [];
    if (this.hotel['serviceResponse'] !== null && this.hotel['serviceResponse'] !== undefined) {
      if (this.hotel['serviceResponse']['RoomTypes']['clsRoomTypesDisney'].length === undefined) {
        _categories.push({
          categorie: this.hotel['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['RateType'],
          rooms: [{
            roomId: this.hotel['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['RoomID'],
            room: null,
            AccID: this.hotel['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['AccID']

          }]
        });
        this.hotel['additionalInfo']['rooms'].forEach(_room => {
          if (_room['roomId'] == this.hotel['serviceResponse']['RoomTypes']['clsRoomTypesDisney']['RoomID']) {
            _categories[0]['rooms'][0]['room'] = _room;
          }
        });
      } else {
        let j = 0;
        this.hotel['serviceResponse']['RoomTypes']['clsRoomTypesDisney'].forEach(room => {
          if (_categories.length === 0) {
            _categories.push({
              categorie: room['RateType'],
              rooms: [{
                roomId: room['RoomID'],
                room: null,
                AccID: room['AccID']
              }]
            });
            this.hotel['additionalInfo']['rooms'].forEach(_room => {
              if (_room['roomId'] == room['RoomID']) {
                _categories[0]['rooms'][0]['room'] = _room;
              }
            });
          } else {
            let newOne = true;
            _categories.forEach(categorie => {
              if (categorie['categorie'] === room['RateType']) {
                categorie['rooms'].push({
                  roomId: room['RoomID'],
                  room: null,
                  AccID: room['AccID']
                });
                this.hotel['additionalInfo']['rooms'].forEach(_room => {
                  if (_room['roomId'] == room['RoomID']) {
                    _categories[j]['rooms'].forEach(element => {
                      if (element['room'] === null) {
                        element['room'] = _room;
                      }
                    });
                  }
                });
                newOne = false;
              }
            });
            if (newOne) {
              j++;
              _categories.push({
                categorie: room['RateType'],
                rooms: [{
                  roomId: room['RoomID'],
                  room: null,
                  AccID: room['AccID']
                }]
              });
              this.hotel['additionalInfo']['rooms'].forEach(_room => {
                if (_room['roomId'] == room['RoomID']) {
                  _categories[j]['rooms'][0]['room'] = _room;
                }
              });
            }
          }
        });
      }
    }
    return _categories;
  }

  continue() {
    console.log('---ModalRoomsComponet--- continue()---');
    this.addItinerary();
  }

  addItinerary() {
    //validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      this._emitRefModal('warning - Warning - ' + fillOwnerMessage());
      // //this.toastr.warning(fillOwnerMessage());
      return;
    }
    console.log('ModalRoomsComponent--- addItinerary()');
    this.item = {
      id: this.hotel['id'] + ' - ' + this.selected['roomId'],
      categoryName: 'DISNEY',
      searchId: this.arrayResults[0]['searchId'],
      searchIdHotel:  this.hotel['additionalInfo']['searchId'],
      providersId: ['c38caa90-f019-11e7-b791-2702c92c19de'],
      payload: this.payloadUpdate,
      form: this.form.value === undefined ? this.form : this.form.value,
      arrayResults: this.arrayResults,
      hotel: this.hotel,
      room: this.selected,
      categoryHotel: this.hotel.serviceResponse.Stars,
      plan: this._rated,
      mealPlan: this.mealResult,
      ticket: this.ticket,
      days: this._days,
      prices: {
        tickets: this.ticketsPrice,
        package: this.price,
        total: this.totalQty
      }
    };
    //se adiciona destination_info
    this.item.destination = this._itineraryService.getDestinationInfo(this.form, this.item);
    if ((this._item !== null) && (this._item !== undefined)) {
      this._itineraryService.updateDisneyItem(this.item, this._item);
      this._emitRefModal('success - Congratulations - Product updated succesfully');
    } else {
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(this.item).subscribe(
        (response:any) => {
          this._emitRefModal('success - Congratulations - Product added succesfully to your itinerary');
          this._itineraryService.sendMessageSubscriptorLoading(false);
        },
        error => {
          console.log('Product not added', error);
          this._emitRefModal('error - Error - Item was not added to your itinerary, please check the console');
          this._itineraryService.sendMessageSubscriptorLoading(false);
        }
      );
    }
  }

  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  tabs(idtab){
    this.tab= idtab
  }

  subtabs(idsubtab){
    this.subtab = idsubtab
  }

  prev(i){
    if(this.imgSelect[i]>0){
      this.imgSelect[i]=this.imgSelect[i]-1
    }
    if(this.imgSelect[i]<=5){
      this.scroll('l',i)
    }
  }
  next(i){
    if(this.imgSelect[i]<(this.imgList[i].length-1)){
      this.imgSelect[i]++
    }
    if(this.imgSelect[i]>=5){
      this.scroll('r',i)
    }
  }
  scroll(dir,id){
    let idc = 'slide-miniatura'+id
    let content = document.getElementById(idc)
    const stepr = 150
    const stepl = -150
    if(dir=='l'){
      content.scrollLeft += stepl
    }else if(dir=='r'){
      content.scrollLeft += stepr
    }
  }
}
