<!-- <div id="modalrooms">
      <div class="modal-content p-4">
        <div class="flex-row">
        </div> -->
        <!-- <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome'" class="ml-auto" [readOnly]="true"></bar-rating> -->
        <!-- <div class="flex-column px-1 py-0 d-flex ">
          <h3 class="d-flex pt-2 mb-0 ">{{ptheme.title}}</h3>
          <span class="address-resumen">
            <i class="icon icon-placeholder" aria-hidden="true"></i> -->
            <!-- <strong class="px-1"> 1201 NW LEJEUNE ROAD, MIAMI - AIRPORT 33126</strong> -->
            <!-- <strong class="px-1">{{ptheme.additionalInfo.location}}</strong>
          </span>
        </div>
        <div class="d-flex justify-content-start py-0 mb-1">
          <div class="d-flex pl-1 pt-0 align-items-center rating-visits">
            {{ratereview}}
            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="p-1 br"></bar-rating>
          </div>
        </div>
        <hr>
        <div class="modal-body py-2">
          <div>
            <tabset class="tabcontainer"> -->
              <!--***********************************-->
              <!--***********TABS FOR OPTIONS**********-->
              <!--***********************************-->
              <!-- <tab heading="OPTIONS" id="tab-options">
                <ng-template ngFor let-option let-i="index" [ngForOf]="ptheme.additionalInfo.options">
                  <usb-option-p-themes [option]='option' [select]="select" [index]="i"
                     (calculateTotalPrice)="calculateTotalPrice(i)"></usb-option-p-themes>
                </ng-template>
                <div class="card card-total-price mb-5  pr-5 text-right">
                  Total: ${{totalQty | number:'1.2-2'}}
                </div>
              </tab> -->
              <!--***********************************-->
              <!--***********TABS FOR MAP************-->
              <!--***********************************-->
             <!--  <tab heading="MAP" id="tab-map">
                <div class="card card-info d-flex flex-row my-3">
                  <div class="flex-row p-2 d-flex">
                    <span class="address-resumen-t">
                      <i class="icon icon-placeholder" aria-hidden="true"></i>
                      <strong class="px-1">{{ptheme.additionalInfo.location}}</strong>
                    </span>
                  </div>
                </div> -->
                <!-- MAPA - HOTEL -->
                <!-- <div class="card border-0 my-3">
                  <img src="assets/img/app/map-hotel-miami.jpg" alt="Map Intercontinental Miami" class="d-block w-100">
                </div> -->
                <!-- / MAPA - HOTEL -->
                <!-- <div class="card card-cercanos">
                  Distancia a Destinos cercanos: Hialeah (Florida) 8.1 Km - Miami Beach 13.1 Km Playa de Hallandele 23.7 Km Fort Lauderdale 38.5 km
                </div>
              </tab> -->
              <!--***********************************-->
              <!--**********TABS FOR Photos**********-->
              <!--***********************************-->
              <!-- <tab heading="PHOTOS" id="tab-photos"> -->
                <!-- Carousel -->
                <!-- <div class="my-3">
                  <carousel>
                    <ng-template [ngIf]="hotel.additionalInfo.images.length">
                      <slide *ngFor="let image of hotel.additionalInfo.images">
                        <img src="{{image.image}}" alt="Image" class="d-block w-100">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                      </slide>
                    </ng-template>
                    <ng-template [ngIf]="!hotel.additionalInfo.images.length">
                      <slide>
                        <img src="assets/img/app/hotel_default.png" alt="Image" class="d-block w-100">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                      </slide>
                    </ng-template>
                  </carousel>
                </div> -->
                <!-- / Carousel -->
              <!-- </tab> -->
              <!-- <tab heading="SERVICES" id="tab-services"> -->
                <!--***********************************-->
                <!--******TABS FOR INFO Y SERVICIOS****-->
                <!--***********************************-->
                <!-- INFO - HOTEL -->
               <!--  <div class="card border-0 my-3">
                    <span class="font-weight-bold text-center title pb-2" *ngIf="ptheme.description">P Themes Information</span>
                    <p class="text-justify info-gral">
                      {{ptheme.description}}
                    </p>
                  </div> -->
                  <!-- / INFO - HOTEL -->
                  <!-- SERVICIOS - HOTEL -->
               <!--  <div class="card border-0 my-5">
                  <span class="font-weight-bold text-center title pb-3">Todos los servicios que incluye este Hotel</span>
                  <div class="row col-12 info-gral-service">
                    <div class="col-4 p-0">
                      <ul>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Wifi</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Guarda Ropa</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Minibar</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Se permiten mascotas g...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Se permiten mascotas pe...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Servicio de niñera</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Servicio a la habitación</li>
                      </ul>
                    </div>
                    <div class="col-4 p-0">
                      <ul>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Gimnasio</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Lavandería</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Camas adicionales</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Bañera de hidromasaje</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Estacionamiento</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Servicio de Lavandería</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Aire acondicionado regula..</li>
                      </ul>
                    </div>
                    <div class="col-4 p-0">
                      <ul>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Albornoces</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Seguro</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Caja de seguridad del hotel</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Televisión</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Garaje</li>
                      </ul>
                    </div>
                  </div>
                  <div class="row col-12 info-gral text-sm-left">
                    <div class="row col-12 mx-4 py-2">
                      <span class="font-weight-bold">OTROS SERVICIOS</span>
                    </div>
                    <div class="col-4 p-0">
                      <ul>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Parking adaptado...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Parking en un garaje</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Parking vigilado</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Piscina</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Taquillas en el gimnasio</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Entrenador personal</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Gimnasio</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Paquetes de spa</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Zona de spa / relajación</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Peinado</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Pedicura</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Visa</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>MasterCard</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>JCB</li>
                      </ul>
                    </div>
                    <div class="col-4 p-0">
                      <ul>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Habitaciones hipoaler...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Tiendas (en el estable...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Calefacción</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Alquiler de coches</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Tienda de recuerdos</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Ascensor</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Habitaciones no fumad...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Prensa</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Cafetería en el alojami...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Desayuno en la habitación</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Bar</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Restaurante</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Cocina</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>¡Muy buen Café!</li>
                      </ul>
                    </div>
                    <div class="col-4 p-0">
                      <ul>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Servicios de belleza</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Sombrillas</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Tumbonas / sillas de playa</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Toallas de playa / piscina</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Cajero automático</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Cambio de moneda</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Registro de entrada...</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Recepción 24 horas</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Limpiabotas</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Servicio de planchado</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Microondas</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Centro de negocios</li>
                        <li>
                          <i class="icon icon-separador" aria-hidden="true"></i>Salas de reuniones</li>
                      </ul>
                    </div>
                  </div>
                </div> -->
                <!-- / SERVICIOS - HOTEL -->
              <!-- </tab> -->
              <!--***********************************-->
              <!--***********TABS FOR TRIPADVISOR**********-->
              <!--***********************************-->
             <!--  <tab heading="REVIEW" id="tab-review">
                <div class="card card-info d-flex justify-content-center mt-2 mb-1"> -->
                  <!-- <div class="p-2 flex-row rating-visits align-content-center justify-content-around">
                    <div class="d-flex">
                      <div class="px-2 col-7 text-center">
                      </div>
                      <div class="px-2 col-3 d-flex flex-row">{{ratereview}}
                        <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="pt-1 pl-2"></bar-rating>
                      </div>
                      <div class="text-opinion py-2">
                        <a href="#">62 opiniones</a>
                      </div>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="px-2 col-7 text-center">
                        <img src="assets/img/app/tripadvisor.png" alt="Tripadvisor" class="mt-1 d-inline tripadvisor">
                      </div>
                      <div class="col-5 p-2 separadores">
                      </div>
                    </div>
                  </div> -->
                  <!-- Review 1-->
              <!--     <div class="d-flex px-2 pb-1 flex-row justify-content-center">
                    <div class="card card-info d-inline flex-row my-0">
                      <div class="d-inline">
                        <div class="d-flex align-content-center pt-1 px-2">
                          <div class="d-flex pt-1 align-content-center rating-visits">
                            {{ratereview}}
                            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="pt-1 pl-2"></bar-rating>
                          </div>
                          <span class="flex-column p-2">
                            Se escribió una opinión el 16 Agosto 2017
                          </span>
                        </div>
                      </div>
                      <div class="py-0 px-2">
                        <span class="strong">
                          <strong>Barrio del Castillo</strong>
                        </span>
                        <p>Nos alojamos por tres noches en este hotel para asistir a una boda familiar. Llegamos tarde ya que
                          nuestro vuelo se retrasó 3 horas y 45 minutos, pero recibió una cálida bienvenida y ayudar con las
                          maletas al piso. La primera impresión, una decoración preciosa
                          <a href="#">...Más</a>
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <!--/ Review 1-->
                  <!-- Review 2-->
            <!--       <div class="d-flex px-2 pb-1 flex-row justify-content-center">
                    <div class="card card-info d-inline flex-row my-0">
                      <div class="d-inline">
                        <div class="d-flex align-content-center pt-1 px-2">
                          <div class="d-flex pt-1 align-content-center rating-visits">
                            {{ratereview}}
                            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="pt-1 pl-2"></bar-rating>
                          </div>
                          <span class="flex-column p-2">
                            Se escribió una opinión el 16 Agosto 2017
                          </span>
                        </div>
                      </div>
                      <div class="py-0 px-2">
                        <span class="strong">
                          <strong>Barrio del Castillo</strong>
                        </span>
                        <p>Nos alojamos por tres noches en este hotel para asistir a una boda familiar. Llegamos tarde ya que
                          nuestro vuelo se retrasó 3 horas y 45 minutos, pero recibió una cálida bienvenida y ayudar con las
                          maletas al piso. La primera impresión, una decoración preciosa
                          <a href="#">...Más</a>
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <!--/ Review 2-->
                  <!-- Review 3-->
                  <!-- <div class="d-flex px-2 pb-1 flex-row justify-content-center">
                    <div class="card card-info d-inline flex-row my-0">
                      <div class="d-inline">
                        <div class="d-flex align-content-center pt-1 px-2">
                          <div class="d-flex pt-1 align-content-center rating-visits">
                            {{ratereview}}
                            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="pt-1 pl-2"></bar-rating>
                          </div>
                          <span class="flex-column p-2">
                            Se escribió una opinión el 16 Agosto 2017
                          </span>
                        </div>
                      </div>
                      <div class="py-0 px-2">
                        <span class="strong">
                          <strong>Barrio del Castillo</strong>
                        </span>
                        <p>Nos alojamos por tres noches en este hotel para asistir a una boda familiar. Llegamos tarde ya que
                          nuestro vuelo se retrasó 3 horas y 45 minutos, pero recibió una cálida bienvenida y ayudar con las
                          maletas al piso. La primera impresión, una decoración preciosa
                          <a href="#">...Más</a>
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <!--/ Review 3-->
                  <!-- Review 4-->
             <!--      <div class="d-flex px-2 pb-1 flex-row justify-content-center">
                    <div class="card card-info d-inline flex-row my-0">
                      <div class="d-inline">
                        <div class="d-flex align-content-center pt-1 px-2">
                          <div class="d-flex pt-1 align-content-center rating-visits">
                            {{ratereview}}
                            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="pt-1 pl-2"></bar-rating>
                          </div>
                          <span class="flex-column p-2">
                            Se escribió una opinión el 16 Agosto 2017
                          </span>
                        </div>
                      </div>
                      <div class="py-0 px-2">
                        <span class="strong">
                          <strong>Barrio del Castillo</strong>
                        </span>
                        <p>Nos alojamos por tres noches en este hotel para asistir a una boda familiar. Llegamos tarde ya que
                          nuestro vuelo se retrasó 3 horas y 45 minutos, pero recibió una cálida bienvenida y ayudar con las
                          maletas al piso. La primera impresión, una decoración preciosa
                          <a href="#">...Más</a>
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <!--/ Review 4-->
                  <!-- Review 5-->
                  <!-- <div class="d-flex px-2 pb-1 flex-row justify-content-center">
                    <div class="card card-info d-inline flex-row my-0">
                      <div class="d-inline">
                        <div class="d-flex align-content-center pt-1 px-2">
                          <div class="d-flex pt-1 align-content-center rating-visits">
                            {{ratereview}}
                            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="pt-1 pl-2"></bar-rating>
                          </div>
                          <span class="flex-column p-2">
                            Se escribió una opinión el 16 Agosto 2017
                          </span>
                        </div>
                      </div>
                      <div class="py-0 px-2">
                        <span class="strong">
                          <strong>Barrio del Castillo</strong>
                        </span>
                        <p>Nos alojamos por tres noches en este hotel para asistir a una boda familiar. Llegamos tarde ya que
                          nuestro vuelo se retrasó 3 horas y 45 minutos, pero recibió una cálida bienvenida y ayudar con las
                          maletas al piso. La primera impresión, una decoración preciosa
                          <a href="#">...Más</a>
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <!--/ Review 5-->
             <!--    </div>
              </tab> -->
            <!-- </tabset>
          </div>
          <div class="flex-row py-2">
              <button type="button" class="pull-right btn btn-sm btn-usblick" (click)="continue()" [disabled] = "select == null">
                  <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
                  Continue</button>
            </div>
        </div>
      </div>
  </div>
   -->

<div class="modal-content" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div id="modalrooms">
    <!-- header modal start -->
    <div class="row">
      <div class="col-12">
        <p class="title-modal font-poppins m-0">{{stingUpperCase(ptheme.title)}}</p>
      </div>
      <div class="col-12">
        <span class="font-location font-poppins">
          <i class="icon-placeholder-green"></i> {{ptheme.additionalInfo.location}}
        </span>
      </div>
      <div class="col-12 mt-2 stars">
        <bar-rating [(rate)]="rate" [max]="6" [theme]="'fontawesome'" class="ml-auto" [readOnly]="true"></bar-rating>
      </div>
    </div>
    <!-- header modal end -->

    <!-- nav-tab modal start -->
    <div class="row mt-3">
      <div class="col-12">
        <ul class="nav justify-content-between">
          <li [ngClass]="{'nav-pills-active': tab=='#ticket'}" class="nav-pills font-poppins" (click)="tabs('#ticket')">
            TICKETS</li>
          <!-- <li [ngClass]="{'nav-pills-active': tab=='#description'}" class="nav-pills font-poppins" (click)="tabs('#description')">
            DESCRIPCIÓN</li> -->
          <li [ngClass]="{'nav-pills-active': tab=='#imagenes'}" class="nav-pills font-poppins" (click)="tabs('#imagenes')">
            IMÁGENES</li>
          <li [ngClass]="{'nav-pills-active': tab=='#mapa'}" class="nav-pills font-poppins" (click)="tabs('#mapa')">
            MAPA</li>
        </ul>
      </div>
    </div>
    <!-- nav-tab modal end -->

    <!-- content-tabs start -->
    <div class="row">
      <div class="col-12 mt-3" id="ticket" [ngClass]="{'d-block' : tab == '#ticket', 'd-none' : tab !== '#ticket'}">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <!-- items # -->
                <div class="item-room d-flex flex-column mb-3" *ngFor="let collapse of collapseList; index as i">
                  <div class="d-flex flex-row">
                    <div class="ticket-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">2 Park 2 Days - Park to Park + <br> 2 Days Free + 4 R/T Share Translate</p>
                        </div>
                      </div>
                    </div>
                    <div class="date-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Fecha</p>
                          <p class="data-item font-poppins m-0 p-0">Jueves 23/12/2020</p>
                        </div>
                      </div>
                    </div>
                    <div class="price-type-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Adult $345 -</p>
                          <i class="icon-adults"></i>
                        </div>
                      </div>
                    </div>
                    <div class="price-colum pl-3">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Precio <br> USD 650 -</p>
                        </div>
                      </div>
                    </div>
                    <div class="select-option">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Select</p>
                          <select name="" id="" class="form-control select-cant-item mt-2">
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-0">
                    <div class="ticket-colum">
                      <div class="row">
                        <div class="col-12">
                          <ul class="nav">
                            <li>
                              <p class="title-item font-poppins m-0 p-0" style="margin-top: 5px !important;">Incluye</p>
                            </li>
                            <li class="ml-3">
                              <i class="icon-tickets"></i>
                            </li>
                            <li class="ml-2">
                              <i class="icon-transports"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="date-colum">
                      <div class="row">
                        <div class="col-12">
                          <a class="others-item font-poppins m-0 p-0 font-verde cursor-pointer">Ver otras fechas</a>
                        </div>
                      </div>
                    </div>
                    <div class="price-type-colum">
                      <div class="row">
                        &nbsp;
                      </div>
                    </div>
                    <div class="price-colum">
                      <div class="row">
                        &nbsp;
                      </div>
                    </div>
                    <div class="opciones-colum">
                      <div class="row">
                        &nbsp;
                      </div>
                    </div>
                    <div class="select-option">
                      <div class="row">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-3">
                    <div id="{{'accordion'+i}}">
                      <!-- Descripción -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingTwo'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseTwo}" data-toggle="collapse"
                          [attr.data-target]="'#collapseTwo'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseTwo'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=!collapse.collapseTwo;collapse.collapseThree=false">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            DESCRIPCIÓN
                          </button>
                          <i class="i-list float-right mt-1 mr-3"></i>
                        </div>
                        <div id="{{'collapseTwo'+i}}" class="collapse" [attr.aria-labelledby]="'headingTwo'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body bg-description">
                            <p class="title-description font-poppins mb-0">Titulo Descripción</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Facere nisi iusto debitis repudiandae, ut quae ipsum, nam neque quo
                              consequatur beatae dolorum blanditiis expedita, sunt laudantium. Iusto totam voluptate
                              enim!</p>
                            <br>
                            <p class="check-description font-poppins mb-0">HORARIOS CHECK</p>
                            <p class="check-description font-poppins mb-0">Check in: 4 pm</p>
                            <p class="check-description font-poppins mb-0">Check out: 10 am</p>
                            <br>
                            <p class="alert-description title-description font-poppins mb-0">Salidas Anticipadas</p>
                            <p class="alert-description text-description font-poppins mb-0">Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Eum quibusdam labore, mollitia consequatur porro alias nulla
                              repudiandae corporis. Aut, quas.</p>
                            <br>
                            <p class="title-description font-poppins mb-0">Otro texto</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Voluptatem tempora vel non iste omnis fugit fuga eius porro quos.
                              Quisquam tempore deleniti necessitatibus fugiat blanditiis, perferendis eos explicabo
                              delectus non!</p>
                          </div>
                        </div>
                      </div>
                      <!-- Politicas de Cancelacion -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingThree'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseThree}" data-toggle="collapse"
                          [attr.data-target]="'#collapseThree'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseThree'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=false;collapse.collapseThree=!collapse.collapseThree">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            POLÍTICAS DE CANCELACIÓN Y PENALIDADES
                          </button>
                          <i class="i-cancel float-right mt-1 mr-3"></i>
                        </div>
                        <div id="{{'collapseThree'+i}}" class="collapse" [attr.aria-labelledby]="'headingThree'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body bg-description">
                            <p class="title-description font-poppins">Titulo Politicas de cancelación</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Facere nisi iusto debitis repudiandae, ut quae ipsum, nam neque quo
                              consequatur beatae dolorum blanditiis expedita, sunt laudantium. Iusto totam voluptate
                              enim!</p>
                            <br>
                            <p class="check-description font-poppins mb-0">Items</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <br>
                            <p class="title-description font-poppins">Algun otro titulo u apartado</p>
                            <p class="text-description font-poppins">Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Voluptatem tempora vel non iste omnis fugit fuga eius porro quos. Quisquam tempore
                              deleniti necessitatibus fugiat blanditiis, perferendis eos explicabo delectus non!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column mb-2">
                  <div class="d-flex flex-row total-bottom">
                    <div class="col-8 my-auto">
                      <p class="font-poppins total-location mb-0"><span class="total-day">2 PARK TO PARK</span> Universal Orlando</p>
                    </div>
                    <div class="col-4 my-auto">
                      <p class="font-poppins total-price text-right mb-0 pr-3">Total: $790</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 mt-3" id="description" [ngClass]="{'d-block' : tab == '#description', 'd-none' : tab !== '#description'}">
        <div class="row">
          <div class="col-12">
            <div class="item-room item-room-description d-flex flex-column mb-2">
              <div class="d-flex flex-row">
                <div class="description-park-themes">
                  <p class="title-item font-poppins">2 PARK EXPLORER TICKET</p>
                  <p class="font-poppins text-description">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam earum nesciunt illo aperiam reprehenderit repellendus vel accusamus explicabo hic, quaerat unde dicta id ab neque cupiditate ea! Adipisci, aspernatur dolorum.</p>
                  <p class="title-item font-poppins">OPERADOR <br> Hotel Beds Tour</p>
                  <p class="title-item font-poppins">INFORMACIÓN GENERAL</p>
                  <p class="font-poppins text-description">- Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quis eos numquam. Nemo esse omnis in cumque ex, illo ea?</p>
                  <p class="font-poppins text-description">- Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quis eos numquam. Nemo esse omnis in cumque ex, illo ea?</p>
                  <p class="font-poppins text-description">- Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quis eos numquam. Nemo esse omnis in cumque ex, illo ea?</p>
                  <p class="font-poppins text-description">- Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quis eos numquam. Nemo esse omnis in cumque ex, illo ea?</p>
                </div>
                <div class="includes-park-themes">
                  <ul class="nav flex-column includes-list mx-auto">
                    <li>
                      <i class="icon-check"></i>
                      <span class="title-item font-poppins ml-2">INCLUDE</span>
                    </li>
                    <li>
                      <i class="icon-transports"></i>
                      <span class="ml-2">Transport</span>
                    </li>
                    <li>
                      <i class="icon-tickets"></i>
                      <span class="ml-2">Tickets</span>
                    </li>
                  </ul>
                  <ul class="nav flex-column mt-3 includes-list mx-auto">
                    <li>
                      <i class="icon-cancel"></i>
                      <span class="title-item font-poppins ml-2">NO INCLUDE</span>
                    </li>
                    <li>
                      <i class="icon-point"></i>
                      <span class="ml-2">Comidas</span>
                    </li>
                    <li>
                      <i class="icon-point"></i>
                      <span class="ml-2">Bebidas</span>
                    </li>
                  </ul>
                  <p class="font-poppins mt-5 title-item alert-description">A partir de Abril 2020, el ticket Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, aperiam!</p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">2 PARK TO PARK</span> UNIVERSAL ORLANDO</p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $790</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-12 mt-3" id="imagenes" [ngClass]="{'d-block' : tab == '#imagenes', 'd-none' : tab !== '#imagenes'}">
        <div class="row">
          <div class="col-12">
            <div class="item-room d-flex flex-column mb-3">
              <div class="d-flex flex-row">
                <div class="col-12 px-0 content-imgSelect">
                  <img [src]="listImg[imgSelect]" class="img-gallery" alt="">
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-12 px-0 mt-3 mb-5">
                  <div class="d-flex bg-description pt-2 px-3">
                    <p class="epigrafe">{{stingUpperCase(ptheme.title)}}</p>
                  </div>
                  <div class="d-flex bg-description pt-2 px-4 content-miniatura">
                    <!-- left scroll -->
                    <i class="scroll-left" *ngIf="listImg.length > 5" (click)="scroll('l')"></i>

                    <div class="d-flex justify-content-between slide-miniatura" id="slide-miniatura">
                      <img [src]="img" class="img-miniatura mr-3" *ngFor="let img of listImg; index as j" [ngClass]="{selectedImg: imgSelect==j}" (click)="imgSelect=j">
                    </div>

                    <!-- right scroll -->
                    <i class="scroll-right" *ngIf="listImg.length > 5" (click)="scroll('r')"></i>
                  </div>
                  <div class="d-flex bg-description justify-content-center py-4">
                    <span class="font-poppins pagination-img">
                      {{imgSelect+1 +' of '+ listImg.length}}
                    </span>
                    <a class="ml-5 pagination-btn" (click)="prev()" title="previous">
                      <i class="i-arrow-left" *ngIf="imgSelect>0"></i>
                      <i class="i-arrow-left-gray" *ngIf="imgSelect==0"></i>
                    </a>
                    <a class="ml-5 pagination-btn" (click)="next()" title="next">
                      <i class="i-arrow-right" *ngIf="imgSelect<(listImg.length-1)"></i>
                      <i class="i-arrow-right-gray" *ngIf="imgSelect==(listImg.length-1)"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mb-2">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">2 PARK TO PARK</span> Universal Orlando</p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $790</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3" id="mapa" [ngClass]="{'d-block' : tab == '#mapa', 'd-none' : tab !== '#mapa'}">
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-column mb-2">
              <div class="d-flex flex-row">
                <div class="col-12 content-dir py-2">
                  <p class="direccion-maps mb-0">Universal Blvd, 6725 Adventure Way, Orlando</p>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-12 p-0">
                  <!-- Falta Insertar Mapa -->
                  <img src="/assets/img/app/maps-modal.png" class="img-map" alt="maps">
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-12 p-0">
                  <p class="title-description">Titulo Ubicación</p>
                  <p class="description-maps">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione dolorem atque ad quod, qui laudantium exercitationem ex quibusdam nisi sit commodi praesentium velit et ducimus repellendus dolorum laboriosam in blanditiis.</p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mb-2">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">2 PARK TO PARK</span> Universal Orlando</p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $790</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-tabs end -->
    <div class="row mt-3 pb-4">
      <div class="col-12">
        <button class="btn btn-continue font-poppins float-right">CONTINUE</button>
      </div>
    </div>
  </div>
</div>
