import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-disney-detail-summary',
  templateUrl: './item-disney-detail-summary.component.html',
  styleUrls: ['./item-disney-detail-summary.component.scss']
})
export class ItemDisneyDetailSummaryComponent implements OnInit {
  @Input() item: any;
  constructor() { }

  ngOnInit() {
    console.log('my item', this.item);
  }

}
