<div class="t-content" style="position: relative; width: 793px;">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <input matInput placeholder="Destination" style="visibility:hidden; width: 1px;" maxlength="1" name="destino" #destination
      formControlName='destination'>
    <div class="form-row mr-3 pb-5 d-flex flex-row justify-content-xl-between">
      <div class="form-row">
        <div class="form-group pr-3" style="width:373px">
          <input type="text" formControlName="arrivalDateDepartureDate" autocomplete="off"
            class="form-control input-search-box" ngxDaterangepickerMd placeholder="  Check-in - check-out"
            [locale]="{applyLabel: 'ok', format: 'DD/MM/YYYY'}" startKey="start" endKey="end" [(ngModel)]="dateRange" [autoApply]="true"
            [closeOnAutoApply]="true" [minDate]="minDate" [maxDate]="maxDate" name="daterange" />
        </div>
      </div>
      <input matInput placeholder="Destination" style="visibility:hidden; width: 1px;" maxlength="1" name="destino" #destination
        formControlName='destination' value="Miami">
      <div class="form-group col-xg-6 col-lg-6 col-md-12 col-sm-12">
        <button type="button" class="form-control btn-usb-rp" (click)="_openModal()" [attr.aria-expanded]="!isCollapsed"
          aria-controls="false">
          {{roomsAndPassengers}}
          <span class="arrow-sort fa fa-sort"></span>
        </button>
        <div *ngFor="let room of _form.value['room']; let i = index">
          <span class="help-block text-danger" *ngIf="((validate || (_close && isCollapsed)) && (room['qtyAdults'] == 0))">At least select 1
            adult at room {{i+1}}.</span>
        </div>
        <div (click)="_over()" (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close"
          class="justify-content-end">
          <div class="row">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100">
              <div class="card">
                <div formArrayName="room" *ngFor="let item of formRoom(_form, 'room'); let i = index"
                  class="d-flex flex-row col-12 align-items-center justify-content-between p-2">
                  <div [formGroupName]="i" class="d-flex flex-row align-items-start justify-content-between p-0" style="width: 100%;">
                    <div class="p-2 mr-2" style="padding:25px 0 0 0 !important; width: 50px;">Room {{i + 1}}
                    </div>
                    <div class="d-flex flex-column p-0 m-0 mr-2">
                      <label for="adults">Adults</label> <!-- [value]="1"-->
                      <select formControlName="qtyAdults" class="custom-select form-control-sm " id="adults" name="adults">
                        <option *ngFor="let qty of _maxAdults" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <div class="d-flex flex-column p-0 m-0 mr-2">
                      <label for="childs">Children</label>
                      <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs"
                        (ngModelChange)="_addChild(item)" name="childs">
                        <!-- [value]="0"-->
                        <option *ngFor="let qty of _maxChildren" [value]="qty">{{qty}}</option>
                      </select>
                    </div>

                    <div id="childrenAges" class="d-flex flex-column" *ngIf="item.get('childsage').length > 0" style="min-width:100px;">
                      <label for="age">Edad niños</label>
                      <div class="d-flex flex-row p-0 m-0">
                        <div formArrayName="childsage" *ngFor="let age of formRoom(item, 'childsage'); let j = index;" class="select-ages">
                          <div [formGroupName]="j" class="p-0">
                            <input formControlName="age" min=0 max=17 limit-to="14" class="form-control mr-1 p-3 input-age"
                              value-formatter="0" placeholder="0" name="age">
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let age of formRoom(item, 'childsage'); let j = index;">
                        <span class="help-block text-danger d-flex w-100 text-nowrap"
                          *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>Age
                          of children {{j+1}} is incorrect</span>
                      </div>
                    </div>
                    <div class="p-0 d-flex ml-auto" style="margin-top: 29px;">
                      <button (click)="_removeRoom(i)" class="btn btn-outline-danger col-12 input-age btn-sm"
                        [disabled]="_form.get('qtyProduct').value===1">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-between flex-row">
                  <div class="p-2 justify-content-start">
                    <button type="button" [disabled]="_form.get('qtyProduct').value===7" (click)="_addRoom()"
                      class="btn btn-outline-danger btn-sm">
                      Add Room
                    </button>

                    <span class="errors" *ngIf="_form.get('qtyProduct').value===7"><br> The maximum
                      amount of rooms is 7</span>
                  </div>
                  <div class="p-2 justify-content-end">
                    <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                      class="btn btn-outline-danger btn-sm" type="button">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
      </div>
      <div class="col-3">
        <!---  [disabled]="(!_form.valid || _findData  || userService.itineraryActive.length == 0)" -->
        <button class="btn btn-buscar" type="submit" style="margin-left:14px">
          <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
          BUSCAR
        </button>
      </div>
    </div>
  </form>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fa1a1a" type="line-scale">
</ngx-spinner>
