<div id="bg-summary m-0">
  <div class="top-summary" style="background-color: #eeeef4! important; padding-bottom: 30px;"
  [ngStyle]="{ 'background-image': 'url(\'' + routeUploadFile + sectionSummary?.background_image + '\')'}">
    <div class="resumen p-4">
      <usb-yourtrip-summary *ngIf="_actualItinerary"
        (resetItemToPartial)="_resetItemToPartial($event)"
        (partialReserveEmitter)="onChange($event)"
        [qtyItemsToPartial]="qtyItemsToPartial"
        [actualItinerary]="_actualItinerary">
      </usb-yourtrip-summary>
    </div>
    <div class="resumen-itinerary p-4 mt-4">
      <!-- Panel -->
      <!-- <article class="panel panel-danger panel-outline row w-100"> -->
        <!-- Date Fly -->
          <usb-flight class="w-100" *ngIf="_flightInfo" [_flightInfo]="_flightInfo" [_start]="1"></usb-flight>
        <!-- /Date Fly -->
      <!-- </article>
      <article class="panel panel-danger panel-outline row w-100"> -->
        <!-- destiny -->
        <usb-destiny-summary (itemToPartial)="_itemToPartial($event)" [partialReserve]="partialReserve" *ngIf="_actualItinerary" [actualItinerary]="_actualItinerary" [qtyItemsToPartial]="qtyItemsToPartial" [suggestions] = "suggestions"  class="w-100">
        </usb-destiny-summary>
        <!-- /destiny -->
      <!-- </article>
      <article class="panel panel-danger panel-outline row w-100"> -->
        <!-- Date Fly -->
        <usb-flight class="w-100" *ngIf="_flightInfo" [_flightInfo]="_flightInfo" [_start]="0"></usb-flight>
        <!-- /Date Fly -->
      <!-- </article> -->
    </div>
  </div>
</div>

 <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size = "medium"  color="white" type="line-scale">
  <p style="position:absolute; font-size: 22px;color: white"> Cargando... </p>
</ngx-spinner>
