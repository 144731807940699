<!-- <div class="width-100" *ngIf="isCheckIn && dato"> -->
    <!-- <div class="row justify-content-between mb-3">
        <div class="col-2 text-right pr-0 my-auto">
          <span class="circle">
            <i class="icon-small icon-bus iconstart text-center pl-1"></i>
          </span>
        </div>
        <div class="col p-0 ml-2 item-detail">
          <div class="d-flex ">
            <div class="col-xg-11 col-lg-11 px-0 m-0 flex-column font-descrip">
              <span class="title-item d-flex align-items-center">
                <span class="pr-3">{{item.title}}</span>
                <i *ngFor="let star of generateStars(dato.categoryHotel)" class="icon-estrella pr-1"></i>
              </span>
              <span class="p-0 spaced">
                <span>
                  {{item.location}}
                </span>
              </span>
              <span class="p-0 spaced d-flex">
                <span>5 Seating | 3 Bags | Diurno </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-4 text-center">
          <span classs="align-items-center d-flex ml-5 pl-5 pr-0" id="expiration-date">
            21 DEC 2018
          </span>
        </div>
      </div> -->

<hr class="mt-1" style="border-top: 1px dotted  #4e4e4e !important;" *ngIf="i >= 1">
  
<div class="row p-2">
  <div class="col-9 item-descrption">
    <div class="row">

      <div class="col-1 p-0 d-flex justify-content-center">
        <div class="d-flex circle align-items-center justify-content-center">
          <i class="icon-small icon-bus iconstart text-center pl-1"  style="font-size: 25px;"></i> 
        </div>
      </div>

      <div class="col-11">
        <h5 class="font-weight-bold m-0 text-capitalize" style="font-size: 16px;">{{item.title}}</h5>
        <p class="font-weight-bold m-0" style="font-size: 13px;">{{item.destination.iata_code}} - US</p>
        <p class="font-weight-light m-0" style="font-size: 10px;">CITYTOUR</p>
        <div class="row m-0">
          <p class="m-0"> <span class="font-weight-bold">Date Pick up:</span> {{ item.arrivalDate | date:'mediumDate' }} </p>
          <p class="m-0 ml-4"> <span class="font-weight-bold">Date Pick up:</span> {{ item.departureDate | date:'mediumDate' }} </p>
        </div>
      </div>

    </div>
  </div>

  <div class="col-3">
    <div class="p-2 d-flex justify-content-between align-items-center" style="background-color: #00d397;border-radius: 5px;">
      <!-- <h5 class="m-0 font-weight-bold text-white"> Save </h5> -->
      <span class="icon-check"></span>
      <h5 class="m-0 font-weight-bold text-white" style="font-size: 14px;"> 
        {{item.partial_selected==1 ? "Reserved" : "Saved" }} 
      </h5>
    </div>
    <div class="text-right mt-2" style="font-size: small;color: #d20358;">
      <p class="font-weight-light m-0"> EXPIRATION DATE </p>
      <p class="font-weight-bold m-0"> {{item.expirationDate ? (item.expirationDate | date:'mediumDate') : '' }} </p>
    </div>
  </div>
</div>