<form [formGroup]="_form">
  <div class="row font-poppins">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12">
      <span class="mb-3" style="font-size: 1.65rem;">SELECT ROOM FOR PASSENGER</span>
      <accordion [closeOthers]="true" class="mt-3">
        <accordion-group formArrayName="paxes" *ngFor="let adult of paxes; let i = index " [isOpen]="i == 0 ? true : false"
          style="margin-bottom: 10px;">
          <div accordion-heading>
            <div class="row ml-0 mr-0 mb-1">
              <div class="col-8 pl-0 pr-1">
                <div class="p-2 d-flex align-items-center justify-content-between pax-header" style="height: 53px;">
                  <div class="pull-left float-left text-uppercase ml-4 title-accord">{{adult.title}}</div>
                  <i class="fas fa-caret-down mr-2"></i>
                </div>
              </div>
              <div class="col-4 p-2 d-flex justify-content-between align-items-center checker" style="border-radius: 5px;cursor: pointer;"
                [ngClass]="{'checker-green': checkProductConfirm(i)}">
                <object *ngIf="!checkProductConfirm(i)" type="image/svg+xml" data='/assets/img/app/little-icons/timer_bco.svg' width="30px"
                  height="30px"></object>
                <span *ngIf="checkProductConfirm(i)" class="icon-check"></span>
                <span class="state" *ngIf="checkProductConfirm(i)">Ready</span>
                <span class="state" *ngIf="!checkProductConfirm(i)">Pending</span>
              </div>
            </div>
          </div>
          <div [formGroupName]="i" class="pr-3">
            <div class="row mb-2">
            </div>
            <div class="row ">
              <div class="col-5 text-lg-left">
                <span class="font-weight-bold pl-3 font-poppins" style="font-size: small;">Please select a gender</span>
                <br>
                <div class="d-flex justify-content-start pl-2 mt-2">
                  <div class="cc-selector">
                    <input checked="checked" id="{{adult.type=='adult' ? 'man'+i : 'boy'+i}}" type="radio" formControlName="gender"
                      name="gender" value="M" />
                    <div class="" style="cursor: pointer; display: inline-block; width: 70px;height: 70px;">
                      <label for="{{adult.type=='adult' ? 'man'+i : 'boy'+i}}">
                        <span *ngIf="adult.type=='adult'"
                          class="{{_form.value.paxes[i].gender==='M' ? 'icon-man-green' : 'icon-man-grey' }}"></span>
                        <span *ngIf="adult.type!='adult'"
                          class="{{_form.value.paxes[i].gender==='M' ? 'icon-boy-green' : 'icon-boy-grey' }}"></span>
                      </label>
                    </div>
                    <span id="orGender">OR</span>
                    <input id="{{adult.type=='adult' ? 'lady'+i : 'girl'+i}}" type="radio" formControlName="gender" name="gender" value="F" />

                    <div style="cursor: pointer;display: inline-block;width: 70px;height: 70px;">
                      <label for="{{adult.type=='adult' ? 'lady'+i : 'girl'+i}}">
                        <span *ngIf="adult.type=='adult'"
                          class="{{_form.value.paxes[i].gender==='F' ? 'icon-girl-green' : 'icon-girl-grey' }}"></span>
                        <span *ngIf="adult.type!='adult'"
                          class="{{_form.value.paxes[i].gender==='F' ? 'icon-child-green' : 'icon-child-grey' }}"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center sm-gutters">
                <div style="width: 100%;">
                  <div class="float-left" style="width: 88%;">
                    <div class="form-group">
                      <select #selectClient id="my-select" class="form-control custom-select select-clientes"
                        (change)="selectClientFunction(selectClient.value,i)" [disabled]="i == 0">
                        <option disabled selected> Select Client </option>
                        <ng-container *ngFor="let client of clients">
                          <ng-container *ngIf="client.relation_ship == 'Child' && adult.type!='adult'">
                            <option *ngIf="client.selected && client.paxSelect == i" selected disabled>
                              {{client.first_name}} {{client.last_name ? client.last_name : ''}}
                            </option>
                            <option [value]="toStr(client)" *ngIf="!client.selected">
                              {{client.first_name}} {{client.last_name ? client.last_name : ''}}
                            </option>
                          </ng-container>
                          <ng-container *ngIf="client.relation_ship != 'Child' && adult.type=='adult'">
                            <option *ngIf="client.selected && client.paxSelect == i" selected disabled>
                              {{client.first_name}} {{client.last_name ? client.last_name : ''}}
                            </option>
                            <option [value]="toStr(client)" *ngIf="!client.selected">
                              {{client.first_name}} {{client.last_name ? client.last_name : ''}}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="float-right">
                    <button (click)="resetClient(i)" [disabled]="!verifyClientExist(i)"
                      class="btn float-right p-0 d-flex justify-content-center align-items-center"
                      style="cursor:pointer;width: 44px; height: 42px; border-radius: 4px; background-color: #ffffff; border: 1px solid #ced4da;">
                      <i class="icon-cesto"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2 pl-3">
              <div class="col-5 sm-gutters pl-3">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="title" style="font-size: small;">Title and first Name*</label>
                  <div class="row d-flex justify-content-between m-0">
                    <select formControlName="title" class="form-control custom-select" style="width: 33%;">
                      <option value="null" selected disabled>Title</option>
                      <option *ngFor="let title of titles" [value]="title.id">
                        {{title.name}}
                      </option>
                    </select>
                    <input formControlName="first_name" type="text" class="form-control" id="first_name" autocomplete="off"
                      style="width: 65%;" placeholder="First Name">
                  </div>
                </div>
              </div>
              <div class="col-3 sm-gutters">
                <div class="form-group">
                  <label for="last_name" class="font-weight-bold font-poppins" style="font-size: small;">Last Name *</label>
                  <input formControlName="last_name" type="text" class="form-control" id="last_name" autocomplete="off"
                    placeholder="Last Name">
                </div>
              </div>
              <ng-container *ngIf="i == 0">
                <div class="col-4 sm-gutters">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins" style="font-size: small;">Date of birthday *</label>
                    <input formControlName="birth_date" type="text" class="form-control" id="birth_date" bsDatepicker readonly
                      [maxDate]="maxDateAdult" [bsConfig]="dpConfig" autocomplete="off" placeholder="Date" *ngIf="adult.type=='adult'">
                    <input formControlName="birth_date" type="text" class="form-control" id="birth_date" bsDatepicker readonly
                      [minDate]="minDateChild" [maxDate]="maxDateChild" [bsConfig]="dpConfig" autocomplete="off"
                      *ngIf="adult.type=='children'" placeholder="Date">
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="i > 0">
                <div class="col-2 sm-gutters">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins" style="font-size: small;">Date of birthday *</label>
                    <input formControlName="birth_date" type="text" class="form-control" id="birth_date" bsDatepicker readonly
                      [maxDate]="maxDateAdult" [bsConfig]="dpConfig" autocomplete="off" placeholder="Date" *ngIf="adult.type=='adult'">
                    <input formControlName="birth_date" type="text" class="form-control" id="birth_date" bsDatepicker readonly
                      [minDate]="minDateChild" [maxDate]="maxDateChild" [bsConfig]="dpConfig" autocomplete="off"
                      *ngIf="adult.type=='children'" placeholder="Date">
                  </div>
                </div>
                <div class="col-2 sm-gutters">
                  <div class="form-group">
                    <label class="font-weight-bold font-poppins" for="last_name" style="font-size: small;">Relationship
                      *</label>
                    <select formControlName="relationShip" name="relationShip" class="form-control custom-select">
                      <option *ngFor="let title of relations" [value]="title.id">
                        {{title.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row mt-2 pl-3">
              <div class="col-5 sm-gutters pl-3">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="document" style="font-size: small;">Document and
                    document Number*</label>
                  <div class="row d-flex justify-content-between m-0">
                    <select formControlName="document" id="document" class="form-control custom-select" style="width: 33%;">
                      <option value="null" selected disabled>Type Doc</option>
                      <option value="4">Passport</option>
                    </select>
                    <input formControlName="document_number" type="text" class="form-control" style="width: 65%;" id="document_number"
                      autocomplete="off" placeholder="Code">
                  </div>
                </div>
              </div>
              <div class="col-3 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="email" style="font-size: small;">Email*</label>
                  <input formControlName="email" type="text" class="form-control" id="email" placeholder="Email">
                </div>
              </div>
              <div class="col-2 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="phone" style="font-size: small;">Phone</label>
                  <input formControlName="phone" class="form-control" type="text" id="phone" placeholder="Phone">
                </div>
              </div>
              <div class="col-2 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="phone_2" style="font-size: small;">Phone 2</label>
                  <input class="form-control" type="text" id="phone_2" formControlName="phone_2" autocomplete="off" placeholder="Phone 2">
                </div>
              </div>
            </div>
            <div class="row pl-3">
              <div class="col-2 pl-3 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="country" style="font-size: small;">Country *</label>
                  <select id="my-select" class="form-control custom-select" name="" formControlName="country" id="country"
                    autocomplete="off" (change)="getStates(i)">
                    <option value="null" selected disabled>Country</option>
                    <option *ngFor="let country of countries" [ngValue]="country">
                      {{country.ZoneName}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-2 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="postal_code" style="font-size: small;">Postal Code</label>
                  <input type="text" class="form-control" id="postal_code" formControlName="postal_code" autocomplete="off"
                    placeholder="Postal Code">
                </div>
              </div>
              <div class="col-2 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="state" style="font-size: small;">State *</label>
                  <select id="my-state" class="form-control custom-select" name="" formControlName="state" id="state" autocomplete="off">
                    <option value="null" selected disabled>State</option>
                    <option *ngFor="let state of states[i].states" [ngValue]="state">
                      {{state.ZoneName}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-2 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="city" style="font-size: small;">City *</label>
                  <input type="text" class="form-control" id="city" formControlName="city" autocomplete="off" placeholder="City">
                </div>
              </div>
              <div class="col-4 sm-gutters">
                <div class="form-group">
                  <label class="font-weight-bold font-poppins" for="address" style="font-size: small;">Address</label>
                  <input type="text" class="form-control" id="address" formControlName="address" autocomplete="off" placeholder="Address">
                </div>
              </div>
            </div>
          </div>
        </accordion-group>
      </accordion>
    </div>
    <div class="row d-flex justify-content-between mt-4 bt-4" 
     style="margin-top: 100px !important;   margin-left: 297px !important;">
      <div class="d-flex ml-auto justify-content-end px-0" style="position: absolute;
      padding-top: 77px;
      ">
        <button class="btn btn-action btn-back ml-2" (click)="goBack()"  > <!--  (click)="goBack()" *ngIf="step > 1 && step < 5"-->
          BACK
        </button>
        <div class="d-flex justify-content-end" > <!-- *ngIf="step > 1 && step < 5"-->  
          <button  (click)="_onSubmit($event)" [disabled]="!_form.valid" class="btn btn-action btn-continue ml-2"> <!--  (click)="next()" *ngIf="step < 5"-->
            CONTINUE
          </button>
        </div>
      </div>
    </div>
   <!--- <div class="col-4 col-md-4 col-lg-4 col-xl-4">
      <span class="mb-3" style="font-size: 1.65rem;color: #00d397;">SUMMARY</span>
      <div class="pb-5 pt-2 px-2" style="background-color: #3c3c3c; border-radius: 5px;">
        <button (click)="_onSubmit($event)" [disabled]="!_form.valid" class="btn btn-danger btn-block" style="height: 70px; cursor: pointer;">
          <span *ngIf="permitedConfiguration">CONTINUE</span>
          <span *ngIf="!permitedConfiguration">RESERVE</span>
        </button>
      </div>
    </div> -->
  </div>
</form>