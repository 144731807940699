import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ShellComponent } from './core/shell/shell.component';

const routes: Routes = [
  // Fallback when no prior route is matched
  // { path: '**', redirectTo: '', pathMatch: 'full' },
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  // {
  //   path: '',
  //   component: ShellComponent,
  // },
  
  // {
  //   path: '',
  //   component: AppComponent,
  // },


  //New Routings
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  /// { path: 'payments', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule) },
  { path: '**', redirectTo: '', pathMatch: 'full' },
  //{ path: 'home', loadChildren: () => import('../app/home/home.module').then(m => m.HomeModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
