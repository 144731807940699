import { Input, Directive } from '@angular/core';

@Directive({
  selector: '[limit-to]', 
  host: {
    '(keyup)': '_onKeypress($event)',
    '(keydown)': '_onKeypress($event)',
  }
})
export class LimitToDirective {
  @Input('limit-to') limitTo;
  _onKeypress(e) {
    const limit = +this.limitTo;
    e.target.value = parseInt(e.target.value);
    if (e.target.value == 'NaN') {
      e.target.value = '';
    }
  }
}