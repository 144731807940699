import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { TruncateModule } from "ng2-truncate";
import { ResultsSearchService } from "../../../../home/results-search.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ModalsComponent } from "../../../../shared/modals/modals.component";
import { MatDialog } from "@angular/material/dialog";
import { ItineraryService } from "../../../../shared/itinerary.service";
import * as moment from 'moment';
import { ModalViewVideComponent } from "../../../../shared/modal-view-video/modal-view-video.component";
import { RestService } from "../../../../shared/rest.service";
import { setting } from "../../../../../../setting";
import { UserService } from "app/pages/com.usblick.common/auth/user.service";

@Component({
  selector: "usb-hotel-result",
  templateUrl: "./hotel-result.component.html",
  styleUrls: ["./hotel-result.component.scss"],
})
export class HotelResultComponent implements OnInit {
  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;
  @Output() coordinatesClicked = new EventEmitter<any>();
  modal: boolean = false;
  modalRef: BsModalRef;
  ModalRooms: any;
  @Input() mapView: Boolean = false;
  addIcon = "add_circle";

  netPriceProf = 0;
  percentage = 0;
  blickoins = 0;
  creditBlickoins = 0;
  view_hotel = 0;
  isview_hotel = false;
  ameninitiesResul = [];
  viewUpdate:string;

  optionNightsNetTotalProf:number = 0;
  countValueDot:number = 0;

  public getClientsSettingAppBooking: any;
  showMoreButton: boolean = false;

  constructor(
    public serviceItinerary: ItineraryService,
    private searchService: ResultsSearchService,
    public _restService: RestService,
    private modalService: BsModalService,
    private dialog: MatDialog,
    private userService: UserService
  ) {
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));

    }

  ngOnInit() {
// console.log(this.result)
    this.view_hotel = this.result.view_hotel;
    // console.log(this.result);
    if (this.origin === null || this.origin === undefined) {
      this.origin = this.searchService._getOrigin();
    }
    this.getAmenitiesHotelResul();

    this.multiplyValue();
    this.getValueAfterDot();

  }


  getAmenitiesHotelResul() {
    if(this.result && this.result.additionalInfo) {
      this.ameninitiesResul = this.result.additionalInfo.amenities.filter(function (amenite: any) {
        return amenite.onlist == 1 && amenite.ame_type === 'hotel';
      })
        .map(function (amenite: any) {
          return amenite;
        });
    }
  }

  stars() {
    if(this.result && this.result.additionalInfo) {
    const array = [];
    const item = Number.parseInt(this.result.additionalInfo.starRating);
    for (let index = 0; index < item; index++) {
      array.push(index);
    }
    return array;
  }
  }

  openVideoModal(data: any) {
    const idVideo = this.getIdVideoYt(data);

    var dialogRef = this.dialog.open(ModalViewVideComponent, {
      width: "700px",
      data: idVideo,
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getIdVideoYt(url:any) {
    const getIdVideo = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/;
    const match = url.match(getIdVideo);
    return match ? match[1] : null;
  }


  // open modal aDD
  openModal(template: TemplateRef<any>, tab: any) {
    console.log('payload open modal', this.payload);
    this.modal = true;
    this.updteView();
    var dialogRef = this.dialog.open(ModalsComponent, {
      height: "680px",
      width:  "920px",
      panelClass: 'custom-container',
      data: {
        modalRoomRef: this.modalRef,
        product: this.result,
        departureDate:this.payload.departureDate,
        productQty: this.payload.qtyProduct,
        form: this.form,
        origin: "HOTELS",
        arrayResults: this.arrayResults,
        tabActive: tab,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  updteView() {
    console.log(this.result.id)
    console.log(this.result.searchId)
    let data = "";
    console.log(data)
    if ( !this.isview_hotel ) {
      this._restService.doPut("app", "products/updateView/" + this.result.id+"/"+ this.result.searchId, data).subscribe(
        (response: any) => {
          console.log('response ->', response);

          if (response.success) {
            this.view_hotel = response.res.qtyview;
            this.isview_hotel = true;
          }
        },
        (error) => {
          console.log("Save-Error: " + error);
        }
      );
    }
  }


  multiplyValue():void{
    if(this.result && this.result.additionalInfo) {
      const valueForMultiply =  this.payload.others.hotels.roomConfiguration.length;
      const netPriceProf = this.result.additionalInfo.rooms[0]?.room?.optionNightsNetTotalProf;
      this.optionNightsNetTotalProf = valueForMultiply * netPriceProf;
    }
  }

  getValueAfterDot(){
    if(this.result && this.result.additionalInfo) {
    let qntyRooms = this.payload.others.hotels.roomConfiguration.length;
    let optionNights = this.result?.additionalInfo?.rooms[0]?.room?.optionNightsNetTotalProf
    const valueDot = Math.floor(Math.abs(optionNights * qntyRooms));

    this.countValueDot = valueDot.toString().length;
    }
  }

  getHotelChoiceID(hotelSelected: any) {
    this.coordinatesClicked.emit(hotelSelected);
  }
}
