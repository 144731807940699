<div class="card card-info d-flex flex-row my-3 ">
  <div class="col-8 flex-column p-2 d-flex ">
    <span class="strong flex-column"> <strong>{{option.name}}</strong></span>
    <span class="strong flex-column">
      Duration: {{option.duration}}
        <i class="icon icon-multiple-users-silhouette" aria-hidden="true"></i>
        Capacity : Adults : {{option.paxAdults}} Children : {{option.paxChild}}
      </span>
    <span class="strong pt-2">
      <strong>Description</strong>
    </span>
    <span class="large-description">{{option.description}}</span>
  </div>

  <div class="col-3 flex-column px-4 py-2 d-flex align-content-center">
    <span class="strong">
      <strong>{{option.priceChildFlag}}Price Children</strong>
    </span>
    <span class="strong">${{option.priceChild | number:'1.0-0'}}</span>

    <span class="strong">
      <strong>{{option.priceAdultsFlag}}Price Adults</strong>
    </span>
    <span class="strong">${{option.priceAdults | number:'1.0-0'}}</span>
    <span class="strong pt-2">
      <strong>{{option.optionTotalFlag}}Tarifa Neta</strong>
    </span>
    <span class="strong">${{option.optionTotal | number:'1.0-0'}}
    </span>
  </div>

  <div class="radio d-flex p-0 align-items-center justify-content-between">
    <label class="d-flex align-items-center">
      <input class="form-check-input" type="radio" name="options" id="options" value="index"  (change)="onSelectionChange(index)">
      <span class="cr">
        <i class="cr-icon fa fa-circle"></i>
      </span>
    </label>
  </div>

</div>