import { Component, OnInit, Input } from '@angular/core';
import { ItineraryService } from '../../shared/itinerary.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import * as moment from 'moment';
import { setting } from '../../../../setting';
@Component({
  selector: 'usb-summary-reservation',
  templateUrl: './summary-reservation.component.html',
  styleUrls: ['./summary-reservation.component.scss']
})
export class SummaryReservationComponent implements OnInit {

  @Input() step:string;
  @Input() itineraryItems: any = [];
  @Input() itineraryTotal: any;
  @Input() itinerary:any;

  totalTarifaNeta  :number = 0;
  totalTarifa  :number = 0;
  totalBlickoins = 0;
  dateItinerary:string = '';

  price: number;
  blickoins: number;
  totalCreditBlickoins: number;
  totalNetPriceBookingFreeTax: number;
  taxNightsPriceTotal: number;
  public taxSum: number = 0;
  public currencyItinerary: string


  public getClientsSettingAppBooking: any;

  constructor(public serviceItinerary: ItineraryService, public userService:UserService)
    {
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    }

  ngOnInit() {
    console.log(this.itinerary)
    console.log(this.itineraryItems)
    // console.log('===>', this.itineraryItems);
    // console.log('itinerary', this.itinerary);
    // console.log('destinos', this.userService.itineraryActive);

    // console.log('userservice ->', this.userService.itineraryDepartureDate);
    // console.log('itinerary-items ->', this.itineraryItems);
    this.dataTax(this.itineraryItems);
    this.itineraryItems.forEach(item => {

      const destiny = this.itinerary.destination.find(element=> element.iata_code == item.destination.iata_code);
      item.cityame = destiny.destination_info.cityName;
      this.price = item.netPriceAgent;
      this.blickoins = item.profility.blickoins;
      this.totalCreditBlickoins = item.profility.creditBlickoins;
      this.totalNetPriceBookingFreeTax = item.optionNightsNetTotalFreeTax;
      this.taxNightsPriceTotal = item.taxNightsPriceTotal;
      this.totalNetPriceBookingFreeTax =  this.itineraryItems.reduce((sum, item) => sum + parseFloat(item.optionNightsNetTotalFreeTax), 0);
      this.taxNightsPriceTotal =  this.itineraryItems.reduce((sum, item) => sum + parseFloat(item.taxNightsPriceTotal), 0);
    });

    this.totalTarifaNeta = this.itinerary.totalNetPriceBooking;
    this.totalTarifa  = this.itinerary.totalNetPriceAgent;
    this.totalBlickoins =  this.itinerary.totalBlickoins;
    this.totalCreditBlickoins = this.itinerary.totalCreditBlickoins;
    this.currencyItinerary = this.itineraryItems[0]?.currency;
    localStorage.setItem('totalNetReserved', this.itinerary.totalPriceByNetReserved);
    localStorage.setItem('totalReserved', this.itinerary.totalPriceByReserved);


      // this.getLastDateSummaryReserv();

    this.price = this.itineraryItems.reduce((sum, item) => sum + parseFloat(item.netPriceAgent), 0);
    this.blickoins = this.itineraryItems.reduce((sum, item) => sum + item.profility.blickoins, 0);
    this.totalCreditBlickoins = this.itineraryItems.reduce((sum, item) => sum + item.profility.creditBlickoins, 0);

  }

  private dataTax(data: any) {
    console.log(data)
    if (data && data.length > 0) {
      // Recorre cada producto en los datos
      data.forEach((product) => {
          // Verifica si hay taxes en el ítem
          console.log(product)
          if (product.room.pricing.taxes && Array.isArray(product.room.pricing.taxes)) {
            // Recorre cada impuesto en las taxes
            product.room.pricing.taxes.forEach((tax) => {
              // Verifica si 'included' es un booleano verdadero
              if (typeof tax.included === 'boolean' && tax.included) {
                // Si 'included' es true, suma el impuesto al total
                this.taxSum += tax.amount;
              }
            });
          }
      });
    }
    // Formatea el resultado con dos decimales
    this.taxSum = parseFloat(this.taxSum.toFixed(2));
    console.log('Suma total de impuestos:', this.taxSum);
  }


  setDateHead(date?:string) {
    if (date) {
      let dateSplit = date.split("-");
      return '' + dateSplit[2] + ' ' + moment().month(parseInt(dateSplit[1]) - 1).format('MMM-Y');
    }else{
      return '';
    }
  }


  verifyCityExist(i){

    if(this.itineraryItems[i-1]){

      if(this.itineraryItems[i-1].destination.iata_code ==  this.itineraryItems[i].destination.iata_code){

        return true;
      }else{
        return false;
      }
      //console.log("")
    }else{
      return false;
    }

  }

  // getLastDateSummaryReserv():void{
  //   let lastItemItinerary = this.itineraryItems[this.itineraryItems.length - 1];
  //   console.log(typeof lastItemItinerary, lastItemItinerary)
  //   this.dateItinerary = lastItemItinerary.arrivalDate;
  // }



}
