import { Component, OnInit, Input } from '@angular/core';
import { ItineraryService } from '../../shared/itinerary.service';
import { RestService } from '../../shared/rest.service';

@Component({
  selector: 'usb-services-details-summary',
  templateUrl: './services-details-summary.component.html',
  styleUrls: ['./services-details-summary.component.scss']
})
export class ServicesDetailsSummaryComponent implements OnInit {
  @Input() inPartial: any;
  @Input() itineraryPartialItems: any;
  @Input() itinerarySavedItems: any;
  @Input() itineraryItems: any;
  @Input() partialReserve: any;


  constructor(private itineraryService: ItineraryService, private restService: RestService) { }

  ngOnInit() {
  }

}
