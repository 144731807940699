import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { formatArrivalDate } from '../../../../../../utils/Functions';
import { ItineraryService } from '../../../../../../shared/itinerary.service';
import { RestService } from '../../../../../../shared/rest.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../../../../../../shared/material-modal-confirm/material-modal-confirm.component';
import * as moment from 'moment';
import { ModalsComponent } from '../../../../../../shared/modals/modals.component';
// import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'usb-item-tours',
  templateUrl: './item-tours.component.html',
  styleUrls: ['./item-tours.component.scss']
})
export class ItemToursComponent implements OnInit {
  @Input() dato: any;
  @Input() isCheckIn: boolean;
  @Input() partialReserve: boolean;
  @Output() itemToPartial = new EventEmitter<number>();
  @Output() itemDelete = new EventEmitter<any>();
  @Output() reSearch = new EventEmitter<any>();


  @ViewChild('modalRooms') modalRooms: ModalDirective;

  public formatDate = formatArrivalDate;
  iconsTourDisabled:boolean = false;

  constructor(private itineraryService: ItineraryService, private restService :RestService, private dialog: MatDialog) { }

  ngOnInit() {
    // console.log("dato tour", this.dato);
    this.getProduct();
  }

  getProduct(){

    if (this.dato.payloadUpdate) {
      // console.log('/search/products------------------> getProduct ItemToursComponent',  this.dato.payloadUpdate);
      this.restService.doPost('app', 'search/products', this.dato.payloadUpdate).subscribe(
        (response:any) => {
          if (response && response.status && response.results) {
            if (response.results !== undefined) {
              this.dato.product = response.results[0];
            } else {
              this.dato.product = response.results;
            }
          }

          //console.log("this.dato tour actualizado", this.dato);
        }, error => {
          console.log(error.message);
        }
      );
    }
  }

  removeItem(item: any) {
    const message = '¿Quieres eliminar este elemento?';
    var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
      width: '350px',
      data: {message: message},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result){
        this.itineraryService.remove(item);
        this.itemDelete.emit(item);
      }else{

      }
    });

    //console.log(item);
    //this.itineraryService.remove(item);
    //this.itemDelete.emit(item);
  }

  toPartialReserve(event, item: any){
    if ( event.target.checked ) {
      this.itineraryService.updateItineraryPartial(item);
      this.itemToPartial.emit(1);
    } else {
      this.itineraryService.updateItineraryPartial(item,true);
      this.itemToPartial.emit(-1);
    }
  }

  subtractDays(date){
    return moment(moment(date)).subtract(2, 'days').format('YYYY-MM-DD');
  }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  openModal() {
    var dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '800px',
      data: {
        product: this.dato.product,
        productQty: "1",
        //form: this.activeItineraryForm,
        origin: 'HOTELS',
        itineraryItem:this.dato,
        view_info:true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // if(result){


      // }else{

      // }
    });
  }

  edit(){
    console.log(this.dato);
    const message = '¿Deseas eliminar este dato y buscar un nuevo '+ this.dato.categoryName + ' en ' + this.dato.location + ' del ' + this.returnDate(this.dato.arrivalDate) + ' hasta ' +  this.returnDate(this.dato.departureDate) +' para ' + this.dato.paxAdults + ' adultos?';
    var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
      width: '665px',
      data: {message:message},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if(result){
        await this.reSearch.emit(this.dato);
        await this.itineraryService.remove(this.dato !== undefined ? this.dato : this.dato);
      }else{

      }
    });
  }

  returnDate(date){
    let dateSplit = date.split("-");
    return '' + dateSplit[2] + '-' + moment().month(parseInt(dateSplit[1]) - 1).format('MMM-Y');
  }

  fontPrice(price){
    if((price.length - 6) > 0){
      const fact = price.length - 6
      const size = 25 - (1.5 * fact)
      return size + 'px'
    }
  }
}
