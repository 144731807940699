<div style="padding-top: 40px; padding-bottom: 1em;">


<h1 mat-dialog-title style="font-family: 'Poppins', sans-serif;font-size: 18px; color: rgb(0, 211, 151); text-align: center; line-height: 1.2; font-weight: 300; padding: 0 1.5em" >{{data}}</h1>

<div class="row d-flex justify-content-center">
  <div class="col-8 d-flex justify-content-center">
    <button *ngIf="!newModal" type="button"  data-placement="bottom" class="btn btn-sm btn-ok text-uppercase text-white" style="width: 125px;"  title="Yes" (click)="dialogRef.close(true); ">OK</button>
    <button *ngIf="newModal" type="button"  data-placement="bottom" class="btn btn-sm btn-ok text-uppercase text-white" style="width: 125px;"  title="Yes" (click)="confirm()">OK</button>

  </div>
</div>
</div>
