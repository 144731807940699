import { Component, OnInit } from '@angular/core';
import { setting } from '../../../../setting';

@Component({
  selector: 'usb-visited',
  templateUrl: './visited.component.html',
  styleUrls: ['./visited.component.scss']
})
export class VisitedComponent implements OnInit {

  public getClientsSettingAppBooking: any;

  constructor() {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }

  ngOnInit() {
  }

}
