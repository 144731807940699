<div *ngIf="(_actualItinerary !== undefined)"
  class="d-flex flex-column mt-4 p-0 font-dark bd-callout  align-items-center w-100" id="resumen-pass">

  <!-- <usb-pax-list-modal class="d-flex w-100" *ngIf="_actualItinerary" [invoke]="0" [actualItinerary]="_actualItinerary"></usb-pax-list-modal>
  <usb-flight-modal class="d-flex w-100" *ngIf="_actualItinerary" [invoke]="0" [_actualItinerary]="_actualItinerary"></usb-flight-modal> -->

  <!-- <usb-pax-list-modal class="d-flex w-100" *ngIf="_actualItinerary" [invoke]="0" [actualItinerary]="_actualItinerary">
  </usb-pax-list-modal> -->
  <!-- <usb-flight-modal class="d-flex w-100" *ngIf="_actualItinerary" [invoke]="0" [modeView]="modeView" [_actualItinerary]="_actualItinerary"></usb-flight-modal> -->

</div>


<div class="ml-auto my-3 d-flex flex-column align-items-end justify-content-end" id="continue-itinerary-s">
  <button type="button" class="btn rounded-pill btn-block btn-usblick btn-big font-poppins text-uppercase" (click)="_redirectToResults(modeView)"
    [disabled]="buttonDisabled"
    [ngStyle]="{'background-color': buttonDisabled ? 'lightgray': getClientsSettingAppBooking?.clients_setting_global?.color_main_3 }"
    >
    <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
    CONTINUAR
  </button>
  <button type="button"
          class="btn rounded-pill btn-block btn-usblick btn-big font-poppins text-uppercase"
          style="background-color: black !important"
          (click)="_redirectHome()"
      >
      <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
    REGRESAR
</button>
</div>
