<div class="header">
  <p>
    LA RESERVA NO SE PUEDE REALIZAR DEBIDO A QUE EL MONTO TOTAL DE LOS PRODUCTOS <span class="icon-nr font-poppins">NR</span> SUPERO EL CRÉDITO QUE TIENES DISPONIBLE
  </p>
  <img class="img-class" src="/assets/img/app/modal-nr/NR.png" alt="">
</div>


<div class="card font-weight-bold" style="font-size: 11pt;">
  <div class="row">
    <div class="col-1">
      <div style="width: 20px;height: 20px;border-radius: 25px;background-color: darkseagreen;">

      </div>
    </div>
    <div class="col-11">
      <p>
        TE SUGERIMOS DESDOBLAR LA RESERVA DE LOS PRODUCTOS EN <span class="icon-nr font-poppins">NR</span> QUE NO TIENEN DEVOLUCIÓN PARA RESERVAR EL MÁS URGENTE DE ACUERDO A TU LÍMITE DE CRÉDITO
      </p>
    </div>
  </div>

</div>

<div class="card-2 font-poppins">

  <div class="row">

    <div class="col-8 d-flex align-items-center">
      <p>Límite de Credito</p>
    </div>
  
    <div class="col-4 d-flex align-items-center">
  
      <div class="border-amount" *ngIf="agency.pay_mode == 1">
        {{points | number:'1.2-2'}} $
      </div>

      <div class="border-amount" *ngIf="agency.pay_mode == 3">
        {{points + fidelityService.creditDepositActives | number:'1.2-2'}} $
      </div>
    </div>

    <div class="col-8 d-flex align-items-center">
      <p>Monto de los products <span class="icon-nr font-poppins">NR</span></p>
    </div>
  
    <div class="col-4 d-flex align-items-center">
  
      <div class="border-amount" >
        {{totalPriceNR | number:'1.2-2'}} $
      </div>
    </div>

    <div class="col-8 d-flex align-items-center">
      <p>Monto Faltante</p>
    </div>
  
    <div class="col-4 d-flex align-items-center">
  
      <div class="border-amount" *ngIf="agency.pay_mode == 1">
        {{totalPriceNR - points | number:'1.2-2'}} $
      </div>

      <div class="border-amount" *ngIf="agency.pay_mode == 3">
        {{totalPriceNR - (fidelityService.creditDepositActives + points) | number:'1.2-2'}} $
      </div>
    </div>


  </div>


  <div class="row d-flex justify-content-end" style="margin-top: 10px;">
    <div class="col-4">
      <button class="btn btn-danger" type="button" style="width: 100% 1important;" (click)="close()">Cerrar</button>
    </div>
  </div>

</div>