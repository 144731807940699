import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ItineraryService } from '../../itinerary.service';
//import { ToastsManager } from 'ng2-toastr';
import { isValidOwener, fillOwnerMessage } from '../../../utils/Functions';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'usb-modal-insurance',
  templateUrl: './modal-insurance.component.html',
  styleUrls: ['./modal-insurance.component.scss'],
  providers: [DatePipe]
})
export class ModalInsuranceComponent implements OnInit {

  _findData
  
  @Input() modalRoomRef: any;
  @Input() insurance: any;
  @Input() form: FormGroup;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() payload?: any;
  @Output() refModal = new EventEmitter<any>();
  Adultts: number;
  Childs: number;
  Origen: string;
  Destino: string;


  constructor(private dateIni: DatePipe, private itineraryService: ItineraryService, private dateEnd: DatePipe, private _cookieService: CookieService) { }

  ngOnInit() {
    if (this.form) {
      this.InitValues(this.form);
    }
  }

  
  getDateIni() {
    // return this.dateIni.transform(moment(this.insurance.dateIni), 'MMM dd, yyyy');
    return this.dateIni.transform(this.insurance.dateIni, 'MMM dd, yyyy');
  }

  InitValues(item: any) {
    this.Adultts = item.value.room[0].qtyAdults;
    this.Childs = item.value.room[0].qtyChilds;
    this.Origen = item.value.origin.name;
    this.Destino = item.value.destination.name;
  }
  getDateEnd() {
    // return this.dateEnd.transform(moment(this.insurance.dateEnd), 'MMM dd, yyyy');
    return this.dateEnd.transform(this.insurance.dateEnd, 'MMM dd, yyyy');
  }
  continue() {
    const optionToReserved = this.insurance.additionalInfo;
    this.addToItinerary(optionToReserved);
    //this._emitRefModal('pers');
  }

  addToItinerary(item: any) {
    //validar si owner esta lleno
    if(!isValidOwener(this._cookieService.get('clientActive'))){
      this._emitRefModal('warning - ' + fillOwnerMessage());
      return;
    }
    item.categoryName = this.origin;
    item.form = this.form !== undefined ? this.form.value : undefined;    
    item.payload = this.payload;
    item.id = this.insurance.id + '/-/' + this.insurance.title;
    item.idProduct = (this.insurance.providerName=='Universal Assistance') ? this.insurance.title : this.insurance.productId;
    item.origin = this.insurance.origin;
    item.nameAdapter = this.insurance.nameAdapter;
    item.searchId = this.arrayResults[0].searchId;
    item.providerId = this.insurance.providerId;
    item.providerName = this.insurance.providerName;
    item.title = this.insurance.title;
    item.location = this.insurance.additionalInfo.location;
    item.arrivalDate = this.insurance.dateIni;
    item.departureDate = this.insurance.dateEnd;
    item.updateDate = new Date();
    item.payloadUpdate = this._payloadUpdateInsurance(this.payload);
    item.destination = this.itineraryService.getDestinationInfo(this.form, item);
    this.itineraryService.sendMessageSubscriptorLoading(true);
    this.itineraryService.add(item).subscribe(
      (response:any) => {
        console.log('Product added successfully', response);
        if (typeof response === 'string') {
          this._emitRefModal('error - ' + response);
        } else {
          ////this.toastr.success('Product added successfully to your itinerary');
          this._emitRefModal('success - Congratulations - Product added succesfully to your itinerary');
        }
        this.itineraryService.sendMessageSubscriptorLoading(false);
      },
      error => {
        console.log('Product not added', error);
        this._emitRefModal('error - Error - Item was not added to your itinerary, please check the console');
        this.itineraryService.sendMessageSubscriptorLoading(false);
        ////this.toastr.error('An error occurred, product was not added', 'Error');
      }
    );
  }
  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }
  _payloadUpdateInsurance(payload: any): Object {

    console.log(this.origin);

    return {
      products: [{
        providerId: this.insurance.providerId,
        nameAdapter: this.insurance.nameAdapter,
        categoryId: this.insurance.categoryId,
        productId: this.insurance.id,
        productName: this.insurance.title,
        origin: this.insurance.origin,
        destination: this.insurance.destination,
        priceFrom: 0,
        priceTo: 10000.00,
        arrivalDate: this.insurance.dateIni,
        departureDate: this.insurance.dateEnd,
        qtyProduct: 1,
        qtyPax: 0,
        others: {
          insurance: payload.others.insurance
        }
      }]
    };
  }
}
