<div  style="position: relative;">
  <h1 class="title d-flex mr-4">
    {{ tour.title }}
  </h1>
  <button type="button" class="close btn-closed" aria-label="Close" (click)="close()">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div class="d-flex justify-content-start">
    <div class="d-flex mr-2">
      <i class="i-placeholder"></i>
    </div>
    <div class="d-flex flex-column justify-content-center">
      <span class="location">{{tour.additionalInfo.location}}</span>
      <span class="location">{{tour.additionalInfo.tours.company}}</span>
    </div>
  </div>
  <div class="d-flex my-3 justify-content-between">
    <button [ngClass]="{'btn-tabs-active': tab==='tour'}" class="btn-tabs" (click)="tab='tour'">TOURS</button>
    <button [ngClass]="{'btn-tabs-active': tab==='maps'}" class="btn-tabs" (click)="tab='maps'; mapMarker()">MAPA</button>
    <button [ngClass]="{'btn-tabs-active': tab==='photos'}" class="btn-tabs" (click)="tab='photos'">FOTOS</button>
    <button [ngClass]="{'btn-tabs-active': tab==='info'}" class="btn-tabs" (click)="tab='info'">+ INFO</button>
  </div>

  <!-------------------------- DIV DE TOUR --------------------------------->
  <div [ngClass]="{'d-block': tab==='tour', 'd-none': tab!='tour' }">
    <div class="d-flex flex-column mb-2">
      <p class="description-tour" [ngClass]="{'texto-recortado': !textComplete}">{{textInfoExtra}}</p>
      <button class="btn-descript-tour mb-1" style="width: 20%;" (click)="toggleTextComplete()">{{toggleTextBtn}}</button>
    </div>
    <p class="title-calendar m-0">Seleccionar Fechas</p>

    <div class="header-calendar">
      <ol class="week-days">
        <li class="day-name" *ngFor="let dayMissing of daysMissing">{{dayMissing}}</li>
      </ol>
    </div>

    <!--------------- CALENDARIO CON FOR --------------->

    <ol class="container-calendar" >
      <li *ngFor="let day of generateCalendar()" (click)="getDateClick(day.date)" type="date" [ngClass]="{'empty-value': day.date < dayInitPayload || day.date > dayFinalPayload, 'dateAvailable':day.date >= dateLastFrom  && day.date <= dateLastTo, 'datesNotSelected': dateLastFrom <= dayInitPayload || dayFinalPayload >= dateLastTo, 'border-selected': day.date === dateSelect && day.date >= dateLastFrom  && day.date <= dateLastTo}" [class.first-day]="day.number === 1 && day.isCurrentMonth" [class.days]="day.number !== 1 || !day.isCurrentMonth">
        <input *ngIf="day.date < dayInitPayload || day.date > dayFinalPayload" [disabled]="false" class="cursor-not-allowed" type="text">
        <p class="number-day" *ngIf="day.date >= dayInitPayload && day.date <= dayFinalPayload">{{day.date | date: 'd'}}</p>
        <p class="month-day" *ngIf="day.date >= dayInitPayload && day.date <= dayFinalPayload">{{day.date | date:'MMM'}}</p>
        <p class="price" *ngIf="day.date >= dateLastFrom && day.date <= dayInitPayload || day.date >= dateLastFrom  && day.date <= dateLastTo"> {{tour.additionalInfo.currency}} {{day.totalPriceProf}}</p>
      </li>
    </ol>
    <!------------  TRASLADO ------------------------------->

    <div class="container-translate">
      <p class="title-translate">Traslados</p>
      <div class="d-flex justify-content-around align-items-center container-choice-translate">

        <fieldset class="d-flex justify-content-around align-items-center first-group-check">
          <div class="d-flex justify-content-around align-items-center first-check">
            <p class="text-check-radio">SI</p>
            <input type="radio" class="input-radio-tour" name="confirmation-translate">
          </div>
          <div class="d-flex justify-content-around align-items-center two-check">
            <p class="text-check-radio">NO</p>
            <input type="radio" class="input-radio-tour" name="confirmation-translate">
          </div>
        </fieldset>

        <div class="d-flex justify-content-around align-items-center two-group-check">
          <div class="d-flex justify-content-around align-items-center three-check">
            <p class="text-check-radio">Regular</p>
            <input type="radio" class="input-radio-tour" name="type-translate">
          </div>
          <div class="d-flex justify-content-around align-items-center four-check">
            <p class="text-check-radio" >Privado</p>
            <input type="radio" class="input-radio-tour" name="type-translate">
          </div>
        </div>

        <div>
          <select class="select-translate" name="" id="" (change)="getUniqueHotelSelect($event.target.value)">
            <option value="" disabled selected>Selecciona tu opción </option>
            <option [value]="hotel" *ngFor="let hotel of hotelsTitleArr" >{{hotel}}</option>
          </select>
        </div>
      </div>

    </div>

    <!------------------ TAPS DESPLEGABLES -------------------------->

    <!-- LOS COLORES ESTAN DEFINIDOS EN EL CSS, DESACTIVARLOS DE SER NECESARIO -->

     <!--  <div class="d-flex justify-content-between align-items-center px-4 container-tap-inactive">
      <div class="d-flex justify-content-between align-items-center">
        <input type="radio" class="input-radio-tap" disabled>
        <p class="text-radio-tap">Solo Show</p>
      </div>
      <div>
        <p class="text-tap">NO DISPONIBLE ESTA FECHA</p>
      </div>
    </div> -->

    <div *ngFor="let item of optionsTour;let i = index"  class="d-flex justify-content-between align-items-center px-4 container-tap-active">
      <div class="d-flex justify-content-between align-items-center">
        <input type="radio" *ngIf="i == 0" checked name="tap-choice" class="input-radio-tap" (click)="toggleTap('showTap_1', item);">
        <input type="radio" *ngIf="i > 0"  name="tap-choice" class="input-radio-tap" (click)="toggleTap('showTap_1', item);">
        <p class="text-radio-tap">{{ item?.option_name }}</p>
      </div>
      <div>
        <p class="text-tap"> {{ item?.totalPriceProf | currency:'USD ' }}</p>
      </div>
    </div>
    <!----------  PRECIO TOTAL --------------->

    <div class="d-flex justify-content-end align-items-center rounded-top text-white container-title-text-price">
      <p class="title-text-price">SELECCIÓN</p>
    </div>

    <div >
      <div class="d-flex flex-column justify-content-end align-items-end container-description-price" *ngFor="let price of arrayPricesPassengers">
        <p *ngIf="price.paxType == 'ADULT'"> Adulto: {{price.amountProf| currency:'USD ':'code'}}</p>
        <p *ngIf="price.paxType == 'CHILD'"> Niños: {{price.ageFrom}} - {{price.ageTo}} años: {{price.amountProf | currency:'USD ':'code'}}</p>
        <!-- <p >Niños {{selectTour?.prices[1]?.ageFrom}} - {{selectTour?.prices[1]?.ageTo}} años: {{selectTour?.prices[1]?.amountProf | currency:'USD ':'code'}} </p> -->
       </div>
      <div class="d-flex flex-column justify-content-end align-items-end container-price-total">
        <p class="text-price">TOTAL PRECIO CLIENTE</p>
        <p class="price-total">{{selectTour.totalPriceProf | currency:'USD ':'code'}}</p>
      </div>
    </div>
  </div>
  <!---------------------------- DIV DE MAPAS --------------------->

  <div [ngClass]="{'d-block': tab==='maps', 'd-none': tab!='maps' }">
    <div class="d-flex flex-column mb-2">
      <div class="d-flex flex-row">
        <div class="col-12 content-dir py-2">
          <p class="direccion-maps mb-0">{{tour?.additionalInfo?.location}}</p>
        </div>
      </div>
      <div class="d-flex flex-row mt-3">
        <div class="col-12 p-0">
          <!-- Falta Insertar Mapa -->
          <div #gmap style="min-height: 300px; margin: 0xp"></div>
          <!-- <img src="/assets/img/app/maps-modal.png" class="img-map" alt="maps"> -->
        </div>
      </div>
      <div class="d-flex flex-row mt-3">
        <div class="epigrafe-content d-flex align-items-center p-3">
          <!-- <span class="d-flex">Epigrafe: Lorem ipsum dolor, sit amet consectetur adipisicing. </span> -->
        </div>
      </div>
    </div>
  </div>
  <!---------------------------- DIV DE FOTOS --------------------->
  <div [ngClass]="{'d-block': tab==='photos', 'd-none': tab!='photos' }">
    <div class="d-flex flex-column">
      <div class="d-flex content-imgSelect">
        <img [src]="imagesArrList[imgSelect]" class="img-gallery" alt="">
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex bg-description pt-2 px-3">
        <!-- <p class="epigrafe">{{stingUpperCase('Lorem ipsum dolor sit amet')}}</p> -->
      </div>
      <div class="d-flex bg-description pt-2 px-4 content-miniatura">
        <!-- left scroll -->
        <i class="scroll-left" *ngIf="imagesArrList.length > 5" (click)="scroll('l')"></i>

        <div class="d-flex justify-content-between slide-miniatura" id="slide-miniatura">
          <img [src]="img" class="img-miniatura mr-3" *ngFor="let img of imagesArrList; index as j"
            [ngClass]="{selectedImg: imgSelect==j}" (click)="imgSelect=j">
        </div>

        <!-- right scroll -->
        <i class="scroll-right" *ngIf="imagesArrList.length > 5" (click)="scroll('r')"></i>
      </div>
      <div class="d-flex bg-description justify-content-center py-4">
        <span class="font-poppins pagination-img">
          {{imgSelect+1 +' of '+ imagesArrList.length}}
        </span>
        <a class="ml-5 pagination-btn" (click)="prev()" title="previous">
          <i class="i-arrow-left" *ngIf="imgSelect>0"></i>
          <i class="i-arrow-left-gray" *ngIf="imgSelect==0"></i>
        </a>
        <a class="ml-5 pagination-btn" (click)="next()" title="next">
          <i class="i-arrow-right" *ngIf="imgSelect<(imagesArrList.length-1)"></i>
          <i class="i-arrow-right-gray" *ngIf="imgSelect==(imagesArrList.length-1)"></i>
        </a>
      </div>
    </div>
  </div>
    <!---------------------------- DIV DE INFO --------------------->
  <div [ngClass]="{'d-block': tab==='info', 'd-none': tab!='info' }">
    <div class="d-flex justify-content-between info">
      <div class="d-flex flex-column p-3 detail">
        <p class="info-title">DISFRUTÁ MIAMI DE UNA MANERA DIFERENTE</p>
        <p class="info-description">
          {{tour.description}}
        </p>
        <p class="info-title m-0">OPERADOR</p>
        <p class="info-title">{{tour.additionalInfo.tours.company}}</p>
        <p *ngIf="infoArr.length > 0" class="info-title">INFORMACION GENERAL</p>
        <p class="info-description" *ngFor="let infoItem of infoArr">
          {{infoItem.info}}
        </p>

        <!-- <p class="info-description">
          - Disfruta de un completo tour narrativo bilingüe.
        </p>
        <p class="info-description">
          - Disfruta los asientos en el exterior o en el interior.
        </p>
        <p class="info-description">
          - Ambiente familiar.
        </p> -->
      </div>
      <div class="d-flex flex-column p-3 resum">
        <div class="d-flex flex-row justify-content-start">
          <i class="i-check"></i>
          <p class="info-title m-0 ml-2">INCLUIDO</p>
        </div>
        <div class="d-flex flex-row justify-content-start mt-2">
          <i class="i-transport"></i>
          <p class="info-description m-0 ml-2">Transporte</p>
        </div>
        <div class="d-flex flex-row justify-content-start mt-1">
          <i class="i-wifi"></i>
          <p class="info-description m-0 ml-2">Wi-Fi</p>
        </div>
        <div class="d-flex flex-row justify-content-start mt-1">
          <i class="i-guide"></i>
          <p class="info-description m-0 ml-2">Guía</p>
        </div>
        <div class="d-flex flex-row justify-content-start mt-5">
          <i class="i-not-included"></i>
          <p class="info-title m-0 ml-2">NO INCLUIDO</p>
        </div>
        <div class="d-flex flex-row justify-content-start mt-2">
          <i class="i-point"></i>
          <p class="info-description m-0 ml-2">Bebidas</p>
        </div>
        <div class="d-flex flex-row justify-content-start mt-1">
          <i class="i-point"></i>
          <p class="info-description m-0 ml-2">Excursiones</p>
        </div>
        <div class="d-flex flex-column justify-content-start mt-5">
          <p class="info-title m-0">DURACIÓN</p>
          <div class="d-flex justify-content-start mt-2">
            <i class="i-clock-red"></i>
            <p class="info-title m-0 ml-2" style="color: #d60159 !important;">1:30 HR</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-row py-2" style="margin-bottom: 65px; margin-top: 25px;">
    <button type="button" class="pull-right btn btn-continue cursor-pointer" (click)="continue()"
      [disabled]="_disabledButton">Continuar</button>

      <!-- <button type="button" class="pull-right btn btn-continue cursor-pointer" (click)="close()" >Close</button> -->
  </div>

  <!-- <div id="modaltours">

    <div class="modal-content p-4">
      <div class="flex-row">
      </div> -->
  <!-- <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome'" class="ml-auto" [readOnly]="true"></bar-rating> -->
  <!-- <div class="card card-info d-flex my-3">
        <div class="flex-row pt-1 pl-1  d-flex">
          <span class="address-resumen">
            <i class="icon icon-placeholder" aria-hidden="true"></i>
            <strong class="px-1">{{tour.additionalInfo.location}}</strong>
          </span>
        </div>
        <div class="flex-row p-2 d-flex">
          <span class="address-resumen">
            <strong class="px-1 pl-3 pb-1">{{tour.additionalInfo.tours.company}}</strong>
          </span>
        </div>
      </div>

      <div class="d-flex justify-content-start py-0 mb-1"> -->
  <!--         <div class="d-flex pl-1 pt-0 align-items-center rating-visits">
            {{ratereview}}
            <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="p-1 br"></bar-rating>
          </div> -->
  <!-- </div>
      <hr>
      <div class="modal-body py-2">
        <div>
          <tabset class="tabcontainer"> -->
  <!--***********************************-->
  <!--***********TABS FOR ROOMS**********-->
  <!--***********************************-->
  <!-- <tab heading="Tours" id="tab-rooms">
              <ng-template ngFor let-options let-i="index"
                [ngForOf]="tour.additionalInfo?.tours?.options | groupBy: 'name'">
                <ng-template ngFor let-option let-i="index" [ngForOf]="options.value">
                  <div class="card justify-content-between card-info d-flex flex-row my-3"
                    *ngIf="i===0 || option.name != options.value[i-1].name ">
                    <div class="flex-column col-2 py-2 d-flex type-room">
                      <span class="strong flex-column">
                        <strong>Name</strong>
                      </span>
                      <span class="strong flex-column">{{option.name}}</span>
                    </div>
                    <div class="flex-column col-2 py-2 d-flex type-room">
                      <span class="strong">
                        <strong>Duration</strong>
                      </span>
                      <span class="strong">{{option.duration}}</span>
                    </div>
                    <div class="flex-column p-2 d-flex max-people col-3">
                      <span class="strong">
                        <strong>Date</strong>
                      </span>
                      <span>
                        <select #itemDate class="w-100" (change)="activeDate(itemDate.value)">
                          <option value="{{_selectDate}}" selected>{{_selectDate}}</option>
                          <option *ngFor="let date of option.availableDates ; let j = index" [value]="date">
                            {{date}}
                          </option>
                        </select> -->
  <!-- <select #itemDate (click)="setItemDate(itemDate.value, option)" class="w-100">
                          <option value="{{_selectDate}}" selected>{{_selectDate}}</option>
                          <option *ngFor="let date of option.availableDates ; let j = index" [value]="date">
                            {{date}}
                          </option>
                        </select> -->
  <!-- <i class="{{_loadDates ?'fa fa-spinner fa-spin fa-sm ':''}}" style="color:black"></i> -->
  <!-- </span>
                      <span class="errors" *ngIf="notFoundDates"><br> No se encontraron fechas disponibles</span>
                    </div>
                    <div class="flex-column py-2 d-flex max-people col-3">
                      <span class="strong">
                        <strong>{{option.prices.adultFlag}}Adult Price</strong>
                      </span>
                      <span class="strong">{{option.prices.adultProf  | number:'1.2-2'}}$ ({{option.prices.adultFrom}} -
                        {{option.prices.adultTo}}) Years</span>
                      <span class="strong">
                        <strong>{{option.prices.childFlag}}Children Price</strong>
                      </span>
                      <span class="strong">{{option.prices.childProf  | number:'1.2-2'}}$ ({{option.prices.childFrom}} -
                        {{option.prices.childTo}}) Years</span>
                    </div>
                    <div class="flex-column p-2 d-flex type-room col-1">
                      <span class="strong">
                        <strong>{{option.totalPriceFlag}}Total</strong>
                      </span>
                      <span class="strong">{{option.netPriceProf | number:'1.2-2'}}$</span>
                    </div>
                    <div class="flex-column col-1 py-2 d-flex max-people radio text-center">
                      <span class="strong">
                        <strong>Select</strong>
                      </span> -->
  <!-- <input #widget class='with-gap' name='itemGroup' type='radio' [id]='option' [value]='option' [(ngModel)]='_itemOption' (ngModelChange)="setTotal()"/> -->

  <!-- <label class="d-flex justify-content-center text-center pt-2">
                        <input #widget class="form-check-input" type="radio" name="itemGroup" [id]='option'
                          [value]='option' [(ngModel)]='_itemOption' (ngModelChange)="setTotal(option)" />
                        <span class="cr ">
                          <i class="cr-icon fa fa-circle"></i>
                        </span>
                      </label>

                    </div>
                  </div>
                </ng-template>
              </ng-template>
              <div class="card card-total-price mb-5 pr-5  text-right">
                <strong>Total: ${{_total | number:'1.2-2'}}</strong>
              </div>
            </tab>
          </tabset>
        </div>
        <div class="flex-row py-2">
          <button type="button" class="pull-right btn btn-sm btn-usblick" (click)="continue()"
            [disabled]="_disabledButton">Continue</button>
        </div>
      </div>
    </div>
  </div> -->
</div>
