import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { setting } from '../../../../setting';
import CssFilterConverter from 'css-filter-converter';

@Component({
  selector: 'usb-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent implements OnInit {
  @ViewChildren('icon', { read: ElementRef }) icons: QueryList<ElementRef>;

customOptions: OwlOptions = {
loop: true,
mouseDrag: false,
touchDrag: false,
pullDrag: false,
dots: false,
navSpeed: 700,
navText: ['<img src="../../assets/img/app/arrow-izq-slider.png" title="arrow-izq-slider" />', '<img src="../../assets/img/app/arrow-der-slider.png" title="arrow-der-slider" />'],
responsive: {
  0: {
    items: 1
  },
  400: {
    items: 2
  },
  740: {
    items: 3
  },
  940: {
    items: 4
  },
  1140: {
    items: 8
  },
  1340: {
    items: 8
  },
  1540: {
    items: 8
  },
  1740: {
    items: 8
  },
  1940: {
    items: 8
  }
},
nav: true
}

  public getClientsSettingAppBooking: any;
  public sectionShortcuts: any;
	public routeUploadFile:any;
  public images:any = [];
  public iconSVG4: string = '';
  public filter?: any;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.getClientsSettingAppBooking =  JSON.parse((localStorage.getItem(setting.name)));
    this.sectionShortcuts  = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_shortcuts);
    this.routeUploadFile =  setting.routeUploadFile;
    //console.log("llegando al shortcuts component", this.getClientsSettingAppBooking);
   // console.log("llegando al shortcuts component image", this.routeUploadFile+this.sectionShortcuts?.info[0].image);
    this.matIconRegistry.addSvgIcon(
      "star",
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.routeUploadFile+this.sectionShortcuts?.info[0].image)
    );
  }

  ngOnInit() {
    for (let index = 0; index < this.sectionShortcuts?.shortcuts_carousel.length; index++) {
      this.images[index] = this.routeUploadFile + this.sectionShortcuts?.shortcuts_carousel[index];
    }
    this.sectionShortcuts  = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_shortcuts);
    this.routeUploadFile =  setting.routeUploadFile;
    this.applyImageFilter();
   // console.log(this.images)
  }

   // Aplica un filtro de css al elemento de imagen utilizando el color seteado por cliente.
   applyImageFilter() {
    const color = this.getClientsSettingAppBooking?.clients_setting_global?.color_main_1;
    const tempFilterObj = CssFilterConverter.hexToFilter(color, { sheen: false });

    //Extraemos el literal en String de la conversión
    const tempFilterStr = Object.keys(tempFilterObj).map(key => `${key}(${tempFilterObj[key]})`).join(" ");

    // Convierte el color a un objeto de filtro de CSS y extrae los filtros de la respuesta color del resultado.
    const colorRegex = /color\((.*)\)/;
    const colorMatch = tempFilterStr.match(colorRegex);
    const colorFilter = colorMatch ? colorMatch[1] : "";

    this.filter = colorFilter;

    // Establece el atributo de estilo "filter" en el elemento de imagen con los filtros de color extraídos.
    setTimeout(() => {
      this.icons.forEach(icon => {
        icon.nativeElement.setAttribute('style', `filter: ${this.filter}`);
      });
     // console.log(this.filter);
    }, 100);
  }

}
