import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalsComponent } from '../../../../shared/modals/modals.component';

@Component({
  selector: 'usb-disney-result',
  templateUrl: './disney-result.component.html',
  styleUrls: ['./disney-result.component.scss']
})
export class DisneyResultComponent implements OnInit {

  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;

  constructor(public dialog:MatDialog) { }

  ngOnInit() {

    console.log ("result Disney", this.result);
    console.log ("result Description", this.isNull(this.result.description));

  }
  
  stars() {
    const array = [];
    const item = Number.parseInt(this.result.serviceResponse.Stars);
    for (let index = 0; index < item; index++) {
      array.push(index);
    }
    return array;
  }

  _lowestPrice(result: any): number{
    let price = 100000000;
    result['additionalInfo']['rooms'].forEach(room => {
      price = room['netPriceProf'] < price ? room['netPriceProf'] : price;
    });
    return price;
  }

  stingUpperCase(str){
    let e = str.toLowerCase()
    return e.replace(/\b\w/g, l => l.toUpperCase())
  }

  isNull(str){
    let nulls = (str == null)? true : false
    return nulls
  }

  openModal(){
    var dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '860px',
      data: {
        //modalRoomRef: this.modalRef,
        product: this.result, 
        productQty: this.payload.qtyProduct, 
        form: this.form, 
        origin: 'DISNEY', 
        arrayResults: this.arrayResults, 
        //payloadUpdate: this.payload,
      },
      //disableClose: true
    });
  }
}