import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarRatingModule } from 'ngx-bar-rating';
import { LoaderComponent } from './loader/loader.component';
import { RestService } from './rest.service';
import { HeadBandComponent } from './head-band/head-band.component';
import { CustomValidator } from './customValidators';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TruncateModule } from 'ng2-truncate';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HomeRoutingModule } from '../home/home-routing.module';
import { ItineraryListComponent } from './itinerary-sidebar/itinerary-list/itinerary-list.component';
import { ItineraryHotelComponent } from './itinerary-sidebar/itinerary-list/components/hotel/itinerary-hotel.component';
import { ItineraryTicketComponent } from './itinerary-sidebar/itinerary-list/components/ticket/itinerary-ticket.component';
import { OrderByPipe, GroupByPipe} from './itinerary-sidebar/itinerary.pipes';
import { RoomComponent } from './modals/modal-hotel/room/room.component';
import { DisneyRoomComponent } from './modals/modal-disney/room/room.component';
import { ModalHotelComponent } from './modals/modal-hotel/modal-hotel.component';
import { MultiDestinationComponent } from './itinerary-sidebar/categories/multi-destination/multi-destination.component';
import { SummaryFooterComponent } from './itinerary-sidebar/categories/summary-footer/summary-footer.component';
import { OptionPThemesComponent } from './modals/modal-pthemes/option-p-themes/option-p-themes.component';
import { ModalsComponent } from './modals/modals.component';
import { ModalPthemesComponent } from './modals/modal-pthemes/modal-pthemes.component';
import { ModalDisneyComponent } from './modals/modal-disney/modal-disney.component';
import { ItemHotelComponent } from './itinerary-sidebar/categories/item-hotel/item-hotel.component';
import { ItemPthemeComponent } from './itinerary-sidebar/categories/item-ptheme/item-ptheme.component';
import { ItemDisneyComponent } from './itinerary-sidebar/categories/item-disney/item-disney.component';
import { ModalTransportsComponent } from './modals/modal-transports/modal-transports.component';
import { ModalInsuranceComponent } from './modals/modal-insurance/modal-insurance.component';
import { VehicleComponent } from './modals/modal-transports/vehicle/vehicle.component';
import { ModalRentacarComponent } from './modals/modal-rentacar/modal-rentacar.component';
import { ItemTransportsComponent } from './itinerary-sidebar/categories/item-transports/item-transports.component';
import { ModalToursComponent } from './modals/modal-tours/modal-tours.component';
import { ItemRentacarComponent } from './itinerary-sidebar/categories/item-rentacar/item-rentacar.component';
import { ItemToursComponent } from './itinerary-sidebar/categories/item-tours/item-tours.component';
import { ItemSelectedComponent } from './item-selected/item-selected.component';
import { FlightModalComponent } from './flight-modal/flight-modal.component';
import { PaxListModalComponent } from './pax-list-modal/pax-list-modal.component';
import { PaxListModalBookingComponent } from './modal-edit-booking/pax-list-modal-booking/pax-list-modal-booking.component';
import { TitleFilterPipe } from './titleFilter.pipe';
import { ItemInsuranceComponent } from './itinerary-sidebar/categories/item-insurance/item-insurance.component';
import { ModalsSearchComponent } from './modals-search/modals-search.component';
import { ModalSearchTransportsComponent } from './modals-search/modal-search-transports/modal-search-transports.component';
import { ModalPackagesComponent } from './modals/modal-packages/modal-packages.component';
import { SelectAgencyModalComponent } from './select-agency-modal/select-agency-modal.component';
import { ModalViewVideComponent } from './modal-view-video/modal-view-video.component';
import { ModalSyncProductComponent } from './modal-sync-product/modal-sync-product.component';

// edit reservar
import { ModalEditBookingComponent } from './modal-edit-booking/modal-edit-booking.component';
import { RoomsSelectComponent } from './modal-edit-booking/rooms-select/rooms-select.component';
import { HotelRoomComponent } from './modal-edit-booking/rooms-select/hotel/hotel-room.component';
import { FormPaxesBookingComponent } from './modal-edit-booking/form-paxes-booking/form-paxes-booking.component';
import { BodyBookingComponent } from './modal-edit-booking/body-booking/body-booking.component';
import { ConfirmBookingComponent } from './modal-edit-booking/confirm-booking/confirm-booking.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModalConfirmComponent } from './material-modal-confirm/material-modal-confirm.component';
import { NrPrepaymentModalComponent } from './nr-prepayment-modal/nr-prepayment-modal.component';
import { FidelityService } from '../fidelity/fidelity.service';
import { NrBlockModalComponent } from './nr-block-modal/nr-block-modal.component';
import { AgentsService } from '../agents/agents.service';
import { AgentsSummaryService } from '../agents-summary/agents-summary.service';

import { ModalPostBookingComponent } from './modal-post-booking/modal-post-booking.component';
import { SelectItemsComponent } from './modal-post-booking/select-items/select-items.component';
import { ConfirmItemsComponent } from './modal-post-booking/confirm-items/confirm-items.component';
import { PaymentItemsComponent } from './modal-post-booking/payment-items/payment-items.component';
import { DocumentationComponent } from './modal-post-booking/documentation/documentation.component';
import { TransactionMasterComponent } from './modal-post-booking/pdfs/transaction-master/transaction-master.component';
import { TransactionMasterSelectedComponent } from './modal-post-booking/pdfs/transaction-master-selected/transaction-master-selected.component';
import { TransactionMasterVoucherComponent } from './modal-post-booking/pdfs/transaction-master-voucher/transaction-master-voucher.component';
import { MaterialModalAlertComponent } from './material-modal-alert/material-modal-alert.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ItinerarySidebarComponent } from './itinerary-sidebar/itinerary-sidebar.component';
import { ItineraryOwnerComponent } from './itinerary-owner/itinerary-owner.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPayPalModule } from 'ngx-paypal';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatIconModule} from '@angular/material/icon';
import { MatInputModule, MatAutocompleteModule, MatButtonModule } from '@angular/material';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AccordionModule, TypeaheadModule } from 'ngx-bootstrap';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { AuthorizationService } from 'app/core/authorization/authorization.service';
import { PhotosComponent } from './modals/modal-hotel/photos/photos.component';
import { DailyPricesComponent } from './modals/modal-hotel/room/daily-prices/daily-prices.component';
import { FilterMannagementComponent } from './filter-mannagement/filter-mannagement.component';
import { ScrollToTopDirectiveDirective } from './scroll-to-top-directive.directive';
import { LoadingSplashDirective } from './directives/loading-splash.directive';
import { ModalLoadingComponent } from './modal-loading/modal-loading.component';
import { DynamicFontSizePipe } from './dynamic-font-size.pipe';



defineLocale('es', esLocale);


@NgModule({

  imports: [
    CommonModule,
    NguiAutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    BarRatingModule,
    SortableModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    RatingModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule,
    ToastrModule.forRoot(),
    TimepickerModule.forRoot(),
    TruncateModule,
    HomeRoutingModule,
    BsDatepickerModule.forRoot(),
    MatDialogModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgxSpinnerModule,
    NgxPayPalModule,
   // NgxStarsModule,
    MatIconModule,
    MatExpansionModule,
    NgxDaterangepickerMd,
    AccordionModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    TypeaheadModule.forRoot()
  ],
  declarations: [
    LoaderComponent,
    TitleFilterPipe,
    HeadBandComponent,
    CustomValidator,
    ItinerarySidebarComponent,
    ItineraryListComponent,
    ItineraryHotelComponent,
    ItineraryTicketComponent,
    OrderByPipe,
    GroupByPipe,
    ItineraryOwnerComponent,
    ModalHotelComponent,
    RoomComponent,
    DisneyRoomComponent,
    MultiDestinationComponent,
    SummaryFooterComponent,
    OptionPThemesComponent,
    ModalsComponent,
    ModalPthemesComponent,
    ModalDisneyComponent,
    ItemHotelComponent,
    ItemPthemeComponent,
    ItemDisneyComponent,
    ModalTransportsComponent,
    VehicleComponent,
    ModalInsuranceComponent,
    ModalRentacarComponent,
    ItemTransportsComponent,
    ItemInsuranceComponent,
    ModalRentacarComponent,
    ModalToursComponent,
    ItemRentacarComponent,
    ItemToursComponent,
    ItemSelectedComponent,
    FlightModalComponent,
    PaxListModalComponent,
    PaxListModalBookingComponent,
    FormPaxesBookingComponent,
    BodyBookingComponent,
    ConfirmBookingComponent,
    RoomsSelectComponent,
    ModalsSearchComponent,
    ModalSearchTransportsComponent,
    ModalPackagesComponent,
    SelectAgencyModalComponent,
    ModalViewVideComponent,
    ModalSyncProductComponent,
    ModalEditBookingComponent,
    HotelRoomComponent,
    MaterialModalConfirmComponent,
    NrPrepaymentModalComponent,
    NrBlockModalComponent,
    ModalPostBookingComponent,
    SelectItemsComponent,
    ConfirmItemsComponent,
    PaymentItemsComponent,
    DocumentationComponent,
    TransactionMasterComponent,
    TransactionMasterSelectedComponent,
    TransactionMasterVoucherComponent,
    MaterialModalAlertComponent,
    PhotosComponent,
    DailyPricesComponent,
    FilterMannagementComponent,
    ScrollToTopDirectiveDirective,
    LoadingSplashDirective,
    ModalLoadingComponent,
    DynamicFontSizePipe
   ],
  providers: [
    RestService,
    DatePipe,
    FidelityService,
    AgentsService,
    AgentsSummaryService,
    AuthorizationService,
  ],
  exports: [
    LoaderComponent,
    ItineraryOwnerComponent,
    HeadBandComponent,
    ItinerarySidebarComponent,
    ModalsComponent,
    ModalsSearchComponent,
    ModalSearchTransportsComponent,
    ItemSelectedComponent,
    TitleFilterPipe,
    PaxListModalComponent,
    PaxListModalBookingComponent,
    FormPaxesBookingComponent,
    BodyBookingComponent,
    ConfirmBookingComponent,
    RoomsSelectComponent,
    FilterMannagementComponent,
    ScrollToTopDirectiveDirective,
    LoadingSplashDirective,
    ModalLoadingComponent,
    DynamicFontSizePipe
  ],
  entryComponents: [
    SelectAgencyModalComponent,
    ModalViewVideComponent,
    ModalSyncProductComponent,
    ModalEditBookingComponent,
    HotelRoomComponent,
    ItineraryListComponent,
    ItineraryHotelComponent,
    ItineraryTicketComponent,
    ModalPthemesComponent,
    ModalHotelComponent,
    OptionPThemesComponent,
    RoomComponent,
    ModalPackagesComponent,
    MaterialModalConfirmComponent,
    NrPrepaymentModalComponent,
    NrBlockModalComponent,
    ModalsComponent,
    MaterialModalAlertComponent
],

})
export class SharedModule { }
