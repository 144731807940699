<div class="row">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fa1a1a" type="line-scale">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="container">
    <div class="col-12">
      <span class="title text-uppercase d-flex align-items-center" *ngIf="roomSelect.categoryName=='HOTELS'">
        <span class="circle-icon mr-3">
                <i class="icons-hotel"></i>
        </span> Select room for passengers</span>
        <span class="title text-uppercase" *ngIf="roomSelect.categoryName=='PTHEMES'"><i
                class="icon-small icon-pthemes iconstart  text-center pr-3"></i>Select ptheme for passengers</span>
            <span class="title text-uppercase d-flex align-items-center" *ngIf="roomSelect.categoryName=='TRANSPORTS'">
              <span class="circle-icon mr-3">
                <i class="icons-transport"></i>
              </span>
              Select transport for passengers</span>
            <span class="title text-uppercase" *ngIf="roomSelect.categoryName=='DISNEY'"><i
                class="icon-small icon-disney iconstart  text-center pr-3"></i>Select disney for passengers</span>
            <span class="title text-uppercase d-flex align-items-center" *ngIf="roomSelect.categoryName=='RENT-A-CAR'">
              <span class="circle-icon mr-3">
                <i class="icons-cars"></i>
              </span>
              Select cars for passengers</span>
            <span class="title text-uppercase d-flex align-items-center" *ngIf="roomSelect.categoryName=='TOURS'">
              <span class="circle-icon mr-3">
                <i class="icons-tours"></i>
              </span>
              Select tours for passengers</span>
            <span class="title text-uppercase" *ngIf="roomSelect.categoryName=='INSURANCE'"><i
                class="icon-small icon-seguro iconstart  text-center pr-3"></i>Select insurance for passengers</span>
            <span class="title text-uppercase" *ngIf="roomSelect.categoryName=='CRUISES'"><i
                class="icon-small icon-crucero iconstart  text-center pr-3"></i>Select cabin for passengers</span>

          <accordion [closeOthers]="true" class="mb-2">
            <accordion-group [isOpen]="i == 0 ? true : false">
              <div class="row" accordion-heading>
                <div class="col-12 d-flex justify-content-between">
                  <div class="d-flex flex-column p-3 product-select-data">
                    <div class="name-hotel">
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='HOTELS'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate.products[0].destination}} > <span class="name"
                          style="color:#00d397">{{roomSelect.title | truncate : 40 : "  "}}</span>
                      </div>
                      <div class="pull-left title-producto" style="color:#00d397" *ngIf="roomSelect.categoryName=='PTHEMES'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397">Activity
                          {{i+1}}</span> </div>
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='TRANSPORTS'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate?.products[0]?.destination}} >
                        <span class="name">
                          Vehicle {{i+1}}
                        </span>
                      </div>
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='DISNEY'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        Disney -
                        <span class="name" style="color:#00d397">{{roomSelect.hotel.title | truncate : 40 : "  "}}
                          {{i+1}}</span></div>
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='RENT-A-CAR'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate.products[0].destination}} > <span class="name"
                          style="color:#00d397">{{roomSelect.title | truncate : 40 : "  "}} {{i+1}}</span>
                      </div>
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='INSURANCE'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397">Insurance
                          {{i+1}}</span></div>
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='CRUISES'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397"> Cruise
                          {{i+1}}
                        </span></div>
                      <div class="pull-left title-producto" *ngIf="roomSelect.categoryName=='TOURS'">
                        <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                          height="15px"></object>
                        {{roomSelect.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397">
                          {{roomSelect.title | truncate : 40 : "  "}} - Ticket
                          {{i+1}}</span>
                      </div>
                      <i class="icon-down pull-right"></i>
                    </div>
                    <div class="" style="margin-left:70px;font-size: small;">
                      <p class="m-0 text-uppercase font-detail" *ngIf="roomSelect.categoryName=='HOTELS'">
                        {{roomSelect.roomType}}</p>
                      <p class="mt-2 mb-0 font-detail">
                        <span>STCHECK-IN :
                          {{roomSelect.arrivalDate !== 'To be defined'? (roomSelect.arrivalDate | date) : roomSelect.arrivalDate}} </span>
                        <span *ngIf="roomSelect.categoryName!='TOURS'">| CHECK-OUT:
                          {{roomSelect.departureDate !== 'To be defined'? (roomSelect.departureDate | date) : roomSelect.departureDate}}
                        </span>
                      </p>
                      <p class="mt-1 m-0 font-detail-2 d-flex align-items-end">
                        <i class="icono-night mr-1"></i>
                        {{roomSelect.nights}} nights
                        <i class="icono-adult ml-2 mr-1"></i>
                        {{roomSelect.paxAdults + (roomSelect.paxAdults > 1 ? ' Adults' : ' Adult')}}
                        <i class="icono-child ml-2 mr-1"></i>
                        {{roomSelect.paxChildren +  ' Children'}}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between p-3 product-status"
                    [ngClass]="{'status-ok': checkProductConfirm(roomSelect), 'status-pending': !checkProductConfirm(roomSelect)}">
                    <object *ngIf="!checkProductConfirm(roomSelect)" type="image/svg+xml" data='/assets/img/app/little-icons/timer_bco.svg'
                      width="30px" height="30px"></object>
                    <span *ngIf="checkProductConfirm(roomSelect)" class="icon-check"></span>
                    <span class="text-white" *ngIf="checkProductConfirm(roomSelect)">Ready</span>
                    <span class="text-white" *ngIf="!checkProductConfirm(roomSelect)">Pending</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <form (ngSubmit)="_onSubmit()" [formGroup]="_form" novalidate>
                    <div class="row px-3">
                      <div class="px-0"
                        [ngClass]="{'col-3' : roomSelect.categoryName=='TRANSPORTS' || roomSelect.categoryName=='RENT-A-CAR', 'col-4' : roomSelect.categoryName !='TRANSPORTS' && roomSelect.categoryName !='RENT-A-CAR'}">
                        <div class="form-group">
                          <label *ngIf="roomSelect.categoryName=='RENT-A-CAR'">Driver</label>
                          <label *ngIf="roomSelect.categoryName!=='RENT-A-CAR'">Passengers</label>
                          <select class="form-control" #pax (change)="setPaxToItem(pax.value, roomSelect, $event)">
                            <option [value]=""></option>
                            <option *ngFor="let pax of paxes" [value]="pax.document_number">
                              {{pax.first_name}} {{pax.last_name}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-4 px-1" *ngIf="roomSelect.categoryName=='TOURS'">
                        <div class="form-group">
                          <label for="Hotel">Hotel</label>
                          <input type="text" class="form-control" id="{{'hotel-' +roomSelect.id}}" [value]="namehotel"
                            (keyup)="_permitedReservation()">
                        </div>
                      </div>
                      <div class="col-4 px-0" *ngIf="roomSelect.categoryName=='TOURS'">
                        <div class="form-group">
                          <label for="checkin">Chech IN</label>
                          <input type="text" class="form-control" id="checkin" readonly="readonly"
                            [value]="formatDate(roomSelect.arrivalDate)  | uppercase">
                        </div>
                      </div>
                      <div class="col-3 px-1" *ngIf="roomSelect.categoryName=='TRANSPORTS' || roomSelect.categoryName=='RENT-A-CAR'">
                        <div class="form-group">
                          <label for="transport">Transport</label>
                          <select class="form-control" formControlName="transport" #trans id="{{'transport-' +roomSelect.id}}"
                            (change)="setTransportToItem(trans.value)">
                            <option [value]=""></option>
                            <option *ngFor="let trans of transport" [value]="trans.value">
                              {{trans.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-3 px-1" *ngIf="roomSelect.categoryName=='TRANSPORTS' || roomSelect.categoryName=='RENT-A-CAR'">
                        <label for="idTransport">{{transportLabel}}</label>
                        <div class="form-group">
                          <input type="text" placeholder="{{transportEx}}" class="form-control" formControlName="idTransport"
                            id="{{'idTransport-' +roomSelect.id}}" (change)="_permitedReservation()">
                        </div>
                      </div>
                      <div class="col-2 px-1" *ngIf="isCruise">
                        <label for="cruiseNumber">Number</label>
                        <div class="form-group">
                          <input type="text" class="form-control" formControlName="cruiseNumber" id="{{'cruiseNumber-' +roomSelect.id}}"
                            (change)="_permitedReservation()">
                        </div>
                      </div>
                      <div class="col-3 px-0" *ngIf="roomSelect.categoryName=='TRANSPORTS' || roomSelect.categoryName=='RENT-A-CAR'">
                        <div class="form-group pt-1">
                          <timepicker (change)="_permitedReservation()" formControlName="arrivalTime" id="{{'arrivalTime-' +roomSelect.id}}"
                            [showMeridian]="ismeridian"></timepicker>
                        </div>
                      </div>
                      <div class="col-12" *ngIf="roomSelect.categoryName=='TRANSPORTS' || roomSelect.categoryName=='RENT-A-CAR'">
                        Please provide requested information.
                        It is essential for the confirmation of the transfer reservation.
                        If the information provided is not correct, the provider is not responsible for
                        the correct provision of the service and may result in cancellation/no-show charges.
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-12">
                  <div class="row px-3"
                    [ngClass]="{ 'justify-content-between' : (paxesInItems.length > 2), 'justify-content-start' : (paxesInItems.length < 3) }">
                    <ng-container *ngFor="let pax of paxesInItems; let i = index;">
                      <div *ngIf="pax.itemId==roomSelect.id" class="d-flex pax-item justify-content-between"
                        [ngStyle]="{'margin-right' : (paxesInItems.length < 3) ? '6px' : '0px'}">
                        <div class="pax-name">
                          <div class="btn d-flex align-items-center pr-4" style="background-color: #afeedd;border-radius: 5px;">
                            <input type="hidden" id="idpax" [value]="roomSelect.id" />
                            <div *ngIf="pax.itemId==roomSelect.id" class="">
                              <i class="icon-small icon-user iconstart text-center pr-3"></i>
                              {{pax.first_name}}
                            </div>
                            <div *ngIf="roomSelect.categoryName=='TOURS'">
                              <input type="hidden" id="{{'idPax-' +pax.idPax}}" [value]="pax.idPax">
                            </div>
                          </div>
                        </div>
                        <div class="delete">
                          <a (click)="removePax(pax.document_number, pax.itemId)" class="btn"
                            style="cursor:pointer;background-color: #e2e2e2 !important;height: 38px;">
                            <i class="icon-cesto"></i>
                          </a>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </accordion-group>
          </accordion>
        </div>
  </div>
</div>

<div class="row d-flex justify-content-between mt-4 bt-4" style="margin-top: 100px !important;   margin-left: 297px !important;">
  <div class="d-flex ml-auto justify-content-end px-0" style="position: absolute;padding-top: 77px;">
    <button class="btn btn-action btn-back ml-2" (click)="goBack()">
      BACK
    </button>
    <div class="d-flex justify-content-end">
      <!--   (click)="_onSubmit($event)"  *ngIf="step > 1 && step < 5"-->
      <button (click)="_onSubmit()"  class="btn btn-action btn-continue ml-2" >
        <!--  (click)="next()" *ngIf="step < 5"-->
        CONTINUE
      </button>
    </div>
  </div>
</div>



<!--- <div class="col-4">
    <span class="mb-3 px-2" style="font-size: 1.65rem;color:#00d397;">SUMMARY</span>
    <div class="sidebar p-2 pb-5">
      <usb-summary-reservation [itineraryItems]="itineraryItems" [itineraryTotal]="itineraryTotal" [itinerary]="itinerary">
      </usb-summary-reservation>
      <button (click)="_onSubmit()" [disabled]='permitedReservation==false || reserving' class="btn btn-danger btn-block"
        style="cursor: pointer; height: 70px;">
        RESERVE
      </button>
      <button (click)="_return()" class="btn btn-info btn-block" style="height: 70px; cursor: pointer;">
        <span >REGRESAR</span>
      </button>
    </div>
  </div>  -->
