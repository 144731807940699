<div id="modal-transport"class="card card-info d-flex flex-row my-3 ">
  <div class="flex-column p-2 d-flex type-vehicle">
    <span class="flex-column">
      <strong>Vehicle Type</strong>
    </span>
    <span class="flex-column">{{vehicle.vehicleType}}</span>
    <span class="pt-2">
      <strong>Transfer Type</strong>
    </span>
    <span>{{vehicle.transferType}}</span>
  </div>
  <div *ngIf="vehicle.maxPax !== null" class="flex-column p-2 d-flex max-people">
    <span class="strong">
      <strong>Capacity</strong>
    </span>
    <i class="icon icon-multiple-users-silhouette" aria-hidden="true"></i>
    <span class="strong">{{vehicle.maxPax + ' ' + 'Person(s)'}}</span>
  </div>
  <div class="flex-column p-2 d-flex vehicles-price">
    <span>
      <strong>Price per trip</strong>
    </span>
    <span>
     <!-- ${{vehicle['price'] | number:'1.2-2'}}- -->
     ${{vehicle.PriceProf | number:'1.2-2'}} -
    </span>
    <span class="pt-2">
        <strong>Tarifa Neta</strong>
    </span>
    <span>
      <strong></strong>
    </span>
    <span>
      ${{vehicle.PriceProf | number:'1.2-2'}} -
  <!--  ${{vehicle.price | number:'1.2-2'}}- -->  
    </span>
  </div>
<!--
  <div class="flex-column col-1 py-2 d-flex max-people radio text-center">
    <span class="strong">
      <strong>Select</strong>
    </span>
    <label class="d-flex justify-content-center text-center pt-2">
      <input #widget class="form-check-input" type="radio" name="itemGroup"   [id]='option' [value]='option' [(ngModel)]='_itemOption' (ngModelChange)="setTotal(option)" />
      <span class="cr ">
        <i class="cr-icon fa fa-circle"></i>
      </span>
    </label>

  </div>-->

</div>
