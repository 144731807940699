import { Component, OnInit, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../../../rest.service';
import { ResultsSearchService } from '../../../../home/results-search.service';
import { ItineraryService } from '../../../itinerary.service';
import { BsModalService, BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
//import { ToastsManager } from 'ng2-toastr';
import * as moment from 'moment';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';
import { setting } from '../../../../../../setting';

@Component({
  selector: 'usb-summary-footer',
  templateUrl: './summary-footer.component.html',
  styleUrls: ['../../itinerary-sidebar.component.scss']
})
export class SummaryFooterComponent implements OnInit {

  @Input() buttonDisabled: Boolean;
  @Input() payload?: any;
  @Input() modeView?: string;
  @Input() activeItinerary?: any;
  @Input() arrivalDate?: any;

  _actualItinerary: any;
  modalRef: BsModalRef;
  _results: any;
  _findData: boolean;
  color_main_3: any;

  public getClientsSettingAppBooking: any;

  constructor(private _router: Router,
    private _restService: RestService,
    private resultService: ResultsSearchService,
    private _itineraryService: ItineraryService,
    public vcr: ViewContainerRef,
//
    public userService:UserService) {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.color_main_3 = this.getClientsSettingAppBooking?.clients_setting_global?.color_main_3;
  }

  ngOnInit() {
  ///  console.log('data payload -->', this.payload);

    this._initData();
  }

  _initData() {
    if(this.userService.itineraryActive.length > 0){
      var data= this.userService.itineraryActive;
      if (data) {
        if (data[0]) {
          this._actualItinerary = data[0];
        } else {
          this._actualItinerary = null;
        }
      }
    }else{
      if(localStorage.getItem('selectedAgency')){
        let agent = JSON.parse(localStorage.getItem('selectedAgency'));
        this._itineraryService.consultActiveItinerary(agent.agent_id);
      }else{
        console.log("holano hay nada en local");
      }

      this._itineraryService.activeItinerary.subscribe((data:any) => {
        if ((data !== undefined) && (data !== null)) {
          if (data[0] !== undefined) {
            this._actualItinerary = data[0];
          } else {
            this._actualItinerary = null;
          }
        }

      },
      error => {
        console.log('Error: ', error);
      });
    }
  }



  _backToMain() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
    this._router.navigate(['/home']);
  }

  _redirectToResults(modeView: string) {
    if (modeView !== undefined && modeView === "MULTIDESTINATION") {
      this.loadResult();
    } else {
      this._router.navigate(['/summary']);
    }

  }

  _saveLocalSearch(payload,results){

    if(localStorage.getItem('payload')){
      //localStorage.clear();
      localStorage.setItem('payload', JSON.stringify(payload));
      localStorage.setItem('results', JSON.stringify(results));
      localStorage.setItem('origin', 'MULTIDESTINATION');
    }
    //localStorage.setItem('arrivalDate', this.arrivalDate);
  }

  loadResult() {

    this._findData = true;


    if (this.payload !== undefined && this.payload.length > 0) {

      this._results = [];
      this.resultService._setPayload(this.payload);

      this._itineraryService.sendMessageSubscriptorLoading(true);

      for (let i = 0; i < this.payload.length; i++) {
        this._restService.doPost('app', 'search', this.payload[i]).subscribe((response:any) => {

          if (response !== null && response.results !== null) {
            this._setSearchIdInResults(response);
          }

          this._results[i] = response.results;
          this._results[i].qtyPax = this.payload[i].qtyPax;
          this._results[i].others = this.payload[i].others;
          this._results[i].destination = this.payload[i].destination;
          this._results[i].categoryName = "HOTELS";
          this._results[i].payload = this.payload[i];

          if (!response.results.length) {
            this._results[i].noResultMessage = 'No results found for the search';
          }

          if (i === (this.payload.length - 1)) {


            this.resultService._setResponse(this._results);
            this.resultService._setOrigin('MULTIDESTINATION');
            this._itineraryService.sendMessageSubscriptorLoading(false);

            //console.log('Los results antes de ir a result content',this._results);

            this._saveLocalSearch(this.payload,this._results);
            this._router.navigate(['/results']);

          }
        }, error => {
          this._results[i] = [];
          this._results[i].noResultMessage = 'No results found for the search';
          this._findData = false;
          this._itineraryService.sendMessageSubscriptorLoading(false);
          console.log(error);
        });
      }

    }



    /*  if (this.activeItinerary !== undefined) {
       this._itineraryService.updateActiveItinerary(this.activeItinerary[0], true);
     }
  */
  }

  _setSearchIdInResults(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }

  _redirectHome(): void {
    this._router.navigateByUrl('/home');
  }

}
