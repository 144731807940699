<div *ngIf="(hotel !== undefined) && (hotel['additionalInfo'] !== undefined)" id="dsny" class="modal-content" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <!-- <div id="modalrooms">
    <div class="modal-content p-4">
      <div class="flex-row">
      </div>
      <div class="flex-column px-1 py-0 d-flex ">
        <h3 class="d-flex pt-2 mb-0 ">{{hotel.title}}</h3>
        <span class="font-location font-poppins">
          <i class="icon-placeholder-green" aria-hidden="true"></i>
          {{hotel.additionalInfo.location}}
        </span>
      </div>
      <div class="d-flex">
        <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome'" class="ml-auto" [readOnly]="true"></bar-rating>
      </div>
      <div class="d-flex justify-content-start py-0 mb-1">
        <div class="d-flex pl-1 pt-0 align-items-center rating-visits">
          {{ratereview}}
          <bar-rating [(rate)]="ratereview" [max]="5" [theme]="'fontawesome-o'" [showText]="false" [readOnly]="true" class="p-1 br"></bar-rating>
        </div>
      </div>
      <hr>
      <div class="modal-body py-2">
        <div>
            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="Room">
                <ngb-panel id="Room">
                  <ng-template ngbPanelTitle>
                      <span class="bottom-number-dsny"><i class="icon-alojamiento icon" ></i></span> <span><b>Room's Categories</b></span>
                  </ng-template>
                  <ng-template ngbPanelContent>      
                      <ngb-accordion [closeOthers]="true" activeIds="1">
                          <ngb-panel *ngFor="let categorie of categories; let i = index" [id]="i">
                            <ng-template ngbPanelTitle>
                              <span class="cat">&#9733; <b>{{categorie['categorie']}}</b> &#9733;</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <ng-template ngFor let-room let-j="index" [ngForOf]="categorie['rooms']">
                                <usb-disney-room [room]="room['room']" [selected]="selected"  [select]="select" [index]="j" [productQty]="productQty" [reserveMultiHab]="hotel.additionalInfo.reserveMultiHab"
                                   (selectRoom)="selectRoom($event)"></usb-disney-room>
                              </ng-template>
                            </ng-template>
                          </ngb-panel>
                    </ngb-accordion>
                    <br/>
                    <div class="rate-opt" *ngIf="selected !== null">
                      <button (click)="getRates(selected, acc)" type="button" class="btn btn-sm btn-usblick">See Rates</button>
                      <button (click)="cancel(acc)" type="button" class="btn btn-sm btn-usblick">Cancel</button>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="Meal" *ngIf="rates.length > 0" >
                  <ng-template ngbPanelTitle>
                      <span class="bottom-number-dsny"><i class="icon-restaurant icon" ></i></span><span><b>Meal's Plans</b></span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <h4><b>Select a Plan</b></h4>
                    <br/>
                    <div *ngFor="let rate of rates;let i = index">
                      <div>
                        <hr class="space" *ngIf="(i>0) && (i%2 === 0)">
                        <button type="button" class="selector-x" (click)="callRates(rate, acc)"><i class="{{rate === _rated? 'icon-dot-tripadvisor icon-selected' : 'icon-dot-tripadvisor-clean icon'}}" >&nbsp;{{rate['name']}}</i></button>
                      </div>
                    </div>
                    <div class="rate-opt" *ngIf="selected !== null">
                      <button (click)="cancel(acc)" type="button" class="btn btn-sm btn-usblick">Cancel</button>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="Ticket" *ngIf="(ticketResult !== null) && (ticketResult !== undefined)">
                    <ng-template ngbPanelTitle>
                        <span class="bottom-number-dsny"><i class="icon-actividades_exterior icon" ></i></span><b><span>Tickets</span></b>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <h4><b>Select a Ticket</b></h4>
                      <br/>
                      <div *ngFor="let _ticket of ticketResult;let i = index">
                        <div>
                          <hr class="space" *ngIf="i>0">
                          <button type="button" class="selector-x" (click)="showDays(_ticket)"><i class="{{ticket === _ticket? 'icon-dot-tripadvisor icon-selected' : 'icon-dot-tripadvisor-clean icon'}}" >&nbsp;{{_ticket['title']}}</i></button>
                        </div>
                      </div>
                      <br>
                      <h4 *ngIf="days.length > 0"><b>Days</b></h4>
                      <br/>
                      <ng-select *ngIf="days.length > 0" [items]="daysArray"  bindLabel="name" bindValue="id" (add)="setDay($event)" placeholder="Days"
                      class="custom w-25">
                      </ng-select>
                      <br/>
                      <div class="text-center d-flex mx-3">
                        <div *ngIf="(_days !== null) && (_days !== undefined) && (_days !== 'Days')">
                          <button class="btn btn-sm btn-usblick lrg mx-2" (click)="getPrice(acc)">Get Ticket Price</button>
                        </div>
                        <div *ngIf="selected !== null">
                          <button (click)="cancel(acc)" type="button" class="btn btn-sm btn-usblick lrg mx-2">Cancel</button>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                <ngb-panel id="Summary" *ngIf="(((mealResult !== null) && (mealResult !== undefined)) || ((_rated !== null) && (_rated !== undefined) && (_rated['name'] === 'Solo Habitación - Sin Tickets')) || ((ticketsPrice !== null) && (ticketsPrice !== undefined)))" >
                    <ng-template ngbPanelTitle>
                        <span class="bottom-number-dsny"><i class="icon-disney icon" ></i></span><span><b>Disney Package Summary</b></span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                    <div *ngIf="(price !== null) && (price !== undefined)" class="rate-opt">
                      <h4><b>Rooms Summary</b></h4>
                      <h5><b>{{price['optionNightsNetTotalFlag']}}TARIFA NETA: </b>$ {{price['optionNightsNetTotalProf'] | number:'1.2-2'}}</h5>
                    </div>
                      <div *ngIf="(mealResult !== null) && (mealResult !== undefined)" class="rate-opt">
                        <h4><b>Meal Summary</b></h4>
                        <h5><b>{{mealResult['additionalInfo']['netPriceFlag']}}TARIFA NETA: </b>$ {{mealResult['additionalInfo']['netPriceProf'] | number:'1.2-2'}}</h5>
                        <h5><b>Total Price: </b>$ {{mealResult['additionalInfo']['totalPriceProf'] | number:'1.2-2'}}</h5>
                      </div>
                      <div *ngIf="(ticketsPrice !== null) && (ticketsPrice !== undefined)" class="rate-opt">
                        <h4><b>Tickets Summary</b></h4>
                        <h5><b>TARIFA NETA: </b>$ {{ticketsPrice['additionalInfo']['netPriceProf'] | number:'1.2-2'}}</h5>
                        <h5><b>Total Price: </b>$ {{ticketsPrice['additionalInfo']['totalPriceProf'] | number:'1.2-2'}}</h5>
                      </div>
                      <div class="rate-opt" *ngIf="selected !== null">
                        <button (click)="cancel(acc)" type="button" class="btn btn-sm btn-usblick">Cancel</button>
                      </div>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>
              <hr/>
        </div>
        <div class="card card-total-price mb-5  pr-5 text-right">
          Total: ${{totalQty | number:'1.2-2'}}
        </div>
        <div class="flex-row py-2">
          <button type="button" class="pull-right btn btn-sm btn-usblick" (click)="continue()" [disabled]="_rated === undefined || _rated === null || (((_rated !== null) && (_rated !== undefined) && (_rated['name'] !== 'Solo Habitación - Sin Tickets')) && (((mealResult === null) || (mealResult === undefined)) && ((ticketsPrice === null) || (ticketsPrice === undefined))) || (((ticketResult !== null) && (ticketResult !== undefined)) && ((ticketsPrice === null) || (ticketsPrice === undefined))))">Continue</button>
          </div>
      </div>
    </div>
  </div> -->
  <div id="modalrooms">
    <!-- header modal start -->
    <div class="row">
      <div class="col-12">
        <p class="title-modal font-poppins m-0">{{stingUpperCase(hotel.title)}}</p>
      </div>
      <div class="col-12">
        <span class="font-location font-poppins">
          <i class="icon-placeholder-green"></i> {{hotel.additionalInfo.location}}
        </span>
      </div>
      <div class="col-12 mt-2 stars">
        <bar-rating [(rate)]="rate" [max]="6" [theme]="'fontawesome'" class="ml-auto" [readOnly]="true"></bar-rating>
      </div>
    </div>
    <!-- header modal end -->

    <!-- nav-tab modal start -->
    <div class="row mt-3">
      <div class="col-12">
        <ul class="nav justify-content-between">
          <li [ngClass]="{'nav-pills-active': tab=='#rooms'}" class="nav-pills font-poppins" (click)="tabs('#rooms')">
            ROOMS</li>
          <li [ngClass]="{'nav-pills-active': tab=='#plans'}" class="nav-pills font-poppins" (click)="tabs('#plans')">
            MEAL`S PLANS</li>
          <li [ngClass]="{'nav-pills-active': tab=='#ticket'}" class="nav-pills font-poppins" (click)="tabs('#ticket')">
            ADMISSION</li>
          <li [ngClass]="{'nav-pills-active': tab=='#package'}" class="nav-pills font-poppins"
            (click)="tabs('#package')">DISNEY SUMMARY</li>
          <li [ngClass]="{'nav-pills-active': tab=='#maps'}" class="nav-pills font-poppins"
            (click)="tabs('#maps')">MAPS</li>
        </ul>
      </div>
    </div>
    <!-- nav-tab modal end -->

    <!-- content-tabs start -->
    <div class="row">
      <div class="col-12 mt-3" id="rooms" [ngClass]="{'d-block' : tab == '#rooms', 'd-none' : tab !== '#rooms'}">
        <!-- <div class="row mb-2">
          <div class="col-12">
            <ul class="nav justify-content-between subnav">
              <li [ngClass]="{'subnav-pills-active': subtab=='#standard'}" class="subnav-pills col-4 font-poppins" (click)="subtabs('#standard')">STANDARD</li>
              <li [ngClass]="{'subnav-pills-active': subtab=='#mega'}" class="subnav-pills col-4 font-poppins" (click)="subtabs('#mega')">MEGA DISPO</li>
              <li [ngClass]="{'subnav-pills-active': subtab=='#nkd'}" class="subnav-pills col-4 font-poppins" (click)="subtabs('#nkd')">NKD</li>
            </ul>
          </div>
        </div> -->

        <div class="row">
          <!-- <div class="col-12" [ngClass]="{'d-block' : subtab == '#standard', 'd-none' : subtab !== '#standard'}" id="standard">
            standard
          </div> -->
          <div class="col-12" [ngClass]="{'d-block' : subtab == '#mega', 'd-none' : subtab !== '#mega'}" id="mega">
            <div class="row">
              <div class="col-12">
                <div class="item-room d-flex flex-column mb-3" *ngFor="let collapse of collapseList; index as i">
                  <div class="d-flex flex-row">
                    <div class="room-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Tipo de Habitación</p>
                          <p class="title-item font-verde font-poppins m-0 p-0">The Little Mermaid Standard Room <span
                              class="data-item font-verde">(Only room)</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="capacity-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Capacidad</p>
                          <i class="icon-two-people"></i>
                          <p class="data-item font-poppins m-0 p-0">Dos por habitación</p>
                        </div>
                      </div>
                    </div>
                    <div class="price-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Precio Por Noche <br> USD 150 -</p>
                        </div>
                      </div>
                    </div>
                    <div class="opciones-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Opciones</p>
                          <p class="option-item font-poppins m-0 p-0"><span
                              class="i-not-included font-poppins">x</span>&nbsp;&nbsp;&nbsp;No incluye régimen de comida
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="select-option">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Select</p>
                          <select name="" id="" class="form-control select-cant-item mt-2">
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-3">
                    <div class="room-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Tipo de Cama</p>
                          <p class="data-item font-poppins m-0 p-0">Una cama doble</p>
                        </div>
                      </div>
                    </div>
                    <div class="capacity-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Incluye</p>
                          <ul class="nav">
                            <li>
                              <i class="icon-wifi"></i>
                            </li>
                            <li>
                              <i class="icon-radio"></i>
                            </li>
                            <li>
                              <i class="icon-coffee"></i>
                            </li>
                            <li>
                              <i class="icon-tv"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="price-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="title-item font-poppins m-0 p-0">Precio Total <br> USD 900 -</p>
                        </div>
                      </div>
                    </div>
                    <div class="opciones-colum">
                      <div class="row">
                        <div class="col-12">
                          <p class="option-item font-poppins m-0 p-0"><span
                              class="i-not-included font-poppins">x</span>&nbsp;&nbsp;&nbsp;No podés cancelar ni
                            realizar cambios</p>
                        </div>
                      </div>
                    </div>
                    <div class="select-option">
                      <div class="row">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-3">
                    <div id="{{'accordion'+i}}">
                      <!-- Imagenes -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingOne'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseOne}" data-toggle="collapse"
                          [attr.data-target]="'#collapseOne'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseOne'+i"
                          (click)="collapse.collapseOne=!collapse.collapseOne;collapse.collapseTwo=false;collapse.collapseThree=false">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            IMAGENES
                          </button>
                          <i class="i-cam float-right mt-1 mr-3"></i>
                        </div>

                        <div id="{{'collapseOne'+i}}" class="collapse" [attr.aria-labelledby]="'headingOne'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12 content-imgSelect">
                                <img [src]="imgList[i][imgSelect[i]]" class="img-gallery" alt="">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="d-flex bg-description pt-2 px-3">
                                  <p class="epigrafe">{{stingUpperCase(hotel.title)}}</p>
                                </div>
                                <div class="d-flex bg-description pt-2 px-4 content-miniatura">
                                  <!-- left scroll -->
                                  <i class="scroll-left" *ngIf="imgList[i].length > 5" (click)="scroll('l',i)"></i>

                                  <div class="d-flex justify-content-between slide-miniatura" id="{{'slide-miniatura'+i}}">
                                    <img [src]="img" class="img-miniatura mr-3" *ngFor="let img of imgList[i]; index as j" [ngClass]="{selectedImg: imgSelect[i]==j}" (click)="imgSelect[i]=j">
                                  </div>
                                  
                                  <!-- right scroll -->
                                  <i class="scroll-right" *ngIf="imgList[i].length > 5" (click)="scroll('r',i)"></i>
                                </div>
                                <div class="d-flex bg-description justify-content-center py-4">
                                  <span class="font-poppins pagination-img">
                                    {{imgSelect[i]+1 +' of '+ imgList[i].length}}
                                  </span>
                                  <a class="ml-5 pagination-btn" (click)="prev(i)" title="previous">
                                    <i class="i-arrow-left" *ngIf="imgSelect[i]>0"></i>
                                    <i class="i-arrow-left-gray" *ngIf="imgSelect[i]==0"></i>
                                  </a>
                                  <a class="ml-5 pagination-btn" (click)="next(i)" title="next">
                                    <i class="i-arrow-right" *ngIf="imgSelect[i]<(imgList[i].length-1)"></i>
                                    <i class="i-arrow-right-gray" *ngIf="imgSelect[i]==(imgList[i].length-1)"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Descripción -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingTwo'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseTwo}" data-toggle="collapse"
                          [attr.data-target]="'#collapseTwo'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseTwo'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=!collapse.collapseTwo;collapse.collapseThree=false">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            DESCRIPCIÓN
                          </button>
                          <i class="i-list float-right mt-1 mr-3"></i>
                        </div>
                        <div id="{{'collapseTwo'+i}}" class="collapse" [attr.aria-labelledby]="'headingTwo'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body bg-description">
                            <p class="title-description font-poppins mb-0">Titulo Descripción</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Facere nisi iusto debitis repudiandae, ut quae ipsum, nam neque quo
                              consequatur beatae dolorum blanditiis expedita, sunt laudantium. Iusto totam voluptate
                              enim!</p>
                            <br>
                            <p class="check-description font-poppins mb-0">HORARIOS CHECK</p>
                            <p class="check-description font-poppins mb-0">Check in: 4 pm</p>
                            <p class="check-description font-poppins mb-0">Check out: 10 am</p>
                            <br>
                            <p class="alert-description title-description font-poppins mb-0">Salidas Anticipadas</p>
                            <p class="alert-description text-description font-poppins mb-0">Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Eum quibusdam labore, mollitia consequatur porro alias nulla
                              repudiandae corporis. Aut, quas.</p>
                            <br>
                            <p class="title-description font-poppins mb-0">Otro texto</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Voluptatem tempora vel non iste omnis fugit fuga eius porro quos.
                              Quisquam tempore deleniti necessitatibus fugiat blanditiis, perferendis eos explicabo
                              delectus non!</p>
                          </div>
                        </div>
                      </div>
                      <!-- Politicas de Cancelacion -->
                      <div class="card">
                        <div class="card-head btn-accordion" id="{{'headingThree'+i}}"
                          [ngClass]="{'activeCollapse': collapse.collapseThree}" data-toggle="collapse"
                          [attr.data-target]="'#collapseThree'+i" aria-expanded="true"
                          [attr.aria-controls]="'collapseThree'+i"
                          (click)="collapse.collapseOne=false;collapse.collapseTwo=false;collapse.collapseThree=!collapse.collapseThree">
                          <button class="btn btn-link font-poppins">
                            <i class="i-arrow-down"></i>
                            &nbsp;&nbsp;&nbsp;
                            POLÍTICAS DE CANCELACIÓN Y PENALIDADES
                          </button>
                          <i class="i-cancel float-right mt-1 mr-3"></i>
                        </div>
                        <div id="{{'collapseThree'+i}}" class="collapse" [attr.aria-labelledby]="'headingThree'+i"
                          [attr.data-parent]="'#accordion'+i">
                          <div class="card-body bg-description">
                            <p class="title-description font-poppins">Titulo Politicas de cancelación</p>
                            <p class="text-description font-poppins mb-0">Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Facere nisi iusto debitis repudiandae, ut quae ipsum, nam neque quo
                              consequatur beatae dolorum blanditiis expedita, sunt laudantium. Iusto totam voluptate
                              enim!</p>
                            <br>
                            <p class="check-description font-poppins mb-0">Items</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <p class="check-description font-poppins mb-0">- Lorem ipsum dolor sit amet.</p>
                            <br>
                            <p class="title-description font-poppins">Algun otro titulo u apartado</p>
                            <p class="text-description font-poppins">Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Voluptatem tempora vel non iste omnis fugit fuga eius porro quos. Quisquam tempore
                              deleniti necessitatibus fugiat blanditiis, perferendis eos explicabo delectus non!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column mb-2">
                  <div class="d-flex flex-row total-bottom">
                    <div class="col-8 my-auto">
                      <p class="font-poppins total-location mb-0"><span class="total-day">7 days</span> cartagena - san
                        andres</p>
                    </div>
                    <div class="col-4 my-auto">
                      <p class="font-poppins total-price text-right mb-0 pr-3">Total: $240</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12" [ngClass]="{'d-block' : subtab == '#nkd', 'd-none' : subtab !== '#nkd'}" id="nkd">
            nkd
          </div> -->
        </div>
      </div>

      <div class="col-12 mt-3" id="plans" [ngClass]="{'d-block' : tab == '#plans', 'd-none' : tab !== '#plans'}">
        <div class="row">
          <div class="col-12">
            <div class="item-room d-flex flex-column mb-2">
              <div class="d-flex flex-row">
                <div class="col-12">
                  <p class="title-item font-poppins">SELECT A PLAN</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Solo habitación - <span class="strong-item">Sin ticket</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Solo habitación - <span class="strong-item">Con ticket</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                  </ul>
                </div>
              </div>

              <div class="hr"></div>

              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Habitación con Plan de comidas Rápidas - <span class="strong-item">Sin tickets</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-burger-dark"></i></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Habitación con Plan de comidas Rápidas - <span class="strong-item">Con tickets</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-3"><i class="icon-burger-dark"></i></li>
                  </ul>
                </div>
              </div>

              <div class="hr"></div>

              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Habitación con Plan de comidas Regular - <span class="strong-item">Sin tickets</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-breakfast-dark"></i></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Habitación con Plan de comidas Regular - <span class="strong-item">Con tickets</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-3"><i class="icon-breakfast-dark"></i></li>
                  </ul>
                </div>
              </div>

              <div class="hr"></div>

              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Habitación con Plan de comidas de Lujo - <span class="strong-item">Sin tickets</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-breakfast2-dark"></i></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-7 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Habitación con Plan de comidas de Lujo - <span class="strong-item">Con tickets</span>
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-3"><i class="icon-breakfast2-dark"></i></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">7 days</span> PLAN DE COMIDAS
                    RÁPIDAS - CON TICKETS</p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $240</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3" id="ticket" [ngClass]="{'d-block' : tab == '#ticket', 'd-none' : tab !== '#ticket'}">
        <div class="row">
          <div class="col-12">
            <div class="item-room d-flex flex-column mb-2">
              <div class="d-flex flex-row">
                <div class="col-9 px-0">
                  <p class="title-item font-poppins">SELECT A TICKETS</p>
                </div>
                <div class="col-3 px-0">
                  <p class="title-item font-poppins">SELECT A DAYS</p>
                </div>
              </div>
              <div class="d-flex flex-row mb-2">
                <div class="col-6 px-0 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Ticket Magia a tu manera <span class="strong-item">Básico</span>
                  </p>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <ul class="nav">
                    <li class="mr-1"><i class="icon-ticket-dark"></i></li>
                  </ul>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <select name="" id="" class="form-control font-poppins select-cant-item">
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>
              <div class="d-flex flex-row mb-2">
                <div class="col-6 px-0 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Ticket Magia a tu manera con Opción <span class="strong-item">Park Hooper</span>
                  </p>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <ul class="nav">
                    <li class="mr-1"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-1">
                      <p class="strong-item mb-0">+</p>
                    </li>
                    <li class="mr-1">
                      <p class="ph mb-0">PH</p>
                    </li>
                  </ul>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <select name="" id="" class="form-control font-poppins select-cant-item">
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>
              <div class="d-flex flex-row mb-2">
                <div class="col-6 px-0 d-flex align-items-center">
                  <label class="custom-radio-checkbox mb-0">
                    <!-- <input class="custom-radio-checkbox__input" type="checkbox" (change)="" ng-checked=""> -->
                    <input class="custom-radio-checkbox__input" type="checkbox">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                  <p class="data-item font-poppins mb-0">
                    Ticket Magia a tu manera con Opción <span class="strong-item">Park Hooper Plus</span>
                  </p>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <ul class="nav">
                    <li class="mr-1"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-1">
                      <p class="strong-item mb-0">+</p>
                    </li>
                    <li class="mr-1">
                      <p class="ph mb-0">PHP</p>
                    </li>
                  </ul>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <select name="" id="" class="form-control font-poppins select-cant-item">
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">7 days</span> TICKETS MAGIA CON
                    PARK HOOPER</p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $240</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3" id="package" [ngClass]="{'d-block': tab == '#package', 'd-none': tab !== '#package'}">
        <div class="row">
          <div class="col-12">
            <div class="item-room d-flex flex-column mb-2">
              <div class="d-flex flex-row">
                <div class="col-1 d-flex align-items-center pr-0">
                  <div class="bg-icon-dark text-center" style="padding-top: 6px;">
                    <i class="icon-bed-green"></i>
                  </div>
                </div>
                <div class="col-4 px-0 d-flex align-items-center">
                  <p class="title-item font-poppins mb-0">
                    ROOMS SUMMARY
                  </p>
                </div>
                <div class="col-7 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-bed-dark"></i></li>
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    TARIFA NETA - <span class="strong-item">$ 699.-</span>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    Solo habitación - <span class="strong-item">Con ticket</span>
                  </p>
                </div>
              </div>

              <div class="hr"></div>

              <div class="d-flex flex-row">
                <div class="col-1 d-flex align-items-center pr-0">
                  <div class="bg-icon-dark text-center" style="padding-top: 8px;">
                    <i class="icon-meals-green"></i>
                  </div>
                </div>
                <div class="col-4 d-flex align-items-center">
                  <p class="title-item font-poppins mb-0">
                    MEALS SUMMARY
                  </p>
                </div>
                <div class="col-7 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-3"><i class="icon-burger-dark"></i></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    TARIFA NETA - <span class="strong-item">$ 233.-</span>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row mt-2">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    <span class="strong-item">TOTAL PRICE - $ 233.-</span>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    Plan de Comidas Rápidas - <span class="strong-item">Con ticket</span>
                  </p>
                </div>
              </div>

              <div class="hr"></div>

              <div class="d-flex flex-row">
                <div class="col-1 d-flex align-items-center pr-0">
                  <div class="bg-icon-dark text-center" style="padding-top: 6px;">
                    <i class="icon-ticket-green"></i>
                  </div>
                </div>
                <div class="col-4 px-0 d-flex align-items-center">
                  <p class="title-item font-poppins mb-0">
                    TICKETS SUMMARY
                  </p>
                </div>
                <div class="col-5 pl-4">
                  <ul class="nav">
                    <li class="mr-3"><i class="icon-ticket-dark"></i></li>
                    <li class="mr-3">
                      <p class="ph mb-0">PH</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    TARIFA NETA - <span class="strong-item">$ 233.-</span>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row mt-2">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    <span class="strong-item">TOTAL PRICE - $ 233.-</span>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-4 px-0 offset-1 d-flex align-items-center">
                  <p class="data-item font-poppins mb-0">
                    Ticket Magia a tu manera - <span class="strong-item">Plan Hooper</span>
                  </p>
                </div>
              </div>

            </div>
            <div class="d-flex flex-column mt-3">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">7 days</span> DISNEY PACK SUMMARY
                  </p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $240</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3" id="maps" [ngClass]="{'d-block': tab == '#maps', 'd-none': tab !== '#maps'}">
        <div class="row">
          <div class="col-12">
            
            <div class="d-flex flex-column mb-2">
              <div class="d-flex flex-row">
                <div class="col-12 content-dir py-2">
                  <p class="direccion-maps mb-0">Universal Blvd, 6725 Adventure Way, Orlando</p>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-12 p-0">
                  <!-- Falta Insertar Mapa -->
                  <img src="/assets/img/app/maps-modal.png" class="img-map" alt="maps">
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="col-12 p-0">
                  <p class="title-description">Titulo Ubicación</p>
                  <p class="description-maps">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione dolorem atque ad quod, qui laudantium exercitationem ex quibusdam nisi sit commodi praesentium velit et ducimus repellendus dolorum laboriosam in blanditiis.</p>
                </div>
              </div>
            </div>

            <div class="d-flex flex-column mt-3">
              <div class="d-flex flex-row total-bottom">
                <div class="col-8 my-auto">
                  <p class="font-poppins total-location mb-0"><span class="total-day">7 days</span> DISNEY PACK SUMMARY
                  </p>
                </div>
                <div class="col-4 my-auto">
                  <p class="font-poppins total-price text-right mb-0 pr-3">Total: $240</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- content-tabs end -->
    <div class="row mt-3">
      <div class="col-12">
        <button class="btn btn-continue font-poppins float-right">CONTINUE</button>
      </div>
    </div>
  </div>
</div>