import { Component, OnInit, Inject } from '@angular/core';
// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ItineraryService } from '../itinerary.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'


@Component({
  selector: 'usb-modal-view-video',
  templateUrl: './modal-view-video.component.html',
  styleUrls: ['./modal-view-video.component.scss']
})
export class ModalViewVideComponent implements OnInit {

  urlSafe: SafeResourceUrl;
  baseUrl:string = 'https://www.youtube.com/embed/';
  constructor(
    // public bsModalRef: BsModalRef,
    private itineraryService: ItineraryService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public urlVideo: any,
    public dialogRef: MatDialogRef<ModalViewVideComponent>,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // console.log("recibi de modal", this.urlVideo);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.urlVideo + '?autoplay=1');
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
