import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-transports',
  templateUrl: './item-transports.component.html',
  styleUrls: ['./item-transports.component.scss']
})
export class ItemTransportsComponent implements OnInit {

  @Input() item: any;
  arrivalDate: Date;
  departureDate: Date;
  _type:any;
  constructor() { }

  ngOnInit() {
    console.log('item', this.item);
    this.arrivalDate = this.dateIt(this.item.arrivalDate);
    this.departureDate = this.dateIt(this.item.departureDate);
    this._type = 0;
    for (let index = 0; index < this.item.vehicles.length; index++) {
        this._type++;
    }
  }
  dateIt(notDateFormatted: any): Date {
    const date = new Date(notDateFormatted);
    return notDateFormatted;
  }
  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str){
   // let e = str.toLowerCase()
    // return e.replace(/\b\w/g, l => l.toUpperCase())
    return str;
  }
}
