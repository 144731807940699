<div class="modal-content">
  <div class="modal-body mb-5 mt-3">
    <div *ngFor="let item of itemToCompare; let i = index;" class="col-{{itemToCompare.length==2 ? 6 : 4}} float-right">
      <div class="card-price">
        <div class="img-company d-flex justify-content-center">
          <img src="assets/img/app/logo-{{item.nameAdapter}}.png">
        </div>
        <ul>
          <li *ngFor="let benefit of item.additionalInfo.benefits">
            {{benefit.description}} <strong class="price-right">{{benefit.detail}}</strong>
          </li>
        </ul>
        <div class="price pl-4 pr-4">
          <div class="display-inline"><span class="price-mount">${{item.additionalInfo.totalPrice}} <span>P/Person</span></span></div>
          <div class="display-inline-r"><button type="button" (click)="add(item)" class="btn btn-usblick-pink justify-content-right py-1 button-add">Add</button></div>
        </div>
      </div>
    </div>
  </div>
</div>
