import { ItineraryService } from 'app/shared/itinerary.service';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import { Logger } from './core/logger.service';
import { I18nService } from './core/i18n.service';
import { AppService } from './app.service';
import { setting } from '../../setting';
import { UserService } from './pages/com.usblick.common/auth/user.service';
import { AuthService } from './pages/com.usblick.common/auth/auth.service';
import { AgentsService } from './agents/agents.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from './core/profiles/storeProfile';
import { selectPermissions } from './core/profiles/profiles.selectors';
import { SeoService } from 'seoService';


const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public loadSetting : boolean;
  public baseUrl: string;
	public apiBookingUrl: string;
	public domain: string;
	public getClientsSettingAppBooking: any;
  public cssVariableValue: string;
  public clientsSettings: any;
  public agency: any;
  namePaymentCondition: any;
  iconPaymentCondition: any;
  isAgencyPaymentCondition: boolean = false;
  balanceBlickoins: any = 0;
  creditLimits: any = 0;
  creditDeposits: any = 0;
  creditBlickoins: any = 0;
  appLoading: boolean = true;
  faviconUrl: string;

  permissions$: Observable<string[]>;
  permissionsMap: { [key: string]: boolean } = {};

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public vcr: ViewContainerRef,
    private titleService: Title,
    private translateService: TranslateService,
    private appService: AppService,
    public userService: UserService,
    private authService: AuthService,
    public agentsService: AgentsService,
    private _itineraryService: ItineraryService,
    private store: Store<AppState>,
    private seoService: SeoService
  ) {}

  ngOnInit() {

      // CARGA CONFIGURACION DEL CLIENTE
      this.loadSetting = true;
      localStorage.removeItem(setting.name);
      setTimeout(() => {
          this.appService.getClientsSettingAppBooking().subscribe(
            (response: any) => {
              localStorage.setItem(setting.name, JSON.stringify(response));
              this.clientsSettings = JSON.parse(localStorage.getItem(setting.name));
              this.getPermissions();
              this.getAgencyData();


              this.loadSetting = false;
            },
            error => {
            }
          );
        }, 500);
      // FIN  CARGA CONFIGURACION DEL CLIENTE
    // Setup loggerd
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    const onNavigationEnd = this.router.events.filter(event => event instanceof NavigationEnd);

    // Change page title on navigation or language change, based on route data
    Observable.merge(this.translateService.onLangChange, onNavigationEnd)
      .map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    const faviconUrl = this.seoService.getFaviconUrl();
    const link: HTMLLinkElement = document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = faviconUrl;
    document.head.appendChild(link);

    this.getSubscriptionLoading();
  }

  getSubscriptionLoading(): void {
    this._itineraryService.loading$.subscribe((res: boolean) => {
      console.log(res);
      if(!res) {
        this.appLoading = false;
      }
    })
  }

  getPermissions(): void {
    this.permissions$ = this.store.select(selectPermissions);
    // Despacha las acciones para cargar roles y permisos
    this.store.dispatch({ type: '[Auth] Load Permissions' });

    this.permissions$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(permissions => {
      this.permissionsMap = this.createPermissionsMap(permissions);
    });
  }

  createPermissionsMap(permissions: any[]): { [key: string]: any } {
    const map: { [key: string]: any } = {};
    permissions.forEach((permission) => {
      // Utiliza el nombre del permiso como clave
      map[permission.name] = permission;
    });
    return map;
  }

  setBalance() {
    let pass = false;
    let creditsBlickoins = 0;
    this._itineraryService.activeItinerary
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((data: any) => {
      pass = true;
      this.agentsService.getBalanceAgency(this.userService._agencyID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: any) => {
        // console.log("getBalanceAgency  activeItinerary", response);
        this.userService.creditsDeposits = parseFloat(response.balanceCreditsDeposits) ? parseFloat(response.balanceCreditsDeposits) : 0;
        this.userService.credits = parseFloat(response.balanceCredits) ? parseFloat(response.balanceCredits) : 0;
        this.userService.balanceBlickoins = parseInt(response.availableBlickoins) ? parseInt(response.availableBlickoins) : 0;
        this.userService.creditBlickoins = parseFloat(response.availableCreditBlickoins) ? parseFloat(response.availableCreditBlickoins) : 0;
        this.userService.creditLimits = this.userService.credits + this.userService.creditsDeposits;
        // this.balanceBlickoins = this.userService.balanceBlickoins;
        this.creditLimits = this.userService.creditLimits;
      });
    });
  }

  setBalanceBlickoins(): void {
      this.agentsService.getBalanceAgent(this.userService._agentID)
      .subscribe((response: any) => {
        console.log(response)
        this.userService.balanceBlickoins = response.availableBlickoins;
        this.userService.creditBlickoins = response.availableCreditBlickoins;
      })
  }



  getAgencyData() {
    this.authService._getAgent();
    this.setBalance();

    setTimeout(() => {
      if(this.userService?.userActive && this.userService?.userActive?.pay_mode) {
        this.setAgencyPaymentCondition(this.userService.userActive.pay_mode);

      }
    }, 2000);
  }

  setAgencyPaymentCondition(pay_mode: number) {
    if (pay_mode) {
        // setTimeout(() => {
        this.agentsService.agencyPaymentConditionByID(pay_mode)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (response: any) => {
                this.isAgencyPaymentCondition = false;
                if(response && typeof response === 'object' && response !== null) {
                  this.namePaymentCondition = response.name;
                  this.iconPaymentCondition = response.icono;
                  this.agentsService.paymentConditions = response;
                  this._itineraryService.paymentConditions = response;
                  this.isAgencyPaymentCondition = true;
                  this.setBalanceBlickoins();
                  this.appLoading = false;
                } else {
                  console.error('Invalid payment condition response:', response);
                  // Handle the case when the payment condition is not available
                  // Set default values or display an appropriate message.
                }
          },
          error: (error: HttpErrorResponse) => {
            alert('refrescar página');
          }
        })
      // }, 1500);
    }
  }



}
