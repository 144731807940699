import { RestService } from "../../rest.service";
import { log } from "util";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { formatArrivalDate, generateArray } from "../../../utils/Functions";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { ItineraryService } from "../../itinerary.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "usb-body-booking",
  templateUrl: "./body-booking.component.html",
  styleUrls: ["./body-booking.component.scss"],
})
export class BodyBookingComponent implements OnInit {
  @Input() roomSelect: any = [];
  @Input() hotel: any = [];
  @Output() steps = new EventEmitter<any>();
  @Input() paxes: any = [];
  @Input() itineraryId: any;
  partialReserve: boolean = false;
  @Output() paxesForItems = new EventEmitter<any>();
  @Input() itinerary: any;
  i = 0;
  from = "total-reserve";
  itineraryTotal = 0;
  public formatDate = formatArrivalDate;
  productItem: any = [];
  selectedItem = "";
  namehotel: any;
  // tslint:disable-next-line:max-line-length

  paxesInItems: Array<{
    idPax: any;
    itemId: string;
    document_number: string;
    first_name: string;
    last_name: string;
    email: string;
    country: string;
    birth_date: string;
    title: string;
    identificationType: string;
    state: string;
    phone: string;
    phone2: string;
    city: string;
    address: string;
    postal_code: string;
  }> = [];

  registerClient: boolean = false;
  resultReserve: any = [];
  permitedReservation: boolean = false;
  reserving: boolean = false;
  emailPrimary: any;
  countryPrimary: any;
  documentPrimary: any;
  isReadOnly: any;
  element: HTMLElement;
  element_: HTMLElement;
  transport: any = [];
  ismeridian = false;
  _form: FormGroup;
  message = [];
  transportLabel: string = "Info";
  transportEx: any;
  isCruise: boolean = false;

  constructor(
    private route: Router,
    private _restService: RestService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    public userService: UserService,
    private _itineraryService: ItineraryService,
    public ngSpinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    console.log("paxes llegenado", this.paxes);
    console.log("roomSelect llegenado", this.roomSelect);
    console.log("hotel llegenado", this.hotel);

    if (this.roomSelect.categoryName === "HOTELS") {
      this.namehotel = this.roomSelect.title;
    }
    if (!this.namehotel) {
      this.namehotel = "";
    }

    if (this.paxes) {
      this.paxes.forEach((pax) => {
        if (this.getDataOfPax(pax.document_number, "relationShip") === null) {
          this.documentPrimary = pax.document_number;
          this.countryPrimary = this.getDataOfPax(pax.document_number, "country");
          this.emailPrimary = this.getDataOfPax(pax.document_number, "email");
        }
      });
    } else {
      this.userService.itineraryActive[0].paxlist.forEach((pax) => {
        if (this.getDataOfPax(pax.document_number, "relationShip") === null) {
          this.documentPrimary = pax.document_number;
          this.countryPrimary = this.getDataOfPax(pax.document_number, "country");
          this.emailPrimary = this.getDataOfPax(pax.document_number, "email");
        }
      });
    }

    this.transport = [
      {
        name: "Flight",
        label: "Flight Code",
        placeholder: "AA908",
        value: "flight",
      },
      {
        name: "Train",
        label: "Ship Name",
        placeholder: "TRSB",
        value: "train",
      },
      {
        name: "Cruise",
        label: "Company Name",
        placeholder: "MSC Sinfonía",
        value: "cruise",
      },
    ];
    this.initForm();
  }

  goBack() {
    ///this.steps.emit(2);
    // tslint:disable-next-line:triple-equals
    // tslint:disable-next-line:no-unused-expression
    this.userService.stepReservation = "two";
  }

  initForm() {
    this._form = this.fb.group({
      arrivalTime: [null, Validators.required],
      transport: ["", Validators.required],
      idTransport: ["", Validators.required],
      cruiseNumber: ["", Validators.required],
    });
  }

  getRandom() {
    return Math.random().toString(36).substring(7);
  }

  setPaxToItem(pax: any, item: any, event: any) {
    let allowNewPax = this.checkQtyPaxesByCategory(item);
    let email = this.getDataOfPax(pax, "email");
    let country_id = this.getDataOfPax(pax, "country_id");
    let document_number = pax;

    if (pax != null && allowNewPax && event.target.value != null) {
      let exists = this.paxesInItems.find((val) => val.itemId == item.id && val.document_number == pax);
      if (exists) {
        console.log("==>pax ya INCLUDEDo en el item");
      } else {
        // obtener datos del pax principal
        if (this.getDataOfPax(pax, "relationShip") === null) {
          this.documentPrimary = pax;
          this.countryPrimary = this.getDataOfPax(pax, "country");
          this.emailPrimary = this.getDataOfPax(pax, "email");
        }

        if (pax === null) {
          document_number = this.documentPrimary;
        }
        if (country_id === null) {
          country_id = this.countryPrimary;
        }
        if (email === "undefined" || email === null) {
          email = this.emailPrimary;
        }

        let personal = this.paxes.find((element) => element.document_number == document_number);
        console.log("this.personal ", personal);

        this.paxesInItems.push({
          idPax: this.getRandom(),
          itemId: item.id,
          document_number: document_number,
          first_name: this.getDataOfPax(pax, "name"),
          last_name: this.getDataOfPax(pax, "last_name"),
          email: email,
          country: country_id,
          birth_date: personal.birth_date ? personal.birth_date : null,
          title: personal.title ? personal.title : null,
          identificationType: personal.document_type_id ? personal.document_type_id : null,
          phone: personal.phone,
          phone2: personal.phone_2,
          address: personal.address,
          city: personal.city,
          state: personal.state_id,
          postal_code: personal.postal_code,
        });

        //validar si ya seteo todo los paxess
        let allowNewPax = this.checkQtyPaxesByCategory(item);
        // this.productItem.$item.id = true;

        this.paxesForItems.emit(this.paxesInItems);
        this._permitedReservation();
        console.log("paxesForItems  emit", this.paxesInItems);
      }

      console.log("this.paxesInItems ", this.paxesInItems);
    }
    // this.selectedItem = '';
    event.target.value = "";
  }

  setTransportToItem(trans) {
    this.transportLabel = this.transport.find((e) => e.value === trans).label;
    this.transportEx = this.transport.find((e) => e.value === trans).placeholder;
    trans == "cruise" ? (this.isCruise = true) : (this.isCruise = false);
  }

  getDataOfPax(pax: string, name: string) {
    const index = this.paxes.findIndex((val) => val.document_number === pax);

    if (name === "name") {
      return this.paxes[index].first_name;
    }
    if (name === "last_name") {
      return this.paxes[index].last_name;
    }
    if (name === "email") {
      return this.paxes[index].email;
    }
    if (name === "country") {
      return this.paxes[index].country;
    }
    if (name === "relationShip") {
      return this.paxes[index].relationShip;
    }
  }

  _permitedReservation() {
    let permited = true;
    this.permitedReservation = false;
   /* this.itineraryItems.forEach((item) => {
      if (this.checkProductConfirm(item) === false) {
        permited = false;
      }
    });
*/
    this.permitedReservation = permited;
  }

  checkProductConfirm(item: any): boolean {
    const paxesFilter = this.paxesInItems.filter((val) => val.itemId === item.id);
    if (item.categoryName === "RENT-A-CAR") {
      return paxesFilter.length === item.paxAdults + item.paxChildren ? true : false;
    }
    // tslint:disable-next-line:max-line-length
    if (item.categoryName === "HOTELS" || item.categoryName === "INSURANCE" || item.categoryName === "CRUISES") {
      return paxesFilter.length === item.paxAdults + item.paxChildren ? true : false;
    }
    // tslint:disable-next-line:max-line-length
    if (item.categoryName === "TOURS") {
      // tslint:disable-next-line:max-line-length
      this.element_ = document.getElementById("hotel-" + item.id) as HTMLElement;
      if (this.element_) {
        // tslint:disable-next-line:max-line-length
        if ((document.getElementById("hotel-" + item.id) as HTMLInputElement).value === "" || !this.checkPaxConfirm(item)) {
          return false;
        }
      }
      return paxesFilter.length >= 1 ? true : false;
    }
    // tslint:disable-next-line:max-line-length
    if (item.categoryName === "DISNEY" || item.categoryName === "PTHEMES") {
      return paxesFilter.length >= 1 ? true : false;
    }

    if (item.categoryName === "TRANSPORTS") {
      if (
        (document.getElementById("transport-" + item.id) != null &&
          (document.getElementById("transport-" + item.id) as HTMLInputElement).value === "") ||
        (this._form.get("arrivalTime") != null && this._form.get("arrivalTime").value == null) ||
        (document.getElementById("idTransport-" + item.id) != null &&
          (document.getElementById("idTransport-" + item.id) as HTMLInputElement).value === "") ||
        (document.getElementById("transport-" + item.id) != null &&
          (document.getElementById("transport-" + item.id) as HTMLInputElement).value === "cruise" &&
          (document.getElementById("cruiseNumber-" + item.id) as HTMLInputElement).value === "")
      ) {
        return false;
      } else {
        return paxesFilter.length >= 1 ? true : false;
      }
    }
  }

  checkPaxConfirm(item: any): boolean {
    let verif = false;
    const paxesFilter = this.paxesInItems.filter((val) => val.itemId === item.id);
    if (item.categoryName === "TOURS") {
      for (let i = 0; i < paxesFilter.length; i++) {
        this.element = document.getElementById("document_number-" + paxesFilter[i].idPax) as HTMLElement;

        if (this.element) {
          const document_number = (document.getElementById("document_number-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          const email = (document.getElementById("email-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          const country = (document.getElementById("country-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          if (document_number === "" || email === "" || country === "") {
            return false;
          } else {
            verif = true;
          }
        } else {
          verif = true;
        }
      }
      return verif === true ? true : false;
    }
  }

  checkQtyPaxesByCategory(item): boolean {
    let paxes = 0;
    paxes = item.paxAdults + item.paxChildren;

    let qtyPaxesItem = 0;

    this.paxesInItems.forEach((pax) => {
      if (pax.itemId == item.id) {
        qtyPaxesItem += 1;
      }
    });
    if (qtyPaxesItem < paxes) {
      return true;
    }
    return false;
  }

  removePax(pax, item) {
    let index = this.paxesInItems.findIndex((val) => val.itemId == item && val.document_number == pax);
    this.paxesInItems.splice(index, 1);
    this.paxesForItems.emit(this.paxesInItems);
    this._permitedReservation();
  }

  _onSubmit() {
    this.steps.emit(4);
  }
}
