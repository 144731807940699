import { DatePipe } from "@angular/common";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ResultsSearchService } from "../../home/results-search.service";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { RestService } from "../../shared/rest.service";
import { ItineraryService } from "../../shared/itinerary.service";
import { ModalLoadingComponent } from "../../shared/modal-loading/modal-loading.component";
import { MatDialog } from "@angular/material/dialog";
import { MaterialModalConfirmComponent } from "../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../shared/material-modal-alert/material-modal-alert.component";

@Component({
  selector: "usb-card-itinerary",
  templateUrl: "./card-itinerary.component.html",
  styleUrls: ["./card-itinerary.component.scss"],
})
export class CardItineraryComponent implements OnInit {
  items: any[] = [1, 2, 3, 4, 5, 6];

  @Input() item: any;
  @Input() index: any;
  @Input() _status: string;
  @Input() reservation: any;
  @Input() itinerary: any;
  @Input() showTextChangeDetailsProducts: boolean;
  @Output() reserve = new EventEmitter<any>();
  @Output() delete = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();
  @Output() updateIti = new EventEmitter<string>();
  @Output() modifications = new EventEmitter<string>();
  @Output() reloadItinerary = new EventEmitter<string>();
  @Input() partialReserve: boolean;
  @Input() changePriceFromReserve: boolean;

  //reservation: any;
  status: string;
  selected: boolean;
  modifications_: boolean;
  @Output() itemToPartial = new EventEmitter<number>();

  constructor(
    private resultsSearch: ResultsSearchService,
    private router: Router,
    private _restService: RestService,
    public userService: UserService,
    public serviceItinerary: ItineraryService,
    private dialog: MatDialog,
  ) {
    this.status = "non-updated";
    this.selected = false;
  }

  ngOnInit() {
console.log(this.item)
  }


  _reloadItinerary() {
    this.reloadItinerary.emit();
  }


  acceptNewPrice() {
    this.status = "updated";
    this.item.modifications = false;
    this.modifications_ = true;
    this.modifications.emit(this.item);
  }

  deleteItem(id: string) {
    this.delete.emit(id);
  }

  cancelReservations(id: string) {
    this.cancel.emit(id);
  }

  /// modal loading
  openModalLoading(data: any) {
    var dialogRef = this.dialog.open(ModalLoadingComponent, {
      width: "500px",
      id: "loadModal",
      data: data,
      disableClose: true,
    });

    /* dialogRef.afterClosed().subscribe(result => {
      });*/
  }

  select() {
    this.selected = this.selected ? false : true;
  }

  reserveItem() {
    this.reserve.emit(this.item);
  }

  updateItem(previousState: string) {

    console.log("data split updateItem ", this.item);

    //  let newPrice = this.item.newPrice;
    // this.item.newPrice = -1;
    // this.item.available = false;        // en true sale  non-available
    //this.item.roomAvailable = false;    // en true sale room-non-available
    //this.item.modifications = true;     // true parq q haya una modificación

    //actualizar la rentalibidad del nuevo precio
    // tslint:disable-next-line:max-line-length

    // this.item.modifications = false; // TEMPORAL 11 NOVIEMBRE 2021

    if (this.item.verified) { // verifica si se hizo la verificacion en en servidor
      if (!this.item.modifications) {
        this.status = "without-changes";
      } else {
        if (this.item.available) {
          if (this.item.roomAvailable) {
            if (!this.item.samePrice) {
           //   this.item.newNetPriceUsblick = -1;
              // tslint:disable-next-line:max-line-length
           /*   this.serviceItinerary.getProfitability(this.item.newPrice, this.item.item.category_id, this.item.item.providerId).subscribe((data: any) => {
                  console.log("data split updateItem ", data);
                  this.item.newNetPriceUsblick = data.netprice; /// data.split('-')[0];
                  const newNetPriceAgent = this.item.newNetPriceUsblick / (1 - this.item.item.percentageAgent / 100);
                  this.item.newNetPriceAgent = parseFloat(newNetPriceAgent.toFixed(2));
                  this.item.percentage = data.percentage; /// data.split('-')[2];
                });*/

              this.status = "price-non-available";
            }
          } else {
            // tslint:disable-next-line:max-line-length
         /*   this.serviceItinerary
              .getProfitability(this.item.newPrice, this.item.item.category_id, this.item.item.providerId)
              .subscribe((data: any) => {
                this.item.newNetPriceUsblick = data.netprice; // data.split('-')[0];
                // tslint:disable-next-line:max-line-length
               // const newNetPriceAgent = this.item.newNetPriceUsblick / (1 - this.item.item.percentageAgent / 100);
               // this.item.newNetPriceAgent = parseFloat(newNetPriceAgent.toFixed(2));
               // this.item.percentage = data.percentage; //  data.split('-')[2];
              });*/
            this.status = "room-non-available";
          }
        } else {
          this.status = "non-available";
        }
      }
    } else {
      this.status = "not-verified";
    }

  }

  getImage(): string {
    let image;
    switch (this.item.item.categoryName) {
      case "DISNEY":
        image = this.item.item.hotel.additionalInfo.images[0].image;
        break;
      default:
        image = "assets/img/app/hotel_default.png";
        break;
    }
    return image;
  }

  _itemToPartial(qty: number) {
    //this.qtyItemsToPartial = this.qtyItemsToPartial + qty;
    this.itemToPartial.emit(qty);
  }

  makePayload(item: any) {
    const _payload = [];
    console.clear();
    console.log('item del makepayload ->', item);
    console.log('products payloadupdate ->', this.item.item.payloadUpdate);

      const  payload = this.item.item.payloadUpdate.products[0];
      const form = this.item.item.form;
      _payload[0] = payload;

      console.log("form +", form);
      localStorage.setItem("form", JSON.stringify(form));
      localStorage.setItem("payload", JSON.stringify(_payload));
      this.openModalLoading(JSON.parse(localStorage.getItem("payload")));
      this._restService.doPost("app", "search", payload).subscribe(
        (response: any) => {
          console.log('response ->', response);

          if (response !== null && response.results !== null) {
            console.log('payload ->', payload);

            const payloadArray = [payload];
            this.resultsSearch._setPayload(payloadArray);
            localStorage.setItem("form", JSON.stringify(form));
            localStorage.setItem("payload", JSON.stringify(payloadArray));
            this.resultsSearch._setOrigin(this.item.item.categoryName);

            this._setSearchIdInResults(response);
            if (response.results.length > 0) {
              this._handleResponse(response, this.item.item.categoryName, item);
              this.dialog.closeAll();
              this.serviceItinerary.notifyModalClosed(true);
              this.router.navigate(["/results"]);
            } else {
              var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
                height: "auto",
                width: "350px",
                data: "No se encontraron Resultados",
                disableClose: true,
              });
            }
          }
        },
        (error) => {
          const message = "Ocurrió un error al intentar encontrar productos";
          this.dialog.closeAll();
          var dialogRef = this.dialog.open(MaterialModalConfirmComponent, {
            width: "450px",
            data: {message: message},
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.makePayload(item);
            } else {
              this.dialog.closeAll();
            }
          });
        }
      );

  /*  } else {
      //Es multidestination
      console.log("Multidestination research");

      var _destinations = [];
      let itinerary = this.userService.itineraryActive;
      if (itinerary[0].destination.length > 0) {
        for (let i = 0; i < itinerary[0].destination.length; i++) {
          const arrival = itinerary[0].destination[i].destination_info.arrival.split("-");
          _destinations.push({
            cityIataCode: itinerary[0].destination[i].iata_code,
            cityName: itinerary[0].destination[i].destination_info.cityName,
            nights: itinerary[0].destination[i].destination_info.nights,
            adults: itinerary[0].destination[i].destination_info.adults,
            childs: itinerary[0].destination[i].destination_info.childs,
            rooms: itinerary[0].destination[i].destination_info.rooms,
            roomsDist: itinerary[0].destination[i].destination_info.roomDist,
            yy: this.datePipe.transform(itinerary[0].destination[i].destination_info.arrival, "yy"),
            mm: this._getMonthName(arrival[1]),
            dd: arrival[2],
          });
        }
      }

      console.log("_destinations", _destinations);
      let payloadArray = this._setPayloadArray(_destinations);
      console.log("payloadArray", payloadArray);
      this.loadMultidestinationResult(payloadArray);
    } */
  }

  loadMultidestinationResult(payload: any) {
    if (payload !== undefined && payload.length > 0) {
      let _results = [];
      this.resultsSearch._setPayload(payload);

      for (let i = 0; i < payload.length; i++) {
        this._restService.doPost("app", "search", payload[i]).subscribe(
          (response: any) => {
            if (response !== null && response.results !== null) {
              this._setSearchIdInResults(response);
            }

            _results[i] = response.results;
            _results[i].qtyPax = payload[i].qtyPax;
            _results[i].others = payload[i].others;
            _results[i].destination = payload[i].destination;
            _results[i].categoryName = "HOTELS";
            _results[i].payload = payload[i];

            if (!response.results.length) {
              _results[i].noResultMessage = "No results found for the search";
            }

            if (i === payload.length - 1) {

              this.resultsSearch._setResponse(_results);
              this.resultsSearch._setOrigin("MULTIDESTINATION");
              //this.spinner.hide();
              this.router.navigate(["/results"]);
            }
          },
          (error) => {
            //this.spinner.hide();
            //this._results[i] = [];
            //this._results[i].noResultMessage = 'No results found for the search';
            //this._findData = false;
            //this._itineraryService.sendMessageSubscriptorLoading(false);
            console.log(error);
          }
        );
      }
    }
  }

  //metodos auxiliares
  _setSearchIdInResults(response: any) {
    response.results.forEach((obj) => {
      obj.searchId = response.searchId;
    });
  }

  _setPayloadArray(destinations: any) {
    let _payloadArray = [];
    let payload = this.item.item.payloadUpdate.products[0];
    let arrivalDate = this.userService.itineraryActive[0].arrivalDate;


    for (let i = 0; i < destinations.length; i++) {
      let arrival = null;
      if (payload !== undefined && payload !== null) {
        if (payload.others !== undefined && payload.others !== null) {
          var qtyAdults = 0;
          if (payload.others.hotels) {
            payload.others.hotels.roomConfiguration.forEach((room) => {
              qtyAdults += parseInt(room.adults);
            });
          }
        }
      }
      if (arrivalDate !== undefined || arrivalDate !== null) {
        arrival = new Date(i === 0 ? arrivalDate : _payloadArray[i - 1].departureDate);
        _payloadArray[i] = {
          arrivalDate: i === 0 ? arrivalDate : _payloadArray[i - 1].departureDate,
          categoriesId: [1],
          departureDate: this.userService.itineraryActive[0].destination[i].destination_info.departure,
          origin: "MULTIDESTINATION",
          destination: destinations[i]["cityIataCode"],
          others: payload.others,
          priceFrom: payload.priceFrom,
          priceTo: payload.priceTo,
          providersId: payload.providersId,
          qtyPax: qtyAdults,
          qtyProduct: 1,
        };
      } else {
        ////this.toastr.error('Arrival date must defined in home.', 'Unknown Arrival Date.');
      }
    }

    return _payloadArray;
  }

  _handleResponse(response: any, categoryName: string, payload: any) {
    if (response && response.results != null) {
      try {
        let _resultsQuery: any;
        _resultsQuery = response.results;
        _resultsQuery.categoryName = categoryName;
        _resultsQuery.arrivalDate = payload.arrivalDate;
        _resultsQuery.destination = this.item.item.destination.iata_code;
        if (!response.results.length) {
          _resultsQuery.noResultMessage = "No results found for the search";
        }
        const arrayResults = [_resultsQuery];
        console.log('arrayResults ->', arrayResults);

        this.resultsSearch._setResponse(arrayResults);
      } catch (e) {
        console.log("error", e);
      }
    }
  }

  //multidestinetion add extras
  _getMonthName(month: number) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Octr", "Nov", "Dec"];
    if (month !== null) {
      return monthNames[month - 1];
    }
  }

  reSearch(event) {
    console.log('event research ->', event);
    this.makePayload(event);
  }
}
