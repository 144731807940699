import { Component, OnInit, TemplateRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ResultsSearchService } from '../home/results-search.service';
import { TitleFilterPipe } from '../shared/titleFilter.pipe';
import { RestService } from '../shared/rest.service';
import { ResultsMultidestinations } from './category-result/results.multidestinations';
import { PayloadCategoryMultidestinations } from './category-result/payload.category';
import { Procesed } from '../shared/enum-procesed';
import { ItineraryService } from '../shared/itinerary.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalLoadingComponent } from '../shared/modal-loading/modal-loading.component';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../shared/material-modal-confirm/material-modal-confirm.component';
import { UserService } from '../pages/com.usblick.common/auth/user.service';
import { PACKAGES } from '../home/search-box/categories/packages/response/searchResponsepackages';
import { CategoryResultComponent } from './category-result/category-result.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'usb-content-result',
  templateUrl: './content-result.component.html',
  styleUrls: ['./content-result.component.scss'],
  providers: [
    TitleFilterPipe
  ]
})

export class ContentResultComponent implements OnInit {

  @ViewChild(CategoryResultComponent) CategoryResultComponent: CategoryResultComponent;

  @Output() isMultiModal = new EventEmitter<any>();
  payloadCategory: PayloadCategoryMultidestinations;
  resultsMultidestinations: ResultsMultidestinations;
  modalRef: BsModalRef;
  group: any;
  _payload: any;
  _results: any;
  _resultPaged: any;
  _origin: string;
  _tabSelected: string;
  tabSelected: string;
  isLoading: boolean = true;
  multiModal = false;
  text: string;
  auxiliarArray: any;
  itinerary: any;
  ori: any;
  pax = 0;
  paxAdults = 0;
  paxChildren = 0;
  payloadForm: any;
  viewMap=false;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };

  resultsFound = false;
  _payloadTransport = [];
  _applyFilter = [];
  _emit = false;
  _contentCollapsed = true;
  public isCollapsed = {};
  public items = ['item 1', 'item 2', 'item 3'];

  filtersRentacar: any;

  bsModalRef?: BsModalRef;

  /// carrusel
  noWrapSlides = false;
  showIndicator = true;

  currency_exchange: any[] = [];

  private searchLocationssubscription: Subscription;


  constructor(
    private modalService: BsModalService,
    private resultsSearch: ResultsSearchService,
    private _restService: RestService,
    private _router: Router,
    private serviceItinerary: ItineraryService,
    private titleFilter: TitleFilterPipe,
    public userService: UserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.isLoading  = false;
    this._results = this.resultsSearch._getResponse();
    this._payload = this.resultsSearch._getPayload();
    console.log(this._payload)
    this._origin = this.resultsSearch._getOrigin();
    let offline = false;

    this.initResultsMultidestination();
    this.initPayloadCategoryMultidestination();

    if (!this._results || !this._payload) {

      this._origin = localStorage.getItem('origin');
      this._payload = JSON.parse(localStorage.getItem('payload'));
      offline = true;
    }

    /// ESTO ES TEMPORAL HASTA QUE EXISTA REUSLTADO DE BUSQUEDA DE DYSNEY
    if ((this._origin == "DISNEY") || (this._origin == "PTHEMES")) {
      offline = false;
    }

    if (this._origin === Procesed.MULTIDESTINATION) {
      this._tabSelected = Procesed.HOTELS;
      this.resultsFound = true;
    } else {
      if (this._results !== undefined && this._results !== null) {
        // tslint:disable-next-line:max-line-length
        this.resultsFound = this._results[0] !== undefined ? this._results[0]['noResultMessage'] !== undefined ? false : true : this._results['noResultMessage'] !== undefined ? false : true;
      }
    }
    if (this._results !== undefined) {
      for (let index = 0; index < this._results.length; index++) {
        this.isCollapsed[index] = true;
      }
     }

    if (offline) {
      this.loadResult('HOTELS', this._payload);
    }

    //this.getItinerary(this.userService.itineraryActive[0]);
  }

  _filtersRentacar(value: any) {
    this.filtersRentacar = value;
  }

  applyFilter(event: any, value: any, category: any, id: any ) {

    this.CategoryResultComponent.applyFilter(event, value, category, id);
  }

  toggleCollapse() {
    this._contentCollapsed = !this._contentCollapsed;
  }


  closeCollapse(close: boolean) {
    this._contentCollapsed = close;
  }

  getItinerary(itinerary: any) {
    this.itinerary = itinerary;

    this.pax = 0;
    this.paxAdults = 0;
    this.paxChildren = 0;
    if (itinerary[0] !== undefined) {
      for (let i = 0; i < itinerary[0].destination.length; i++) {
        const destiny = itinerary[0].destination[i];
        for (let j = 0; j < itinerary[0].items.length; j++) {
          const item = itinerary[0].items[j];
          let payload = item.payloadUpdate && item.payloadUpdate.products ? item.payloadUpdate.products[0] : item.payload;
          if ((item.categoryName === 'TRANSPORTS') && (payload && payload.destination === destiny.iata_code)) {
            this.pax = this.pax + item.paxAdults;
            this.paxAdults = this.paxAdults + item.paxAdults;
            this.paxChildren = this.paxChildren + item.paxChildren;
          }
        }
      }
    }


  }

 /* textEmitter(text: any) {
    this.text = text;
    this._resultPaged = this.titleFilter.transform(this.auxiliarArray, this.text);
    // console.log('llego', this.text);
    // console.log(this._resultPaged);
  } */

  updateArray(array: any) {
    this.auxiliarArray = array;
    this._resultPaged = this.text ? this.titleFilter.transform(this.auxiliarArray, this.text) : this._resultPaged;
  }
  /**
  *
  * @param selected
  */

  _redirectTo(selected: string) {
    if (this.multiModal) {
      this.modalService._hideModal(1);
    }
    if (selected === 'SUMMARY') {
      this._router.navigate(['/summary']);
    } else {
      if (selected !== 'SUMMARY') {
        this._tabSelected = selected;
        this.setResultTab(selected);
      }
    }
  }

  _getCitys(param: any) {
  this.searchLocationssubscription = this._restService.doGet('api', '/searchlocations/' + param).subscribe(res => {
      return this.ori = res['data'][0]['cityName'];
    },
      Error => {
        console.log('Error', Error);
      });
  }

  _goTo(selected: string) {
    this.tabSelected = selected;
    this._payloadTransport = [];
  }

  loadPaylod(payload: any) {
    this.payloadForm = payload;
  }


  reloadSearch(payload: any, i: any) {

    this.addDestination(payload);
    this.payloadForm = payload;

    this.payloadCategory.payloadTransport = this._payloadTransport;
    document.getElementById('check-p' + i).style.display = 'none';
    document.getElementById('check-r' + i).style.display = 'block';
  }

  reloadIndex(index: any, i: any) {
    this.isCollapsed[i] = true;
    this.isCollapsed[i + 1] = false;
  }

  addDestination(newItem: any) {
    let _new = false;
    for (let i = 0; i < this._payloadTransport.length; i++) {
      if (this._payloadTransport[i]['origin'] === newItem['origin']) {
        this._payloadTransport[i] = newItem;
        _new = true;
      }
    }
    if (_new === false) {
      this._payloadTransport.push(newItem);
    }
  }

  _onLoading(isLoading: boolean) {
    this.isLoading = isLoading;

    if  ((this.isLoading)) {
        this.openModalLoading(JSON.parse(localStorage.getItem('payload')));
    } else {
        this.dialog.closeAll();
    }
  }

  /// modal loading
  openModalLoading (data: any) {
    var dialogRef = this.dialog.open(ModalLoadingComponent, {
      width: '500px',
      id: "loadModal",
      data: data,
      disableClose: true
    });

    /* dialogRef.afterClosed().subscribe(result => {
    });*/
  }
  /**
   *
   * @param categoryName
   */
  setResultTab(selected: string) {
    switch (selected) {
      case Procesed.TOURS:
        if (this.resultsMultidestinations.resultsTours !== undefined) {
          this._results = this.resultsMultidestinations.resultsTours;
        } else {
          const payload = this.payloadCategory.payloadTours !== undefined ? this.payloadCategory.payloadTours : this.loadPayloadTours(this._payload);
          this.loadResult(selected, payload);
        }
        break;
      case Procesed.HOTELS:
        if (this.resultsMultidestinations.resultsHotel !== undefined) {
          this._results = this.resultsMultidestinations.resultsHotel;
        } else {
          this.payloadCategory.payloadHotel = this._payload;
          this.loadResult(selected, this._payload);
        }
        break;
      case Procesed.TRANSPORTS:
        if (this.resultsMultidestinations.resultsTransport !== undefined) {
          this._results = this.resultsMultidestinations.resultsTransport;
        } else {
          this.verifiResult();
          this.payloadCategory.payloadTransport = this._payloadTransport;
          this.loadResult(selected, this._payloadTransport);
        }

        break;
    }
  }

  verifiResult() {
    const array = [];
    for (let i = 0; i < this._payload.length; i++) {
      let igual = false;
      for (let j = 0; j < this._payloadTransport.length && !igual; j++) {
        if (this._payload[i]['destination'] === this._payloadTransport[j]['origin']) {
          igual = true;
        }
      }
      if (!igual) {
        array.push(this._payload[i]);
      }
    }
    array.forEach(element => {
      this._loadPayloadTransport(this._payload, element.destination);
    });
  }

  initResultsMultidestination() {
    this.resultsMultidestinations = {
      resultsHotel: undefined,
      resultsTours: undefined,
      resultsTransport: undefined
    };
  }

  initPayloadCategoryMultidestination() {
    this.payloadCategory = {
      payloadHotel: undefined,
      payloadTours: undefined,
      payloadTransport: undefined
    };
  }

  /**
   * realiza la busqueda en el endpoint
  */
  async loadResult(category: string, payload: any[]) {

    this.isLoading = true;

    if  ((this.isLoading)) {
        this.openModalLoading(JSON.parse(localStorage.getItem('payload')));
    }

    if (payload[0].categoriesId[0] == '7') {  // temporal 12/10/2020

      this._results[0] = PACKAGES;
      this._results[0].qtyPax = payload[0].qtyPax;
      this._results[0].others = payload[0].others;
      this._results[0].destination = payload[0].destination;
      this._results[0].payload = payload[0];
      this._results[0].categoryName = category;

    } else { // fin temporal
      if (payload !== undefined && payload.length > 0) {
        this.resultsSearch._setPayload(payload);
        this._results = [];
        this._restService.doPost('app', 'search', payload[0]).subscribe(
            (response: any) => {
              if (response !== null && response.results !== null) {
                this._results[0] = response.results;
                this._results[0].qtyPax = payload[0].qtyPax;
                this._results[0].others = payload[0].others;
                this._results[0].destination = payload[0].destination;
                this._results[0].arrivalDate = payload[0].arrivalDate;
                this._results[0].payload = payload[0];
                this._results[0].categoryName = category;
                console.log(this._results)
                this.isLoading = false;
                this._setSearchIdInResults(response);
                this.dialog.closeAll();
                } else {
                  const initialState: ModalOptions = {
                    initialState: {
                      message:'No se encontraron Resultados'
                    }
                  };
                  this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
                }
            }, error => {
              const initialState: ModalOptions = {
                initialState: {
                  message: 'Ocurrió un error al intentar encontrar productos. Inténtalo de nuevo'
                }
              };

              this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
              const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
                if(result) {
                  if (result) {
                    this.loadResult(category, payload);
                  } else {
                    this.modalRef.hide();
                    this._router.navigate(["/home"]);
                }
                } else {

                }
                confirmSubscription.unsubscribe();
              });
            }
        );
      }
    }

  }

  _emitReSearch(event: Event) {
    this._payload = event;
    this.resultsSearch._setPayload(event);
    this.loadResult('HOTELS', this.resultsSearch._getPayload());
  }

  setObject(category: string) {
    switch (category) {
      case Procesed.TOURS:
        this.resultsMultidestinations.resultsTours = this._results;
        break;
      case Procesed.HOTELS:
        this.resultsMultidestinations.resultsHotel = this._results;
        break;
      case Procesed.TRANSPORTS:
        this.resultsMultidestinations.resultsTransport = this._results;
        break;
    }
  }

  /**
   *
   * @param pay
   */
  loadPayloadTours(pay: any): any[] {
    var payloadTours = [];
    if (pay !== undefined && pay.length > 0) {

      pay.forEach(element => {
        const pax = this.getPaxList(element);

        const payload = {
          providersId: element.providersId,
          categoriesId: [6],
          origin: element.origin,
          destination: element.destination,
          priceFrom: 0,
          priceTo: 20000,
          arrivalDate: element.arrivalDate,
          departureDate: element.departureDate,
          qtyProduct: 1,
          qtyPax: element.qtyPax,
          others: {
            maxRequestTimeOnSeconds: 20,
            maxNumResultsPerAdapter: "",
            tours: pax
          }
        };
        payloadTours.push(payload);
      });
      this.payloadCategory.payloadTours = payloadTours;
    }
    //console.log('pay tour', payloadTours);

    return payloadTours;
  }

  /**
   *
   * @param pay
   */

  _loadPayloadTransport(pay: any, des: any): any[] {
    if (pay !== undefined && pay.length > 0) {
      pay.forEach(element => {
        if (des === element.destination) {
          const pax = this.getPaxList(element);
          const payload = {
            providersId: null,
            categoriesId: [8],
            origin: element.origin,
            destination: element.destination,
            priceFrom: null,
            priceTo: null,
            arrivalDate: element.arrivalDate,
            departureDate: element.departureDate,
            qtyProduct: 1,
            qtyPax: element.qtyPax,
            others: {
              maxRequestTimeOnSeconds: 20,
              minNumResults: 3,
              maxNumResultsPerAdapter: "",
              transfers: {
                transferType: 'Regular / Premium',
                pickupTime: "01:00",
                dropoffTime: "01:00",
                pickupLocation: {
                  id: "MCO-",
                  type: "airport",
                  providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                  nameAdapter: "Hotel_Beds_Address"
                },
                dropoffLocation: {
                  id: "540005",
                  type: "hotel",
                  providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                  nameAdapter: "Hotel_Beds_Address"
                },
                paxList: pax,
                vehiclesDist: [
                  pax
                ],
                oneWay: false
              }
            }
          };
          this._payloadTransport.push(payload);
        }
      });
    }
    return this._payloadTransport;
  }


  loadPayloadTransport(pay: any): any[] {
    var payloadTransport = [];
    if (pay !== undefined && pay.length > 0) {
      pay.forEach(element => {
        const pax = this.getPaxList(element);
        const payload = {
          providersId: null,
          categoriesId: [8],
          origin: element.origin,
          destination: element.destination,
          priceFrom: null,
          priceTo: null,
          arrivalDate: element.arrivalDate,
          departureDate: element.departureDate,
          qtyProduct: 1,
          qtyPax: element.qtyPax,
          others: {
            maxRequestTimeOnSeconds: 20,
            minNumResults: 3,
            maxNumResultsPerAdapter: "",
            transfers: {
              transferType: 'Regular / Premium',
              pickupTime: "01:00",
              dropoffTime: "01:00",
              pickupLocation: {
                id: "MCO",
                type: "airport",
                providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                nameAdapter: "Hotel_Beds_Address"
              },
              dropoffLocation: {
                id: "540005",
                type: "hotel",
                providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                nameAdapter: "Hotel_Beds_Address"
              },
              paxList: pax,
              vehiclesDist: [
                pax
              ],
              oneWay: false
            }
          }
        };
        payloadTransport.push(payload);
      });
      this.payloadCategory.payloadTransport = payloadTransport;
    }

    return payloadTransport;
  }

  /**
   *
   * @param element
   */
  getPaxList(element: any): any {

    var children = [];
    var adults = 0;
    if (element.others !== undefined && element.others !== null) {
      element.others.hotels.roomConfiguration.forEach(room => {
        adults += parseInt(room.adults);
        children = room.children;
      })
    }
    return { adults, children };
  }

  /**
   *
   * @param categoryName
   * @param index
   */
  getPayloadCategory(categoryName: string, index: number) {
    let payload;
    if (this._origin === Procesed.MULTIDESTINATION) {
      switch (categoryName) {
        case Procesed.TOURS:
          payload = this.payloadCategory.payloadTours !== undefined ? this.payloadCategory.payloadTours[index] : this.loadPayloadTours(this._payload)[index];
          break;
        case Procesed.TRANSPORTS:
          payload = this.payloadCategory.payloadTransport !== undefined ? this.payloadCategory.payloadTransport[index] : this.loadPayloadTransport(this._payload)[index];
          break;
        case Procesed.HOTELS:
          payload = this.payloadCategory.payloadHotel !== undefined ? this.payloadCategory.payloadHotel[index] : this._payload[index];
          break;
        default:
          payload = this._payload[index];
      }
    } else {
      payload = this._payload[index];
    }

    /// console.log('envio payload a category ',payload);
    return payload;
  }

  loading() {
    this.serviceItinerary.getLoadingObject().subscribe((response:any) => {
      //this.isLoading = Boolean(response);
    });
  }

  _setSearchIdInResults(response: any) {
   [ response.results].forEach(obj => {
      obj.searchId = response.searchId;
    });
  }

  openModalWithClass(template: TemplateRef<any>) {
    this.multiModal = true;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' })
    );
  }


  changeTab(event) {


    switch (this._tabSelected) {
      case 'HOTELS':
        this._redirectTo('TRANSPORTS');
        break;

      case 'TRANSPORTS':
        this._redirectTo('TOURS');
        break;

      case 'SUMMARY':
        this._redirectTo('SUMMARY');
        break;

      default:
        break;
    }

  }

  _onMultimodal(event){

  }



}
