<!-- primer día  -->
<div class="width-100" *ngIf="isCheckIn && dato" >
    <div class="row mt-2">
        <div class="col-2 p-0 m-0 text-center">
            <span class="date-itinerary-s">{{formatDate(dato.arrivalDate) | uppercase}}</span>
        </div>
      <div class="col-auto mr-4">
        <span class="circle">
            <i class="icon-small icon-pthemes iconstart"></i>
        </span>
      </div>
      <div class="col-xg-9 col-lg-9 flex-column px-0 mx-0 d-flex space-items">
      <div class="d-flex ">
        <div class="col-xg-10 col-lg-10 px-0 m-0 flex-column font-descrip">
            <span class="title-destiny-start d-flex align-items-center">{{dato.title}} <br>Park to Park</span>  
            <span class="p-0 spaced">
              <span>{{dato.name}}</span>
            </span>
        </div>
        <div class="col-2 d-inline m-0 p-0 text-right">
          <div *ngIf="partialReserve===true" class="pretty p-round p-thick p-bigger p-icon p-smooth">
            <div ng-if="media.Id == mediaFromLead ">
              <input type="checkbox" (change)="toPartialReserve($event, dato)" ng-checked="dato.status_id==3" />
              <div class="state">
                  <i class="icon fa fa-check"></i>
                  <label></label>
              </div>
            </div>
          </div>
          <span>
            <i class="icon-pencil"></i>
          </span>
          <span>
              <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(dato)">
                  <i class="icon-papelera"></i>
              </button>
          </span>
  
        </div>
      </div>
      <div class="d-flex font-inline flex-row">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class=" spaced font-start d-flex pr-5 mr-5"></span>
          <span classs="align-items-center d-flex ml-5 pl-5 pr-0">Tarifa Neta: {{dato.paxAdults}} Adults - {{dato.paxChild}} Children</span>
          <span class="title-destiny-start pt-auto pr-4" [ngStyle]="{'font-size': fontPrice(dato.optionTotal)}">$ {{dato.optionTotal | number:'1.2-2'}}</span>
        </div>
      </div>
    </div>
  
    </div>
  </div>

<!-- día intermedio  -->
<!-- <div class="width-100">
    <div class="row mt-2">
        <div class="col-2 p-0 m-0 text-center">
            <span class="date-itinerary-s">{{formatDate(dato.arrivalDate) | uppercase}}</span>
        </div>
      <div class="col-auto mr-4">
        <span class="circle">
            <i class="icon-small icon-pthemes iconregular"></i>
        </span>
      </div>
      <div class="col-xg-9 col-lg-9 flex-column px-0 mx-0 d-flex space-items">
      <div class="d-flex ">
        <div class="col-xg-11 col-lg-11 px-0 m-0 flex-column font-descrip">
            <span class="title-destiny-start d-flex align-items-center">{{dato.title}}  <br>Park to Park</span>  
            <span class="p-0 spaced">
              <span>{{dato.name}}</span>
              <span>| {{dato.paxAdults}} Adults - {{dato.paxChild}} Children</span>
            </span>
        </div>
        <div class="col-1 d-inline m-0 p-0">
          <span>
            <i class="icon-pencil"></i>
          </span>
          <span>
              <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(dato)">
                  <i class="icon-papelera"></i>
              </button>
          </span>
  
        </div>
      </div>
      <div class="d-flex font-inline flex-row">
        <div class="w-100 justify-content-between d-flex flex-row">
          <span class=" spaced font-start d-flex pr-5 mr-5"></span>
        </div>
      </div>
    </div>
  
    </div>
  </div> -->

<!-- último día  -->
      <div class="width-100" *ngIf="!isCheckIn && dato">
        <div class="row mt-2">
            <div class="col-2 p-0 m-0 text-center">
                <span class="date-itinerary-s">{{formatDate(dato.departureDate) | uppercase}}</span>
            </div>
          <!-- <div class="col-auto mx-0 px-0 space-primer-nivel"></div> -->
          <div class="col-auto mr-4">
            <span class="circle">
                <i class="icon-small icon-pthemes iconend"></i>
            </span>
          </div>
          <div class="col-xg-9 col-lg-9 flex-column px-0 mx-0 d-flex space-items">
          <div class="d-flex ">
            <div class="col-xg-11 col-lg-11 px-0 m-0 flex-column font-descrip">
                <span class="title-destiny-start d-flex align-items-center">{{dato.title}}  <br>Park to Park</span>  
                <span class="p-0 spaced">
                  <span>{{dato.name}}</span>
                  <span>|  {{dato.paxAdults}} Adults - {{dato.paxChild}} Children</span>
                </span>
            </div>
            <!-- <div class="col-1 d-inline m-0 p-0">
              <span>
                <i class="icon-pencil"></i>
              </span>
              <span>
                  <button type="button" class="btn btn-sm py-0 mb-1" (click)="removeItem(dato)">
                      <i class="icon-papelera"></i>
                  </button>
              </span>
      
            </div> -->
          </div>
          <div class="d-flex font-inline flex-row">
            <div class="w-100 justify-content-between d-flex flex-row">
              <span class=" spaced font-start d-flex pr-5 mr-5"></span>
            </div>
          </div>
        </div>
      
        </div>
      </div>