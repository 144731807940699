import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usb-item-tours-detail-summary',
  templateUrl: './item-tours-detail-summary.component.html',
  styleUrls: ['./item-tours-detail-summary.component.scss']
})
export class ItemToursDetailSummaryComponent implements OnInit {
  @Input() item: any;
  @Input() i: any;
  
  constructor() { }

  ngOnInit() {
  }

}
