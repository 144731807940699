import {Injectable} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setting } from '../setting';

@Injectable()
export class SeoService {


public faviconUrl = '';
public routeUploadFile:any;

constructor(private meta: Meta) {
  // Se obtiene el título y el favicon de la base de datos (LocalStorage) y se guarda en las propiedades de la clase
  const clientsSettings = JSON.parse(localStorage.getItem(setting.name));
  console.log(clientsSettings)
  this.routeUploadFile =  setting.routeUploadFile;
  this.faviconUrl = this.routeUploadFile+clientsSettings?.clients_setting_global?.favicon;
}

setSeoData(description: string) {
  this.meta.updateTag({ name: 'description', content: description });
  this.meta.updateTag({ rel: 'icon', href: this.faviconUrl });
}

getFaviconUrl(): string {
  return this.faviconUrl;
}
}
