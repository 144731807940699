<div *ngIf="(insurance['additionalInfo'] !== undefined)" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div id="modalinsurance">
    <div class="modal-content p-4">
      <div class="flex-row px-1 py-0 d-flex ">
        <div class="col-4">
          <img class="img-insurance" src="assets/img/app/logo-{{insurance.nameAdapter}}.png">
        </div>
        <div class="col-8 d-flex flex-column capitalize">
          <div class="mb-1">
              <span class="titulo">Origen:</span>
              <span>{{Origen}}</span>
              <span class="titulo">Destino:</span>
              <span>Destino: {{Destino}}</span>
          </div>
          <div class="texSub mb-1" id="pasajeros">
              <span>{{Adultts}}</span>
              <i class="icon icon-multiple-users-silhouette my-2"></i>
              <span class="number">{{Childs}}</span>
              <i class="icon icon-menor my-1"></i>
              <span class="titulo">In:</span>
              <span>{{getDateIni()}}</span>
              <span class="titulo">Out:</span>
              <span>{{getDateEnd()}}</span>
          </div>
        </div>
      </div>
      <div class="modal-body py-2 pl-10">
        <div id="tab-insurance" *ngIf="(insurance.additionalInfo !== undefined)">
          <!-- <tabset class="tabcontainer"> -->
            <!--***********************************-->
            <!--***********TABS FOR INSURANCE**********-->
            <!--***********************************-->
            <!-- <tab heading="INSURANCE" id="tab-insurance" *ngIf="(insurance.additionalInfo !== undefined)"> -->
              <div id="modal-transport"class="card card-info d-flex flex-row my-3 ">
                <div class="flex-column p-2 d-flex">
                  <span class="flex-column">
                    <strong>Benefits</strong>
                  </span>
                  <ul class="list-benefits">
                    <li *ngFor="let benefit of insurance.additionalInfo.benefits">
                      {{benefit.description}} -
                      <strong>{{benefit.detail}}</strong>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div class="card card-total-price mb-5 text-right d-flex "> -->
              <div class="d-flex justify-content-end card-total-price">
                <span>Total to</span>
                <span class="number">{{Adultts}}</span>
                <i class="icon icon-multiple-users-silhouette my-2"></i>
                <span class="number">{{Childs}}</span>
                <i class="icon icon-menor my-1"></i>
                <div *ngIf="!(insurance.additionalInfo.totalPriceProf ? true : false)">
                  <a class="loader text-center justify-content-center ">
                      <i class="fa fa-spinner fa-spin"></i>
                  </a>
                </div>
                <span *ngIf="(insurance.additionalInfo.totalPriceProf ? true : false)"
                      class="left-right-space">${{insurance.additionalInfo.totalPriceProf | number:'1.2-2'}}</span>
                <button type="button" (click)="continue()" class="btn btn-sm btn-usblick">
                    <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i>
                  Continue
                </button>
              </div>
            <!-- </tab>
          </tabset> -->
        </div>
      </div>
    </div>
  </div>
</div>
