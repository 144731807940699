<div *ngIf="invoke === 0 && origin !== 'RENT-A-CAR'" class="d-flex w-75 justify-content-center">
  <div class="p-3 mr-2">
    <i class="icon icon-multiple-users-silhouette" aria-hidden="true"></i>
    <span class="font-dark">{{ paxList.adults + (paxList.adults > 1 ? " Adults" : " Adult") }}</span>
  </div>
  <div class="p-3 mr-2">
    <i class="icon icon-menor" aria-hidden="true"></i>
    <span class="font-dark">{{ paxList.children === undefined ? 0 + " Children" : paxList.children + "Children" }}</span>
  </div>

  <div *ngIf="origin === 'MULTIDESTINATION'" class="pt-2 m-0 align-items-center">
    <button type="button" class="btn btn-sm btn-dark" data-placement="bottom" (click)="openModalWithClass(templateRooms)" title="Edit">
      <i class="icon-edit icon icon-pencil" aria-hidden="true"></i>
    </button>
  </div>
</div>

<div *ngIf="invoke === 1 && origin !== 'RENT-A-CAR'" class="d-flex align-items-center justify-content-center">
  <span class="font-opensans font-detalles mr-2" *ngIf="resultService._payload">
    <span class="f-strong">In:</span> {{ resultService._payload[0]?.arrivalDate | date : "dd MMM yy" }} &nbsp;&nbsp;
    <span class="f-strong">Out:</span> {{ resultService._payload[0]?.departureDate | date : "dd MMM yy" }} &nbsp;&nbsp;
  </span>
</div>

<div *ngIf="invoke === 1 && origin == 'RENT-A-CAR'" class="d-flex align-items-center justify-content-center">
  <span class="font-opensans font-detalles mr-2" *ngIf="resultService._payload">
    <span class="f-strong">In: </span> {{ resultService._payload[0]?.others.carRental.pickupLocation.pickupDate | date : "dd MMM yy" }}
    &nbsp;&nbsp;
    <span class="f-strong">Out:</span> {{ resultService._payload[0]?.others.carRental.dropoffLocation.dropoffDate | date : "dd MMM yy" }}
    &nbsp;&nbsp;
  </span>
</div>

<div *ngIf="invoke === 1" class="p-2">In: {{ paxList.datestart !== "To be defined" ? (paxList.datestart | date) : paxList.datestart }}</div>

<div *ngIf="invoke === 1" class="p-2">Out: {{ paxList.dateend !== "To be defined" ? (paxList.dateend | date) : paxList.dateend }}</div>

<div *ngIf="invoke === 1" class="p-2 font-green">Stay: {{ paxList.stay }}</div>

<div *ngIf="invoke === 1 && origin == 'HOTELS'" class="p-2">
  <i class="icon icon-multiple-users-silhouette" aria-hidden="true"></i>
  <span class="font-dark">{{ paxList.adults + (paxList.adults > 1 ? " Adults" : " Adult") }}</span>
</div>

<div *ngIf="invoke === 1 && origin == 'HOTELS'" class="p-2 mr-2">
  <i class="icon icon-menor" aria-hidden="true"></i>
  <span class="font-dark">{{ paxList.children + " Children" }}</span>
</div>

<div *ngIf="invoke === 1 && origin == 'HOTELS'" class="pr-2 m-0 p-0 div-fly align-items-center">
  <button
    type="button"
    class="btn btn-sm btn-dark my-1"
    data-placement="bottom"
    title="Editar habitaciones y pasajeros"
    (click)="openModalWithClass(templateRooms)"
    [disabled]="actualItinerary === null"
  >
    <i class="icon-edit icon icon-pencil" aria-hidden="true"></i>
  </button>
</div>

<div *ngIf="invoke === 3" class="d-flex align-items-center justify-content-between">
  <div *ngIf="activeBed" class="mr-2 d-flex align-items-center">
    <i class="i-bed-room mr-1" aria-hidden="true"></i>
    <span *ngIf="loading && chargeOnInit" class="font-descrip">{{qtyProducts}} Habitación</span>
    <span *ngIf="!editProduct && !loading && show" class="font-descrip">{{ getForm?.room.length }} Habitación</span>
  </div>

  <i *ngIf="origin != 'RENT-A-CAR'" class="i-adults mr-1 ml-3" aria-hidden="true"></i>
  <span *ngIf="loading && chargeOnInit && origin != 'RENT-A-CAR'" class="font-descrip mr-2">{{ paxList.adults >= 1 ? paxList.adults + (paxList.adults > 1 ? " Adultos" : " Adulto") : payload.others.hotels.roomConfiguration[0].adults + (payload.others.hotels.roomConfiguration[0].adults > 1 ? " Adultos" : " Adulto") }}</span>
  <div *ngIf="show">{{qtyAdults}} Adultos</div>
  <i *ngIf="origin != 'RENT-A-CAR'" class="i-child mr-1 ml-3" aria-hidden="true"></i>
  <span *ngIf="loading && chargeOnInit && origin != 'RENT-A-CAR'" class="font-descrip mr-4">{{ paxList.children != null ? (paxList.children + ' Niños') : (payload.others.hotels.roomConfiguration[0].children.length + ' Niños')}}</span>
  <div *ngIf="show">{{qtyChilds}} Niños</div>
  <hr class="vertical-hr" />
  <button class="btn btn-actions-room mr-2 d-flex align-items-center justify-content-center p-0" (click)="_emitReload()">
    <i class="i-refresh"></i>
  </button>
  <span>
    <button
      type="button"
      class="btn btn-actions-room d-flex align-items-center justify-content-center p-0"
      data-placement="bottom"
      title="Edit Rooms and Passengers"
      (click)="openModalWithClass(templateRooms)"
      [disabled]="actualItinerary === null"
      *ngIf="this._form"
    >
      <i class="i-edit-room" aria-hidden="true"></i>
    </button>
  </span>
</div>

<ng-template #templateRooms>
  <div class="modal-body" id="modal-edit-rooms" style="padding: 0px; padding-bottom: 2.5em">
    <!-- <button type="button" class="close btn-closed" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true" style="font-size: 50px; font-weight: 100">&times;</span>
    </button> -->
    <div class="" role="document">
      <div class="px-5 pt-4 pb-2" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
        <h5
            *ngIf="!this.activeBed"
            class="modal-title font-poppins text-left"
            [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
        >Edit Passengers</h5>
        <h5
            *ngIf="this.activeBed"
            class="modal-title font-poppins text-left"
            [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
        >Editar habitaciones y pasajeros</h5>
        <ul class="d-flex gap-2 mt-2">
          <ng-container *ngIf="this.activeBed">
              <button class="btn-circle-items" (click)="actionEdit = 1"><i class="i-room"></i></button>
              <button class="btn-circle-items ml-2" (click)="actionEdit = 2"><i class="i-passenger"></i></button>
          </ng-container>
          <ng-container *ngIf="!this.activeBed">
              <button class="btn-circle-items"><i class="i-passenger"></i></button>
          </ng-container>
        </ul>
      </div>
      <div class="mb-5 px-5">
        <div *ngIf="!this.activeBed">
          <form [formGroup]="_form" (ngSubmit)="validateOverlapMulti(templateMulti)">
            <div class="d-flex justify-content-between mt-3 mb-3">
              <div class="d-flex flex-column w-50 mr-3">
                <label for="arrivalDate" class="labels font-poppins">Check-in </label>
                <div class="input-content">
                  <input
                    formControlName="arrivalDate"
                    type="text"
                    class="date-itinerary"
                    id="arrivalDate"
                    placeholder="Arrival Date"
                    autocomplete="off"
                    #dpArrival="bsDatepicker"
                    (bsValueChange)="setDepartureDate($event)"
                    bsDatepicker
                    [minDate]="minDate"
                    [maxDate]="maxDateArrival"
                    [bsConfig]="dpConfig"
                    readonly
                  />
                  <i></i>
                </div>
                <span
                  class="help-block text-danger"
                  *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('required')"
                  >This field is required.</span
                >
                <small class="error-label" *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('invalidDate')">
                  * Check-in must be a date before Check-out!
                </small>
              </div>
              <div class="d-flex flex-column w-50">
                <label for="departureDate" class="labels font-poppins">Check-out</label>
                <div class="input-content">
                  <input
                    formControlName="departureDate"
                    type="text"
                    class="form-control date-itinerary"
                    id="departureDate"
                    placeholder="Deaperture Date"
                    autocomplete="off"
                    bsDatepicker
                    [minDate]="minDateDeparture"
                    [bsConfig]="dpConfig"
                    #dpDeparture="bsDatepicker"
                    readonly
                    (bsValueChange)="setArrivalDate($event)"
                  />
                  <i></i>
                </div>
              </div>
            </div>

            <div *ngIf="origin == 'HOTELS'" class="d-flex flex-row align-items-start justify-content-start mt-3">
              <div class="d-flex w-50 mr-3">
                <div class="d-flex flex-column mr-3 w-50">
                  <label for="adults" class="labels font-poppins">Adults</label>
                  <div class="select-content">
                    <select
                      class="select-pax-nro"
                      formControlName="adults"
                      id="adult"
                      name="adults"
                      [value]="1"
                      (change)="changePaxAdultTours()"
                    >
                      <option *ngFor="let qty of _maxAdults" [value]="qty">{{ qty }}</option>
                    </select>
                    <i></i>
                  </div>
                </div>
                <div class="d-flex flex-column w-50">
                  <label for="children" class="labels font-poppins">Children</label>
                  <div class="select-content">
                    <select
                      class="select-pax-nro"
                      id="children"
                      name="childs"
                      [value]="0"
                      formControlName="childrens"
                      (change)="changePaxChildrenTours(room.controls[0])"
                    >
                      <option *ngFor="let qty of _maxChildren" [value]="qty">{{ qty }}</option>
                    </select>
                    <i></i>
                  </div>
                </div>
              </div>

              <div class="d-flex w-50 justify-content-between" *ngIf="room">
                <ng-container formArrayName="room">
                  <div class="d-flex" *ngFor="let room of room.controls; let i = index">
                    <div [formGroupName]="i" class="w-100">
                      <div id="childrenAges" class="d-flex flex-column" *ngIf="room.value.childsage.length > 0">
                        <label for="age" class="labels font-poppins">Edad de niños</label>
                        <div class="d-flex p-0 m-0">
                          <div
                            formArrayName="childsage"
                            *ngFor="let age of room.controls.childsage.controls; let j = index; let last = last"
                            class="w-25 mr-2"
                          >
                            <div [formGroupName]="j" class="p-0">
                              <input
                                formControlName="age"
                                min="0"
                                max="17"
                                limit-to="14"
                                class="form-control input-age"
                                value-formatter="0"
                                placeholder="0"
                                name="age"
                              />
                            </div>
                          </div>
                        </div>

                        <div *ngFor="let age of room.controls.childsage.controls; let j = index" class="d-flex flex-column error-message">
                          <span
                            class="help-block text-danger font-poppins d-flex"
                            *ngIf="
                              age.controls['age'].hasError('invalidsoloNumeros') ||
                              age.controls['age'].hasError('min') ||
                              age.controls['age'].hasError('max') ||
                              age.controls['age'].hasError('required')
                            "
                          >
                            <br />
                            La edad de los niños {{j + 1}} es incorrecta.
                            <!-- The age of children {{ j + 1 }} is incorrect -->
                            </span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>

        <div *ngIf="this.activeBed">
          <form [formGroup]="_form" (ngSubmit)="validateOverlapMulti(templateMulti)">
            <!-- <div [ngClass]="{'d-block' : actionEdit===1, 'd-none' : actionEdit!=1}"> -->
            <div class="pb-3">
              <div class="d-flex flex-row align-items-center justify-content-between mt-3">
                <div class="col-6 p-0 pt-2 pr-2 pb-2 d-flex flex-column position-relative">
                  <label for="arrivalDate" class="labels font-poppins pl-3">Check-in</label>
                  <span class="arrow-sort fa fa-sort position-absolute"
                        style="top: 57%; right: 1em"
                        [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
                  </span>
                  <input
                    type="text"
                    formControlName="arrivalDate"
                    class="form-control input-date font-poppins"
                    id="arrivalDate"
                    autocomplete="off"
                    #startDateDp="bsDatepicker"
                    [bsConfig]="dpConfig"
                    bsDatepicker
                    [minDate]="minDate"
                    [bsConfig]="dpConfig"
                    (bsValueChange)="setDepartureDate($event)"
                    readonly
                  />

                  <small class="error-label" *ngIf="_form.get('arrivalDate').touched && _form.get('arrivalDate').hasError('invalidDate')">
                    * Check-in must be a date before Check-out!
                  </small>
                </div>

                <div class="col-6 p-0 pt-2 pl-2 pb-2 d-flex flex-column position-relative">
                  <label for="departureDate" class="labels font-poppins pl-3">Check-out</label>
                  <span class="arrow-sort fa fa-sort position-absolute"
                        style="top: 57%; right: 1em"
                        [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
                  </span>
                  <input
                    type="text"
                    formControlName="departureDate"
                    class="form-control input-date font-poppins"
                    id="departureDate"
                    autocomplete="off"
                    #endDateDp="bsDatepicker"
                    [bsConfig]="dpConfig"
                    bsDatepicker
                    [minDate]="minDateDeparture"
                    [bsConfig]="dpConfig"
                    (bsValueChange)="setArrivalDate($event)"
                    readonly
                  />
                  <small
                    class="error-label"
                    *ngIf="_form.get('departureDate').touched && _form.get('departureDate').hasError('invalidDate')"
                  >
                    * Check-out must be a date after Check-in!
                  </small>
                </div>

              </div>
              <div class="d-flex flex-row align-items-center justify-content-between mt-3">
                <div class="d-flex flex-column w-100 position-relative">
                  <label for="nationality" class="labels font-poppins pl-3">Nacionalidad</label>
                  <span class="arrow-sort fa fa-sort position-absolute"
                                 style="top: 57%; right: 1em"
                                [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
                          </span>
                          <input
                                  id="nationality"
                                  name="nationality"
                                  class="form-control input-date font-poppins"
                                  type="text"
                                  [(ngModel)]="selectedIsNationality"
                                  formControlName="nationality"
                                  aria-label="nationality"
                                  typeaheadOptionField="country_name"
                                  [typeahead]="filteredCountries"
                                  autocomplete="off"
                          >
                </div>
              </div>
            </div>
            <!-- <div [ngClass]="{'d-block' : actionEdit===2, 'd-none' : actionEdit!=2}"> -->
            <div>
              <div
                class="d-flex justify-content-end mt-4"
                *ngIf="_form.value['room'].length < 7 && !payload[0]['others']['transfers'] && !payload[0]['others']['tours']"
              >
                <button
                    type="button"
                    class="btn btn-add-green d-flex justify-content-between align-items-center"
                    [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"
                    (click)="_addRoom()">
                  <i class="icon-add-white mr-3"></i>
                  Agregar habitación
                </button>
              </div>
              <!-- room.controls -->
              <div formArrayName="room" *ngFor="let room of room.controls; let i = index">
                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="title-room font-poppins">Habitación {{ i + 1 }}</h4>
                </div>
                <div [formGroupName]="i" class="d-flex flex-row align-items-center justify-content-center">
                  <div class="d-flex flex-row align-items-start justify-content-start w-100">
                    <div class="d-flex w-50 mr-3">
                      <div class="d-flex flex-column mr-3 w-50">
                        <label for="adults" class="labels font-poppins">Adultos</label>
                        <div class="select-content position-relative">
                          <span class="arrow-sort fa fa-sort position-absolute"
                                 style="top: 37%; right: 1em"
                                [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
                          </span>
                          <select
                                  id="adult"
                                  name="adult"
                                  class="form-control input-date font-poppins"
                                  style="height: 45px;"
                                  formControlName="qtyAdults"
                                  [value]="room.value.qtyAdults"
                                  aria-label="qtyAdults"
                                  (ngModelChange)="_adultModify()"
                          >
                            <option *ngFor="let qty of _maxAdults" [value]="qty">{{ qty }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="d-flex flex-column w-50">
                        <label for="childs" class="labels font-poppins">Niños</label>
                        <div class="select-content position-relative">
                          <span class="arrow-sort fa fa-sort position-absolute"
                                 style="top: 37%; right: 1em"
                                [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
                          </span>
                          <select
                                  id="child"
                                  name="child"
                                  class="form-control input-date font-poppins"
                                  style="height: 45px;"
                                  formControlName="qtyChilds"
                                  [value]="room.value.qtyChilds"
                                  aria-label="qtyChilds"
                                  (ngModelChange)="_addChild(room)"
                          >
                            <option *ngFor="let qty of _maxChildren" [value]="qty">{{ qty }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex w-50 justify-content-between">
                      <div class="d-flex" [ngStyle]="{ width: _form.value.room.length > 1 ? 'calc(100% - 53px)' : '100%' }">
                        <div class="w-100">
                          <div id="childrenAges" class="d-flex flex-column" *ngIf="room.value.childsage.length > 0">
                            <label for="age" class="labels font-poppins">Edad de niños</label>
                            <div class="d-flex p-0 m-0">
                              <div
                                formArrayName="childsage"
                                *ngFor="let age of room.controls.childsage.controls; let j = index"
                                class="w-25 mr-2"
                              >
                                <div [formGroupName]="j" class="p-0">
                                  <input
                                    formControlName="age"
                                    min="0"
                                    max="17"
                                    limit-to="14"
                                    type="number"
                                    class="form-control input-age"
                                    placeholder="0"
                                    name="age"
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              *ngFor="let age of room.controls.childsage.controls; let j = index"
                              class="d-flex flex-column error-message"
                            >
                              <span
                                class="help-block text-danger font-poppins d-flex"
                                *ngIf="
                                  age.controls['age'].hasError('invalidsoloNumeros') ||
                                  age.controls['age'].hasError('min') ||
                                  age.controls['age'].hasError('max') ||
                                  age.controls['age'].hasError('required')
                                "
                              >
                                <br />
                                La edad de los niños {{j + 1}} es incorrecta.

                                The age of children {{ j + 1 }} is incorrect
                              </span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-end" *ngIf="_form.value['room'].length > 1">
                        <button
                          (click)="_removeRoom(i)"
                          type="button"
                          class="btn btn-white d-flex justify-content-center align-items-center"
                          style="margin-top: 27px"
                        >
                          <span class="circle-dark d-flex justify-content-center align-items-center">
                            <i class="icons-delete"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="divider-room" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center p-2 pr-5" id="apply-btn-l">
        <button
          class="btn btn-cancelar d-flex align-items-center justify-content-center text-uppercase mr-2"
          [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}"
          aria-controls="false"
          (click)="cancel()">
          Cancelar
        </button>
        <button
          class="btn btn-buscar d-flex align-items-center justify-content-center text-uppercase"
          [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
          (click)="validateOverlapMulti(templateMulti)"
          [disabled]="!_form.valid">
          Buscar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <form method="POST">
    <div class="modal-body text-center" id="confirm">
      <p>"The dates overlap. Are you sure to continue?"</p>
      <button type="button" data-placement="bottom" class="btn btn-sm btn-usblick" title="Yes" (click)="_emitReload()">Yes</button>
      <button type="button" class="btn btn-outline-danger btn-usblick btn-sm" (click)="closeModal()">No</button>
    </div>
  </form>
</ng-template>

<ng-template #templateMulti>
  <form method="POST">
    <div class="modal-body text-center" id="confirm">
      <p>"The dates overlap. Do you wish modify all Dates?"</p>
      <button type="button" data-placement="bottom" class="btn btn-sm btn-usblick" title="Yes" (click)="_emitReSearch()">Yes</button>
      <button type="button" class="btn btn-outline-danger btn-usblick btn-sm" (click)="_roomsApply()">No</button>
    </div>
  </form>
</ng-template>


