<div *ngIf="partialReserve===true">
  <div *ngFor="let item of itineraryItems; let i = index">
      <div *ngIf="i==0">
        
        <!-- <div class="text-center" id="service-title">Services Partial Reserved</div> -->

        <h4 class="text-uppercase" style="color: #00d397;">
          PRODUCT PARTIAL RESERVED LIST
        </h4>

        <!-- <div class="row justify-content-between">
          <div class="col-5">
            
          </div>
          <div class="col-4 text-center" >
            <span id="expiration-title">Expiration or expiration date</span>
          </div>
        </div> -->
      </div>
      
      <usb-item-hotel-detail-summary *ngIf="item.categoryName == 'HOTELS' && item.partial_selected==1" [item]="item" [i]="i"></usb-item-hotel-detail-summary>

      <usb-item-ptheme-detail-summary *ngIf="item.categoryName == 'PTHEMES' && item.partial_selected==1" [item]="item"></usb-item-ptheme-detail-summary>

      <usb-item-disney-detail-summary *ngIf="item.categoryName == 'DISNEY' && item.partial_selected==1" [item]="item" ></usb-item-disney-detail-summary>

      <usb-item-transport-detail-summary *ngIf="item.categoryName == 'TRANSPORTS' && item.partial_selected==1" [item]="item" ></usb-item-transport-detail-summary>

      <usb-item-insurance-detail-summary *ngIf="item.categoryName == 'INSURANCE' && item.partial_selected==1" [item]="item" ></usb-item-insurance-detail-summary>

      <usb-item-tours-detail-summary *ngIf="item.categoryName == 'TOURS' && item.partial_selected==1" [item]="item" [i]="i"></usb-item-tours-detail-summary>

      <usb-item-rentacar-detail-summary *ngIf="item.categoryName == 'RENT-A-CAR' && item.partial_selected==1" [item]="item" ></usb-item-rentacar-detail-summary>
  </div>

  <div class="" *ngFor="let item of itineraryItems; let i = index">
      <div class="mt-4 pt-4" *ngIf="i==0">
        
        <!-- <div class="text-center" id="service-title">Services Save Draft</div> -->
        <h4 class="text-uppercase" style="color: #00d397;">
          PRODUCT SAVED LIST
        </h4>
        <!-- <div class="row justify-content-between">
          <div class="col-5">
            
          </div>
          <div class="col-4 text-center" >
            <span id="expiration-title">Expiration or expiration date</span>
          </div>
        </div> -->
      </div>
      
      <usb-item-hotel-detail-summary *ngIf="item.categoryName == 'HOTELS' && item.partial_selected!=1 && item.status_id == 1" [item]="item"></usb-item-hotel-detail-summary>

      <usb-item-ptheme-detail-summary *ngIf="item.categoryName == 'PTHEMES' && item.partial_selected!=1 && item.status_id == 1" [item]="item"></usb-item-ptheme-detail-summary>

      <usb-item-disney-detail-summary *ngIf="item.categoryName == 'DISNEY' && item.partial_selected!=1 && item.status_id == 1" [item]="item"></usb-item-disney-detail-summary>

      <usb-item-transport-detail-summary *ngIf="item.categoryName == 'TRANSPORTS' && item.partial_selected!=1 && item.status_id == 1" [item]="item"></usb-item-transport-detail-summary>

      <usb-item-insurance-detail-summary *ngIf="item.categoryName == 'INSURANCE' && item.partial_selected!=1&& item.status_id == 1 " [item]="item"></usb-item-insurance-detail-summary>

      <usb-item-tours-detail-summary *ngIf="item.categoryName == 'TOURS' && item.partial_selected!=1 && item.status_id == 1" [item]="item"></usb-item-tours-detail-summary>

      <usb-item-rentacar-detail-summary *ngIf="item.categoryName == 'RENT-A-CAR' && item.partial_selected!=1 && item.status_id == 1" [item]="item"></usb-item-rentacar-detail-summary>
  </div>
</div>



