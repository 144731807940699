<ng-container *ngIf="items">

  <div class="d-flex flex-row float-right pt-3 pb-3">
    <button class="btn btn-sm text-white"
            style="margin-right: 1.5em;"
            type="button"
            (click)="selectAll()"
            *ngIf="!selectAllMode"
            [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">Seleccionar Todo</button>
    <button class="btn btn-sm text-white"
            style="margin-right: 1.5em;"
            type="button"
            (click)="deSelectAll()"
            *ngIf="selectAllMode"
            [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">Deseleccionar todo</button>
  </div>

  <div class="d-flex flex-row card mt-2 justify-content-between" *ngFor="let item of items, let i = index"
    [ngClass]="{'border-green': item.selected}" style="padding-bottom: .5em; padding-top: .5em;">
    <div class="d-flex data-item">
      <div class="d-flex content-icon justify-content-center">
        <div class="d-flex circle align-items-center justify-content-center"
          [ngClass]="{'green-background': item.status_id != 5, 'red-background': item.status_id == 5}">
          <i class="icons-hotel" *ngIf="item.categoryName=='HOTELS'"></i>
          <i class="icons-adventure" *ngIf="item.categoryName=='TOURS'"></i>
          <i class="icons-transporte" *ngIf="item.categoryName=='TRANSPORTS'"></i>
          <i class="icons-car" *ngIf="item.categoryName=='RENT-A-CAR'"></i>
          <i class="icon-small icon-pthemes iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='PTHEMES'"></i>
          <i class="icon-small icon-seguro iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='INSURANCE'"></i>
          <i class="icon-small icon-crucero iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='CRUISES'"></i>
          <i class="icon-small icon-disney iconstart text-center pl-1" style="font-size: 25px;"
            *ngIf="item.categoryName=='DISNEY'"></i>
        </div>
      </div>
      <div class="d-flex flex-column title-item">
        <div class="d-flex mb-2" style="margin-left: 5px;">
          <h5 class="font-poppins m-0">{{stingUpperCase(item.title)}}</h5>
        </div>
        <div class="d-flex mb-2 pl-1">
          <p class="font-poppins m-0">
            <i class="icon-placeholder placeholder" aria-hidden="true"></i>
            &nbsp;&nbsp;{{item.destination.iata_code}}
          </p>
        </div>
        <div class="d-flex flex-row justify-content-start">
          <img _ngcontent-c35="" alt="" class="img-fluid icon-premium-point" src="/assets/img/app/little-icons/premium-badge-coral.svg">
          <p _ngcontent-c35="" class="font-point text-left f-16 m-0 pt-2">&nbsp; {{item.blickoins}} Bks.</p>
        </div>
      </div>
      <!-- <div class="d-flex stars-item">
        <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome'" class="ml-auto stars" [readOnly]="true">
        </bar-rating>
      </div> -->
      <div class="d-flex flex-column ml-auto status-item justify-content-between">
        <div class="d-flex align-self-center">
          <div class="state" [ngClass]="{
              'product-status-saved': item.status_id == 1,
              'product-status-reserved': item.status_id == 2,
              'product-status-locked': item.status_id == 3,
              'product-status-denied': item.status_id == 4,
              'product-status-cancelled': item.status_id == 5,
              'product-status-paid-agency': item.status_id == 6,
              'product-status-paid-provider': item.status_id == 7,
              'product-status-billed': item.status_id == 8,
              'product-status-pending-approve-pay': item.status_id == 9,
              'product-status-completed': item.status_id == 10 }"
          >
            <p class="text-status m-0">
              {{ item.status }}
            </p>
          </div>
        </div>
        <div class="d-flex align-self-center">
          <p class="text-expiration m-0 p-0">Día de expiración <br> <span
              class="date-expiration">{{item.expirationDate ? (item.expirationDate | date:'mediumDate') : '' }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex price-item flex-column justify-content-between align-self-center">
      <div class="d-flex rows-price" style="justify-content: center;">
        <p class="font-poppins m-0 p-0 text-price">TARIFA NETA</p>
      </div>
      <div class="d-flex rows-price" style="justify-content: center;">
        <p class="font-poppins m-0 p-0 nro-price">{{currencyItinerary}} {{item.netPriceBooking | number:'1.2-2'}}</p>
      </div>
      <div class="d-flex rows-price flex-column mt-auto" >
        <p class="font-poppins text-select m-0 p-0 mt-2" style="text-align: center;">Seleccionar</p>
        <span class="mb-0 mt-1" style="margin: 0 auto;">
          <label class="custom-radio-checkbox mb-0">
            <input class="custom-radio-checkbox__input" [id]="i" type="checkbox" [name]="i" (change)="addItem(item, i)" [(ngModel)]="item.selected">
            <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
          </label>
        </span>
      </div>

    </div>
  </div>
</ng-container>


<!-- <button class="btn btn-primary" (click)="goStepTwo()">
  Next
</button> -->
