import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../../shared/rest.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'usb-confirm-reservation',
  templateUrl: './confirm-reservation.component.html',
  styleUrls: ['./confirm-reservation.component.scss']
})
export class ConfirmReservationComponent implements OnInit {
  reservation: any = [];
  reservationId: any;

  constructor(private _restService: RestService,private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log('aqui toy');

    this.route.queryParams.subscribe(params => {
      this.reservationId = (params["reservationId"]) ? params["reservationId"] : '1';
    });

    this.getConfirmationReservation();
  }

  getConfirmationReservation(){
    this._restService.doGet('app','reservations/' + this.reservationId).subscribe((response:any) => {
      this.reservation = response;
      console.log('lo que taje ', this.reservation);

    }, error => {
      console.log('Error: ' + error);
    });
  }

}
