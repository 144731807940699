export interface AppState {
  auth: AuthState;
}

export interface AuthState {
  roles: string[];
  permissions: string[];
  agency: string[];
  agent: string[];
  categories: string[];
  currency: string[];
}

export const initialAuthState = {
  roles: [],
  permissions: [],
  agency: [],
  agent: [],
  categories: [],
  currency: []
}
